import { canUseDOM } from 'exenv';
import React, { useContext, useEffect } from 'react';
import InPageAnnouncement from '_components/Announcements/InPageAnnouncement';
import {
  EducationProfessionalBodiesForm,
  EmploymentDetailsForm,
  ProfessionalInterestsForm,
  SpecialInterestsForm,
  LicencesListForm
} from '_components/MMFCareerProfileForm/Sections';
import Icon from '_components/Icon';
import ApiErrorContent from '_components/MMFCareerProfileForm/Sections/ApiErrorContent';
import { CareerProfileFormContext } from '_containers/MMFCareerProfileFormContext/definitions';
import { FormContext } from '_containers/FormSectionContext';
import { EditableSectionActions } from '_utils/components/Form/EditableSection';
import {
  IntroContainer,
  NarrowContainer,
  PrivacyPolicy,
} from '_utils/components/Form/StyledContainerForm';
import { CTAButton } from '_utils/components/Form/StyledFormSection';
import { buttonAction } from '_utils/helpers/form/index';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { CareerProfileFormProps } from './definitions';

const FormContent: React.FC<CareerProfileFormProps> = (props) => {
  const {
    careerProfileApi,
    careerProfileSubmissionApi,
    companySubmissionApi,
    rendering,
  } = props;

  const {
    isAnySectionEditing,
    sectionEditing,
    employmentDetails: { updateAction },
    isReferredFromRenewal,
    setIsEditing,
  } = useContext<CareerProfileFormContext>(FormContext);

  const isEditing = isAnySectionEditing;

  const datasource = rendering?.fields?.data?.datasource;
  const myAccountLink = datasource?.myAccountLink?.jss?.value;
  const privacyNotice = datasource?.privacyNotice?.jss;

  const viewMyAccountLabel = getDictionaryItem('form-my-account-link-label', 'View My Account');
  const cancelLabel = getDictionaryItem('form-contact-button-cancel', 'Cancel');
  const saveLabel = getDictionaryItem('form-contact-button-save-updates', 'Save changes');
  const errorHeading = getDictionaryItem('form-errors-heading', 'Something went wrong');

  // TODO: these need to be made clearer
  const isLoading = careerProfileApi?.result?.isLoading || careerProfileSubmissionApi?.result?.isLoading || companySubmissionApi?.result?.isLoading;
  const shouldShowApiError = !isLoading && (!careerProfileApi?.result?.isLoading && careerProfileApi?.result?.error);
  const shouldShowForm = !isLoading && !careerProfileApi?.result?.error && careerProfileApi?.result?.data && !shouldShowApiError;

  const getAPIErrorMessage = () => {
    if (careerProfileApi) {
      return careerProfileApi?.result?.error?.message;
    }
  };

  // scroll user to section being edited
  // TODO allow for header offset
  useEffect(() => {
    if (!canUseDOM || !sectionEditing) {
      return;
    }

    const targetSection = document.getElementById(sectionEditing);

    if (targetSection) {
      window.scrollTo({ top: targetSection?.offsetTop, behavior: 'smooth' });
    }
  }, [sectionEditing]);

  useEffect(() => {
    // Trigger Edit mode on form if referred from Membership Renewal form
    // TODO: change this to open the specific section that was clicked on the renewal form, when partial saving is available
    if (setIsEditing) {
      setIsEditing(isReferredFromRenewal);
    }
  }, [isReferredFromRenewal]);

  return (
    <>
      {shouldShowForm &&
        <PrivacyPolicy field={privacyNotice} className="fullWidthOnDesktop" />
      }

      <NarrowContainer>
        {isEditing && shouldShowApiError &&
          <>
            <IntroContainer>
              <InPageAnnouncement
                description={{ value: getAPIErrorMessage() }}
                title={{ value: errorHeading }}
                type={{ value: 'Red' }}
              />
            </IntroContainer>
            {careerProfileApi?.result?.error?.message &&
              <NarrowContainer>
                <ApiErrorContent {...props} />
              </NarrowContainer>
            }
          </>
        }

        {shouldShowForm &&
          <>
            <NarrowContainer>
              <EmploymentDetailsForm {...props} />
              <EducationProfessionalBodiesForm {...props} />
              <LicencesListForm {...props} />
              <ProfessionalInterestsForm {...props} />
              <SpecialInterestsForm {...props} />
              <EditableSectionActions
                cancelLabel={cancelLabel}
                explicitSectionKey={'employmentDetails'} // arbitrary choice because forms using this prop have the same sectionContexts per section (no partial saving available)
                saveLabel={saveLabel}
              >
                <CTAButton
                  aria-label={viewMyAccountLabel}
                  onClick={(e) => buttonAction(e, myAccountLink)}
                >
                  <Icon
                    ariaHidden={true}
                    name="arrow_back" />
                  {viewMyAccountLabel}
                </CTAButton>
              </EditableSectionActions>
            </NarrowContainer>
          </>
        }
      </NarrowContainer >
    </>
  )
};


export default FormContent;
