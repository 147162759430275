import { IPropList } from "..";
import generateHelper from '../generateHelper';

const SUPPORTED_PROPS: IPropList = {
  alignSelf: ['align-self'],
  alignItems: ['align-items'],
  alignContent: ['align-content'],
  justifyContent: ['justify-content'],
  order: ['order'],
  flexDirection: ['flex-direction'],
  flexWrap: ['flex-wrap'],
  flex: ['flex'],
  flexGrow: ['flex-grow'],
  flexShrink: ['flex-shrink']
};

const getValue = (props, property) => props ? props[property] : undefined;

export default (props) => generateHelper(SUPPORTED_PROPS, props, { getValue });
