export type LiveChatDatasource = {
  hideLiveChat?: boolean;
  niceBusNumber?: string;
  nicePoc?: string;
  niceServerHost?: string;
  niceSourceUrl?: string;
  offlineMessage?: string;
  showOfflineChat?: boolean;
};

export type LiveChatRendering = {
  fields?: {
    data?: {
      datasource?: LiveChatDatasource;
    };
  };
};

export type LiveChatProps = {
  rendering: LiveChatRendering;
  sitecoreContext: {
    route: {
      fields: {
        hideLiveChat: {
          value: boolean;
        }
      }
    }
  };
};

export type LiveChatOfflineMessageProps = {
  closeLabel?: string;
  message?: string;
  toggleIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type LiveChatOfflineProps = {
  datasource?: LiveChatDatasource;
  shouldHideLiveChat?: boolean;
};

export const COOKIE_BANNER_ID = 'onetrust-banner-sdk';
export const LIVE_CHAT_ONLINE_BUTTON_ID = 'icChatButton';
export const LIVE_CHAT_ONLINE_MESSAGE_ID = 'chat-div-wrap';
export const LIVE_CHAT_OFFLINE_BUTTON_ID = 'LiveChatOfflineButton';
export const LIVE_CHAT_OFFLINE_MESSAGE_ID = 'LiveChatOfflineMessage';
export const LIVE_CHAT_ONLINE_WRAPPER_CLASS = 'ie-div-position-customer-chat';
