import { useFormikContext } from 'formik';
import React from 'react';

import { ContactDetailsFormValues } from '_containers/ContactDetailsFormContext/definitions';
import { useLocationAPIContext } from '_containers/LocationAPIContext';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Dropdown from '_utils/components/Form/Dropdown';
import FormLabel from '_utils/components/Form/Label';
import { renderSelectPlaceholderForField } from '_utils/helpers/form';

import { CountryDropdownProps } from './definitions';

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  formValuesKey,
  handleChange,
}) => {
  const { countriesMap } = useLocationAPIContext();
  const { values: formValues } = useFormikContext<ContactDetailsFormValues>();

  const selectedCountry = formValues?.[formValuesKey]?.country;

  const countryLabel = getDictionaryItem("form-contact-country", "Country");
  const countryPlaceholder = renderSelectPlaceholderForField(countryLabel);

  return (
    <>
      <FormLabel htmlFor={`${formValuesKey}.country`} required>{countryLabel}</FormLabel>
      <Dropdown
        value={selectedCountry}
        getOptionLabel={(option) => option?.name ?? ''}
        getOptionSelected={(option, value) => option.isoCode2Letter === value.isoCode2Letter}
        handleChange={handleChange}
        id={`${formValuesKey}.country`}
        name={`${formValuesKey}.country`}
        multiple={false}
        options={Object.keys(countriesMap ?? {})?.map((countryIso) => (
          {
            name: countriesMap?.[countryIso]?.name,
            isoCode2Letter: countriesMap?.[countryIso]?.iso
          }
        ))
        }
        placeholder={countryPlaceholder}
      />
    </>
  );
};

export default CountryDropdown;
