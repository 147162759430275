import colors from '_containers/Theme/intheblack/colors';

const defaultStyle = {
  borderRadius: '1px',
}

export default {
  backgroundPressed: colors.interactiveCell.pressed,
  borderPressed: `1px solid ${colors.all.interactive500}`,
  nonInteractiveOutline: {
    ...defaultStyle,
    background: colors.background.tint1,
    border: colors.text.default,
    color: colors.text.default,

  },
  nonInteractiveDefault: {
    ...defaultStyle,
    background: colors.button.primary.color,
    color: colors.darkTheme.text.default,
  },
  nonInteractiveSubtle: {
    ...defaultStyle,
    background: colors.background.tint3,
    border: colors.background.tint3,
    color: colors.text.secondary,
  },
  nonInteractiveCategory: {
    ...defaultStyle,
    background: colors.background.tint1,
    border: colors.all.transparent,
    color: colors.text.subtle,
  },
  nonInteractiveStatusSuccess: {
    ...defaultStyle,
    background: colors.messaging.backgroundSuccess,
    border: colors.messaging.backgroundSuccess,
    color: colors.messaging.colorSuccessDark,
  },
  nonInteractiveStatusWarning: {
    ...defaultStyle,
    background: colors.messaging.backgroundWarning,
    border: colors.messaging.backgroundWarning,
    color: colors.messaging.colorWarningDark,
  },
  nonInteractiveStatusError: {
    ...defaultStyle,
    background: colors.messaging.backgroundError,
    border: colors.messaging.backgroundError,
    color: colors.messaging.colorErrorDark,
  },
  interactiveLink: {
    ...defaultStyle,
    background: colors.background.tint1,
    backgroundHover: colors.button.ghost.backgroundHover,
    backgroundVisited: colors.button.ghost.backgroundVisited,
    border: colors.text.interactive1,
    color: colors.text.interactive1,
  },
  interactiveCategory: {
    ...defaultStyle,
    background: colors.background.tint1,
    border: colors.all.transparent,
    color: colors.text.interactive1,
    colorHover: colors.text.interactive1Hover,
    colorVisited: colors.button.ghost.colorVisited,
    padding: 0,
    textDecoration: 'underline',
  },
}

export const darkMode = {
  nonInteractiveSubtle: {
    ...defaultStyle,
    background: colors.darkTheme.background.tint3,
    color: colors.darkTheme.text.secondary,
  },
  nonInteractiveCategory: {
    ...defaultStyle,
    background: colors.background.tint1,
    color: colors.darkTheme.text.subtle,
  },
};
