import styled, { css } from 'styled-components';

import {
  mq,
  svg,
} from '_utils/styles';

import { HingePlacement } from './definitions';

/* You need to apply this to the parent of BookHinge. They're just to make sure BookHinge can position absolute. */
export const BookHingeParent = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const getHingePlacementRules = (
  placement: HingePlacement,
) => {
  const hingePlacementRules: Record<HingePlacement, css> = {
     /* To prevent 'crack' between this and banner, shift 1px extra outside of container. */
    top: css`
      left: 50%;
      top: -29px;
      transform: translateX(-50%) rotate(90deg);
    `,
    left: css`
      left: -1px;
      top: 50%;
      transform: translateY(-50%);
    `,
    hidden: css`
      display: none;
    `,
  };

  return hingePlacementRules[placement];
};

export const BookHingeContainer = styled.div`
  position: absolute;
  ${({ mobilePlacement }) => getHingePlacementRules(mobilePlacement)}

  ${mq('md')} {
    ${({ desktopPlacement }) => getHingePlacementRules(desktopPlacement)}
  }
`;

export const BookHingeSVGContainer = styled.span`
  ${svg(31,101)}
  width: 22px;

  ${mq('md')} {
    width: 31px;
  }

  @media print {
    display: none;
  }

  /* Default to page background. Apply your desired color through your component. */
  path {
    fill: ${({ theme }) => theme.colors.background.tint1};
  }
`;
