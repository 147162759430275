import styled from 'styled-components';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';

import {
  Grid,
} from '_utils/components';

import { spacing } from '_utils/props';
import {
  mq,
  svg,
} from '_utils/styles';

export const PageContainer = styled(Grid.Container)`
  padding-left: 0;
  padding-right: 0;
`;


export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 12px 4px 4px 12px;
  ${mq.lessThan('md')} {
    flex-direction: column;
    border-radius: 4px 4px 4px 4px;
  }
  margin-bottom: 20px;
`;


export const ImageWrapper = styled.div`
  min-height: 170px;
  width: 170px;
  flex-shrink: 0;

  ${mq.lessThan('md')} {
    width: 100%;
    height: 170px;
    max-height: 190px;
  }
`;

export const BrandImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;

  border-radius: 12px 0px 0px 12px;

  // This is only for the with Interest brand
  ${mq.lessThan('md')} {
    ${({ isWithInterestBrand }) => isWithInterestBrand && `object-position: center 82%;`};
    border-radius: 4px 4px 4px 4px;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 300px;
  position: relative;
  margin-right: 12px;
  ${mq.lessThan('md')} {
    width: 100%;
  }
`;

export const HeadingText = styled.div`
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  color: #0A0D46;
  ${({ theme }) => spacing({ theme, ml: { xs: `20px`, sm: `20px`, md: 2, lg: `20px` }, mt: `16px` })};
  ${mq.lessThan('md')} {
    display: none;
  }
`;

export const DescriptionText = styled.div`
  ${({ theme }) => spacing({ theme, ml: { md: 2, lg: `20px` }, mt: { sm: `10px` } })};
  font-size :14px;
  line-height: 20px;
  margin-top: 10px;
  ${mq.lessThan('md')} {
    margin: 18px 20px;
  }
`;

export const FooterWrapper = styled.div`
${({ theme }) => spacing({ theme, ml:  `20px`, mt: { sm: `10px` } })};
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  a {
    color: ${({ theme }) => theme.cardList.action};
    text-decoration: none;
  } 

  margin-top: auto;
  ${mq.lessThan('md')} {
    margin-top: 4px;
  }
`;


export const ArrowWrapper = styled.span`
  ${svg(24, 24)}
  display: flex;
  width: 24px;
  margin-left: 6px;
  path {
    fill: ${({ theme }) => theme.cardList.action};
  }
`;

export const SubscriptionWrapper = styled.div`
${({ theme }) => spacing({ theme, ml: { xs: `20px`, sm: `20px`, md: 2, lg: `20px` } })};

display: flex;
flex-direction: column;
width: 200px;
${mq.lessThan('md')} {
  width: 100%;
}
`;

export const SubscriptionHeadingText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #0A0D46;
  ${({ theme }) => spacing({ mt: `16px` })};
`;

export const PlatformWrappers = styled.div`
  display: flex;
  margin-top: 12px;
`;