import { compose } from 'ramda';
import React from 'react';

import { withDigitalDataContext } from '_containers/DigitalDataContext';
import { LinkTrackingEvent } from '_containers/DigitalDataContext/definitions';

import PersonFallbackImage from '_utils/components/PersonFallbackImage';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize } from '_utils/types/enums';

import { ContributorInfoProps } from '../definitions';
import {
  Profile,
  ProfileDetails,
  ProfileFigure,
  ProfileImage,
  ProfileName,
} from './StyledContributorInfo';

const ContributorInfo: React.FC<ContributorInfoProps> = ({
  digitalData,
  editMode,
  image,
  profileName,
  setDigitalData,
  url,
}) => {
  // handle analytics
  const dispatchTracking: LinkTrackingEvent = (name, destination) => {
    if (typeof setDigitalData === 'function') {

      setDigitalData(
        setObjectData(
          ['link'],
          {
            linkName: name,
            linkDestination: destination,
          },
          digitalData)
      );

      emitTrackEvent('linkClick');
    }
  };

  return (
    <Profile
      to={!editMode && url}
      onClick={
        editMode ? () => { }
          :
          () => {
            dispatchTracking(profileName?.value, url);
          }}>
      <ProfileFigure >
        {image?.src ? (
          <ProfileImage
            className='lazyload'
            aria-hidden
            draggable={false}
            field={
              editMode
                ? image
                : {
                  value: {
                    src: imagePlaceholderSrc,
                    alt: '', // alt text is empty string, as this is a decorative image
                    'data-srcset': createSrcSet(image?.src, [ImageSize.MW_128])
                  }
                }
            }
            height="48"
            width="48"
          />
        ) :
          (
            <PersonFallbackImage
              width='48'
              height='49'
            />
          )}
      </ProfileFigure>
      <ProfileDetails>
        <ProfileName>{profileName?.value}</ProfileName>
      </ProfileDetails>
    </Profile>
  )
};

export default compose(
  withDigitalDataContext,
)(ContributorInfo);
