import { compose } from 'ramda';
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { CareerProfileFormContext, CareerProfileFormValues } from '_containers/MMFCareerProfileFormContext/definitions';
import { withEditMode } from '_containers/BaseComponent';
import {
  FormContext,
  withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import Dropdown from '_utils/components/Form/Dropdown';
import FieldSummary from '_utils/components/Form/FieldSummary';
import FormLabel from '_utils/components/Form/Label';
import {
  Field,
  FormSummary,
} from '_utils/components/Form/StyledFormSection';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { getCommaSeparatedList } from '_utils/helpers/form/index';

import { CareerProfileFormProps } from '../definitions';
import mockData from '../mock.json';

const LicencesForm: React.FC<CareerProfileFormProps> = ({
  editMode,
  mockCareerProfile,
  rendering,
  careerProfileApi
}) => {
  const formContext = useContext<CareerProfileFormContext>(FormContext);
  const sectionContext = formContext?.licences;
  const referenceData = careerProfileApi?.result;

  const getLicensesOptions = referenceData?.data?.referenceData?.licences?.items;

  const uid = `licenses-certificates`;

  // render component with mock data when in Experience Editor
  const careerProfile =
    !editMode
      ? careerProfileApi?.result?.data
      : mockCareerProfile ?? mockData?.mockCareerProfile;

  // get the field and label values
  const heading = getDictionaryItem('form-career-section-licenses', 'Licenses and certificates');
  const updateLabel = getDictionaryItem('form-career-button-update', 'Update');
  const licensesAndCertificatesLabel = getDictionaryItem('form-career-licenses-certificates', 'Licenses and certificates');
  const licensesPlaceholder = getDictionaryItem('form-placeholder-licenses-certificates', 'Select');

  const { values } = useFormikContext<CareerProfileFormValues>();

  const getLicences = values?.licences?.items;

  return (
    <EditableSectionContainer
      className="licenses-certificates-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      <EditableSectionHeader
        icon="verified_user"
        updateLabel={updateLabel}
        sectionIdentifier={uid}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <FormSummary>
            <Field config={{ col: { xs: 12, lg: 6 } }}>
              <FormLabel htmlFor="licences.items">{licensesAndCertificatesLabel}</FormLabel>
              <Dropdown
                defaultValue={getLicences?.map((item) => ({
                  key: item.licenceKey,
                  name: item.licenceName
                }))}
                getOptionLabel={(option) => option?.name}
                getOptionSelected={(option, value) => value?.key === option?.key}
                id="licences.items"
                name="licences.items"
                options={getLicensesOptions ?? []}
                placeholder={licensesPlaceholder}
                shouldShowCheckboxes
              />
            </Field>
          </FormSummary>
        ) : (
          <FormSummary>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-licenses-held"
                labelFallback="Licenses held"
                value={getCommaSeparatedList(careerProfile?.licences?.items, 'licenceName')}
              />
            </Field>
          </FormSummary>
        )
      }
    </EditableSectionContainer>
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('licences'),
)(LicencesForm);
