import React from 'react';

import Icon from '_components/Icon';

import { AuthenticatedContentIconProps } from './definitions';
import { IconWrapper } from './StyledAuthenticatedContentIcon';

const iconBreakpointDefaultSize = {
  xs: {
    iconSize: 16,
    mr: 8,
    top: 5,

  },
  md: {
    iconSize: 24,
    mr: 8,
    top: 5,
  }
}

export const AuthenticatedContentIcon: React.FC<AuthenticatedContentIconProps> = ({
  iconStyleConfig,
  isAuthenticated,
  ...props
}) => {
  const mergedProps = { ...iconBreakpointDefaultSize, ...iconStyleConfig };

  return isAuthenticated &&
    <IconWrapper config={mergedProps} {...props}>
      <Icon name="lock" ariaHidden={false} />
    </IconWrapper>
};
