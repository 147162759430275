import React from 'react';
import { List } from './StyledCardList';
import { Carousel } from '_utils/components';

const ItemWrapper= ({ carouselEnabled, className, children, trackingName }) => {
  return !carouselEnabled 
    ? <List as="ul" className={className}>
      {children}
    </List>
    : <Carousel flickityOptions={{ groupCells: true, cellAlign: 'left' }} trackingName={trackingName}>
      {children}
    </Carousel>
};

export default ItemWrapper;
