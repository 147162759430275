import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';
import { withEditMode } from '_containers/BaseComponent';

import { Container, Description, HeaderSection, Heading } from './StyledHeadingWithDescription';

export type HeadingWithDescriptionProps = JSX.ElementChildrenAttribute & {
  editMode: boolean;
  descriptionField?: {
    value?: string;
  };
  headingField: {
    value: string;
  };
  headingLevel: any;
  reduceBottomPadding?: boolean;
};

const HeadingWithDescription: React.FC<HeadingWithDescriptionProps> = ({
  children,
  editMode,
  descriptionField,
  headingField,
  headingLevel,
  reduceBottomPadding = false
}) => {
  headingLevel = headingLevel || 'h3';

  return (
    <Container>
      {(editMode || headingField) && (
        <HeaderSection>
          <Heading
            tag={typeof headingLevel === 'number' ? `h${headingLevel}` : headingLevel}
            field={headingField}
          />
          {children}
        </HeaderSection>
      )}
      {(editMode || (descriptionField && descriptionField?.value)) && <Description reduceBottomPadding={reduceBottomPadding}><Text field={descriptionField} /></Description>}
    </Container>
  );
};

export default compose(withEditMode(HeadingWithDescription));
