import styled, { css } from 'styled-components';
import { Grid } from '_utils/components';

import {
  avoidPageBreakOnPrint,
  mq,
} from '_utils/styles';
import {
    Container as CarouselContainer,
    PrevNextPosition,
} from '_utils/components/Carousel/StyledCarousel';
import { spacing } from '_utils/props';

export const StyledListItem = styled(Grid.Item)`
  flex-direction: column;
  min-height: 100%;

  @media print {
    display: inline-block !important;
    margin:0;
    width: 33%;
  }
`;

export const hideContainer = css`
  display: none
`

// ${(props: { hiddenContainer: any; }) => props.hiddenContainer && hiddenContainer};

export const StyledContainer = styled(Grid.Container)`
// remove padding if child of Single Column placeholder (ie. within Dashboard)
  .ph-sc & {
    padding: 0;
  }

  .hideContainer {
    display: none;
  }

  background: ${({ theme }) => theme.personalisedCarousel.carousel.background};

  // if carousel, ensure shadows aren't cut off and apply negative margin to extend full width in < lg breakpoints
  ${CarouselContainer} {
    ${({ theme }) => spacing({
        theme,
        ml: {
            xs: `-${theme.grid.gutter.size.xs}px`,
            md: `-${theme.grid.gutter.size.md}px`,
            lg: `-${theme.grid.gutter.size.lg}px`,
        },
        mr: {
            xs: `${theme.grid.gutter.size.xs * 2}px`,
            md: `${theme.grid.gutter.size.md * 2}px`,
            lg: `${theme.grid.gutter.size.lg * 2}px`,
        }
    })}

    width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.xs * 2}px)`};

    ${mq('md')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.md * 2}px)`};
    }

    ${mq('lg')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.lg * 2}px)`};
    }

    .flickity-slider {

      ${avoidPageBreakOnPrint}

      @media print {
        overflow: hidden;
        white-space: pre-wrap;
      }

      ${StyledListItem} {
        ${({ theme }) => spacing({
            theme,
            pl: {
                xs: `${theme.grid.gutter.size.xs}px`,
                md: 3,
                lg: 0,
            },
            pr: {
                xs: 0,
                md: 0,
                lg: `${theme.grid.gutter.size.lg + (theme.grid.gutter.size.lg / 3)}px`,
            },
            py: {
                xs: 5,
                md: 3,
                lg: 3,
            },
        })}

        ${mq('lg')} {
          transform: ${({ theme }) => `translateX(${theme.grid.gutter.size.lg}px)`};
        }
      }

      /* In order to get the sizing correct and prevent cut off of box shadow on every 3rd card */
      ${mq('lg')} {
        > div:nth-of-type(3n) {
          margin-right: ${({ theme }) => `${(theme.grid.gutter.size.lg / 3) * 2}px`};
          width: ${({ theme }) => `calc(33.3% - ${(theme.grid.gutter.size.lg / 3) * 2}px)`};

          &.is-selected {
            margin-right: 0;
          }
        }

        .is-selected + div:not(.is-selected) {
          > a {
            box-shadow: none !important;
          }
        }

        > div:nth-last-child(3) {
          &.is-selected {
            ${({ numItems }) => {
                if (numItems % 3 === 0) {
                return css`
                        transform: ${({ theme }) => `translateX(${(theme.grid.gutter.size.lg / 3) * 2}px)`};
                    `
                }
            }}
        }
        }

        > div:nth-last-child(2) {
          &.is-selected {
        ${({ numItems }) => {
            if (numItems % 3 === 0 || numItems % 3 === 2) {
            return css`
                transform: ${({ theme }) => `translateX(${(theme.grid.gutter.size.lg / 3) * 2}px)`};
                    `
            }
        }}
        }}}

      /* Corrections to the last card, to allow it to sit on the end edge with shadow overflowing right */
      > div:last-of-type {
        ${({ theme }) => spacing({
            theme,
            pr: {
            xs: `${theme.grid.gutter.size.xs}px`,
            md: `${theme.grid.gutter.size.md}px`,
            lg: `${theme.grid.gutter.size.lg + (theme.grid.gutter.size.lg / 3)}px`,
            },
        })}

        &.is-selected {
          ${mq('lg')} {
            transform: ${({ theme }) => `translateX(${(theme.grid.gutter.size.lg / 3)}px)`};
          }
        }
      }
    }

    ${PrevNextPosition} {
      &.prev {
        ${({ theme }) => css`
          left: ${theme.grid.gutter.size.xs}px;

          ${mq('md')} {
            left: ${theme.grid.gutter.size.md}px;
          }

          ${mq('lg')} {
            left: 64px;
          }
        `}
      }

      &.next {
        ${({ theme }) => css`
          right: ${theme.grid.gutter.size.xs}px;

          ${mq('md')} {
            right: ${theme.grid.gutter.size.md}px;
          }

          ${mq('lg')} {
            right: 64px;
          }
        `}
      }
    }
  }
`;