import React from 'react';
import { List } from './StyledCardListWithInternalNavigation';
import { Carousel } from '_utils/components';

const ItemWrapper = ({ carouselEnabled, children, trackingName }) => {
  return !carouselEnabled ?
    <List>
      {children}
    </List>
    :
    <Carousel flickityOptions={{ groupCells: true, cellAlign: 'left' }} trackingName={trackingName}>
      {children}
    </Carousel>
};

export default ItemWrapper;
