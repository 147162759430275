
import { compose } from 'ramda';
import React from 'react';

import { withCoveoNoIndexTags } from '_containers/BaseComponent';

import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { MagazineBannerRenderingProps } from './definitions';

import SidebarMagazineBanner from './SidebarMagazineBanner';
import ColumnMagazineBanner from './ColumnMagazineBanner';


const MagazineBanner: React.FC<MagazineBannerRenderingProps> = ({
  rendering,
  editMode,
}) => {
  const uid = rendering?.uid;
  const magazine = rendering?.fields?.data?.datasource?.latestMagazine?.targetItem;
  if (!magazine) {
    return null;
  }

  const coverImage = magazine.coverImage?.jss;
  const title = magazine.title?.value;
  const link = magazine.url;

  const isSidebarVariant = rendering?.params?.sidebarVariant || false;
  const hideOnMobile = rendering?.params?.hideOnMobile || false;

  const componentLabel = rendering?.fields?.data?.datasource?.componentLabel?.value;
  const ctaLabel = rendering?.fields?.data?.datasource?.ctaLabel?.value;

  return (
    <>
      {!isSidebarVariant &&
        <ColumnMagazineBanner
          componentLabel={componentLabel}
          coverImage={coverImage}
          ctaLabel={ctaLabel}
          editMode={editMode}
          hideOnMobile={hideOnMobile}
          link={link}
          title={title}
          uid={uid}
        />
      }

      {isSidebarVariant &&
        <SidebarMagazineBanner
          className="sidebarMagazineBanner"
          componentLabel={componentLabel}
          coverImage={coverImage}
          ctaLabel={ctaLabel}
          editMode={editMode}
          link={link}
          title={title}
          uid={uid}
        />
      }
    </>
  )
};

export default compose(withCoveoNoIndexTags)(MagazineBanner);