import { useFormikContext } from 'formik';
import { compose } from 'ramda';
import React, { useContext } from 'react';

import { withEditMode } from '_containers/BaseComponent';
import { CareerProfileFormContext, CareerProfileFormValues } from '_containers/MMFCareerProfileFormContext/definitions';
import {
  FormContext,
  withFormSectionKeyContext
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import Dropdown from '_utils/components/Form/Dropdown';
import { Tooltip } from '_utils/components';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import FieldSummary from '_utils/components/Form/FieldSummary';
import FormLabel from '_utils/components/Form/Label';

import {
  Field,
  FormSummary
} from '_utils/components/Form/StyledFormSection';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { getCommaSeparatedList } from '_utils/helpers/form';

import { CareerProfileFormProps } from '../definitions';
import mockData from '../mock.json';


const EducationProfessionalBodiesForm: React.FC<CareerProfileFormProps> = ({
  editMode,
  mockCareerProfile,
  rendering,
  careerProfileApi,
}) => {
  const formContext = useContext<CareerProfileFormContext>(FormContext);
  const sectionContext = formContext?.educationProfessionalBodies;
  const { values } = useFormikContext<CareerProfileFormValues>();
  const referenceData = careerProfileApi?.result;
  const educationRefData = referenceData?.data?.referenceData?.highestEducations?.items;
  const professionalBodiesRefData = referenceData?.data?.referenceData?.professionalBodies?.items;
  const tooltipText = "Please provide the highest level of education you have achieved through an education provider such as a university.  Exams completed with professional accounting or finance membership bodies should be provided under Licences and Certificates below."
  const uid = `education-professionalbodies`;
  const tooltipTextAccred = 'If you have studied or attained membership with an accounting or finance membership body other than CPA Australia, please select from the list of bodies below and also indicate this in the Licences and certificates.'

  // render component with mock data when in Experience Editor
  const careerProfile =
    !editMode
      ? careerProfileApi?.result?.data
      : mockCareerProfile ?? mockData?.mockCareerProfile;

  // get the field and label values
  const heading = getDictionaryItem('form-career-section-education', 'Education and professional bodies');
  const updateLabel = getDictionaryItem('form-career-button-update', 'Update');
  const highestEducationLabel = getDictionaryItem('form-career-highest-education', 'Highest education');
  const accreditationBodiesLabel = getDictionaryItem('form-career-accreditation-bodies', 'Other professional accreditations');
  const pleaseSelectPlaceholder = getDictionaryItem("form-placeholder-please-select", "Please select");
  const searchAccreditationPlaceholder = getDictionaryItem("form-placeholder-accreditation-bodies", "Search accreditation");

  return (
    <EditableSectionContainer
      className="education-professionalbodies-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      <EditableSectionHeader
        icon="school"
        updateLabel={updateLabel}
        sectionIdentifier={uid}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <>
            <FormSummary>
              <Field config={{ col: { xs: 12, lg: 6 } }}>
                <FormLabel htmlFor="education.highestEducationName">{highestEducationLabel}
                  <Tooltip content={tooltipText} />
                </FormLabel>
                <Dropdown
                  defaultValue={values?.education?.highestEducationName}
                  id="education.highestEducationName"
                  multiple={false}
                  name="education.highestEducationName"
                  options={educationRefData?.map(item => item?.name) ?? []}
                  placeholder={pleaseSelectPlaceholder}
                />
              </Field>
            </FormSummary>
            {/* in case needed later we can add this but we do not need this now */}


            <FormSummary>
              <Field config={{ col: { xs: 12, lg: 6 } }}>
                <FormLabel htmlFor="professionalMemberships.items">Other professional accreditations
                  <Tooltip content={tooltipTextAccred} />
                </FormLabel>
                <Dropdown
                  defaultValue={values?.professionalMemberships?.items}
                  getOptionLabel={(option) => option?.professionalBodyName}
                  getOptionSelected={(option, value) => value?.professionalBodyName === option?.professionalBodyName}
                  id="professionalMemberships.items"
                  name="professionalMemberships.items"
                  options={professionalBodiesRefData ?? []}
                  placeholder={searchAccreditationPlaceholder}
                  shouldShowCheckboxes
                />
              </Field>
            </FormSummary>

          </>
        ) : (
          <>
            <FormSummary>
              <Field>
                <FieldSummary
                  labelDictionaryKey="form-career-highest-education"
                  labelFallback="Highest education"
                  value={careerProfile?.education?.highestEducationName}
                />
              </Field>
            </FormSummary>
            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 6 } }}>
                <FieldSummary
                  labelDictionaryKey="form-career-other-accreditations"
                  labelFallback="Other professional accreditations"
                  value={getCommaSeparatedList(careerProfile?.professionalMemberships?.items, 'professionalBodyName')}
                />
              </Field>
            </FormSummary>
          </>
        )
      }
    </EditableSectionContainer>
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('educationProfessionalBodies'),
)(EducationProfessionalBodiesForm);