import colors from '_containers/Theme/intheblack/colors';

export default {
  lightBanner: {
    headerColor: colors.all.brandPrimary3,
    color: colors.all.neutral700,
  },
  darkBanner: {
    headerColor: colors.all.neutralWhite,
    color: colors.darkTheme.text.default,
  },
  background: {
    black: colors.darkTheme.background.tint1,
    blue: colors.all.brandSecondary4Light,
    orange: colors.all.brandSecondary3,
    purple: colors.all.visited500,
    teal: colors.all.brandPrimary2,
    yellow: colors.all.brandSecondary5Light,
  },
  container: {
    mediaPrint: {
      background: colors.background.tint1,
      color: colors.element.neutralBlack
    },
    minHeight: 270,
  },
  linkButton: {   
    pathFillColor: colors.button.primary.background
  }
};
