import colors from '_containers/Theme/corporate/colors';


export default {
  searchMenuHeader: {
    borderColor: colors.background.disabled
  },
  closeIcon: {
    pathFillColor: colors.element.neutralWhite,
  },
  searchMenuButton: {
    pathFillColor: colors.all.brandPrimary3,
    focus: colors.element.focused,
    label: {
      color: colors.all.brandPrimary3
    }
  }
}