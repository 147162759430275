import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, { useRef } from 'react';

import { withEditMode, withThemeOption } from '_containers/BaseComponent';
import { excludeThemeColors } from '_containers/Theme/global';
import { Section } from '_utils/components';
import { usePlaceholderHasChildren } from '_utils/hooks';
import { PlaceholderDashboardFullWidthProps } from './definitions';
import { Wrapper } from './StyledPlaceholderDashboardFullWidth';

const PlaceholderDashboardFullWidth: React.FC<PlaceholderDashboardFullWidthProps> = ({ rendering, editMode }) => {
  const isEmpty = (rendering?.placeholders?.['jss-components-dashboard-fullwidth'] ?? []).length === 0;

  const isEmptyClassName = !editMode && isEmpty ? 'is-empty' : null;

  const sectionTheme =
    (rendering?.params?.placeholderTheme &&
      excludeThemeColors.indexOf(rendering?.params?.placeholderTheme.toLowerCase()) === -1)
      ? rendering?.params?.placeholderTheme.toLowerCase()
      : '';

  const staticClassArray = [];

  const defaultClassName = 'default';

  if (isEmptyClassName) {
    staticClassArray.push(isEmptyClassName);
  }

  if (sectionTheme) {
    staticClassArray.push(sectionTheme);
  }

  if (staticClassArray.length === 0) {
    staticClassArray.push(defaultClassName);
  }

  const staticClasses = staticClassArray.join(' ');

  const rowRef = useRef<HTMLElement>(null);

  usePlaceholderHasChildren(rowRef)

  return (
    <Section className={`ph db-fw ${staticClasses}`}>
      <Wrapper ref={rowRef}>
        <Placeholder name="jss-components-dashboard-fullwidth" rendering={rendering} darkMode={true} />
      </Wrapper>
    </Section>
  );
};

export default compose(withThemeOption, withEditMode)(PlaceholderDashboardFullWidth);
