import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Grid from '_utils/components/Grid';

import { spacing } from '_utils/props';
import {
  color,
  fixedBody12,
  fixedTitle18,
  fixedTitle12,
  linkCard,
  listReset,
  mq,
  restrictCardTitle,
  svg,
  textBody1,
  textBody2
} from '_utils/styles';

import {
  avoidPageBreakOnPrint,
  hideOnPrint,
} from '_utils/styles/print';

const container = css`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq('lg')} {
    height: 100%;
  }
`;

// The margin-right is to avoid overlapping with brandImage
export const TagContainer = styled.ul`
  ${listReset}
  margin-bottom: 16px;
  margin-right: ${({hasBrandImg}) => hasBrandImg ? '42px' : '0px'};
  ${hideOnPrint}
`;

export const Tag = styled.li`
  ${fixedBody12};
  background: ${({ theme }) => theme.colors.background.tint3};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.subtle};
  display: inline;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 0 4px;
  text-transform: uppercase;
`;

const strip = (type) => css`
  :after {
    background-color: ${({ theme }) => `${theme.cardList.highlightColour[type]}`};
    border-top-left-radius: ${({ theme }) => theme.cardList.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.cardList.borderRadius}px;
    content:'';
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .fullwidth &:after{
    ${mq('md')} {
      bottom: 0;
      height: 100%;
      right: unset;
      top: 0;
      width: 6px;
    }
  }
`;

export const PlaceholderCard = styled(Grid.Item)`
  background-color: ${({ theme }) => theme.cardList.cardBackground};
`;

export const Card = styled(NavLink)`
  ${({ theme }) => linkCard({ theme })}
  ${({ theme }) => spacing({ theme, pr: 3, pl: 3, pb: 3 })}
  ${container}
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  /* HACK: add height of HighlightStrip because absolute positioned */
  padding-top: ${(theme) => theme.$hasHighlightStrip ? '30px' : '24px'};
  position: relative;

  ${({ $showHighlightStrip, $type }) => $showHighlightStrip ? strip($type) : null}

  &:hover {
    h4 {
      color: ${({ theme }) => theme.colors.text.interactive1};
    }
  }

  .fullwidth & {
    ${mq('md')} {
      flex: 1 1 auto;
      flex-wrap: wrap;
      padding: 0;
    }
  }

  ${avoidPageBreakOnPrint}
`;

export const ImageColumn = styled.div`
  ${({ theme }) => spacing({ theme, mt: '-24px', mb: 3, ml: '-24px', mr: '-24px' })}
  align-items: flex-start;
  border-top-left-radius: ${({ theme }) => theme.cardList.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.cardList.borderRadius}px;
  display: flex;
  justify-content: center;

  .fullwidth .hasImage & {
    ${mq('md')} {
      border-bottom-right-radius: ${({ theme }) => theme.cardList.borderRadius}px;
      border-top-left-radius: 0;
      height: 100%;
      margin: 0;
      position: absolute;
      right: 0;
      width: 33.4%;
      }
  }
`;

export const ImageContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  .fullwidth & {
    ${mq('md')} {
      height: 100%;
    }
  }
`;

export const BrandContainer= styled.div`
  height: 34px;
  width: 34px;;
  position: absolute;
  right: 24px;
  top: 24px;
`;

export const BrandImage= styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${Card}.publication & {
    font-family: 'object-fit: contain;';
    object-fit: contain;
  }

  ${Card}.focus & {
    font-family: 'object-fit: contain;';
    height: 75%;
    left: 50%;
    object-fit: contain;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
  }
`;

export const DetailsColumn = styled.div`
  ${textBody2}
  color: ${color('text.subtle')};
  display: flex;
  flex-direction: column-reverse;
  flex: 0 1 auto;
  
  > div {
    display: flex;
    flex-direction: column;
    margin-top:${({hasBrandImg}) => hasBrandImg ? '16px' : '0px'};
  }

  .fullwidth & {
    ${mq('md')} {
      ${({ theme }) => spacing({ theme, p: 3 })}
      }
  }

  .fullwidth .hasImage & {
    ${mq('md')} {
      width: 66.6%;
    }
  }

  /* allow for absolutely positioned logo */
  .fullwidth .intheblack &,
  .fullwidth .inpractice &  {
    ${mq('md')} {
      width: calc(100% - 98px);
    }
  }
`;

const IconContainerStyles = css`
  color: inherit;
  display: block;
  text-decoration: none;

  &.title-is-link:hover {
    color: ${({ theme }) => theme.cardList.action};
  }
`;

export const IconContainer = styled.span`
  ${IconContainerStyles}
`;

export const LinkIconContainer = styled(NavLink)`
  ${IconContainerStyles}
`;

const AttributeItemStyles = css`
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  &.cardType {
    display: none;
  }

  .fullwidth & {

    ${mq('md')} {
      display: flex;
      margin-bottom: 0;

      &.cardType {
        margin-right: 16px;
      }

      &:not(.cardType) {
        margin-right: 24px;
      }
  }

  }

  svg {
    ${svg(16, 16)}
    flex-shrink: 0;
    margin-right: 8px;
    position: relative;
    top: 0;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.text.nonessential};
    }
  }`;

export const AttributesItem = styled.div`
  ${AttributeItemStyles}
`;

export const AttributesWrapper = styled.div`
  ${fixedBody12}
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .fullwidth & {
    ${mq('md')} {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
`;

export const ArrowWrapper = styled.span`
  ${svg(24, 24)}
  display: flex;
  width: 24px;

  path {
    fill: ${({ theme }) => theme.cardList.action};
  }
`;

export const Title = styled.div`
  h3,
  h4,
  h5,
  h6,
  h3&,
  h4&,
  h5&,
  h6& {
    ${fixedTitle18}
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const SubTitle = styled.div`
  h3,
  h4,
  h5,
  h6,
  h3&,
  h4&,
  h5&,
  h6& {
    ${fixedTitle12}
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const AttributeFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => spacing({ theme, pt: 2 })}

  .fullwidth & {
    ${mq('md')} {
      ${({ theme }) => spacing({ theme, px: 3, pb: 2 })}
    }
  }

  .fullwidth li > *:not(.textonly):not(.icon) & {
    ${mq('md')} {
      border-top: 1px solid ${({ theme }) => theme.colors.ui.divider};
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .fullwidth .textonly &,
  .fullwidth .icon & {
    ${mq('md')} {
      ${({ theme }) => spacing({ theme, px: 3, pt: 0 })}
    }
  }

  .fullwidth .hasImage & {
    ${mq('md')} {
      max-width: 66.6%;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: auto;

  .fullwidth .hasImage & {
    ${mq('md')} {
      ${({ theme }) => spacing({ theme, ml: 0 })}
    }
  }
`;

export const CardTypeLabel = styled.span`
  display: none;
  font-weight: bold;
  text-transform: capitalize;

  .fullwidth & {
    ${mq('md')} {
      display: flex;
    }
  }
`;

export const Description = styled.p`
  ${textBody2}
  ${restrictCardTitle}
  flex-grow: auto;
  margin-bottom: 0 !important;
  margin-top: 8px;
`;

export const DateWrapper = styled.div`
  ${fixedBody12}
`;

export const LogoWrapper = styled.div`
  height: 20px;
  margin-bottom: 32px;
  width: 73px;

  .fullwidth & {

    ${mq('md')} {
      position:absolute;
      top: 24px;
      right: 24px;
    }
  }
`;

export const IconWrapper = styled.div`
  ${svg(40, 40)}
  display: flex;
  margin-bottom: 16px;
  width: 40px;

  .fullwidth & {
    ${mq('md')} {
      ${({ theme }) => spacing({ theme, mb: 0, ml: 3, mt: 2 })}
    }
  }

  path {
    fill: ${({ theme }) => theme.cardList.action};
  }

  ${mq('md')} {
    ${svg(48, 48)};
    width: 48px;
  }
`;

export const ITBLabel = styled.span`
 color: ${({ theme }) => theme.colors.all.brandPrimary1};
 margin-right: 11px;
`;

export const StyledSeparator = styled.span`
  ${textBody1}
  display: none;
  font-weight: 900;

  .fullwidth a:not(.campaign) & {
    ${mq('md')} {
        color: ${({ theme }) => theme.colors.text.secondary};
        display: inline-block;
        margin-left: 12px;
    }
  }
`;

export const StyledHtmlContent = styled.div`
  //  style all potential content
  &.html-content {
    ${textBody2}

    p & {
      color: #767676 !important;
    }

    ul,
    ol & {
      list-style: none;
      padding-left: 0;
    }
  }
  

  
  
  
`;
