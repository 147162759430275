export { default as createFocusStylesObserver } from './FocusStylesObserver';

export const safeFocusRemoval = `
  .safe-focus-removal & {
    outline: 0
  }
`;

export const focusOutline = ({ theme }) => `
  outline-color: ${theme?.focus?.outlineColor};
  outline-offset: ${theme?.focus?.outlineOffset};
  outline-width: ${theme?.focus?.outlineWidth};
  outline-style: ${theme?.focus?.outlineStyle};
`;
