import { canUseDOM } from 'exenv';
import cookies from 'js-cookie';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { COOKIE_BANNER_ID } from '_components/LiveChat/definitions';

import { usePrevious } from '_utils/hooks';

const ONE_TRUST_COOKIE_PANEL_ID = 'onetrust-consent-sdk';
const ONE_TRUST_CONSENT_UPDATE_EVENT = 'OneTrustGroupsUpdated';
const ONE_TRUST_CONSENT_COOKIE_NAME = 'OptanonConsent';
const ONE_TRUST_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed';
const ONE_TRUST_DEFAULT_ACTIVE_GROUPS = ',,';

// Cookies to exclude when clearing all cookies on consent change
const ONE_TRUST_COOKIE_PURGE_EXCLUDE_LIST = [
  ONE_TRUST_CLOSED_COOKIE_NAME, // remembers that the user has closed the banner initially
  ONE_TRUST_CONSENT_COOKIE_NAME, // persists the consent that the user has given. this cookie is legal to keep around
];

const purgeBrowserData = (onComplete) => {
  localStorage.clear();
  sessionStorage.clear();

  const currentCookies = Object.keys(cookies.get());
  const cookiesToDelete = currentCookies.filter(cookie => !ONE_TRUST_COOKIE_PURGE_EXCLUDE_LIST.includes(cookie));

  // Make a best effort to remove as many cookies as possible
  // As we can only remove cookies of the same domain, ensure that the other cookies are no longer used by scripts if no longer consented to
  if (cookiesToDelete.length > 0) {
    cookiesToDelete.forEach((cookie, i, arr) => {
      cookies.remove(cookie);

      // .www.cpaaustralia.com.au
      cookies.remove(cookie, { domain: `.${location.hostname}` });

      // www.cpaaustralia.com.au
      cookies.remove(cookie, { domain: `${location.hostname}` });

      // .cpaaustralia.com.au
      cookies.remove(cookie, { domain: `${location.hostname.replace(/^[^.]+/, '')}` });

      if (i === (arr.length - 1)) {
        onComplete();
      }
    });
  } else {
    // we still want the page to refresh in case scripts need to reinit
    onComplete();
  }
};

export default function useOneTrustActiveGroups() {
  if (!canUseDOM) {
    return {
      activeGroups: ONE_TRUST_DEFAULT_ACTIVE_GROUPS,
      hasActiveGroupsChanged: false,
      isCookieBannerClosed: false,
    };
  }

  const [activeGroups, setActiveGroups] = useState(window?.OptanonActiveGroups ?? ONE_TRUST_DEFAULT_ACTIVE_GROUPS);
  const [isCookieBannerClosed, setIsCookieBannerClosed] = useState(!!cookies.get(ONE_TRUST_CLOSED_COOKIE_NAME));
  const [isOneTrustScriptReady, setIsOneTrustScriptReady] = useState(false);

  useEffect(() => {
    // To avoid any race conditions with OneTrust mounting the DOM element,
    // (as unfortunately the ONE_TRUST_CONSENT_UPDATE_EVENT fires BEFORE the DOM element is mounted)
    // observe when this element arrives in the body and report when the script is truly ready
    if (window?.MutationObserver) {
      let observer = new MutationObserver(() => {
        // Cookie panel is always mounted on the page by OneTrust, and this is how we tell the script is ready
        if (document.getElementById(ONE_TRUST_COOKIE_PANEL_ID)) {
          observer.disconnect();
          setIsOneTrustScriptReady(true);
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false,
      });

      return () => {
        observer.disconnect();
      }
    }
  }, []);

  useEffect(() => {
    // If option to force users to re-consent to cookies is selected in OneTrust console,
    // OneTrust script does NOT clear ONE_TRUST_CLOSED_COOKIE_NAME, so we manually ensure it here
    if (
      isOneTrustScriptReady
      && !!cookies.get(ONE_TRUST_CLOSED_COOKIE_NAME)
      && document.getElementById(COOKIE_BANNER_ID)
    ) {
      // Flush the old cookie - we'll get a fresh one baked once the user reconsents
      cookies.remove(ONE_TRUST_CLOSED_COOKIE_NAME);
      setIsCookieBannerClosed(false);
    }
  }, [isOneTrustScriptReady]);

  const prevActiveGroups = usePrevious(activeGroups);

  const hasActiveGroupsChanged = useMemo(() => {
    const hasActiveGroupsInitialized =
      activeGroups
      && prevActiveGroups
      && (activeGroups !== ONE_TRUST_DEFAULT_ACTIVE_GROUPS)
      && (prevActiveGroups !== ONE_TRUST_DEFAULT_ACTIVE_GROUPS);

    if (hasActiveGroupsInitialized) {
      return prevActiveGroups !== activeGroups;
    }

    return false
  }, [activeGroups]);

  useEffect(() => {
    if (hasActiveGroupsChanged) {
       // Remove all existing cookies and storage just in case some remains that the user has not consented to
      purgeBrowserData(() => {
        // Force a reload to ensure all page scripts and tags reinit under the new consent groups
        location.reload();
      });
    }
  }, [hasActiveGroupsChanged]);

  useEffect(() => {
    const handleConsentUpdate = () => {
      setActiveGroups(window?.OptanonActiveGroups ?? ONE_TRUST_DEFAULT_ACTIVE_GROUPS);
      setIsCookieBannerClosed(!!cookies.get(ONE_TRUST_CLOSED_COOKIE_NAME));
    };

    window.addEventListener(ONE_TRUST_CONSENT_UPDATE_EVENT, handleConsentUpdate);

    return () => {
      window.removeEventListener(ONE_TRUST_CONSENT_UPDATE_EVENT, handleConsentUpdate);
    };
  }, []);

  return {
    activeGroups,
    hasActiveGroupsChanged,
    isCookieBannerClosed,
    isOneTrustScriptReady,
  };
};
