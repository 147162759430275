import React from 'react';
import { JsonLd } from 'react-schemaorg';
import {
  WithContext,
} from 'schema-dts';

import { StructuredDataProps } from './definitions';

const StructuredData: React.FC<StructuredDataProps> = ({ context, route }) => {

  if (!context?.site?.name) {
    return null;
  }

  const contributors = route?.fields?.contributors || [];
  const author = contributors[0]?.fields?.fullName?.value ?? '';
  const datePublished = route?.fields?.published?.value ?? '';
  const headline = route?.fields?.pageTitle?.value ?? '';
  const image = route?.fields?.featuredImage?.value?.src ?? '';
  const url = context?.canonicalUrl ?? '';
  const logoUrl = 'https://cpaaustralia.com.au/dist/corporate/images/logo.jpg';

  const websiteData: WithContext<any> = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": url
    },
    "headline": headline,
    "image": [
      image,
    ],
    "datePublished": datePublished,
    "author": {
      "@type": "Person",
      "name": author
    },
    "publisher": {
      "@type": "Organization",
      "name": "CPA Australia",
      "logo": {
        "@type": "ImageObject",
        "url": logoUrl
      }
    }
  }

  return (
    <>
      <JsonLd item={websiteData} />
    </>
  );
};

export default StructuredData;
