import { spacing } from '_utils/props';
import styled from 'styled-components';
import { hideOnPrint } from '_utils/styles';

export const Container = styled.div`
  text-align: center;
  ${({ theme }) => spacing({ theme, mx: 'auto' })}
`;

export const AdContainer = styled.div`
  ${hideOnPrint}
`;

export const PlaceholderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.tint2};
  padding: 30px 20px;
  text-align: center;

  span {
    font-weight: bold;
  }
`;

export const AdLabel = styled.div`
  color: ${({ theme }) => theme.colors.text.subtle};
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  ${({ theme }) => spacing({ theme, my: '8px', })}

  ${hideOnPrint}
`;
