import colors from './colorOptions';

import button from './button';
import link from './link';

export default {
  all: colors,
  background: {
    darkBlue: colors.brandPrimary3,
    disabled: colors.brandPrimary1Dark,
    tint1: colors.neutralWhite,
    tint2: colors.neutral50,
    tint3: colors.neutral100,
    tint4: colors.neutral200,
    tint5: colors.neutral300
  },
  button: button.colors,
  dna: {
    default: colors.brandSecondary1Dark
  },
  element: {
    danger: colors.error500,
    focused: colors.focus,
    focusedGrey: colors.neutralWhite,
    neutralBlack: colors.neutralBlack,
    neutralWhite: colors.neutralWhite,
    primary: colors.brandPrimary1,
    primary2: colors.brandPrimary2,
    primary3: colors.brandPrimary3,
    secondary: colors.brandSecondary1
  },
  featureList: {
    cross: colors.error500,
    tick: colors.brandSecondary3
  },
  icon: {
    color: colors.interactive500
  },
  interactiveCell: {
    focus: colors.brandPrimary1,
    hover: colors.neutral50,
    pressed: colors.neutral200,
    selected: colors.neutral100
  },
  link,
  messaging: {
    backgroundError: colors.error100,
    backgroundErrorDark: colors.error700,
    backgroundInfo: colors.information100,
    backgroundNeutral: colors.neutral50,
    backgroundSuccess: colors.success100,
    backgroundWarning: colors.warning100,
    colorError: colors.error500,
    colorInfo: colors.information500,
    colorNeutral: colors.neutral300,
    colorSuccess: colors.success500,
    colorWarning: colors.warning500
  },
  modal: {
    close: {
      fill: colors.neutralWhite,
      fillHover: colors.neutralWhite
    },
    container: {
      background: colors.brandPrimary3
    },
    scrollThumb: {
      background: colors.brandPrimary1Dark
    }
  },
  status: {
    error: colors.error500,
    info: colors.information500,
    warning: colors.warning500
  },
  table: {
    cell: {
      border: colors.neutralWhite
    },
    head: {
      background: colors.brandPrimary3,
      color: colors.neutralWhite
    },
    row: {
      background: colors.neutralWhite,
      backgroundAlt: colors.neutral50
    }
  },
  text: {
    contrast: colors.neutral900,
    default: colors.neutral700,
    disabled: colors.neutral300,
    interactive1: colors.interactive500,
    interactive1Hover: colors.interactive600,
    interactive1Pressed: colors.neutral600,
    nonessential: colors.neutral400,
    secondary: colors.neutral600,
    subtle: colors.neutral500,
    neutral: colors.neutralWhite,
    dark: {
      default: colors.darkText,
      secondary: colors.darkTextSecondary
    },
    visited: colors.visited
  },
  ui: {
    accent1: colors.brandSecondary3,
    defaultBorderColor: colors.neutral200,
    divider: colors.neutral200,
  },
  brand: {
    orange: {
      secondary: colors.brandSecondary2,
    },
  }
}