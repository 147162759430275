import { withSitecoreContext, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from 'exenv';
import { compose, omit } from 'ramda'
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import { DISMISSED_ANNOUNCEMENTS_COOKIE_NAME } from '_components/Announcements/definitions';

import { withDigitalDataContext } from '_containers/DigitalDataContext';
import { UserRestrictedAccess } from '_containers/DigitalDataContext/definitions';
import { withMemberContext } from '_containers/MemberContext';

import {
  emitTrackEvent,
  setAnalyticsStaffUserRole,
  setObjectData,
} from '_utils/helpers/analytics';
import { warnInDev } from '_utils/helpers/dev';
import {
  MUTATING_CLASS,
  STABLE_CLASS,
} from '_utils/helpers/render';
import { useScrollDepthAnalyticsTracking } from '_utils/hooks';

import { MetadataProps } from './definitions';
import {
  setSection,
} from './helpers';
import cpaLogo from '../../assets/cpa.png';

const getCustomMetaTags = (customMetaTags: string): JSX.Element[] => {
  let metaTags: JSX.Element[] = [];

  if (customMetaTags) {
    let tags = customMetaTags.split('&');
    tags.forEach((tag) => {
      if (tag.indexOf('=') !== -1) {
        const tagParts = tag.split('=');
        const extraTag = <meta name={tagParts[0]} content={tagParts[1] || ''} />;
        metaTags.push(extraTag);
      }
    });
  }

  return metaTags;
}

const Head: React.FC<MetadataProps> = (props) => {
  /**
   * On Page Load, focus H1. Does not subscribe to a trigger.
   */
   useEffect(() => {
    
    const firstHeadingOne: null | HTMLHeadElement = document.querySelector("h1");
    
    if(firstHeadingOne !== null){
      firstHeadingOne.tabIndex = -1;
      firstHeadingOne.focus();
    }
  }, []);

  const {
    dashboardApi,
    digitalData,
    rendering,
    setDigitalData,
    sitecoreContext,
  } = props;
  
  const route = sitecoreContext?.route || null;
  const securityInfo = sitecoreContext?.securityInfo || {};
  const fields = (route && route.fields) || {};
  const seoTitle: string = getFieldValue(fields, 'seoTitle') || '';
  const seoDescription: string = getFieldValue(fields, 'seoDescription') || '';
  const restrictAccessTo: Array<UserRestrictedAccess> = fields?.RestrictAccessTo || []; // using `getFieldValue` fn returns null ??
  const pageTitle: string = getFieldValue(fields, 'pageTitle') || '';
  const pageDescription: string = getFieldValue(fields, 'pageDescription') || '';
  const pageListingDescription: string = getFieldValue(fields, 'pageListingDescription') || '';
  const language: string = getFieldValue(fields, 'languageType') || 'en';
  const listingImage: any = getFieldValue(fields, 'listingImage') || null;
  const socialSharingImage: any = getFieldValue(fields, 'socialSharingImage') || null;
  const noFollow: boolean = getFieldValue(fields, 'noFollow') || false;
  const seoCanonicalUrl: boolean = getFieldValue(fields, 'seoCanonicalUrl') || null;
  const customMetaTags: string = getFieldValue(fields, 'customTags') || null;
  const extraMetaTags = getCustomMetaTags(customMetaTags);
  const canonicalUrl: string = seoCanonicalUrl || sitecoreContext?.canonicalUrl || null;
  const dataSource = rendering?.fields?.data?.datasource || null;
  const adobeLaunchScriptURL: string = dataSource?.adobeLaunchConfigurationItem?.jss?.fields?.URL?.value ?? '';
  const titleSiteName: string = dataSource?.titleSiteName?.value || null;
  const ogSiteName: string = dataSource?.ogSiteName?.value || null;
  const twitterCreator: string = dataSource?.twitterCreator?.value || null;
  const twitterSite: string = dataSource?.twitterSite?.value || null;
  const member = dashboardApi?.result?.data;
  const isLoading = dashboardApi?.result?.isLoading;
  const apiCallRunning = dashboardApi?.result?.apiCallRunning;

  // Set the title to the seoTitle, falling back to the pageTitle if not set or if set to the site title
  let title = seoTitle;
  if (!title || title === titleSiteName) title = pageTitle;

  // Append the site title
  if (title && title !== titleSiteName) {
    title = title + " | " + titleSiteName;
  } else {
    title = titleSiteName;
  }

  const description = seoDescription || pageDescription;

  let image = socialSharingImage?.src || listingImage?.src || cpaLogo;

  // set authentication info
  const authenticatedContent = !!restrictAccessTo?.length;
  const authenticatedContentPermissions = restrictAccessTo
    .map(userType => userType?.name || userType?.displayName)
    .join(',');

  const { pathname } = useLocation();
  // reset fields that shouldn't persist through SPA
  useEffect(() => {
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        omit([
          'accordion',
          'carousel',
          'download',
          'form',
          'link',
          'search',
          'section',
        ], digitalData));
    }

    // reset mutation observer classes
    if (canUseDOM) {
      document.body.classList.remove(MUTATING_CLASS);
      document.body.classList.remove(STABLE_CLASS);
    }

  }, [pathname]);

  // update Adobe Analytics `digitalData` obj
  useEffect(() => {
    // only interested in first topic of page
    let primaryTopic = '';
    let enterpriseTopic = '';
    if (route?.fields?.Topics?.length) {
      const primaryTopicItem = route?.fields?.Topics[0];
      primaryTopic = primaryTopicItem?.fields?.displayName?.value || primaryTopicItem?.name;
      enterpriseTopic = primaryTopicItem?.fields?.enterpriseTopic?.displayName;
    }

    if (typeof setDigitalData === 'function') {

      const updatedPageData = setObjectData(
        ['page'],
        {
          authenticatedContent,
          authenticatedContentPermissions,
          pageDescription: description,
          pageListingDescription: pageListingDescription,
          pageName: title,
          pageurl: pathname,
          siteSection: canUseDOM && pathname && `cpa:${setSection(pathname)}`,
          language: language,
          pageType: route?.fields?.analyticsPageType?.value || route?.templateName || '',
          primaryTopic: primaryTopic,
          enterpriseTopic: enterpriseTopic
        },
        digitalData
      );

      //  update what we can from Sitcore Context (ie. without needing to wait for API resolve)
      const updatedMemberData = setObjectData(
        ['member'],
        {
          ...updatedPageData.member,
          loginStatus: !securityInfo?.isAnonymous ? 'logged in' : 'not logged in',
          profileType: securityInfo?.profileTypes ?? '',
          userType: setAnalyticsStaffUserRole(securityInfo?.userRole)
        },
        updatedPageData
      );

      const updatedDigitalData = {
        ...digitalData,
        ...updatedPageData,
        ...updatedMemberData
      };

      setDigitalData(
        updatedDigitalData
      );

      // track page views (for SPA)
      if (window?.ssrRenderComplete) {
        emitTrackEvent('pageView');
      }
    }

    // Ignore changes to pathname it happens before title
    // has changed, instead wait for title to change before
    // tracking page view. Assumes no two page titles are the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  // expose `digitalData` in namespaced window obj
  // for ease of debugging by analytics team
  useEffect(() => {
    if (canUseDOM) {
      window.cpa = window.cpa || {};
      window.cpa.digitalData = digitalData;
    }
  }, [digitalData]);


  // update user info
  useEffect(() => {
    // only dispatch `memberStatus` if within dashboard area
    const isDashboardArea =
      canUseDOM
      && (location?.pathname ?? '').indexOf('/dashboard') === 0;
      
    if (
      canUseDOM
      && isDashboardArea
      && typeof setDigitalData === 'function'
      && (member !== null || securityInfo?.userRole === "extranet\\Staff")
      && isLoading !== null
      && isLoading !== true
      && apiCallRunning!== false
    ) {
      // delay firing event to allow time for window.OptanonActiveGroups
      setTimeout(() => {
        // ensure page data updated first
        const updatedPageData = setObjectData(
          ['page'],
          {
            authenticatedContent,
            authenticatedContentPermissions,
            pageDescription: description,
            pageListingDescription: pageListingDescription,
            pageName: title,
            pageurl: pathname,
            siteSection: pathname && `cpa:${setSection(pathname)}`,
            language: language,
            pageType: route?.fields?.analyticsPageType?.value || route?.templateName || '',
          },
          digitalData
        );

        // update member info
        const updatePageDataWithMember = setObjectData(
          ['member'],
          {
            ...digitalData.member,
            countryRegion: member?.contact?.countryName ?? '',
            countryArea: member?.contact?.countryArea ?? '',
            stateProvince: member?.contact?.stateName ?? '',
            cpaID: member?.contact?.cpaAustraliaId ?? '',
            cpdhoursPendingByTriennium: member?.cpdTracker?.hoursPendingByTriennium ?? '',
            cpdRequired: member?.cpdTracker?.cpdRequired ?? '',
            cpdtrienniumEndDate: member?.cpdTracker?.trienniumEndDate ?? '',
            designation: member?.contact?.designation ?? '',
            division: member?.division?.divisionName ?? '',
            loginStatus: !securityInfo?.isAnonymous ? 'logged in' : 'not logged in',
            profileType: securityInfo?.profileTypes ?? '',
            userType: setAnalyticsStaffUserRole(securityInfo?.userRole),
          },
          updatedPageData
        );

        setDigitalData(
          updatePageDataWithMember
        );

        emitTrackEvent('memberStatus');
      }, 3000);
    }
  }, [isLoading, title, canUseDOM]);

  const renderDigitalData = () => {
    try {
      if (typeof digitalData !== 'undefined') {
        return JSON.stringify(digitalData, null, 2);
      }
      return {};
    }
    catch (err) {
      warnInDev(err);
    }
  };

  // Dispatch 50% scroll depth analytics
  const dispatchHalfPageScrollEmitTrack = () => emitTrackEvent('scroll50');
  useScrollDepthAnalyticsTracking(dispatchHalfPageScrollEmitTrack, 50);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {noFollow && <meta name="robots" content="noindex, follow" />}
        <meta property="og:title" content={title} />
        {description && <meta property="og:description" content={description} />}
        {image && <meta property="og:image" content={image} />}
        {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
        {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
        <meta property="og:type" content="website" />
        {image && <meta name="twitter:card" content="summary_large_image" />}
        {!image && <meta name="twitter:card" content="summary" />}
        <meta name="twitter:title" content={title} />
        {description && <meta name="twitter:description" content={description} />}
        {image && <meta name="twitter:image" content={image} />}
        {twitterSite && <meta name="twitter:site" content={twitterSite} />}
        {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {extraMetaTags}

        {
          adobeLaunchScriptURL && (
            <script type="text/javascript">
              {`//prehiding snippet for Adobe Target with asynchronous Launch deployment
              (function(g,b,d,f){(function (a, c, d) { if (a) { var e = b.createElement("style"); e.id = c; e.innerHTML = d; a.appendChild(e) } })(b.getElementsByTagName("head")[0], "at-body-style", d);setTimeout(function(){var a=b.getElementsByTagName("head")[0];if(a){var c=b.getElementById("at-body-style");c&&a.removeChild(c)}},f)})(window,document,"body {opacity: 0 !important}",1E3);
              `}
            </script>
          )
        }

        {
          adobeLaunchScriptURL && (
            <script src={adobeLaunchScriptURL} async></script>
          )
        }

        {/* Site-wide Announcements Dismissal Handler - split out as it relies on a cookie in the 'C0003 / Functional' category. */}
        <script type="text/plain" className="optanon-category-C0003">
          {
            `
              function setAnnouncementDismissed (id) {
                if (typeof document !== 'undefined') {
                  var DISMISSED_ANNOUNCEMENTS_COOKIE_NAME = '${DISMISSED_ANNOUNCEMENTS_COOKIE_NAME}';
                  var DISMISSED_ANNOUNCEMENTS_EXPIRY_IN_DAYS = 30;

                  function _getDismissedAnnouncementsCookieValue() {
                    var dismissedAnnouncementsCookie = document.cookie
                      .split('; ')
                      .find(row => row.startsWith(DISMISSED_ANNOUNCEMENTS_COOKIE_NAME + '='));

                    return dismissedAnnouncementsCookie
                      ? dismissedAnnouncementsCookie.split('=')[1]
                      : false;
                  };

                  var dismissedAnnouncementsCookieValue = _getDismissedAnnouncementsCookieValue();

                  var dismissedAnnouncementIds = dismissedAnnouncementsCookieValue
                    ? decodeURIComponent(dismissedAnnouncementsCookieValue).split(',')
                    : [];

                  var isIDAlreadyDismissed = dismissedAnnouncementIds && (dismissedAnnouncementIds.indexOf(id) > -1);

                  if (id && !isIDAlreadyDismissed) {
                    var expiry = new Date(Date.now() + DISMISSED_ANNOUNCEMENTS_EXPIRY_IN_DAYS * 864e5).toUTCString();

                    document.cookie =
                      DISMISSED_ANNOUNCEMENTS_COOKIE_NAME
                      + '='
                      + encodeURIComponent(dismissedAnnouncementIds.concat(id).toString())
                      + '; expires='
                      + expiry;
                  };
                }
              };

              window.setAnnouncementDismissed = setAnnouncementDismissed;
            `
          }
        </script>

        {adobeLaunchScriptURL && (
          <script>
            {`var digitalData = ${renderDigitalData()};`}
          </script>
        )}
      </Helmet>
    </>
  );
};

export default compose(withDigitalDataContext, withMemberContext, withSitecoreContext())(Head);
