import React from 'react';

import {
  TagContainer,
} from './StyledTag';

import { TagProps } from './definitions';


const Tag: React.FC<TagProps> = ({
  children,
  className,
  size = 'small',
  tagVariant
}) => (
  <TagContainer
    className={className}
    size={size}
    tagVariant={tagVariant}
  >
    {children}
  </TagContainer>
);

export default Tag;
