import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  Grid,
  Link,
} from '_utils/components';

import {
  avoidPageBreakOnPrint,
  button,
  hideOnPrint,
  link,
  linkWithoutVisited,
  mq,
  svg,
  textBody2
} from '_utils/styles';
import { spacing } from '_utils/props';

export const Header = styled.div`
  border-bottom: ${({ theme }) => theme.featureSectionWithAd?.header?.border};
  color: ${({ theme }) => theme.featureSectionWithAd.color};
  ${({ theme }) => spacing({ theme, pb: 3, mb: { xs: 3, md: 4, lg: 6 } })}

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.featureSectionWithAd.headingColor ? theme.featureSectionWithAd.headingColor : null};
    margin-bottom: 0;
  }
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-grow: 0;
  ${({ theme }) => spacing({
  theme,
  px: { xs: 0 },
  pb: 6
})}

  .left & {
    ${({ theme }) => spacing({ theme, pl: { md: 3 } })};
  }

  .right & {
    ${({ theme }) => spacing({ theme, pr: { md: 3 } })};
  }

  padding-top: 0;

  @media print {
    display: inline-block !important;
    margin-right: 2%;
    max-width: 23%;
  }
`;

export const Container = styled.div`
  // override global spacing rules as required
  &.removeBottomPadding {
    margin-bottom: 12px !important;
  }
`;

export const AdContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.background.tint2};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;

  ${mq('md')} {
    width: 224px;
    ${({ order }) => `order:${order};`}
  }

  ${mq('lg')} {
    width: 304px;
  }

  @media print {
    display: none;
  }
`;

export const CardContainer = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;

  /* beat specificity of grid breakpoints */
  && {
    margin-top: 0;
  }

  ${avoidPageBreakOnPrint}

  @media print {
    display: block;
    width: 100%;
  }

  .noTopBorder {
    border-top: 0;
  }
`;

export const FooterLinkContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const FooterLink = styled(NavLink)`
  ${link};
`;

export const FooterLinkLabel = styled(Text)`
  ${textBody2}
`;

export const ArrowWrapper = styled.span`
  ${svg(14, 14)}
  margin-left: 6px;
  margin-right: 2px;
  width: 14px;

  path {
    fill: ${({ theme }) => theme.featureSectionWithAd.action};
  }
`;

export const LinkButton = styled(Link)`
  a {
    ${linkWithoutVisited}
    ${button('slim', 'tertiary')}
    width: 100%;

    svg {
      top: 2px;
    }

    ${mq('md')} {
      width: auto;
    }
  }
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4, mb: 7 })};

  ${mq.lessThan('md')} {
    border-top: 1px solid ${({ theme }) => theme.colors.ui.divider};
    ${({ theme }) => spacing({ theme, pt: { xs: 6 }, mt: { xs: 0 } })};
  }

  &.hideOnMobile {
    display: none;
  }

  ${mq('md')} {
    &.hideOnMobile {
      display: block;
    }

    &.showOnMobile {
      display: none;
    }

   ${({ theme }) => spacing({ theme, my: 0 })};
  }

  ${hideOnPrint}
`;
