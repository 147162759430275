export const materialIcons = [
"accessibility_new",
"accessible_forward",
"access_time",
"account_balance_wallet",
"account_balance",
"account_circle",
"add_circle_outline",
"add_shopping_cart",
"add_task",
"add",
"addchart",
"admin_panel_settings",
"alarm",
"analytics",
"announcement",
"apartment",
"arrow_back",
"arrow_downward",
"arrow_drop_down",
"arrow_drop_up",
"arrow_forward",
"arrow_left",
"arrow_right",
"arrow_upward",
"article",
"aspect_ratio",
"assessment",
"assignment_ind",
"assignment_late",
"assignment_turned_in",
"assignment",
"attach_email",
"attach_file",
"attach_money",
"autorenew",
"apps",
"backup_table",
"backup",
"beach_access",
"book",
"bookmark_border",
"bookmarks",
"build_circle",
"build",
"business_center",
"cached",
"cake",
"calculate",
"calendar_today",
"call",
"campaign",
"cancel",
"card_travel",
"chat",
"check_circle",
"check",
"chevron_left",
"chevron_right",
"chrome_reader_mode",
"class",
"clear",
"close",
"closed_caption",
"cloud_download",
"cloud_upload",
"cloud",
"comment_bank",
"commute",
"construction",
"contact_page",
"contact_support",
"contactless",
"copyright",
"coronavirus",
"credit_card",
"dashboard",
"delete_forever",
"delete",
"description",
"design_services",
"desktop_mac",
"dns",
"do_disturb_alt",
"domain",
"done",
"donut_large",
"download_done",
"download",
"drafts",
"eco",
"edit",
"elderly",
"email",
"emoji_events",
"emoji_objects",
"emoji_people",
"emoji_symbols",
"error_outline",
"euro_symbol",
"event_available",
"event_busy",
"event_note",
"expand_less",
"expand_more",
"explore",
"fact_check",
"family_restroom",
"favorite_border",
"feedback",
"filter_alt",
"find_in_page",
"fingerprint",
"flag",
"flight_land",
"flight_takeoff",
"folder_shared",
"folder",
"follow_the_signs",
"forum",
"free_breakfast",
"gavel",
"get_app",
"golf_course",
"grade",
"groups",
"headset_mic",
"hearing_disabled",
"hearing",
"help_center",
"help_outline",
"highlight_off",
"history",
"home_work",
"home",
"hourglass_empty",
"how_to_reg",
"how_to_vote",
"history_edu",
"info",
"input",
"insert_invitation",
"insights",
"label",
"language",
"laptop_mac",
"leaderboard",
"lightbulb",
"link",
"list",
"local_activity",
"local_airport",
"local_library",
"local_offer",
"lock_open",
"lock",
"login",
"loop",
"luggage",
"mail",
"map",
"mark_chat_unread",
"mediation",
"meeting_room",
"menu_book",
"menu",
"mic_none",
"mic_off",
"military_tech",
"miscellaneous_services",
"mode",
"more_horiz",
"more_vert",
"movie",
"new_movie",
"nature_people",
"near_me",
"new_releases",
"nights_stay",
"not_listed_location",
"not_started",
"notifications_none",
"notifications_off",
"open_in_new",
"pan_tool",
"pause",
"payment",
"pending_actions",
"pending",
"people",
"perm_identity",
"perm_media",
"person_add_alt_1",
"person_outline",
"person_pin_circle",
"person_remove",
"person_search",
"personal_video",
"phone_iphone",
"phonelink",
"photo_camera",
"pie_chart",
"place",
"play_arrow",
"poll",
"power_settings_new",
"print",
"privacy_tip",
"psychology",
"public",
"push_pin",
"question_answer",
"queue_music",
"record_voice_over",
"redeem",
"refresh",
"report",
"request_page",
"request_quote",
"rule",
"receipt",
"save_alt",
"save",
"schedule",
"school",
"search",
"security",
"sentiment_satisfied",
"settings_ethernet",
"settings_remote",
"settings",
"share",
"shopping_bag",
"shopping_cart",
"sms_failed",
"sms",
"source",
"spa",
"sports",
"stars",
"sticky_note_2",
"storefront",
"subdirectory_arrow_right",
"supervised_user_circle",
"supervisor_account",
"support_agent",
"support",
"swap_horiz",
"swap_horizontal_circle",
"swap_vert",
"swap_vertical_circle",
"sync",
"table_view",
"tablet_mac",
"thumb_down",
"thumb_up",
"thumbs_up_down",
"timeline",
"toc",
"track_changes",
"train",
"translate",
"trending_down",
"trending_flat",
"trending_up",
"tune",
"turned_in_not",
"upload",
"verified_user",
"verified",
"vertical_split",
"view_list",
"visibility_off",
"visibility",
"voice_over_off",
"volume_off",
"volume_up",
"vpn_lock",
"warning_amber",
"wb_sunny",
"wifi",
"work_outline",
"zoom_in",
"zoom_out"
] as const;

export const customIcons = [
  "excel",
  "facebook",
  "file",
  "image",
  "instagram",
  "linked_in",
  "movie",
  "pdf",
  "tiktok",
  "weibo",
  "threads",
  "twitter",
  "wechat",
  "word",
  "youtube",
  "zip"
] as const;

export type CustomIcons = typeof customIcons[number];
export type MaterialIcons = typeof materialIcons[number];