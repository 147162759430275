import styled from 'styled-components';

import { spacing } from '_utils/props';
import {
  svg,
  button,
  iconColorOnPrint,
} from '_utils/styles';

export const SearchMenuContainer = styled.div`
  overflow: hidden;
`;

export const SearchMenuHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.searchMenu.searchMenuHeader.borderColor};

  ${({ theme }) => spacing({ theme, pl: 3, pr: 0, py: 1 })}
`;

export const MainSearchWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  width: 100%;
`;

export const CloseButton = styled.button`
  ${button('default', 'text')}

  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 8px;
  padding: 0 26px;
  width: 24px;
`;

export const CloseIcon = styled.span`
  ${svg(14, 14)}
  flex: none;
  width: 19px;

  path {
    fill: ${({ theme }) => theme.searchMenu.closeIcon.pathFillColor};
  }

  ${iconColorOnPrint}
`;