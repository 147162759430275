import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { ApiProxy } from '_utils/helpers/apiProxy';
import { ApiResult } from '_utils/helpers/apiProxy/definitions';
import { Optionalize } from '_utils/types';
import { ChildrenPropTypes } from '../../types';

import {
  CareerProfile,
  CareerProfileSubmission,
  Companies,
  CompanyIndustries,
  CompanySubmission,
  ContactProfile,
  ContactProfileSubmission,
  Member,
  MemberContextProps,
  MemberProviderProps,
  Renewal,
  RenewalNotice,
  RenewalSubmission,
} from './definitions';

export const MemberContext = createContext<MemberContextProps>({});

const MemberProvider: React.FC<MemberProviderProps> = ({
  children,
  apiHost,
  companiesApiHost
}) => {
  const sitecoreContext: any = useContext(SitecoreContextReactContext);

  const isLoggedIn: boolean = useMemo(
    () => !sitecoreContext?.context?.securityInfo?.isAnonymous,
    [sitecoreContext?.context?.securityInfo?.isAnonymous],
  );

  const initialApiResultState = {
    apiCallRunning: false,
    data: null,
    error: null,
    isLoading: false,
    postResponse: null,
  };

  const [member, setMember] = useState<ApiResult<Member>>(initialApiResultState);
  const [careerProfile, setCareerProfile] = useState<ApiResult<CareerProfile>>(initialApiResultState);
  const [careerProfileSubmission, setCareerProfileSubmission] = useState<ApiResult<CareerProfileSubmission>>(initialApiResultState);
  const [companies, setCompanies] = useState<ApiResult<Companies>>(initialApiResultState);
  const [companyIndustries, setCompanyIndustries] = useState<ApiResult<CompanyIndustries>>(initialApiResultState);
  const [companySubmission, setCompanySubmission] = useState<ApiResult<CompanySubmission>>(initialApiResultState);
  const [contactProfile, setContactProfile] = useState<ApiResult<ContactProfile>>(initialApiResultState);
  const [contactProfileSubmission, setContactProfileSubmission] = useState<ApiResult<ContactProfileSubmission>>(initialApiResultState);
  const [renewal, setRenewal] = useState<ApiResult<Renewal>>(initialApiResultState);
  const [renewalSubmission, setRenewalSubmission] = useState<ApiResult<RenewalSubmission>>(initialApiResultState);
  const [renewalNotice, setRenewalNotice] = useState<ApiResult<RenewalNotice>>(initialApiResultState);

  // APIs
  const dashboardApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/Dashboard`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: member,
    setResult: setMember,
  });

  const careerProfileApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/CareerProfile`,
    axiosRequestConfig: {
      method: 'GET',
      params: {
        employment: true,
        education: true,
        licences: true,
        professionalMemberships: true,
        specialityAreas: true,
        referenceData: true,
        professionalInterests: true
      },
    },
    isLoggedIn,
    result: careerProfile,
    setResult: setCareerProfile,
  });

  const careerProfileSubmissionApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/CareerProfile`,
    axiosRequestConfig: {
      method: 'PATCH',
      params: {
        OnlyEmployment: false,
      },
    },
    isLoggedIn,
    result: careerProfileSubmission,
    setResult: setCareerProfileSubmission,
  });

  const companiesApi = ApiProxy({
    apiUri: `${companiesApiHost ?? ''}/api/company/Companies`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: companies,
    setResult: setCompanies,
  });
  const companyIndustriesApi = ApiProxy({
    apiUri: `${companiesApiHost ?? ''}/api/company/Industries`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: companyIndustries,
    setResult: setCompanyIndustries,
  });
  const companySubmissionApi = ApiProxy({
    apiUri: `${companiesApiHost ?? ''}/api/company/Company`,
    axiosRequestConfig: {
      method: 'POST',
    },
    isLoggedIn,
    result: companySubmission,
    setResult: setCompanySubmission,
  });

  const contactProfileApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/ContactDetail`,
    axiosRequestConfig: {
      method: 'GET',
      params: {
        contact: true,
        billingAddress: true,
        shippingAddress: true,
        referenceData: true,
      },
    },
    isLoggedIn,
    result: contactProfile,
    setResult: setContactProfile,
  });

  const contactProfileSubmissionApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/ContactDetail`,
    axiosRequestConfig: {
      method: 'PATCH',
      params: {
        // testing to see if this works
        contact: true,
        billingAddress: true,
        shippingAddress: true,
        referenceData: true,
      },
    },
    isLoggedIn,
    result: contactProfileSubmission,
    setResult: setContactProfileSubmission,
  });

  // For the new renew page

  const renewContactProfileDataApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/ContactDetail`,
    axiosRequestConfig: {
      method: 'GET',
      params: {
        referenceData: true,
        contact: true,
        billingAddress: false,
        shippingAddress: false,
      },
    },
    isLoggedIn,
    result: contactProfile,
    setResult: setContactProfile,
  }); 


  const renewContactProfileSubmissionApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/ContactDetailForRenewal`,
    axiosRequestConfig: {
      method: 'PATCH',
      params: {
        // testing to see if this works
        contact: true,
        billingAddress: false,
        shippingAddress: false,
        referenceData: false,
      },
    },
    isLoggedIn,
    result: contactProfileSubmission,
    setResult: setContactProfileSubmission,
  });

  // Renew Career profile api 
  const renewCareerProfileApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/CareerProfile`,
    axiosRequestConfig: {
      method: 'GET',
      params: {
        employment: true,
        education: false,
        licences: false,
        professionalMemberships: false,
        specialityAreas: false,
        referenceData: true,
        professionalInterests: false
      },
    },
    isLoggedIn,
    result: careerProfile,
    setResult: setCareerProfile,
  });


  // Renew Career profile submit only employment api 
  const renewCareerProfileSubmitApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/CareerProfile`,
    axiosRequestConfig: {
      method: 'PATCH',
      params: {
        OnlyEmployment: true,
      },
    },
    isLoggedIn,
    result: careerProfileSubmission,
    setResult: setCareerProfileSubmission,
  });
  
  const renewalApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/Renewal`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: renewal,
    setResult: setRenewal,
  });
  const renewalSubmissionApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/Renewal`,
    axiosRequestConfig: {
      method: 'POST',
    },
    isLoggedIn,
    result: renewalSubmission,
    setResult: setRenewalSubmission,
  });

  const renewalNoticeApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/member/RenewalNotice`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: renewalNotice,
    setResult: setRenewalNotice,
  });

  // Selectors
  const renewalProductsTotalAmount = useMemo(() => {
    if (!renewalApi?.result?.isLoading) {
      return renewalApi?.result?.data?.products
        ?.filter(product => !product.isOptional) // Ignore optional products
        ?.map(product => product.totalAmount)
        ?.reduce((sum, val) => sum + val, 0)
        ?.toFixed(2)
        ?? '';
    } else {
      return '';
    }
  }, [renewalApi?.result?.isLoading]);

  // get currency symbol and currency code from first array item in products
  const renewalProductsCurrency = useMemo(() => {
    if (!renewalApi?.result?.isLoading) {
      return renewalApi?.result?.data?.products
        .map(product => product.currency)
        .find(({ isoCode }) => isoCode);
    } else {
      return null;
    }
  }, [renewalApi?.result?.isLoading]);

  // exclude optional products returning just the product codes
  const renewalProductCodes = useMemo(() => {
    if (!renewalApi?.result?.isLoading) {
      return renewalApi?.result?.data?.products
        ?.filter(product => !product.isOptional) // Ignore optional products
        .map(product => product?.code);
    } else {
      return [];
    }
  }, [renewalApi?.result?.isLoading]);

  // For faster dashboard loading, call the dashboard API here rather than within a useEffect per component
  // (also needed ASAP for analytics reporting in the Metadata component on every route)
    useEffect(() => {
      dashboardApi?.request();
    }, []);
  
  return (
    <MemberContext.Provider
      value={{
        careerProfileApi,
        careerProfileSubmissionApi,
        companiesApi,
        companyIndustriesApi,
        companySubmissionApi,
        contactProfileApi,
        contactProfileSubmissionApi,
        dashboardApi,
        renewalApi,
        renewalSubmissionApi,
        renewalNoticeApi,
        renewalProductCodes,
        renewalProductsCurrency,
        renewalProductsTotalAmount,
        // For new renew page
        renewContactProfileDataApi,
        renewContactProfileSubmissionApi,
        renewCareerProfileApi,
        renewCareerProfileSubmitApi
      }}
    >{children}
    </MemberContext.Provider>
  );
};

MemberProvider.propTypes = {
  children: ChildrenPropTypes
};

export default MemberProvider;

export const MemberConsumer = MemberContext.Consumer;

export const withMemberContext = <T extends MemberContextProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withMemberContext: React.FC<Optionalize<T, MemberContextProps>> = (props) => (
    <MemberContext.Consumer>
      {(value) => <WrappedComponent {...value} {...(props as T)} />}
    </MemberContext.Consumer>
  );

  withMemberContext.displayName = `withMemberContext(${displayName})`;

  return withMemberContext;
};
