import { AnalyticsDataProps } from '_containers/DigitalDataContext/definitions';

import {
  Declaration,
  MemberContextProps,
} from '_containers/MemberContext/definitions';

import { RenewalPaymentURLRequest } from '_components/MMFMembershipRenewalForm2023/definitions';

export const FORM_STICKY_FOOTER_ID = 'form-sticky-footer';

export type LinkTrackingEvent = (name: string, destination: string) => void;

export type FormStickyFooterProps = AnalyticsDataProps & MemberContextProps & {
  editMode: boolean;
  sitecoreContext: any;
  declaration: Declaration;
  isDeclarationConfirmed?: boolean;
  isDeclarationRequired?: boolean;
  isRedirectingToPaymentProvider?: boolean;
  noticeDescription?: string;
  noticeHeading?: string;
  paymentURLPostReqPayload?: RenewalPaymentURLRequest;
  setIsDeclarationConfirmed?: () => void;
  setIsRedirectingToPaymentProvider?: (boolean) => void;
  renewalInvoiceLink?: string;
  currentStep: number;
  removeQueryParam: () => void;
};
