export const getThemeClasses = (params) => {
  const removeTopPadding = params?.removeTopPadding || false;
  const removeBottomPadding = params?.removeBottomPadding || false;

  const classes = [];

  if (removeTopPadding) {
    classes.push('no-pt');
  }
  if (removeBottomPadding) {
    classes.push('no-pb');
  }

  return classes.join(' ')
}
