import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { withMenuContext } from '_containers/MenuContext';
import NavItem from '_components/NavItem';
import Icon from '_components/Icon';
import { warnInDev } from '_utils/helpers/dev';
import { isValidNavLink } from '_utils/validationChecks';

import {
  MenuItemHandlerProps,
  MenuItemProps
} from './definitions';

import {
  Description,
  GroupLabelWrapper,
  IconWrapper,
  Item,
  Label,
  LabelWrapper,
  Link,
  LinkEndIcon,
  LinkWrapper,
  List,
  MenuCloseButton,
  MenuDivider,
  MenuLinkButton,
  MenuSubBody,
  NavToolbar,
  NavToolbarBack,
  NavToolbarClose,
} from './StyledMainMenu';
import Logout from '_components/Logout';

const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
  ({
    item,
    isSubItem,
    menuActive,
    submenuActive,
    onBlur,
    onFocus,
    onItemClick,
    tabIndex,
    useLevel2Title
  }, ref) => {
    if (!item) {
      return null;
    }

    let label = item.title?.value || item?.link?.text || item?.link?.fallbackTitle || '';
    if (useLevel2Title) {
      label = item.level2LinkTitle?.value || label
    }
    const description = !isSubItem && item.description?.value ? item.description?.value : '';
    const hasChildren = isSubItem ? false : item?.secondaryNavigation?.length;
    const buttonClass = item?.buttonClass?.value;
    const iconClass = item?.iconClass?.targetItem?.name;

    // nullify links without urls
    const link = isValidNavLink(item) ? item.link : null;

    // only create handlers if we have a link
    const handlers: MenuItemHandlerProps = link
      ? {
        'aria-hidden': !menuActive,
        'aria-expanded': submenuActive,
        onBlur,
        onClick: onItemClick,
        onFocus,
        onKeyDown: hasChildren ? onItemClick : () => { },
        tabIndex: tabIndex,
        target: item?.link?.target || ''
      }
      : null;

    return (
      <>
        {buttonClass ? (
          <LinkWrapper>
            <NavItem {...handlers} className={buttonClass} ref={ref} link={link} StyledLink={MenuLinkButton}>
              {label && label}
              {iconClass && (
                <IconWrapper position="after">
                  <Icon name={iconClass} ariaHidden={true} />
                </IconWrapper>
              )}
            </NavItem>
          </LinkWrapper>
        ) : (
            <NavItem {...handlers} ref={ref} link={link} StyledLink={Link} className={description ? 'with-description' : ''}>
              {!description && iconClass && (
                <IconWrapper position="before">
                  <Icon name={iconClass} />
                </IconWrapper>
              )}
              <LabelWrapper>
                {label && (
                  <Label>
                    {description && iconClass && (
                      <IconWrapper position="before">
                        <Icon name={iconClass} />
                      </IconWrapper>
                    )}
                    {label}
                  </Label>
                )}
                {description && <Description>{description}</Description>}
              </LabelWrapper>
              {hasChildren ? (
                <LinkEndIcon>
                  <Icon name="chevron_right" ariaHidden={true} />
                </LinkEndIcon>
              ) : (
                  <LinkEndIcon showOnHover={true}>
                    <Icon name="arrow_forward" ariaHidden={true} />
                  </LinkEndIcon>
                )}
            </NavItem>
          )}
      </>
    );
  }
);


const MenuItemSub = forwardRef<HTMLElement, any>(
  ({
    items,
    enabledItemRefs,
    enabledItemsIndex,
    getEnabledItemsChildIndex,
    keyPrefix,
    menuActive,
    onBlur,
    onItemClick,
    onItemFocus,
    subNavTabIndex,
    submenuActive
  }, ref) => {

    if (!items) {
      return null;
    }

    return (
      <>
        {items.map((child, index) => {
          const groupLinks = child?.groupSecondarylinks;
          const groupTitle = child?.groupTitle?.value;
          const childItems = groupLinks.length ? groupLinks : [child];
          const newKeyPrefix = `${keyPrefix}-${index}`;

          return (
            <React.Fragment key={`fragment-${newKeyPrefix}`}>
              {groupLinks && groupTitle && (
                <Item key={`label-${newKeyPrefix}`}>
                  <GroupLabelWrapper>{groupTitle}</GroupLabelWrapper>
                </Item>
              )}
              {childItems.map((childItem, i) => {
                const enabledChildIndex = getEnabledItemsChildIndex(enabledItemsIndex, childItem);
                const spacing = !!(childItem?.addSpacingBelow?.value) || false;
                const childRef = enabledItemRefs.current[enabledItemsIndex].children[enabledChildIndex];

                // If item doesnt have a ref, return early
                if (!childRef) {
                  warnInDev('Link does not have valid url');
                  return null;
                }

                return (
                  <React.Fragment key={`fragment-${newKeyPrefix}-${i}`}>
                    <Item key={`${newKeyPrefix}-${i}`}>
                      <MenuItem
                        item={childItem}
                        menuActive={menuActive}
                        onBlur={onBlur}
                        onFocus={(e) => {
                          onItemFocus(e, enabledItemsIndex, enabledChildIndex)
                        }}
                        onItemClick={onItemClick}
                        ref={childRef}
                        tabIndex={subNavTabIndex}
                        isSubItem={true}
                        submenuActive={submenuActive}
                      />
                    </Item>
                    {spacing && (
                      <MenuDivider key={`divider-${keyPrefix}-${index}-${i}`} />
                    )}
                  </React.Fragment>
                )
              })
              }
              {!!(child?.addSpacingBelow?.value) && (
                <MenuDivider key={`divider-${keyPrefix}-${index}`} />
              )}
            </React.Fragment>
          )
        })}
      </>
    );
  },
);

const MenuItemWrapper = forwardRef<HTMLElement, any>(
  ({
    activeSubNav,
    enabledItemRefs,
    enabledItemsIndex,
    getEnabledItemsChildIndex,
    item,
    isDesktop,
    keyPrefix,
    menuActive,
    onBlur,
    onItemClick,
    onItemFocus,
    onItemHover,
    onParentItemClick,
    resetMenu
  }, ref) => {
    if (!item) {
      return null;
    }
    // TODO: Replace useTranslation() to getDictionaryItem()
    const [t] = useTranslation();
    const children = item?.secondaryNavigation || [];
    const currentRef = enabledItemRefs?.current[enabledItemsIndex];
    const shouldHaveSubmenu = !!(children.length);
    const isSubNavActive = !!(shouldHaveSubmenu && activeSubNav === enabledItemsIndex);
    const subNavTabIndex = isSubNavActive ? 0 : -1;
    const spacing = !!(item?.addSpacingBelow?.value) || false;
    const mouseOverEffect = () => onItemHover && isDesktop
      ? onItemHover.callback(activeSubNav, shouldHaveSubmenu ? enabledItemsIndex : -1)
      : () => { };

    // props that need to be passed to subnav items
    const subnavItemProps = {
      enabledItemRefs: enabledItemRefs,
      enabledItemsIndex: enabledItemsIndex,
      getEnabledItemsChildIndex: getEnabledItemsChildIndex,
      menuActive: menuActive,
      onBlur: onBlur,
      onItemClick: onItemClick,
      onItemFocus: onItemFocus,
      subNavTabIndex: subNavTabIndex,
    }

    if (!currentRef) {
      warnInDev(`Link does not have valid url: ${item?.title?.value}`);
      return null;
    }

    const classList = [];

    if (isSubNavActive) {
      classList.push('submenu-active');
    }
    if (item?.className) {
      classList.push(item.className);
    }

    return (
      <React.Fragment key={`fragment-${keyPrefix}`}>
        <Item
          className={classList.join(' ')}
          onMouseEnter={() => mouseOverEffect()}
          key={`menu-${keyPrefix}`}
        >
          <MenuItem
            item={item}
            menuActive={menuActive}
            onBlur={onBlur}
            onFocus={(e) => {
              onItemFocus(e, enabledItemsIndex, -1)
            }}
            onItemClick={shouldHaveSubmenu ? onParentItemClick : onItemClick}
            ref={ref}
            submenuActive={isSubNavActive}
            tabIndex={menuActive ? 0 : -1}
          />
          {shouldHaveSubmenu && (
            <>
              <MenuSubBody
                active={menuActive && isSubNavActive}
                className={'submenu'}
              >
                <NavToolbar level="secondary">
                  <NavToolbarBack onClick={onParentItemClick} tabIndex="-1" aria-label={t('menu-back-label')}>
                    <Icon name="arrow_back" ariaHidden={true} />
                    {t('menu-back-label')}
                  </NavToolbarBack>
                  <NavToolbarClose>
                    <MenuCloseButton onClick={resetMenu} tabIndex="0" aria-label="close menu" />
                  </NavToolbarClose>
                </NavToolbar>
                <List ref={currentRef?.subNavWrapper}>
                  {/* Repeated first nav item key=menu-sub-primary-0*/}
                  <Item key={`menu-sub-${keyPrefix}`}>
                    <MenuItem
                      item={item}
                      isSubItem={true}
                      menuActive={menuActive}
                      onBlur={onBlur}
                      onFocus={(e) => {
                        onItemFocus(e, enabledItemsIndex, 0)
                      }}
                      onItemClick={onItemClick}
                      ref={currentRef?.children && currentRef?.children[0]}
                      tabIndex={menuActive ? 0 : -1}
                      useLevel2Title={true}
                      submenuActive={isSubNavActive}
                    />
                  </Item>
                  <MenuItemSub
                    key={`menu-sub-${keyPrefix}-${enabledItemsIndex}`}
                    keyPrefix={`menu-sub-${keyPrefix}-${enabledItemsIndex}`}
                    items={children}
                    submenuActive={isSubNavActive}
                    {...subnavItemProps}
                  />
                </List>
              </MenuSubBody>
            </>
          )}
        </Item>
        {spacing && (
          <MenuDivider key={`menu-divdier-${keyPrefix}`} />
        )}
      </React.Fragment>
    );
  },
);

const MenuItemList = ({
  activeSubNav,
  enabledItemRefs,
  getEnabledItemsChildIndex,
  getEnabledItemsIndex,
  isDesktop,
  keyPrefix,
  menuActive,
  menuItems,
  onItemBlur,
  onItemClick,
  onItemHover,
  onItemFocus,
  onParentItemClick,
  resetMenu,
}) => menuItems.map((item, index) => {
  const enabledItemsIndex = getEnabledItemsIndex(item);

  const enabledItemProps =
    enabledItemsIndex > -1
      ? {
        ref: enabledItemRefs?.current[enabledItemsIndex]?.uid,
        onItemFocus: onItemFocus,
        onBlur: onItemBlur
      }
      : {};

  return (
  (item?.title?.value === "Sign out") ? 
    <Logout item={item}/> : 
    <MenuItemWrapper
      activeSubNav={activeSubNav}
      enabledItemRefs={enabledItemRefs}
      enabledItemsIndex={enabledItemsIndex}
      getEnabledItemsChildIndex={getEnabledItemsChildIndex}
      item={item}
      isDesktop={isDesktop}
      key={`${keyPrefix}-${index}`}
      keyPrefix={`${keyPrefix}-${index}`}
      menuActive={menuActive}
      onItemClick={onItemClick}
      onItemHover={onItemHover}
      onParentItemClick={(e) => onParentItemClick(e, enabledItemsIndex)}
      resetMenu={(e) => resetMenu(e)}
      {...enabledItemProps}
    />
  );
});

export default compose(withSitecoreContext(), withMenuContext)(MenuItemList);

