// intentionally importing Corporate as only difference is heading level
// ie. colors remain as per Corporate
import { FONT_FAMILY_TEXT } from '_containers/Theme/global';
import { headingH4 } from '_utils/styles';

import corporateVariantSettings from './variantSettings';

export default {
  ...corporateVariantSettings,
  primaryLink: {
    fontFamily: FONT_FAMILY_TEXT,
    fontWeight: '700 !important', // override weight defined in utils/styles/headingH4
    level: headingH4,
  },
};
