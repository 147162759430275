import personalisedCarousel from '_components/PersonalisationCarousel/variantSettings';
import accordion, { darkMode as accordionDarkTheme } from '_components/Accordion/variantSettings';
import agendaAccordion, { darkMode as agendaAccordionDarkTheme } from '_components/AgendaAccordion/variantSettings';
import bppAssessment from '_components/MMFBPPAssessment/variantSettings';
import breadcrumb from '_components/Breadcrumb/variantSettings';
import informationSession from '_components/InformationSession/variantSettings';
import callToAction from '_components/CallToAction/variantSettings';
import campaignNavigation from '_components/CampaignNavigation/variantSettings';
import campaignPageBanner from '_components/CampaignPageBanner/variantSettings';
import cardList, { darkMode as cardListDarkTheme } from '_components/CardList/variantSettings';
import cardListInTheBlackBanner from '_components/CardListInTheBlackBanner/variantSettings';
import { darkMode as cardListWithInternalNavigation } from '_components/CardListWithInternalNavigation/variantSettings';
import contactDetailsForm from '_components/MMFContactDetailsForm/variantSettings';
import contentRelated from '_components/ContentRelated/variantSettingsInTheBlack';
import contentRichText from '_components/ContentRichText/variantSettings';
import contentSummary from '_components/ContentSummary/variantSettings';
import cpdTracker from '_components/CPDTracker/variantSettings';
import dateLinkList, { darkMode as dateLinkListDarkTheme } from '_components/DateLinkList/variantSettings';
import documentList from '_components/DocumentList/variantSettings';
import form from '_components/Form/variantSettings';
import homepageBanner from '_components/HomepageBanner/variantSettings';
import imageCardGrid from '_components/ImageCardGrid/variantSettings';
import imageWithTitleAndText from '_components/ImageWithTitleAndText/variantSettings';
import inPageAnnouncement from '_components/Announcements/InPageAnnouncement/variantSettings';
import internalPageBanner from '_components/InternalPageBanner/variantSettings';
import lateralNavigation from '_components/LateralNavigation/variantSettings';
import linkList from '_components/LinkList/variantSettings';
import liveChat from '_components/LiveChat/variantSettings';
import magazineBanner from '_components/MagazineBanner/variantSettings';
import multiMediaEmbed from '_components/MultiMediaEmbed/variantSettings';
import mainMenu from '_components/MainMenu/variantSettings';
import mainSearch from '_components/MainSearch/variantSettings';
import masonryGrid, { darkMode as masonryGridDarkTheme } from '_components/MasonryGrid/variantSettings';
import memberDetails from '_components/MMFMemberDetails/variantSettings';
import memberRenewalForm from '_components/MMFMembershipRenewalForm/variantSettings';
import modal from '_components/Modal/variantSettings';
import pageBanner from '_components/PageBanner/variantSettings';
import pageBannerWithInternalNavigation from '_components/PageBannerWithInternalNavigation/variantSettings';
import pageFooter from '_components/PageFooter/variantSettings';
import pageHeader from '_components/PageHeader/variantSettings';
import placeholderDashboardSingleColumn from '_components/PlaceholderDashboardSingleColumn/variantSettings';
import profilePageBanner from '_components/ProfilePageBanner/variantSettings';
import programTracker from '_components/ProgramTracker/variantSettings';
import searchMenu from '_components/SearchMenu/variantSettings';
import searchResults from '_components/SearchResults/variantSettings';
import singleImage from '_components/SingleImage/variantSettings';
import siteWideAnnouncement from '_components/Announcements/SiteWideAnnouncement/variantSettings';
import staticTextAndImage from '_components/StaticTextAndImage/variantSettings';
import stepTracker from '_utils/components/StepTracker/variantSettings';
import tabs, { darkMode as tabsDarkTheme } from '_components/Tabs/variantSettings';
import timezoneSelector from '_components/TimezoneSelector/variantSettings';
// utils
import carousel from '_utils/components/Carousel/variantSettings';
import headingWithDescription from '_utils/components/HeadingWithDescription/variantSettings';
import spinner from '_utils/components/Spinner';
import tag from '_utils/components/Tag/variantSettings';
import complexFormElement from '_utils/components/Form/variantSettings';
import radio from '_utils/components/Radio/variantSettings';
import lastUpdated from '_utils/components/LastUpdated/variantSettings';
import showMore from '_utils/components/ShowMore/variantSettings';
import tooltip, { darkMode as tooltipDarkTheme } from '_utils/components/Tooltip/variantSettings';
// styles
import card from '_utils/styles/card/variantSettings';
import section, { darkMode as sectionDarkTheme } from '_utils/styles/section/variantSettings';

import button from './button';
import colors from './colors';
import { FONT_FAMILY_TEXT } from './global';
import link from './link';

import {
  motion,
  shadows,
} from '../shared';

import { ITheme } from './definitions';

const Theme: ITheme = {
  personalisedCarousel,
  accordion,
  agendaAccordion,
  borderRadius: {
    default: {
      lg: 0,
      md: 14,
      sm: 10
    }
  },
  bppAssessment,
  button,
  breadcrumb,
  informationSession,
  callToAction,
  campaignNavigation,
  campaignPageBanner,
  card,
  cardList,
  cardListInTheBlackBanner,
  cardListWithInternalNavigation,
  carousel,
  colors: colors,
  content: {
    indent: {
      lg: 44,
      md: 36,
      xs: 32
    }
  },
  contentRelated,
  complexFormElement,
  contactDetailsForm,
  contentRichText,
  contentSummary,
  cpdTracker,
  dateLinkList,
  documentList,
  focus: {
    height: 'calc(100% + 8px)',
    left: '-4px',
    outlineColor: colors.all.interactive300,
    outlineOffset: '2px',
    outlineStyle: 'solid',
    outlineWidth: '2px',
    radius: '3px',
    top: '-4px',
    width: 'calc(100% + 8px)'
  },
  form,
  grid: {
    borderColor: colors.brandPrimary1,
    borderWidth: '1px',
    colCount: 12,
    containerMaxWidth: 1024,
    containerMinWidth: 280,
    gutter: {
      directions: ['left', 'right', 'top', 'bottom'],
      size: {
        lg: 24,
        md: 24,
        xs: 20
      }
    },
    margin: {
      lg: 32,
      md: 24,
      xs: 20
    }
  },
  headingWithDescription,
  homepageBanner,
  imageCardGrid,
  imageWithTitleAndText,
  inPageAnnouncement,
  internalPageBanner,
  lastUpdated,
  lateralNavigation,
  link,
  linkList,
  liveChat,
  magazineBanner,
  multiMediaEmbed,
  mainMenu,
  mainSearch,
  masonryGrid,
  memberDetails,
  memberRenewalForm,
  modal,
  motion,
  pageBanner,
  pageBannerWithInternalNavigation,
  pageFooter,
  pageHeader,
  placeholderDashboardSingleColumn,
  profilePageBanner,
  programTracker,
  responsiveSpacing: {
    lg: {
      '0': 0,
      '1': 8,
      '2': 16,
      '3': 24,
      '4': 32,
      '5': 40,
      '6': 48,
      '7': 64,
      '8': 80,
      '9': 120
    },
    md: {
      '0': 0,
      '1': 8,
      '2': 16,
      '3': 24,
      '4': 32,
      '5': 40,
      '6': 48,
      '7': 64,
      '8': 80,
      '9': 120
    },
    xs: {
      '0': 0,
      '1': 8,
      '2': 12,
      '3': 16,
      '4': 16,
      '5': 24,
      '6': 24,
      '7': 32,
      '8': 40,
      '9': 64
    }
  },
  radio,
  searchMenu,
  searchResults,
  section,
  shadows,
  showMore,
  spinner,
  singleImage,
  siteWideAnnouncement,
  staticTextAndImage,
  stepTracker,
  table: {
    font: {
      fontFamily: FONT_FAMILY_TEXT,
      // same as body2
      fontSize: {
        lg: 0,
        md: 16,
        sm: 14
      },
      lineHeight: {
        lg: 0,
        md: 20,
        sm: 16
      },
      spacing: 16
    }
  },
  tabs,
  tag,
  timezoneSelector,
  tooltip,
  typography: {
    headings: {
      caption1: {
        fontSize: 14,
        lineHeight: 14
      },
      caption2: {
        fontSize: 12,
        lineHeight: 12
      },
      fixedTitle12: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 16,
        spacing: 24
      },
      fixedTitle14: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 18,
        spacing: 24
      },
      fixedTitle16: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 20,
        spacing: 24
      },
      fixedTitle18: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 24,
        spacing: 24
      },
      H0: { // only used in HomepageBanner currently as oversized H1
        fontSize: {
          lg: 64,
          md: 45,
          sm: 40
        },
        lineHeight: {
          lg: 72,
          md: 54,
          sm: 48
        }
      },
      H1: {
        fontSize: {
          lg: 48,
          md: 40,
          sm: 32
        },
        lineHeight: {
          lg: 54,
          md: 48,
          sm: 38
        }
      },
      H2: {
        fontSize: {
          lg: 36,
          md: 32,
          sm: 28
        },
        lineHeight: {
          lg: 44,
          md: 38,
          sm: 32
        }
      },
      H3: {
        fontSize: {
          lg: 28,
          md: 24,
          sm: 24
        },
        lineHeight: {
          lg: 32,
          md: 28,
          sm: 28
        }
      },
      H4: {
        fontSize: {
          lg: 22,
          md: 20,
          sm: 20
        },
        lineHeight: {
          lg: 26,
          md: 24,
          sm: 24
        }
      },
      H5: {
        fontSize: {
          lg: 16,
          md: 14,
          sm: 14
        },
        lineHeight: {
          lg: 20,
          md: 18,
          sm: 18
        }
      },
      // same as H5 - attempt backward compaitble for any
      // previously authored content using H6
      H6: {
        fontSize: {
          lg: 16,
          md: 14,
          sm: 14
        },
        lineHeight: {
          lg: 20,
          md: 18,
          sm: 18
        }
      },
      subheading1: {
        fontSize: {
          lg: 24,
          md: 22,
          sm: 20
        },
        fontWeight: 300,
        lineHeight: {
          lg: 32,
          md: 30,
          sm: 28
        }
      },
      subheading2: {
        fontSize: {
          lg: 18,
          md: 18,
          sm: 16
        },
        fontWeight: 300,
        lineHeight: {
          lg: 24,
          md: 24,
          sm: 20
        }
      },
      title: {
        fontSize: {
          lg: 64,
          md: 48,
          sm: 36
        },
        fontWeight: 300,
        lineHeight: {
          lg: 64,
          md: 48,
          sm: 36
        }
      }
    },
    text: {
      // normal body
      body1: {
        fontSize: {
          lg: 16,
          md: 16,
          sm: 16
        },
        lineHeight: {
          lg: 28,
          md: 28,
          sm: 24
        },
        spacing: 24
      },
      // smaller body
      body2: {
        fontSize: {
          lg: 14,
          md: 14,
          sm: 14
        },
        lineHeight: {
          lg: 20,
          md: 24,
          sm: 20
        },
        spacing: 24
      },
      fixedBody12: {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 16,
        spacing: 24
      },
      fixedBody14: {
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 18,
        spacing: 24
      },
      fixedBody16: {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 20,
        spacing: 24
      },
      fixedBody18: {
        fontSize: 18,
        fontWeight: 'normal',
        lineHeight: 24,
        spacing: 24
      },
      tag16: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 24,
        spacing: 0
      },
      tag14: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 24,
        spacing: 0
      },
      tag12: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 18,
        spacing: 0
      },
      tag10: {
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: 12,
        spacing: 0
      }
    }
  }
};

export const DarkTheme = {
  ...Theme,
  agendaAccordion: {
    ...agendaAccordion,
    ...agendaAccordionDarkTheme,
  },
  accordion: {
    ...accordion,
    ...accordionDarkTheme,
  },
  cardList: {
    ...cardList,
    ...cardListDarkTheme
  },
  cardListWithInternalNavigation: cardListWithInternalNavigation,
  dateLinkList: {
    ...dateLinkList,
    ...dateLinkListDarkTheme
  },
  masonryGrid: {
    ...masonryGrid,
    ...masonryGridDarkTheme
  },
  section: {
    ...section,
    ...sectionDarkTheme
  },
  tabs: {
    ...tabs,
    ...tabsDarkTheme
  },
  tooltip: {
    ...tooltip,
    ...tooltipDarkTheme
  },
  multiMediaEmbed: {
    ...multiMediaEmbed
  }
};

export default Theme;
