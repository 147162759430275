import colors from '_containers/Theme/corporate/colors';

const whiteCard = {
  background: colors.all.neutralWhite,
  backgroundHover: colors.all.neutral50,
  color: colors.all.brandPrimary3,
  iconFill: colors.all.brandPrimary1,
  shadow: 16
};

export default {
  ctaIconFill: colors.all.neutralWhite,
  blueCard: {
    background: colors.all.brandPrimary1,
    backgroundHover: colors.all.brandPrimary1Dark,
    color: colors.all.neutralWhite,
    iconFill: colors.all.brandPrimary2,
  },
  whiteCard: whiteCard,
  yellowCard: {
    background: colors.all.brandPrimary2,
    backgroundHover: colors.all.brandPrimary2Dark,
    color: colors.all.brandPrimary3,
    iconFill: colors.all.brandPrimary3,
  },
  functionalCardContainer: {
    outlineColor: colors.element.focused
  }
};

export const darkMode = {
  descriptionColor: colors.all.darkText,
  headingColor: colors.all.neutralWhite,
  whiteCard: {
    ...whiteCard,
    shadow: null
  },
};
