import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';

import Grid from '_utils/components/Grid';

import {
  aspect,
  avoidPageBreakOnPrint,
  mq,
  textBody2
} from '_utils/styles';

const container = css`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq('md')} {
    height: 100%;
  }
`;

export const PlaceholderCard = styled(Grid.Item)``;

export const Card = styled.a`
  ${container}
  display: flex;
  flex-direction: column;
  height: auto;

  ${avoidPageBreakOnPrint}
`;

export const ImageContainer = styled.div`
  transition-duration: ${({ theme }) => theme.motion.timing.fast};
  transition-property: box-shadow;
  transition-timing-function: ${({ theme }) => theme.motion.ease};

  ${aspect(304, 382)}
  position: relative;
  width: 100%;
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const Title = styled.h5`
  ${textBody2}
  color: ${({ theme }) => theme.colors.text.contrast};
  flex-grow: auto;
  font-weight: 800;
  margin-bottom: 0 !important;
  margin-top: 16px;
  text-align: center;
`;
