import colors from '_containers/Theme/intheblack/colors';

export default {
  background: colors.all.neutral50,
  backgroundActive: colors.all.neutralWhite,
  border: `1px solid ${colors.all.neutral200}`,
  borderActive: `2px solid ${colors.all.interactive500}`,
  iconFill: colors.all.neutral400,
  inputBorderRadius: '4px 0 0 4px',
  inputBorderRadiusLessThanMd: '4px',
  outlineColour: colors.all.interactive300,
  clear: {
    borderRadius: '0',
    height: '-16px',
    iconFill: colors.all.neutral500,
    left: '-2px',
    top: '9px',
    width: '-8px',
  },
  submit: {
    background: colors.all.neutral200,
    backgroundActive: colors.all.brandPrimary1,
    borderHover: colors.all.interactive500,
    borderRadius: '0 4px 4px 0',
    height: '8px',
    iconFill: colors.all.neutral700,
    iconFillActive: colors.all.neutralWhite,
    left: '-2px',
    minWidth: '48px',
    padding: '0',
    top: '-4px',
    width: '6px',
  },
};
