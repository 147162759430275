import { compose } from 'ramda';
import React from 'react';

import { withEditMode } from '_containers/BaseComponent';

import ContributorInfo from './ContributorInfo/ContributorInfo';
import PublishDate from './PublishDate/PublishDate';
import ReadingTime from './ReadingTime/ReadingTime';

import {
  ContributorContainer,
  DateReadingTimeContainer,
  StyledSeparator,
  Wrapper,
} from './StyledArticleInfo';

import {
  ArticleInfoProps,
  ContributorInfoProps
} from './definitions';

const Separator = (props) => <StyledSeparator className={props?.hideOnMobile ? 'hideOnMobile' : ''}>&#183;</StyledSeparator>;

const ArticleInfo: React.FC<ArticleInfoProps> = (
  {
    editMode,
    fields,
    uid,
  }) => {
  const contributors: ContributorInfoProps[] = fields?.data?.datasource?.contributors?.targetItems ?? [];
  const published = fields?.data?.datasource?.published;
  const readingTime = fields?.data?.datasource?.readingTime;

  return (
    <Wrapper
      className="articleInfo"
      data-component
      id={uid}
    >
      <ContributorContainer>
        {contributors.map((item) =>
        (
          <ContributorInfo
            editMode={editMode}
            id={item?.id}
            image={item?.image}
            key={item?.id}
            profileName={item?.profileName}
            url={item?.url}
          />
        )
        )}
      </ContributorContainer>

      <DateReadingTimeContainer>
        {contributors?.length ? <Separator hideOnMobile={true} /> : null}
        <PublishDate {...published} />
        {published && readingTime?.value && <Separator />}
        {readingTime?.value && <ReadingTime {...readingTime} />}
      </DateReadingTimeContainer>
    </Wrapper>
  );
}

export default compose(
  withEditMode,
)(ArticleInfo);
