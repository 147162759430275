import { ITypographySetting, IFixedTypographySetting } from '_containers/Theme/definitions';
import { spacing } from '_utils/props';
import { mq } from '_utils/styles/';

function headingStyle(settings: ITypographySetting) {
  return `
    ${settings.fontFamily ? `font-family: ${settings.fontFamily};` : ''}
    ${settings.fontSize ? `font-size: ${settings.fontSize.sm}px;` : ''}
    ${settings.fontWeight ? `font-weight: ${settings.fontWeight};` : ''}
    ${settings.lineHeight ? `line-height: ${settings.lineHeight.sm}px;` : ''}
    ${({ theme }) => spacing({ theme, my: 0 })}
    margin-bottom: 0;
    margin-top: 0;


    ${mq('md')} {
      ${settings.fontSize ? `font-size: ${settings.fontSize.md}px;` : ''}
      ${settings.fontWeight ? `font-weight: ${settings.fontWeight};` : ''}
      ${settings.lineHeight ? `line-height: ${settings.lineHeight.md}px;` : ''}
    }

    ${mq('lg')} {
      ${settings.fontSize ? `font-size: ${settings.fontSize.lg}px;` : ''}
      ${settings.fontWeight ? `font-weight: ${settings.fontWeight};` : ''}
      ${settings.lineHeight ? `line-height: ${settings.lineHeight.lg}px;` : ''}
    }
  `
}

function bodyStyle(settings: ITypographySetting) {
  return `
    ${settings.fontFamily ? `font-family: ${settings.fontFamily};` : ''}
    ${settings.fontSize ? `font-size: ${settings.fontSize.sm}px;` : ''}
    ${settings.fontWeight ? `font-weight: ${settings.fontWeight};` : ''}
    ${settings.lineHeight ? `line-height: ${settings.lineHeight.sm}px;` : ''}
    ${settings.textTransform ? `text-transform: ${settings.textTransform};` : ''}

    ${mq('md')} {
      ${settings.fontSize ? `font-size: ${settings.fontSize.md}px;` : ''}
      ${settings.lineHeight ? `line-height: ${settings.lineHeight.md}px;` : ''}
    }

    ${mq('lg')} {
      ${settings.fontSize ? `font-size: ${settings.fontSize.lg}px;` : ''}
      ${settings.lineHeight ? `line-height: ${settings.lineHeight.lg}px;` : ''}
    }

    p {
      margin-bottom: ${settings.spacing || '16'}px;
    }
  `;
}

function fixedStyle(settings: IFixedTypographySetting) {
  return `
    ${settings.fontFamily ? `font-family: ${settings.fontFamily};` : ''}
    ${settings.fontSize ? `font-size: ${settings.fontSize}px;` : ''}
    ${settings.fontWeight ? `font-weight: ${settings.fontWeight};` : ''}
    ${settings.lineHeight ? `line-height: ${settings.lineHeight}px;` : ''}
  `;
}

function fixedBodyStyle(settings: IFixedTypographySetting) {
  return `
    ${fixedStyle(settings)}
    ${settings.lineHeight ? `line-height: ${settings.lineHeight}px;` : ''}
  `;
}

const marginBottom1 = (theme) => spacing({ theme, mb: 1 });
const marginBottom2 = (theme) => spacing({ theme, mb: 2 });
const marginBottom3 = (theme) => spacing({ theme, mb: 3 });

export const pageTitle = ({ theme }) => `${headingStyle(theme.typography.headings.title)}`;
export const headingH0 = ({ theme }) => `${headingStyle(theme.typography.headings.H0)}`;
export const headingH1 = ({ theme }) => `${headingStyle(theme.typography.headings.H1)}${marginBottom3(theme)}`;
export const headingH2 = ({ theme }) => `${headingStyle(theme.typography.headings.H2)}${marginBottom3(theme)}`;
export const headingH3 = ({ theme }) => `${headingStyle(theme.typography.headings.H3)}${marginBottom2(theme)}`;
export const headingH4 = ({ theme }) => `${headingStyle(theme.typography.headings.H4)}${marginBottom2(theme)}`;
export const headingH5 = ({ theme }) => `${headingStyle(theme.typography.headings.H5)}${marginBottom1(theme)}`;
export const headingH6 = ({ theme }) => `${headingStyle(theme.typography.headings.H6)}`;
export const subheading1 = ({ theme }) => `${headingStyle(theme.typography.headings.subheading1)}${marginBottom2(theme)}`;
export const subheading2 = ({ theme }) => `${headingStyle(theme.typography.headings.subheading2)}`;
// fixed headings
export const fixedTitle20 = ({ theme }) => `${fixedStyle(theme.typography.headings.fixedTitle20)}`;
export const fixedTitle18 = ({ theme }) => `${fixedStyle(theme.typography.headings.fixedTitle18)}`;
export const fixedTitle16 = ({ theme }) => `${fixedStyle(theme.typography.headings.fixedTitle16)}`;
export const fixedTitle14 = ({ theme }) => `${fixedStyle(theme.typography.headings.fixedTitle14)}`;
export const fixedTitle12 = ({ theme }) => `${fixedStyle(theme.typography.headings.fixedTitle12)}`;
export const caption1 = ({ theme }) => `${fixedBodyStyle(theme.typography.headings.caption1)}`;
export const caption2 = ({ theme }) => `${fixedBodyStyle(theme.typography.headings.caption2)}`;
// fixed text
export const fixedBody18 = ({ theme }) => `${fixedBodyStyle(theme.typography.text.fixedBody18)}`;
export const fixedBody16 = ({ theme }) => `${fixedBodyStyle(theme.typography.text.fixedBody16)}`;
export const fixedBody14 = ({ theme }) => `${fixedBodyStyle(theme.typography.text.fixedBody14)}`;
export const fixedBody12 = ({ theme }) => `${fixedBodyStyle(theme.typography.text.fixedBody12)}`;
export const articleBody = ({ theme }) => `${bodyStyle(theme.typography?.text?.articleBody)}`;
export const textBody1 = ({ theme }) => `${bodyStyle(theme.typography?.text?.body1)}`;
export const textBody2 = ({ theme }) => `${bodyStyle(theme.typography?.text?.body2)}`;

// tags
const upperCase = 'text-transform: uppercase;';
export const tag16 = ({ theme }) => `
  ${fixedStyle(theme.typography?.text?.tag16)}
  ${upperCase}
`;
export const tag14 = ({ theme }) => `
  ${fixedStyle(theme.typography?.text?.tag14)}
  ${upperCase}
`;
export const tag12 = ({ theme }) => `
  ${fixedStyle(theme.typography?.text?.tag12)}
  ${upperCase}
`;
export const tag10 = ({ theme }) => theme?.typography?.text?.tag10 ? `${fixedStyle(theme.typography?.text?.tag10)}` : {};
