import styled from 'styled-components';

import depth from '_utils/styles/depth';
import { spacing } from '_utils/props';
import {
  buttonReset,
  color,
  mq,
  subheading2,
  svg,
} from '_utils/styles';

export const Heading = styled.h5`
  ${subheading2}
  color: ${color('text.default')};
  font-weight: normal;
`;

export const Dialog = styled.div`
  background-color: ${color('background.tint1')};
  height: 100%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
  z-index: ${depth.MODAL};

  ${({ closeOnBreakpoint }) => mq(closeOnBreakpoint)} {
    display: none;
  }
`;

export const Layout = styled.div``;

export const CloseContainer = styled.div`
  border-bottom: 1px solid ${color('ui.divider')};
  align-items: center;
  display: flex;
  flex: none;
  height: 64px;
  justify-content: space-between;
  ${({ theme }) => spacing({ theme, px: 3 })}
  position: relative;
  width: 100%;

  ${mq('md')} {
    height: 92px;
  }
`;

export const CloseButton = styled.button`
  ${() => buttonReset()}

  display: inline-block;
  line-height: 0;
  position: relative;
`;

export const CrossWrapper = styled.span`
  ${svg(18, 18)}
  flex: none;
  width: 18px;

  ${mq('md')} {
    ${svg(24, 24)}
    width: 24px;
  }
  path {
    fill: ${color('all.brandPrimary1')};
  }

  ${CloseButton}:hover & {
    path {
      fill: ${color('all.brandPrimary1')};
    }
  }
`;

export const ContentContainer = styled.div``;
