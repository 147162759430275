import colors from '_containers/Theme/colors';

export default {
  label: {
    color: colors.neutral700
  },
  error: {
    background: colors.error100,
    border: colors.error500,
    color: colors.error500
  },
  success: {
    background: colors.success100,
    border: colors.success500
  },
  input: {
    background: colors.neutralWhite,
    backgroundDisabled: colors.neutral50,
    border: colors.neutral200,
    borderDisabled: colors.neutral200,
    borderError: colors.error500,
    borderFocus: colors.interactive500,
    borderFocusError: colors.error500,
    borderHover: colors.neutral800,
    color: colors.neutral700,
    colorDisabled: colors.neutral300,
  },
  select: {
    active: colors.interactive500,
    iconColor: colors.brandPrimary1,
    selected: colors.neutral300
  },
  check: {
    background: colors.neutralWhite,
    backgroundDisabled: colors.neutral50,
    border: colors.neutral700,
    borderDisabled: colors.neutral300,
    borderError: colors.error500,
    borderFocus: colors.interactive500,
    borderHover: colors.neutral700,
    color: colors.neutralWhite,
    colorDisabled: colors.neutral300,
    colorHover: colors.neutralWhite,
    selectedBackground: colors.interactive500,
    selectedBackgroundFocus: colors.interactive600,
    selectedBackgroundHover: colors.interactive600,
    selectedBorder: colors.interactive500,
    selectedBorderFocus: colors.interactive200,
    selectedBorderHover: colors.interactive600
  },
  button: {
    background: colors.interactive500,
    backgroundActive: colors.neutral600,
    backgroundDisabled: colors.neutral50,
    backgroundHover: colors.interactive600,
    borderRadius: 24,
    color: colors.neutralWhite,
    colorDisabled: colors.neutral300,
    outline: colors.interactive200
  },
  spinner: {
    color: colors.neutral500
  }
};
