import colors from '_containers/Theme/corporate/colors';


export default {
  flickityWrapper: {
    dot: {
      background: colors.background.tint4
    },
    isSelected: {
      background: colors.all.brandPrimary1
    }
  },
  arrowWrapper: {
    pathFillColor: colors.element.neutralWhite
  }
};
