import { Text } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React from 'react';

import {
  withDataSourceValidation,
  withEditMode,
  withSitecoreContext,
} from '_containers/BaseComponent';
import { Grid } from '_utils/components';
import { BookHinge } from '_utils/components/BookHinge';
import { stripHTML } from '_utils/helpers/html';
import { normalizeJssFields } from '_utils/helpers/jss';
import { minBreakpoint, maxBreakpoint } from '_utils/hooks/useBreakpoint';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageHeight16by9, ImageSize } from '_utils/types/enums';

import {
  NormalisedPageBannerPageFields,
  PageBannerProps,
} from './definitions';
import { HomepageBannerFieldValidators } from './validation';

import {
  BannerBlurbContainer,
  BannerContent,
  BannerGridContainer,
  BannerHeader,
  BannerImage,
  Blurb,
  Container,
  Figure,
  Header,
  Image,
  Label,
  LinkButton,
} from './StyledHomepageBanner';

const BannerGridRow = ({ children }) => {
  return (
    <Grid.Row alignItems="stretch" config={{ gutters: [] }}>
      <BannerContent config={{ col: 12, gutters: [] }} alignSelf="center">
        {children}
      </BannerContent>
    </Grid.Row>
  );
};

const BannerBlurb = ({
  cta,
  editMode,
  pageDescription,
  $mobile = false,
}) => {
  return (
    <BannerBlurbContainer aria-hidden={$mobile ? minBreakpoint('md') : maxBreakpoint('md')} $mobile={$mobile}>
      {(editMode || pageDescription?.value) && (
        <Blurb>
          <Text field={editMode ? pageDescription : { value: stripHTML(pageDescription?.value) }} />
        </Blurb>
      )}
      <LinkButton
        field={cta}
        fallbackTitle={cta?.fallbackTitle}
        linkSize="s"
        linkTheme="none"
      />
    </BannerBlurbContainer>
  );
};

const HomepageBanner: React.FC<PageBannerProps> = ({
  rendering,
  editMode,
}) => {
  const renderingPageFields: NormalisedPageBannerPageFields =
    normalizeJssFields(rendering?.fields?.data?.contextItem) || {};
  const checkAdjustDepth = rendering?.params?.adjustDepth || '';

  const pageTitle = renderingPageFields?.Heading;
  const pageDescription = renderingPageFields?.Description;
  const pageImage = renderingPageFields?.Image;
  const label = renderingPageFields?.Label;
  const cta = renderingPageFields?.CTAbutton;
  const uid = rendering?.uid

  return (
    <Container
      className="homepage-banner"
      data-component
      id={uid}
      adjustDepth={checkAdjustDepth}
    >
      <BannerGridContainer adjustDepth={checkAdjustDepth}>
        <BannerGridRow>
          <BannerHeader>
            {(editMode || label?.value) && (
              <Label>
                <Text field={editMode ? label : { value: stripHTML(label?.value) }} />
              </Label>
            )}
            <Header>
              <Text field={pageTitle} />
            </Header>
          </BannerHeader>
          <BannerBlurb {...{ editMode, pageDescription, cta }} />
        </BannerGridRow>
      </BannerGridContainer>

      <BannerImage>
        <Figure>
          <Image
            className="lazyload"
            draggable={false}
            field={
              editMode
                ? pageImage
                : {
                  value: {
                    src: imagePlaceholderSrc,
                    alt: '', // alt text is empty string, as this is a decorative image
                    'data-srcset': createSrcSet(pageImage?.value?.src, [
                      ImageSize.MW_384,
                      ImageSize.MW_640,
                      ImageSize.MW_768,
                      ImageSize.MW_1024,
                      ImageSize.MW_1280,
                      ImageSize.MW_1664,
                    ])
                  }
                }
            }
            height={ImageHeight16by9.MW_384}
            width={ImageSize.MW_384}
          />
        </Figure>
        <BookHinge
          desktopPlacement='left'
          mobilePlacement='top'
        />
      </BannerImage>

      {canUseDOM && (
        <BannerGridContainer>
          <BannerGridRow>
            <BannerBlurb $mobile {...{ editMode, pageDescription, cta }} />
          </BannerGridRow>
        </BannerGridContainer>
      )}
    </Container>
  );
};

export default compose(
  withSitecoreContext(),
  withEditMode,
  withDataSourceValidation(HomepageBannerFieldValidators, false),
)(HomepageBanner);
