import colors from '_containers/Theme/corporate/colors';

export default {
  introduction: {
    color: colors.text.secondary
  },
  chartRoundingLabel: {
    color: colors.text.subtle
  },
  details: {
    thisYear: {
      background: colors.element.primary2,
    },
    threeYears: {
      background: colors.element.primary
    }
  },
  item: {
    span: {
      color: colors.text.subtle
    },
    strong: {
      color: colors.text.secondary
    }
  },
  footnote: {
    color: colors.text.subtle
  },
  callToAction: {
    borderColor: colors.ui.divider
  },
  ctaIconWrapper: {
    color: colors.element.primary
  },
  ctaHeading: {
    color: colors.text.subtle
  },
  ctaLinkIconWrapper: {
    color: colors.element.primary
  }
};
