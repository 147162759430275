import styled from 'styled-components';

import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import {
  headingH5,
  linkCard,
  mq,
  svg,
  textBody2,
} from '_utils/styles';


export const List = styled(Grid.Row)`
  list-style: none;
  padding: 0;

  && {
    margin-top: 0;
  }
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0;
  width: 100%;

  ${mq('md')} {
    width: 50%;
  }

  ${mq('lg')} {
    width: 33%;
  }
`;

export const NavigationLink = styled.a`
  ${({ theme }) => linkCard({ theme })}
  ${({ theme }) => spacing({ theme, p: 2 })}
  border-bottom: 1px solid ${({ theme }) => theme.pageBannerWithInternalNavigation?.border?.color};
  border-radius: 0;
  border-right: 1px solid ${({ theme }) => theme.pageBannerWithInternalNavigation?.border?.color};
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  position: relative; 
  text-decoration: none;

  &:hover {
    cursor: pointer;
    
    h5 {
      color: ${({ theme }) => theme.colors.text.interactive1};
    }
  }

  ${mq.lessThan('md')} {
    border-right: none;

    ::last-child {
      border-bottom: none;
    }
  }
`
export const ContextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.subtle};
  display: block;
  flex: 0 1 auto;

  ${mq.lessThan('md')} {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

`

export const Heading = styled.h5`
 ${headingH5}
  margin-bottom: 0 !important;
  
  ${mq('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Description = styled.p`
  ${textBody2}
  color:  ${({ theme }) => theme.colors.text.subtle};
  word-break: break-word;

  ${mq('md')} {
    line-height: 20px;
    ${({ theme }) => spacing({ theme, mt: 1, mb: 1 })}
  }

  ${mq.lessThan('md')} {
    display: none;
  }

`

export const IconWrapper = styled.div`
  ${svg(16, 16)}
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  width: 24px;

  ${mq.lessThan('md')} {
    justify-items: center;
  }

  path {
    fill: ${({ theme }) => theme.pageBannerWithInternalNavigation?.icon?.color};
  }
`;


export const IconDirectionWrapper = styled.div`
  display: flex;
`

export const StyledGridContainer = styled(Grid.Container)`
  transform: translate(0%, -16px);

  ${mq('lg')} {
    transform: translate(0%, -32px);
  }

`