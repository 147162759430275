import React from 'react';
import { compose } from 'ramda';
import { useIsMobile } from '_utils/hooks';
import { BREAKPOINTS }  from '_utils/styles/mq'
import {
  withEditMode
} from '_containers/BaseComponent';

import {
  PageContainer
} from './StyledPodcastTuner';
import BrandItem from './BrandItem'

import { PodcastTunerProps } from './definitions'

const PodcastTuner: React.FC<PodcastTunerProps> = ({ editMode, rendering }) => {

  const brandedPodcastPages = rendering?.fields?.data?.datasource?.children || []
  const uid = rendering?.uid;

  const isMobile = useIsMobile(BREAKPOINTS.md);

  return (
    <PageContainer id={uid}>
      { brandedPodcastPages.map(item => (
        <BrandItem editMode={editMode} {...item} isMobile={isMobile}/>
      ))}
    </PageContainer>
  );
}

export default compose(withEditMode)(PodcastTuner);