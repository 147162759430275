import { ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';

import {
  buttonReset,
  headingH5,
  mq,
} from '_utils/styles';

export const Title = styled.h5`
  ${headingH5};
`;

export const ManualModeToggle = styled.button`
  ${buttonReset};
  color: ${({ theme }) => theme.complexFormElement.addressManual.toggle.color};
  text-decoration: underline;

  ${mq('sm')} {
    padding-top: 40px;
  }

  ${mq('md')} {
    padding-top: 45px;
  }
`;
