import styled from 'styled-components';
import {
    buttonReset
  } from '_utils/styles';
  

export const StyledIconDiv = styled.div`
    svg {
        position: relative;
        width: 40px !important;
        top: 45px;
        left: -3px;  
    }
`;

export const StyledPaddingDiv = styled.div`
    margin-left: 12px;
`

export const ManualModeToggle = styled.button`
  ${buttonReset};
  color: ${({ theme }) => theme.complexFormElement.dropdown.manualToggle.color};
`;
