import { canUseDOM } from 'exenv';

import { DateData } from '_utils/helpers/dateConverter';

import {
  ARTICLE_ADVERTORIAL_TEMPLATE,
  ARTICLE_SPONSORED_TEMPLATE,
  ARTICLE_STANDARD_TEMPLATE,
  CATEGORY_TEMPLATE,
  CONTRIBUTOR_TEMPLATE,
  TOPIC_TEMPLATE
} from './definitions';

export const setSection = (path): string => {
  if (!canUseDOM) {
    return '';
  }

  if (path === '/') {
    return 'home:';
  }

  return path.substring(1).replace(/\//g, ':').toLowerCase();
};

/**
 * @param {Array} data = optimistically: fields?.multimediaType[index]?.fields?.displayName?.value (object array).
 * @returns empty or comma delimited string.
 */
export const getMultiMediaTypes = (data: Array<any>): string => {
  if (data === undefined) {
    return '';
  }

  return data
    .map((p) => (p?.fields?.displayName?.value))
    .join(',');
};

/**
 * @param {object} fields - from sitecore route
 * @param {string} templateName - from sitecore route
 * @returns comma delimited string or undefined.
 */
export const getContributors = (fields: any, templateName: string): string | undefined => {
  if (fields === undefined) {
    return undefined;
  }

  if (templateName.toLowerCase() === CONTRIBUTOR_TEMPLATE) {
    return fields?.fullName?.value;
  }

  if (!fields?.contributors) {
    return undefined;
  }

  const fullNames = fields.contributors
    .filter((p) => (p?.fields?.fullName?.value !== undefined && p?.fields?.fullName?.value !== ''))
    .map((p) => (p?.fields?.fullName?.value));

  return fullNames.length > 0
    ? fullNames.join(',')
    : undefined;
};

/**
 * @param {object} route - sitecore route
 * @returns comma delimited string or undefined.
 */
export const getTopicTags = (route: any) => {
  if (route.templateName.toLowerCase() === TOPIC_TEMPLATE) {
    return route?.displayName;
  }

  let topicTags = '';
  if (route?.fields?.topicKeywords?.length) {
    topicTags = route?.fields?.topicKeywords
      .map(tag => tag?.displayName ?? '')
      .join(',');
  }
  return topicTags;
}

/**
 * @param {object} route - sitecore route
 * @param {object} rendering - rendering data source
 * @returns comma delimited string or undefined.
 */
 export const getPrimaryTopic = (route: any, rendering: any) => {
  if (route.templateName.toLowerCase() === CATEGORY_TEMPLATE) {
    return route?.displayName;
  }
  
  return rendering?.fields?.data?.contextItem?.categoryInformation?.categoryLabelText || '';
}

/**
 * @param {object} route - sitecore route
 * @param {object} rendering - rendering data source
 * @returns comma delimited string or undefined.
 */
 export const getEnterpriseTopic = (route: any, rendering: any) => {
  if (route.templateName.toLowerCase() === CATEGORY_TEMPLATE) {
    return route?.fields?.enterpriseTopic?.displayName || '';
  }
  
  return rendering?.fields?.data?.contextItem?.categoryInformation?.enterpriseTopic || '';
}

//  TODO: this whitelist will need to be managed as article types {templateName}'s change or are added. 
/**
 * Whitelisted template names that facilitate 'publishedDate' to be included in analytics.
 */
const templateNameWhiteList: Array<string> = [
  ARTICLE_ADVERTORIAL_TEMPLATE,
  ARTICLE_SPONSORED_TEMPLATE,
  ARTICLE_STANDARD_TEMPLATE,
];

/**
 *
 * @param {string | undefined} templateName
 * @param {DateData | null} dateData
 * @returns string - empty if not whitelisted 'itb article {type}' or DateData is null or invalid.
 */
export const getPublishedDate = (templateName: string | undefined, dateData: DateData | null): string => {
  if (templateName === undefined || !templateNameWhiteList.includes(templateName.toLocaleLowerCase())) {
    return '';
  }
  if ((dateData == null) || dateData.visualDate == '' || dateData.attrDate == '0001-01-01') {
    return '';
  }
  return dateData.visualDate;
}