export const TEXT_LINE_HEIGHT = 1.6;
export const FONT_FAMILY_TEXT = `'Avenir', Helvetica, Arial, sans-serif`;
import styled, { css } from 'styled-components';

export const placeholderBackgroundColors = {
  'Grey': 'neutral50'
}


export const hiddenContainer = css`
    display: none
`

// TODO remove once BEDs run Powershell script to remove from Placeholders
export const excludeThemeColors = ['white'];
