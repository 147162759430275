import * as validators from '_utils/validationChecks';
import { SitecoreFieldValidator } from '_utils/validationChecks/definitions'

export const contactDetailsFormValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.myAccountLink',
    errorMessage: 'A valid link to the My Account page must be provided',
    required: true,
    validators: [validators.isValidLink]
  },
  {
    field: 'rendering.fields.data.datasource.editEmailLink',
    errorMessage: 'A valid link to help customers edit their email must be provided',
    required: true,
    validators: [validators.isValidLink]
  }
];
