import styled from 'styled-components';

import {
  button,
  hideOnPrint,
  mq,
  safeFocusRemoval,
  svg,
} from '_utils/styles';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const FlickityWrapper = styled.div`
  /* padding is for dots to avoid overflow scroll bars */
  padding-bottom: 28px;

  .flickity-viewport {
    overflow-x: hidden;

    @media print {
      overflow: visible;
    }

    body:not(.safe-focus-removal) &:focus {
      outline: none;
    }
  }

  .flickity-page-dots .dot {
    background: ${({ theme }) => theme.carousel.flickityWrapper.dot.background};
    height: 8px;
    width: 8px;

    &.is-selected {
      background: ${({ theme }) => theme.carousel.flickityWrapper.isSelected.background};
    }
    opacity: 1;
  }
`;

export const PrevNextPosition = styled.div`
  position: absolute;
  z-index: 1;
  overflow: visible;
  top: 50%;
  transition: transform 0.4s ${({ theme }) => theme.motion.ease};

  &.prev {
    left: 12px;
    transform: translate(-50%, -50%);

    ${mq('lg')} {
      left: 52px;
      transform: translate(-150%, -50%);
    }
  }

  &.next {
    right: 12px;
    transform: translate(50%, -50%);

    ${mq('lg')} {
      right: 52px;
      transform: translate(150%, -50%);
    }
  }

  ${mq.lessThan('lg')} {
    ${({ hidePrevNextButtonsOnMobile})=> {
      if (hidePrevNextButtonsOnMobile) {
        return `display: none;`
      }
    }}
  }
`;

export const PrevNextButton = styled.button`
  ${button()}
  ${safeFocusRemoval}
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  opacity: 1;
  padding: 0;
  transition: opacity ${({ theme }) => theme.motion.timing.fast} linear;
  width: 32px;

  ${mq('md')} {
    width: 40px;
    height: 40px;
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
    cursor: auto;
  }

  ${hideOnPrint}
`;

export const ArrowWrapper = styled.span`
  flex: none;
  ${() => svg(7, 12)};
  width: 8px;
  path {
    fill: ${({ theme }) => theme.carousel.arrowWrapper.pathFillColor};
  }

  .prev & {
    right: 2px;
    transform: rotate(180deg);
  }
`;
