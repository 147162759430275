import { Link } from 'react-router-dom';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import colors from '_containers/Theme/intheblack/colors';
import styled from 'styled-components';

import { spacing } from '_utils/props';
import {
  tag12,
  tag14,
  mq,
} from '_utils/styles';


export const Container = styled.div`
  ${({ theme }) => spacing({ theme, mb: 6, px: 0 })}
`;

export const LogoWrapper = styled.figure`
  margin: 0;
  ${({ theme }) => spacing({ theme, mb:{ xs: '12px', md: 2 }, px: 0 })}

  &.portrait-style {
    img {
      width: auto;
      max-height: 90px;

      ${mq('md')} {
        max-height: 112px;
      }
    }
  }

  &.landscape-style {
    img {
      max-width: 200px;

    ${mq('md')} {
        max-height: 248px;
      }
    }
  }
}
`;

export const LogoLink = styled(Link)`
  display: inline-block;
  color: ${colors.text.default};
  text-decoration: none;

  &:hover {
  text-decoration: underline;
  }

  &:focus {
    outline-color: ${colors.interactiveCell.focus};
    outline-offset: 0;
  }
`;

export const SponsorLogoImg = styled(SCImage)``;

export const SponsorLabel = styled.div`
  ${tag12}

  ${mq('md')} {
    ${tag14}
  }
`;
