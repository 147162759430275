import colors from './colorOptions';
import { FONT_FAMILY_TEXT } from './global';

export default {
  default: {
    borderRadius: {
      topLeft: 9999,
      topRight: 9999,
      bottomLeft: 9999,
      bottomRight: 9999
    },
    // (same as h5)
    fontSize: {
      sm: 16,
      md: 16
    },
    lineHeight: {
      sm: 24,
      md: 24
    },
    padding: {
      x: 24,
      y: 12
    },
    icon: {
      sm: {
        w: 16,
        h: 16
      },
      md: {
        w: 16,
        h: 16
      }
    },
    fontFamily: FONT_FAMILY_TEXT
  },
  slim: {
    borderRadius: {
      topLeft: 9999,
      topRight: 9999,
      bottomLeft: 9999,
      bottomRight: 9999
    },
    fontSize: {
      sm: 14,
      md: 14
    },
    lineHeight: {
      sm: 24,
      md: 24
    },
    padding: {
      x: 16,
      y: 4
    },
    icon: {
      sm: {
        w: 20,
        h: 20
      },
      md: {
        w: 20,
        h: 20
      }
    },
    fontFamily: FONT_FAMILY_TEXT
  },
  iconOnly: {
    borderRadius: {
      topLeft: 0,
      topRight: 0,
      bottomLeft: 0,
      bottomRight: 0
    },
    fontSize: {
      sm: 16,
      md: 16
    },
    lineHeight: {
      sm: 16,
      md: 16
    },
    padding: {
      x: 16,
      y: 16
    },
    icon: {
      sm: {
        w: 20,
        h: 20
      },
      md: {
        w: 24,
        h: 24
      }
    },
    fontFamily: FONT_FAMILY_TEXT,
  },
  colors: {
    danger: {
      background: colors.error500,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.error700,
      border: '',
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.brandSecondary3,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: colors.neutralWhite
    },
    ghost: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.neutral100,
      border: colors.transparent,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.brandPrimary1,
      colorActive: '',
      colorHover: colors.brandPrimary1
    },
    primary: {
      background: colors.brandPrimary1,
      backgroundActive: colors.neutral600,
      backgroundFocus: '',
      backgroundHover: colors.brandPrimary1Dark,
      border: colors.brandPrimary1,
      borderActive: colors.neutral600,
      borderFocus: colors.interactive200,
      borderHover: colors.brandPrimary1Dark,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: colors.neutralWhite
    },
    secondary: {
      background: colors.brandPrimary3,
      backgroundActive: colors.neutral600,
      backgroundFocus: '',
      backgroundHover: colors.brandPrimary3Dark,
      border: colors.brandPrimary3Dark,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.brandPrimary3Dark,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: colors.neutralWhite
    },
    secondaryDark: {
      background: colors.brandPrimary2,
      backgroundActive: colors.neutral600,
      backgroundFocus: '',
      backgroundHover: colors.brandPrimary2Dark,
      border: colors.brandPrimary2,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.brandSecondary3Dark,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.brandPrimary3,
      colorActive: '',
      colorHover: colors.brandPrimary3
    },
    tag: {
      background: colors.neutralWhite,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.interactive500,
      border: colors.interactive500,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 1,
      color: colors.interactive500,
      colorActive: '',
      colorHover: colors.neutralWhite
    },
    tertiary: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.brandPrimary3,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: '',
      borderHoverWidth: 3,
      borderWidth: 1,
      color: colors.brandPrimary3,
      colorActive: '',
      colorHover: colors.brandPrimary3
    },
    announcementTertiary: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.neutral900,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: '',
      borderHoverWidth: 3,
      borderWidth: 1,
      color: colors.neutral900,
      colorHover: colors.neutral900
    },
    tertiaryDark: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.neutralWhite,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.neutralWhite,
      borderHoverWidth: 3,
      borderWidth: 1,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: colors.neutralWhite
    },
    text: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.transparent,
      border: colors.transparent,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.brandSecondary2,
      colorActive: '',
      colorHover: colors.brandSecondary3Dark
    },
    inTheBlack: {
      background: colors.brandSecondary3,
      backgroundActive: colors.neutral600,
      backgroundFocus: '',
      backgroundHover: colors.brandSecondary3Dark,
      border: colors.transparent,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralBlack,
      colorActive: colors.neutralWhite,
      colorHover: colors.neutralBlack,
    },
    backToTop: {
      background: colors.brandPrimary1,
      backgroundActive: colors.neutral600,
      backgroundFocus: '',
      backgroundHover: colors.brandPrimary1Dark,
      border: colors.brandPrimary1,
      borderActive: colors.neutral600,
      borderFocus: colors.interactive200,
      borderHover: colors.brandPrimary1Dark,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: colors.neutralWhite
    },
  }
}
