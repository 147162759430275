import { canUseDOM } from 'exenv';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type WindowSize = {
  width?: number;
  height?: number;
}

export default function(
  options = {
    enabled: true,
  },
): WindowSize {
  function getSize() {
    return {
      width: canUseDOM ? window.innerWidth : undefined,
      height: canUseDOM ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  const debouncedHandleResize = useDebouncedCallback(
    () => setWindowSize(getSize()), 200,
  );

  useEffect(() => {
    if (options.enabled) {
      window.addEventListener('resize', debouncedHandleResize.callback);
      return () => window.removeEventListener('resize', debouncedHandleResize.callback);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.enabled]);

  return windowSize;
}
