import { compose } from 'ramda';
import React from 'react';

import { withEditMode, withSitecoreContext } from '_containers/BaseComponent';

import { PodcastRelatedCardsProps } from './definitions';
import CardList from '_components/CardList';
import { CardListProps } from "_components/CardList/definitions";
import { Podcast, NoPodcast, Heading } from "./StyledPodcastRelatedCards";

const PodcastRelatedCards: React.FC<PodcastRelatedCardsProps> = ({ rendering, editMode, sitecoreContext }) => {

    const headingLevel = rendering?.params?.headingLevel || 'h2';
    const headingType = typeof headingLevel === 'number' ? `h${headingLevel}` : headingLevel

    const getItems = rendering?.fields?.data?.cards?.items || [];
    const templateId = sitecoreContext?.route?.templateId?.toUpperCase();

    let title = 'Episodes';
    // Keep this here in case need to customise the title in the future
    if (templateId === '47A90326-C257-4C0B-80AE-4F2AD4811318') {
        title = 'Episodes';
    }

    const removeGroupByYearUI = (item) => {
        if (item.podcastcards !== null) {
            return <Podcast><CardList {...(updateValue(item, "RemoveGroupByYear"))} /></Podcast>
        } else {
            return
        }
    }


     const keepPreviousUI = (item) => {
        if(item.podcastcards !== null) {
            if (templateId === '3EC61CE4-0269-499D-9CCE-025D23A8108B') {
                return <Podcast><h3>{item.title}</h3><CardList {...(updateForPodcastBrandPage(item))} /></Podcast>
            }
            return <Podcast><h3>{item.title}</h3><CardList {...(updateValue(item))} /></Podcast>
        } else {
            return <NoPodcast><h3>{item.title}</h3><p>No podcast episodes within the topic for this year</p></NoPodcast>
        }
    }

    function updateForPodcastBrandPage(item) {
        let cardRendering: CardListProps = {
            editMode: editMode,
            rendering: {
                params: {
                    "layoutType": "List",
                    type: templateId === '3EC61CE4-0269-499D-9CCE-025D23A8108B'? 'PodcastBrandPage' : '' 
                },
                fields: {
                    data: {
                        datasource: {
                            type: "CardList",
                            items: item.podcastcards,
                            heading: item.title
                        },
                    },
                },
            }
        };

        return cardRendering
    }
    
    function updateValue(item, type?:'RemoveGroupByYear') {
        let cardRendering: CardListProps = {
            editMode: editMode,
            rendering: {
                params: {
                    "layoutType": "List",
                },
                fields: {
                    data: {
                        datasource: {
                            type: "CardList",
                            items: item.podcastcards,
                            heading: item.title
                        },
                    },
                },
            }
        };

        if (type === 'RemoveGroupByYear') {
            cardRendering.rendering.params.type = "RemoveGroupByYear"

            // Order the card from the newest to oldest based on published
            const orderByDatePodcastcards = item.podcastcards.sort((a, b) => {
                // some item may not have published date there, move them to the end
                if (a.published === "" && b.published === "") return 0;
                if (a.published === "") return 1;
                if (b.published === "") return -1;

                // When there is valid published time, sorting the array
                const dateA = new Date(a.published);
                const dateB = new Date(b.published);
                return dateB.getTime() - dateA.getTime();
            })

            cardRendering.rendering.fields.data.datasource.items = orderByDatePodcastcards
        }

        return cardRendering;
    }

  return (
    <div className="podcast-related-cards" data-component>
        {/* template for the podcast brand page */}
        {templateId === '3EC61CE4-0269-499D-9CCE-025D23A8108B' && (getItems?.length !== 0) ?  <Heading as={headingType}>{title}</Heading>: ''}
        {templateId !== '3EC61CE4-0269-499D-9CCE-025D23A8108B' && <Heading as={headingType}>{title}</Heading>}
        {templateId === '47A90326-C257-4C0B-80AE-4F2AD4811318' ? getItems?.map((item) => removeGroupByYearUI(item) ): getItems?.map((item) => keepPreviousUI(item) ) }

    </div>
  );
};

export default compose(withEditMode, withSitecoreContext())(PodcastRelatedCards);
