import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
  mq,
} from '_utils/styles';
import { Link } from '_utils/components';


export const PlatformWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Figure = styled.div`
  display: flex;
  height: 24px;
  margin: 0;
  position: relative;
  width: 24px;

  ${mq('md')} {
    height: 28px; // align with line-height
  }
`;

export const Image = styled(SCImage)`
  width: 100%;
`;

export const PlatformLink = styled(Link)`
  cursor: pointer;
  ${({ theme }) => spacing(
    {
      theme,
      mb: { xs: 1, md: 0 },
      mr: `20px`,
    })}
    
  a {
    text-decoration: none;
    font-size: 10px;
    line-height: 18px;
    color: #0A0D46;
  }

  a span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  a div {
    .dark &{
      color: ${({ theme }) => theme.colors.text.neutral} !important;
    }

    .light & {
      color: ${({ theme }) => theme.colors.text.contrast} !important;
    }
  }
`;