import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    color: colors.element.neutralBlack
  },
  textBannerContainer: {
    background: colors.background.tint1,
    color: colors.element.neutralBlack
  },
  subtitle: {
    color: colors.text.default
  }
}