import React from 'react';

import {
  FormElementLabel,
  FormElementTitle,
  FormElementOptionalTitle
} from './StyledLabel';

import { FormLabelProps } from './definitions';

const FormLabel: React.FC<FormLabelProps> = ({ children, ...props }) => {
  const required = props?.required;
  const optional = props?.optional;
  return (
    <FormElementLabel {...props}>
      <FormElementTitle required={required}>{children}</FormElementTitle>
      {optional && <FormElementOptionalTitle>{`(optional)`}</FormElementOptionalTitle>}
    </FormElementLabel>
  )
};

export default FormLabel;
