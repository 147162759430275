import React from 'react';

import { Time } from '_utils/icons';
import { AttributeRow, AttributeIcon, AttributeValue } from './StyledTimeEstimate';

const TimeEstimate = ({ estimate }) => (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <Time ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>{estimate}</AttributeValue>
    </AttributeRow>
  );

export default TimeEstimate;
