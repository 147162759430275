import canUseDOM from 'exenv';
import { compose } from 'ramda'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce';

import { withDataSourceValidation, withEditMode, withCoveoNoIndexTags } from '_containers/BaseComponent'

import { Grid } from '_utils/components/';
import { stickyfill } from '_utils/styles';

import { CampaignNavigationProps } from './definitions';

import { campaignNavigationFieldValidators } from './validation';

import {
  CampaignName,
  CampaignNavigationContainer,
  Gradient,
  GridContainer,
  GridItem,
  GridRow,
  LinkButton,
  LinkItem,
  LinkList,
  NavigationContainer,
} from './StyledCampaignNavigation';

export const campaignNavigationStaticClassname = 'campaign-navigation';

const CampaignNavigation: React.FC<CampaignNavigationProps> = ({ editMode, data }) => {
  
  const campaignCTA = data?.campaignCta;
  const campaignName = data?.campaignName;
  const campaignNavigationStickyRef = useRef<HTMLElement>(null);
  const currentPageId = data?.currentPageId;
  const links = data?.links;
  const numberLinks = links && links?.length || 0;
  const listRef = useRef<HTMLElement>(null);
  const startGradientRef = useRef<HTMLElement>(null);
  const endGradientRef = useRef<HTMLElement>(null);
  const activeLinkRef = useRef<HTMLLIElement>(null);

  const [isSticky, setSticky] = useState(false);
  const [isScrollUp, setScrollUp] = useState(false);

  let timeout;
  let horizontalTimeout;

  const toggleGradientMasking = () => {
    // left gradient end mask
    const scrollThreshold = 20;

    if (listRef?.current?.scrollLeft > scrollThreshold) {
      startGradientRef?.current?.classList.add('visible');
    } else {
      startGradientRef?.current?.classList.remove('visible');
    }

    // right gradient end mask
    if (listRef?.current?.scrollLeft <
      (listRef?.current?.scrollWidth - (listRef?.current?.getBoundingClientRect().width)
        - scrollThreshold)) {
      endGradientRef?.current?.classList.add('visible');
    } else {
      endGradientRef?.current?.classList.remove('visible');
    }
  };

  let initialScrollPos = 0;
  const handleScroll = () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    timeout = window.requestAnimationFrame(() => {
      const pageHeader = canUseDOM && document.getElementsByTagName('header')[0];
      const pageHeaderHeight = pageHeader?.getBoundingClientRect().height;

      setScrollUp((canUseDOM && document.body.getBoundingClientRect().top) > initialScrollPos);
      initialScrollPos = canUseDOM && document.body.getBoundingClientRect().top;

      if (pageHeaderHeight >= 0) {
        setSticky(window.scrollY >= pageHeaderHeight);
      }
    });
  }

  const handleHorizontalScroll = () => {
    if (horizontalTimeout) {
      window.cancelAnimationFrame(horizontalTimeout);
    }
    horizontalTimeout = window.requestAnimationFrame(() => {
      toggleGradientMasking();
    });
  }

  const renderLinks = useMemo(() => {
    return links.map(link => {
      // LinkItem(NavLink) expects obj
      const linkObj = {
        value: { ...link }
      }

      return (
        <li
          className={currentPageId === link?.id ? 'active' : null}
          key={link.id}
          ref={currentPageId === link?.id ? activeLinkRef : null}>
          <LinkItem
            className="campaign-navigation-link"
            field={linkObj}
            key={link?.id}
            linkTheme="none"
          />
        </li>
      )
    })
  }, [currentPageId, links]);


  useEffect(() => {
    if (!canUseDOM) {
      return;
    }
    // add vertical scroll listener to window
    window.addEventListener('scroll', handleScroll, false);
    stickyfill.add(campaignNavigationStickyRef?.current ?? []);

    return () => {
      // remove vertical scroll listener to window
      window.removeEventListener('scroll', handleScroll, false);
      stickyfill.remove(campaignNavigationStickyRef?.current ?? []);
    };
  }, []);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    // scroll the nav list so active link positioned in the middle of the screen
    if (listRef?.current && activeLinkRef?.current) {
      const activeLeftEl = activeLinkRef.current.getBoundingClientRect();
      const listLeftEl = listRef.current.getBoundingClientRect();
      const offset = activeLeftEl.left + (activeLeftEl.width / 2) - (listLeftEl.width / 2);
      listRef.current.scrollLeft = offset;
    }

    // add horizontal scroll listener to nav list
    if (listRef?.current) {
      listRef.current.addEventListener('scroll', handleHorizontalScroll, false);
    }
    return () => {
      // remove horizontal scroll listener to nav list
      if (listRef?.current) {
        listRef.current.removeEventListener('scroll', handleHorizontalScroll, false);
      }
    };

  }, []);

  // apply gradient masking on window size change
  const debouncedHandleResize = useDebouncedCallback(toggleGradientMasking, 500);

  useEffect(() => {
    if (!canUseDOM ||
      !listRef?.current ||
      !endGradientRef?.current) {
      return;
    }

    window.addEventListener('resize', debouncedHandleResize.callback);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize.callback);
    };
  }, []);

  // Add pageheader height in the style 
  const scrollUpPageHeaderStyle = (isScrollUp && isSticky) ? "isScrollingUp" : '';
  
  const linkType = (!campaignCTA?.href?.length) ? "" : (campaignCTA?.href?.indexOf("://") > 0 || campaignCTA?.href?.indexOf("//") === 0) ? "external" : "internal";
 
  return !editMode && numberLinks < 1 ? null : (
    <CampaignNavigationContainer
      className={`${campaignNavigationStaticClassname} ${scrollUpPageHeaderStyle}`}
      data-component
      id={'campaign-navigation'}
      isSticky={isSticky}
      ref={campaignNavigationStickyRef}
    >
      {
        campaignName && (
          <CampaignName>
            <Grid.Container>{campaignName} </Grid.Container>
          </CampaignName>)
      }

      <GridContainer>
        <GridRow config={{ gutters: [] }}>
          <GridItem config={{ col: { xs: 12 }, gutters: ['left', 'right'] }}>
            <NavigationContainer>
              <Gradient ref={startGradientRef} />
              <LinkList className="navigation-list" ref={listRef}>
                {renderLinks}
              </LinkList>
              <Gradient className="end" ref={endGradientRef} />
            </NavigationContainer>
            {campaignCTA &&
              <LinkButton field={
                {
                  value: {
                    text: campaignCTA?.text,
                    href: campaignCTA?.href,
                    linkType: linkType,
                    target: campaignCTA?.target
                  }
                }} />
            }
          </GridItem>
        </GridRow>
      </GridContainer>
    </CampaignNavigationContainer>
  );
};

export default compose(withDataSourceValidation(campaignNavigationFieldValidators, false), withEditMode, withCoveoNoIndexTags)(CampaignNavigation);
