import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { withMenuContext } from '_containers/MenuContext';
import { MOTION_SPEED_FAST } from '_containers/Theme/global';

import MenuIcon from '_utils/icons/material/Menu';
import LoginIcon from '_utils/icons/material/Login';
import PermIdentityIcon from '_utils/icons/material/PermIdentity';
import { useTimer } from '_utils/hooks';
import { spacing } from '_utils/props';
import {
  buttonReset,
  depth,
  fixedTitle18,
  hideOnPrint,
  mq,
  safeFocusRemoval,
} from '_utils/styles';

import { MenuButtonProps } from './definitions';

const buttonStyles = css`
  ${buttonReset};
  ${safeFocusRemoval};
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-weight: ${({ theme }) => theme.pageHeader.menu.fontWeight};
  height: 100%;
  justify-content: center;
  position: relative;
  min-width: 64px;
  z-index: ${depth.MENU_BUTTON};

  &#menu-button {
    ${mq('lg')} {
      ${({ theme }) => spacing({ theme, ml: theme.pageHeader.menu.button.paddingLeft })};
    }
  }

  &#dashboard-menu-button {
    ${({ theme }) => spacing({ theme, ml: 3, mr: 'auto' })}

    ${mq.between('md', 'lg')} {
      ${({ theme }) => spacing({ theme, ml: theme.pageHeader.menu.dashboardMenuButton.marginLeft })}
    }

    ${mq.lessThan('md')} {
      display: none;
    }
  }

  &:focus {
    ${safeFocusRemoval}
    outline-color: ${({ theme }) => theme.colors.element.focused};
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 2px;
    position: relative;
  }
  ${hideOnPrint}

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, ml: 5, px: '12px' })}
  }

  ${mq('lg')} {
    ${fixedTitle18};
  }

  &:after {
    background: ${({ theme }) => theme.colors.element.focused};
    bottom: -1px;
    content: '';
    height: 6px;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity ${({ theme }) => theme.motion.timing.fast};
    width: 100%;
  }

  &[aria-expanded='true']:after {
    opacity: 1;
  }
`;

const Button = styled.button`
  ${buttonStyles};

  ${mq('md')} {
   ${({ theme }) => spacing({ theme, ml: theme.pageHeader.menu.md.marginLeft })};
  }
`;

const StyledLink = styled.a`
  ${buttonStyles};
`;

const IconWrapper = styled.span`
  ${mq('md')} {
    margin-right: 11px;
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.pageHeader.headerColor};
  display: none;

  ${mq('md')} {
    display: block;
  }
`;

const MenuButton: React.FC<MenuButtonProps> = ({
  activeMenu,
  iconName,
  id,
  link,
  menuActive,
  setActiveMenu,
  setMenuActive,
  target,
  targetMenu,
  title,
}) => {
  const [t] = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const { setTimer, cancelTimer } = useTimer();

  // clean up
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cancelTimer(), []);

  const onClickHandler = () => {
    if (!enabled) {
      return;
    }

    if (!menuActive) {
      setMenuActive(true);
      setActiveMenu(targetMenu);
    }

    if (menuActive && targetMenu === activeMenu) {
      setMenuActive(false);
    }

    if (menuActive && targetMenu !== activeMenu) {
      setActiveMenu(targetMenu);
    }

    setEnabled(false);

    setTimer(() => {
      setEnabled(true);
    }, MOTION_SPEED_FAST + 10);
  }

  const onFocusHandler = () => {
    if (!enabled) {
      return;
    }

    if (menuActive && targetMenu === activeMenu) {
      setEnabled(false);
      setMenuActive(false);
    }

    setTimer(() => {
      setEnabled(true);
    }, MOTION_SPEED_FAST + 10);
  }

  const map = {
    "menu": <MenuIcon />,
    "perm_identity": <PermIdentityIcon />,
    "login": <LoginIcon />
  }

  const renderIcon = (iconName) => map[iconName];

  return (
    <>
      {
        !!(link) ? (
          <StyledLink href={link} id={id} target={target}>
            <IconWrapper>
             {renderIcon(iconName)}
            </IconWrapper>
            <Label>{title}</Label>
          </StyledLink>
        ) : (
            <Button
              id={id}
              aria-expanded={menuActive && targetMenu === activeMenu}
              aria-label={menuActive && targetMenu === activeMenu ? 'hide main navigation' : 'show navigation'}
              onClick={() => onClickHandler()}
              onFocus={() => onFocusHandler()}
            >
              <IconWrapper>
              {renderIcon(iconName)}
              </IconWrapper>
              <Label>{title}</Label>
            </Button>
          )
      }
    </>
  );
};

export default withMenuContext(MenuButton);
