import { compose } from 'ramda';
import React from 'react';

import { withEditMode, withSitecoreContext} from '_containers/BaseComponent';

import { PodcastSeriesCardsProps } from './definitions';
import CardList from '_components/CardList';
import {CardListProps} from "_components/CardList/definitions";
import { Heading } from '_components/PodcastRelatedCards/StyledPodcastRelatedCards';

const PodcastSeriesCards: React.FC<PodcastSeriesCardsProps> = ({rendering, editMode, sitecoreContext}) => {

  const headingLevel = rendering?.params?.headingLevel || 'h2';
  const headingType = typeof headingLevel === 'number' ? `h${headingLevel}` : headingLevel;

  const getItems = rendering?.fields?.data?.cards?.seriesCards || [];
  const templateId = sitecoreContext?.route?.templateId?.toUpperCase();

  let title = 'Series';
// Keep this here in case need to customise the title in the future
if (templateId === '3EC61CE4-0269-499D-9CCE-025D23A8108B') {
    title = 'Series';
}


  let cardRendering: CardListProps = {
      editMode: editMode,
      rendering: {
          params: {
              "layoutType": "List",
              type: templateId === '3EC61CE4-0269-499D-9CCE-025D23A8108B'? 'PodcastBrandPage' : '' 
          },
          fields: {
              data: {
                  datasource: {
                      type: "CardList",
                      items: rendering?.fields?.data?.cards?.seriesCards,
                  },
              },
          },
      }
  };

  return (
    <div className="podcast-series-cards" data-component>
        {/* for podcast brand page */}
        {templateId === '3EC61CE4-0269-499D-9CCE-025D23A8108B' && (getItems?.length !== 0) ?  <Heading as={headingType}>{title}</Heading>: ''}
        {templateId !== '3EC61CE4-0269-499D-9CCE-025D23A8108B' && <Heading as={headingType}>{title}</Heading>}
        <CardList {...cardRendering} />
    </div>
  );
};

export default compose(withEditMode, withSitecoreContext())(PodcastSeriesCards);
