// import { endsWith } from '_utils/helpers/';
// import * as R from 'ramda';
import { ComponentFields, Field } from '@sitecore-jss/sitecore-jss-react';
import { dateConverter } from '_utils/helpers';
import { normalizeJssField } from '_utils/helpers/jss';
import { SitecoreFieldValidator, SitecoreFieldValidatorCallback, SitecoreFieldValidatorResult } from './definitions';

const isObject = (obj: any) => {
  return obj instanceof Object && obj.constructor === Object;
}

export const getProperty = (obj: any, path: string) => {
  if (!isObject(obj) || !path) return null;
  return path.split('.').reduce((acc, part) => {
    if (acc) {
      const prop = acc[part.replace(/\[.*/, '')];
      const arrayIndex = part.replace(/^[^\[]*(\[|$)|\]$/g, '');
      if (prop) {
        if (Array.isArray(prop)) {
          if (arrayIndex.match(/^[0-9]+$/)) {
            return prop[parseInt(arrayIndex)] || null;
          } else if (arrayIndex) {
            return prop[arrayIndex] || null;
          }
        }
        return prop;
      }
    }
    return null;
  }, obj);
}

// Will return true if the param passed is an object with at least one property and all properties have data
const validateObjectHasPropertiesWithData = (obj: object) => {
  if (!!obj && isObject(obj)) {
    var objValues = Object.values(obj);
    if (objValues.length > 0) {
      return objValues.every((prop) => !!prop);
    }
  }
  return false;
}

const getFieldValue = (field: object) => {
  if (!!field) {
    const normalizedField = normalizeJssField(field);
    return isObject(normalizedField) ? normalizedField['value'] : field;
  }
  return '';
}

export const isValidField: SitecoreFieldValidatorCallback = (field) => !!getFieldValue(field);

export const isValidIconReferenceField: SitecoreFieldValidatorCallback = (field) => !!field && !!field.targetItem && !!field.targetItem.name;

// export const isValidJssField : SitecoreFieldValidatorCallback  = (field) => !!field && !!field.jss && !!field.jss.value;

// export const isValidJssFieldAndNotEmpty : SitecoreFieldValidatorCallback  = (field) => !!field && !!field.jss && !!field.jss.value && field.jss.value !== '';

// export const isValidMedia : SitecoreFieldValidatorCallback  = (field) => isValidField(field) && !!getFieldValue(field).src && getFieldValue(field).src !== '';

// export const isValidJssImage : SitecoreFieldValidatorCallback = (field) => isValidField(field) && !!getFieldValue(field).jss && !!getFieldValue(field).jss.src && getFieldValue(field).jss.src !== '';

export const isValidImage: SitecoreFieldValidatorCallback = (field) => {
  const fieldValue = getFieldValue(field);
  return isValidField(field) &&
    !!fieldValue.src &&
    fieldValue.src !== '' &&
    fieldValue.class !== 'scEmptyImage';
}

export const isValidLink: SitecoreFieldValidatorCallback = (field) => {
  const fieldValue = getFieldValue(field);
  return isValidField(field) && !!fieldValue.href && !(fieldValue.href.toString().match(/:\/\/$/));
}

export const isValidNavLink: SitecoreFieldValidatorCallback = (field) =>
  field?.link?.url.startsWith('/') || isValidUrl(field?.link?.url)

export const isValidUrl: SitecoreFieldValidatorCallback = (field) =>
  isValidField(field) && !!(getFieldValue(field).toString().match(/^https?:\/\//i));

export const isValidUrlWithSSL: SitecoreFieldValidatorCallback = (field) =>
  isValidField(field) && !!(getFieldValue(field).toString().match(/^https:\/\//i));

// export const isValidReadMore: SitecoreFieldValidatorCallback = (field) => isValidLink(field) && getFieldValue(field).text !== '';

export const isValidDate: SitecoreFieldValidatorCallback = (field) => {
  // If the field is not set, return false
  const defaultSitecoreDateStr = '00010101T000000Z';
  if (!isValidField(field) || getFieldValue(field).toString() === defaultSitecoreDateStr) return false;

  // Check the value is a valid date
  const rawDateStr = getFieldValue(field).toString().replace('Z', '');
  const parsedDate = dateConverter(rawDateStr);
  return parsedDate !== null;
}

export const isValidNumber: SitecoreFieldValidatorCallback = (field) =>
  isValidField(field) && !Number.isNaN(parseFloat(getFieldValue(field)?.toString()));

export const isValidInteger: SitecoreFieldValidatorCallback = (field) =>
  isValidNumber(field) && Number.isInteger(parseFloat(getFieldValue(field)?.toString()));

export const isValidPercentage: SitecoreFieldValidatorCallback = (field) => {
  if (!isValidNumber(field)) return false;

  var num = parseFloat(getFieldValue(field)?.toString());
  if (Number.isFinite(num) && num >= 0 && num <= 100) return true;
  return false;
}

// export const isValidLookup : SitecoreFieldValidatorCallback = (field) => !!field && !!field.targetItem && !!field.targetItem.lookupValue;

// export const isValidCategoryLookup: SitecoreFieldValidatorCallback = (field) => !!field && !!field.targetItem && !!field.targetItem.tagName && !!field.targetItem.tagName.value;

// export const isImageUsingStandardValue: SitecoreFieldValidatorCallback = (image) => {
//   const field = typeof image.jss !== 'undefined' ? image.jss : image;

//   // field has no value if user has cleared the placeholder image from the backend
//   return image.containsStandardValue === true || Object.keys(getFieldValue(field)).length === 0;
// };

// export const isImagePlaceholder: SitecoreFieldValidatorCallback = (image) =>{
//   try {
//     return R.contains('/placeholders/',image.value.src);
//   } catch (e) {
//     return false;
//   }
// }

export const hasDataSource = (props: { componentProps: any }): boolean => {
  var hasComponentProps = !!props?.componentProps && isObject(props.componentProps)
  var hasRenderingWithDataSourceId = !!props?.componentProps?.rendering?.dataSource;
  var hasFields = !!props?.componentProps?.rendering?.fields && isObject(props.componentProps.rendering.fields);
  var isGraphQL = !!props?.componentProps?.rendering?.fields?.data && isObject(props.componentProps.rendering.fields.data) && !props.componentProps.rendering.fields.data.value;
  var hasData = validateObjectHasPropertiesWithData(isGraphQL ? props.componentProps.rendering.fields.data : props.componentProps.rendering.fields);

  return hasComponentProps && hasRenderingWithDataSourceId && hasFields && hasData;
}

export const hasValidFields = (props: { componentProps: any, validators: SitecoreFieldValidator[] }): boolean => {
  return getFieldValidationResults(props)?.every((v) => v.isValid) === true;
}

export const getFieldValidationResults = (props: { componentProps: any, validators: SitecoreFieldValidator[] }): SitecoreFieldValidatorResult[] => {
  let validationResults: SitecoreFieldValidatorResult[] = [];

  // Check we have fields and validators
  if (isObject(props.componentProps) && props.validators) {

    // Run each of the validators against the field value
    props.validators.forEach((fieldValidator) => {
      const fieldPath = fieldValidator?.field;
      if (fieldPath) {
        let prop = getProperty(props.componentProps, fieldPath);
        // rare situation where need to check both 'datasource' and 'contextItem'
        if (fieldValidator?.checkContextItem) {
          prop = getProperty(props.componentProps, fieldPath) || getProperty(props.componentProps, fieldPath?.replace('datasource', 'contextItem'));
        }

        let fields = prop;
        if (!Array.isArray(prop)) {
          fields = [prop];
        } else if (
          fieldValidator.minLength && fields.length < fieldValidator.minLength ||
          fieldValidator.maxLength && fields.length > fieldValidator.maxLength) {
          validationResults.push({
            field: fields,
            isValid: false,
            error: fieldValidator.errorMessage,
            validator: fieldValidator
          });
        }
        fields.forEach((fieldRaw, index) => {
          const field = normalizeJssField(fieldRaw);
          const hasValue = isValidField(field) || isValidIconReferenceField(field);
          let path = !Array.isArray(prop) ? fieldPath : `${fieldPath}[${index + 1}]`;
          let isValid = true;
          let error = `${fieldValidator.errorMessage ? fieldValidator.errorMessage + ' - ' : ''}${path}`;

          if (!hasValue && fieldValidator.required) {
            // Set an error as the required field is missing a value
            error += ' is missing';
            isValid = false;
          } else if (fieldValidator.validators) {
            // Set an error if not all the validators return true
            if (!fieldValidator.validators.every((v) => v?.call(this, field, props.componentProps)) === true) {
              error += ' is invalid';
              isValid = false;
            }
          }

          // Return the validation result for each validator
          validationResults.push({
            field: field,
            isValid: isValid,
            error: !isValid ? error : '',
            validator: fieldValidator
          });
        });
      }
    });
  }

  return validationResults.length > 0 ? validationResults : null;
}
