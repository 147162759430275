import {
  LinkField,
  LinkFieldValue,
} from "@sitecore-jss/sitecore-jss-react/types/components/Link";
import { TextProps } from "@sitecore-jss/sitecore-jss-react/types/components/Text";

import { IconProps } from '_components/Icon';

type JSSField<Content> = {
  jss: Content;
};

interface AnnouncementLinkFieldValue extends LinkFieldValue {
  linktype?: string;
  text?: string;
};

interface AnnouncementLinkField extends LinkField {
  value: AnnouncementLinkFieldValue;
};

export type AnnouncementColor = {
  value?: 'Red' | 'Yellow' | 'Blue' | 'Green';
};

export type Dismissable = {
  value?: boolean;
};

export type NormalizedSiteWideAnnouncement = {
  description?: TextProps["field"];
  dismissable?: Dismissable;
  id?: string;
  link?: AnnouncementLinkField & {
    fallbackTitle?: string;
  };
  title?: TextProps["field"];
  type?: AnnouncementColor;
  __typename?: "SiteWideAnnouncement"
};

export type NormalizedInPageAnnouncement = {
  customIcon?: {
    targetItem: IconProps
  };
  description?: TextProps["field"];
  id?: string;
  link?: AnnouncementLinkField & {
    fallbackTitle?: string;
  };
  title?: TextProps["field"];
  type?: AnnouncementColor;
  isTitleBold?: boolean;
  __typename?: "InPageAnnouncement";
};

export type SiteWideAnnouncement = {
  description?: JSSField<TextProps["field"]>;
  dismissable?: JSSField<Dismissable>;
  id?: string;
  link?: JSSField<AnnouncementLinkField> & {
    fallbackTitle?: string;
  };
  title?: JSSField<TextProps["field"]>;
  type?: JSSField<AnnouncementColor>;
  __typename?: "SiteWideAnnouncement"
};

export type InPageAnnouncement = {
  customIcon?: {
    targetItem: IconProps
  };
  description?: JSSField<TextProps["field"]>;
  id?: string;
  link?: JSSField<AnnouncementLinkField> & {
    fallbackTitle?: string;
  };
  title?: JSSField<TextProps["field"]>;
  type?: JSSField<AnnouncementColor>;
  isTitleBold?: boolean;
  __typename?: "InPageAnnouncement";
};

export type Announcement = SiteWideAnnouncement | InPageAnnouncement;

export type Announcements = SiteWideAnnouncement[] | InPageAnnouncement[];

export type AnnouncementsProps = {
  editMode?: boolean;
  fields: {
    data: {
      datasource: {
        __typename?: "InPageAnnouncementsFolder";
        children?: Announcements;
      }
    }
  };
};

export interface InPageAnnouncementProps extends NormalizedInPageAnnouncement {
};

export interface SiteWideAnnouncementProps extends NormalizedSiteWideAnnouncement {
  setAnnouncementDismissed?: () => {};
};

export const DISMISSED_ANNOUNCEMENTS_COOKIE_NAME = 'cpa_dismissed-announcements';
