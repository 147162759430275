import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '_utils/components/Grid';
import { spacing } from '_utils/props';
import {
  fixedBody12,
  fixedBody14,
  fixedBody16,
  listReset,
  avoidPageBreakOnPrint,
  mq,
  svg,
  safeFocusRemoval
} from '_utils/styles';

import {
  hideOnPrint,
} from '_utils/styles/print';


export const Profile = styled(Link)`

  display: flex;
  text-decoration: none;
  align-items: center;
  color: inherit;
  &:hover {
    text-decoration: underline;
    color: inherit;

    img,
    svg {
      outline: 2px solid ${({ theme }) => theme.colors.all.interactive500};
      outline-offset: 2px;
    }
  }

  &:focus {
    ${safeFocusRemoval}
    outline-offset: 0 !important;
    outline-width: 2px !important;
  }
`;

export const StyledGridContainer = styled(Grid.Container)`
  padding: ${({ theme }) => theme?.contentSummary?.gridContainer?.padding};
`

export const DotSpanFirst = styled.div`
  color: rgb(118, 118, 118);
  font-weight: 700;
  padding-bottom: 8px;
  ${mq.lessThan('sm')} {
    display: none;
  }
`;

export const DotSpanSecond = styled.div`
  color: rgb(118, 118, 118);
  font-weight: 700;
  padding-bottom: 8px;
  margin-right: 16px;
`;

export const TagContainer = styled.ul`
  ${listReset};
  ${hideOnPrint};
`;


export const Tag = styled.li`
  ${fixedBody14};
  background: ${({ theme }) => theme.colors.background.tint3};
  color: ${({ theme }) => theme.colors.text.subtle};
  display: inline;
  font-weight: bold;
  line-height: 18px;
  margint-top: 8px;
  margin-right: 20px;
  padding: 2px 4px;
  text-transform: uppercase;
`;


export const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
  ${mq.lessThan('sm')} {
    width: 100%;
    margin-bottom: 12px;
  }
  margin-right: 16px;
`

export const AttributesWrap = styled.div`
  display: flex;
  align-items: center;
  ${mq.lessThan('sm')} {
    width: 100%
  }
`

export const ProfileFigure = styled.div`
  height: 48px;
  width: 48px;
  position: relative;
  margin-right: 16px;
  img,
  svg {
    border-radius: ${(props) => !props.editMode ? '50%' : null};
    outline-offset: 2px;
  }

`;

export const ProfileImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const DateText = styled.div`
  ${fixedBody14};
  ${({ theme }) => spacing({ theme, mr: 2 })}
`

export const ReadingText = styled.div`
  ${fixedBody12};
`

export const Header5 = styled.div`
  ${fixedBody16};
  font-weight: bold;
  margin-bottom: 0 !important;
  margin-right: 16px;
`;

export const AttributesItem = styled.span`

svg {
  ${svg(16, 16)}
  margin-right: 8px;
  width: 16px;
  path {
    fill: ${({ theme }) => theme.colors.text.nonessential};
  }
}
`;