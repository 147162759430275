import colors from '_containers/Theme/corporate/colors';


export default {
  column: {
    borderBottom: colors.all.neutral200
  },
  body: {
    color: colors.all.brandSecondary2Dark,
    svgFill: colors.all.brandSecondary2,
    exempt: {
      color: colors.all.brandSecondary3Dark,
      svgFill: colors.all.brandSecondary3
    }
  },
  item: {
    span: {
      unknown: {
        color: colors.text.subtle
      }
    },
    subjects: {
      color: colors.all.brandPrimary1
    }
  }
}