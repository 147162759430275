import colors from './colorOptions';
import corporateColors from '../corporate/colorOptions';
import { FONT_FAMILY_TEXT } from './global';

export default {
  default: {
    borderRadius: {
      topLeft: 4,
      topRight: 4,
      bottomLeft: 4,
      bottomRight: 4
    },
    // (same as h5)
    fontSize: {
      sm: 16,
      md: 16
    },
    lineHeight: {
      sm: 24,
      md: 24
    },
    padding: {
      x: 16,
      y: 12
    },
    icon: {
      sm: {
        w: 16,
        h: 16
      },
      md: {
        w: 16,
        h: 16
      }
    },
    fontFamily: FONT_FAMILY_TEXT
  },
  tertiary: {
    borderRadius: {
      topLeft: 4,
      topRight: 4,
      bottomLeft: 4,
      bottomRight: 4
    },
    // (same as h5)
    fontSize: {
      sm: 16,
      md: 16
    },
    lineHeight: {
      sm: 24,
      md: 24
    },
    padding: {
      x: 16,
      y: 12
    },
    icon: {
      sm: {
        w: 16,
        h: 16
      },
      md: {
        w: 16,
        h: 16
      }
    },
    fontFamily: FONT_FAMILY_TEXT
  },
  slim: {
    borderRadius: {
      topLeft: 4,
      topRight: 4,
      bottomLeft: 4,
      bottomRight: 4
    },
    fontSize: {
      sm: 14,
      md: 14
    },
    lineHeight: {
      sm: 24,
      md: 24
    },
    padding: {
      x: 16,
      y: 4
    },
    icon: {
      sm: {
        w: 20,
        h: 20
      },
      md: {
        w: 20,
        h: 20
      }
    },
    fontFamily: FONT_FAMILY_TEXT
  },
  iconOnly: {
    borderRadius: {
      topLeft: 0,
      topRight: 0,
      bottomLeft: 0,
      bottomRight: 0
    },
    fontSize: {
      sm: 16,
      md: 16
    },
    lineHeight: {
      sm: 16,
      md: 16
    },
    padding: {
      x: 16,
      y: 16
    },
    icon: {
      sm: {
        w: 20,
        h: 20
      },
      md: {
        w: 24,
        h: 24
      }
    },
    fontFamily: FONT_FAMILY_TEXT,
  },
  colors: {
    danger: {
      background: colors.error500,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.error700,
      border: '',
      borderActive: '',
      borderFocus: colors.interactive300,
      borderHover: '',
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: '',
      disabled: colors.neutral300,
      pressed: colors.neutral600
    },
    ghost: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.interactive100,
      border: colors.transparent,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.interactive600,
      colorActive: '',
      colorAlternate: colors.neutral800,
      colorHover: ''
    },
    primary: {
      background: colors.interactive400,
      backgroundActive: '',
      backgroundDisabled: colors.neutral300,
      backgroundFocus: '',
      backgroundHover: colors.interactive500,
      border: '',
      borderActive: '',
      borderFocus: colors.interactive300,
      borderHover: '',
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralBlack,
      colorActive: '',
      colorHover: '',
      disabled: colors.neutralWhite,
      pressed: colors.neutral600
    },
    secondary: {
      background: colors.brandPrimary1,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.neutral700,
      border: '',
      borderActive: '',
      borderFocus: colors.interactive300,
      borderHover: '',
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: '',
      disabled: colors.neutral300,
      pressed: colors.neutral600
    },
    // Not show in figma - DO NOT REMOVE - breaks Corporate build
    // TODO - work out why it breaks Corporate build
    secondaryDark: {
      background: '',
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: '',
      borderActive: '',
      borderFocus: '',
      borderHover: '',
      borderHoverWidth: 0,
      borderWidth: 0,
      color: '',
      colorActive: '',
      colorHover: ''
    },
    tertiary: {
      background: colors.neutralWhite,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.brandPrimary1,
      borderActive: '',
      borderFocus: colors.neutralBlack,
      borderHover: '',
      borderHoverWidth: 2,
      borderWidth: 2,
      color: colors.brandPrimary1,
      colorActive: '',
      colorHover: '',
      pressed: colors.neutral600
    },
    tertiaryDark: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.brandPrimary1,
      borderActive: '',
      borderFocus: colors.interactive300,
      borderHover: '',
      borderHoverWidth: 3,
      borderWidth: 2,
      color: colors.brandPrimary1,
      colorActive: '',
      colorHover: '',
      pressed: colors.neutral600
    },
    text: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: colors.transparent,
      border: colors.transparent,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.brandSecondary2,
      colorActive: '',
      colorHover: colors.brandSecondary3Dark
    },
    // Not shown in figma -- used by InPageAnnouncement, SiteWideAnnouncement
    announcementTertiary: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.neutral900,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: '',
      borderHoverWidth: 3,
      borderWidth: 1,
      color: colors.neutral900,
      colorHover: colors.neutral900
    },
    tertiaryDark: {
      background: colors.transparent,
      backgroundActive: '',
      backgroundFocus: '',
      backgroundHover: '',
      border: colors.neutralWhite,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderHover: '',
      borderHoverWidth: 3,
      borderWidth: 1,
      color: colors.neutralWhite,
      colorActive: '',
      colorHover: '',
      disabled: colors.neutral600,
      pressed: colors.neutral400
    },
    // Not show in figma
    inTheBlack: {
      background: colors.interactive400,
      backgroundActive: colors.neutral600,
      backgroundDisabled: colors.neutral300,
      backgroundFocus: '',
      backgroundHover: colors.interactive500,
      border: colors.transparent,
      borderActive: '',
      borderFocus: colors.interactive200,
      borderFocusAll: colors.interactive300,
      borderHover: colors.transparent,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: colors.neutralBlack,
      colorActive: colors.neutralWhite,
      colorHover: colors.neutralBlack,
    },
    backToTop: {
      background: corporateColors.brandPrimary1,
      backgroundActive: corporateColors.neutral600,
      backgroundFocus: '',
      backgroundHover: corporateColors.brandPrimary1Dark,
      border: corporateColors.brandPrimary1,
      borderActive: corporateColors.neutral600,
      borderFocus: corporateColors.interactive200,
      borderHover: corporateColors.brandPrimary1Dark,
      borderHoverWidth: 0,
      borderWidth: 0,
      color: corporateColors.neutralWhite,
      colorActive: '',
      colorHover: corporateColors.neutralWhite
    },
  }
}
