// In order of layering order.

export default {
  MODAL: 2147483646, // one more than COOKIE_BANNER, above everything else
  COOKIE_BANNER: 2147483645,
  LIVE_CHAT: 2147483638, // value comes from that set by thrid party lib
  LOADING_SCREEN: 9999,
  TOOL_TIP: 1050,
  MENU: 1000,
  MENU_BUTTON: 950,
  MENU_BLOCK: 900,
  HEADER: 898,
  MENU_COVER: 889,
  LIVE_CHAT_MOBILE_MENU_ACTIVE: 888,
  BREADCRUMB: 700,
  CAMPAIGN_NAVIGATION: 897,
  QUICK_CLOSE: 5,
};
