import React from 'react';
import {
  InvalidMessageContainer,
  InvalidMessage,
} from './StyledErrorMessage'
interface ErrorMessageProps {
  isInRenewPage?: boolean; // Optional boolean
  children: React.ReactNode; // React children
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ isInRenewPage = false, children }) => {
  return (
    <InvalidMessageContainer isInRenewPage={isInRenewPage}>
      <InvalidMessage>{children}</InvalidMessage>
    </InvalidMessageContainer>
  )
};

export default ErrorMessage;
