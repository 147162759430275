import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';

import { withEditMode, withThemeOption } from '_containers/BaseComponent';
import { Section } from '_utils/components';
import Grid from '_utils/components/Grid';
import { PlaceholderMasonryGridProps } from './definitions';

import { Wrapper } from './StyledPlaceholderMasonryGrid';

const PlaceholderMasonryGrid: React.FC<PlaceholderMasonryGridProps> = ({ rendering, editMode }) => {
  const masonryGridPlaceholder = rendering?.placeholders?.['jss-content-masonry-grid'] ?? [];

  const isEmpty = masonryGridPlaceholder?.length === 0;

  const masonryGridRendering: any = masonryGridPlaceholder?.filter((obj: any) => {
    return obj.componentName === 'MasonryGrid';
  })?.[0];

  const hasHeadingOrDescription =
    !!(masonryGridRendering?.fields?.data?.datasource?.heading?.jss?.value
      || masonryGridRendering?.fields?.data?.datasource?.description?.jss?.value) === true;

  const sectionClassName =
    ((!editMode && isEmpty) || !hasHeadingOrDescription)
      ? 'is-empty'
      : '';

  const sectionTheme = rendering?.params?.placeholderTheme ?? 'Dark';

  return (
    <Section className={`ph ${sectionClassName}`} sectionTheme={sectionTheme}>
      <Grid.Container>
        <Grid.Row className="row" justifyContent=" center" config={{ gutters: ['left', 'right'] }}>
          <Wrapper config={{ gutters: ['left', 'right'] }}>
            <Placeholder name="jss-content-masonry-grid" rendering={rendering} />
          </Wrapper>
        </Grid.Row>
      </Grid.Container>
    </Section >
  );
};

export default compose(withThemeOption, withEditMode)(PlaceholderMasonryGrid);
