import personalisedCarousel from '_components/PersonalisationCarousel/variantSettingsInTheBlack';
import accordion, { darkMode as accordionDarkTheme } from '_components/Accordion/variantSettings';
import agendaAccordion, { darkMode as agendaAccordionDarkTheme } from '_components/AgendaAccordion/variantSettings';
import inPageAnnouncement from '_components/Announcements/InPageAnnouncement/variantSettings';
import siteWideAnnouncement from '_components/Announcements/SiteWideAnnouncement/variantSettings';
import breadcrumb from '_components/Breadcrumb/variantSettings';
import callToAction from '_components/CallToAction/variantSettingsInTheBlack';
import campaignNavigation from '_components/CampaignNavigation/variantSettings';
import campaignPageBanner from '_components/CampaignPageBanner/variantSettings';
import cardListInTheBlack, { darkMode as cardListInTheBlackDarkTheme } from '_components/CardListInTheBlack/variantSettingsInTheBlack';
import contentRelated from '_components/ContentRelated/variantSettingsInTheBlack';
import contentRichText from '_components/ContentRichText/variantSettingsInTheBlack';
import contentSummary from '_components/ContentSummary/variantSettingsInTheBlack';
import coveoContentCards from '_components/CoveoContentCards/variantSettingsInTheBlack';
import dateLinkList, { darkMode as dateLinkListDarkTheme } from '_components/DateLinkList/variantSettings';
import documentList from '_components/DocumentList/variantSettings';
import featureSection from '_components/FeatureSection/variantSettingsInTheBlack';
import featureSectionWithAd from '_components/FeatureSectionWithAd/variantSettingsInTheBlack';
import form from '_components/Form/variantSettingsInTheBlack';
import homepageBanner from '_components/HomepageBanner/variantSettings';
import imageCardGrid from '_components/ImageCardGrid/variantSettings';
import imageWithTitleAndText from '_components/ImageWithTitleAndText/variantSettings';
import internalPageBanner from '_components/InternalPageBanner/variantSettingsInTheBlack';
import lateralNavigation from '_components/LateralNavigation/variantSettingsInTheBlack';
import linkList from '_components/LinkList/variantSettings';
import liveChat from '_components/LiveChat/variantSettings';
import magazineBanner from '_components/MagazineBanner/variantSettingsInTheBlack';
import multiMediaEmbed from '_components/MultiMediaEmbed/variantSettingsInTheBlack';
import magazinePageBanner from '_components/MagazinePageBanner/variantSettingsInTheBlack';
import mainMenu from '_components/MainMenuInTheBlack/variantSettingsInTheBlack';
import mainSearch from '_components/MainSearch/variantSettingsInTheBlack';
import masonryGrid, { darkMode as masonryGridDarkTheme } from '_components/MasonryGrid/variantSettings';
import modal from '_components/Modal/variantSettingsInTheBlack';
import pageBanner from '_components/PageBanner/variantSettingsInTheBlack';
import pageFooter from '_components/PageFooter/variantSettingsInTheBlack';
import pageHeader from '_components/PageHeader/variantSettingsInTheBlack';
import placeholderDashboardSingleColumn from '_components/PlaceholderDashboardSingleColumn/variantSettings';
import profilePageBanner from '_components/ProfilePageBanner/variantSettings';
import searchMenu from '_components/SearchMenu/variantSettingsInTheBlack';
import searchResults from '_components/SearchResultsInTheBlack/variantSettingsInTheBlack';
import showcaseHero from '_components/ShowcaseHero/variantSettingsInTheBlack';
import singleImage from '_components/SingleImage/variantSettingsInTheBlack';
import staticTextAndImage from '_components/StaticTextAndImage/variantSettings';
import tabs, { darkMode as tabsDarkTheme } from '_components/Tabs/variantSettings';
import timezoneSelector from '_components/TimezoneSelector/variantSettings';

// utils
import contentCard from '_utils/components/ContentCard/variantSettingsInTheBlack';
import carousel from '_utils/components/Carousel/variantSettingsInTheBlack';
import headingWithDescription from '_utils/components/HeadingWithDescription/variantSettingsInTheBlack';
import spinner from '_utils/components/Spinner';
import tag, { darkMode as tagDarkTheme } from '_utils/components/Tag/variantSettingsInTheBlack';
import complexFormElement from '_utils/components/Form/variantSettings';
import lastUpdated from '_utils/components/LastUpdated/variantSettings';
import radio from '_utils/components/Radio/variantSettings';
import showMore from '_utils/components/ShowMore/variantSettings';
import tooltip, { darkMode as tooltipDarkTheme } from '_utils/components/Tooltip/variantSettings';

// styles
import card from '_utils/styles/card/variantSettingsInTheBlack';
import section, { darkMode as sectionDarkTheme } from '_utils/styles/section/variantSettingsInTheBlack';

import button from './button';
import colors from './colors';
import { FONT_FAMILY_TEXT } from './global';
import link from './link';
import {
  motion,
  shadows,
} from '../shared';

import { ITheme } from './definitions';

const Theme: ITheme = {
  personalisedCarousel,
  accordion,
  agendaAccordion,
  borderRadius: {
    default: {
      lg: 0,
      md: 14,
      sm: 10
    }
  },
  button,
  breadcrumb,
  callToAction,
  campaignNavigation,
  campaignPageBanner,
  card,
  cardListInTheBlack,
  carousel,
  colors: colors,
  content: {
    indent: {
      lg: 44,
      md: 36,
      xs: 32
    }
  },
  complexFormElement,
  contentCard,
  contentRelated,
  contentRichText,
  contentSummary,
  coveoContentCards,
  dateLinkList,
  documentList,
  featureSection,
  featureSectionWithAd,
  focus: {
    height: 'calc(100% + 4px)',
    left: '-2px',
    outlineColor: colors.all.interactive500,
    outlineOffset: 0,
    outlineStyle: 'solid',
    outlineWidth: '2px',
    radius: 0,
    top: '-2px',
    width: 'calc(100% + 4px)'
  },
  form,
  grid: {
    borderColor: colors.brandPrimary1,
    borderWidth: '1px',
    colCount: 12,
    containerMaxWidth: 1024,
    containerMinWidth: 280,
    gutter: {
      directions: ['left', 'right', 'top', 'bottom'],
      size: {
        lg: 24,
        md: 24,
        xs: 14
      }
    },
    margin: {
      lg: 32,
      md: 24,
      xs: 20
    }
  },
  headingWithDescription,
  homepageBanner,
  imageCardGrid,
  imageWithTitleAndText,
  inPageAnnouncement,
  internalPageBanner,
  lastUpdated,
  lateralNavigation,
  link,
  linkList,
  liveChat,
  magazineBanner,
  multiMediaEmbed,
  magazinePageBanner,
  mainMenu,
  mainSearch,
  masonryGrid,
  modal,
  motion,
  pageBanner,
  pageFooter,
  pageHeader,
  placeholderDashboardSingleColumn,
  profilePageBanner,
  responsiveSpacing: {
    xxl: {
      '0': 0,
      '1': 8,
      '2': 16,
      '3': 24,
      '4': 32,
      '5': 40,
      '6': 48,
      '7': 64,
      '8': 80,
      '9': 120
    },
    lg: {
      '0': 0,
      '1': 8,
      '2': 16,
      '3': 24,
      '4': 32,
      '5': 40,
      '6': 48,
      '7': 64,
      '8': 80,
      '9': 120
    },
    md: {
      '0': 0,
      '1': 8,
      '2': 16,
      '3': 24,
      '4': 32,
      '5': 40,
      '6': 48,
      '7': 64,
      '8': 80,
      '9': 120
    },
    xs: {
      '0': 0,
      '1': 8,
      '2': 12,
      '3': 16,
      '4': 16,
      '5': 24,
      '6': 24,
      '7': 32,
      '8': 40,
      '9': 64
    }
  },
  radio,
  searchMenu,
  searchResults,
  section,
  shadows,
  showcaseHero,
  showMore,
  singleImage,
  siteWideAnnouncement,
  spinner,
  staticTextAndImage,
  table: {
    font: {
      fontFamily: FONT_FAMILY_TEXT,
      // same as body2
      fontSize: {
        lg: 0,
        md: 16,
        sm: 14
      },
      lineHeight: {
        lg: 0,
        md: 20,
        sm: 16
      },
      spacing: 16
    }
  },
  tabs,
  tag,
  timezoneSelector,
  tooltip,
  typography: {
    headings: {
      caption1: {
        fontSize: 14,
        lineHeight: 14
      },
      caption2: {
        fontSize: 12,
        lineHeight: 12
      },
      fixedTitle12: {
        fontSize: 12,
        fontWeight: 900,
        lineHeight: 16,
        spacing: 16
      },
      fixedTitle14: {
        fontSize: 14,
        fontWeight: 900,
        lineHeight: 18,
        spacing: 18
      },
      fixedTitle16: {
        fontSize: 16,
        fontWeight: 900,
        lineHeight: 20,
        spacing: 20
      },
      fixedTitle18: {
        fontSize: 18,
        fontWeight: 900,
        lineHeight: 24,
        spacing: 24
      },
      fixedTitle20: {
        fontSize: 20,
        fontWeight: 900,
        lineHeight: 24,
        spacing: 24
      },
      H0: { // only used in HomepageBanner currently as oversized H1
        fontSize: {
          lg: 64,
          md: 45,
          sm: 40
        },
        lineHeight: {
          lg: 72,
          md: 54,
          sm: 48
        },
        fontWeight: 900
      },
      H1: {
        fontSize: {
          lg: 48,
          md: 40,
          sm: 32
        },
        lineHeight: {
          lg: 54,
          md: 48,
          sm: 38
        },
        fontWeight: 900
      },
      H2: {
        fontSize: {
          lg: 36,
          md: 32,
          sm: 28
        },
        lineHeight: {
          lg: 44,
          md: 38,
          sm: 32
        },
        fontWeight: 900
      },
      H3: {
        fontSize: {
          lg: 28,
          md: 24,
          sm: 24
        },
        lineHeight: {
          lg: 32,
          md: 28,
          sm: 28
        },
        fontWeight: 900
      },
      H4: {
        fontSize: {
          lg: 22,
          md: 20,
          sm: 20
        },
        lineHeight: {
          lg: 26,
          md: 24,
          sm: 24
        },
        fontWeight: 900
      },
      H5: {
        fontSize: {
          lg: 16,
          md: 14,
          sm: 14
        },
        lineHeight: {
          lg: 20,
          md: 18,
          sm: 18
        },
        fontWeight: 900
      },
      // same as H5 - attempt backward compaitble for any
      // previously authored content using H6
      H6: {
        fontSize: {
          lg: 16,
          md: 14,
          sm: 14
        },
        lineHeight: {
          lg: 20,
          md: 18,
          sm: 18
        },
        fontWeight: 900
      },
      subheading1: {
        fontSize: {
          lg: 24,
          md: 22,
          sm: 20
        },
        fontWeight: 300,
        lineHeight: {
          lg: 32,
          md: 30,
          sm: 28
        }
      },
      subheading2: {
        fontSize: {
          lg: 18,
          md: 18,
          sm: 16
        },
        fontWeight: 'normal',
        lineHeight: {
          lg: 24,
          md: 24,
          sm: 20
        }
      },
      title: {
        fontSize: {
          lg: 64,
          md: 48,
          sm: 36
        },
        fontWeight: 'normal',
        lineHeight: {
          lg: 64,
          md: 48,
          sm: 36
        }
      }
    },
    text: {
      // article body
      articleBody: {
        fontSize: {
          lg: 20,
          md: 18,
          sm: 18
        },
        lineHeight: {
          lg: 32,
          md: 28,
          sm: 28
        },
        spacing: 24
      },
      // normal body
      body1: {
        fontSize: {
          lg: 16,
          md: 16,
          sm: 16
        },
        lineHeight: {
          lg: 28,
          md: 28,
          sm: 24
        },
        spacing: 24
      },
      // smaller body
      body2: {
        fontSize: {
          lg: 14,
          md: 14,
          sm: 14
        },
        lineHeight: {
          lg: 20,
          md: 20,
          sm: 20
        },
        spacing: 24
      },
      fixedBody12: {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 16,
        spacing: 24
      },
      fixedBody14: {
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 18,
        spacing: 24
      },
      fixedBody16: {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 20,
        spacing: 24
      },
      fixedBody18: {
        fontSize: 18,
        fontWeight: 'normal',
        lineHeight: 24,
        spacing: 24
      },
      tag16: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 24,
        spacing: 0
      },
      tag14: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 24,
        spacing: 0
      },
      tag12: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 18,
        spacing: 0
      },
      tag10: {
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: 12,
        spacing: 0
      }
    }
  }
};

export const DarkTheme = {
  ...Theme,
  agendaAccordion: {
    ...agendaAccordion,
    ...agendaAccordionDarkTheme,
  },
  accordion: {
    ...accordion,
    ...accordionDarkTheme,
  },
  cardListInTheBlack: {
    ...cardListInTheBlack,
    ...cardListInTheBlackDarkTheme
  },
  dateLinkList: {
    ...dateLinkList,
    ...dateLinkListDarkTheme
  },
  masonryGrid: {
    ...masonryGrid,
    ...masonryGridDarkTheme
  },
  section: {
    ...section,
    ...sectionDarkTheme
  },
  tabs: {
    ...tabs,
    ...tabsDarkTheme
  },
  tag: {
    ...tag,
    ...tagDarkTheme
  },
  tooltip: {
    ...tooltip,
    ...tooltipDarkTheme
  },
  multiMediaEmbed: {
    ...multiMediaEmbed
  }
};

export default Theme;
