import colors from '_containers/Theme/intheblack/colors';
import {
  fixedBody12,
  fixedBody14,
  fixedTitle16,
  headingH4
} from '_utils/styles/';

export default {
  background: colors.all.neutralWhiteTransparent,
  border: `4px solid ${colors.all.brandPrimary1}`,
  caption: fixedBody12,
  captionColor: colors.text.pressed,
  content: {
    border: `1px solid ${colors.ui.divider}`
  },
  contentLinkColor: colors.all.brandPrimary1,
  heading: headingH4,
  headingColor: colors.all.brandPrimary1,
  iconColor: colors.text.nonessential,
  textbody: fixedTitle16,
  link: {
    focusBorder: colors.all.interactive500,
    color: colors.all.interactive600,
    hoverColor: colors.text.interactive1Hover,
    iconColor: colors.all.interactive600
  }
}