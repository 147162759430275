import canUseDOM from 'exenv';
import { compose } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'

import Icon from '_components/Icon';
import { withEditMode, withCoveoNoIndexTags } from '_containers/BaseComponent'
import { Grid } from '_utils/components/';
import { Portals, getPortal } from '_utils/helpers';
import { stickyfill } from '_utils/styles';

import { BreadcrumbProps } from './definitions';

import {
  BreadcrumbContainer,
  LinkItem,
  LinkList,
} from './StyledBreadcrumb'

const renderLinks = (links) => {
  return (
    links.map((l, index) => {
      // LinkItem(NavLink) expects obj
      const linkObj = {
        value: {
          href: l.path,
          text: l.name
        }
      }

      return (
        <li key={index + l.name}>
          {index === (links?.length - 1) ?
            <>{l.name}</> :
            <LinkItem key={index} className='breadcrumb' field={linkObj} linkTheme="breadcrumb" linkSize="s">
              {index === (links?.length - 2) ?
                <span className="chevron"><Icon ariaHidden={true} name="chevron_left" /></span>
                : null}
            </LinkItem>
          }
        </li>
      )
    })
  );
};

const STICKY_HEADER_HEIGHT_DELTA = 20; // expected change in header height

const Breadcrumb: React.FC<BreadcrumbProps> = ({ editMode, rendering }) => {
  const portalRef = useRef<HTMLElement>(null);
  const links = rendering.fields.data?.breadCrumbQuery?.breadcrumbs;
  const [isSticky, setSticky] = useState(false);
  const breadcrumbStickyRef = useRef<HTMLElement>(null);
  const numberLinks = links && links?.length || 0;
  const uid = rendering?.uid;

  let timeout;

  const handleScroll = () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    timeout = window.requestAnimationFrame(() => {
      const pageHeader = canUseDOM && document.getElementsByTagName('header')?.[0];
      const pageHeaderHeight = pageHeader ? pageHeader?.getBoundingClientRect()?.height : -1;
      const portalBeforeHeight = portalRef?.current ? portalRef?.current?.getBoundingClientRect()?.height : -1;

      if (pageHeaderHeight >= 0 && portalBeforeHeight >= 0) {
        setSticky(window.scrollY >= (pageHeaderHeight - STICKY_HEADER_HEIGHT_DELTA) + portalBeforeHeight);
      }
    });
  }

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    if (portalRef.current === null) {
      portalRef.current = getPortal(Portals.before);
    }

    window.addEventListener('scroll', handleScroll, false);
    stickyfill.add(breadcrumbStickyRef?.current ?? []);

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
      stickyfill.remove(breadcrumbStickyRef?.current ?? []);
    };
  }, []);

  return (numberLinks <= 1) ? null : (
    <BreadcrumbContainer
      className="page-breadcrumb"
      data-component
      editMode={editMode}
      id={uid}
      isSticky={isSticky}
      ref={breadcrumbStickyRef}
    >
      <Grid.Container>
        <Grid.Row config={{ gutters: ['left', 'right'] }}>
          <Grid.Item config={{ col: { xs: 12 }, gutters: ['left', 'right'] }}>
            <LinkList>
              {renderLinks(links)}
            </LinkList>
          </Grid.Item>
        </Grid.Row>
      </Grid.Container>
    </BreadcrumbContainer>
  );
};

export default compose(withEditMode, withCoveoNoIndexTags)(Breadcrumb);
