import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import { Grid, Link, Spinner } from '_utils/components';
import {
  button,
  mq,
} from '_utils/styles';

export const PageContainer = styled(Grid.Container)``;

export const CTALink = styled(Link)`
  display: flex;
  ${({ theme }) => spacing({ theme, pt: '24px' })}

  ${mq.lessThan('md')}{
    justify-content: center;

    a {
      width: 100%;
    }
  }

   a {
    ${button('default', 'tertiary')}

    ${mq('md')}{
      margin-left: auto;
    }

    path {
      fill: currentColor;
    }

  }
`;

export const Heading = styled.h3`
  ${({ theme }) => theme.bppAssessment.heading};
`;

export const StepTrackerContainer = styled.div`
  ${({ theme }) => spacing({ theme, mx: -3 })};
`;

export const Introduction = styled.div`
  ${({ theme }) => theme.bppAssessment.introduction.subheading}
  ${({ theme }) => spacing({ theme, pb: 2 })}
`;

export const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;
