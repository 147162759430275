import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { ApiProxy } from '_utils/helpers/apiProxy';
import { ApiResult } from '_utils/helpers/apiProxy/definitions';
import { errorInDev } from '_utils/helpers/dev';

import {
  AddressResult,
  AddressSearch,
  Countries,
  CountryDetail,
  CountriesMap,
  CountryStates,
  LocationAPIContextValue,
  CountryItem,
  HasCountryGotStates,
  HasCountryGotAddressLookupFeature,
} from './definitions';

export const LocationAPIContext = createContext<LocationAPIContextValue>(null);

export const LocationAPIContextProvider = ({
  apiHost = '',
  children,
}) => {
  const sitecoreContext: any = useContext(SitecoreContextReactContext);

  const isLoggedIn: boolean = useMemo(
    () => !sitecoreContext?.context?.securityInfo?.isAnonymous,
    [sitecoreContext?.context?.securityInfo?.isAnonymous],
  );

  const initialApiResultState = {
    data: null,
    error: null,
    isLoading: false,
    postResponse: null,
  }

  const [countries, setCountries] = useState<ApiResult<Countries>>(initialApiResultState);
  const [countryDetail, setCountryDetail] = useState<ApiResult<CountryDetail>>(initialApiResultState);
  const [countryStates, setCountryStates] = useState<ApiResult<CountryStates>>(initialApiResultState);
  const [addressSearch, setAddressSearch] = useState<ApiResult<AddressSearch>>(initialApiResultState);
  const [addressResult, setAddressResult] = useState<ApiResult<AddressResult>>(initialApiResultState);

  const countriesApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/location/Countries`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: countries,
    setResult: setCountries,
  });

  const countryDetailApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/location/CountryDetail`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: countryDetail,
    setResult: setCountryDetail,
  });

  const countryStatesApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/location/CountryStates`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: countryStates,
    setResult: setCountryStates,
  });

  const addressSearchApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/location/AddressSearch`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: addressSearch,
    setResult: setAddressSearch,
  });

  const addressResultApi = ApiProxy({
    apiUri: `${apiHost ?? ''}/api/location/AddressResult`,
    axiosRequestConfig: {
      method: 'GET',
    },
    isLoggedIn,
    result: addressResult,
    setResult: setAddressResult,
  });

  // Selectors
  const countriesMap: CountriesMap = useMemo(() => {
    if (!countriesApi?.result?.isLoading) {
      return countriesApi?.result?.data?.items?.reduce((map, obj) => (map[obj.iso] = obj, map), {}) ?? null;
    }
  }, [countriesApi?.result?.isLoading]);

  // Utils
  const hasCountryGotStates: HasCountryGotStates = (selectedCountry: CountryItem) => {
    const selectedCountryValidations = selectedCountry?.validations;

    return selectedCountry?.hasState &&
      (Array.isArray(selectedCountryValidations)
        ? !!selectedCountryValidations?.find?.(v => v?.type === 'STATE')
        : selectedCountryValidations?.type === 'STATE');
  };

  const hasCountryGotAddressLookupFeature: HasCountryGotAddressLookupFeature = (selectedCountry: CountryItem) => {
    const selectedCountryValidations = selectedCountry?.validations;

    return Array.isArray(selectedCountryValidations)
      ? (selectedCountryValidations?.filter((validation) => validation?.type === 'SEARCH')?.length ?? 0) > 0
      : selectedCountryValidations?.type === 'SEARCH';
  };

  return (
    <LocationAPIContext.Provider value={{
      countriesApi,
      countryDetailApi,
      countryStatesApi,
      countriesMap,
      addressSearchApi,
      addressResultApi,
      hasCountryGotStates,
      hasCountryGotAddressLookupFeature,
    }}>
      {children}
    </LocationAPIContext.Provider>
  )
};

export const useLocationAPIContext = () => {
  const locationAPIContext = useContext<LocationAPIContextValue>(LocationAPIContext);

  if (locationAPIContext === null) {
    errorInDev("A component that uses Location APIs is being rendered outside a LocationAPIContextProvider!");
  }

  return locationAPIContext;
};
