import { clone } from 'ramda';
import { appendAnalyticsTrackingQueryString } from '_utils/data/queryString';
import { Anchor } from './AnchorAttribute';

export const isValidLinkObject = (link) => Boolean(link?.url);

export const getLink = (link, analyticsOptions = null) => {

  const analyticsTrackingName = analyticsOptions?.analyticsTrackingName;
  const datasourceName = analyticsOptions?.datasourceName;
  const trackingName = analyticsTrackingName || datasourceName || false;

  if (typeof link === 'string' && Boolean(link)) {
    const linkObj: any = {
      url: link
    };

    // external link
    if (!link.startsWith('/')) {
      linkObj.href = link;
      linkObj.type = 'external';
      linkObj.target = '_blank';
    }
    // internal link
    else {
      linkObj.to = link;
    }

    return linkObj;
  } else if (link && typeof link === 'object') {
    // handle yet another format of links
    const linkObj: any = {
      ...link
    };

    if (Object.prototype.hasOwnProperty.call(link, 'href')) {
      linkObj.url = link.href;
    }

    if (Object.prototype.hasOwnProperty.call(link, 'linktype')) {
      linkObj.type = link.linktype;
    }

    if (Object.prototype.hasOwnProperty.call(link, 'linkType')) {
      linkObj.type = link.linkType;
    }

    // remove renamed keys
    delete linkObj.href;
    delete linkObj.linkType;
    delete linkObj.linktype;

    if (isValidLinkObject(linkObj)) {
      // only certain components (ie. banners) require the query string to pass analytics tracking info
      if (trackingName && linkObj?.url && linkObj.url.startsWith('/')) {
        linkObj.url = appendAnalyticsTrackingQueryString(link, trackingName);
      } else if (linkObj.querystring) {
        linkObj.url = linkObj?.url.concat('?', linkObj.querystring);
      }

      return linkObj;
    }
  }

  return null;
};

/**
 * Returns HTML anchor attributes and calculates appropriate target based on link construction. 
 * 
 * @param {string} link 
 * @returns { Anchor | null }
 */
export const getAnchorProps = (link: string): Anchor | null => {
  if (!link) {
    return null;
  }

  const anchor: Anchor = { href: link };

  if (link.startsWith('/')) {
    return anchor;
  }

  return {
    ...anchor,
    target: '_blank'
  }
};