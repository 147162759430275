import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    backgroundPrimary: colors.all.brandPrimary3,
    backgroundSecondary: colors.all.darkBackgroundTint1,
    divider: colors.all.transparent,
  },
  toolbar: {
    activeHighlight: colors.all.brandPrimary2,
    background: colors.all.brandPrimary3,
    buttonColor: colors.all.neutralWhite,
    color: colors.all.darkTextSubtle,
    colorActive: colors.all.neutralWhite,
    divider: colors.all.darkBackground,
  },
  item: {
    background: colors.all.transparent,
    backgroundActive: colors.all.darkBackgroundTint1,
    color: colors.all.neutralWhite,
    colorActive: colors.all.neutralWhite,
    colorHighlight: colors.all.brandPrimary2,
    colorLabel: colors.all.darkTextSubtle,
    description: colors.all.darkText,
    descriptionActive: colors.all.darkText,
    focusHighlight: colors.all.focusGrey,
    groupLabelColor: colors.all.darkTextSubtle,
  },
};
