import { useFormikContext } from 'formik';
import { compose } from 'ramda';
import React, { useContext } from 'react';

import Alert from '_components/MMFContactDetailsForm/Sections/Alert';
import { hasCommunicationErrors } from '_components/MMFContactDetailsForm/FormContent';

import { withEditMode } from '_containers/BaseComponent';
import {
  FormContext, withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import FieldSummary from '_utils/components/Form/FieldSummary';
import {
  Field,
  FormSummary,
} from '_utils/components/Form/StyledFormSection';

import { EditLink } from '_components/MMFContactDetailsForm/StyledContactDetailsForm';

import Icon from '_components/Icon';

import RadioList from '_utils/components/Form/RadioList';
import FormLabel from '_utils/components/Form/Label';
import Dropdown from '_utils/components/Form/Dropdown';
import TextInput from '_utils/components/Form/TextInput';
import { FormElementHint } from '_utils/components/Form/StyledFormElement';
import { ContactDetailsFormContext, ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  formatMobilePhone,
} from '_utils/helpers/form';

import { ContactDetailsFormProps } from '../definitions';

import mockData from '../mock.json';

// trims 'number' from label as per designs - not re-useable outside this component
const formatLabel = (label: string): string => label.replace('number', '');

const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  editMode,
  mockContactProfile,
  rendering,
  contactProfileApi
}) => {
  const formContext = useContext<ContactDetailsFormContext>(FormContext);
  const sectionContext = formContext?.contactDetails;

  const formikContext = useFormikContext<ContactDetailsFormValues>();

  const { errors, values, submitCount } = formikContext;
  const showErrorOnLoad = hasCommunicationErrors(errors);

  const uid = `contact-details`;
  const datasource = rendering?.fields?.data?.datasource;
  const editEmailLink = datasource?.editEmailLink?.jss;

  // render component with mock data when in Experience Editor
  const contactProfile =
    !editMode
      ? contactProfileApi?.result?.data
      : mockContactProfile ?? mockData?.mockContactProfile;

  // get reference data from api
  const referenceData = contactProfileApi?.result;
  const mobileCountryCodeRefData = referenceData?.data?.referenceData?.countries?.items || [];
  const phoneNumberTypeRefData = referenceData?.data?.referenceData?.phoneNumberTypes?.items || [];

  // get the field and label values
  const heading = getDictionaryItem('form-contact-section-contact-details', 'Contact details');
  const updateLabel = getDictionaryItem('form-contact-button-update', 'Update');

  const emailLabel = getDictionaryItem("form-contact-email", "Email");
  const preferredPhoneLabel = getDictionaryItem("form-contact-preferred-phone", "Preferred phone number");
  const businessPhoneLabel = getDictionaryItem("form-contact-work-number", "Business phone number");
  const homePhoneLabel = getDictionaryItem("form-contact-home-number", "Home phone number");
  const mobileCountryCodeLabel = getDictionaryItem("form-contact-mobile-country", "Mobile country code");
  const mobilePhoneLabel = getDictionaryItem("form-contact-mobile-number", "Mobile phone number");
  const phoneFormatHint = getDictionaryItem("form-contact-phone-format", "'CountryCode- AreaCode-PhoneNumber' (Eg: 061-03-XXXXXXXX)");

  // placeholder labels
  const mobileCountryCodePlaceholder = getDictionaryItem("form-placeholder-mobile-country-code", "Select country code");
  const mobilePhonePlaceholder = getDictionaryItem("form-placeholder-mobile-phone", "Enter mobile number");
  const phoneNumberPlaceholder = getDictionaryItem("form-placeholder-enter-phone-number", "Enter phone number");

  return (
    <EditableSectionContainer
      className="contact-details-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      {/* Phone number alert */}
      {
        sectionContext?.isEditing &&
        showErrorOnLoad && submitCount === 0 &&
        <Alert type="phone" />
      }

      <EditableSectionHeader
        icon="call"
        sectionIdentifier={uid}
        updateLabel={updateLabel}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <>
            <FormSummary>
              <Field config={{ col: { xs: 12 } }}>
                <FormLabel>{emailLabel}</FormLabel>

                <div>
                  {contactProfile?.contact?.comms?.emailAddress}
                  <EditLink
                    childrenAfterText
                    hideExternalIcon
                    linkTheme="none"
                    field={editEmailLink}>

                    <Icon ariaHidden={true} name="mode" />
                  </EditLink>
                </div>
              </Field>
            </FormSummary>

            <FormSummary>
              <Field config={{ col: { xs: 12 } }}>
                <FormLabel>{preferredPhoneLabel}</FormLabel>
                {
                  !contactProfileApi?.result?.isLoading &&
                  <RadioList
                    name="contact.comms.preferredPhoneNumber"
                    items={
                      phoneNumberTypeRefData.map((item => (
                        {
                          value: item?.name ?? '',
                          label: item?.name?.concat(' phone') // append to match designs since missing from API
                        })
                      ))
                    }
                  />
                }
              </Field>
            </FormSummary>

            <FormSummary>
              <Field config={{ col: { xs: 12, md: 4 } }}>
                <FormLabel
                  htmlFor="workPhone"
                  required={values?.contact?.comms?.preferredPhoneNumber == 'Business Phone'}
                >
                  {formatLabel(businessPhoneLabel)}
                </FormLabel>
                <TextInput
                  id="workPhone"
                  name="contact.comms.workPhone"
                  placeholder={phoneNumberPlaceholder}
                />
                <FormElementHint>{phoneFormatHint}</FormElementHint>
              </Field>
              <Field config={{ col: { xs: 12, md: 4 } }}>
                <FormLabel
                  htmlFor="homePhone"
                  required={values?.contact?.comms?.preferredPhoneNumber == 'Home Phone'}
                >
                  {formatLabel(homePhoneLabel)}
                </FormLabel>
                <TextInput
                  id="homePhone"
                  name="contact.comms.homePhone"
                  placeholder={phoneNumberPlaceholder}
                />
                <FormElementHint>{phoneFormatHint}</FormElementHint>
              </Field>
            </FormSummary>

            <FormSummary>
              <Field config={{ col: { xs: 12, md: 4 } }}>
                {/* TODO - not unique list from API - multiple countries share same country code */}
                <FormLabel
                  htmlFor="mobilePhoneCountryCode"
                  required={values?.contact?.comms?.preferredPhoneNumber == 'Mobile Phone'}
                >
                  {mobileCountryCodeLabel}
                </FormLabel>
                {
                  <Dropdown
                    placeholder={mobileCountryCodePlaceholder}
                    defaultValue={
                      {
                        label: contactProfile?.contact?.comms?.mobilePhoneCountryCode,
                        value: contactProfile?.contact?.comms?.mobilePhoneCountryCode
                      }
                    }
                    getOptionLabel={(option) => option.hasOwnProperty('label') ? option?.label ?? '' : option}
                    getOptionSelected={(option, selectedOption) => option?.value === selectedOption?.value}
                    id="mobilePhoneCountryCode"
                    name="contact.comms.mobilePhoneCountryCode"
                    multiple={false}
                    options={
                      !contactProfileApi?.result?.isLoading ?
                        mobileCountryCodeRefData?.map(item => (
                          {
                            label: `${item.name} (${item.mobilePhoneCountryCode})`,
                            value: item.mobilePhoneCountryCode
                          }
                        ))
                        : []
                    }
                  />
                }
              </Field>

              <Field config={{ col: { xs: 12, md: 4 } }}>
                <FormLabel
                  htmlFor="mobilePhone"
                  required={values?.contact?.comms?.preferredPhoneNumber == 'Mobile Phone'}
                >
                  {formatLabel(mobilePhoneLabel)}
                </FormLabel>
                <TextInput
                  id="mobilePhone"
                  name="contact.comms.mobilePhone"
                  placeholder={mobilePhonePlaceholder}
                />

              </Field>

            </FormSummary>
          </>
        ) :
        //  pre-interaction state
        (
          <FormSummary>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-email"
                labelFallback="Email"
                value={contactProfile?.contact?.comms?.emailAddress}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-preferred-phone"
                labelFallback="Preferred phone number"
                value={contactProfile?.contact?.comms?.preferredPhoneNumber}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-mobile-number"
                labelFallback="Mobile number"
                value={formatMobilePhone(contactProfile?.contact?.comms?.mobilePhoneCountryCode, contactProfile?.contact?.comms?.mobilePhone)}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-work-number"
                labelFallback="Business phone number"
                value={contactProfile?.contact?.comms?.workPhone}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-home-number"
                labelFallback="Home phone number"
                value={contactProfile?.contact?.comms?.homePhone}
              />
            </Field>
          </FormSummary>
        )
      }
    </EditableSectionContainer>
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('contactDetails'),
)(ContactDetailsForm);
