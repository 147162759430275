import styled from 'styled-components';
import { fixedBody12, iconColorOnPrint, mq } from '_utils/styles';
import svg from '../../styles/svg';

export const AttributeRow = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const AttributeValue = styled.span`
  ${fixedBody12}
  flex: 1 1 auto;
  margin: 0;
`;

export const AttributeIcon = styled.span`
  ${svg(20, 20)}
  width: 20px;
  margin: 0 2px 0 0;

  path {
    fill: ${({ theme }) => theme.lastUpdated.pathFillColor};
    ${iconColorOnPrint}
  }
`;
