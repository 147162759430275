import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';

// Validation is not that good as previous, content team need additional effort
export const magazineCarouselFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.heading.jss.value',
    errorMessage: 'Heading is required',
    required: true
  },
  {
    field: 'rendering.fields.data.datasource.magazineList.targetItems',
    errorMessage: 'At least one card must be provided',
    minLength: 1
  }
];
