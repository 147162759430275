import classnames from 'classnames';
import React from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';

import Modal from '_components/Modal';
import DigitalDataProvider from '_containers/DigitalDataContext';
import MenuProvider from '_containers/MenuContext';
import ModalProvider from '_containers/ModalContext';

import { Language } from '_types';

import {
  Grid,
  SpaLoader,
} from '_utils/components';

import { ITBAllPagesStructuredData, ITBArticlePageStructuredData } from '_utils/components/StructuredDataITB';

import { INVISIBLE_CLASS } from '_utils/helpers/render';

import { LayoutProps } from './definitions';

import {
  ArticleContainer,
  IntroContentPlaceholder,
  MainContainer,
  OutroContentPlaceholder,
} from './StyledLayout';

const DefaultLayout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { loading, route } = props;
  const languageType: Language = route?.fields?.languageType?.value || null;

  /**
   * Get the sitecore generated 'Template Name'.
   * If not undefined, replace ALL spaces with '-' and convert to lowercase.
   * '/containers/Theme/intheblack/index.tsx' target class name with css when required.
   */
  const templateName: string = route?.templateName ?? '';
  const templateNameClassName = templateName !== '' ? templateName.replace(/ /g, '-').toLocaleLowerCase() : '';
  const mainContainerClassNames = classnames('intheblack-default', templateNameClassName);

  return (
    <>
      <MenuProvider>
        <ModalProvider>
          <DigitalDataProvider>
            <Placeholder name="jss-header" rendering={route} />
            <MainContainer
              className={mainContainerClassNames}
              id="main-content"
              lang={languageType}
              name="jss-main"
              rendering={route}
              role="main"
            >

              <SpaLoader />

              {/* prevents flash reverted content when route change and personalisation applied */}
              <div className={loading && INVISIBLE_CLASS}>
                <Placeholder
                  name="jss-content-intro"
                  rendering={route}
                  render={(components) => (
                    <IntroContentPlaceholder>
                      {components}
                    </IntroContentPlaceholder>
                  )}
                />
                <Grid.Container>
                  <Grid.Row
                    config={{ gutters: ['left', 'right'] }}>
                    {/* Main content */}
                    <ArticleContainer
                      className="jss-content-main ph">
                      <Placeholder name="jss-content-main" rendering={route} />
                    </ArticleContainer>
                  </Grid.Row>
                </Grid.Container>
                <Placeholder name="jss-content-outro" rendering={route}
                  render={(components) => (
                    <OutroContentPlaceholder>
                      {components}
                    </OutroContentPlaceholder>
                  )}
                />
              </div>

            </MainContainer>
            <Placeholder name="jss-footer" rendering={route} />
            <Modal />
            {/* Google structured data */}
            <ITBAllPagesStructuredData {...props} />
            <ITBArticlePageStructuredData {...props} />
          </DigitalDataProvider>
        </ModalProvider>
      </MenuProvider>
      <VisitorIdentification />
    </>
  );
};

export default DefaultLayout;
