import { compose } from 'ramda';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import SearchMenu from '_components/SearchMenu';
import { withModalContext } from '_containers/ModalContext'
import { Search } from '_utils/icons';

import { SearchMenuButtonProps } from './definitions';

import {
  Button,
  Label,
} from './StyledSearchMenuButton';

const SEARCH_MODAL_ID = 'search';

const SearchMenuButton: React.FC<SearchMenuButtonProps> = ({
  setModal,
}) => {
  const [t] = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Button
      id="search-menu-button"
      className="search-menu-button"
      aria-expanded={enabled}
      aria-labelledby="search-menu-button-label"
      aria-haspopup={true}
      onClick={() => {
        setEnabled(true);
        setModal({
          id: SEARCH_MODAL_ID,
          content: <SearchMenu inputRef={inputRef} />,
          onClose: () => setModal(null),
          shouldHideCloseButton: true,
          closeOnBreakpoint: 'md',
          onActivate: () => {
            inputRef?.current?.focus();
          }
        });
      }}
    >
      <Search />
      <Label id="search-menu-button-label">{t('search-accessible-search-menu-button-label')}</Label>
    </Button>
  );
};

export default compose(withModalContext)(SearchMenuButton);
