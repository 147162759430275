import React from 'react';
import { NavLink, Link as ReactLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MOTION_SPEED_FAST } from '_containers/Theme/global';

import {
  button,
  buttonReset,
  depth,
  fixedTitle16,
  fixedTitle18,
  ie,
  listReset,
  mq,
  safeFocusRemoval,
  svg,
  textBody2,
} from '_utils/styles';
import { spacing } from '_utils/props';


export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: transform ${() => `${MOTION_SPEED_FAST}ms`} cubic-bezier(0.42, 0, 0.59, 1);
  /* delay to allow menu transition bw mobile/table opposite sides of screen */
  transition-delay: opacity .1s;
  width: 100%;
  z-index: ${depth.MENU};

  &.menu-active {
    opacity: 1;
    transform: translateX(0);
  }

  ${mq('md')} {
    height: 100%;
    min-height: auto;
    overflow: visible;
    position: absolute;
    transform: translateX(-100%);
    width: 330px;
  }

  ${mq('lg')} {
    width: 360px;
  }

  // hide scrollbars if possible
  * {
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(225, 225, 225, 0.1);
      border-radius: 20px;
    }
    scrollbar-color: rgba(225, 225, 225, 0.1) ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    scrollbar-width: thin;
  }
`;

export const NavToolbar = styled.div`
  ${({ theme }) => spacing({ theme, pl: '20px' })};
  border-bottom: 1px solid ${({ theme }) => theme.mainMenu.toolbar.divider};
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.pageHeader.height.xs}px;
  position: fixed;
  top: 0;
  width: 100%;

  ${ie} {
    display: none !important;
  }

  ${mq('md')} {
    border: 0;
  }

  ${({ level }) => {
    if (level === 'primary') {
      return css`
        background: ${({ theme }) => theme.mainMenu.toolbar.background};
      `;
    }
  }}
`;

export const NavToolbarControl = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  ${mq('md')} {
    display: none;
  }
`;

export const NavToolbarTabs = styled.div`
  display: flex;
`;

export const NavToolbarTabItem = styled.button`
  ${buttonReset};
  ${fixedTitle16};
  ${({ theme }) => spacing({ theme, px: 4 })};
  align-items: center;
  color: ${({ theme }) => theme.mainMenu.toolbar.color};
  display: flex;
  height: 100%;

  ${({ active }) => {
    if (active) {
      return css`
        color: ${({ theme }) => theme.mainMenu.toolbar.colorActive};
        box-shadow: inset 0px -4px 0px ${({ theme }) => theme.mainMenu.toolbar.activeHighlight};
      `;
    }
  }}
`;

export const NavToolbarBack = styled.button`
  ${buttonReset};
  ${fixedTitle16};
  align-items: center;
  display: flex;
  flex: 1;

  svg {
    margin-right: 12px;
  }

  path {
    fill: ${({ theme }) => theme.mainMenu.toolbar.colorActive};
  }

  ${mq('md')} {
    display: none;
  }
`;

export const NavToolbarClose = styled.div`
  display: flex;
  margin-left: auto;

  ${({ submenuActive }) => {
    if (submenuActive) {
      return css`
        ${mq('md')} {
          display: none;
        }
      `;
    }
  }}
`;

export const MenuBody = styled.div`
  flex: 1;
`;

export const MenuBodyNav = styled.div`
  background: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
  min-height: 100%;
`;

export const MenuDivider = styled.li`
  ${({ theme }) => spacing({ theme, mb: 5 })};
  background-color: ${({ theme }) => theme.mainMenu.container.divider};
  flex: none;
  height: 1px;
`;

export const MenuSubBody = styled.div`
  background-color: ${({ theme }) => theme.mainMenu.container.backgroundSecondary};
  flex: 1;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  transition: all ${() => `${MOTION_SPEED_FAST}ms`};
  width: 100%;
  z-index: 1;

  ${mq('md')} {
    opacity: 0;
    transform: translateX(0);
    z-index: -1;
  }

  ${({ active }) => {
    if (active) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0);

        ${mq('md')} {
          opacity: 1;
          transform: translateX(100%);
        }
      `;
    }
  }};
`;

export const List = styled.ul`
  ${listReset};
  ${({ theme }) => spacing({ theme, py: { xs: 4, md: 0 } })};
  background: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
  display: flex;
  display: none;
  flex-direction: column;
  height: calc(100% - ${({ theme }) => theme.pageHeader.height.xs}px);
  margin-top: ${({ theme }) => theme.pageHeader.height.xs}px;
  opacity: 0;
  overflow-y: auto;
  transition: opacity ${() => `${MOTION_SPEED_FAST}ms`};
  width: 100%;

  & & {
    display: flex;
    opacity: 1;
    background: ${({ theme }) =>
    theme.mainMenu.container.backgroundSecondary};
  }

  &.transition-in {
    display: flex;
  }

  &.isActive {
    display: flex;
    opacity: 1;
  }
`;

export const Item = styled.li`
  color: ${({ theme }) => theme.mainMenu.item.color};
  display: block;
  flex: none;

  &:last-child {
    ${({ theme }) => spacing({ theme, mb: 4 })};
  }

  &.loginButton {
    ${mq('md')} {
      display: none;
    }
  }
`;

export const Link = styled(NavLink)`
  ${({ theme }) => spacing({ theme, py: '16px', pl: { xs: '20px', md: 5 }, pr: { xs: '20px', md: 3 } })};
  align-items: center;
  color: ${({ theme }) => theme.mainMenu.item.color};
  display: flex;
  flex-wrap: nowrap;
  outline: 0;
  text-decoration: none;

  body:not(.safe-focus-removal) &:focus {
    box-shadow: inset 0 3px 0 0 ${({ theme }) => theme.colors.element.focusedGrey}, inset 0 -3px 0 0 ${({ theme }) => theme.colors.element.focusedGrey};
    outline: 0;
  }

  &:focus {
    ${safeFocusRemoval};

    body:not(.safe-focus-removal) & {
      background-color: ${({ theme }) => theme.mainMenu.item.backgroundActive};
    }
  }

  &[href] {
    position: relative;
    transition: background-color 0.1s linear;

    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.mainMenu.item.backgroundActive};
    }
  }

  .submenu & {
    ${({ theme }) => spacing({ theme, py: '18px' })};
  }

  .submenu-active & {
     background: ${({ theme }) => theme.mainMenu.item.backgroundActive};
  }

  ${({ className }) => {
    if (className === "with-description") {
      return css`
        ${mq('md')} {
          align-items: flex-start;
        }
      `;
    }
  }}
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: '16px', ml: 6, mr: 5 })};
  outline: 0;

  body:not(.safe-focus-removal) &:focus {
    background-color: ${({ theme }) => theme.mainMenu.item.backgroundActive};
  }
`;

export const GroupLabelWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 2, mx: { xs: '20px', md: 5 } })};
  color: ${({ theme }) => theme.mainMenu.item.groupLabelColor};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const LabelWrapper = styled.div`
  flex: 1;
`;

export const Label = styled.span`
  ${fixedTitle16};
  color: ${({ theme }) => theme.mainMenu.item.color};
  display: inline-flex;
  font-weight: bold;
  line-height: 1.2 !important;

  ${Link}[href]:active &,
  ${Link}[href]:hover &,
  .submenu-active > a & {
    color: ${({ theme }) => theme.mainMenu.item.colorHighlight};
  }

  ${mq('md')} {
    ${fixedTitle18};

    .submenu & {
      ${fixedTitle16};
    }
  }
`;

export const Description = styled.span`
  ${textBody2};
  color: ${({ theme }) => theme.mainMenu.item.description};
  display: block;
  overflow: hidden;

  ${({ theme }) => spacing({ theme, mt: '4px', mb: 0 })};
  &:first-of-type {
    margin-top: 0;
  }

  body:not(.safe-focus-removal) ${Link}[href]:focus & {
    color: ${({ theme }) => theme.mainMenu.item.descriptionActive};
  }

  ${Link}[href]:active &,
  ${Link}[href]:hover & {
    color: ${({ theme }) => theme.mainMenu.item.descriptionActive};
  }
`;

export const LinkEndIcon = styled.div`
  ${({ theme }) => spacing({ theme, ml: '16px' })};
  ${svg(24, 24)};
  flex-shrink: 0;
  height: 24px;
  width: 24px;

  path {
    fill: ${({ theme }) => theme.mainMenu.item.color};

    ${Link}[href]:active &,
    ${Link}[href]:hover &,
    .submenu-active > a & {
      fill: ${({ theme }) => theme.mainMenu.item.colorHighlight};
    }
  }

  ${({ showOnHover }) => {
    if (showOnHover) {
      return css`
        opacity: 0;

        [href]:hover &,
        [href]:focus & {
          opacity: 1;
        }
      `;
    }
  }}
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;

  svg {
    height: 24px;
  }

  path {
    fill: ${({ theme }) => theme.mainMenu.item.color};

    ${Link}[href]:active &,
    ${Link}[href]:hover &,
    .submenu-active > a & {
      fill: ${({ theme }) => theme.mainMenu.item.colorHighlight};
    }
  }

  ${({ position }) => {
    if (position === 'after') {
      return css`
        ${({ theme }) => spacing({ theme, ml: 1 })};
      `;
    }
    if (position === 'before') {
      return css`
        ${({ theme }) => spacing({ theme, mr: 1 })};
      `;
    }
  }}
`;

export const MenuLinkButton = styled(ReactLink)`
  width: 100%;

  ${mq('md')} {
    width: auto;
  }

  ${({ className }) => {
    if (className) {
      return css`
        ${button('default', className)};
        .submenu & {
          width: 100%;
        }
      `;
    }
  }}
  display: inline-flex;
  justify-content: center;
`;

export const MenuCloseButton = styled.button`
  ${buttonReset};
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 64px;

  &:before,
  &:after {
    content: '';
    background-color: ${({ theme }) => theme.mainMenu.toolbar.buttonColor};
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 20px;
  }

  &:before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }

  &:after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme }) =>
    theme.mainMenu.toolbar.activeHighlight};
    }
  }

  &:focus {
    ${safeFocusRemoval}
    outline-color: ${({ theme }) => theme.colors.element.focusedGrey} !important;
    outline-offset: 2px;
    outline-style: solid !important;
    outline-width: 2px;
    position: relative;
  }
`;
