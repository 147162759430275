import styled, {css} from 'styled-components';
import {
  fixedBody12,
  fixedBody14,
} from '_utils/styles/';

export const TextBoxInput = styled.input`
  ${fixedBody14};
  background-color: ${({ theme }) => theme.form.input.background};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.form.input.border};
  color: ${({ theme }) => theme.form.input.color};
  line-height: 24px;
  outline-color: ${({ theme }) => theme.form.input.borderFocus};
  outline-offset: 0px;
  padding: 12px 16px;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.form.input.borderHover};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.form.input.borderFocus};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.form.input.backgroundDisabled};
    border-color: ${({ theme }) => theme.form.input.borderDisabled};
    color: ${({ theme }) => theme.form.input.colorDisabled};
    pointer-events: none;
  }

  ${({ hasError }) => hasError && css`
    &:not(:disabled) {
      border-color: ${({ theme }) => theme.form.input.borderError};
      &:focus {
        outline-color: ${({ theme }) => theme.form.input.borderFocusError};
      }
    }
  `}
`;

export const InvalidMessageContainer = styled.div`
  ${fixedBody12};
  color: ${({ theme }) => theme.form.error.color};
  padding-top: 4px;
`;

export const InvalidMessage = styled.p`
  margin: 0;
`;
