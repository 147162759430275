import React from 'react';

import Icon from '_components/Icon';
import { getDictionaryItem } from '_utils/data/dictionaryItem';

import Menu from './Menu';
import Magazine from './Magazine';
import {
  SecondaryContainerWrapper,
} from './StyledMainMenu';

import { NavigationProps } from "./definitions";
import { IconWrapper, VisitCorporateSiteCTA } from './StyledMagazine';

const SecondaryNavigation: React.FC<NavigationProps> = ({
  activeMenu,
  fields,
  isSticky,
  navigationItems,
  resetMenu
}) => {
  // Get Magazine data
  const datasource = fields?.data?.datasource;
  const magazine = datasource?.globalMagazineShowcase?.targetItem;
  const magazineCoverImage = magazine?.latestMagazine?.targetItem?.coverImage?.jss;
  const magazineDescription = magazine?.primaryNavDescription?.value;
  const magazineUrl = magazine?.latestMagazine?.targetItem?.url?.jss?.value;
  const magazineCtaLabel = magazine?.ctaLabel?.value;
  const magazineTitle = magazine?.componentLabel?.value;
  const visitCpaAustraliaLabel = getDictionaryItem('visit-cpa-australia-label', 'Visit CPA Australia');
  const subscribeToNewsletterLink = fields?.data?.datasource?.subscribeToNewsletterLink?.jss?.value?.href || '/';

  return (
    <SecondaryContainerWrapper>
      <Magazine
          editMode={true}
          componentLabel={magazineDescription}
          coverImage={magazineCoverImage}
          ctaLabel={magazineCtaLabel}
          title={magazineTitle}
          link={magazineUrl}
          subscribeToNewsletterLink={subscribeToNewsletterLink}
          resetMenu={resetMenu}
        />
      <Menu
        activeMenu={activeMenu}
        fields={fields}
        isSticky={isSticky}
        menuID={0}
        navigationItems={navigationItems}
        secondaryNavigation={true}
      />
      <VisitCorporateSiteCTA
        field={{
          value: {
            href: "https://www.cpaaustralia.com.au",
            linktype: 'External',
            target: '_blank'
          }
        }}
        fallbackTitle={visitCpaAustraliaLabel}

      >
        <IconWrapper>
          <Icon ariaHidden={true} name="login" />
        </IconWrapper>
      </VisitCorporateSiteCTA>
    </SecondaryContainerWrapper>
  )
}

export default SecondaryNavigation;