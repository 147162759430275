import React from 'react';
import { withSvg } from '../Svg';

const SvgUnderReview = () => (
  <>
    <path
      d="M22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51809 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92894 4.92894C3.53041 6.32746 2.578 8.10929 2.19215 10.0491C1.8063 11.9889 2.00433 13.9996 2.76121 15.8268C3.51809 17.6541 4.79981 19.2159 6.4443 20.3147C8.08879 21.4135 10.0222 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12ZM4 12C4 10.4178 4.4692 8.87104 5.34825 7.55544C6.2273 6.23985 7.47673 5.21447 8.93854 4.60897C10.4003 4.00347 12.0089 3.84504 13.5607 4.15372C15.1126 4.4624 16.538 5.22433 17.6569 6.34315C18.7757 7.46197 19.5376 8.88743 19.8463 10.4393C20.155 11.9911 19.9965 13.5997 19.391 15.0615C18.7855 16.5233 17.7602 17.7727 16.4446 18.6518C15.129 19.5308 13.5823 20 12 20C9.87827 20 7.84344 19.1572 6.34315 17.6569C4.84286 16.1566 4 14.1217 4 12Z"
      fill="black"
    />
    <path
      d="M16.9062 12.4733C16.8466 12.6266 16.7574 12.7666 16.6435 12.8853L12.8912 16.6307C12.7749 16.7477 12.6366 16.8406 12.4841 16.904C12.3317 16.9674 12.1682 17 12.0031 17C11.838 17 11.6745 16.9674 11.5221 16.904C11.3697 16.8406 11.2313 16.7477 11.1151 16.6307C10.9978 16.5146 10.9048 16.3766 10.8413 16.2244C10.7778 16.0723 10.7451 15.9091 10.7451 15.7443C10.7451 15.5795 10.7778 15.4163 10.8413 15.2642C10.9048 15.112 10.9978 14.974 11.1151 14.8579L12.7411 13.2474H8.25078C7.91905 13.2474 7.60091 13.1159 7.36635 12.8817C7.13178 12.6476 7 12.33 7 11.9989C7 11.6678 7.13178 11.3503 7.36635 11.1161C7.60091 10.882 7.91905 10.7505 8.25078 10.7505H12.7411L11.1151 9.13997C10.8795 8.90488 10.7472 8.58603 10.7472 8.25356C10.7472 7.9211 10.8795 7.60225 11.1151 7.36716C11.3506 7.13207 11.67 7 12.0031 7C12.3362 7 12.6556 7.13207 12.8912 7.36716L16.6435 11.1125C16.7574 11.2313 16.8466 11.3713 16.9062 11.5245C17.0313 11.8285 17.0313 12.1694 16.9062 12.4733Z"
      fill="black"
    />
  </>
);

export default withSvg(24, 24)(SvgUnderReview);
