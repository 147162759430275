import classNames from 'classnames';
import { compose } from 'ramda';
import React from 'react';

import {
  CoveoNoIndexWrapper,
  withDataSourceValidation,
  withEditMode,
} from '_containers/BaseComponent';

import ContentCard from '_utils/components/ContentCard';
import HeadingWithDescription from '_utils/components/HeadingWithDescription';

import { normalizeJssFields } from '_utils/helpers/jss';
import { useBreakpoint } from '_utils/hooks/useBreakpoint';

import {
  Container,
  Header,
  LinkButton,
  LinkWrapper,
  List,
  ListItem,
} from './StyledFeatureSection';

import {
  FeatureSectionProps,
  LayoutType,
} from './definitions';
import { featureSectionFieldValidators } from './validation';

const CTA = (props) => {
  const { link, className } = props;
  return (
    <LinkWrapper className={className}>
      <LinkButton field={link} fallbackTitle={link?.fallbackTitle} linkTheme="featured" />
    </LinkWrapper>
  );
}

const responsiveColumnConfig =
{
  '2 Column': {
    col: {
      xs: 12,
      md: 6,
      lg: 6,
      xl: 6,
    }
  },
  '3 Column': {
    col: {
      xs: 12,
      md: 6,
      lg: 4,
      xl: 4,
    }
  },
  'horizontal': {
    col: {
      xs: 12,
      md: 12,
      lg: 12,
      xl: 12,
    }
  }
};

const FeatureSection: React.FC<FeatureSectionProps> = ({ rendering, editMode }) => {

  const fields = rendering?.fields?.data?.datasource || {};
  const params = rendering?.params;
  const uid = rendering?.uid || null;

  const bp = useBreakpoint();
  const layoutType: LayoutType = params?.layoutType || '2 Column';
  // if layout type set to 3-column, mobile behaves as horizontal
  const isHorizontalMode = layoutType === 'Horizontal' ||
    (layoutType === '3 Column' && ['xs', 'sm'].includes(bp));

  const normalized = normalizeJssFields(fields);
  const heading = normalized?.heading;
  const items = normalized?.items || [];
  const link = normalized?.link;
  const headingLevel = params?.headingLevel || 'h3';
  const removeBottomPadding = params?.removeBottomPadding;
  const validItems = !editMode ? items.filter((item) => item.url) : items;
  const hasItems = editMode || (validItems?.length > 0);

  const columnConfig = responsiveColumnConfig[layoutType];

  const level = parseInt(headingLevel?.toLowerCase().replace('h', '')) || 3;
  const shouldShowCTA: boolean = !!(editMode || (link && link?.value?.href));

  const classes = classNames(
    'feature-section',
    {
      'removeBottomPadding': removeBottomPadding,
    });

  return (
    (editMode || hasItems) ?
      <CoveoNoIndexWrapper editMode={editMode}>
        <Container
          className={classes}
          data-component
          id={uid}
          numItems={validItems?.length ?? 0}
        >
          {(heading?.value) && (
            <Header>
              <HeadingWithDescription
                headingField={heading}
                headingLevel={headingLevel}
              >
                {shouldShowCTA && <CTA link={link} className="hideOnMobile" />}
              </HeadingWithDescription>
            </Header>
          )}
          {hasItems &&
            <List>
              {validItems.map((item, index) => (
                <ListItem
                  config={columnConfig}
                  editMode={editMode}
                  key={item.id || index}
                >
                  <ContentCard
                    className={
                      index === 0 &&
                      isHorizontalMode &&
                      heading?.value &&
                      'noTopBorder'
                    }
                    {...item}
                    isHorizontalMode={isHorizontalMode}
                    editMode={editMode}
                    headingLevel={level}
                    showTags
                  />
                </ListItem>
              ))}
            </List>
          }
          {shouldShowCTA && <CTA link={link} className="showOnMobile" />}
        </Container>
      </CoveoNoIndexWrapper>
      : null);
};

export default
  compose(
    withDataSourceValidation(featureSectionFieldValidators),
    withEditMode
  )(FeatureSection);
