import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';

export const cardListFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.items',
    errorMessage: 'At least one card must be provided',
    minLength: 1
  },
  {
    field: 'rendering.fields.data.datasource.items',
    errorMessage: 'Some cards do not have valid links',
    allowRenderOnError: true,
    validators: [
      (field) => field?.link
    ]
  },
  {
    field: 'rendering.fields.data.datasource.items',
    errorMessage: 'Some cards do not have valid titles',
    allowRenderOnError: true,
    validators: [
      (field) => field?.title
    ]
  }
];
