import classnames from 'classnames';
import React from 'react';

import { ImageProps } from "./definitions"
import { ImageSize } from '_utils/types/enums';
import Icon from '_components/Icon';
import {
  createSrcSet,
} from '_utils/media';

import {
  Caption,
  IconWrapper,
  Image,
  ImageFigure,
} from './StyledRichTextImage';


const RichTextImage: React.FC<ImageProps> = ({
  caption,
  image,
  alignment,
  editMode
}) => {
  return (
    <ImageFigure
      className={classnames({ [alignment]: alignment, editMode })}
    >
      {/* //TODO: Width and Height Image ratio */}
      <Image
        className="lazyload"
        field={
          editMode
            ? image
            : {
              value: {
                src: image?.value?.src,
                alt: image?.value?.alt,
                'data-srcset': createSrcSet(image?.value?.src, [
                  ImageSize.MW_384,
                  ImageSize.MW_768
                ])
              }
            }
        }
        width={ImageSize.MW_384}
        height={ImageSize.MW_384}
      />
      {caption &&
        <Caption>
          <IconWrapper>
            <Icon name="photo_camera" />
          </IconWrapper>
          {caption}
        </Caption>
      }
    </ImageFigure>
  )

}

export default RichTextImage;