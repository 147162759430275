import colors from '_containers/Theme/corporate/colors';

export default {
  background: colors.background.tint1,
  color: colors.text.default,
  svgColor: colors.all.interactive500,
  borderColorFocus: colors.element.focused
};

export const darkMode = {
  background: colors.background.darkBlue,
  color: colors.text.neutral,
  svgColor: colors.all.interactive500,
  borderColorFocus: colors.element.focused
};
