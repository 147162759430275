import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, values } from 'ramda';
import React, { useRef } from 'react';
import { canUseDOM } from 'exenv';

import { withEditMode, withThemeOption } from '_containers/BaseComponent';
import { withMemberContext } from '_containers/MemberContext';
import { PlaceholderDashboardSingleColumnProps } from './definitions';

import { usePlaceholderHasChildren } from '_utils/hooks';

import { Container, Section, Wrapper } from './StyledPlaceholderDashboardSingleColumn';

const PlaceholderDashboardSingleColumn: React.FC<PlaceholderDashboardSingleColumnProps> = ({ dashboardApi, rendering, editMode, sitecoreContext }) => {
  const dashboardPlaceholder = rendering?.placeholders?.['jss-components-dashboard-single-column'];
  const isEmpty = (dashboardPlaceholder ?? []).length === 0;
  const sectionClassName = !editMode && isEmpty ? 'is-empty' : '';
  const sectionTheme = rendering?.params?.placeholderTheme;

  const rowRef = useRef<HTMLElement>(null);
  usePlaceholderHasChildren(rowRef)

  // Get member dashboard data 
  const shouldSupressCPDTrackerAndProgramTracker = dashboardApi?.result?.data?.flags?.items?.includes('suppressCpaProgramTracker');

  const isTargetComponentShowOnPage = (targetComponent: string) => {
    let isComponentNameMatch = false;

    if (dashboardPlaceholder?.length) {
      const componentName = "Tabs";
      const filterDashboardPlaceholderContainsTab = dashboardPlaceholder.filter(item => values(item).includes(componentName));

      values(filterDashboardPlaceholderContainsTab).forEach(item => {
        const filterTabsPlaceholder = item?.placeholders;

        values(filterTabsPlaceholder).forEach(tab => {
          // if tab is not empty 
          if (tab?.length) {
            // Looping thru tab to return all tabs 
            values(tab).forEach(item => {
              // Looping thru all tabs to check if componentName match targetComponent
              isComponentNameMatch = item?.componentName === targetComponent;
            })
          }
        })
      });
      return isComponentNameMatch
    };
  }

  return shouldSupressCPDTrackerAndProgramTracker && (isTargetComponentShowOnPage("CPDTracker") || isTargetComponentShowOnPage("ProgramTracker"))
    ? null
    : (
      <Section
        className={`db-sc ${sectionClassName}`}
        sectionTheme={sectionTheme}
      >
        <Container className="container">
          <Wrapper className="row db-row" ref={rowRef}>
            <Placeholder name="jss-components-dashboard-single-column" rendering={rendering} />
          </Wrapper>
        </Container>
      </Section>
    );
}

export default compose(withThemeOption, withEditMode, withMemberContext)(PlaceholderDashboardSingleColumn);
