import React, { useState, Fragment } from 'react';
import { useFormikContext } from 'formik';
import { CareerProfileFormValues } from '_containers/CareerProfileFormContext/definitions';
import GeneralDropdown from '_utils/components/Form/Dropdown/GeneralDropdown';
import { 
  LicencesActionButton, 
  LicencesCheckButton, 
  LicencesCancelButton, 
  LicencesActionButtonWrapper, 
  ConfirmingButton,
  DialogHeadingWrapper,
  TextInput,
  ErrorMessage
 } from '../StyledLicencesListForm';
 import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Icon from '_components/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Heading } from '_utils/components/Form/EditableSection/StyledEditableSection';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: "center",
  },
}));

const getErrorMessage = (errorObj) => {
  if (typeof errorObj === 'string') {
    return errorObj || '';
  }

  return errorObj?.[Object.keys(errorObj)?.[0]] || '' // pick out first key in error obj if the field name describes a field that stores an object with many keys, and possibly has multiple validation errors
};

const LicencesTableRow = ({
  getLicensesOptions,
  addNew,
  item,
  index
}) => {
  const [editTableRow, setEditTableRow] = useState(addNew ?? false)
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  // component local state, not for updating formik context
  const [licenceName, setLicenceName] = useState(item?.licenceName)
  const [number, setNumber] = useState(item?.registrationNumber)

  // Since the referenceData name is the source of truth
  const nameOptions = getLicensesOptions ? getLicensesOptions.map((item)=>item.name) : []

  const { values, setFieldValue, getFieldMeta } = useFormikContext<CareerProfileFormValues>();
  const name = `licences.items.${index}.licenceName`;
  const meta = name ? getFieldMeta(name) : null;
  const hasError = !!((meta?.touched || meta?.initialTouched) && meta?.error);
  const errorMessage = getErrorMessage(meta?.error);


  const getLicences = values?.licences?.items;
  const updated = [...getLicences]

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    updated.splice(index, 1)
    setFieldValue('licences.items', updated)
    setOpen(false);
  };

  const handleRegistrationNumber = (event) => {
    const inputValue = event.target.value;
    // Only allow alphanumeric characters
    const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    setNumber(filteredValue);
  };

   // get the field and label values
  const cancelLabel = getDictionaryItem('form-career-license-cancel', 'Cancel');
  const deleteLabel = getDictionaryItem('form-career-license-delete', 'Delete');
  const notProvidedLabel = getDictionaryItem('form-career-registration-number-not-provided', 'Not Provided');
  const confirmDeleteLabel = getDictionaryItem('form-career-license-confirmation-delete', 'Are you sure you want to delete this item?');
  
  const showTableRow = (edit) => {
    if (edit) {
      return (
        <tr style={{height: 96}}>
          <td>
            <GeneralDropdown
              value={item?.licenceName}
              getOptionLabel={(option) => { 
                return option
              }}
              multiple={false}
              name={`licences.items.${index}.licenceName`}
              id={`licences.items.${index}.licenceName`}
              options={nameOptions ?? []}
              placeholder={'Select'}
              shouldShowCheckboxes={false}   
              hasError={!!hasError}         
            />
            {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </td>
          <td>
            <TextInput
              id={`licences.items.${index}.registrationNumber`}
              value={number}
              onChange={handleRegistrationNumber}
              maxLength={100}
              placeholder="Alphanumeric"
            />
          </td>
          {/* make them as a whole or seprate? */}
          <td>
            <LicencesActionButtonWrapper>
              <LicencesCheckButton style={{marginRight: 10, width: 48}} onClick={() => {
                // the result is confirmed, so sync with formik state
                setFieldValue(`licences.items.${index}.registrationNumber`, number)
                item.addNew = false
                setEditTableRow(false) 
                
              }}><Icon name="check" /></LicencesCheckButton>

              <LicencesCancelButton onClick={() => {
                // since it is a cancel function, the value is not updated in formik, so since local state with formik
                setFieldValue(`licences.items.${index}.registrationNumber`, number)
                item.addNew = false
                setEditTableRow(false) 
               
              }}> {cancelLabel}</LicencesCancelButton>
            </LicencesActionButtonWrapper>
          </td>
        </tr>)
    } else {
      return (<tr><td>
        {item?.licenceName}
      </td>
        <td>
        {item?.registrationNumber || notProvidedLabel}
        </td>


        {/* make them as a whole or seprate? */}
        <td>
          <LicencesActionButtonWrapper>
            <LicencesActionButton onClick={() => {
              setEditTableRow(true)
            }}><Icon ariaHidden={true} name="edit" /></LicencesActionButton>

            <LicencesActionButton onClick={() => {
              handleClickOpen()
            }}>
              <Icon ariaHidden={true} name="delete" /></LicencesActionButton>
          </LicencesActionButtonWrapper>

        </td></tr>)
    }
  }

  return (
    <Fragment>
        {showTableRow(editTableRow)}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogHeadingWrapper><Heading>{confirmDeleteLabel}</Heading></DialogHeadingWrapper>
          <DialogActions className={classes.dialogActions}>
            <ConfirmingButton onClick={handleDelete} >
              {deleteLabel}
            </ConfirmingButton>
            <LicencesCancelButton onClick={handleClose} >
              {cancelLabel}
            </LicencesCancelButton>
          </DialogActions>
        </Dialog>
    </Fragment>
  );
};

export default LicencesTableRow;