import React from 'react';
import { PublishDateProps } from './definitions';
import { PublishDateText, PublishDateContainer } from './StyledPublishDate';

import dateConverter, { DateData } from '_utils/helpers/dateConverter';

const PublishDate: React.FC<PublishDateProps> = ({
  serverDate,
}) => {
  const formattedDate: DateData = serverDate && dateConverter(serverDate, { utc: true, medium: true });

  if (!formattedDate?.visualDate) {
    return null;
  }

  return (
    <PublishDateContainer>
      <PublishDateText>{formattedDate?.visualDate}</PublishDateText>
    </PublishDateContainer>
  )
}

export default PublishDate;
