import React from 'react';

import {
  EditableSectionContainerProps,
} from './definitions';

import { SectionContainer } from './StyledEditableSection';

const EditableSectionContainer: React.FC<EditableSectionContainerProps> = ({
  children,
  uid,
  ...props
}) => {
  return (
    <SectionContainer id={uid} {...props}>
      {children}
    </SectionContainer>
  );
};

export default EditableSectionContainer;
