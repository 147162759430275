import React from 'react';
import { compose } from 'ramda';
import { LinkTrackingEvent } from '_containers/DigitalDataContext/definitions';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';

import {
  withEditMode,
  withCoveoNoIndexTags,
} from '_containers/BaseComponent';
import { createSrcSet } from '_utils/media';
import { ImageSize } from '_utils/types/enums';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Icon from '_components/Icon';
import {
  ItemsWrapper,
  ImageWrapper,
  BrandImage,
  DetailsWrapper,
  SubscriptionWrapper,
  HeadingText,
  DescriptionText,
  FooterWrapper,
  ArrowWrapper,
  SubscriptionHeadingText,
  PlatformWrappers
} from './StyledPodcastTuner';
import Platform from './Platform';

import { BrandItemProps } from './definitions'
import { withDigitalDataContext } from '_containers/DigitalDataContext';

const imageSrcSizes = [ImageSize.MW_384, ImageSize.MW_512];

const renderPlatforms = (platforms) => platforms?.map((platform) => (
  <Platform
    {...platform}
    key={platform?.id} />
));

const BrandItem: React.FC<BrandItemProps> = ({
  editMode,
  brandIcon,
  mobileBrandIcon,
  brandName,
  description,
  link,
  subscribeLinks,
  isMobile,
  digitalData,
  setDigitalData
}) => {

  const podcastExploreLabel = getDictionaryItem('podcast-explore-podcast-label', 'Explore podcast');
  let img = '';
  if (isMobile) {
    img = mobileBrandIcon?.jss?.value?.src
  } else {
    img = brandIcon?.jss?.value?.src
  }

  const titile = brandName?.jss?.value || '';
  const descriptionText = description?.jss?.value || '';
  const subTitle = subscribeLinks?.targetItem?.heading?.jss?.value || '';
  const exploreLink = link?.jss?.value?.href || '';
  const validSources = subscribeLinks?.targetItem?.podcastSubscriptionSources?.targetItems || [];
  const isWithInterestBrand = titile === 'With Interest';

  // handle analytics
  const dispatchTracking: LinkTrackingEvent = (name, destination) => {
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['link'],
          {
            linkName: name,
            linkDestination: destination,
          },
          digitalData)
      );

      emitTrackEvent('linkClick');
    }
  };

  return (
    <ItemsWrapper>
      <ImageWrapper>
        <BrandImage
          className='lazyload'
          aria-hidden
          draggable={false}
          field={
            editMode
              ? img
              : {
                value: {
                  src: img,
                  alt: 'brandName', // alt text is empty string, as this is a decorative image
                  'data-srcset': createSrcSet(img, imageSrcSizes)
                }
              }
          }
          height={ImageSize.MW_384}
          width={ImageSize.MW_384}
          isWithInterestBrand={isWithInterestBrand}
        />
      </ImageWrapper>
      <DetailsWrapper>
        <HeadingText>
          {titile}
        </HeadingText>
        <DescriptionText>
          {descriptionText}
        </DescriptionText>
        <FooterWrapper onClick={editMode ? () => { }
          :
          () => {
            dispatchTracking(titile, exploreLink);
          }}>
          <a href={exploreLink} style={{ display: 'flex' }}>
            <span style={{ fontWeight: 'bold' }}>{podcastExploreLabel}</span>
            <ArrowWrapper>
              <Icon name="arrow_forward" ariaHidden />
            </ArrowWrapper>
          </a></FooterWrapper>
      </DetailsWrapper>
      <SubscriptionWrapper item>
        <SubscriptionHeadingText>
          {subTitle}
        </SubscriptionHeadingText>
        <PlatformWrappers>
          {renderPlatforms(validSources)}
        </PlatformWrappers>
      </SubscriptionWrapper>
    </ItemsWrapper>
  );
}

export default compose(withEditMode, withDigitalDataContext, withCoveoNoIndexTags)(BrandItem);