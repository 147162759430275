import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, { useRef } from 'react';

import { withEditMode, withThemeOption } from '_containers/BaseComponent';
import { excludeThemeColors } from '_containers/Theme/global';

import { Section } from '_utils/components';
import { usePlaceholderHasChildren } from '_utils/hooks';
import { getThemeClasses } from '_utils/props/theme';
import { PlaceholderFullWidthProps } from './definitions';
import { ShouldShowBorderTop, Wrapper } from './StyledPlaceholderFullWidth';

const PlaceholderFullWidth: React.FC<PlaceholderFullWidthProps> = ({ rendering, editMode }) => {
  const isEmpty = (rendering?.placeholders?.['jss-components-fullwidth'] ?? []).length === 0;

  const isEmptyClassName = !editMode && isEmpty ? 'is-empty' : null;

  const sectionTheme =
    (rendering?.params?.placeholderTheme &&
      excludeThemeColors.indexOf(rendering?.params?.placeholderTheme.toLowerCase()) === -1)
      ? rendering?.params?.placeholderTheme.toLowerCase()
      : '';
  const backgroundColor = rendering?.params?.backgroundColor;
  const defaultClassName = 'default';

  // TODO consolidate into `getThemeClasses` util
  const staticClassArray = [];

  if (backgroundColor) {
    staticClassArray.push(backgroundColor.toLowerCase());
  }

  if (isEmptyClassName) {
    staticClassArray.push(isEmptyClassName);
  }

  if (sectionTheme) {
    staticClassArray.push(sectionTheme);
  }

  if (staticClassArray.length === 0) {
    staticClassArray.push(defaultClassName);
  }

  const staticClasses = staticClassArray.join(' ');

  const rowRef = useRef<HTMLElement>(null);

  usePlaceholderHasChildren(rowRef)

  return (
    <Section
      backgroundColor={backgroundColor}
      className={`ph ph-fw ${getThemeClasses(rendering?.params)} ${staticClasses}`}
    >
      {rendering?.params?.showTopBorder === "1" &&
        <ShouldShowBorderTop />
      }
      <Wrapper className='row' ref={rowRef}>
        <Placeholder name="jss-components-fullwidth" rendering={rendering} />
      </Wrapper>
    </Section>
  );
};

export default compose(withThemeOption, withEditMode)(PlaceholderFullWidth);
