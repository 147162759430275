import styled, { css } from 'styled-components';

import { AnnouncementWrapper } from '_components/Announcements/InPageAnnouncement/StyledInPageAnnouncement';
import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import {
  button,
  color,
  headingH4,
  mq,
} from '_utils/styles';

export const SectionContainer = styled(Grid.Container)`
  ${({ theme }) => spacing({ theme, px: 3, pt: 3, pb: { xs: '6px', sm: '12px' }, mb: 3 })};
  background: ${({ theme }) => theme.complexFormElement.editableSection.sectionContainer.background};
  border: 1px solid;
  border-color: ${({ theme }) => theme.complexFormElement.editableSection.sectionContainer.borderColor};
  border-radius: 4px;

  ${({ isEditing }) => isEditing && css`
    border-top: 6px solid ${({ theme }) => theme.complexFormElement.editableSection.sectionContainer.isEditing.borderColor};
    box-shadow: ${({ theme }) => theme.shadows.elevation16};
    padding-top: 11px;

    ${mq('sm')} {
    padding-top: 19px;
  }
`};

  ${AnnouncementWrapper} {
    margin-top: 0;
    ${({ theme }) => spacing({ theme, mb: 4 })};
  };
`;

export const HeadingContainer = styled.div`
  ${({ theme }) => spacing({
  theme,
  mb: {
    xs: 3,
    sm: 5,
    md: 3,
    lg: 4,
  }
})};

  ${mq('sm')} {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
`;

export const HeadingIcon = styled.div`
  ${({ theme }) => spacing({
  theme,
  mr: {
    xs: 0,
    sm: 3,
    md: 2,
  },
  mb: {
    xs: 3,
    sm: 0,
  }
})};

  color: ${({ theme }) => theme.complexFormElement.editableSection.headingIcon.color};
  display: flex;
  height: 32px;
  width: 32px;

  svg {
    width: 100%;
  }
`;

export const Heading = styled.h3`
  ${headingH4}
  ${({ theme }) => spacing({
  theme,
  mt: {
    xs: 2,
    sm: '4px',
  },
  mb: {
    xs: 3,
    sm: 0,
  }
})};

  flex: 1;
`;

export const HeadingButton = styled.button`
  ${button('slim', 'tertiary')};
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 10px;
  width: 100%;

  ${mq('sm')} {
    width: auto;
  }

  svg {
    ${({ theme }) => spacing({ theme, ml: 1 })};
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
  }
`;

export const SectionActions = styled.div`
  ${({ theme }) => spacing({ theme, mt: 5 })};
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  ${mq('sm')} {
    flex-direction: row;
  }
`;

export const CancelButton = styled.button`
  ${button('default', 'tertiary')};
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  ${mq('sm')} {
    width: auto;
  }
`;

export const SaveButton = styled.button`
  ${button('default', 'primary')};
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ theme }) => spacing({ theme, mb: { xs: 5, sm: 0 } })};

  ${mq('sm')} {
    width: auto;
  }

  &:disabled {
    background: ${({ theme }) => theme.complexFormElement.editableSection.saveButton.disabled.background};
    box-shadow: none;
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }) => theme.complexFormElement.editableSection.saveButton.active.background};
    }
  }
`;