import React from 'react';

export const BookHingeSVG = () => (
  <svg
    width="31"
    height="101"
    viewBox="0 0 31 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.176 29.347A56.857 56.857 0 010 0v100.531a56.836 56.836 0 018.172-29.349A56.859 56.859 0 0130.33 50.266a56.881 56.881 0 01-22.153-20.92z" />
  </svg>
);
