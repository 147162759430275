import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  Grid,
  Link,
} from '_utils/components';

import {
  button,
  hideOnPrint,
  link,
  linkWithoutVisited,
  mq,
  svg,
  textBody2
} from '_utils/styles';
import { spacing } from '_utils/props';

export const Header = styled.div`
  border-bottom: ${({ theme }) => theme.featureSection?.header?.border};
  color: ${({ theme }) => theme.featureSection.color};
  ${({ theme }) => spacing({ theme, pb: 3, mb: { xs: 3, md: 4, lg: 6 } })}

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.featureSection.headingColor ? theme.featureSection.headingColor : null};
    margin-bottom: 0;
  }
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  ${({ theme }) => spacing({ theme, pb: 6 })}
  padding-top: 0;

  @media print {
    display: inline-block !important;
    flex-direction: none;
    margin: 0;
    max-width: 25%;

    a {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.featureSection.background};

  // override global spacing rules as required
  &.removeBottomPadding {
    margin-bottom: 12px !important;
  }
`;

export const List = styled(Grid.Row)`
  padding: 0;

  /* beat specificity of grid breakpoints */
  && {
    margin-top: 0;
  }

  @media print {
    width: 100%;
  }

  .noTopBorder {
    border-top: 0;
  }
`;

export const FooterLinkContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const FooterLink = styled(NavLink)`
  ${link};
`;

export const FooterLinkLabel = styled(Text)`
  ${textBody2}
`;

export const ArrowWrapper = styled.span`
  ${svg(14, 14)}
  margin-left: 6px;
  margin-right: 2px;
  width: 14px;

  path {
    fill: ${({ theme }) => theme.featureSection.action};
  }
`;

export const LinkButton = styled(Link)`
  a {
    ${linkWithoutVisited}
    ${button('slim', 'tertiary')}
    width: 100%;

    svg {
      top: 2px;
    }

    ${mq('md')} {
      width: auto;
    }
  }
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4, mb: 7 })};

  ${mq.lessThan('md')} {
    border-top: 1px solid ${({ theme }) => theme.colors.ui.divider};
    ${({ theme }) => spacing({ theme, pt: { xs: 6 }, mt: { xs: 0 } })};
  }

  &.hideOnMobile {
    display: none;
  }

  ${mq('md')} {
    &.hideOnMobile {
      display: block;
    }

    &.showOnMobile {
      display: none;
    }

   ${({ theme }) => spacing({ theme, my: 0 })};
  }

  ${hideOnPrint}
`;
