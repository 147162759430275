import { compose } from 'ramda';
import React, { FC } from 'react';

import { ImageBanner } from '_components/PageBannerWithInternalNavigation/ImageBanner';
import {
  withDataSourceValidation,
  withEditMode,
  withSitecoreContext,
} from '_containers/BaseComponent';
import { normalizeJssFields } from '_utils/helpers/jss';
import { isCorporateSite as isCorporateSiteContext } from '_utils/helpers/render';
import { PageBannerWithInternalNavigationFieldValidators } from './validation';

import {
  ImageBannerProps,
  NormalisedPageBannerWithInternalNavigationPageFields,
  PageBannerWithInternalNavigationProps,
  RenderingParams,
} from './definitions';

import { PageBannerWithInternalNavigationWrapper } from './StyledPageBanner';
import { InternalNavigation } from './InternalNavigation';
import { List, StyledGridContainer } from './StyledInternalNavigation';

const PageBannerWithInternalNavigation: FC<PageBannerWithInternalNavigationProps> = ({
  editMode,
  rendering,
  sitecoreContext,
}): JSX.Element => {
  const isCorporateSite = isCorporateSiteContext(sitecoreContext);
  const renderingPageFields: NormalisedPageBannerWithInternalNavigationPageFields = normalizeJssFields(rendering?.fields?.data?.pageFields);
  const renderingParams: RenderingParams = normalizeJssFields(rendering);

  const pageBannerTheme = renderingParams?.params?.pageBannerTheme ?? 'Blue';
  const uid = rendering?.uid;

  // LinkItem(NavLink) expects obj.
  const categoryInformationLinkObj = {
    value: {
      id: '',
      href: renderingPageFields?.categoryInformation?.categoryUrl,
      text: renderingPageFields?.categoryInformation?.categoryLabelText
    }
  };

  const linkField = isCorporateSite
    ? renderingPageFields?.CTAbutton
    : categoryInformationLinkObj;

  const pageFields = rendering?.fields?.data?.pageFields;
  const internalNavigation = renderingPageFields?.children || [];

  const imageBannerProps: ImageBannerProps = {
    datasourceName: renderingPageFields?.datasourceName,
    editMode,
    isCorporateSite,
    linkField,
    pageBannerTheme,
    pageDescription: renderingPageFields?.Description,
    pageImage: renderingPageFields?.Image,
    pageTitle: renderingPageFields?.Heading,
    trackingName: renderingPageFields?.trackingName,
  };


  return (
    <PageBannerWithInternalNavigationWrapper
      className="page-banner-with-internal-navigation-and-image"
      data-component
      id={uid}
    >
      <ImageBanner {...imageBannerProps} />
      <StyledGridContainer>
        <List as="ul">
          {internalNavigation?.map((item, index) => (
            <InternalNavigation
              description={item?.pageDescription}
              editMode={editMode}
              key={index}
              title={item?.pageTitle}
              url={item?.url}
            />
          ))}
        </List>
      </StyledGridContainer>
    </PageBannerWithInternalNavigationWrapper>
  );
};

export default compose(
  withSitecoreContext(),
  withEditMode,
  withDataSourceValidation(PageBannerWithInternalNavigationFieldValidators, false),
)(PageBannerWithInternalNavigation);
