import colors from '_containers/Theme/intheblack/colors';
import { getDictionaryItem } from '_utils/data/dictionaryItem';

export default {
  action: colors.all.brandPrimary1,
  attributeIcon: colors.all.neutral700,
  background: {
    dark: colors.all.brandPrimary1,
    default: colors.all.neutralWhite,
    subtle: colors.all.neutral50,
    subtle2: colors.all.neutral100,
    subtle3: colors.all.neutral200,
  },
  border: {
    dark: colors.all.neutral300,
    default: colors.all.neutral200,
    outline: colors.all.interactive200,
    divider: colors.ui.divider,
    focus: colors.all.interactive500
  },
  button: {
    background: colors.all.neutral200,
    backgroundHover: colors.all.brandPrimary1,
    border: colors.all.brandPrimary2,
    primaryBackground: colors.button.primary.background,
    primaryColor: colors.button.primary.color,
    primaryHover: colors.button.primary.backgroundHover,
    secondaryBackground: colors.button.secondary.background
  },
  cardBackground: colors.all.neutralWhite,
  checkbox: {
    background: colors.text.interactive1,
    backgroundHover: colors.all.brandPrimary1Dark,
    border: colors.all.brandPrimary2
  },
  label: {
    clearAll: getDictionaryItem('search-filters-clear-all-label', 'Clear all'),
    clearFilter: getDictionaryItem('search-filters-clear-label', 'Clear filter'),
    showFewerItem: getDictionaryItem('search-filters-show-fewer-label', 'Show fewer categories'),
    showAllItem: getDictionaryItem('search-filters-show-all-label', 'Show all categories'),
  },
  loading: {
    icon: colors.all.interactive600,
  },
  link: {
    color: colors.all.interactive600,
    hover: colors.all.interactive700
  },
  pagination: {
    backgroundActive: colors.background.tint4,
    text: colors.text.default,
    colorHover: colors.text.interactive1Hover
  },
  text: {
    attributes: colors.text.secondary,
    category: colors.text.interactive1,
    categoryHover: colors.text.interactive1Hover,
    dark: colors.all.neutral600,
    dark2: colors.all.neutral900,
    default: colors.all.neutral700,
    error: colors.all.error500,
    heading: colors.text.default,
    light: colors.all.neutralWhite,
    primary: colors.all.brandPrimary1,
    primaryDark: colors.all.brandPrimary3,
    subtle: colors.all.neutral500,
    subtle2: colors.all.neutral400,
    subtle3: colors.all.neutral300,
  },
};
