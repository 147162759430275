import { canUseDOM } from 'exenv';
import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '_components/Icon';
import SearchIcon from '_utils/icons/material/Search'; 
import ScreenReader from '_utils/components/ScreenReader';
import { buildSearchUrl } from '_utils/helpers';


import { MainSearchProps } from './definitions';

import {
  ClearButton,
  ClearIcon,
  Form,
  IconWrapper,
  Input,
  InputIcon,
  InputWrapper,
  Submit
} from './StyledMainSearch';

const ESCAPE_KEY_CODE = 27;

const MainSearch: FC<MainSearchProps> = ({
  inputRef,
  placeholder,
  onSubmit = () => { },
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [focused, setFocused] = useState(false);

  const location = useLocation();
  const { search, pathname } = location || {};
  const searchParams = new URLSearchParams(search);
  const currentUrlSearchTerm = searchParams.get('term');
  const [searchTerm, setSearchTerm] = useState(currentUrlSearchTerm || '');

  if (!placeholder) {
    placeholder = t('search-placeholder-text');
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === ESCAPE_KEY_CODE) {
      setSearchTerm('');
    }
  };

  const handlePressClear = (e) => {
    if (e) {
      e.preventDefault();
    }

    setSearchTerm('');
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (onSubmit) {
      onSubmit()
    }

    if (searchTerm) {
      history.push(buildSearchUrl(
        { term: searchTerm, page: 1 }),
        // store where search was triggered from
        { from: (canUseDOM && document?.title) ?? '' }
      );
    }
  };

  useEffect(() => {
    if (pathname !== buildSearchUrl({})) {
      setSearchTerm('');
    }
  }, [pathname]);

  return (
    <Form
      id="main-search"
      action="#" // Defining the action prop along with an input with type="search" makes the iOS return key say "Search".
      role="search"
      className={`main-search ${(focused || searchTerm) ? 'search--focused' : ''}`}
      onSubmit={handleSubmit}
    >
      <InputWrapper>
        <InputIcon>
          <SearchIcon />
        </InputIcon>
        <Input
          ref={inputRef}
          type="search"
          placeholder={placeholder}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyDown={handleKeyDown}
          aria-label="Search"
          value={searchTerm}
        />
        {searchTerm && (
          <ClearButton
            type="button"
            onClick={handlePressClear}
            aria-label="Clear search"
          >
            <ClearIcon>
              <Icon ariaHidden={true} name="close" />
            </ClearIcon>
          </ClearButton>
        )}
      </InputWrapper>
      <Submit
        type="submit"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <ScreenReader>
          {t('search-accessible-search-button-label')}
        </ScreenReader>
        <IconWrapper>
          <SearchIcon />
        </IconWrapper>
      </Submit>
    </Form>
  );
};

export default MainSearch;
