import { ILinkColors } from '_containers/Theme/definitions';

// TODO future use?
// remove native button styling for buttons as links
export function buttonAsLinkReset() {
  return `
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    line-height: inherit;
    padding: 0;
    text-decoration: inherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  `;
}

export function linkStyle(settings: ILinkColors) {
  return `
      border-bottom: 3px solid ${settings.border};
      color: ${settings.color};
      position: relative;
      text-decoration: ${settings.textDecoration};
      transition: background-color 0.1s linear, color 0.1s linear;

      &:visited {
        border-color: ${settings.borderVisited};
        color: ${settings.colorVisited};

        &:hover {
          background-color: ${settings.backgroundHover};
        }
        .no-visited-link & {
          color: inherit;
        }
      }

      &:hover, &:active {
        background-color: ${settings.backgroundHover};
        border-color: ${settings.borderHover};
        color: ${settings.colorHover};
        cursor: pointer;

        path {
          fill: ${settings.colorHover};
        }
      }

      &:focus {
        background-color: ${settings.backgroundHover};
        color: ${settings.colorFocus};
        cursor: pointer;
        outline-offset: 2px;
        outline: 1px solid ${settings.borderFocusColor};
        text-decoration: none;

        path {
          fill: ${settings.colorHover};
        }
      }

      /* same styles but keep seperate. not supported by
         IE11 so will break other hover/focus selectors */
      &:focus-within {
        background-color: ${settings.backgroundHover};
        box-shadow: inset 0 -2px ${settings?.boxShadowColor};
        color: ${settings.colorFocus};
        cursor: pointer;
        text-decoration: ${settings.textDecorationFocus};
      }

      &:not([target=_blank]).link-featured {
        border: none;
        display: inline-block;
        font-weight: ${settings.fontWeight};
        padding-left: 4px;
        padding-right: 30px;
        text-transform: ${settings.textTransform};
        ${settings.textBody};

        &:visited {
          text-decoration: underline;
        }

        &:after {
          /* NOTE: trying to access ${settings.color} for fill failing in browser */
          background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="%23${(settings?.color?.replace('#', ''))}"/%3E%3C/svg%3E');
          background-position: center right;
          background-repeat: no-repeat;
          background-size: 14px 14px;
          content: '';
          height: 100%;
          position: absolute;
          right: 6px;
          top: 0;
          transition: background-image 0.1s linear, color 0.1s linear, background-color 0.1s linear;
          width: 20px;
        }

        &:focus {
          border-radius: ${({ theme }) => `${theme?.focus?.radius}`};
          color: ${settings.colorFocus};
          outline: 1px solid ${settings.borderFocusColor};
          outline-offset: -2px;

          &:hover, &:active {
            text-decoration: ${settings.textDecorationFocus};
          }
        }

        &:hover {
          text-decoration: underline;
        }


      &:focus-within {
        box-shadow: none;
        text-decoration: ${settings.textDecorationFocus};
      }
    }
  `;
}

export const link = ({ theme }) => `${linkStyle(theme.colors.link.primary)}`;
export const linkFeatured = ({ theme }) => `${linkStyle(theme.colors.link.featured)}`;
export const linkButton = ({ theme }) => `${linkStyle(theme.colors.button.primary)}`;
export const linkSecondary = ({ theme }) => `${linkStyle(theme.colors.link.secondary)}`;

export const linkReset = () => `
  color: inherit;
  margin: 0;
  text-decoration: none;
`;

export const linkWithoutVisited = ({ theme }) =>
  `${linkStyle(theme.colors.link.primaryWithoutVisited)}`;
