import colors from '_containers/Theme/corporate/colors';


export default {
  formSection: {
    background: colors.background.tint1,
    borderColor: colors.ui.divider,
    headingIcon: {
      color: colors.element.primary
    },
    richTextContainer: {
      borderColor: colors.ui.divider
    }
  },
  formContainer: {
    borderColor: colors.ui.divider
  },
  stickyFooter: {
    background: colors.background.tint1,
    borderColor: colors.ui.defaultBorderColor,
  },
  fieldSummary: {
    label: {
      color: colors.text.subtle,
    },
    noValue: {
      color: colors.text.nonessential
    },
    footNote: {
      color: colors.text.subtle
    }
  },
  editableSection: {
    sectionContainer: {
      background: colors.background.tint1,
      borderColor: colors.ui.divider,
      isEditing: {
        borderColor: colors.all.primary,
      }
    },
    headingIcon: {
      color: colors.element.primary
    },
    saveButton: {
      disabled: {
        background: colors.background.tint5
      },
      active: {
        background: colors.background.tint5
      }
    }
  },
  dropdown: {
    chips: {
      background: colors.button.ghost.background,
      color: colors.button.ghost.color
    },
    indicator: {
      color: colors.text.interactive1
    },
    overrides: {
      borderColor: colors.all.brandPrimary1,
      backgroundHover: colors.all.neutral50

    },
    manualOption: {
      borderColor: colors.ui.divider,
    },
    manualToggle: {
      color: colors.text.interactive1
    }
  },
  addressManual: {
    toggle: {
      color: colors.text.interactive1
    }
  }
}
