import styled, { css } from 'styled-components';
import {
  fixedBody12,
  fixedTitle18,
  textBody2,
  mq,
} from '_utils/styles';
import { spacing } from '_utils/props';

const strip = () => css`
  :after {
    background-color: ${({ theme }) => `${theme.personalisedCarousel.item.card.background}`};
    border-top-left-radius: ${({ theme }) => theme.personalisedCarousel.item.card.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.personalisedCarousel.item.card.borderRadius}px;
    content:'';
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .fullwidth &:after{
    ${mq('md')} {
      bottom: 0;
      height: 100%;
      right: unset;
      top: 0;
      width: 6px;
    }
  }
`;


export const StyledTitle = styled.div`
  > * {
      ${fixedTitle18}

      margin-top: 0;
      margin-bottom: 8px;
  }
`;
const container = css`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq('lg')} {
    height: 100%;
  }
`;

export const StyledCard = styled.a`

  ${({ theme }) => spacing({ theme, pr: 3, pl: 3, pb: 3 })}
  ${container}
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  padding-top: 24px;
  position: relative;

  ${strip()}
  border-radius: ${({ theme }) => theme.personalisedCarousel.item.card.borderRadius}px;
  box-shadow: ${({ theme }) => theme.personalisedCarousel.item.card.boxShadow};
  transition: box-shadow 0.1s linear;

  &:hover {
    box-shadow: ${({ theme }) => theme.personalisedCarousel.item.card.hoverBoxShadow};
  }

  .fullwidth & {
    ${mq('md')} {
      flex: 1 1 auto;
      flex-wrap: wrap;
      padding: 0;
    }
  }
`;

export const StyledTags = styled.div`
  margin-bottom: 16px;

  & span {
    ${fixedBody12};
    background: ${({ theme }) => theme.personalisedCarousel.item.tags.background};
    border-radius: 3px;
    color: ${({ theme }) => theme.personalisedCarousel.item.tags.color};
    display: inline;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 0 4px;
    text-transform: uppercase;
  }
`;

export const StyledHtmlContent = styled.div`
  &.html-content {
    ${textBody2}

    & p {
      color: ${({ theme }) => theme.personalisedCarousel.item.htmlContent.color};
      margin-bottom: 14px;
    }

    & ul,
    ol {
      list-style: none;
      padding-left: 4px;
      font-weight: bold;

      & li:before {
        content: '$ '
      }
    }
  }
`;