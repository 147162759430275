import colors from '../../containers/Theme/corporate/colors';

export default {
  background: colors.all.neutralWhite,
  backgroundActive: colors.background.tint4,
  backgroundHover: colors.background.tint2,
  primary: colors.all.neutral600,
  action: colors.all.neutral300,
  actionHover: colors.all.neutral900,
  icon: colors.all.brandPrimary1,
  borderRadius: 0,
  border: `1px solid`,
  borderColor: colors.all.neutral300,
  borderHover: `1px solid inherit`,
};

export const darkMode = {};