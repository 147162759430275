import colors from '_containers/Theme/corporate/colors';

export default {
  background: colors.all.neutral50,
  backgroundActive: colors.all.neutralWhite,
  border: `1px solid ${colors.all.neutral200}`,
  borderActive: `2px solid ${colors.all.brandPrimary1}`,
  iconFill: colors.all.neutral400,
  inputBorderRadius: '74px 0 0 74px',
  inputBorderRadiusLessThanMd: '74px',
  outlineColour: colors.all.interactive300,
  clear: {
    borderRadius: '1px',
    height: '4px',
    iconFill: colors.all.neutral500,
    left: '-10px',
    top: '-1px',
    width: '8px',
  },
  submit: {
    background: colors.all.neutral200,
    backgroundActive: colors.all.brandPrimary1,
    borderHover: colors.all.interactive600,
    borderRadius: '0 74px 74px 0',
    height: '4px',
    iconFill: colors.all.neutral700,
    iconFillActive: colors.all.neutralWhite,
    left: '0',
    minWidth: '56px',
    padding: '0px 5px 0px 0px',
    top: '-2px',
    width: '2px',
  },
};
