import canUseDOM from 'exenv';
import React, { useLayoutEffect } from 'react';

function getParentSection(element: HTMLElement): HTMLElement | null {
  if (element === null) {
    return null;
  }

  if (element?.nodeName.toLowerCase() === 'section') {
    return element;
  }

  return getParentSection(element.parentElement);
}

export default function usePlaceholderHasChildren(
  rowRef: React.MutableRefObject<HTMLElement>,
) {
  useLayoutEffect(() => {
    if (!canUseDOM) {
      return;
    }

    let counter = 0;

    const effectItv = setInterval(() => {
      if (counter === 5) {
        clearInterval(effectItv);

        if (rowRef?.current && !rowRef?.current?.hasChildNodes()) {
          getParentSection(rowRef?.current?.parentElement)?.classList.add(
            'is-empty',
          );
        }
      }

      ++counter;
    }, 100);
  }, [rowRef.current]);
}
