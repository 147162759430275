import { Field } from "@sitecore-jss/sitecore-jss-react";
import { ImageField } from "@sitecore-jss/sitecore-jss-react/types/components/Image";
import { LinkProps } from "@sitecore-jss/sitecore-jss-react/types/components/Link";

import { AuthenticatedAccessCheck } from '_utils/types';

export const simpleCardTypes = ['icon', 'textOnly'];

export const stylesThatRenderImage = {
  campaign: true,
  category: true,
  podcastSeries: true,
  podcast: true,
};

export const stylesThatRenderIcon = {
  icon: true,
};

export const stylesThatRenderLogo = {
  inPractice: true,
  inTheBlack: true,
};

export const stylesThatRenderDate = {
  contentDetail: true,
  inPractice: true,
  inTheBlack: true,
  // new added cards
  podcastEpisodeCard: true,
};

export const stylesThatRenderPodcastDate = {
  podcast: true,
};

export const stylesThatRenderPodcastSeriesDate = {
  podcastSeries: true,
};

export const stylesThatRenderPodcastSeriesCardDate = {
  podcastSeriesCard: true
};

export const stylesThatRenderAuthor = {
  inPractice: true,
  inTheBlack: true,
};


export const stylesThatRenderColourStrip = {
  campaign: false,
  category: false,
  contentDetail: true,
  icon: true,
  inPractice: true,
  inTheBlack: true,
  podcastSeries: true,
  podcast: true,
  textOnly: false,
  // new added cards
  podcastEpisodeCard: true,
  podcastSeriesCard: true
};

export const stylesThatRenderDescription = {
  campaign: true,
  category: true,
  contentDetail: true,
  icon: true,
  inPractice: true,
  inTheBlack: true,
  podcastSeries: true,
  podcast: true,
  textOnly: true,
  // new added cards
  podcastEpisodeCard: true,
  podcastSeriesCard: true
};

export const stylesThatRenderAttributes = {
  campaign: true,
  category: true,
  contentDetail: true,
  inPractice: true,
  inTheBlack: true,
  podcastSeries: true,
  podcast: true,
  // new added cards
  podcastEpisodeCard: true,
  podcastSeriesCard: true
};

export const stylesThatRenderTags = {
  campaign: true,
  category: true,
  contentDetail: true,
  inPractice: true,
  inTheBlack: true,
  podcastSeries: true,
  podcast: true,
  // new added cards
  podcastEpisodeCard: true,
  podcastSeriesCard: true
};

export const stylesThatRenderReadingTime = {
  contentDetail: true,
  podcastEpisodeCard: true,
};

export const stylesThatRenderListeningTime = {
  podcast: true
};

export const stylesThatRenderEpisode = {
  podcastSeries: true,
  podcastSeriesCard: true
};

export const cardTypeLabels = {
  campaign: 'campaign',
  category: '',
  contentDetail: '',
  icon: '',
  inPractice: 'article',
  inTheBlack: 'article',
  podcastSeries: 'Series',
  podcast: '',
  textOnly: '',
  podcastSeriesCard: 'Series',
  podcastEpisodeCard: ''
}


// replace the props for cardlist props on the podcast. 
export type CardListProps = {
  editMode?: boolean;
  rendering: {
    params: {
      layoutType: 'Carousel' | 'List';
      headingLevel?: string;
      headingLevelAccordionItem?: number | string;
      numVisibleItems?: {
        value?: number;
      },
      // add one more field with Literal type to tell different types of podcastcard 
      type? : 'RemoveGroupByYear'
    },
    fields: {
      data: {
        datasource: {
          type: string;
          items: ItemProps[];
          description?: {
            jss: Field;
          };
          heading?: {
            jss: Field;
          };
          link?: {
            jss: LinkProps;
          }
        };
      };
    };
    uid?: string;
  };
};

type TagType = {
  id: string;
  displayName?: string;
}

export type ItemProps = AuthenticatedAccessCheck & {
  $hasHighlightStrip: boolean;
  id: string;
  icon?: any;
  type: string;
  title: string;
  description: string;
  headingLevel: number;
  link: string;
  listingImage?: ImageField;
  author?: string;
  published?: string;
  noOfEpisodes?: string;
  episodeBrand? : {
    image? : string,
    name?: string
  }
  readingTime?: string;
  listeningTime?: string;
  ctaButtonText?:string;
  tags?: TagType[];
  podcastcards?: [{
    id: string;
    title: string;
    link: string;
    published: string;
    description: string;
  }]
};

export type ListItemProps = ItemProps & {
  editMode: boolean;
};

/**
 * Sitecore to provide data for quantity of carousel items. Presonalisation will populate.
 */
export type PersonalisationItemProps = {
  id: string,
}
