import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';
import { useLocationAPIContext } from '_containers/LocationAPIContext';
import { StateItem } from '_containers/LocationAPIContext/definitions';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Dropdown from '_utils/components/Form/Dropdown';
import { renderSelectPlaceholderForField } from '_utils/helpers/form';

import TextInput from '../TextInput';
import FormLabel from '../Label';

import { FormSummary, Field } from '_utils/components/Form/StyledFormSection';
import { AddressManualProps } from './definitions';

const AddressManual: React.FC<AddressManualProps> = ({ formValuesKey, sitecoreContext }) => {
  const addressLine1Label = getDictionaryItem("form-contact-address-line1", "Address line 1");
  const addressLine2Label = getDictionaryItem("form-contact-address-line2", "Address line 2");
  const addressLine3Label = getDictionaryItem("form-contact-address-line3", "Address line 3");

  const { countryStatesApi, countriesMap, hasCountryGotStates } = useLocationAPIContext();
  const { values: formValues, setFieldValue } = useFormikContext<ContactDetailsFormValues>();
  const [countryStates, setCountryStates] = useState<StateItem[]>([]);

  const selectedCountryIsoCode = formValues?.[formValuesKey]?.country?.isoCode2Letter;
  const selectedCountry = countriesMap?.[selectedCountryIsoCode];
  const selectedState = formValues?.[formValuesKey]?.state;

  const statesPlaceholder = renderSelectPlaceholderForField(selectedCountry?.stateLabel);
  // Set initial selectedCountry based on form values, and subsequent country selection in form and set the selectedCountry
  useEffect(() => {
    if (!!selectedCountry && !!selectedCountry?.iso && hasCountryGotStates(selectedCountry)) {
      countryStatesApi?.request?.({
        axiosRequestConfig: {
          params: {
            isoCode: selectedCountry?.iso,
          }
        },
        useCachedResponse: false,
        allowParallelFetching: true,
        onError: (err) => {
          // TODO: Handle errors from CountryStates API
          console.warn(err);
        },
        onSuccess: (response) => setCountryStates(response?.data?.items)
      });
    } else {
      setCountryStates([]);
    }
  }, [countriesMap, selectedCountryIsoCode]);

  return (
    <>
      {selectedCountry &&
        <>
          <FormSummary>
            <Field>
              <FormLabel htmlFor={`${formValuesKey}.addressLine1`} required>{addressLine1Label}</FormLabel>
              <TextInput
                id={`${formValuesKey}.addressLine1`}
                maxLength={250}
                name={`${formValuesKey}.addressLine1`}
                type="text"
              />
            </Field>
          </FormSummary>
          <FormSummary>
            <Field>
              <FormLabel htmlFor={`${formValuesKey}.addressLine2`}>{addressLine2Label}</FormLabel>
              <TextInput
                id={`${formValuesKey}.addressLine2`}
                maxLength={250}
                name={`${formValuesKey}.addressLine2`}
                type="text"
              />
            </Field>
          </FormSummary>
          <FormSummary>
            <Field>
              <FormLabel htmlFor={`${formValuesKey}.addressLine3`}>{addressLine3Label}</FormLabel>
              <TextInput
                id={`${formValuesKey}.addressLine3`}
                maxLength={250}
                name={`${formValuesKey}.addressLine3`}
                type="text"
              />
            </Field>
          </FormSummary>
          <FormSummary>
            {selectedCountry?.suburbLabel &&
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor={`${formValuesKey}.city`} required={selectedCountry.hasSuburb}>{selectedCountry.suburbLabel}</FormLabel>
                <TextInput
                  id={`${formValuesKey}.city`}
                  maxLength={80}
                  name={`${formValuesKey}.city`}
                  type="text"
                  required={selectedCountry.hasSuburb}
                />
              </Field>
            }

            {selectedCountry?.stateLabel && !!(countryStates?.length ?? 0) &&
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor={`${formValuesKey}.state`} required={selectedCountry.hasState}>{selectedCountry.stateLabel}</FormLabel>
                <Dropdown
                  value={{
                    isoCode: selectedState?.isoCode ?? '',
                    name: selectedState?.name ?? ''
                  }}
                  getOptionLabel={(option) => option?.name}
                  getOptionSelected={(option, value) => option.isoCode === value.isoCode}
                  handleChange={(_, value, reason) => {
                    switch (reason) {
                      case 'clear':
                        setFieldValue(
                          `${formValuesKey}.state`,
                          {
                            isoCode: '',
                            name: '',
                          },
                          false
                        );
                        break;
                      case 'select-option':
                        setFieldValue(
                          `${formValuesKey}.state`,
                          {
                            isoCode: value?.isoCode,
                            name: value?.name,
                          },
                          false
                        );
                        break;
                      default:
                        break;
                    }
                  }}
                  id={`${formValuesKey}.state`}
                  name={`${formValuesKey}.state`}
                  multiple={false}
                  options={countryStates?.map((state) => ({
                    isoCode: state?.code,
                    name: state?.name
                  })) ?? []}
                  placeholder={statesPlaceholder}
                />
              </Field>
            }

            {selectedCountry?.stateLabel && !(countryStates?.length ?? false) &&
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor={`${formValuesKey}.state.name`} required={selectedCountry.hasState}>{selectedCountry.stateLabel}</FormLabel>
                <TextInput
                  id={`${formValuesKey}.state.name`}
                  maxLength={50}
                  name={`${formValuesKey}.state.name`}
                  required={selectedCountry.hasState}
                  type="text"
                />
              </Field>
            }

            {selectedCountry?.postcodeLabel &&
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor={`${formValuesKey}.postCode`} required={selectedCountry.hasPostcode}>{selectedCountry.postcodeLabel}</FormLabel>
                <TextInput
                  id={`${formValuesKey}.postCode`}
                  maxLength={20}
                  name={`${formValuesKey}.postCode`}
                  required={selectedCountry.hasPostcode}
                />
              </Field>
            }
          </FormSummary>
        </>
      }
    </>
  );
};

export default AddressManual;