import React, {useEffect, useState} from 'react';
import { canUseDOM } from 'exenv';
import { InPracticeArticleListProps } from './definitions';
import { InPracticeArticleListContainer,ListItem, List, Container } from './StyledInPracticeArticleList';
import Item from "./Item";


const InPracticeArticleList: React.FC<InPracticeArticleListProps> = ({rendering}) => {

  const getItems = rendering?.fields?.data?.cards?.inpracticeArticles;
  return (
    <InPracticeArticleListContainer>
      <Container
          className="card-list"
          data-component
          id={rendering.uid}
      >
      <h2>Articles</h2>
        <ListItem>
          <List as="ul" className={'fullwidth'}>
              {getItems?.map((item) =>  <Item {...item}> </Item>)}
          </List>
        </ListItem>
      </Container>
    </InPracticeArticleListContainer>
  );
}

export default InPracticeArticleList;
