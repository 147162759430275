import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';

export const ShowcaseHeroFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.items.targetItems',
    errorMessage: 'All 4 In The Black showcase items must be provided',
    minLength: 4,
  },
  {
    field: 'rendering.fields.data.datasource.items.targetItems',
    errorMessage: 'A maximum of four In The Black showcase items will be displayed, any extra cards will not be displayed',
    allowRenderOnError: true,
    maxLength: 4,
  },
  {
    field: 'rendering.fields.data.datasource.items.targetItems',
    errorMessage: 'Some In The Black showcase items do not have valid links',
    validators: [
      (field) => field?.url,
    ],
  },
  {
    field: 'rendering.fields.data.datasource.items.targetItems',
    errorMessage: 'Some In The Black showcase items do not have valid categories',
    validators: [
      (field) => field?.categoryInformation?.categoryLabelText,
    ],
  },
  {
    field: 'rendering.fields.data.datasource.items.targetItems',
    errorMessage: 'Some In The Black showcase items do not have valid headlines',
    validators: [
      (field) => field?.headline,
    ],
  }
];
