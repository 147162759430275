import colors from '_containers/Theme/corporate/colors';

export const validVariants = {
  variant1: {
    value: 'image-title-text-left-horizontal',
    columns: 12,
    headingLevel: 'h4',
    alignment: 'flex-start',
  },
  variant2: {
    value: 'icon-title-text-left-horizontal',
    columns: { xs: 12, sm: 4 },
    headingLevel: 'h4',
    isImageAspectSquare: true,
    alignment: 'flex-start',
  },
  variant3: {
    value: 'image-title-text-vertical',
    columns: { xs: 12, sm: 6, lg: 3 },
    headingLevel: 'h5',
    isImageAspectSquare: true,
    alignment: 'flex-start',
  },
  variant4: {
    value: 'icon-text-center-horizontal',
    columns: { xs: 6, sm: 3 },
    headingLevel: 'h4',
    alignment: 'center',
  },
};

export default {
  headingColor: colors.all.brandPrimary3,
  item: {
    color: colors.text.default
  }
};
