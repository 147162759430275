export const MOTION_SPEED_BASE = 400;
export const MOTION_SPEED_FAST = 400 / 2;
export const MOTION_SPEED_SLOW = 400 * 2;

export const motion = {
    ease: 'cubic-bezier(.27,.23,.08,1)',
    timing: {
        default: `${MOTION_SPEED_BASE}ms`,
        fast: `${MOTION_SPEED_FAST}ms`,
        none: `0ms`,
        slow: `${MOTION_SPEED_SLOW}ms`
    }
}

export const shadows = {
    elevation4: `0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12)`,
    elevation8: `0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12)`,
    elevation16: `0px 1px 4px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.12)`,
    elevation32: `0px 4px 12px rgba(0, 0, 0, 0.08), 0px 24px 64px rgba(0, 0, 0, 0.12)`
};