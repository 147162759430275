import React from 'react';

import Icon from '_components/Icon';

import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { getLink } from '_utils/helpers/link';

import {
  ListItemProps,
} from './definitions';

import {
  ArrowWrapper,
  BackToParentLabel,
  Card,
  Description,
  DetailsColumn,
  FooterWrapper,
  PlaceholderCard,
  Title,
} from './StyledItem';

const Item: React.FC<ListItemProps> = ({
  description,
  editMode,
  headingLevel,
  link,
  title,
  themeOption,
}) => {
  const cardProps = getLink(link);
  const headingType = `h${headingLevel + 1}`;
  const Component = editMode ? PlaceholderCard : Card;
  const backToLabel = themeOption && getDictionaryItem('card-list-with-internal-nagivation-back-to-label', 'Back to');

  return (
    (editMode || cardProps) && (
      <Component
        {...cardProps}
        $themeOption={themeOption}
      >
        <DetailsColumn>
          {backToLabel && <BackToParentLabel $themeOption={themeOption}>{backToLabel}</BackToParentLabel>}
          <Title as={headingType}>{title}</Title>
          <Description>{description}</Description>
        </DetailsColumn >
        {(
          <FooterWrapper>
            &#65279;
            <ArrowWrapper $themeOption={themeOption}>
              <Icon name={themeOption ? 'arrow_back' : 'arrow_forward'} ariaHidden />
            </ArrowWrapper>
          </FooterWrapper>
        )}
      </Component >
    )
  );
};

export default Item;
