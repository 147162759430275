import React from 'react';
import Icon from '_components/Icon';
import PersonFallbackImage from '_utils/components/PersonFallbackImage';
import { convertSrcJss } from '_utils/media';
import { dateConverter } from '_utils/helpers/';
import { ImageSize } from '_utils/types/enums';
import { imagePlaceholderSrc } from '_utils/styles';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { ContributorDetailProps } from './definitions';
import {
  StyledGridContainer,
  ProfileImage,
  ProfileFigure,
  ProfileWrap,
  AttributesItem,
  DotSpanFirst,
  DotSpanSecond,
  Tag,
  Header5,
  TagContainer,
  AttributesWrap,
  DateText,
  ReadingText,
  Profile
} from './StyledContributor';

import Grid from '@material-ui/core/Grid';

const ContributorInfo: React.FC<ContributorDetailProps> = ({ rendering }) => {
  const renderingContextItem = rendering?.fields?.data?.contextItem || {};
  const photo = renderingContextItem?.contributors?.targetItem;
  const fullName = renderingContextItem?.contributors?.targetItem?.fullName?.value || '';
  const publishedDate = renderingContextItem?.published?.serverDate || ''
  const readingTime = renderingContextItem?.readingTime?.value || ''
  const topicDisplayName = renderingContextItem?.topics?.targetItems || [];

  const readingTimeLabel = getDictionaryItem('global-read-time', '{minutes} min read time');
  const formattedReadingTime = readingTimeLabel.replace(/\{minutes\}/ig, readingTime ?? '');

  const formattedDate = publishedDate && dateConverter(publishedDate, { utc: true, medium: true });

  return (
    <StyledGridContainer
      className="contributor-info"
      data-component
    >
      <Grid container>
        <Grid container alignItems='center' style={{ marginBottom: 8 }} >

          {fullName && <ProfileWrap>
            <Profile>
              <>
                {photo?.image?.src ? (
                  <ProfileFigure>
                    <> <ProfileImage
                      className="lazyload"
                      draggable={false}
                      field={
                        {
                          value: {
                            src: imagePlaceholderSrc,
                            alt: photo?.image?.alt,
                            ...{ 'data-src': `${convertSrcJss(photo?.image?.src, ImageSize.MW_512)}` },
                          }
                        }
                      }
                      height={photo?.image?.height}
                      width={photo?.image?.width}
                    />
                    </>
                  </ProfileFigure>
                ) : (
                  <ProfileFigure>
                    <PersonFallbackImage />
                  </ProfileFigure>
                )}
              </>
              {fullName && <Header5>{fullName}</Header5>}
            </Profile>
            {fullName && <DotSpanFirst>.</DotSpanFirst>}
          </ProfileWrap>
          }

          <AttributesWrap>
            {formattedDate?.visualDate && <DateText>{formattedDate?.visualDate}</DateText>}
            {formattedDate?.visualDate && <DotSpanSecond>.</DotSpanSecond>}

            {readingTime && <AttributesItem>
              <Icon name="schedule" ariaHidden />
            </AttributesItem>}
            {readingTime && <ReadingText>{formattedReadingTime}</ReadingText>}
          </AttributesWrap>
        </Grid>

        {/* Tags, in another row */}
        <Grid container>
          <TagContainer>
            {topicDisplayName.map(tag =>
              <Tag key={tag.id}>{tag.displayName.value}</Tag>
            )}
          </TagContainer>
        </Grid>
      </Grid>

    </StyledGridContainer>
  );
};

export default ContributorInfo;
