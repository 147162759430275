import { canUseDOM } from 'exenv';
import React, { useContext } from 'react';

import Icon from '_components/Icon';
import { FormContext, SectionKeyContext } from '_containers/FormSectionContext';

import { EditableSectionHeaderProps } from './definitions';

import {
  HeadingContainer,
  HeadingIcon,
  Heading,
  HeadingButton,
} from './StyledEditableSection';

const EditableSectionHeader: React.FC<EditableSectionHeaderProps> = ({
  children,
  icon,
  sectionIdentifier,
  updateLabel,
  updateLink,
}) => {
  const formContext = useContext(FormContext);
  const sectionKeyFromContext = useContext(SectionKeyContext);
  const sectionContext = formContext?.[sectionKeyFromContext] ?? {};

  // TODO: Remove this handling of updateLink when SC9 form is gone.
  const handleClickUpdate = (e: Event) => {
    e.preventDefault();

    if (canUseDOM && updateLink) {
      return window.open(
        updateLink?.href,
        updateLink?.target ?? '_blank'
      );
    }

    if (sectionContext?.updateAction) {
      return sectionContext?.updateAction(e, sectionIdentifier);
    }
  };

  return (
    <HeadingContainer>
      {icon && (
        <HeadingIcon>
          <Icon
            ariaHidden={true}
            name={icon}
          />
        </HeadingIcon>
      )}

      {children && (
        <Heading>
          {children}
        </Heading>
      )}

      {!sectionContext?.isEditing && updateLabel && (
        <HeadingButton
          aria-label={updateLabel}
          onClick={handleClickUpdate}
        >
          {updateLabel}
          <Icon
            ariaHidden={true}
            name="arrow_forward"
          />
        </HeadingButton>
      )}
    </HeadingContainer>
  );
};

export default EditableSectionHeader;
