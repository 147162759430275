import { compose } from 'ramda';
import React, { useContext, useEffect } from 'react';
import {
  FormContext,
  withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { useFormikContext } from 'formik';
import { withMemberContext } from '_containers/MemberContext';
import { withEditMode } from '_containers/BaseComponent';
import { Tooltip } from '_utils/components';
import { CareerProfileFormContext, CareerProfileFormValues } from '_containers/MMFCareerProfileFormContext/definitions';
import DateInput from '_utils/components/Form/DateInput';
import Dropdown from '_utils/components/Form/Dropdown';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import CompaniesDropdown from '_utils/components/Form/Dropdown/CompaniesDropdown';
import FieldSummary from '_utils/components/Form/FieldSummary';
import FormLabel from '_utils/components/Form/Label';
import RadioList from '_utils/components/Form/RadioList';
import TextInput from '_utils/components/Form/TextInput';
import {
  Field,
  FormSummary
} from '_utils/components/Form/StyledFormSection';
import {
  StyledPaddingDiv, 
  ManualModeToggle
} from '../StyledCareerProfileForm';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { formatDate, renderBooleanAsString } from '_utils/helpers/form';
import { CareerProfileFormProps } from '../definitions';
import mockData from '../mock.json';

const EmploymentDetailsForm: React.FC<CareerProfileFormProps> = ({
  editMode,
  mockCareerProfile,
  careerProfileApi
}) => {
  const formContext = useContext<CareerProfileFormContext>(FormContext);
  const sectionContext = formContext?.employmentDetails;
  const {
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = useFormikContext<CareerProfileFormValues>();
  const referenceData = careerProfileApi?.result;

  const uid = `employment-details`;

  // render component with mock data when in Experience Editor
  const careerProfile =
    !editMode
      ? careerProfileApi?.result?.data
      : mockCareerProfile ?? mockData?.mockCareerProfile;

  // get the field and label values
  const heading = getDictionaryItem('form-career-section-employment-details', 'Employment details');
  const careerStageLabel = getDictionaryItem('form-career-career-stage', 'Career stage');
  const yourCareerSectorLabel = getDictionaryItem('form-career-your-career-sector', 'Your career sector');
  const yourCareerIndustryLabel = getDictionaryItem('form-career-your-career-industry', 'Your career industry');
  const lookUpAutoLabel = getDictionaryItem('form-career-look-up-auto', 'Look up Employer name automatically');
  const employerNameLabel = getDictionaryItem("form-career-employer-name", "Employer name");
  const employerCountryRegionLabel = getDictionaryItem("form-career-employer-country-region", "Employer country/region");
  const employerWebsiteLabel = getDictionaryItem("form-career-employer-website", "Employer website");
  const enterManuallyLabel = getDictionaryItem("form-career-enter-employer-name-manually","Enter Employer name manually");
  const jobLevelLabel = getDictionaryItem("form-career-job-level", "Job level");
  const employmentTypeLabel = getDictionaryItem("form-career-employment-type", "Employment type");
  const updateLabel = getDictionaryItem('form-career-button-update', 'Update');
  const pleaseSelectPlaceholder = getDictionaryItem("form-placeholder-please-select", "Please select");
  const currentlyEmployedLabel = getDictionaryItem("form-career-currently-employed-entry", "Are you currently employed?");
  const startDateLabel = getDictionaryItem("form-start-date-in-the-role", "Start date in this role")
  const careerPositionLabel = getDictionaryItem("form-career-position", "Position");
  const careerPositionPlacholder = getDictionaryItem("form-placeholder-position", "Enter your position");
  const jobPrimaryJobLabel = getDictionaryItem("form-career-job-function", "Primary job function");

  // map reference data
  const countries = referenceData?.data?.referenceData?.countries?.items || [];
  const careerStageItems = referenceData?.data?.referenceData?.careerStages?.items || []
  const employmentSectorOptions = referenceData?.data?.referenceData?.sectors?.items || [];
  const employmentIndustriesOptions = referenceData?.data?.referenceData?.employmentIndustries?.items || []

  // careerStage from api
  const careerStageDefaultApiVal = careerProfile?.employment?.careerStage
  const careerStageDefaultItem = careerStageItems.find(item => item.name === careerStageDefaultApiVal)
  const currentCareerSectorDefaultApiVal = careerProfile?.employment?.currentCareerSector
  const currentCareerSectorDefaultItem = employmentSectorOptions.find(item => item.name === currentCareerSectorDefaultApiVal)

  const currentCareerIndustryDefaultApiVal = careerProfile?.employment?.currentCareerIndustry
  const currentCareerIndustryDefaultItem = employmentIndustriesOptions.find(item => item.name === currentCareerIndustryDefaultApiVal)

  // careerstart date

  const startDate = careerProfile?.employment?.currentEmploymentStartDate || ''


  const tooltipPostionText = 'Please enter your position or job title at your primary employer.  You may choose from the list or type your position.';
  const tooltipLevelText = 'Please select your level of authority/responsibility at your primary employer';
  const tooltipFunctionText = 'Please select the functional area in which you perform your job at your primary employer';

  const clearCompany = () => {
    setFieldValue('employment.company.id', '', false);
    setFieldValue('employment.company.name', '', false);
    setFieldValue('employment.company.country.isoCode2Letter', '', false);
    setFieldValue('employment.company.websiteUrl', '', false);

    // reset touched
    setFieldTouched('employment.company.id', false);
    setFieldTouched('employment.company.name', false);
    setFieldTouched('employment.company.country.isoCode2Letter', false);
    setFieldTouched('employment.company.industry', false);
    setFieldTouched('employment.company.sector', false);
    setFieldTouched('employment.company.websiteUrl', false);
  };

  const onEnterManually = () => {
    clearCompany();
    setFieldValue(
      "employment.isNewCompany",
      true,
      false,
    );
  };

  const onAutomaticMode = () => {
    clearCompany();
    setFieldValue(
      "employment.isNewCompany",
      false,
      false,
    );
  }

  // reset the employment sector form data when toggling employment status
  useEffect(() => {
    if (
      sectionContext?.isEditing &&
      touched?.employment?.isEmployed &&
      !isSubmitting
    ) {
      setFieldValue('employment.company.id', '', false);
    }

    // clear any fields relating to employment
    if (values?.employment?.isEmployed === 'false') {
      setFieldValue('employment.jobTitle', '', false);
      setFieldValue('employment.jobLevel', '', false);
      setFieldValue('employment.jobFunction', '', false);
      setFieldValue('employment.company', null, false);
      setFieldValue('employment.isNewCompany', false, false);
    }
  },
    [values?.employment?.isEmployed]
  );

  // set touched manually 
  const handleEmploymentStatusFocus = () => {
    if (!touched?.employment?.isEmployed) {
      setFieldTouched('employment.isEmployed', true);
    }
  }

  return (
    <EditableSectionContainer
      className="employment-details-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      <EditableSectionHeader
        icon="work_outline"
        updateLabel={updateLabel}
        sectionIdentifier={uid}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <>
            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 4 } }} onFocus={handleEmploymentStatusFocus}>
                <FormLabel
                  htmlFor="employment.careerStage"
                  required>
                  {careerStageLabel}
                </FormLabel>
                <Dropdown
                    id="employment.careerStage"
                    name="employment.careerStage"
                    multiple={false}
                    getOptionLabel={(option) => option?.label}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    options={careerStageItems.map((career) => ({
                      label: career?.name,
                      value: career?.code
                    })) ?? []}
                    placeholder={pleaseSelectPlaceholder}
                    defaultValue={{label: careerStageDefaultItem?.name, value:careerStageDefaultItem?.code}}
                    />
              </Field>
              </FormSummary>
              <FormSummary>
              <Field config={{ col: { xs: 12, sm: 6 } }} onFocus={handleEmploymentStatusFocus}>
                <FormLabel
                  htmlFor="employment.currentCareerSector"
                  required>
                  {yourCareerSectorLabel}
                </FormLabel>
                <Dropdown
                    id="employment.currentCareerSector"
                    name="employment.currentCareerSector"
                    multiple={false}
                    getOptionLabel={(option) => option?.label}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    options={employmentSectorOptions.map((career) => ({
                      label: career?.name,
                      value: career?.code
                    })) ?? []}
                    placeholder={pleaseSelectPlaceholder}
                    defaultValue={{label: currentCareerSectorDefaultItem?.name, value:currentCareerSectorDefaultItem?.code}}
                    />
              </Field>
              <Field config={{ col: { xs: 12, sm: 6 } }} onFocus={handleEmploymentStatusFocus}>
                <FormLabel
                  htmlFor="employment.currentCareerIndustry"
                  required>
                  {yourCareerIndustryLabel}
                </FormLabel>
                <Dropdown
                    id="employment.currentCareerIndustry"
                    name="employment.currentCareerIndustry"
                    multiple={false}
                    getOptionLabel={(option) => option?.label}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    options={employmentIndustriesOptions.map((career) => ({
                      label: career?.name,
                      value: career?.code
                    })) ?? []}
                    placeholder={pleaseSelectPlaceholder}
                    defaultValue={{label: currentCareerIndustryDefaultItem?.name, value:currentCareerIndustryDefaultItem?.code}}
                    />
              </Field>

            </FormSummary>
            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 6 } }} onFocus={handleEmploymentStatusFocus}>
                <FormLabel
                  htmlFor="employment.isEmployed"
                  required>
                  {currentlyEmployedLabel}
                </FormLabel>
                <RadioList
                  name="employment.isEmployed"
                  items={[
                    {
                      label: 'Yes',
                      value: 'true'
                    },
                    {
                      label: 'No',
                      value: 'false'
                    }
                  ]
                  }
                />
              </Field>
              
            </FormSummary>

            {/*!searchCompany &&*/ values.employment.isEmployed === 'true' && values.employment.isNewCompany && (
              <>
                <ManualModeToggle
                  onClick={onAutomaticMode}
                  onMouseDown={event => {
                    event.preventDefault();
                    }}
                >
                  {lookUpAutoLabel}
                </ManualModeToggle>
                <FormSummary>
                  <Field config={{ col: { xs: 12, sm: 6 } }}>
                    <FormLabel required htmlFor="employment.company.name">{employerNameLabel}</FormLabel>
                    <TextInput
                      id="employment.company.name"
                      name="employment.company.name"
                    />
                  </Field>
                  </FormSummary>
                  {values.employment.isEmployed === 'true' && values.employment.isNewCompany && (
                    <>
                    <FormSummary>
                    <Field config={{ col: { xs: 12, sm: 6 } }}>
                      <FormLabel required htmlFor="employment.company.country.isoCode2Letter">{employerCountryRegionLabel}</FormLabel>
                      <Dropdown
                        id="employment.company.country.isoCode2Letter"
                        name="employment.company.country.isoCode2Letter"
                        multiple={false}
                        getOptionLabel={(option) => option?.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        options={countries.map((country) => ({
                          label: country?.name,
                          // The v2 api is using the below field name
                          value: country?.isoCode2Letter
                        })) ?? []}
                        placeholder={pleaseSelectPlaceholder}
                      />
                    </Field>
                    </FormSummary>
                    {/*NOSONAR*/}
                    {/* <FormSummary>
                      <Field config={{ col: { xs: 12, sm: 6 } }}>
                        <FormLabel required htmlFor="employment.company.sector">{employerSectorLabel}</FormLabel>
                        <Dropdown
                          id="employment.company.sector"
                          name="employment.company.sector"
                          multiple={false}
                          getOptionLabel={(option) => option?.label}
                          getOptionSelected={(option, value) => option.value === value.value}
                          options={employmentSectorOptions?.map((sector) => ({
                            label: sector?.name,
                            value: sector
                          })) ?? []}
                          placeholder={pleaseSelectPlaceholder}
                        />
                      </Field>
                      <Field config={{ col: { xs: 12, sm: 6 } }}>
                        <FormLabel required htmlFor="employment.company.industryId">{employerIndustryLabel}</FormLabel>
                        <Dropdown
                          id="employment.company.industry"
                          name="employment.company.industry"
                          multiple={false}
                          getOptionLabel={(option) => option?.label}
                          getOptionSelected={(option, value) => option.value === value.value}
                          options={employmentIndustriesOptions?.map((industry) => ({
                            label: industry?.name,
                            value: industry
                          })) ?? []}
                          placeholder={pleaseSelectPlaceholder}
                        />
                      </Field>
                    </FormSummary> */}
                    <FormSummary>
                    <Field config={{ col: { xs: 12, sm: 9 } }}>
                        <FormLabel htmlFor="employment.company.websiteUrl">{employerWebsiteLabel}</FormLabel>
                        <TextInput
                          id="employment.company.websiteUrl"
                          name="employment.company.websiteUrl"
                        />
                      </Field>
                    </FormSummary>
                  </>
                  )}
              </>
            )}
            {/* only show company dropdown when employed */}
            {
              values.employment.isEmployed === 'true' && (
                <>
                  {!values.employment.isNewCompany && <FormSummary>
                    <Field config={{ col: { xs: 12 } }}>
                      <CompaniesDropdown
                        isV2={true}
                        defaultValue={{name: values?.employment?.company?.name}}
                        onEnterManually={onEnterManually}
                        handleChange={(_, value, reason) => {
                          switch (reason) {
                            case 'clear':
                              clearCompany();
                              break;
                            case 'select-option':
                              clearCompany();
                              setFieldValue(
                                "employment.company.id",
                                value?.identifier,
                                false,
                              );
                              break;
                          }
                        }}
                      />
                    </Field>
                    {!values.employment.isNewCompany &&
                    <StyledPaddingDiv>
                      <ManualModeToggle
                      onClick={onEnterManually}
                      onMouseDown={event => {
                        event.preventDefault();
                      }}
                    >
                      {enterManuallyLabel}
                    </ManualModeToggle>
                    </StyledPaddingDiv>  
                    }
                  </FormSummary>}
                  <>
                  {values.employment.isEmployed === 'true' && <FormSummary>
                    <Field config={{ col: { xs: 8, sm: 4 } }}>
                      <FormLabel htmlFor="employment.currentEmploymentStartDate" required>{startDateLabel}</FormLabel>
                        <DateInput
                          name="employment.currentEmploymentStartDate"
                          placeholder={"DD/MM/YYYY"}
                          disabled={false}
                          type="text"
                          maxLength={10}
                          inputMode="numeric"
                        />
                    </Field>
                  </FormSummary>}
                 
                  <FormSummary>
                    <Field config={{ col: { xs: 12, md: 4 } }}>
                      <FormLabel
                        htmlFor="employment.jobTitle"
                      >
                        {careerPositionLabel}
                        <Tooltip content={tooltipPostionText} />
                      </FormLabel>
                      <TextInput
                        id="employment.jobTitle"
                        maxLength={100}
                        name="employment.jobTitle"
                        placeholder={careerPositionPlacholder}
                      />
                    </Field>
                    {values.employment.isEmployed === 'true' &&
                      <Field config={{ col: { xs: 12, md: 4 } }}>
                      <FormLabel
                        htmlFor="employment.jobFunction"
                        required
                      >
                        {jobPrimaryJobLabel}
                        <Tooltip content={tooltipFunctionText} />
                      </FormLabel>
                      <Dropdown
                        defaultValue={values?.employment?.jobFunction}
                        id="employment.jobFunction"
                        name="employment.jobFunction"
                        multiple={false}
                        options={referenceData?.data?.referenceData?.jobFunctions?.items.map(item => item?.name) || []}
                        placeholder={careerPositionPlacholder}
                      />
                    </Field>}
                  </FormSummary>

                  <FormSummary>
                    <Field config={{ col: { xs: 12, md: 4 } }}>
                      <FormLabel
                        htmlFor="employment.jobLevel"
                      >
                        {jobLevelLabel}
                        <Tooltip content={tooltipLevelText}/>
                      </FormLabel>
                      <Dropdown
                        defaultValue={values?.employment?.jobLevel}
                        id="employment.jobLevel"
                        name="employment.jobLevel"
                        multiple={false}
                        options={referenceData?.data?.referenceData?.jobLevels?.items.map(item => item?.name) || []}
                        placeholder={careerPositionPlacholder}
                      />
                    </Field>
                    <Field config={{ col: { xs: 12, md: 4 } }}>
                      <FormLabel
                        htmlFor="employment.employmentType"
                      >
                        {employmentTypeLabel}
                      </FormLabel>
                      <Dropdown
                        defaultValue={values?.employment?.employmentType}
                        id="employment.employmentType"
                        name="employment.employmentType"
                        multiple={false}
                        options={referenceData?.data?.referenceData?.employmentTypes?.items.map(item => item?.name) || []}
                        placeholder={careerPositionPlacholder}
                      />
                    </Field>
                  </FormSummary>
                  </>
                </>
              )
            }
          </>
        ) : (
          <FormSummary>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-currently-employed"
                labelFallback="Currently employed"
                value={renderBooleanAsString(careerProfile?.employment?.isEmployed)}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-stage"
                labelFallback="Career stage"
                value={careerProfile?.employment?.careerStage}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-your-career-sector"
                labelFallback="Your career sector"
                value={careerProfile?.employment?.currentCareerSector}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-your-career-industry"
                labelFallback="Your career industry"
                value={careerProfile?.employment?.currentCareerIndustry}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-company"
                labelFallback="Company"
                value={careerProfile?.employment?.company?.name}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-start-date-in-the-role"
                labelFallback="Start date in this role"
                value={formatDate(startDate?.split('T')[0], 'DAY_FIRST') ? formatDate(startDate?.split('T')[0], 'DAY_FIRST') : '--'}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-position"
                labelFallback="Position"
                value={careerProfile?.employment?.jobTitle ?? careerProfile?.employment?.jobLevel}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-career-job-function"
                labelFallback="Primary job function"
                value={careerProfile?.employment?.jobFunction}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 12 } }}>
              <FieldSummary
                labelDictionaryKey="form-job-level"
                labelFallback="Job level"
                value={careerProfile?.employment?.jobLevel}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 12 } }}>
              <FieldSummary
                labelDictionaryKey="form-employment-type"
                labelFallback="Employment type"
                value={careerProfile?.employment?.employmentType}
              />
            </Field>
          </FormSummary>
        )
      }
    </EditableSectionContainer >
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('employmentDetails'),
)(EmploymentDetailsForm);