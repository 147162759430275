/**
 * Helper to get cost estimate for service content type
 *
 */

import handleSpecificity from '../handleSpecificity';

export const formatCostString = (estimate: string) => {
  if (estimate === '') {
    return false;
  }

  if (estimate === '0') {
    return 'Free';
  }

  return `$${Number(estimate)
    .toFixed(2)
    .replace(/\.00$/, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export default (specificity = '', estimate = '', description = '') => {
  // no valid estimate, so just give description back if valid
  // 1) no estimate
  // 2) an estimate with no specificity
  if (estimate === '' || (estimate !== '' && specificity === '')) {
    // still return description if valid
    if (description !== '') {
      return `${description}`;
    }

    return null;
  }

  const parts = [];

  const formattedEstimate = formatCostString(estimate);

  if (formattedEstimate !== 'Free') {
    parts.push(handleSpecificity(specificity));
  }

  parts.push(formattedEstimate);
  parts.push(description);

  return parts.filter(Boolean).join(' ');
};
