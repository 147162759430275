import { canUseDOM } from 'exenv';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import LiveChatOffline from '_components/LiveChat/LiveChatOffline';
import { GlobalLiveChatStyleOverrides } from '_components/LiveChat/StyledLiveChat';
import { FORM_STICKY_FOOTER_ID } from '_components/MMFMembershipRenewalForm2023/Sections/FormStickyFooter/definitions';
import {
  useOneTrustActiveGroups,
  useScript,
  useUnload,
} from '_utils/hooks';

import {
  LiveChatProps,
  COOKIE_BANNER_ID,
  LIVE_CHAT_ONLINE_WRAPPER_CLASS,
  LIVE_CHAT_ONLINE_BUTTON_ID,
  LIVE_CHAT_ONLINE_MESSAGE_ID,
  LIVE_CHAT_OFFLINE_BUTTON_ID,
} from './definitions';

const LiveChat: React.FC<LiveChatProps> = ({
  rendering,
}) => {
  const [shouldHideLiveChat, setShouldHideLiveChat] = useState(false);
  const datasource = rendering?.fields?.data?.datasource;

  const {
    hideLiveChat,
    showOfflineChat: isLiveChatOffline,
    ...requiredFields
  } = datasource;

  const shouldShowLiveChat = !hideLiveChat && !Object.keys(requiredFields)?.some(key => !datasource[key]);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    const liveChatOnlineWrapper = document.getElementsByClassName(LIVE_CHAT_ONLINE_WRAPPER_CLASS)[0] as HTMLElement;

    if (liveChatOnlineWrapper) {
      liveChatOnlineWrapper.style.display = shouldShowLiveChat ? '' : 'none';
    }
  }, [shouldShowLiveChat]);

  const {
    activeGroups,
    isCookieBannerClosed,
    isOneTrustScriptReady,
  } = useOneTrustActiveGroups();

  const updateLiveChatPosition = () => {
    if (!canUseDOM) {
      return;
    }

    // avoid the cookie banner if any
    const cookieBanner = document.getElementById(COOKIE_BANNER_ID);

    const liveChatMessage = document.getElementById(LIVE_CHAT_ONLINE_MESSAGE_ID);

    const liveChatButton =
      document.getElementById(LIVE_CHAT_ONLINE_BUTTON_ID)
      || document.getElementById(LIVE_CHAT_OFFLINE_BUTTON_ID);

    // Special case for FormStickyFooter used on MembershipRenewalForm:
    // leave positioning of LiveChat to code within FormStickyFooter
    const formStickyFooterEl = document?.getElementById(FORM_STICKY_FOOTER_ID);

    if (formStickyFooterEl) {
      return;
    }

    if (isCookieBannerClosed) {
      if (liveChatMessage) {
        liveChatMessage.style.bottom = '100px';
      }
  
      if (liveChatButton) {
        liveChatButton.style.bottom = '24px';
      }
    } else {
      if (cookieBanner) {
        if (liveChatMessage) {
          liveChatMessage.style.bottom = `${cookieBanner?.getBoundingClientRect()?.height + 100}px`;
        }
    
        if (liveChatButton) {
          liveChatButton.style.bottom = `${cookieBanner?.getBoundingClientRect()?.height + 24}px`;
        }
      }
    }
  };

  const updateLiveChatVisibility = () => {
    if (!canUseDOM) {
      return;
    }

    // Special case for FormStickyFooter used on MembershipRenewalForm
    const formStickyFooterEl = document?.getElementById(FORM_STICKY_FOOTER_ID);

    if (!formStickyFooterEl) {
      return;
    }

    const pageFooter = document.getElementsByTagName('footer')?.[0];
    const liveChatEl = document.getElementsByClassName(LIVE_CHAT_ONLINE_WRAPPER_CLASS)[0] as HTMLElement;
    const pageFooterPosition = pageFooter?.getBoundingClientRect();
    const isPageFooterVisible = (pageFooterPosition?.top ?? 0) < window.innerHeight;

    if (isPageFooterVisible) {
      liveChatEl?.classList?.add('hidden');
      setShouldHideLiveChat(true);
    } else {
      liveChatEl?.classList?.remove('hidden');
      setShouldHideLiveChat(false);
    }
  };

  let timeout;

  const handleScroll = () => {
    if (!canUseDOM) {
      return;
    }

    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    timeout = window.requestAnimationFrame(() => {
      updateLiveChatVisibility();
    });
  };

  const debouncedHandleResize = useDebouncedCallback(updateLiveChatPosition, 500);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    window.addEventListener('resize', debouncedHandleResize.callback);
    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize.callback);
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, []);

  useEffect(() => {
    // reposition live chat if the cookie banner has been closed
    updateLiveChatPosition();
    updateLiveChatVisibility();
  }, [
    isCookieBannerClosed,
    isOneTrustScriptReady,
  ]);

  useScript(rendering?.fields?.data?.datasource?.niceSourceUrl);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    const shouldMountLiveChat =
      shouldShowLiveChat
      && (window?.icPatronChat ?? false)
      && isOneTrustScriptReady
      && !isLiveChatOffline
      && !document.getElementById('chat-div-wrap')
      && activeGroups?.indexOf('C0003') > -1

    const icPatronChatParams = {
      serverHost: rendering?.fields?.data?.datasource?.niceServerHost,
      bus_no: rendering?.fields?.data?.datasource?.niceBusNumber,
      poc: rendering?.fields?.data?.datasource?.nicePoc,
      params: [
        'FirstName',
        'Last Name',
        'first.last@company.com',
        555-555-5555,
      ],
    };

    if (shouldMountLiveChat) {
      window?.icPatronChat?.init(icPatronChatParams);

      // reposition live chat on init
      updateLiveChatPosition();
      updateLiveChatVisibility();
    }
  }, [
    isOneTrustScriptReady,
    isLiveChatOffline,
    activeGroups,
    shouldShowLiveChat,
  ]);

  useUnload((e) => {
    // e?.preventDefault(); in chrome 119, this one is always triggering default Chrome dialog, so comment out
    updateLiveChatPosition();
    updateLiveChatVisibility();
  });

  return (
    <>
      { shouldShowLiveChat && (
          isLiveChatOffline
            ? <LiveChatOffline datasource={datasource} shouldHideLiveChat={shouldHideLiveChat} />
            : <GlobalLiveChatStyleOverrides />
        )
      }
    </>
  );
};

export default LiveChat;
