import classNames from 'classnames';
import { compose } from 'ramda';
import React from 'react';

import Ad from '_components/Advertisement/Ad';

import {
  CoveoNoIndexWrapper,
  withDataSourceValidation,
  withEditMode,
} from '_containers/BaseComponent';

import ContentCard from '_utils/components/ContentCard';
import HeadingWithDescription from '_utils/components/HeadingWithDescription';

import { useBreakpoint } from '_utils/hooks/useBreakpoint';

import { normalizeJssFields } from '_utils/helpers/jss';

import {
  AdContainer,
  Container,
  CardContainer,
  Header,
  LinkButton,
  LinkWrapper,
  List,
  ListItem,
} from './StyledFeatureSection';

import {
  FeatureSectionWithAdProps,
  AdPlacementType,
} from './definitions';
import { featureSectionWithAdFieldValidators } from './validation';

const CTA = (props) => {
  const { link, className } = props;
  return (
    <LinkWrapper className={className}>
      <LinkButton field={link} fallbackTitle={link?.fallbackTitle} linkTheme="featured" />
    </LinkWrapper>
  );
}

const FeatureSectionWithAd: React.FC<FeatureSectionWithAdProps> = ({ rendering, editMode }) => {

  const fields = rendering?.fields?.data?.datasource || {};
  const params = rendering?.params;
  const uid = rendering?.uid || null;

  const bp = useBreakpoint();

  const normalized = normalizeJssFields(fields);
  const heading = normalized?.heading;
  const items = normalized?.items || [];
  const link = normalized?.link;

  // rendering params
  const advertisment = normalized?.adConfiguration?.targetItem?.adverts;
  const adPosition: AdPlacementType = params?.adPosition || 'left';
  const headingLevel = params?.headingLevel || 'h3';
  const removeBottomPadding = params?.removeBottomPadding;

  const validItems = !editMode ? items.filter((item) => item.url)?.slice(0, 4) : items;
  const hasItems = editMode || (validItems?.length > 0);

  // float ad left/right based on rendering parameter
  const adContainerOrder = adPosition === 'left' ? 0 : 1;
  const cardContainerOrder = adPosition === 'left' ? 1 : 0;

  const level = parseInt(headingLevel?.toLowerCase().replace('h', '')) || 3;
  const shouldShowCTA: boolean = !!(editMode || (link && link?.value?.href));

  const classes = classNames(
    'feature-section-with-ad',
    {
      [adPosition]: adPosition,
      'removeBottomPadding': removeBottomPadding,
    });

  const adProps = {
    adSlotID: advertisment?.adSlotID,
    position: advertisment?.position,
    keywords: '',
    uid: uid.concat('-ads'),
  }

  return (
    (editMode || hasItems) ?
      <CoveoNoIndexWrapper editMode={editMode}>
        <Container
          className={classes}
          data-component
          id={uid}
          numItems={validItems?.length ?? 0}
        >
          {/* display Ad above entire component for mobile breakpoints when position is 'right' */}
          {advertisment && ['sm', 'xs'].includes(bp) && adPosition === 'right' &&
            <Ad {...adProps} />
          }

          {(heading?.value) && (
            <Header>
              <HeadingWithDescription
                headingField={heading}
                headingLevel={headingLevel}
              >
                {shouldShowCTA && <CTA link={link} className="hideOnMobile" />}
              </HeadingWithDescription>
            </Header>
          )}

          {hasItems &&
            <List>
              {/* display Ad to side for tablet/desktop breakpoints */}
              {advertisment && !['sm', 'xs'].includes(bp) &&
                <AdContainer
                  order={adContainerOrder}
                >
                  <Ad {...adProps} />
                </AdContainer>
              }
              <CardContainer
                order={cardContainerOrder}
              >
                {validItems.map((item, index) => (
                  <ListItem
                    config={{
                      col: {
                        xs: 12,
                        md: 6
                      }
                    }}
                    editMode={editMode}
                    key={item.id || index}
                  >
                    <ContentCard
                      {...item}
                      editMode={editMode}
                      headingLevel={level}
                      showTags
                    />
                  </ListItem>
                ))}

              </CardContainer>
            </List>
          }

          {shouldShowCTA && <CTA link={link} className="showOnMobile" />}

          {/* display Ad below CTA for mobile breakpoints  when position is 'left' */}
          {advertisment && ['sm', 'xs'].includes(bp) && adPosition === 'left' &&
            <AdContainer>
              <Ad {...adProps} />
            </AdContainer>
          }
        </Container>
      </CoveoNoIndexWrapper>
      : null);
};

export default
  compose(
    withDataSourceValidation(featureSectionWithAdFieldValidators),
    withEditMode
  )(FeatureSectionWithAd);
