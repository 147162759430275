import { DigitalDataContextProps } from '_containers/DigitalDataContext/definitions'

export type MetadataProps = DigitalDataContextProps & {
  rendering: {
    fields: {
      data: {
        contextItem: {
          categoryInformation?: {
            categoryLabelText?: string;
            categoryUrl?: string;
            enterpriseTopic?: string;
          };
        }
      }
    };
  };
};

export const CONTRIBUTOR_TEMPLATE = 'itb contributor page';
export const TOPIC_TEMPLATE = 'itb topic page';
export const CATEGORY_TEMPLATE = 'itb category page';
export const ARTICLE_ADVERTORIAL_TEMPLATE = 'itb article advertorial';
export const ARTICLE_SPONSORED_TEMPLATE = 'itb article sponsored';
export const ARTICLE_STANDARD_TEMPLATE = 'itb article standard';