import styled from 'styled-components';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { svg, mq } from "_utils/styles";

export const ItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem;

  ${mq('lg')} {
    flex-direction: row;
  }

`;

export const ItemContent = styled.div`
  h3 {
    margin-top: 0;
  }

  margin-top: 20px;
  ${mq('lg')} {
    padding: 0 24px;
    margin-top: 0px;
    width: 60%;
  }
`;

export const ItemTitle = styled.div`
  h3& {
    margin-top: 0;
    margin-bottom: 10px;
    ${mq('md')} {
      font-size: 28px;
      line-height: 32px;
    }

    ${mq.lessThan('md')} {
      font-size: 24px;
      line-height: 28px;
    }
  }
  `;

export const ItemSubTitle = styled.div`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;

  ${mq.lessThan('md')} {
    -webkit-line-clamp: 3;
    line-clamp: 3; 
  }

  ${mq('lg')} {
    margin-bottom: 20px;
  }
`;

export const ItemDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  -webkit-box-orient: vertical;

  ${mq.lessThan('md')} {
    display: none;
  }

  ${mq('md')} {
    -webkit-line-clamp: 2;
    line-clamp: 2; 
  }

  ${mq('lg')} {
    -webkit-line-clamp: 4;
    line-clamp: 4; 
  }

`

export const ItemFooterLink = styled.div`

  position: absolute;
  bottom: 4px;
  right: 0px;
  display: flex;

  a {
    color: ${({ theme }) => theme.cardList.action};
    text-decoration: none;
    margin-right: 6px;
  }
`;


export const ArrowWrapper = styled.span`
  ${svg(24, 24)}
  width: 24px;
  
  path {
    fill: ${({ theme }) => theme.cardList.action};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  ${mq('md')} {
    min-height: 250px;
  }
  
  ${mq('lg')} {
    width: 40%;
    height: 100%;
  }
`


export const Image = styled(SCImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;