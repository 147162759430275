import React from 'react';
import { withSvg } from './Svg';

const Download = () => (
  <path
    fill="black"
    d="M14 6H10V0H4V6H0L7 13L14 6ZM6 8V2H8V8H9.17L7 10.17L4.83 8H6ZM0 15H14V17H0V15Z"
  />
);

export default withSvg(14, 17)(Download);
