import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    background: colors.background.tint2,
    borderColor: colors.all.neutral200
  },
  linkList: {
    color: colors.all.neutral700
  }
};
