import colors from '_containers/Theme/corporate/colors';

export default {
  lightBanner: {
    headerColor: colors.background.darkBlue,
    color: colors.text.default,
  },
  darkBanner: {
    headerColor: colors.background.tint1,
    color: colors.text.dark.default,
  },
  background: {
    yellow: colors.element.primary2,
    teal: colors.featureList.tick,
    orange: colors.brand.orange.secondary,
    blue: colors.element.primary,
    purple: colors.element.secondary,
    black: colors.element.neutralBlack
  }
};
