import colors from '_containers/Theme/intheblack/colors';


export default {
  container: {
    column: {
      border: colors.all.brandPrimary2,
      background: colors.darkTheme.background.tint3,
    },
    sidebar: {
      border: colors.all.interactive500,
      background: colors.darkTheme.background.tint2,
    }
  },
  title: {
    color: colors.all.darkTextContrast
  },
  link: {
    color: colors.all.darkTextContrast,
    hoverColor: colors.darkTheme.text.interactive1Hover,
    iconColor: colors.darkTheme.text.interactive1
  },
  button: {
    color: colors.all.darkTextContrast,
    iconColor: colors.all.darkTextContrast
  }
}