import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { normalize } from 'styled-normalize';
import { spacing } from '_utils/props';

import {
  buttonColors,
  buttonReset,
  buttonSize,
  fixedBody12,
  focusOutline,
  mq,
  safeFocusRemoval,
  textBody1,
} from '_utils/styles/';

import { ChildrenPropTypes } from '../../../types';
import theme from './theme';

export * from './theme';

const GlobalStyles = createGlobalStyle`

${normalize}

html {
  box-sizing: border-box;
  height: 100%;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  ${textBody1}
  color: ${theme.colors.text.default};
  margin: 0;
  max-width: 100%;
  min-height: 100%;
  min-width: 300px;
  overflow-x: hidden;
  position: relative;
  &.menu-active {
    height: 100%;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

img,
picture,
svg {
  vertical-align: middle;
  border-style: none;
  height: auto;
  max-width: 100%;
}

img {
  &.lazyload,
  &.lazyloading  {
    opacity: 0;
  }

  &.lazyloaded {
    ${mq('md')} {
      transition: opacity ${theme.motion.timing.fast} linear;
    }

    opacity: 1;
  }
}

.button-primary {
  ${buttonReset()}
  ${buttonSize(theme.button.default)}
  ${buttonColors(theme.colors.button.primary)}
}

.button-ghost {
  ${buttonReset()}
  ${buttonSize(theme.button.default)}
  ${buttonColors(theme.colors.button.ghost)}
}

.button-slim {
  ${buttonSize(theme.button.slim)}
}

a:focus {
  ${safeFocusRemoval}
  ${focusOutline({ theme })}
}

.pageTitle,
h1,
.headingH1,
h2,
.headingH2,
h3,
.headingH3,
h4,
.headingH4,
h5,
.headingH5,
h6,
.headingH6 {
  color: ${theme.colors.all.neutralBlack};
}

${mq('lg')} {
  .fullWidthOnDesktop .content-rich-text > *  {
    max-width: 100% !important;
  }
}

  // Tooltips overrides for react-aria-tooltip
  .ra-tooltip-wrapper {
    display: inline-flex !important;
    height: 14px;

    button {
      position:relative;
      top:-2px;
    }
  }

  .ra-tooltip {
    background: transparent !important;
    max-width: 200px;
    width: max-content;

    &.top {
      transform: translate(-50%,-104%) !important;
    }

    .ra-tooltip-message {
      ${fixedBody12}
      background: ${theme.tooltip.background};
      border-radius: 4px;
      box-shadow: ${theme.shadows.elevation16};
      color: ${theme.tooltip.color};
      padding: 8px 16px;

      p {
        margin-bottom: 0;
        text-align: center;
        white-space: initial;
      }

      // notch on bottom
      &:after {
        border-top-color: ${theme.tooltip.background} !important;
      }
    }
  }

/* start placeholder spacing for verical rhythm between components */

/*  using 'noindex' DOM element for .coveo-no-index-begin/end which is wrapper to output
    HTML comments - this just to reduce CSS specificity complexity for targeting spacing rules */
noindex {
  display: none;
}

.ph {

  // targeting row as ph too high and cannot check :empty
  .row:not(.db-row):not(:empty) {
    ${({ theme }) => spacing({ theme, py: 7 })}
  }

  &.no-pt {
    .row:not(:empty) {
      padding-top: 0;

      // targeting ads to have at least 24px when removeTopPadding is checked on sitecore
      .advertisement {
        ${({ theme }) => spacing({ theme, pt: '24px' })}
      }
    }
  }

  &.no-pb {
    .row:not(:empty) {
      padding-bottom: 0;
    }
  }

  // component level spacing
  > [data-component]:not(:last-child) {
    ${({ theme }) => spacing({ theme, mb: 6 })}
  }
}
/* end placeholder spacing */

/* negate top margin on Page Banner if:
    - there is a breadcrumb component on the page and
    - no campaign navigation exists
*/
body .page-breadcrumb ~ .page-banner {
  margin-top: 0;
}

.itb-magazine-listing-page {
  background-color: ${({ theme }) => theme.colors.background.tint3};
}

.hide-on-print {
  @media print {
    display: none !important;
  }
}

@media print {
  * {
      background: transparent !important;
      color: black !important;
      color-adjust: economy;
      box-shadow: none !important;
      text-shadow: none !important;

      &::after {
        box-shadow: none !important;
      }
    }

    // Prevent page breaks in the middle of a blockquote or preformatted text block
    pre,
    blockquote {
      border: 1px solid black;
      break-inside: avoid;
    }

    tr,
    img {
      break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      break-inside: avoid; /* or 'auto' */
     }
    img { max-width: 100% !important; }

    @page { margin: 0.5cm; }

    p,
    h2,
    h3 {
      break-after: avoid;
      orphans: 3;
      widows: 3;
    }

     // hide OneTrust consent & Live Chat when printing
    .chat-iframe-wrap,
    .button-iframe-wrap,
    iframe#icChatButton,
    div#onetrust-consent-sdk  {
      display: none;
    }

    .ph > [data-component] {
      margin-bottom: 20px !important;
    }

  }
`;

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyles />
        {children}
      </Fragment>
    </ThemeProvider>
  );
}

Theme.propTypes = {
  children: ChildrenPropTypes,
};
