import styled from "styled-components";

export const PageSlimHeaderWrapper = styled.header`
    position: relative;
    background-color: #043472; /* Blue background */
    width: 100vw;
    height: 60px;
    color: white;
    justify-content: flex-start;
    display: flex;
    padding-left: 20px;
`;

export const PageSlimHeaderText = styled.div`
    max-width: 130px;
    font-size: 24px;
    padding: 10px 20px;
`;

export const PageSlimHeaderLogoLink = styled.a`
`;

export const PageSlimHeaderLogo = styled.img`
`;