import colors from '_containers/Theme/intheblack/colors';

export default {
  lightBanner: {
    headerColor: colors.all.neutralBlack,
    color: colors.text.default,
  },
  darkBanner: {
    headerColor: colors.background.tint1,
    color: colors.darkTheme.text.default,
  },
  background: {
    black: colors.element.neutralBlack
  }
};
