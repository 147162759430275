import React, { createContext, useState, useMemo } from 'react';

import { Optionalize } from '_utils/types';

import { NormalisedCountry, CountryContextProps } from './definitions';

export const DEFAULT_COUNTRY_NAME = 'Australia'

const defaultCountry: NormalisedCountry = {
  name: { value: DEFAULT_COUNTRY_NAME },
};

const CountryContext = createContext({
  country: null,
  setCountry: null
});

function CountryProvider({ children }) {
  const [country, setCountry] = useState<NormalisedCountry>(defaultCountry);
  const contextValue = useMemo(() => {
    return {
      country,
      setCountry
    };
  }, [country]);

  return (
    <CountryContext.Provider
      value={contextValue}
    >
      {children}
    </CountryContext.Provider>
  );
}

export default CountryProvider;

export const CountryConsumer = CountryContext.Consumer;

export function withCountryContext<T extends CountryContextProps = CountryContextProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withTimezoneContext: React.FC<Optionalize<T, CountryContextProps>> = (props) => (
    <CountryContext.Consumer>
      {(value) => <WrappedComponent {...(props as T)} {...value} />}
    </CountryContext.Consumer>
  );

  withTimezoneContext.displayName = `withTimezoneContext(${displayName})`;

  return withTimezoneContext;
}
