import { compose } from 'ramda';
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { AddressEditor } from '_components/MMFContactDetailsForm/Sections/AddressEditor';
import Alert from '_components/MMFContactDetailsForm/Sections/Alert';
import { withEditMode } from '_containers/BaseComponent';
import { ContactDetailsFormContext, ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';
import {
  FormContext, withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import { Title } from '_utils/components/Form/AddressManual/StyledAddressManual';
import CheckboxList from '_utils/components/Form/CheckboxList';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import FieldSummary from '_utils/components/Form/FieldSummary';
import {
  Field,
  FormSummary,
} from '_utils/components/Form/StyledFormSection';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  getAddress,
  getMailingAddress,
  getMailingContact,
} from '_utils/helpers/form/index';

import { ContactDetailsFormProps } from '../definitions';
import mockData from '../mock.json';

const AddressesForm: React.FC<ContactDetailsFormProps> = ({
  editMode,
  mockContactProfile,
  rendering,
  contactProfileApi,
}) => {
  const formContext = useContext<ContactDetailsFormContext>(FormContext);
  const sectionContext = formContext?.addresses;

  const { errors, isValid, values, setFieldTouched, setFieldValue, submitCount } = useFormikContext<ContactDetailsFormValues>();

  const uid = `addresses`;

  const contactProfile =
    !editMode
      ? contactProfileApi?.result?.data
      : mockContactProfile ?? mockData?.mockContactProfile;

  const heading = getDictionaryItem("form-contact-section-addresses", "Addresses");
  const updateLabel = getDictionaryItem("form-contact-button-update", "Update");
  const residentialAddressLabel = getDictionaryItem("form-contact-residential-address", "Residential address");
  const mailingAddressLabel = getDictionaryItem("form-contact-mailing-address", "Mailing address");
  const deliverySameAsResidential = getDictionaryItem("form-contact-same-as-residential-address", "Same as residential");

  // show mailing contact if not null or NOT same as billing contact
  const mailingContact = getMailingContact(
    contactProfile?.billingAddress?.primaryContactName,
    contactProfile?.shippingAddress?.primaryContactName,
  );

  const isSameAddressCheckboxItems = [{
    name: 'isSameAddress',
    label: deliverySameAsResidential,
    onChange: () => {
      setFieldTouched('isSameAddress', true);
    }
  }];

  // unset the `isMailReturned` if user focuses on address fields to enable submit button
  const handleAddressFocus = () => {
    if (values?.contact?.comms?.isMailReturned) {
      setFieldValue('contact.comms.isMailReturned', false);
    }
  }

  return (
    <EditableSectionContainer
      className="addresses-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      {/* Redirected mail alert */}
      {!isValid && errors?.contact?.comms?.isMailReturned && submitCount === 0 &&
        <Alert type="mail" />
      }

      <EditableSectionHeader
        icon="mail"
        sectionIdentifier={uid}
        updateLabel={updateLabel}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <div onFocus={handleAddressFocus}>
            <Title>{residentialAddressLabel}</Title>
            <AddressEditor formValuesKey="billingAddress" />

            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 12 } }}>
                <Title>{mailingAddressLabel}</Title>
                <CheckboxList
                  id="isSameAddress"
                  items={isSameAddressCheckboxItems}
                />
              </Field>
            </FormSummary>

            {!values.isSameAddress && <AddressEditor formValuesKey="shippingAddress" />}
          </div>
        ) : (
          <FormSummary>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-residential-address"
                labelFallback="Residential address"
                value={getAddress(contactProfile?.billingAddress)}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-delivery-contact"
                labelFallback="Delivery contact"
                value={contactProfile?.billingAddress?.primaryContactName}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-mailing-address"
                labelFallback="Mailing address"
                value={getMailingAddress(contactProfile?.billingAddress, contactProfile?.shippingAddress)}
              />
            </Field>
            {
              mailingContact && <Field config={{ col: { xs: 12, sm: 6 } }}>
                <FieldSummary
                  labelDictionaryKey="form-contact-delivery-contact"
                  labelFallback="Delivery contact"
                  value={mailingContact}
                />
              </Field>
            }
          </FormSummary>
        )
      }
    </EditableSectionContainer>
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('addresses'),
)(AddressesForm);
