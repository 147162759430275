import styled from 'styled-components';

import { PlaceholderWrapper } from '_utils/components';

export const Wrapper = styled(PlaceholderWrapper)`
  clear: both;
  padding: 0;
`;

export const ShouldShowBorderTop = styled.div`
  border-top: 1px solid ${({ theme }) => theme.section.divider.color};
`