import styled, { css } from 'styled-components';
// import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';

import { section } from '_utils/styles';
import { spacing } from '_utils/props';
import Grid from '_utils/components/Grid';

const handleBackground = (color) =>
  ({ theme }) => css`
    background-color: ${theme.section.backgroundOverride[color]};
  `;

export const SectionWrapper = styled.section`
  ${({ theme }) => section({ theme })}

  &.db-fw {
    background-color: ${({ theme }) => theme.section.backgroundOverride.Grey};
  }

  ${(props) => props?.backgroundColor && handleBackground(props.backgroundColor)};

  &.is-empty {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const styleKey = isEditorActive() ? 'mb' : 'mt';
const marginWildCard = { [styleKey]: 7 };
const marginFirstChild = { [styleKey]: 0 };

export const PlaceholderWrapper = styled(Grid.Item)`
  > * {
    ${({ theme }) => spacing({ ...theme, marginWildCard })}
    &.scEmptyPlaceholder,
    &:first-child {
      ${({ theme }) => spacing({ ...theme, marginFirstChild })}
    }
  }

  .is-empty & {
    margin: 0 !important;
  }
`;
