import { canUseDOM } from 'exenv';
import cookies from 'js-cookie';
import { compose } from 'ramda'
import React from 'react';

import InPageAnnouncement from '_components/Announcements/InPageAnnouncement';
import SiteWideAnnouncement from '_components/Announcements/SiteWideAnnouncement';
import { withDataSourceValidation, withEditMode, withCoveoNoIndexTags } from '_containers/BaseComponent'
import { normalizeJssFields } from '_utils/helpers/jss';

import {
  AnnouncementsProps,
  InPageAnnouncement as InPageAnnouncementType,
  NormalizedInPageAnnouncement,
  NormalizedSiteWideAnnouncement,
  SiteWideAnnouncement as SiteWideAnnouncementType,
  DISMISSED_ANNOUNCEMENTS_COOKIE_NAME,
} from './definitions';

import { announcementsValidators } from './validation';

const Announcements: React.FC<AnnouncementsProps> = ({
  editMode,
  fields,
}) => {
  const announcements = fields?.data?.datasource?.children;
  const folderType = fields?.data?.datasource?.__typename;
  const isInPage = folderType === 'InPageAnnouncementsFolder';

  if (isInPage) {
    return (
      <>
        {
          announcements?.length === 0 && editMode
            ? (
              <InPageAnnouncement
                type={{ value: 'Yellow' }}
                title={{ value: `Add content item(s) to this ${folderType}` }}
              />
            )
            : (
              (announcements as InPageAnnouncementType[])
                ?.filter((a) => (
                  a?.title?.jss?.value !== '' // Need at least a title
                  || editMode
                ))
                ?.map((a) => {
                  const { id, ...rest }: NormalizedInPageAnnouncement = normalizeJssFields(a);
                  const args = {
                    key: id,
                    id,
                    ...rest,
                  };

                  return <InPageAnnouncement {...args} />
                })
            )
        }
      </>
    )
  } else {
    return (
      <>
        {
          (announcements as SiteWideAnnouncementType[])
            ?.filter((a) => {
              const dismissedAnnouncementIds: string[] = cookies.get(DISMISSED_ANNOUNCEMENTS_COOKIE_NAME)?.split(',') ?? [];

              return (
                !dismissedAnnouncementIds?.includes(a?.id) // If the announcement's id is not already in the announcements-dismissed cookie, render it.
                && (editMode || a?.title?.jss?.value !== '') // Need at least a title
              )
            })
            ?.map((a) => {
              const { id, ...rest }: NormalizedSiteWideAnnouncement = normalizeJssFields(a);
              const args = {
                key: id,
                id,
                setAnnouncementDismissed: () => {
                  if (!canUseDOM) {
                    return;
                  }

                  if (window?.setAnnouncementDismissed) {
                    window?.setAnnouncementDismissed(id);
                  }
                },
                ...rest
              };

              return <SiteWideAnnouncement {...args} />;
            })
        }
      </>
    );
  };

  return null;
};

export default compose(withEditMode, withCoveoNoIndexTags, withDataSourceValidation(announcementsValidators))(Announcements);
