import React from 'react';

import {
  MainContainer,
  SpinnerContainer,
} from './StyledSpinner';

import { SpinnerProps } from './definitions';
import { getDictionaryItem } from '_utils/data/dictionaryItem';


const Spinner: React.FC<SpinnerProps> = ({
  className,
  showLoadingLabel = false
}) => {

  // dictionary item
  const loading = getDictionaryItem('spinner-label-loading', 'Loading...');


  return (
    <MainContainer>
      <SpinnerContainer className={className}>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" />
        </svg>

      </SpinnerContainer>
      {showLoadingLabel &&
        <span>{loading}</span>
      }
    </MainContainer>
  );
};

export default Spinner;
