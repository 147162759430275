import React from 'react';

import { getDictionaryItem } from '_utils/data/dictionaryItem';

import Menu from './Menu';
import {
  ListWrapper,
  MenuCloseButton,
  NavToolbar,
  NavToolbarClose,
  NavToolbarControl,
  NavToolbarTabItem,
  NavToolbarTabs,
  PrimaryContainerWrapper,
  ButtonWrapper,
  LinkButtonSub
} from './StyledMainMenu';

import { NavigationProps } from "./definitions";

const PrimaryNavigation: React.FC<NavigationProps> = ({
  fields,
  isSticky,
  navigationItems,
  resetMenu
}) => {
  const menuLabel = getDictionaryItem('menu-label', 'Menu');
  const menuSubscribeLabel = getDictionaryItem('menu-button-subscribe-to-newsletter', 'Subscribe to newsletter');
  const subscribeToNewsletterLink = fields?.data?.datasource?.subscribeToNewsletterLink?.jss?.value?.href || '/';

  return (
    <PrimaryContainerWrapper>
      <ListWrapper>
        <ButtonWrapper onClick={resetMenu}>
          <LinkButtonSub
            to={subscribeToNewsletterLink}
          >{menuSubscribeLabel}</LinkButtonSub>
        </ButtonWrapper>
        <NavToolbar>
          <NavToolbarControl>
            <NavToolbarTabs>
              <NavToolbarTabItem>{menuLabel}</NavToolbarTabItem>
            </NavToolbarTabs>
          </NavToolbarControl>
          <NavToolbarClose>
            <MenuCloseButton onClick={resetMenu} tabIndex="0" aria-label="close menu" />
          </NavToolbarClose>
        </NavToolbar>
        <Menu
          className="primaryNavigation"
          fields={fields}
          isSticky={isSticky}
          menuID={1}
          navigationItems={navigationItems}
        />
      </ListWrapper>
    </PrimaryContainerWrapper>
  )
}

export default PrimaryNavigation;