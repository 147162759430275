import classNames from 'classnames';
import React from 'react';

import inTheBlackPlaceholder from '_assets/logo_intheblack_placeholder.svg';

import Icon from '_components/Icon';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { dateConverter } from '_utils/helpers/';
import { getLink } from '_utils/helpers/link';
import { useBreakpoint } from '_utils/hooks/useBreakpoint';
import { createSrcSet } from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageHeight16by9, ImageSize } from '_utils/types/enums';

import {
  ContentCardProps,
} from './definitions';

import {
  AttributesItem,
  AttributesWrapper,
  Card,
  DetailsColumn,
  AttributeFooterWrapper,
  Image as StyledImage,
  ImageColumn,
  ImageContainer,
  PlaceholderCard,
  PlaceholderImage,
  StyledTagList,
  TagContainer,
  Title,
} from './StyledContentCard';

const getPublishedDate = (published) => {
  if (published) {
    return dateConverter(published, { isUTC: true, outputFormat: 'DD MMM YYYY' });
  }
  return;
}

const ContentCard: React.FC<ContentCardProps> = ({
  areTagsClickable = false,
  category,
  className,
  editMode,
  featuredImage,
  headingLevel,
  isHorizontalMode = false,
  // published not required currently, but maybe in future
  // published,
  readingTime,
  showCategoryTag = false,
  showPublishDate = false,
  showTags = false,
  tags,
  title,
  url,
}) => {

  const cardProps = getLink(url);
  const Component = editMode ? PlaceholderCard : Card;
  const hasImageSrc = !!featuredImage?.value?.src;
  const headingType = headingLevel ? `h${headingLevel + 1}` : `h4`;
  const isExternalLink = cardProps?.target === '_blank';
  // const publishedDate = getPublishedDate(published);

  const bp = useBreakpoint();

  const classes = classNames(
    'card',
    {
      'horizontal': isHorizontalMode,
      [className]: className
    });

  return (
    (editMode || cardProps) && (
      <Component {...cardProps}
        className={classes}
        as={isExternalLink ? 'a' : null}>
        <ImageColumn>
          <ImageContainer>
            {hasImageSrc ?
              <StyledImage
                className="lazyload"
                draggable={false}
                field={
                  editMode
                    ? featuredImage?.value
                    : {
                      value: {
                        src: imagePlaceholderSrc,
                        alt: '', // alt text is empty string, as this is a decorative image
                        'data-srcset': createSrcSet(featuredImage?.value?.src, [ImageSize.MW_384, ImageSize.MW_512])
                      }
                    }
                }
                height={ImageHeight16by9.MW_384}
                width={ImageSize.MW_384}
              /> :
              <PlaceholderImage src={inTheBlackPlaceholder} alt='' />
            }
          </ImageContainer>
        </ImageColumn>
        <DetailsColumn>
          <Title as={headingType} className="title">{title}</Title>
          <AttributeFooterWrapper>
            {
              <AttributesWrapper>
                {/* published not required currently, but maybe in future
                  {((showPublishDate && typeof publishedDate !== 'undefined')) && (
                  <AttributesItem>
                    <Icon name="calendar_today" ariaHidden />
                    {(editMode || publishedDate) && (
                      <span>{getDictionaryItem('global-published', 'Published')}{' '}<time dateTime={publishedDate?.attrDate}>{publishedDate?.visualDate}</time></span>
                    )}
                  </AttributesItem>
                )} */}
                {
                  (editMode || readingTime) &&
                  <AttributesItem>
                    <Icon name="schedule" ariaHidden />
                    <span>{getDictionaryItem('global-read-time', '{minutes} min reading time').replace(/\{minutes}/ig, readingTime)}</span>
                  </AttributesItem>
                }
              </AttributesWrapper >
            }
            {
              showCategoryTag && category?.url &&
              <TagContainer>
                <StyledTagList
                  isLinkEnabled={areTagsClickable}
                  size={['sm', 'xs'].includes(bp) ? 'extra-small' : undefined}
                  tagList={[category]}
                  variant={areTagsClickable ? 'interactiveCategory' : 'nonInteractiveCategory'} />
              </TagContainer>
            }
            {
              showTags && tags?.length > 0 &&
              <TagContainer>
                <StyledTagList
                  isLinkEnabled={areTagsClickable}
                  size={['sm', 'xs'].includes(bp) ? 'extra-small' : undefined}
                  tagList={tags}
                  variant="nonInteractiveSubtle" />
              </TagContainer>
            }
          </AttributeFooterWrapper>
        </DetailsColumn >

      </Component >
    )
  );
};

export default ContentCard;
