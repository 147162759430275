import React from 'react';
import { useField } from 'formik';

import ErrorMessage from '_utils/components/Form/ErrorMessage';

import { TextInputProps } from './definitions';
import { TextBoxInput } from './StyledTextInput'

const TextInput: React.FC<TextInputProps> = (props) => {
  const [field, meta] = useField(props);

  const hasError = !!((meta?.touched || meta?.initialTouched) && meta?.error);

  return (
    <>
      <TextBoxInput
        hasError={hasError}
        {...field}
        {...props}
      />

      {hasError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </>
  );
};


export default TextInput;