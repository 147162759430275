import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React from 'react';

import { withDataSourceValidation, withEditMode, CoveoNoIndexWrapper } from '_containers/BaseComponent';

import HeadingWithDescription from '_utils/components/HeadingWithDescription';
import { normalizeJssFields } from '_utils/helpers/jss';
import { useBreakpoint } from '_utils/hooks/useBreakpoint';

import Item from './Item';
import ItemsWrapper from './ItemsWrapper';

import {
  Container,
  Header,
  ListItem,
} from './StyledCardListWithInternalNavigation';

import { CardListWithInternalNavigationProps } from './definitions';
import { cardListFieldValidators } from './validation';

const CardListWithInternalNavigation: React.FC<CardListWithInternalNavigationProps> = ({ rendering, editMode }) => {

  const fields = rendering?.fields?.data?.datasource || {};
  const params = rendering?.params;
  const uid = rendering?.uid || null;
  const normalized = normalizeJssFields(fields);
  const heading = normalized?.heading;
  const description = normalized?.description;
  const cardTheme = (normalized?.theme || 'Blue').toLowerCase();
  const items = normalized?.items || [];
  const trackingName = normalized?.trackingName;

  const headingLevel = params?.headingLevel || 'h3';
  const validItems = !editMode ? items.filter((item) => item.link) : items;
  const hasItems = editMode || (validItems?.length > 0);

  // carousel functionality
  // define how many cards required to trigger carousel behaviour
  const bp = useBreakpoint();
  const numValidItemsToRenderCarousel = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  };

  const carouselEnabled = !editMode && canUseDOM && validItems?.length >= numValidItemsToRenderCarousel[bp];

  // nested repsonsive behaviour
  const multiColumnSettings = {
    md: {
      1: 6,
      2: 6,
      multi: 6
    },
    lg: {
      1: 4,
      2: 6,
      multi: 4
    },
    xl: {
      1: 4,
      2: 6,
      multi: 4
    },
  };

  const responsiveColumnConfig =
    // nested behaviour
    !carouselEnabled ?
      {
        col: {
          xs: 12,
          md: multiColumnSettings.md[validItems.length < 3 ? validItems.length : 'multi'],
          lg: multiColumnSettings.lg[validItems.length < 3 ? validItems.length : 'multi'],
          xl: multiColumnSettings.lg[validItems.length < 3 ? validItems.length : 'multi'],
        }
      }
      :
      // carousel behaviour
      {
        col: {
          xs: 10,
          md: 5,
          lg: 4,
          xl: 4,
        }
      }
    ;

  const level = parseInt(headingLevel?.toLowerCase().replace('h', '')) || 3;

  return (
    <CoveoNoIndexWrapper editMode={editMode}>
      {(editMode || hasItems) &&
        <Container
          numItems={validItems?.length ?? 0}
          className="card-list-with-internal-navigation"
          data-component
          id={uid}
        >
          {(heading || description) && (
            <Header>
              <HeadingWithDescription
                headingField={heading}
                descriptionField={description}
                headingLevel={headingLevel}
              >
              </HeadingWithDescription>
            </Header>
          )}
          {hasItems &&
            <ItemsWrapper
              carouselEnabled={carouselEnabled}
              trackingName={trackingName?.value}
            >
              {validItems.map((item, index) => (
                <ListItem
                  carouselEnabled={carouselEnabled}
                  className={index === 0 ? 'backToParent' : null}
                  config={responsiveColumnConfig}
                  editMode={editMode}
                  key={item.id || index}
                >
                  <Item
                    {...item}
                    themeOption={index === 0 ? cardTheme : null}
                    editMode={editMode}
                    headingLevel={level}
                  />
                </ListItem>
              ))}
            </ItemsWrapper>
          }
        </Container>
      }
    </CoveoNoIndexWrapper>
  );
};

export default compose(withDataSourceValidation(cardListFieldValidators), withEditMode)(CardListWithInternalNavigation);
