import { css } from 'styled-components';

export default (
  lines = 1,
  maxHeight = '16px',
) => css`
  display: -webkit-box;
  line-clamp: ${lines};
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};

  /* IE Only, no support for line-clamp */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: ${maxHeight};
  }
`;