import styled, { css } from 'styled-components';
import { Grid, Link } from '_utils/components/';
import {
  spacing,
} from '_utils/props';
import {
  button,
  color,
  depth,
  fixedTitle12,
  linkWithoutVisited,
  listReset,
  mq,
  safeFocusRemoval,
  textBody1,
  transition,
} from '_utils/styles';

export const CampaignNavigationContainer = styled.section`
  background: ${({ theme }) => theme.campaignNavigation.container.background};
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.campaignNavigation.container.borderColor};
  left: 0;
  line-height: ${({ theme }) => theme.typography.text.fixedBody14.lineHeight}px;
  position: ${({ editMode, isSticky }) => !editMode && isSticky ? 'sticky' : 'relative'};
  right: 0;
  top: ${({ editMode, isSticky }) => !editMode && isSticky ? '-1px' : '0'};
  z-index: ${depth.CAMPAIGN_NAVIGATION};

  @media print {
    display: none;
  }

  &.isScrollingUp {
    top: ${({ theme }) => theme.pageHeader.height.xs}px;

    ${mq('md')} {
      top: ${({ editMode, isSticky, theme }) => !editMode && isSticky && `${theme.pageHeader.height.md}px`};

  }
  }
`;

export const CampaignName = styled.div`
  ${fixedTitle12}
  background: ${({ theme }) => theme.campaignNavigation.campaignName.background};
  color: ${({ theme }) => theme.campaignNavigation.campaignName.color};
  padding: 4px 0;
`;

export const LinkItem = styled(Link)`
  display: flex;

  a {
    ${textBody1}
    ${({ theme }) => spacing({ theme, px: { xs: `8px`, md: `12px` } })};
    border-bottom: 0;
    color: ${({ theme }) => theme.campaignNavigation.linkItem.color};
    flex-grow: 1;
    font-weight: bold;
    line-height: 68px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
  }

  a:hover {
    background-color: ${({ theme }) => theme.campaignNavigation.linkItem.hover.background};
    color: ${({ theme }) => theme.campaignNavigation.linkItem.hover.color};
  }

  a:not(:active):focus {
    ${safeFocusRemoval}
    outline: 2px solid ${({ theme }) => theme.campaignNavigation.linkItem.focus.outline};
    outline-offset: -3px;
  }

  a:active {
    ${safeFocusRemoval}
    background-color: ${({ theme }) => theme.campaignNavigation.linkItem.active.background};

  }

  ${mq('md')} {
    a {
      line-height: inherit;
    }
  }

  .active &:after {
      background-color: ${({ theme }) => theme.campaignNavigation.linkItem.activeAfter.background};
      bottom: -2px;
      content: '';
      height: 4px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 100%;
    }

`;

export const Gradient = styled.span`
  background: linear-gradient(90deg, ${({ theme }) => theme.campaignNavigation.gradient.background} 0%, rgba(247, 247, 247, 0) 100%);
  content: '';
  pointer-events: none;
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: ${transition('opacity', 'fast')};
  width: 64px;
  will-change: opacity;

  ${mq('md')} {
    width: 80px;
  }

  &.visible {
    opacity: 1;
    z-index: 1;
  }

  &.end {
    background: linear-gradient(270deg, ${({ theme }) => theme.campaignNavigation.gradient.end.background} 0%, rgba(247, 247, 247, 0) 100%);
    left: auto;
    right: 0;
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  position: relative;
  min-width: 0;
  width: 100%;

  ${mq.lessThan('md')} {
    border-bottom: 1px solid ${({ theme }) => theme.campaignNavigation.navigation.borderColor};
  }
 `;

export const LinkList = styled.ul`
  ${listReset}
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  line-height: 68px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  width: 100%;

  ${mq.lessThan('md')} {
    li:first-child {
      padding-left: ${({ theme }) => theme.grid.gutter.size.xs}px;
    }

    li:last-child {
      padding-right: ${({ theme }) => theme.grid.gutter.size.xs}px;
    }
    overflow-y: hidden;
  }

  ${mq('md')} {
    align-items: center;

    /* change the thinkness of the scrollbar here*/
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      box-shadow: inset 0 0 8px rgba(0,0,0,0);
      -webkit-border-radius: 8px;
      border-radius: 8px;
    }
    /* add a shadow to the scrollbar here*/
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(0,0,0,0);
      -webkit-border-radius: 8px;
      border-radius: 8px;
    }

    /* this is the little scrolly dealio in the bar*/
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: ${({ theme }) => theme.campaignNavigation.navigation.borderColor};
      height: 3px;
    }

    /* scrollbar corner*/
    &::-webkit-scrollbar-corner {
      display: none;
      height: 0px;
      width: 0px;
    }
  }

  li {
    position: relative;
  }
`;

export const GridContainer = styled(Grid.Container)`
  ${mq.lessThan('md')} {
      margin: 0 -${({ theme }) => theme.grid.gutter.size.xs}px;
      padding-right: 0;
  }
`;

export const GridRow = styled(Grid.Row)`
  ${mq.lessThan('md')} {
    width: calc(100% + 20px);
  }
`;

export const GridItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;

  ${mq.lessThan('md')} {
    padding: 0;
  }

  ${mq('md')} {
    flex-direction: row;
    /* flex-wrap: nowrap; */
    padding: 0;
  }
`;


export const LinkButton = styled(Link)`
  border-bottom: 0;
  ${({ theme }) => spacing({ theme, my: { xs: 2, md: 0 }, ml: { md: 3 }, px: { xs: `${theme.grid.gutter.size.xs}px`, md: 0 } })};
  width: 100%;

  ${mq('md')} {
    align-items: center;
    display: flex;
    width: auto;
  }

  a {
    ${button('slim', 'primary')}
    width: 100%;

    ${mq('md')} {
      ${button('default', 'primary')}
      width: auto;
      white-space: nowrap;
    }

    &:visited {
      color: ${({ theme }) => theme.campaignNavigation.linkButton.visited.color};

      &:hover {
        background-color: inherit;
      }
    }

    &:focus:visited {
        background-color: inherit;
      }
  }
`;
