import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';

import { MOTION_SPEED_FAST } from '_containers/Theme/global';
import { withMenuContext } from '_containers/MenuContext';
import { isValidNavLink } from '_utils/validationChecks'
import { MenuProps } from './definitions';
import MenuItemList from './MenuItemList';
import {
  List
} from './StyledMainMenu';
import { useTimer } from '_utils/hooks';
// https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html

const Menu: React.FC<MenuProps> = ({
  activeMenu,
  children,
  className,
  fields,
  menuActive,
  menuID,
  navigationItems,
  setMenuActive
}) => {
  const datasource = fields?.data?.datasource;
  const [navigation] = navigationItems;

  const primaryItems = navigation?.links ?? [];
  let enabledMenuItems = primaryItems;
  const mainNavRef = useRef<HTMLInputElement>();
  // focused element index - the first value is parent index, second is child index
  const [focusItemIndex, setFocusItemIndex] = useState([-1, -1]);
  const { setTimer } = useTimer();
  const [transitioning, setTransitioning] = useState(false);

  // return all primary menu items that have a valid links
  const getAllEnabledItems = useCallback(() => {
    return enabledMenuItems.filter((item) => isValidNavLink(item));
  }, [enabledMenuItems, primaryItems]);

  // get valid primary menus and return valid child links
  const getAllEnabledChildItems = getAllEnabledItems().map((item) => {
    const childLinks = item?.secondaryNavigation || [];
    const enabledChildLinks = childLinks.map((el) => {
      if (el?.groupSecondarylinks.length) {
        return el?.groupSecondarylinks.filter((groupLink) => isValidNavLink(groupLink));
      } else {
        return isValidNavLink(el) ? el : [];
      }
    }).flat();

    // If there are valid child links, prepend the parent link
    return enabledChildLinks.length ? [item, ...enabledChildLinks] : [];
  });

  // store array of enabled menu item refs inside another ref
  const enabledItemRefs = useRef(getAllEnabledItems().map((item, index) => {
    return {
      uid: createRef(),
      children: getAllEnabledChildItems[index].map(() => createRef())
    }
  }));

  const focusFirstItem = () => {
    if (document.body && document.body.classList.contains('safe-focus-removal')) {
      // dont highlight item if menu was opened with mouse
      return;
    }

    if (enabledItemRefs?.current && enabledItemRefs.current.length) {
      // Find first visible
      const firstAnchor: HTMLAnchorElement = document.querySelector(".magazineLink a");
      firstAnchor?.focus()
    }
  };

  useEffect(() => {
    const currentNavClasses = mainNavRef?.current?.classList;
    if (menuActive && activeMenu === menuID) {
      currentNavClasses.add('transition-in');
      setTimer(() => {
        focusFirstItem();
        currentNavClasses.add('isActive');
        currentNavClasses.remove('transition-in');
        setTransitioning(false);
      }, MOTION_SPEED_FAST + 10);
    } else if (menuActive && activeMenu !== menuID) {
      setFocusItemIndex([-1, -1]);
    }
    return () => {
      currentNavClasses.remove('isActive');
    }
  }, [menuActive, activeMenu]);


  const onItemClick = () => {
    setMenuActive(false);
  };

  const onParentItemClick = (e) => {
    if (e.type === 'keydown') {
      // Keyboard event 'enter' will open submenus
      if (e.keyCode === 13) {
        e.preventDefault();

      }
      return;
    }

    e.preventDefault();

  };

  const resetMenu = (e) => {
    e.preventDefault();
    setFocusItemIndex([-1, -1]);
    setMenuActive(false);
  };

  const onItemFocus = (e, enabledItemsIndex, enabledItemsChildIndex) => {
    if (transitioning) {
      return;
    }
    if (!menuActive) {
      setMenuActive(true);
    }
    setFocusItemIndex([enabledItemsIndex, enabledItemsChildIndex]);
  };

  const onItemBlur = () => {
    setFocusItemIndex([-1, -1]);
  };


  const onKeyDown = (e, focusItemIndex, enabledItemRefs) => {
    if (!menuActive || focusItemIndex[0] === -1 || !enabledItemRefs || !enabledItemRefs?.current.length) {
      return;
    }

    // focusItemIndex is [parent-index, child-index], a '-1' index means its not open/active
    // work out where the focus is to define which index to increase/decrease
    const parentIndex = focusItemIndex[0];
    const childIndex = focusItemIndex[1];
    const isChildNav = !!(childIndex >= 0);
    const hasChildNav = enabledItemRefs?.current[parentIndex]?.children.length;
    const targetIndex = isChildNav ? childIndex : parentIndex;

    const currentFocusGroup = isChildNav ? enabledItemRefs?.current[parentIndex].children.map(({ current }) => current) : enabledItemRefs?.current.map(({ uid }) => uid.current);

    const prevIndex = targetIndex === 0 ? currentFocusGroup.length - 1 : targetIndex - 1;
    const nextIndex = targetIndex === currentFocusGroup.length - 1 ? 0 : targetIndex + 1;

    if (e && e.keyCode === 35) {
      e.preventDefault();
      // END
      if (currentFocusGroup[currentFocusGroup.length - 1]) {
        currentFocusGroup[currentFocusGroup.length - 1].focus();
      }
    } else if (e && e.keyCode === 36) {
      e.preventDefault();
      // HOME
      if (currentFocusGroup[0]) {
        currentFocusGroup[0].focus();
      }
    } else if (e && e.keyCode === 38) {
      e.preventDefault();
      // UP
      if (currentFocusGroup[prevIndex]) {
        currentFocusGroup[prevIndex].focus();
      }
    } else if (e && e.keyCode === 40) {
      e.preventDefault();
      // DOWN
      if (currentFocusGroup[nextIndex]) {
        currentFocusGroup[nextIndex].focus();
      }
    } else if (e && e.keyCode === 39) {
      e.preventDefault();
      // RIGHT
      if (currentFocusGroup[nextIndex]) {
        // if right key on parent item
        if (!isChildNav && hasChildNav) {
          enabledItemRefs?.current[parentIndex].children[0].current.focus();
        } else {
          currentFocusGroup[nextIndex].focus();
        }
      }
    } else if (e && e.keyCode === 37) {
      e.preventDefault();
      // LEFT
      if (currentFocusGroup[nextIndex]) {
        // if left key on parent item
        if (isChildNav) {
          enabledItemRefs?.current[parentIndex].uid.current.focus();
        } else {
          currentFocusGroup[nextIndex].focus();
        }
      }
    }
  };

  const getEnabledItemsIndex = (item) => getAllEnabledItems().indexOf(item);
  const getEnabledItemsChildIndex = (parentIndex, item) => getAllEnabledChildItems[parentIndex].indexOf(item);

  if (!datasource) {
    return null;
  }

  return (
    <List
      className={className}
      ref={mainNavRef}
      onKeyDown={(e) => onKeyDown(e, focusItemIndex, enabledItemRefs)}
    >
      {children}
      <MenuItemList
        enabledItemRefs={enabledItemRefs}
        getEnabledItemsChildIndex={getEnabledItemsChildIndex}
        getEnabledItemsIndex={getEnabledItemsIndex}
        keyPrefix={`primary${menuID}`}
        menuActive={menuActive}
        menuItems={enabledMenuItems}
        onItemBlur={onItemBlur}
        onItemClick={onItemClick}
        onItemFocus={onItemFocus}
        onParentItemClick={onParentItemClick}
        resetMenu={resetMenu}
      />
    </List>
  );
};

export default compose(withSitecoreContext(), withMenuContext)(Menu);
