import React from 'react';
import { canUseDOM } from 'exenv';

const getInitialStorageValue = (storageKey: string) => {
  try {
    const item = canUseDOM ? window.localStorage.getItem(storageKey): null;
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};

export default function useLocalStorage(storageKey: string) {

  if(!canUseDOM) {
    return [null, ()=>{}];
  }

  const storedValue = getInitialStorageValue(storageKey);
  const setValue = (value: {}) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
  };
  return [storedValue, setValue];
}
