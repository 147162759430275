import styled, { css } from 'styled-components';
import buttonSettings from '_containers/Theme/corporate/button';
import {
  button,
  buttonReset,
  fixedTitle14,
  fixedBody14,
  fixedBody12
} from '_utils/styles';

import { spacing } from '_utils/props';


export const LicencesTableWrapper = styled.table`
   border-collapse: collapse;
   width: 100%;

    thead {
        border-bottom: 1px solid #ddd;
    }

    th {
        text-align: left;
        padding: 12px 24px 12px 0px;
        ${fixedTitle14};
        color: ${({ theme }) => theme.form.label.color};
        font-weight: bold;
        line-height: 24px;
    }

    
    td {
        line-height: 24px;
        padding: 12px 24px 12px 0px;
        border-bottom: 1px solid #ddd;
      }

    td:first-child {
      width: 60%;
    }

    td:nth-child(2) {
      max-width: 200px;
      white-space: nowrap; /* prevent the text from wrapping */
      overflow: hidden; /* hide the overflowing text */
      text-overflow: ellipsis;
    }

    td:last-child {
        padding-right: 0px;
        min-width: 80px
    }

    // @media (min-width:768px) {
    //     td {
    //         width: 200px;
    //         max-width: 200px;
    //         white-space: nowrap; /* prevent the text from wrapping */
    //         overflow: hidden; /* hide the overflowing text */
    //         text-overflow: ellipsis;
    //     }
    //     th {
    //         width: 200px;
    //         max-width: 200px;
    //     }
    // }
    
    tfoot td {
        text-align: right;
        border-bottom: 0px;
        padding-top: 20px;
    }

`;

export const LicencesAddButton = styled.button`
    ${button('slim')}
    margin-top: 10px;
    align-items: center;
    border-radius: ${Object.values(buttonSettings?.default?.borderRadius).map(corner => `${corner}px `)};


    svg {
    margin-left: 10px;
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
    }   
`;

export const LicencesActionButton = styled.button`
    border: none;
    background: transparent;
    display: inline-block;
    &:hover {
        color: ${({ theme }) => theme.colors.text.interactive1};
    }
`;

export const ConfirmingButton = styled.button`
${button('slim', 'primary')};
 border: none;
 margin-right: 24px;
 display: inline-block;
 &:hover {
     color: ${({ theme }) => theme.colors.text.interactive1};
 }

 &:disabled {
     background: ${({ theme }) => theme.complexFormElement.editableSection.saveButton.disabled.background};
     box-shadow: none;
     cursor: default;
 
     &:hover,
     &:focus,
     &:active {
       background: ${({ theme }) => theme.complexFormElement.editableSection.saveButton.active.background};
     }
   }
`;

export const LicencesCheckButton = styled.button`
    ${button('slim')}

    align-items: center;
    border-radius: 50%

    svg {
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
    }   

`;

export const LicencesCancelButton = styled.button`
    ${buttonReset()}
    
    color: ${({ theme }) => theme.colors.text.interactive1}; 
    align-items: center;
    border: none

    svg {
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
    }   

    &:hover {
        color: ${({ theme }) => theme.colors.text.interactive1Hover};
    }
`;

export const LicencesActionButtonWrapper = styled.div`
    display: flex;
    justify-content: right; 
    align-items: center;

    button {
        margin-right: 5px;
    }

    button:last-child {
        margin-right: 0px;
    }
`;


export const LicencesHeadingButton = styled.button`
${button('slim', 'primary')};
align-items: center;
display: flex;
justify-content: center;
width: 100%;
margin-bottom: 10px;


svg {
  ${({ theme }) => spacing({ theme, ml: 1 })};
  height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
  width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
}
`;

export const DialogHeadingWrapper = styled.div`
  padding: 16px 24px 0 24px;
`

// For the table UI style
export const StyledTableRow = styled.div`
    svg {
        position: relative;
        width: 40px !important;
        top: 45px;
        left: -3px;  
    }
`;

export const StyledPaddingDiv = styled.div`
    margin-left: 12px;
`


export const TextInput = styled.input`
  ${fixedBody14};
  background-color: ${({ theme }) => theme.form.input.background};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.form.input.border};
  color: ${({ theme }) => theme.form.input.color};
  line-height: 24px;
  outline-color: ${({ theme }) => theme.form.input.borderFocus};
  outline-offset: 0px;
  padding: 12px 16px;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.form.input.borderHover};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.form.input.borderFocus};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.form.input.backgroundDisabled};
    border-color: ${({ theme }) => theme.form.input.borderDisabled};
    color: ${({ theme }) => theme.form.input.colorDisabled};
    pointer-events: none;
  }

  ${({ hasError }) => hasError && css`
    &:not(:disabled) {
      border-color: ${({ theme }) => theme.form.input.borderError};
      &:focus {
        outline-color: ${({ theme }) => theme.form.input.borderFocusError};
      }
    }
  `}
`;

// For the list UI view
export const LicencesListRowWrapper = styled.div`
  border-top: 1px solid #ddd;
  padding: 12px 0px;
  width: 100%
`

export const LicencesListContentWrapper = styled.div`
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 6px 12px 6px 0px;
  }
  span:first-child {
    text-align: left;
    ${fixedTitle14};
    color: ${({ theme }) => theme.form.label.color};
    font-weight: bold;
  }

  span:last-child {
    ${fixedBody14};
    color: ${({ theme }) => theme.form.label.color};
    ${({ isRegistrationNumber }) => {
      if (isRegistrationNumber) {
        return css`
          max-width: 250px;
          white-space: nowrap; /* prevent the text from wrapping */
          overflow: hidden; /* hide the overflowing text */
          text-overflow: ellipsis;
        `;
      }
    }}
  }
`;

export const DialogKeyTextStyle = styled.div`
  ${fixedBody12};
  color: ${({ theme }) => theme.form.label.color}; 
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  ${fixedBody12};
  color: ${({ theme }) => theme.form.error.color};
  position: absolute;
  margin-top: 2px;
`;