import { useField } from 'formik';
import React from 'react';

import TextInput from '_utils/components/Form/TextInput';
import { TextInputProps } from '_utils/components/Form/TextInput/definitions';

const DateInput: React.FC<TextInputProps> = (props) => {

  const [, , helpers] = useField(props);
  const { setValue } = helpers;

  return (
    <>
      <TextInput
        onKeyUp={e => {

          const val = e?.target?.value;
          const len = val?.length ?? 0;

          // allow user move through input manually
          if (
            [
              'ArrowLeft',
              'ArrowRight',
              'Control',
              'End',
              'Escape',
              'Enter',
              'Home',
              'Insert',
              'PageDown',
              'PageUp',
              'Shift',
              'Tab',
            ].includes(e?.key)) {
            return;
          }

          // only allow numbers or forward slash
          const isAllowedInput =
            (e?.key === '/' && ![4, 7].includes(len)) ||
            parseInt(e.key) == 0 ||
            !!parseInt(e.key);

          const isDelete = [8, 46].includes(e?.keyCode);

          // if trying to delete or not a number, delete last char
          if ((!isAllowedInput && !isDelete) || (isDelete && val.substring(-1) === '/')) {
            setValue(val.substring(0, len - 1));
            return;
          }

          // If they don't add the slash, do it for them...
          if ([2, 5].includes(len) && !isDelete) {
            setValue(`${val}/`);
          }
        }}

        {...props}
      />
    </>
  );
};

export default DateInput;
