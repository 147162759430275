import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { canUseDOM } from 'exenv';
import moment from 'moment-mini';

import { Address } from '_containers/MemberContext/definitions';
import { DateFormat } from '_utils/components/Form/definitions';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { deleteKeyFromQueryString } from '_utils/data/queryString';

// TODO - add unit tests

export const YEAR_FIRST_FORMAT = 'YYYY-MM-DD';
export const DAY_FIRST_FORMAT = 'DD/MM/YYYY';
export const REF_PARAM = 'ref';
export const RENEWAL_REF_PARAM_VALUE = 'renewal';

const sameAsResidentialLabel = getDictionaryItem('form-contact-same-as-residential-address', 'Same as residential');

// Helper functions
export const buttonAction = (e: Event, link?: any, targetBlank?: boolean) => {
  e.preventDefault();

  const target = targetBlank ? '_blank' : '_self'

  if (canUseDOM && link?.href) {
    return window.open(
      link.href,
      target,
    );
  }
};

export const handleMembershipRenewalUpdateClick = (
  e: Event,
  link?: LinkFieldValue
) => {
  e.preventDefault();

  if (canUseDOM && link?.href) {
    const linkWithReferrerParam = new URL(link.href, document.baseURI); // sitecore returns relative paths only, so we need to specify the base
    linkWithReferrerParam.searchParams.set(REF_PARAM, RENEWAL_REF_PARAM_VALUE);

    return window.open(
      linkWithReferrerParam.href,
      '_self',
    );
  }
};

const toTitleCase = (str: string) => {
  if (!str) return '';
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
};

export const getAddress = (address: Address) => {
  if (!address) return '';

  // address lines
  const addressArr = [];

  if (address?.addressLine1) {
    addressArr.push(address.addressLine1);
  }

  if (address?.addressLine2) {
    addressArr.push(address.addressLine2);
  }

  if (address?.addressLine3) {
    addressArr.push(address.addressLine3);
  }

  const addressStr = addressArr?.length ? addressArr.join('\n') : null;

  // last line (city, isoCode, state, country) needs to be comma-separated
  const lastLineArr = [];

  if (address?.city) {
    lastLineArr.push(toTitleCase(address.city));
  }
  if (address?.state?.name) {
    lastLineArr.push(toTitleCase(address.state.name));
  }
  if (!address?.state?.name && address?.state?.isoCode) {
    lastLineArr.push(address.state.isoCode);
  }
  if (address?.country?.name) {
    lastLineArr.push(toTitleCase(address.country?.name));
  }
  if (address?.postCode) {
    lastLineArr.push(address.postCode);
  }

  const lastLineString = lastLineArr.join(', ');

  // merge address and last line
  const completeAddressArr = [];

  if (addressStr) {
    completeAddressArr.push(addressStr);
  }
  if (lastLineString) {
    completeAddressArr.push(lastLineString);
  }

  const completeAddressStr = completeAddressArr?.length ? completeAddressArr.join('\n') : '';

  return completeAddressStr;
};

export const getMailingAddress = (address: Address, mailingAddress: Address) => {
  const addressStr = getAddress(address);
  const mailingAddressStr = getAddress(mailingAddress);

  return addressStr === mailingAddressStr ? sameAsResidentialLabel : mailingAddressStr;
};

export const getMailingContact = (billingContact: string, mailingContact: string) => {
  if (!mailingContact || (billingContact && (billingContact === mailingContact))) {
    return null;
  }

  return mailingContact;
};

export const getCommaSeparatedList = (items: any[], propertyName: string = 'name'): string => {
  if (!items || (items?.length ?? 0) === 0) {
    return '';
  }
  const list = items.map((i) => i?.[propertyName]) || [];

  return list?.length ? list.join(', ') : '';
};

export const renderBooleanAsString = (value: boolean) => {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
};

export const castStringAsBoolean = (value) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return null;
};

export const renderSelectPlaceholderForField = (fieldName: string) => {
  const selectPlaceholderLabel = getDictionaryItem("form-placeholder-select", "Select {field}");
  return selectPlaceholderLabel.replace(/\{field\}/ig, fieldName?.toLowerCase());
};

export const renderSearchPlaceholderForField = (fieldName: string) => {
  const selectPlaceholderLabel = getDictionaryItem("form-placeholder-search", "Search {field}");
  return selectPlaceholderLabel.replace(/\{field\}/ig, fieldName?.toLowerCase());
};

export const renderRequiredError = (
  fieldName: string,
  token = '{field}',
  errorMessage = getDictionaryItem('required-error-message', '{field} is a required field'),
) => {
  if (!fieldName || !errorMessage) {
    return null;
  }

  return replaceTokens(errorMessage, { [token]: fieldName });
};

export const renderInvalidError = (
  fieldName: string,
  token = '{field}',
  errorMessage = getDictionaryItem('invalid-error-message', '{field} has an invalid format'),
) => {
  if (!fieldName || !errorMessage) {
    return null;
  }

  return replaceTokens(errorMessage, { [token]: fieldName });
};

export const renderInvalidMinLengthError = (
  fieldName: string,
  minLength: number
) => {
  const errorMessage = getDictionaryItem('invalid-min-length-message', '{field} must be at least {length} characters');
  if (!fieldName || !errorMessage) {
    return null;
  }

  return replaceTokens(errorMessage, { '{field}': fieldName, '{length}': minLength });
};

const replaceTokens = (str: string, mapObj: object) => {
  var regex = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(regex, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

/**
 * @name formatDate
 * @param {string} date Either YYYY-MM-DD (YEAR_FIRST) or DD/MM/YYYY (DAY_FIRST)
 * @param {DateFormat} outputFormat Desired output format
 * @description Takes a YEAR_FIRST or DAY_FIRST date and returns date in desired format. Invalid inputs result in original value returned. Falsy inputs return empty string.
 */
export const formatDate = (
  dateOfBirth: string,
  outputFormat: DateFormat,
) => {
  if (!dateOfBirth) return '';

  const isYearFirstInput = moment(dateOfBirth, YEAR_FIRST_FORMAT, true).isValid();
  const isDayFirstInput = moment(dateOfBirth, DAY_FIRST_FORMAT, true).isValid();

  if (isYearFirstInput && outputFormat === 'DAY_FIRST') {
    return moment(dateOfBirth, YEAR_FIRST_FORMAT).format(DAY_FIRST_FORMAT);
  }

  if (isDayFirstInput && outputFormat === 'YEAR_FIRST') {
    return moment(dateOfBirth, DAY_FIRST_FORMAT).format(YEAR_FIRST_FORMAT);
  }

  return dateOfBirth;
};

// append '+' and merge countryCode and mobile
export const formatMobilePhone = (countryCode: string, mobilePhone: string) => {
  if (!countryCode && !mobilePhone) {
    return;
  }
  if (!countryCode) {
    return mobilePhone;
  }

  return `+ ${countryCode} ${mobilePhone}`;
};

export const removeRenewalReferrerFromQueryString = () => {
  if (canUseDOM) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url?.search);

    if ((params?.get(REF_PARAM) === RENEWAL_REF_PARAM_VALUE)) {
      deleteKeyFromQueryString(REF_PARAM);
    }
  }
}

export const checkUserRerferredFromRenewal = () => {
  if (!canUseDOM) {
    return
  };

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url?.search);

  return (params?.get(REF_PARAM) === RENEWAL_REF_PARAM_VALUE);
}
