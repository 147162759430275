export const setAnchorElemScrollMarginTop = (targetEl?: HTMLElement) => {
  if (!targetEl) {
    return
  }

  const breadcrumbHeight = document.querySelector('.page-breadcrumb')?.getBoundingClientRect().height || 50;
  const pageHeaderHeight = document.getElementById('page-header')?.getBoundingClientRect().height || 73;
  const calcScrollMarginTop = pageHeaderHeight && breadcrumbHeight ? pageHeaderHeight + breadcrumbHeight : pageHeaderHeight;

  // Apply scroll-margin-top on the elemToScroll item that is being covered by the fixed header and breadcrumb
  // Docs: https://www.blindemanwebsites.com/today-i-learned/2020/scroll-margin-top/
  const setElemScrollMarginTop = (e: HTMLElement) => e?.setAttribute('style', `scroll-margin-top: ${calcScrollMarginTop}px`);
  setElemScrollMarginTop(targetEl);
}
