import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    background: colors.all.brandPrimary1,
    mediaPrint: {
      background: colors.background.tint1,
      color: colors.element.neutralBlack
    }
  },
  labelColor: colors.all.darkText,
  headerColor: colors.all.neutralWhite,
  color: colors.all.darkText,
  buttonBackground: colors.all.brandPrimary2,
  buttonColor: colors.all.brandPrimary3,
};
