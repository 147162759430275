import React from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';

import Modal from '_components/Modal';
import DigitalDataProvider from '_containers/DigitalDataContext';
import MenuProvider from '_containers/MenuContext';
import ModalProvider from '_containers/ModalContext';
import { Language } from '_types';

import {
  Grid,
  SpaLoader,
} from '_utils/components';

import { ITBAllPagesStructuredData, ITBArticlePageStructuredData } from '_utils/components/StructuredDataITB';

import { INVISIBLE_CLASS } from '_utils/helpers/render';

import { LayoutProps } from './definitions';

import {
  ArticleContainer,
  ArticleFooterPlaceholder,
  IntroContentPlaceholder,
  MainContainer,
  MainContentPlaceholder,
  OutroContentPlaceholder,
  SidebarContainer,
  SingleColumnContainer,
} from './StyledLayout';

/*
  DEFAULT APP LAYOUT
*/

const DefaultLayout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { loading, route } = props;
  const languageType: Language = route?.fields?.languageType?.value || null;

  return (
    <>
      <MenuProvider>
        <ModalProvider>
          <DigitalDataProvider>
            <Placeholder name="jss-header" rendering={route} />
            <MainContainer
              className="intheblack-sidebar"
              id="main-content"
              lang={languageType}
              name="jss-main"
              rendering={route}
              role="main"
            >

              <SpaLoader />

              {/* prevents flash reverted content when route change and personalisation applied */}
              <div className={loading && INVISIBLE_CLASS}>
                <Placeholder
                  name="jss-content-intro"
                  rendering={route}
                  render={(components) => (
                    <IntroContentPlaceholder>
                      {components}
                    </IntroContentPlaceholder>
                  )}
                />
                <Grid.Container>
                  <Grid.Row
                    config={{ gutters: ['left', 'right'] }}>
                    <Placeholder
                      name="jss-content-intro-single-column"
                      rendering={route}
                      render={(components) => (
                        <>
                          {!!components.length &&
                            <SingleColumnContainer className="jss-content-intro-single-column ph">
                              {components}
                            </SingleColumnContainer>
                          }
                        </>
                      )}
                    />
                  </Grid.Row>
                  <Grid.Row
                    config={{ gutters: ['left', 'right'] }}>
                    {/* Main content */}
                    <ArticleContainer
                      className="jss-content-main ph"
                      config={{ col: { xs: 12, md: 8 } }}>
                      <MainContentPlaceholder name="jss-content-main" rendering={route} />
                      <Placeholder
                        name="jss-content-article-footer"
                        rendering={route}
                        render={(components) => (
                          <>
                            {!!components.length &&
                              <ArticleFooterPlaceholder>
                                {components}
                              </ArticleFooterPlaceholder>
                            }
                          </>
                        )}
                      />
                    </ArticleContainer>
                    {/* Sidebar content */}
                    <SidebarContainer
                      className="jss-content-sidebar ph"
                      config={{ col: { xs: 12, md: 4 } }}
                      rendering={route}>
                      <Placeholder name="jss-content-sidebar" rendering={route} />
                    </SidebarContainer>
                  </Grid.Row>
                </Grid.Container>
                <Placeholder name="jss-content-outro" rendering={route}
                  render={(components) => (
                    <OutroContentPlaceholder>
                      {components}
                    </OutroContentPlaceholder>
                  )}
                />
              </div>

            </MainContainer>
            <Placeholder name="jss-footer" rendering={route} />
            <Modal />
            {/* Google structured data */}
            <ITBAllPagesStructuredData {...props} />
            <ITBArticlePageStructuredData {...props} />
          </DigitalDataProvider>
        </ModalProvider>
      </MenuProvider>
      <VisitorIdentification />
    </>
  );
};

export default DefaultLayout;
