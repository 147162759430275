import colors from '_containers/Theme/corporate/colors';

export default {
  cardTheme: {
    borderRadius: 4,
    borderColor: colors.all.neutral200,
    main: colors.background.tint1,
    tint: colors.all.brandSecondary3
  },
  footLinkRow: {
    color: colors.text.default
  },
  iconWrapper: {
    background: colors.ui.accent1
  },
  linkListItem: {
    background: colors.background.tint1
  },
  linkListItemDesc: {
    color: colors.text.subtle
  }
};
