import React from 'react';

import { SectionWrapper } from './StyledSection';
import { SectionProps } from './definitions';

const Section: React.FC<SectionProps> = ({ backgroundColor, className, children, sectionTheme, ...rest }) => {

  const sectionThemeClass = typeof sectionTheme !== 'undefined' ? sectionTheme.toLowerCase() : '';
  const bgColor = typeof backgroundColor !== 'undefined' ? backgroundColor : '';

  return (
    <SectionWrapper
      backgroundColor={bgColor}
      className={`${className} ${sectionThemeClass}`}
      {...rest}
    >
      {children}
    </SectionWrapper>
  )
};

export default Section;
