import styled from 'styled-components';
import { button } from '_utils/styles';

export const BaseComponentContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const BaseComponentButton = styled.button`
  ${button('default', 'primary')}
  align-items: center;
  display: inline-flex;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);

  svg {
    margin-left: 10px;
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
  }
`;
