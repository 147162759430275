import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Grid from '_utils/components/Grid';

import { spacing } from '_utils/props';
import {
    color,
    fixedBody12,
    linkCard,
    listReset,
    mq,
    restrictCardTitle,
    svg,
    textBody1,
} from '_utils/styles';

import {
    avoidPageBreakOnPrint,
    hideOnPrint,
} from '_utils/styles/print';

const container = css`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq('lg')} {
    height: 100%;
  }
`;

export const ItemList = styled.li`
  width: 100%;
  min-height: 100%;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;

  ${mq('md')} {
    margin-bottom: 30px;
  }
  
`;

export const PlaceholderCard = styled(Grid.Item)`
  background-color: ${({ theme }) => theme.cardList.cardBackground};
`;

export const Card = styled(NavLink)`
  ${({ theme }) => linkCard({ theme })}
  ${({ theme }) => spacing({ theme, pr: 3, pl: 3, pb: 3 })}
  ${container}
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  box-shadow: none;
  border-radius: 0;
  padding: 12px 0 24px 0;

  /* HACK: add height of HighlightStrip because absolute positioned */
  padding-top: ${(theme) => theme.$hasHighlightStrip ? '30px' : '24px'};
  position: relative;

  //border-top

  border-top: 1px solid ${({ theme }) => theme.colors.all.neutral300};

   > last-child {
    border-bottom: 0
   }

  &:hover {
    h4 {
      color: ${({ theme }) => theme.colors.text.interactive1};
    }
  }

  .fullwidth & {
    ${mq('md')} {
      flex: 1 1 auto;
      flex-wrap: wrap;
    }
  }

  ${avoidPageBreakOnPrint}
`;

export const NewCard = styled.a`
  display: none;
  text-decoration: none;
  color: inherit;
  ${mq('sm')} {
    display: block
  }
`;


export const Image = styled(SCImage)`
  // font-family: 'object-fit: cover;',serif;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const DetailsColumn = styled.div`
  ${textBody1};
  color: ${color('text.subtle')};
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  margin-top: 30px;
  
  > div {
    display: flex;
    flex-direction: column;
  }

  .fullwidth .hasImage & {
    ${mq('md')} {
      width: 66.6%;
    }
  }
`;


export const ArrowWrapper = styled.span`
  ${svg(24, 24)}
  display: flex;
  width: 24px;
  margin-left: 6px;
  path {
    fill: ${({ theme }) => theme.cardList.action};
  }
`;

export const CardTitle = styled.div`
  h4& {
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 22px;
    line-height: 26px;
  }
`;

export const SubTitle = styled.div`
  h3& {
    margin-top: 0;
    margin-bottom: 0;
    ${mq('md')} {
      font-size: 28px;
      line-height: 32px;
    }

    ${mq.lessThan('md')} {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;


export const FirstPartFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 22px;
  a {
    color: ${({ theme }) => theme.cardList.action};
    text-decoration: none;
  }
`;

export const CardFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  margin-left: 22px;
  margin-top: 12px;
  a {
    color: ${({ theme }) => theme.cardList.action};
    text-decoration: none;
  }
`;

export const CardTypeLabel = styled.span`
  display: none;
  font-weight: bold;
  text-transform: capitalize;

  .fullwidth & {
    ${mq('md')} {
      display: flex;
    }
  }
`;

export const Description = styled.p`
  ${textBody1};
  ${restrictCardTitle};
  flex-grow: 0;
  margin-bottom: 0 !important;
  margin-top: 10px;
  color: black;
  letter-spacing: 0.2px;
  ${mq('md')} {
    margin-top: 20px;
    font-size: 18px;
  }
`;

export const CardDescription = styled.div`
  ${textBody1};
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TagContainer = styled.ul`
  ${listReset}
  margin-bottom: 14px;

  ${hideOnPrint}
`;

export const Tag = styled.li`
  ${fixedBody12};
  background: ${({ theme }) => theme.colors.background.tint3};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.subtle};
  display: inline;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 0 4px;
  text-transform: uppercase;
`;