import colors from '_containers/Theme/corporate/colors';
import {
  headingH5,
  subheading1,
  textBody1,
  textBody2
} from '_utils/styles';

export default {
  base: {
    maxWidth: 91.67 // mimic 10/12 columns
  },
  blockquote: {
    background: colors.all.brandSecondary3,
    heading: subheading1,
  },
  externalLink: {
    icon: {
      afterColor: colors.all.brandPrimary1,
      hoverColor: colors.all.neutralWhite
    }
  },
  list: {
    textbody: textBody2,
    marker: {
      content: "-",
      fontSize: '24px',
      color: colors.element.primary3,
    },
    border: `2px solid`,
    borderColor: colors.element.primary,
    color: colors.element.primary,
    fontSize: headingH5
  },
  paragraph: textBody1,
  orderedList: {
    bullet: {
      fontSize: '27px',
      top: '-4px'
    },
    lineHeight: 24,
    md: {
      lineHeight: 28,
    },
    padding: '0 0 0 22px',
  },
  richTextWithImage: {
    captionColor: colors.all.neutral500
  },
  table: {
    head: {
      background: colors.table.head.background,
      color: colors.table.head.color,
    },
    row: {
      background: colors.table.row.background,
      backgroundAlt: colors.table.row.backgroundAlt,
    },
    cell: {
      border: `1px solid`,
      borderColor: colors.table.cell.border
    }
  }
};
