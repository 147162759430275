import React, { createContext, useState } from 'react';

import { Optionalize } from '_utils/types';

import { NormalisedTimezone, TimezoneContextProps } from './definitions';

export const DEFAULT_TIMEZONE_STANDARD_NAME = 'Australia/Melbourne'
export const DEFAULT_SC_NULL_DATE = '0001-01-01T00:00:00Z';

const defaultTimezone: NormalisedTimezone = {
  abbreviation: { value: '' },
  standardRegionName: { value: DEFAULT_TIMEZONE_STANDARD_NAME },
  displayRegionName: { value: '' },
  offset: { value: '' },
};

/**
 * @name checkHasDayShifted
 * @description Checks if the date supplied is the same day as the default timezone
 */
export const checkHasDayShifted = (timezone: string) => (dateToCheck: string) => {
  try {
    if (dateToCheck === DEFAULT_SC_NULL_DATE) {
      throw new Error('Date is not set');
    } else {
      const dateToCheckAsDate = new Date(dateToCheck);

      const dateToCheckAsDefaultTimezone = new Intl.DateTimeFormat('en', {
        timeZone: DEFAULT_TIMEZONE_STANDARD_NAME,
      }).format(dateToCheckAsDate);

      const dateToCheckAsCurrentTimezone = new Intl.DateTimeFormat('en', {
        timeZone: timezone,
      }).format(dateToCheckAsDate);

      return new Date(dateToCheckAsCurrentTimezone)?.getDate() !== new Date(dateToCheckAsDefaultTimezone)?.getDate();
    }
  } catch {
    return false;
  };
};

export const convertUTCToCurrentTimezone = (timezone: string) => (utcDate: string, shouldReturnLongDate: boolean) => {
  try {
    if (utcDate === DEFAULT_SC_NULL_DATE) {
      throw new Error('Date is not set');
    } else {
      const utcDateAsDate = new Date(utcDate);

      return new Intl.DateTimeFormat('en', {
        weekday: shouldReturnLongDate ? 'short' : undefined,
        day: shouldReturnLongDate ? '2-digit' : undefined,
        month: shouldReturnLongDate ? 'short' : undefined,
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
      })?.format(utcDateAsDate);
    }
  } catch {
    return '';
  };
};

const TimezoneContext = createContext({
  checkHasDayShifted: null,
  convertUTCToCurrentTimezone: null,
  timezone: null,
  setTimezone: null
});

function TimezoneProvider({ children }) {
  const [timezone, setTimezone] = useState<NormalisedTimezone>(defaultTimezone);

  return (
    <TimezoneContext.Provider
      value={{
        checkHasDayShifted: checkHasDayShifted(timezone?.standardRegionName?.value),
        convertUTCToCurrentTimezone: convertUTCToCurrentTimezone(timezone?.standardRegionName?.value),
        timezone,
        setTimezone
      }}
    >
      {children}
    </TimezoneContext.Provider>
  );
}

export default TimezoneProvider;

export const TimezoneConsumer = TimezoneContext.Consumer;

export function withTimezoneContext<T extends TimezoneContextProps = TimezoneContextProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withTimezoneContext: React.FC<Optionalize<T, TimezoneContextProps>> = (props) => (
    <TimezoneContext.Consumer>
      {(value) => <WrappedComponent {...(props as T)} {...value} />}
    </TimezoneContext.Consumer>
  );

  withTimezoneContext.displayName = `withTimezoneContext(${displayName})`;

  return withTimezoneContext;
}
