import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import colors from '_containers/Theme/colors';

import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import { button, color, mq } from '_utils/styles';

export const FormSection = styled(Grid.Container)`
  ${({ theme }) => spacing({ theme, p: 3, mb: 3 })};
  background: ${({ theme }) => theme.complexFormElement.formSection.background};
  border: 1px solid ${({ theme }) => theme.complexFormElement.formSection.borderColor};
  border-radius: 4px;
`;

export const HeadingContainer = styled.div`
  margin-bottom: 16px;

  ${mq('sm')} {
    align-items: flex-start;
    display: flex;
    margin-bottom: 24px;
  }

  ${mq('md')} {
    margin-bottom: 32px;
  }
`;

export const HeadingIcon = styled.div`
  color: ${({ theme }) => theme.complexFormElement.formSection.headingIcon.color};
  display: flex;
  height: 32px;
  width: 32px;

  svg {
    width: 100%;
  }
`;

export const Heading = styled.h3`
  flex: 1;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 0 32px;

  ${mq('sm')} {
    font-size: 22px;
    line-height: 26px;
    margin: 4px 16px 16px;
  }
`;

export const LargeHeading = styled.h3`
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 16px;
  padding: 0;

  ${mq('sm')} {
    margin: 0 0 32px;
  }

  ${mq('md')} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const HeadingButton = styled.button`
  ${button('slim', 'tertiary')};
  padding-right: 10px;
  width: 100%;

  ${mq('sm')} {
    width: auto;
  }

  svg {
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    margin-left: 10px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
  }
`;

export const DashboardButtonContainer = styled.div`
  ${({ theme }) => spacing({ theme, my: 5 })};
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const DashboardButton = styled(HeadingButton)`
  margin-top: 0px;
`;

export const FormSummary = styled(Grid.Row)`
  ${({ theme }) => spacing({ theme, mb: { xs: 5, md: 4 } })}
`;

export const Field = styled(Grid.Item)``;

export const EditModeHeading = styled.h2`
  margin: 12px 0;
  padding: 0 20px;
`;

export const EditModeInstructions = styled.p`
  margin: 12px 0;
  padding: 0 20px;
`;

export const RichTextContainer = styled.div`
  & > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  & .content-rich-text {
    hr {
      ${({ theme }) => spacing({ theme, my: 3 })};
      border-top: 1px solid ${({ theme }) => theme.complexFormElement.formSection.richTextContainer.borderColor};
    }

    h5 {
      ${({ theme }) => spacing({ theme, mt: 3 })};

      &:first-child {
        margin-top: 0;
      }
    }

    & > * {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      max-width: 100%;
    }
  }
`;


// Button to view my account
export const CTAButton = styled.button`
  ${button('default', 'tertiary')};
  align-items: center;
  display: flex;
  justify-content: center;
  ${({ theme }) => spacing({ theme, mt: 5 })};
  width: 100%;

  ${mq('md')} {
    width: auto;
  }

  svg {
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    margin-right: 10px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
  }
`;
