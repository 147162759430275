import React from 'react';

import Icon from '_components/Icon';
import NavItem from '_components/NavItem';
import PersonFallbackImage from '_utils/components/PersonFallbackImage';
import ScreenReader from '_utils/components/ScreenReader';
import { convertSrcJss } from '_utils/media';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { ImageSize } from '_utils/types/enums';
import { imagePlaceholderSrc } from '_utils/styles';
import { ContributorInfoProps } from './definitions';
import {
  Container,
  Heading,
  ProfileDetails,
  ProfileImage,
  ProfileFigure,
  ProfileBio,
  ProfileFigureWrap,
  SocialLink,
  SocialListItem
} from './StyledContributor'

const ContributorInfo: React.FC<ContributorInfoProps> = ({ rendering }) => {
  const renderingContextItem = rendering?.fields?.data?.contextItem || {};
  const photo = renderingContextItem?.photo;
  const uid = rendering?.uid;
  const bio = renderingContextItem?.bio?.jss;
  const fullName = renderingContextItem?.fullName?.jss?.value;

  const socialLinkMap: any = [
    {
      facebook: {
        title: 'facebook',
        url: renderingContextItem.facebook?.jss?.value,
        href: renderingContextItem.facebook?.jss?.value,
        linktype: 'external',
        target: "_blank"
      }
    },
    {
      linkedin: {
        iconName: 'linked_in',
        url: renderingContextItem.linkedIn?.jss?.value,
        href: renderingContextItem.linkedIn?.jss?.value,
        title: 'linked-in',
        linktype: 'external',
        target: "_blank"
      }
    },
    {
      twitter: {
        title: 'twitter',
        url: renderingContextItem.twitter?.jss?.value,
        href: renderingContextItem.twitter?.jss?.value,
        linktype: 'external',
        target: "_blank"
      }
    },
    {
      instagram: {
        title: 'instagram',
        url: renderingContextItem.instagram?.jss?.value,
        href: renderingContextItem.instagram?.jss?.value,
        linktype: 'external',
        target: "_blank"
      }
    },
    {
      tiktok: {
        title: 'tiktok',
        url: renderingContextItem.tiktok?.jss?.value,
        href: renderingContextItem.tiktok?.jss?.value,
        linktype: 'external',
        anchor: "",
        target: "_blank"
      }
    }
  ];
  const socialLinks = (socialProfileData) => {
    return socialProfileData.map((item, index) => {
      const objectvalues: {} = Object.values(item);
      const link = objectvalues[0];
      const iconName = link?.iconName || link?.title;
      const iconLabel = getDictionaryItem(`contributor-accessible-social-label-${link?.title}`, link?.title)
      if (link?.url) {
        return (
          <SocialListItem key={index}>
            <NavItem link={link} StyledLink={SocialLink} className={iconName} ariaLabelledBy={iconName} >
              <ScreenReader>{iconLabel}</ScreenReader>
              <Icon ariaHidden={true} name={iconName} />
            </NavItem>
          </SocialListItem>
        )
      }
      else {
        return null
      }
    });
  }

  return (
    <Container
      className="contributor-info"
      data-component
      id={uid}
    >
      <ProfileFigureWrap>
        <ProfileFigure >
          {photo?.jss?.value?.src ? (
            <ProfileImage
              className="lazyload"
              draggable={false}
              field={
                {
                  value: {
                    src: imagePlaceholderSrc,
                    alt: photo?.jss?.value?.alt,
                    ...{ 'data-src': `${convertSrcJss(photo?.jss?.value?.src, ImageSize.MW_512)}` },
                  }
                }
              }
              height={photo?.jss?.value?.height}
              width={photo?.jss?.value?.width}
            />
          ) : (
            <PersonFallbackImage />
          )}
        </ProfileFigure>
      </ProfileFigureWrap>

      <ProfileDetails>
        <Heading>{fullName}</Heading>
        <ProfileBio field={bio} />
        <>
          {socialLinks(socialLinkMap)}
        </>
      </ProfileDetails>
    </Container>
  );
};

export default ContributorInfo;
