import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

import Icon, { MaterialIcons } from '_components/Icon';

import { InPageAnnouncementProps } from '../definitions';

import {
  AnnouncementCTA,
  AnnouncementWrapper,
  TitleWrapper,
  Content,
  TextWrapper,
  IconWrapper
} from './StyledInPageAnnouncement';

const Icons = {
  Blue: 'check_circle' as MaterialIcons,
  Yellow: 'warning_amber' as MaterialIcons,
  Red: 'error_outline' as MaterialIcons,
  Green: 'check_circle' as MaterialIcons,
};

const InPageAnnouncement: React.FC<InPageAnnouncementProps> = ({
  customIcon,
  id,
  description,
  link,
  title,
  type = { value: 'Blue' },
  isTitleBold
}) => {

  return (
    <AnnouncementWrapper role="alert" alertStyle={type?.value} id={id} className="in-page-announcement">
      <IconWrapper>
        <Icon name={customIcon?.targetItem?.name ?? Icons[type?.value]} />
      </IconWrapper>
      <TextWrapper>
        <TitleWrapper isBold={isTitleBold}>
          <Text field={title} editable={false}/>
        </TitleWrapper>

        {description?.value && <Content field={description} editable={false} />}

        <AnnouncementCTA
          field={link}
          fallbackTitle={link?.fallbackTitle}
          linkSize="s"
          linkTheme="none"
        />
      </TextWrapper>
    </AnnouncementWrapper>
  );
};

export default InPageAnnouncement;
