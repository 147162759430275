import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';
import { useLocationAPIContext } from '_containers/LocationAPIContext';
import AddressManual from '_utils/components/Form/AddressManual';
import { ManualModeToggle } from '_utils/components/Form/AddressManual/StyledAddressManual';
import AddressDropdown from '_utils/components/Form/Dropdown/AddressDropdown';
import CountryDropdown from '_utils/components/Form/Dropdown/CountryDropdown';
import FormLabel from '_utils/components/Form/Label';
import { FormSummary, Field as AddressManualField } from '_utils/components/Form/StyledFormSection';
import TextInput from '_utils/components/Form/TextInput';
import { getDictionaryItem } from '_utils/data/dictionaryItem';

import {
  AddressEditorProps,
  DeliveryContactFieldProps,
} from '../definitions';
import {
  AddressDropdownContainer,
} from '../StyledContactDetailsForm';

const DeliveryContactField: React.FC<DeliveryContactFieldProps> = ({ formValuesKey }) => {
  const deliveryContactLabel = getDictionaryItem("form-contact-delivery-contact", "Delivery contact");
  const deliveryContactPlaceholder = getDictionaryItem("form-placeholder-delivery-contact", "Enter first and last name ");

  return (
    <FormSummary>
      <AddressManualField config={{ col: { xs: 12, sm: 4 } }}>
        <FormLabel htmlFor="primaryContactName">{deliveryContactLabel}</FormLabel>
        <TextInput
          id={`${formValuesKey}.primaryContactName`}
          maxLength={100}
          name={`${formValuesKey}.primaryContactName`}
          placeholder={deliveryContactPlaceholder}
          type="text"
        />
      </AddressManualField>
    </FormSummary>
  );
};

const AddressEditor: React.FC<AddressEditorProps> = ({
  formValuesKey,
}) => {
  const { values, touched, setFieldValue, setTouched, validateField } = useFormikContext<ContactDetailsFormValues>();
  const { countriesMap, hasCountryGotAddressLookupFeature } = useLocationAPIContext();
  const address = values?.[formValuesKey];
  const selectedCountryIsoCode = address?.country?.isoCode2Letter;
  const hasExistingAddress = !!address?.addressLine1;
  const isShippingAddress = formValuesKey === 'shippingAddress';
  const hasAddressDropdownFeature = hasCountryGotAddressLookupFeature(countriesMap?.[selectedCountryIsoCode]);
  const [shouldShowManualMode, setShouldShowManualMode] = useState(hasExistingAddress || !hasAddressDropdownFeature);

  const clearAddress = () => {
    // we must set these individually to avoid any clashes with a change of country, which would get overwritten in a state "race condition"
    setFieldValue(`${formValuesKey}.addressLine1`, '', false);
    setFieldValue(`${formValuesKey}.addressLine2`, '', false);
    setFieldValue(`${formValuesKey}.addressLine3`, '', false);
    setFieldValue(`${formValuesKey}.postCode`, '', false);
    setFieldValue(`${formValuesKey}.city`, '', false);
    setFieldValue(`${formValuesKey}.state`, { isoCode: '', name: '' }, false);

    setTouched(
      {
        [`${formValuesKey}.addressLine1`]: false,
        [`${formValuesKey}.addressLine2`]: false,
        [`${formValuesKey}.addressLine3`]: false,
        [`${formValuesKey}.postCode`]: false,
        [`${formValuesKey}.city`]: false,
        [`${formValuesKey}.state`]: false,
      },
      false,
    );
  };

  const clearCountry = () => {
    setFieldValue(`${formValuesKey}.country`, { isoCode2Letter: '', name: '' }, false);
    setTouched({ [`${formValuesKey}.country`]: false }, false);
  };

  useEffect(() => {
    setShouldShowManualMode(hasExistingAddress || !hasAddressDropdownFeature);
  }, [hasAddressDropdownFeature]);


  // Clear out shipping address if the isSameAddressCheckbox is set to false
  useEffect(() => {
    if (!values?.isSameAddress && touched?.isSameAddress && isShippingAddress) {
      clearAddress?.();
      clearCountry?.();
    }
  }, [values?.isSameAddress]);

  const onSelectAddressResult = () => {
    // validate here to override the previous errors styles 
    validateField(formValuesKey)

    // show manual forms so user can enter address line 2, 3 etc.
    setShouldShowManualMode(true);
  };

  const onEnterManually = () => {
    setShouldShowManualMode(true);
    clearAddress();
  };

  const onEnterLookup = () => {
    setShouldShowManualMode(false);
    clearAddress();
  };

  const findAnAddressText = getDictionaryItem("form-contact-find-an-address", "Find an address");

  return (
    <>
      {shouldShowManualMode ? (
        <>
          <FormSummary>
            <AddressManualField config={{ col: { xs: 12, sm: 4 } }}>
              <CountryDropdown
                formValuesKey={formValuesKey}
                handleChange={(_, value, reason) => {
                  switch (reason) {
                    case 'clear':
                      clearAddress();
                      clearCountry();
                      break;
                    case 'select-option':
                      setFieldValue(
                        `${formValuesKey}.country`,
                        {
                          isoCode2Letter: value?.isoCode2Letter ?? '',
                          name: value?.name ?? ''
                        },
                        false
                      );
                      clearAddress();
                      break;
                    default:
                      break;
                  }
                }}
              />
            </AddressManualField>
            {
              hasAddressDropdownFeature && (
                <AddressDropdownContainer config={{ col: { xs: 12, sm: 8 } }}>
                  <ManualModeToggle onClick={onEnterLookup}>
                    {findAnAddressText}
                  </ManualModeToggle>
                </AddressDropdownContainer>
              )
            }
          </FormSummary>
          <AddressManual formValuesKey={formValuesKey} />
        </>
      ) : (
        <>
          <FormSummary>
            <AddressManualField config={{ col: { xs: 12, sm: 4 } }}>
              <CountryDropdown
                formValuesKey={formValuesKey}
                handleChange={clearAddress}
              />
            </AddressManualField>
            <AddressDropdownContainer config={{ col: { xs: 12, sm: 8 } }}>
              <AddressDropdown
                formValuesKey={formValuesKey}
                onEnterManually={onEnterManually}
                onSelectAddressResult={onSelectAddressResult}
              />
            </AddressDropdownContainer>
          </FormSummary>
        </>
      )}

      {!!selectedCountryIsoCode &&
        <DeliveryContactField formValuesKey={formValuesKey} />
      }
    </>
  );
};

export { AddressEditor };