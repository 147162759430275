import { stringify } from 'query-string';

const baseUrl = '/search';
const resultsPerPage = 20;

export enum TagKey {
  capacity = 'capacity',
  usedFor = 'used-for',
  location = 'location',
  suitableFor = 'suitable-for',
  topic = 'topic'
}

type Query = {
  term?: string;
  page?: string | number;
  [TagKey.capacity]?: string;
  [TagKey.usedFor]?: string;
  [TagKey.location]?: string;
  [TagKey.suitableFor]?: string;
  [TagKey.topic]?: string;
};

const querySortPrecedence = {
  term: 1000,
  page: 500,
  type: 200,
  filters: 100,
  [TagKey.capacity]: 70,
  [TagKey.usedFor]: 60,
  [TagKey.location]: 50,
  [TagKey.suitableFor]: 40,
  [TagKey.topic]: 30
};

const querySort = (a, b) => querySortPrecedence[b] - querySortPrecedence[a];

export const queryFilterDelimiter = '&';

export const buildSearchQuery = (query: Query): string => {
  if (typeof window === 'undefined') {
    return '';
  }

  let queryStr = window.location.search + '#';

  queryStr += stringify(query, { sort: querySort }).replace(
    new RegExp(encodeURIComponent(queryFilterDelimiter), 'g'),
    queryFilterDelimiter
  );

  queryStr = queryStr.replace(/page=([0-9]*)/i, (match, contents) => `first=${((parseInt(contents) - 1) * resultsPerPage) + 1}`);
  queryStr += '&sort=relevancy';
  queryStr += `&numberOfResults=${resultsPerPage}`;

  return queryStr.replace('term=', 'q=');
};

export const buildSearchUrl = (query: Query): string => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (query && Object.prototype.hasOwnProperty.call(query, 'term') && !query.term) {
    query.term = undefined;
  }

  const stringifiedQuery = buildSearchQuery(query);

  return `${baseUrl}${stringifiedQuery !== '' ? `${stringifiedQuery}` : ''}`;
};
