import styled from 'styled-components';
import {
  TRANSITIONING_CLASS,
} from '_utils/helpers/render';
import { mq } from '_utils/styles';

export const Container = styled.div`
  background-color: #FFF;
  left: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 150ms linear;
  width: 100%;
  z-index: 2;

  .${TRANSITIONING_CLASS} & {
    height: 100%;
    opacity: 1;
  }

  > div {
    left: calc(50% - 24px);
    position: absolute;
    text-align: center;
    top: 150px;

    ${mq('md')} {
      top: 300px;
    }
  }
`;