import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import {
  WithContext,
} from 'schema-dts';
import { SitelinkBoxHomePageStructuredDataProps } from '../definitions';

const SitelinkBoxHomePageStructuredData: React.FC<SitelinkBoxHomePageStructuredDataProps> = ({ context }) => {

  // This is only for the internal website with relative routes
  const location = useLocation();
  const currentPath = location.pathname;
  const homepPagePath = '/';

  if (!context?.site?.name) {
    return null;
  }

  if (currentPath !== homepPagePath) {
    return null;
  }

  const SitelinkBoxData: WithContext<any> = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "CPA Australia",
    "url": "https://www.cpaaustralia.com.au/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.cpaaustralia.com.au/search#q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  }

  // from Google, all is added in the html head part
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(SitelinkBoxData)}
        </script>
      </Helmet>
    </>
  );
};

export default SitelinkBoxHomePageStructuredData;