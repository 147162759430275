import styled from 'styled-components';
import { fixedBody12, fixedBody14, fixedTitle14, fixedTitle16, textBody1 } from '_utils/styles/';
export const FormElementWrapper = styled.div`
  ${fixedBody14};
  flex-basis: 100%;
  line-height: 24px;
  margin-top: 12px;
`;

export const FormElementLabel = styled.label`
  ${textBody1};
  color: ${({ theme }) => theme.form.label.color};
  display: inline-block;
  font-weight: bold;
  line-height: 24px;

  &:disabled {
    color: ${({ theme }) => theme.form.input.colorDisabled};
  }

  .checkbox-radio & {
    ${fixedTitle14};
    display: block;
  }
`;

export const FormElementTitle = styled.span`
  ${fixedTitle14};
  line-height: 24px;
`;

export const FormElementHint = styled.div`
  ${fixedBody12}
  margin-top: 4px;
`;

export const FormElementCtaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 24px;
`;

export const FormElementCta = styled.button`
  background-color: ${({ theme }) => theme.form.button.background};
  border: none;
  border-radius: 24px;
  color: ${({ theme }) => theme.form.button.color};
  ${fixedTitle16};
  margin-left: 32px;
  outline: none;
  padding: 12px 24px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.form.button.backgroundHover};
  }

  &:focus {
    border: solid 2px ${({ theme }) => theme.form.button.outline};
    padding: 10px 22px;
  }

  &:active {
    background-color: ${({ theme }) => theme.form.button.backgroundActive};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.form.button.backgroundDisabled};
    color: ${({ theme }) => theme.form.button.colorDisabled};
  }
`;
