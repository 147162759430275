import React from 'react';
import { useState } from 'react';
import { isValidNavLink } from '_utils/validationChecks';
import { LogOutButton, StyledSpinnerLogout } from "_components/Logout/StyledLogout";

type Props ={
  item: {
      link: {
          url: string;
      },
      title: {
          value: string;
      }
  }
}


export const Logout = ({item}:Props) => {
    const [buttonText, setButtonText] = useState("Sign Out");
    const [showSpinner, setSpinner] = useState(false);
    let spinner: JSX.Element;

    async function logoutOkta(item) {
        setButtonText("Signing Out");
        setSpinner( true);

        const link = isValidNavLink(item) ? item.link?.url : null;
        const OktaResponse = await fetch(link);

        if(OktaResponse.status === 204)
        {
            window.location.replace(item.ssoSignoutLink?.url);
        }
        else{
            //continue the process no matter if it fails in Okta, 
            //placeholder for logging purpose in the future
            window.location.replace(item.ssoSignoutLink?.url);
        }
    };

    if (showSpinner) {
        spinner =  <StyledSpinnerLogout />
    }

    return (
    <li>
      <LogOutButton  onClick={() =>logoutOkta(item)}>
        {buttonText}
        {spinner}
      </LogOutButton>
    </li>
  );
};



export default Logout;