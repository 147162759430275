import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import TagList from '_components/TagList';
import Grid from '_utils/components/Grid';

import { spacing } from '_utils/props';
import {
  color,
  fixedBody12,
  headingH4,
  headingH5,
  imageFitCover,
  mq,
  svg,
  textBody2
} from '_utils/styles';

import { hideOnPrint } from '_utils/styles/print';

const container = css`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq('lg')} {
    height: 100%;
  }
`;

export const TagContainer = styled.div`
  ${hideOnPrint}
`;

export const StyledTagList = styled(TagList)`
  margin: 0;

  > div {
    padding: 0;
  }
`;

export const PlaceholderCard = styled(Grid.Item)`
  background-color: ${({ theme }) => theme?.contentCard?.cardBackground};
  display: flex;
`;

export const Card = styled(NavLink)`
  ${container}
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  position: relative;

  &:hover .title {
      text-decoration: underline;
  }

  &:not(.noTopBorder).horizontal {
    border-top: 1px solid ${({ theme }) => theme.colors.ui.divider};
  }

  &.horizontal {
    flex-direction: row;
    ${({ theme }) => spacing({ theme, pt: 3 })}

    @media print {
      flex: none;
      padding-bottom: 20px;
      padding-top: 0;
    }
  }

  &.horizontal.noTopBorder {
    padding-top: 0;
  }

  @media print {
    border-top: none !important;
    display: inline-block;
    width: 100%;
  }
`;

export const ImageColumn = styled.div`
  ${({ theme }) => spacing({ theme, mb: 3 })}
  align-items: flex-start;
  display: flex;
  justify-content: center;

  &:not(.noTopBorder).horizontal {
    margin-top: 0;
  }

  .horizontal & {
    flex-basis: 33.3%;
    flex-shrink: 0;
    min-width: 96px;
    ${({ theme }) => spacing({ theme, m: 0 })}

    @media print {
      display: block;
      flex: none;
      margin-bottom: 20px;
      width: 100%;
    }
  }

`;

export const ImageContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
`;

export const PlaceholderImage = styled.img`
 ${imageFitCover};
`;

export const Image = styled(SCImage)`
  ${imageFitCover}

  ${Card}.focus & {
    font-family: 'object-fit: contain;';
    height: 75%;
    left: 50%;
    object-fit: contain;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
  }
`;

export const DetailsColumn = styled.div`
  ${textBody2}
  color: ${color('text.subtle')};

  .horizontal & {
    ${({ theme }) => spacing({ theme, ml: 3 })}

    @media print {
      margin-left: 0;
    }
  }
`;

const IconContainerStyles = css`
  color: inherit;
  display: block;
  text-decoration: none;

  &.title-is-link:hover {
    color: ${({ theme }) => theme?.contentCard?.action};
  }
`;

export const IconContainer = styled.span`
  ${IconContainerStyles}
`;

export const LinkIconContainer = styled(NavLink)`
  ${IconContainerStyles}
`;

const AttributeItemStyles = css`
  align-items: center;
  display: flex;

  svg {
    ${svg(16, 16)}
    flex-shrink: 0;
    margin-right: 8px;
    position: relative;
    top: 0;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.text.nonessential};
    }
  }`;

export const AttributesItem = styled.div`
  ${AttributeItemStyles}
`;

export const AttributesWrapper = styled.div`
  ${fixedBody12}
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // specific values due to 4px already on Tag
  ${({ theme }) => spacing({ theme, mb: { xs: '4px', md: '12px' } })}

  .horizontal & {
    ${({ theme }) => spacing({ theme, mb: { md: 1 } })}
  }
`;

export const Title = styled.div`
  h3,
  h4,
  h5,
  h6,
  h3&,
  h4&,
  h5&,
  h6& {
    ${headingH4}
    margin-top: 0;
    margin-bottom: 8px;
  }

  .horizontal & {
    ${mq.lessThan('md')} {
      ${headingH5}
    }
  }

  @media print {
    font-size: 16px !important;
    line-height: 20px !important;
  }
`;

export const AttributeFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
`;

export const DateWrapper = styled.div`
  ${fixedBody12}
`;
