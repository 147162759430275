import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';

import { withDataSourceValidation, withEditMode } from '_containers/BaseComponent';

import { getImageAttrsByRatio } from '_utils/helpers/image';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize, ImageRatio } from '_utils/types/enums';

import {
  Container,
  ContentWrapper,
  CoverImage,
  CoverImageWrapper,
  Description,
  LinkButton,
  PageContainer,
  Title,
} from "./StyledMagazinePageBanner";

import { MagazinePageBannerRenderingProps } from './definitions';

import { magazinePageBannerValidators } from './validation';

const MagazinePageBanner: React.FC<MagazinePageBannerRenderingProps> = ({
  editMode,
  rendering,
}) => {
  const uid = rendering?.uid;
  const magazine = rendering?.fields?.data?.datasource?.latestMagazine?.targetItem;

  if (!magazine && !editMode) {
    return null;
  }

  const coverImage = magazine?.coverImage?.jss;
  const title = magazine?.title?.value;
  const link = magazine?.url;
  const description = rendering?.fields?.data?.pageFields?.description?.value;
  const ctaLabel = rendering?.fields?.data?.datasource?.ctaLabel?.value;

  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_2By3);

  return (
    <Container
      className="magazine-page-banner"
      data-component
      id={uid} >
      <PageContainer>
        <CoverImageWrapper>
          <CoverImage
            className="lazyload"
            field={
              editMode
                ? coverImage
                : {
                  value: {
                    src: imagePlaceholderSrc,
                    alt: coverImage?.value?.alt ?? '',
                    'data-srcset': createSrcSet(coverImage?.value?.src, [
                      ImageSize.MW_256,
                      ImageSize.MW_384,
                    ])
                  }
                }
            }
            {...defaultImageSize}
          />
        </CoverImageWrapper>
        <ContentWrapper>
          <Title>
            <Text
              field={{ value: title }}
              editable={false}
            />
          </Title>
          <Description>
            <Text
              field={{ value: description }}
              editable={false}
            />
          </Description>
          <LinkButton
            href={link?.jss?.value?.href}
            target="_blank">
            {ctaLabel}
          </LinkButton>
        </ContentWrapper>
      </PageContainer>
    </Container>
  );
}

export default compose(
  withEditMode,
  withDataSourceValidation(magazinePageBannerValidators, false),
)(MagazinePageBanner);
