import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, {useEffect}from 'react';
import { canUseDOM } from 'exenv';
import Menu from '_components/MainMenu/Menu';

const DashboardNavigation: React.FC<any> = ({
  activeSubNav,
  isSticky,
  rendering,
  setActiveSubNav,
  sitecoreContext,
}) => {
  let navItems = rendering?.fields?.data?.datasource?.primaryNavigation;
  
  if (!navItems) {
    return null;
  }

  return navItems?.length && (
    <Menu
      activeSubNav={activeSubNav}
      fields={rendering?.fields}
      isSticky={isSticky}
      menuID={1}
      setActiveSubNav={setActiveSubNav}
    />
  );
};

export default compose(withSitecoreContext())(DashboardNavigation);

