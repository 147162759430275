import colors from '_containers/Theme/corporate/colors';

export default {
  dialog: {
    background: colors.modal.container.background,
  },
  crossWrapper: {
    pathFillColor: colors.modal.close.fill,
    closeButtonHover: {
      pathFillColor: colors.modal.close.fillHover,
    }
  }
};