import { IPropList } from "..";
import generateHelper from '../generateHelper';

const SUPPORTED_PROPS: IPropList = {
  transition: ['transition']
};

const getValue = ({ transition, timing }, _, { motion }) => {
  const transitionProperty = transition ? `${transition} ` : '';
  const transitionTiming = timing ? `${motion.timing[timing]} ` : `${motion.timing.default} `;

  return `${transitionProperty}${transitionTiming}${motion.ease}`;
};

export default (props) => generateHelper(SUPPORTED_PROPS, props, { getValue });
