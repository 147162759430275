import React, { RefObject, useState, useEffect } from 'react';
import { canUseDOM } from 'exenv';

export function useVisibility(
  element: RefObject<HTMLInputElement>,
  rootMargin: string
) {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if(!canUseDOM) {
      return;
    }

    let observerRefValue: HTMLElement | null = null;
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        { rootMargin }
      );
      if (element && element.current) {
        observer.observe(element.current);
        observerRefValue = element.current;
      }

      return () => {
        if (observerRefValue) observer.unobserve(observerRefValue);
      };
    } else {
      return setIsVisible(true);
    }
  }, [element, rootMargin]);

  return isVisible;
}
