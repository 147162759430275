import colors from './colorOptions';

import button from './button';
import link from './link';

export default {
  all: colors,
  background: {
    tint1: colors.neutralWhite,
    tint2: colors.neutral50,
    tint3: colors.neutral100,
    tint4: colors.neutral200,
    tint5: colors.neutral300,
  },
  button: button.colors,
  darkTheme: {
    background: {
      tint1: colors.neutralBlack,
      tint2: colors.neutral900,
      tint3: colors.neutral800,
      tint4: colors.neutral700,
      tint5: colors.neutral600,
      tint6: colors.neutral500,
    },
    text: {
      contrast: colors.neutral900,
      default: colors.neutral50,
      disabled: colors.neutral600,
      interactive1: colors.interactive500,
      interactive1Hover: colors.interactive600,
      nonessential: colors.neutral400,
      pressed: colors.neutral600,
      secondary: colors.neutral200,
      subtle: colors.neutral300,
      visited: colors.visited400,
    },
    ui: {
      divider: colors.neutral600,
    },
  },
  // Feature List is not showing on figma
  featureList: {
    cross: colors.error500,
    tick: colors.brandSecondary3,
  },
  form: {
    border: colors.neutral200,
    color: colors.neutral800,
    colorDisabled: colors.neutral300,
    disabled: colors.neutral50,
    error: colors.error500,
    focus: colors.interactive500,
    hover: colors.neutral800,
  },
  // Element is not showing on figma
  element: {
    danger: colors.error500,
    focused: colors.interactive500,
    neutralBlack: colors.neutralBlack,
    neutralWhite: colors.neutralWhite,
    primary: colors.brandPrimary1,
    primary2: colors.brandPrimary2,
    primary3: colors.brandPrimary3,
    secondary: colors.brandSecondary1,
  },
  icon: {
    demo: colors.interactive500,
  },
  interactiveCard: {
    border: colors.neutral200,
    focus: colors.interactive500,
    hover: colors.neutral800,
    selected: colors.interactive700,
  },
  interactiveCell: {
    focus: colors.interactive500,
    hover: colors.neutral50,
    pressed: colors.neutral200,
    selected: colors.neutral100,
  },
  link,
  messaging: {
    backgroundError: colors.error100,
    backgroundInfo: colors.information100,
    backgroundSuccess: colors.success100,
    backgroundWarning: colors.warning100,
    colorError: colors.error500,
    colorErrorDark: colors.error700,
    colorInfo: colors.information500,
    colorSuccess: colors.success500,
    colorSuccessDark: colors.success700,
    colorWarning: colors.warning500,
    colorWarningDark: colors.warning700,
  },
  // Modal is not showing on figma
  modal: {
    background: colors.brandPrimary1,
    close: {
      fill: colors.neutralWhite,
      fillHover: colors.neutralWhite,
    },
    container: {
      background: colors.brandPrimary3,
    },
    scrollThumb: {
      background: colors.brandPrimary1Dark,
    },
  },
  // Status is not showing on figma
  status: {
    error: colors.error500,
    info: colors.information500,
    warning: colors.warning500,
  },
  // TODO: Update table color
  table: {
    cell: {
      border: colors.neutralWhite,
    },
    head: {
      background: colors.brandPrimary1,
      color: colors.neutralWhite,
    },
    row: {
      background: colors.neutralWhite,
      backgroundAlt: colors.neutral50,
    },
  },
  text: {
    contrast: colors.neutral900,
    darkSubtle: colors.darkTextSubtle,
    darkTextSecondary: colors.darkTextSecondary,
    default: colors.neutral700,
    disabled: colors.neutral300,
    interactive1: colors.interactive600,
    interactive1Hover: colors.interactive700,
    nonessential: colors.neutral400,
    pressed: colors.neutral600,
    secondary: colors.neutral600,
    subtle: colors.neutral500,
    visited: colors.visited500,
  },
  ui: {
    accent1: colors.brandPrimary2,
    divider: colors.neutral200,
  },
};
