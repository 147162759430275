import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";
import moment from 'moment-mini';

yup.addMethod(yup.date, 'format', function (formats, parseStrict) {
  return this.transform(function (value, originalValue) {
    value = moment(originalValue, formats, parseStrict);
    return value.isValid() ? value.toDate() : new Date('');
  });
});

// Extend yup date schema so typescript recognises new 'format' method
declare module 'yup' {
  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined, 
    TContext extends AnyObject = AnyObject, 
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    format(formats: string, parseStrict: boolean): DateSchema<TType, TContext>;
  }
}

export default yup;