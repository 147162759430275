import colors from '_containers/Theme/intheblack/colors';

export default {
  label: {
    color: colors.text.default
  },
  error: {
    background: colors.messaging.backgroundError,
    border: colors.form.error,
    color: colors.form.error
  },
  success: {
    background: colors.messaging.backgroundSuccess,
    border: colors.messaging.colorSuccess
  },
  input: {
    background: colors.element.neutralWhite,
    backgroundDisabled: colors.form.disabled,
    border: colors.form.border,
    borderDisabled: colors.form.border,
    borderError: colors.form.error,
    borderFocus: colors.form.focus,
    borderFocusError: colors.form.error,
    borderHover: colors.form.hover,
    color: colors.text.default,
    colorDisabled: colors.text.disabled,
  },
  select: {
    active: colors.form.focus,
    iconColor: colors.all.brandPrimary2Dark,
    selected: colors.background.tint5
  },
  check: {
    background: colors.background.tint1,
    backgroundDisabled: colors.form.disabled,
    border: colors.text.default,
    borderDisabled: colors.text.disabled,
    borderError: colors.form.error,
    borderFocus: colors.form.focus,
    borderHover: colors.text.default,
    color: colors.element.neutralWhite,
    colorDisabled: colors.text.disabled,
    colorHover: colors.element.neutralWhite,
    selectedBackground: colors.text.interactive1,
    selectedBackgroundFocus: colors.all.interactive600,
    selectedBackgroundHover: colors.text.interactive1Hover,
    selectedBorder: colors.text.interactive1,
    selectedBorderFocus: colors.button.inTheBlack.borderFocusAll,
    selectedBorderHover: colors.text.interactive1Hover
  },
  button: {
    background: colors.button.primary.background,
    backgroundActive: colors.button.primary.backgroundActive,
    backgroundDisabled: colors.button.primary.backgroundDisabled,
    backgroundHover: colors.button.primary.backgroundHover,
    borderRadius: 4,
    color: colors.button.primary.color,
    colorDisabled: colors.button.primary.disabled,
    outline: colors.button.primary.borderFocus
  },
  spinner: {
    color: colors.text.subtle
  }
};
