import styled from 'styled-components';

import {
  buttonReset,
  hideOnPrint,
  mq,
  safeFocusRemoval,
} from '_utils/styles';

export const Button = styled.button`
  ${buttonReset}
  ${safeFocusRemoval}
  ${hideOnPrint}
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  min-width: 64px;

  svg {
    width: 23px;
  }

  path {
    fill: ${({ theme }) => theme.searchMenu.searchMenuButton.pathFillColor};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.searchMenu.searchMenuButton.focus};
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
    position: relative;
  }

  ${mq('md')} {
    display: none;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.searchMenu.searchMenuButton.label.color};
  display: none;

  ${mq('md')} {
    display: block;
  }
`;