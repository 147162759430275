import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

import Icon from '_components/Icon';
import { DashboardButton, DashboardButtonContainer, FormSection, LargeHeading, RichTextContainer } from '_utils/components/Form/StyledFormSection';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { buttonAction } from '_utils/helpers/form';

import { CareerProfileFormProps } from '../definitions';

const ApiErrorContent: React.FC<CareerProfileFormProps> = ({
  rendering,
}) => {
  const datasource = rendering?.fields?.data?.datasource;
  const heading = getDictionaryItem('form-api-error-heading', 'We’re experiencing some technical difficulties');
  const hasContent = rendering?.placeholders?.['jss-form-api-error']?.length > 0;
  const dashboardLinkLabel = getDictionaryItem('form-dashboard-link-label', 'Go to My CPA dashboard');
  const dashboardLink = datasource?.dashboardLink?.jss?.value;

  return (
    <>
      {hasContent &&
        <FormSection>
          <LargeHeading>{heading}</LargeHeading>
          <RichTextContainer>
            <Placeholder name="jss-form-api-error" rendering={rendering} />
          </RichTextContainer>
        </FormSection>
      }
      {dashboardLink && dashboardLinkLabel &&
        <DashboardButtonContainer>
          <DashboardButton aria-label={dashboardLinkLabel} onClick={(e) => buttonAction(e, dashboardLink?.href)}>
            {dashboardLinkLabel}
            <Icon ariaHidden={true} name="arrow_forward" />
          </DashboardButton>
        </DashboardButtonContainer>
      }
    </>
  );
};

export default ApiErrorContent;
