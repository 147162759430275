import React, { useState, Fragment } from 'react';
import { useFormikContext } from 'formik';
import { CareerProfileFormValues } from '_containers/CareerProfileFormContext/definitions';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Dropdown from '_utils/components/Form/Dropdown';
import { 
  LicencesActionButton, 
  LicencesActionButtonWrapper, 
  ConfirmingButton,
  DialogHeadingWrapper, 
  LicencesCancelButton,
  LicencesListContentWrapper,
  LicencesListRowWrapper,
  DialogKeyTextStyle,
  TextInput
} from '../StyledLicencesListForm';
import Icon from '_components/Icon';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Heading } from '_utils/components/Form/EditableSection/StyledEditableSection';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: "center",
    marginTop: "12px",
    marginBottom: "16px"
  },
}));

const LicencesListRow = ({
  getLicensesOptions,
  addNew,
  item,
  index
}) => {

  // Override the material ui css
  const classes = useStyles();

  const [licenceName, setLicenceName] = useState(item?.licenceName)
  const [number, setNumber] = useState(item?.registrationNumber)

  const nameOptions = getLicensesOptions ? getLicensesOptions.map((item) => item.name) : []
  const { values, setFieldValue, validateField } = useFormikContext<CareerProfileFormValues>();
  const getLicences = values?.licences?.items;
  const updated = [...getLicences]

  // For controlling 3 dialog
  const [addOpen, setAddOpen] = useState(addNew ?? false)
  const [editOpen, setEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  // This is for all the Dialog open and close
  const handleDialogClose = () => {
    // Do nothing here, so the dialog can only be closed by click related buttons
  };

  const handleRegistrationNumber = (event) => {
    const inputValue = event.target.value;
    // Only allow alphanumeric characters
    const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    setNumber(filteredValue);
  };

  // There are same code for different dialog, could write them as one, but now keep them for personal habit 
  const handleAdd = async () => {
    item.addNew = false
    setFieldValue(`licences.items.${index}.registrationNumber`, number)
    setAddOpen(false);
  }

  // For update
  const handleEdit = () => {
    setFieldValue(`licences.items.${index}.registrationNumber`, number)
    setEditOpen(false);
  }

  // For delete
  const handleDelete = () => {
    updated.splice(index, 1)
    setFieldValue('licences.items', updated)
    setDeleteOpen(false);
  }

  const handleAddCancel = async () => {
    setFieldValue(`licences.items.${index}.registrationNumber`, number)
    item.addNew = false
    setAddOpen(false);
  }

  const handleEditCancel = async () => {
    setFieldValue(`licences.items.${index}.registrationNumber`, number)
    item.addNew = false
    setEditOpen(false);
  }

  const handleDeleteCancel= () => {
    setDeleteOpen(false);
  }

  // Those value use hooks
  const licenceTypeLabel = getDictionaryItem('form-career-license-type', 'Licence Type');
  const registrationNumberLabel = getDictionaryItem('form-career-registration-number', 'Registration Number');
  const notProvidedLabel = getDictionaryItem('form-career-registration-number-not-provided', 'Not Provided');
  const addNewLabel = getDictionaryItem('form-career-registration-number-add-new', 'Add New');
  const addLabel = getDictionaryItem('form-career-license-add', 'Add');
  const cancelLabel = getDictionaryItem('form-career-license-cancel', 'Cancel');
  const updateLabel = getDictionaryItem('form-career-license-update', 'Update');
  const editLabel = getDictionaryItem('form-career-license-edit', 'Edit');
  const deleteLabel = getDictionaryItem('form-career-license-delete', 'Delete');
  const confirmDeleteLabel = getDictionaryItem('form-career-license-confirmation-delete', 'Are you sure you want to delete this item?');

  const showTableRow = () => {
    return (
      <Fragment>
        <LicencesListRowWrapper>
          <Grid container>
            <Grid item xs={8}>
              <LicencesListContentWrapper><span>{licenceTypeLabel}</span><span>{item?.licenceName}</span></LicencesListContentWrapper>
              <LicencesListContentWrapper isRegistrationNumber={true}> <span>{registrationNumberLabel}</span><span>{item?.registrationNumber || notProvidedLabel}</span></LicencesListContentWrapper>
            </Grid>
            <Grid item xs={4}>
              <LicencesActionButtonWrapper>
                <LicencesActionButton onClick={() => {
                  setEditOpen(true)

                }}><Icon ariaHidden={true} name="edit" /></LicencesActionButton>

                <LicencesActionButton onClick={() => {
                  setDeleteOpen(true)
                }}>
                  <Icon ariaHidden={true} name="delete" /></LicencesActionButton>
              </LicencesActionButtonWrapper>
            </Grid>
          </Grid>
        </LicencesListRowWrapper>
      </Fragment>

    )
  };

  return (
    <Fragment>
      {showTableRow()}
      <Dialog
        open={addOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogHeadingWrapper id="alert-dialog-title"><Heading>{addNewLabel}</Heading></DialogHeadingWrapper>
        <DialogContent>
          <Grid container style={{ marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={4}>
              <DialogKeyTextStyle>{licenceTypeLabel}</DialogKeyTextStyle>
            </Grid>
            <Grid item xs={8}>
              <Dropdown
                value={item?.licenceName}
                getOptionLabel={(option) => {
                  return option
                }}
                multiple={false}
                name={`licences.items.${index}.licenceName`}
                id={`licences.items.${index}.licenceName`}
                options={nameOptions ?? []}
                placeholder={'Select'}
                shouldShowCheckboxes={false}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={4}>
              <DialogKeyTextStyle>{registrationNumberLabel}</DialogKeyTextStyle>
            </Grid>
            <Grid item xs={8}>
              <TextInput 
                value={number}
                onChange={handleRegistrationNumber}
                maxLength={100}
                placeholder="Alphanumeric" 
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <ConfirmingButton onClick={handleAdd} >
            {addLabel}
          </ConfirmingButton>
          <LicencesCancelButton onClick={handleAddCancel} >
            {cancelLabel}
          </LicencesCancelButton>
        </DialogActions>

      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogHeadingWrapper id="alert-dialog-title"><Heading>{editLabel}</Heading></DialogHeadingWrapper>
        <DialogContent>
          <Grid container justifyContent='center' alignItems='center' style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <DialogKeyTextStyle>{licenceTypeLabel}</DialogKeyTextStyle>
            </Grid>
            <Grid item xs={8}>
              <Dropdown
                value={item?.licenceName}
                getOptionLabel={(option) => {
                  return option
                }}
                multiple={false}
                name={`licences.items.${index}.licenceName`}
                id={`licences.items.${index}.licenceName`}
                options={nameOptions ?? []}
                placeholder={'Select'}
                shouldShowCheckboxes={false}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={4}>
              <DialogKeyTextStyle>{registrationNumberLabel}</DialogKeyTextStyle>
            </Grid>
            <Grid item xs={8}>
              <TextInput 
                value={number}
                onChange={handleRegistrationNumber}
                maxLength={100}
                placeholder="Alphanumeric" 
              />
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <ConfirmingButton onClick={handleEdit} >
            {updateLabel}
          </ConfirmingButton>
          <LicencesCancelButton onClick={handleEditCancel} >
            {cancelLabel}
          </LicencesCancelButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogHeadingWrapper id="alert-dialog-title"><Heading>{confirmDeleteLabel}</Heading></DialogHeadingWrapper>
        <DialogActions className={classes.dialogActions}>
          <ConfirmingButton onClick={handleDelete} >
            {deleteLabel}
          </ConfirmingButton>
          <LicencesCancelButton onClick={handleDeleteCancel} >
            {cancelLabel}
          </LicencesCancelButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default LicencesListRow;
