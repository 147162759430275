import styled from 'styled-components';

import { buttonReset, focusOutline, svg } from '_utils/styles';
import depth from '_utils/styles/depth';
import mq from '_utils/styles/mq';

export const Dialog = styled.div`
  background-color: ${({ theme }) => theme.modal.dialog.background};
  height: 100%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
  z-index: ${depth.MODAL};

  ${({ closeOnBreakpoint }) => mq(closeOnBreakpoint)} {
    display: none;
  }
`;

export const Layout = styled.div`
  ${mq('lg')} {
    margin-top: 64px;
  }
`;

export const CloseContainer = styled.div`
  align-items: center;
  display: flex;
  flex: none;
  height: 64px;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  ${mq('lg')} {
    left: 0;
    position: fixed;
    top: 0;
    width: calc(100% - 15px);
  }
`;

export const CloseButton = styled.button`
  ${() => buttonReset()}

  &:focus {
    ${({ theme }) => focusOutline({ theme })}
    .safe-focus-removal & {
      outline: 0;
    }
  }

  display: inline-block;
  margin-right: 8px;
  padding: 10px;
  position: relative;
`;

export const CrossWrapper = styled.span`
  ${() => svg(28, 28)}
  flex: none;
  width: 28px;

  path {
    fill: ${({ theme }) => theme.modal.crossWrapper.pathFillColor};
  }

  ${CloseButton}:hover & {
    path {
      fill: ${({ theme }) => theme.modal.crossWrapper.closeButtonHover.pathFillColor};
    }
  }
`;

export const ContentContainer = styled.div``;
