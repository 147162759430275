import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { withEditMode, withEditModeNotice } from '_containers/BaseComponent';

import HeadingWithDescription from '_utils/components/HeadingWithDescription';

import { errorInDev } from '_utils/helpers/dev';
import { scrollToElement } from '_utils/helpers/scrollToElement';
import { setAnchorElemScrollMarginTop } from '_utils/helpers/setAnchorElemScrollMarginTop';

import { ContentRichTextProps } from './definitions';
import {
  HeadingContainer,
  default as StyledContentRichText
} from './StyledContentRichText';
import {
  Container,
} from './StyledRichTextImage';
import RichTextImage from './RichTextImage'


/**
 * ContentRichText component.
 * Connected component for the display of the Rich Text Content.
 */

const wrapTablesWithDiv = (text: string): string => text.replace(/(<table(.*?)>.*?<\/table>)+/gis, '<div class="table-scroll-container" tabindex="0">\n\t$1\n</div>');

const handleClick = (history, e) => {
  if (!canUseDOM) {
    return;
  }
  const { target } = e;

  if (target.tagName.toLowerCase() === 'a') {
    // eslint-disable-next-line no-useless-escape
    const match = target.href.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
    const isExternal =
      (typeof match[1] === 'string' &&
        match[1].length > 0 &&
        match[1].toLowerCase() !== window.location.protocol) ||
      (typeof match[2] === 'string' &&
        match[2].length > 0 &&
        match[2].replace(
          new RegExp(`:(${{ 'http:': 80, 'https:': 443 }[window.location.protocol]})?$`),
          ''
        ) !== window.location.host) || target?.getAttribute('target') === '_blank';
    const isDownload = e.target.pathname.startsWith('/-/media/');

    if (target.pathname !== '/' && !isExternal && !isDownload) {
      e.preventDefault();

      if (target.hash) {
        // Apply scroll-margin-top on the elemToScroll item that is being covered by the fixed header and breadcrumb
        const targetElement = document.getElementById(target.hash.replace('#', ''));
        setAnchorElemScrollMarginTop(targetElement);

        // handle anchor on same page
        try {
          const currentPath = window.location.pathname;
          const currentHash = window.location.hash;

          if (
            target.pathname.toLowerCase() === currentPath.toLowerCase() &&
            target.hash.toLowerCase() === currentHash.toLowerCase()
          ) {
            // if link points to anchor on this page, scroll to it
            const id = target.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({
                behavior: 'smooth'
              });
              // exit without updating history
              return;
            }
          }
        } catch (err) {
          errorInDev(err);
        }
      }
      history.push(target.pathname + (target.hash ? target.hash : ''));
    }
  }
};

const MissingDataSource = compose(withEditModeNotice)(`div`);

const ContentRichText: React.FC<ContentRichTextProps> = ({
  field,
  fields,
  history,
  className = '',
  editMode,
  rendering,
  hideExternalLinkIcon = false
}) => {
  const richTextField = typeof field === 'undefined' ? fields?.body : field;
  const uid = rendering?.uid;

  // HeadingWithDescription fields
  const description = fields?.description;
  const heading = fields?.heading;
  const headingLevel = rendering?.params?.headingLevel || 'h3';

  //TODO update component to use the SitecoreDatasourceValidator

  if (!richTextField) {
    if (editMode) {
      return (
        <MissingDataSource
          noticeEnabled={true}
          noticeMessage={`ContentRichText has no data source`}
        />
      );
    }
    return null;
  }

  const parsedRichTextField = {
    ...richTextField, value: wrapTablesWithDiv(richTextField.value)
  };

  // Get a hash value from location and scroll to element
  useEffect(() => {
    scrollToElement();
  }, []);

  const image = fields?.image;
  const hasImage = fields?.image?.value?.src;
  const caption = fields?.caption?.value;
  const alignment = fields?.alignment?.value ?? "left";
  const componentName = rendering?.componentName;

  return (
    <Container
      className={`content-rich-text ${className}`}
      componentName={componentName}
      data-component
      id={uid}
    >
      {(heading || description) && (
        <HeadingContainer>
          <HeadingWithDescription
            headingField={heading}
            headingLevel={headingLevel}
            descriptionField={description}
          />
        </HeadingContainer>
      )}

      {hasImage &&
        <RichTextImage
          editMode={editMode}
          alignment={alignment}
          caption={caption}
          image={image}
        />
      }
      <StyledContentRichText
        field={parsedRichTextField}
        onClick={handleClick.bind(null, history)}
        hideExternalLinkIcon={hideExternalLinkIcon}
      />
    </Container>
  );
};

export default compose(withRouter, withEditMode)(ContentRichText);
