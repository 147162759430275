import colors from '_containers/Theme/intheblack/colors';

export default {
  container: {
    backgroundPrimary: colors.all.darkBackgroundTint1,
    backgroundSecondary: colors.all.darkBackgroundTint2,
    divider: colors.all.transparent,
  },
  item: {
    background: colors.all.transparent,
    backgroundActive: colors.all.darkBackgroundTint2,
    color: colors.all.neutralWhite,
    colorActive: colors.all.neutralWhite,
    colorHighlight: colors.all.brandPrimary2,
    colorLabel: colors.all.darkTextSubtle,
    description: colors.all.darkText,
    descriptionActive: colors.all.darkText,
    focusHighlight: colors.all.interactive200,
    groupLabelColor: colors.all.darkTextSubtle,
    primaryNavigation: {
      backgroundActive: colors.all.darkBackgroundTint1,
    }
  },
  toolbar: {
    activeHighlight: colors.all.brandPrimary2,
    background: colors.all.darkBackgroundTint1,
    buttonColor: colors.all.neutralWhite,
    color: colors.all.darkTextSubtle,
    colorActive: colors.all.neutralWhite,
    divider: colors.all.darkBackground,
  },
  secondaryNavigation: {
    divider: colors.darkTheme.ui.divider
  },
  magazine: {
    color: colors.all.darkText
  }
};
