import canUseDOM from 'exenv';
import { compose } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'

import {
  COOKIE_BANNER_ID,
  LIVE_CHAT_OFFLINE_BUTTON_ID,
  LIVE_CHAT_ONLINE_BUTTON_ID,
  LIVE_CHAT_ONLINE_MESSAGE_ID,
} from '_components/LiveChat/definitions';
import { withEditMode } from '_containers/BaseComponent'
import { Grid } from '_utils/components/';
import { useOneTrustActiveGroups } from '_utils/hooks';

import {
  STICKY_FOOTER_ID,
  StickyFooterProps,
} from './definitions';

import { StickyFooterContainer } from './StyledStickyFooter';

/**
 * @name StickyFooter
 * @description StickyFooter is a reusable footer component that pulls together 
 * polyfill for `position: sticky`, and positioning logic to avoid LiveChat and 
 * OneTrust's Cookie Banner (updating for when routes change).
 */
const StickyFooter: React.FC<StickyFooterProps> = ({
  children,
  editMode,
}) => {
  const [isSticky, setSticky] = useState(false);
  const stickyFooterRef = useRef<HTMLElement>(null);

  const {
    isCookieBannerClosed,
    isOneTrustScriptReady,
  } = useOneTrustActiveGroups();

  const updateLiveChatPosition = () => {
    if (!canUseDOM) {
      return;
    }

    const stickyFooterHeight = stickyFooterRef?.current?.getBoundingClientRect()?.height ?? 0;
    const liveChatMessage = document.getElementById(LIVE_CHAT_ONLINE_MESSAGE_ID);
    const liveChatButton = document.getElementById(LIVE_CHAT_ONLINE_BUTTON_ID) || document.getElementById(LIVE_CHAT_OFFLINE_BUTTON_ID);
    const cookieBanner = document.getElementById(COOKIE_BANNER_ID);

    if (isCookieBannerClosed) {
      if (liveChatMessage) {
        liveChatMessage.style.bottom = `${stickyFooterHeight + 100}px`;
      }

      if (liveChatButton) {
        liveChatButton.style.bottom = `${stickyFooterHeight + 24}px`;
      }
    } else {
      if (cookieBanner) {
        if (liveChatMessage) {
          liveChatMessage.style.bottom = `${stickyFooterHeight + cookieBanner?.offsetHeight + 100}px`;
        }

        if (liveChatButton) {
          liveChatButton.style.bottom = `${stickyFooterHeight + cookieBanner?.offsetHeight + 24}px`;
        }
      }
    }
  };

  const updateStickyFooterPosition = () => {
    if (!canUseDOM) {
      return;
    }

    const stickyFooter = stickyFooterRef?.current;
    const cookieBanner = document.getElementById(COOKIE_BANNER_ID);
    const pageFooter = document.getElementsByTagName('footer')?.[0];
    const pageFooterPosition = pageFooter?.getBoundingClientRect();
    const isPageFooterVisible = (pageFooterPosition?.top ?? 0) < window.innerHeight;

    if (stickyFooter) {
      if (isPageFooterVisible || isCookieBannerClosed) {
        stickyFooter.style.bottom = `0px`;
      } else {
        if (cookieBanner) {
          stickyFooter.style.bottom = `${cookieBanner?.offsetHeight}px`;
        } else {
          stickyFooter.style.bottom = `0px`;
        }
      }
    }
  };

  const updateStickyState = () => {
    if (!canUseDOM) {
      return;
    }

    const pageFooter = document.getElementsByTagName('footer')?.[0];
    const pageFooterTop = pageFooter?.getBoundingClientRect()?.top ?? 0;
    const windowHeight = window?.innerHeight;

    if (pageFooterTop > 0) {
      setSticky(windowHeight <= pageFooterTop);
    }
  };

  let timeout;

  const updatePositions = () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    timeout = window.requestAnimationFrame(() => {
      updateLiveChatPosition();
      updateStickyState();
      updateStickyFooterPosition();
    });
  };

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    window.addEventListener('resize', updatePositions, false);
    window.addEventListener('scroll', updatePositions, false);

    updatePositions();

    return () => {
      window.removeEventListener('resize', updatePositions, false);
      window.removeEventListener('scroll', updatePositions, false);
    };
  }, []);

  // reposition live chat if the cookie banner has been closed
  useEffect(() => {
    updatePositions();
  }, [
    isCookieBannerClosed,
    isOneTrustScriptReady,
  ]);

  return (
    <StickyFooterContainer
      id={STICKY_FOOTER_ID}
      className="sticky-footer"
      data-component
      editMode={editMode}
      isSticky={isSticky}
      ref={stickyFooterRef}
    >
      <Grid.Container>
        <Grid.Row config={{ gutters: [] }}>
          {children}
        </Grid.Row>
      </Grid.Container>
    </StickyFooterContainer>
  );
};

export default compose(
  withEditMode,
)(StickyFooter);
