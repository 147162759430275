// options
export default {
  // primary
  brandPrimary1: '#000000',
  brandPrimary2: '#00BAB5',
  brandPrimary2Dark: '#00827E',
  // secondary
  brandSecondary1: '#D92F1F',
  brandSecondary1Dark: '#9A281D',
  brandSecondary1Light: '#FBEAE9',
  brandSecondary2: '#712B90',
  brandSecondary2Dark: '#57216E',
  brandSecondary2Light: '#F1EAF4',
  brandSecondary3: '#F59419',
  brandSecondary3Dark: '#B26200',
  brandSecondary3Light: '#FDEAD1',
  brandSecondary4: '#E7EFF9',
  brandSecondary4Light: '#0A5CC7',
  brandSecondary4Dark: '#084697',
  brandSecondary5: '#FFF8CC',
  brandSecondary5Light: '#FFDD00',
  brandSecondary5Dark: '#E6C700',

  // TODO move this when dark theme is ready
  darkBackgroundTint1: '#000000',
  darkBackgroundTint2: '#111111',
  darkBackgroundTint3: '#222222',
  darkBackgroundTint4: '#303030',
  darkBackgroundTint5: '#444444',
  darkBackgroundTint6: '#767676',
  darkText: '#F7F7F7',
  darkTextSecondary: '#DDDDDD',
  darkTextSubtle: '#C7C7C7',
  darkTextContrast: '#FFFFFF',
  darkTextNonessential: '#8C8C8C',
  darkTextDisabled: '#444444',

  error100: '#FFEDED',
  error500: '#E10004',
  error700: '#AD0003',
  focus: 'rgba(100,100,100,0.5)',
  information100: '#F0F5FE',
  information500: '#0A5CC7',
  interactive100: '#E6F8F8',
  interactive200: '#CCF1F0',
  interactive300: '#99E3E1',
  interactive400: '#66D6D3',
  interactive500: '#00BAB5',
  interactive600: '#00827E',
  interactive700: '#275D5C',
  neutral50: '#F7F7F7',
  neutral75: '#F4F4F4',
  neutral100: '#EEEEEE',
  neutral200: '#DDDDDD',
  neutral300: '#C7C7C7',
  neutral400: '#8C8C8C',
  neutral500: '#767676',
  neutral600: '#444444',
  neutral700: '#303030',
  neutral800: '#222222',
  neutral900: '#111111',
  neutralBlack: '#000000',
  neutralBlackTransparent10: 'rgba(0,0,0,0.1)',
  neutralBlackTransparent20: 'rgba(0,0,0,0.2)',
  neutralWhite: '#FFFFFF',
  neutralWhiteTransparent: '#ffffff33',
  success100: '#E3F9EC',
  success500: '#24A148',
  success700: '#01803C',
  transparent: '#00000000',
  visited500: '#712B90',
  visited400: '#9267AD',
  warning100: '#FFF5DF',
  warning500: '#FDA108',
  warning700: '#A85D00',
};
