import styled from 'styled-components';
import { mq, textBody2 } from '_utils/styles';

export const PublishDateContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const PublishDateText = styled.span`
  ${textBody2}
  color: ${({ theme }) => theme.colors.text.secondary};
`;
