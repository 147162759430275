import { css } from 'styled-components';
import { IButtonColors, IButtonSetting } from '_containers/Theme/definitions';
import { mq } from '_utils/styles/';
import { throwInDev } from '../../helpers/dev';

export function buttonReset() {
  return css`
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    appearance: none;
  `;
}

export function buttonColors(settings: IButtonColors) {
  return css`
    background: ${settings.background};
    box-shadow: 0 0 0 ${settings.borderWidth}px ${settings.border};
    color: ${settings.color};
    position: relative;

    svg {
      fill: ${settings.color};
    }

    &:visited {
      color: ${settings.color};

      // increase specificity override link util
      path {
        fill: ${settings.color};
      }

      &:hover {
        path {
          fill: ${settings.colorHover};
        }
      }
    }

    &:hover {
      background: ${settings.backgroundHover};
      box-shadow: 0 0 0 ${settings.borderHoverWidth}px ${settings.borderHover};
      color: ${settings.colorHover};

      svg {
       fill: ${settings.colorHover};
      }
    }

    &:focus {
      box-shadow: 0 0 0 ${settings.borderHoverWidth}px ${settings.borderFocus};
    }

    &:active {
      background: ${settings.backgroundActive};
      border-color: ${settings.borderActive};
      color: ${settings.colorActive};
    }

    /* You have the option of disabled attr for form elements, or data-disabled for any other */
    &:disabled,
    &[data-disabled] {
      &:hover {
        background: ${settings.background};
        box-shadow: 0 0 0 ${settings.borderWidth}px ${settings.border};
        color: ${settings.color};
      }

      &:focus {
        box-shadow: 0 0 0 ${settings.borderHoverWidth}px ${settings.borderFocus};
      }

      &:active {
        background: ${settings.background};
        border-color: ${settings.border};
      }
    }

    outline: 0;

    body:not(.safe-focus-removal) &:focus {
      /* psuedo focus outline */
      &:before {
        border-radius: ${({ theme }) => `${theme?.focus?.radius}`};
        outline: ${({ theme }) => `${theme?.focus?.outlineWidth} solid ${theme?.focus?.outlineColor}`};
        content: '';
        height: ${({ theme }) => theme?.focus?.height};
        left: ${({ theme }) => theme?.focus?.left};
        position: absolute;
        top: ${({ theme }) => theme?.focus?.top};
        width: ${({ theme }) => theme?.focus?.width};
      }
    }

    // override global anchor focus
    &[href]:focus {
      outline-color: transparent;
    }
  `;
}

export function buttonSize(settings: IButtonSetting) {
  return css`
    border-top-left-radius: ${settings.borderRadius.topLeft}px;
    border-top-right-radius: ${settings.borderRadius.topRight}px;
    border-bottom-right-radius: ${settings.borderRadius.bottomRight}px;
    border-bottom-left-radius: ${settings.borderRadius.bottomRight}px;
    font-family: ${settings.fontFamily};
    font-size: ${settings.fontSize.sm}px;
    font-weight: ${settings.fontWeight || 'bold'};
    line-height: ${settings.lineHeight.sm}px;
    padding: ${settings.padding.y}px ${settings.padding.x}px;
    text-align: center;

    ${mq('md')} {
      font-size: ${settings.fontSize.md}px;
      line-height: ${settings.lineHeight.md}px;
    }
  `;
}

export const button = (size = 'default', color = 'primary') => ({ theme }) => {
  if (typeof size !== 'string') {
    // most likely got a theme object. give helpful error
    throwInDev(
      `Invalid button 'size' parameter (${size}). Usage: button([size],[color]) => curried fn. Did you forget to add parenthesis?`
    );
  }

  return css`
    ${buttonReset}
    ${buttonSize(theme.button[size])}
    ${buttonColors(theme.colors.button[color])}
  `;
};
