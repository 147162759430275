export const adConfig = {
  "Top": {
    sizes: [[300, 50], [728, 90], [970, 90]],
    mapping: [
      {
        viewport: [1024, 768],
        sizes: [970, 90],
      },
      {
        viewport: [728, 480],
        sizes: [728, 90],
      },
      {
        viewport: [640, 480],
        sizes: [300, 50],
      },
      {
        viewport: [0, 0],
        sizes: [300, 50],
      }
    ]
  },
  "SidebarTop": {
    sizes: [[970, 90],[728, 90],[300, 50], [300, 250], [160, 600], [180, 150], [300, 600]],
    mapping: [
      {
        viewport: [1024, 768],
        sizes: [[300, 600], [300, 250]],
      },
      {
        viewport: [728, 480],
        sizes: [160, 600],
      },
      {
        viewport: [640, 480],
        sizes: [300, 250],
      },
      {
        viewport: [0, 0],
        sizes: [300, 50],
      }
    ]
  },
  "SidebarBottom": {
    sizes: [[970, 90],[728, 90],[300, 50], [300, 250], [160, 600], [180, 150], [300, 600]],
    mapping: [
      {
        viewport: [1024, 768],
        sizes: [[300, 600], [300, 250]],
      },
      {
        viewport: [728, 480],
        sizes: [160, 600],
      },
      {
        viewport: [640, 480],
        sizes: [300, 250],
      },
      {
        viewport: [0, 0],
        sizes: [300, 50],
      }
    ]
  }
}
