import React, { ReactNode, forwardRef } from 'react';

import { Container, Input, Label, Check } from './StyledRadio';

type RadioProps = {
  id: string;
  name: string;
  value: any;
  checked: boolean;
  onChange: (e) => void;
  children: ReactNode;
};

export type Ref = HTMLElement;

const Radio = forwardRef<Ref, RadioProps>(
  ({ id, name, value, checked, children, onChange }, ref) => (
    <Container>
      <Input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        ref={ref}
      />
      <Label htmlFor={id}>{children}</Label>
      <Check role="presentation" aria-hidden="true" />
    </Container>
  )
);

export default Radio;
