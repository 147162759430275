import { canUseDOM } from 'exenv';
import React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import CorporateTheme from '_containers/Theme/corporate/';
import ITBTheme from '_containers/Theme/intheblack/';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import Scroller from '_utils/components/Scroller';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ssrRenderComplete: false
    }

    if (props.ssrState) {
      this.sitecoreContext = props.ssrState.sitecore && props.ssrState.sitecore.route
        ? {
          route: props.ssrState.sitecore.route,
          itemId: props.ssrState.sitecore.route.itemId,
          ...props.ssrState.sitecore.context,
        }
        : props.ssrState.sitecore.context
    } else {
      this.sitecoreContext = null;
    }
  }

  setSsrRenderComplete = ssrRenderComplete =>
    this.setState({
      ssrRenderComplete
    })

  componentDidMount() {

    // initialize ads to trigger - pushing empty fn into cmd queue seems only way to fix (arrggghh!)
    if (canUseDOM && typeof window !== 'undefined') {
      window.googletag = window.googletag || { cmd: [] };
      const { googletag } = window;
      googletag.cmd.push(function () { });
    }

    this.setSsrRenderComplete(true);
  }

  render() {
    const { path, Router, graphQLClient, siteName } = this.props;
    
    const LayoutWithTheme = (props) => (
      props?.siteName === 'corporate' ?
      <CorporateTheme>
          {props.children}
        </CorporateTheme>
        :
        <ITBTheme>
          {props.children}
        </ITBTheme>
    );

    return (
      <LayoutWithTheme siteName={siteName}>
        <ApolloProvider client={graphQLClient}>
          <SitecoreContext componentFactory={componentFactory} layoutData={this.props.ssrState}>
            <Router location={path} context={{}}>
              <Scroller />
              <Switch>
                {routePatterns.map((routePattern) => (
                  <Route
                    key={routePattern}
                    path={routePattern}
                    render={(props) => <RouteHandler route={props} ssrRenderComplete={this.state.ssrRenderComplete} />}
                  />
                ))}
              </Switch>
            </Router>
          </SitecoreContext>
        </ApolloProvider>
      </LayoutWithTheme>
    )
  }
}

export default AppRoot;
