import React from 'react';
import { compose } from 'ramda';
import { normalizeJssField, normalizeJssFields } from '_utils/helpers/jss';
import { withAuthenticatedCheck } from '_containers/BaseComponent';
import { createSrcSet } from '_utils/media';
import { getImageAttrsByRatio } from '_utils/helpers/image';
import { ImageSize, ImageRatio } from '_utils/types/enums';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Icon from '_components/Icon';
import { ItemProps } from './definitions';
import {
  ItemList,
  DetailsColumn,
  SubTitle,
  CardTitle,
  Description,
  CardDescription,
  NewCard,
  FirstPartFooterWrapper,
  CardFooterWrapper,
  ArrowWrapper,
  Image as StyledImage,
  Tag,
  TagContainer,
} from './StyledItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    // Add your custom styles here
    border: '1px solid #C7C7C7',
    borderRadius: '4px',
    margin: '30px 10px 30px 20px',
    padding: '20px 20px',
    width: 'inherit'
  },
});

const Item: React.FC<ItemProps> = ({
  description,
  featuredArticle,
  link,
  title,
}) => {
  const classes = useStyles();
  const viewAllLabel = getDictionaryItem('inpractice-view-all', 'View all')
  const readArticleLabel = getDictionaryItem('inpractice-read-article', 'Read article')

  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_16By9);
  const titleVal = normalizeJssField(title)?.value
  const descriptionVal = normalizeJssField(description)?.value
  const linkVal = normalizeJssField(link)
  const linkHref = linkVal?.value?.href
  const featuredArticleVal = normalizeJssFields(featuredArticle?.targetItem)
  const articleTitleVal = featuredArticleVal?.pageTitle?.value
  const articleDescriptionVale = featuredArticleVal?.pageListingDescription?.value
  const articleLink = featuredArticleVal?.url
  const articalTags = featuredArticleVal?.topics?.targetItems || []
  const listingImage = featuredArticleVal?.listingImage

  return <ItemList>
    <DetailsColumn>
      <SubTitle as={'h3'}>
        {titleVal}
      </SubTitle>
      <Description>{descriptionVal}</Description>
    </DetailsColumn>
    <FirstPartFooterWrapper>
      <a href={linkHref} style={{ display: 'flex' }}>
        <span>{viewAllLabel}</span>
        <ArrowWrapper>
          <Icon name="arrow_forward" ariaHidden />
        </ArrowWrapper>
      </a>
    </FirstPartFooterWrapper>

    {/* Below are for card, need to hide when it is mobile */}
    <NewCard href={articleLink}>
      <Grid
        container
        wrap="nowrap"
        alignItems="stretch"
        alignContent="flex-start"
        justifyContent="flex-start"
        className={classes.root}
      >
        <Grid item xs>
          <CardTitle as={'h4'}>
            {articleTitleVal}
          </CardTitle>
          <TagContainer>
            {articalTags.map((tag) =>
              <Tag key={`${tag.name}`}>{tag.name}</Tag>
            )}
          </TagContainer>
          <CardDescription>{articleDescriptionVale}</CardDescription>
          <CardFooterWrapper>
            <a href={articleLink} style={{ display: 'flex' }}>
              <span>{readArticleLabel}</span>
              <ArrowWrapper>
                <Icon name="arrow_forward" ariaHidden />
              </ArrowWrapper>
            </a>
          </CardFooterWrapper>
        </Grid>
        {/* Better for content editor to use aspectRatio 2/1 */}
        <Grid item xs={3}  style={{ aspectRatio: '2 / 1' }}>
          {
            <StyledImage
              className="lazyload"
              draggable={false}
              field={
                {
                  value: {
                    src: listingImage?.value?.src,
                    alt: 'image', // alt text is empty string, as this is a decorative image
                    'data-srcset': createSrcSet(listingImage?.value?.src, [ImageSize.MW_384, ImageSize.MW_512])
                  }
                }
              }
              {...defaultImageSize}
            />
          }
        </Grid>
      </Grid>
    </NewCard>
  </ItemList>
}

export default compose(withAuthenticatedCheck)(Item);
