import LayoutMap from './temp/layoutMap.js';
import * as layouts from './layouts';

class LayoutFactory {
  layoutMap: Map<string, ({ route: any }) => JSX.Element>;

  layoutIdMap: Map<string, ({ route: any }) => JSX.Element>;

  constructor() {
    // disconnected mode
    this.layoutMap = LayoutMap;
    // connected mode
    this.layoutIdMap = new Map();
    this.layoutIdMap.set('860e4214-847d-5f17-a731-ce9f15a732e3', layouts.DefaultLayout);
    this.layoutIdMap.set('d431f83b-05b1-4299-93ce-450eb0d96524', layouts.ITBLayout);
    this.layoutIdMap.set('f8d19c4a-e40a-4247-a18c-ae3f44ccd6d8', layouts.ITBSidebarLayout);
  }

  resolveLayout(contextData: any): any {
    // console.log('layoutMap', this.layoutMap);
    const layoutId =
      contextData && contextData.layoutId ? contextData.layoutId.toLowerCase() : null;
    const layoutName = contextData && contextData.layoutName ? contextData.layoutName : null;

    let layout;

    if (layoutId) {
      layout = this.layoutIdMap.get(layoutId);
    }

    if (!layout && layoutName) {
      layout = this.layoutMap.get(layoutName);
    }

    return layout || this.layoutMap.get('DefaultLayout');
  }
}

const layoutFactory = new LayoutFactory();

Object.freeze(layoutFactory);

export default layoutFactory;
