import styled from 'styled-components';
import { spacing } from '_utils/props';
import { fixedBody12 } from '_utils/styles';

export const ReadingTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ReadingTimeIconWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mr: '8px' })}

  svg {
    height: 16px;
    width: 16px;

    path {
      color: ${({ theme }) => theme.colors.text.nonessential};
    }
  }
`;

export const ReadingTimeText = styled.span`
  ${fixedBody12}
  color: ${({ theme }) => theme.colors.text.secondary};
`;
