import packageJson from '../../../package.json';
import colors from '_containers/Theme/corporate/colors';


const theme = {
  backgroundImage: 'none',
  colorKeyline: colors.all.neutral200,
  iconVisible: false,
  tablist: {
    color: colors.all.neutral700,
    colorActive: colors.all.neutral700,
    colorHighlightActive: colors.all.brandPrimary1,
    colorBackgroundHover: colors.interactiveCell.hover,
    colorBackgroundPressed: colors.interactiveCell.pressed,
    colorInactiveColorHover: `inherit`,
    flexGrow: 0,
    fontWeight: 400,
    fontWeightActive: 700,
    paddingXMobile: '18px',
    paddingX: '18px',
    outlineFocus: colors.all.brandPrimary1
  },
  tabPanelTextColor: colors.all.neutral700,
  tagList: {
    borderColor: colors.element.focused
  }
};

export const darkMode = {
  backgroundColor: colors.all.brandPrimary3,
  backgroundImage: `url(${packageJson.config.corporate.sitecoreDistPath}/images/homepage-tab-bg-optimised.png)`,
  colorKeyline: colors.all.brandPrimary1,
  iconVisible: true,
  tablist: {
    color: colors.all.darkText,
    colorActive: colors.all.darkText,
    colorHighlightActive: colors.all.brandPrimary2,
    colorBackgroundHover: colors.all.darkBackgroundTint3,
    colorInactiveColorHover: colors.all.neutralWhite,
    flexGrow: 1,
    fontWeight: 700,
    fontWeightActive: 700,
    paddingXMobile: '20px',
    paddingX: '30px',
    outlineFocus: colors.all.brandPrimary1
  },
  tabPanelTextColor: colors.all.neutralWhite,
  tabPanelMinHeight: {
    md: `500px`,
    lg: `800px`,
  }
};

export default theme;
