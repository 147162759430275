import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    background: colors.background.tint2
  },
  formContainer: {
    borderColor: colors.ui.divider
  }
};

