import colors from '_containers/Theme/intheblack/colors';
import {
  articleBody,
  headingH4,
  headingH5
} from '_utils/styles';

export default {
  base: {
    maxWidth: 100
  },
  blockquote: {
    background: colors.all.brandPrimary2,
    heading: headingH4,
  },
  externalLink: {
    icon: {
      afterColor: colors.all.interactive600,
      hoverColor: colors.all.neutralWhite
    }
  },
  list: {
    textbody: articleBody,
    marker: {
      content: "\\25CB",
      fontSize: '14px',
      color: colors.element.primary,
    },
    background: colors.element.primary,
    color: colors.all.darkTextContrast,
    fontSize: headingH5
  },
  paragraph: articleBody,
  orderedList: {
    bullet: {
      fontSize: '24px',
      top: 0
    },
    lineHeight: 30,
    md: {
      lineHeight: 32,
    },
    padding: '0 0 0 28px',
  },
  richTextWithImage: {
    captionColor: colors.all.neutral500,
  },
  table: {
    head: {
      background: colors.table.head.background,
      color: colors.table.head.color,
    },
    row: {
      background: colors.table.row.background,
      backgroundAlt: colors.table.row.backgroundAlt,
    },
    cell: {
      border: "1px solid",
      borderColor: colors.table.cell.border
    }
  },
}