import React, { createContext } from 'react';

import { Optionalize } from '_utils/types';

import { SectionKey } from './definitions';

export const FormContext = createContext(null);

export const SectionKeyContext = createContext<SectionKey>(null);

export const withFormSectionKeyContext = (sectionKey: SectionKey) => <T extends SectionKey>(
  WrappedComponent: React.ComponentType<T>
) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withFormSectionKeyContext: React.FC<Optionalize<T, SectionKey>> = (props) => (
    <SectionKeyContext.Provider value={sectionKey}>
      <WrappedComponent {...(props as T)} />
    </SectionKeyContext.Provider>
  );

  withFormSectionKeyContext.displayName = `withFormSectionKeyContext(${displayName})`;

  return withFormSectionKeyContext;
};
