import colors from '_containers/Theme/corporate/colors';


export default {
  figcaption: {
    color: colors.element.primary3
  },
  iconContainer: {
    pathFillColor: colors.all.neutral700
  },
  textWrapper: colors.text.default,
  credit: {
    color: colors.text.subtle
  }
}