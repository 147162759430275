import React from 'react';
import { Helmet } from 'react-helmet';
import {
  WithContext,
} from 'schema-dts';

import { ITBAllPagesStructuredDataProps } from '../definitions';

const ITBAllPagesStructuredData: React.FC<ITBAllPagesStructuredDataProps> = ({ context }) => {

  if (!context?.site?.name) {
    return null;
  }

  const websiteData: WithContext<any> = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "INTHEBLACK",
    "url": "https://intheblack.cpaaustralia.com.au/",
    "logo": "https://www.cpaaustralia.com.au/-/jssmedia/Project/CPA/InTheBlack/images/Other/INTHEBLACK.jpg",
    "sameAs": [
      "https://www.facebook.com/cpaaustralia",
      "https://www.instagram.com/cpaaustralia/",
      "https://www.linkedin.com/school/cpaaustralia",
      "https://twitter.com/cpaaustralia"
    ]
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(websiteData)}
      </script>
    </Helmet>
  );
};

export default ITBAllPagesStructuredData;