import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ImageSize } from '_utils/types/enums';
import {
  createSrcSet,
} from '_utils/media';

import {
  ClickableWrapper,
  Container,
  ContentWrapper,
  CoverImage,
  CoverImageWrapper,
  Label,
  LinkItem,
  Title,
} from "./StyledSidebarMagazineBanner";

import { MagazineBannerProps } from "./definitions";


const SidebarMagazineBanner: React.FC<MagazineBannerProps> = ({
  componentLabel,
  coverImage,
  ctaLabel,
  editMode,
  link,
  title,
  uid
}) => {
  const linkItem = { ...link?.jss?.value, target: '_blank' }

  return (
    <ClickableWrapper
      as={editMode ? 'div' : 'a'}
      className="sidebar-magazine-banner"
      data-component
      href={link?.jss?.value?.href}
      id={uid}
      target={link?.jss?.value?.target ?? '_blank'}
      title={link?.jss?.value?.title}
    >
      <Container>
        <CoverImageWrapper>
          <CoverImage
            className="lazyload"
            field={
              editMode
                ? coverImage
                : {
                  value: {
                    src: coverImage?.value?.src,
                    alt: coverImage?.value?.alt ?? '',
                    'data-srcset': createSrcSet(coverImage?.value?.src, [
                      ImageSize.MW_128,
                      ImageSize.MW_256,
                      ImageSize.MW_384
                    ])
                  }
                }
            }
            width={ImageSize.MW_128}
            height={ImageSize.MW_128}
          />
        </CoverImageWrapper>
        <ContentWrapper>
          <Title>
            <Text
              field={{ value: title }}
              editable={false}
            />
          </Title>
          <Label>{componentLabel}</Label>
          <LinkItem
            fallbackTitle={ctaLabel}
            field={{ value: linkItem }}
            hideExternalIcon="true"
            linkTheme="none"
          />
        </ContentWrapper>
      </Container >
    </ClickableWrapper >
  )
}

export default SidebarMagazineBanner;