import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';

import {
  BannerContent,
  BannerImage,
  Blurb,
  Container,
  Figure,
  Title,
  Image,
  SubTitle
} from './StyledProfilePageBanner';

import {
  withDataSourceValidation,
  withEditMode,
  withSitecoreContext,
} from '_containers/BaseComponent';

import { stripHTML } from '_utils/helpers/html';
import { normalizeJssFields } from '_utils/helpers/jss';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize } from '_utils/types/enums';

import { ProfilePageBannerFieldValidators } from './validation';

import {
  NormalisedProfilePageBannerPageFields,
  ProfilePageBannerProps
} from './definitions';

const ProfilePageBanner: React.FC<ProfilePageBannerProps> = ({ rendering, editMode }) => {
  // TODO: add type def to normalizeJssFields output and then remove need for NormalisedProfilePageBannerPageFields
  const renderingPageFields: NormalisedProfilePageBannerPageFields =
    normalizeJssFields(rendering?.fields?.data?.datasource) || {};

  const description = renderingPageFields?.description;
  const image = renderingPageFields?.image;
  const title = renderingPageFields?.title;
  const subTitle = renderingPageFields?.subTitle;
  const uid = rendering?.uid;

  return (
    <Container
      className="profile-page-banner"
      data-component
      id={uid}
    >
      <BannerImage>
        <Figure editMode={editMode}>
          <Image
            draggable={false}
            field={
              editMode
                ? image
                : {
                  value: {
                    src: imagePlaceholderSrc,
                    alt: '', // alt text is empty string, as this is a decorative image
                    'srcset': createSrcSet(image?.value?.src, [ImageSize.MW_384, ImageSize.MW_640, ImageSize.MW_768, ImageSize.MW_1024])
                  }
                }
            }
            height="224"
            width="224"
          />
        </Figure>
      </BannerImage>
      <BannerContent>
        <Title>
          <Text field={title} />
        </Title>
        <SubTitle>
          <Text field={subTitle} />
        </SubTitle>
        {(editMode || description?.value) && (
          <Blurb>
            <Text field={editMode ? description : { value: stripHTML(description?.value) }} />
          </Blurb>
        )}
      </BannerContent>
    </Container>
  )
};

export default compose(
  withSitecoreContext(),
  withEditMode,
  withDataSourceValidation(ProfilePageBannerFieldValidators, false),
)(ProfilePageBanner);
