import colors from '_containers/Theme/corporate/colors';

export default {};

export const darkMode = {
  action: colors.all.brandPrimary1,
  attributeIcon: colors.all.neutral700,
  background: colors.all.brandPrimary3,
  cardBackground: colors.all.neutralWhite,
  border: `1px solid ${colors.all.neutral200}`,
  borderDashed: `0px dashed ${colors.all.neutral200}`,
  borderDashedHover: `0px dashed ${colors.all.neutral800}`,
  borderFocus: `2px solid ${colors.all.interactive200}`,
  borderHover: `none`,
  borderRadius: 4,
  borderSelected: `none`,
  boxShadow: null,
  boxShadowHover: null,
  color: colors.all.neutralWhite,
  ctaColor: 'tertiary',
  theme: {
    light: {
      headerColor: colors.background.darkBlue,
      backToParentColor: colors.all.brandPrimary3,
      color: colors.all.brandPrimary3,
    },
    dark: {
      headerColor: colors.text.neutral,
      backToParentColor: colors.text.neutral,
      color: colors.text.neutral,
    },
    background: {
      yellow: colors.element.primary2,
      teal: colors.all.brandSecondary3,
      orange: colors.brand.orange.secondary,
      blue: colors.element.primary,
      purple: colors.element.secondary,
      black: colors.element.neutralBlack
    }
  }
};
