import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React from 'react';

import { withEditMode } from '_containers/BaseComponent';
import { withDigitalDataContext } from '_containers/DigitalDataContext';

import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';


import { Container, TagNavLink } from './StyledTagList';
import NavItem from '../NavItem';
import { TagListProps, TagItem } from './definitions';
import Tag from '_utils/components/Tag';


const validTag = (tag: TagItem) => {
  if (!tag) {
    return false;
  }

  const { displayName, id } = tag;

  return Boolean(displayName && id);
};

const TagList: React.FC<TagListProps> = ({
  className = '',
  digitalData,
  editMode,
  isLinkEnabled = true,
  rendering,
  setDigitalData,
  size = 'small',
  tagList,
  variant = 'interactiveLink',
}) => {
  const tags = rendering?.fields?.data?.datasource?.tags?.targetItems || tagList;
  const uid = rendering?.uid ?? '';

  if (!tags?.length) {
    return null;
  }

  const handleClick = (name: string, url: string) => {
    if (!canUseDOM) {
      return;
    }

    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['link'],
          {
            linkName: name,
            linkDestination: url
          },
          digitalData
        )
      );

      emitTrackEvent('sectionViews');
    }
  }

  return (
    <Container
      data-component
      className={`tag-list ${className}`}
      variant={variant}
      id={uid}
      isLinkEnabled={isLinkEnabled}
    >
      {
        tags?.filter(validTag).map(
          ({
            displayName,
            id,
            url,
          }) => (
            (url && isLinkEnabled) ? (
              <NavItem
                key={id}
                onClick={() => handleClick(displayName, url)}
                StyledLink={TagNavLink}
                link={{
                  url: url,
                  type: 'internal'
                }}
              >
                <Tag
                  key={id}
                  size={size}
                  tagVariant={variant}
                >{displayName}</Tag>
              </NavItem>)
              : (
                <Tag
                  key={id}
                  size={size}
                  tagVariant={variant}
                >{displayName}</Tag>
              )
          )
        )
      }
    </Container>
  );
};

export default compose(
  withDigitalDataContext,
  withEditMode)(TagList);

