import styled, { css } from 'styled-components';
import {
  TRANSITIONING_CLASS,
} from '_utils/helpers/render';
import {
  spacing,
  transitions,
} from '_utils/props';
import { depth } from '_utils/styles';
import {
  mq,
  fixedBody14,
  listReset
} from '_utils/styles';

import { Link } from '_utils/components';

const calcMenuOffset = (bp: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fixed' = 'xs', extra = 0) => ({
  theme
}) => {
  const offset = theme.pageHeader.height[bp] + extra;

  return css`
    top: ${offset}px;
  `;
};

export const BreadcrumbContainer = styled.section`
  background: ${({ theme, isSticky }) => isSticky ? theme.breadcrumb.container.background : 'none'};
  border-bottom: 1px solid transparent;
  left: 0;
  line-height: ${({ theme }) => theme.typography.text.fixedBody14.lineHeight}px;
  margin-top: 27px;
  position: ${({ editMode, isSticky }) => !editMode && isSticky ? 'sticky' : 'relative'};
  right: 0;
  top: ${({ editMode, isSticky }) => !editMode && isSticky ? '-1px' : '0'};
  z-index: ${depth.BREADCRUMB};

  // hide breadcrumb during SPA route change
  .${TRANSITIONING_CLASS} & {
    opacity: 0;
  }

  ${mq('md')} {
    margin-top: 40px;
  }

  ${({ theme }) => spacing({ theme, pb: { md: 2, xs: '8px' }, pt: { md: '8px', xs: 2 } })};
  ${({ theme }) => transitions({ theme, transition: 'top' })};

  ${({ isSticky }) => isSticky
    ? css`
      border-bottom: 1px solid ${({ theme }) => theme.breadcrumb.container.borderColor};

      ${calcMenuOffset('xs')}

      ${mq('md')} {
        ${calcMenuOffset('fixed')}
      }
    `
    : css`
      margin-bottom: 0;
      ${({ theme }) => transitions({ theme, transition: 'background' })};
    `
  }

  // hide breadcrumb if campaign navigation on page
  .hasCampaignNavigation & {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const LinkItem = styled(Link)`
  a {
    border-bottom: none;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
  }
`;

export const LinkList = styled.ul`
  ${listReset}

  margin-left: -8px;

  ${mq('md')} {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    ${fixedBody14}
    display: none;
    flex-grow: 0;
    line-height: inherit;
    position: relative;

    /* only show parent link on XS breakpoint */
    &:nth-last-child(2) {
      display: flex;
    }

    /* chevron is 24px but line-height only 18 */
    .chevron {
      position:relative;
      top: -2px;
    }

    ${mq('md')} {
      ${({ theme }) => spacing({ theme, mt: '8px' })};
      display: flex;

      .chevron {
        display: none;
      }

      // separator
      &:not(:first-child):before {
        color: ${({ theme }) => theme.breadcrumb.linkList.color};
        content: '/';
        position: absolute;
        left: 0;
        top: 0;
      }

      &:not(:first-child) {
        padding-left: 10px;
      }

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
`;
