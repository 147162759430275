
import { ImageField } from "@sitecore-jss/sitecore-jss-react/types/components/Image";
import { AuthenticatedAccessCheck } from '_utils/types';

export const simpleCardTypes = ['icon', 'textOnly'];

export const cardTypeLabels = {
  inPractice: 'inPractice',
}

export const stylesThatRenderAttributes = {
  inPractice: true,
};

export const stylesThatRenderAuthor = {
  inPractice: true,
};

export const stylesThatRenderDate = {
  inPractice: true,
};

export const stylesThatRenderImage = {
  inPractice: true,
};

export const stylesThatRenderLogo = {
  inPractice: true,
  inTheBlack: true,
}

export const stylesThatRenderReadingTime = {
  inPractice: true,
};


export const stylesThatRenderDescription = {
  inPractice: true,
};

export const stylesThatRenderTags = {
  inPractice: true,
};

type TagType = {
  id: string;
  displayName?: string;
}

export type ItemProps = AuthenticatedAccessCheck & {
  $hasHighlightStrip: boolean;
  id: string;
  icon?: any;
  type: string;
  title: string;
  description: string;
  headingLevel: number;
  link: string;
  listingImage?: ImageField;
  author?: string;
  published?: string;
  noOfEpisodes?: string;
  readingTime?: string;
  listeningTime?: string;
  ctaButtonText?:string;
  tags?: TagType[];
};

export type ListItemProps = ItemProps & {
  editMode: boolean;
};

export type InPracticeArticleListProps = {
  editMode: boolean;
  rendering: {
    params: {
      layoutType: 'Carousel' | 'List';
    };
    fields: {
      data: {
        cards: {
          inpracticeArticles: ItemProps[];
        };
      };
    };
    uid: string;
  },
  sitecoreContext?: any;
};
