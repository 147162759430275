import styled, { css } from 'styled-components';

import {
  fixedBody12,
  fixedBody14,
  fixedTitle14,
} from '_utils/styles';

export const FieldSummaryLabel = styled.div`
  ${({ isEditing }) => isEditing
    ? css`
      /* Describes label for field that cannot be edited, whilst in edit mode. */
      ${fixedTitle14};
      color: ${({ theme }) => theme.form.label.color};
      display: inline-block;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 4px;
    `
    : css`
      /* Describes label for a read-only field, whilst in read-only mode. */
      ${fixedBody14};
      color: ${({ theme }) => theme.complexFormElement.fieldSummary.label.color};
    `
  };
`;

export const FieldSummaryValue = styled.div`
  white-space: pre-wrap;
`;

export const FieldSummaryNoValue = styled.div`
  color: ${({ theme }) => theme.complexFormElement.fieldSummary.noValue.color};
`;

export const FieldSummaryFootnote = styled.div`
  ${fixedBody12};
  color: ${({ theme }) => theme.complexFormElement.fieldSummary.footNote.color};
`;
