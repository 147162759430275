import moment from 'moment-mini';

export const isValidDate = (d) => d.toString().toLowerCase() !== 'invalid date';

export type DateData = {
  attrDate: string; // used for <time datetime="" />
  dateObj: Date;
  visualDate: string;
};

export default (value = null, options = null, dateFormat = 'YYYYMMDDTHHmmss', strictMode = true): DateData => {
  try {
    if (value === null) {
      return null;
    }

    // handle utc
    const date = (options?.isUTC) ?
      moment.utc(value, dateFormat, false) :
      moment.parseZone(value, dateFormat, strictMode);

    if (!date.isValid()) {
      return null;
    }

    // 01/09/2021
    if (options?.short) {
      return {
        attrDate: date.format('YYYY-MM-DD'),
        dateObj: date.toDate(),
        visualDate: date.format('DD/MM/YYYY')
      };
    }

    // 1 Sep 2021
    if (options?.medium) {
      return {
        attrDate: date.format('YYYY-MM-DD'),
        dateObj: date.toDate(),
        visualDate: date.format('D MMM Y')
      };
    }

    // output preferred format
    if (options?.outputFormat) {
      return {
        attrDate: date.format('YYYY-MM-DD'),
        dateObj: date.toDate(),
        visualDate: date.format(options.outputFormat)
      };
    }

    return {
      attrDate: date.format('YYYY-MM-DD'),
      dateObj: date.toDate(),
      visualDate: date.format('D MMMM YYYY')
    };
  } catch {
    return null;
  }
};
