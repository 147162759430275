import styled, { css, keyframes } from 'styled-components';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { TRANSITION_DELAY } from '_utils/components/Scroller/';
import Grid from '_utils/components/Grid';

import {
  depth,
  hideOnPrint,
  mq,
} from '_utils/styles';
import { spacing } from '_utils/props';

export const MainContainer = styled.div`
  flex: 1 0 auto;
  position: relative;

  &.intheblack-default {
    &.itb-homepage {
      .ph {
        // overwrite jss-content-main padding bottom on homepage
        padding-bottom: 0;

          // overwrite data-component margin bottom from 6 to 7 on homepage
        > [data-component]:not(:last-child) {
          ${({ theme }) => spacing({ theme, mb: 7 })}
        }
      }
    }
  }
`;

export const SingleColumnContainer = styled(Grid.Item)``;

export const SidebarContainer = styled(Grid.Item)`
  ${({ theme }) => spacing({ theme, pb: 7 })}

  ${mq('md')} {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  ${hideOnPrint}
`;

export const ArticleContainer = styled(Grid.Item)`
  ${({ theme }) => spacing({ theme, pb: 7 })}

  @media print {
    max-width: 100%;
    display: block;
    width: 100%;
  }
`;

const pulse = keyframes`
  100% {
    opacity: 0;
  }
`;

export const MainContentPlaceholder = styled(Placeholder)``

export const SingleColumnPlaceholder = styled(Placeholder)`
  ${({ theme }) => spacing({ theme, pb: 5 })}
`

export const IntroContentPlaceholder = styled.div`
// overwrite jss-content-intro padding bottom on homepage
 .intheblack-default.itb-homepage & {
    ${({ theme }) => spacing({ theme, pb: '52px' })}
  }

  .page-banner,
  .articleInfo {
    margin-bottom: 0;
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, pb: '17px' })}
  }

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, pb: '36px' })}
  }
`

export const ArticleFooterPlaceholder = styled.div`
  ${({ theme }) => spacing({ theme, pt: '20px' })};
  border-top: 1px solid ${({ theme }) => theme.section.divider.color};
  display: flex;
  flex-direction: column;

  & > .new-social-share {
    // when column, adding margin on top
    margin-top: 20px;
  }

  ${mq('lg')} {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    & > .new-social-share {
    margin-top: 20;
  }
  }
`;

// hide Recommended For You (Card List component)
export const OutroContentPlaceholder = styled.div`
  .card-list {
    ${hideOnPrint}
  }
`;
