import { canUseDOM } from 'exenv';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @name useUnload
 * @description Allows setting of a callback should the beforeunload event fire
 * @param fn - callback function to fire
 * @param {boolean} disabled - set this to update whether to override and disable the event listener
 */
const useUnload = (
  fn: (e?: Event) => void,
  disabled?: boolean,
) => {
  if (!canUseDOM) {
    return;
  }

  const handleUnload = useRef(fn);
  const { pathname } = useLocation();

  // On path change: routes changing
  useEffect(() => {
    if (disabled) {
      return;
    }

    const onUnload = handleUnload?.current;
    onUnload?.();
  }, [pathname, handleUnload, disabled]);

  // On unload: the more native things like reload, back / forward
  useEffect(() => {
    const onUnload = handleUnload?.current;

    if (!onUnload) {
      return;
    }

    if (onUnload) {
      if (!disabled) {
        window.addEventListener('beforeunload', onUnload);
      } else {
        window.removeEventListener('beforeunload', onUnload);
      }
    }

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [handleUnload, disabled]);
};

export default useUnload;