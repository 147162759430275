import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
  avoidPageBreakOnPrint,
  fixedBody12,
  mq,
  safeFocusRemoval,
  textBody1,
} from '_utils/styles';

export const Profile = styled(Link)`

  display: flex;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: inherit;

    img,
    svg {
      outline: 2px solid ${({ theme }) => theme.colors.all.interactive500};
      outline-offset: 2px;
    }
  }

  &:focus {
    ${safeFocusRemoval}
    outline-offset: 0 !important;
    outline-width: 2px !important;
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mb: 3 })}
  }

  ${mq('md')} {
    &:not(:last-child) {
      ${({ theme }) => spacing({ theme, mr: 5 })}
    }
  }
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProfileFigure = styled.div`
  ${({ theme }) => spacing({ theme, mr: '16px' })}
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 48px;
  position: relative;
  width: 48px;;

  img,
  svg {
    border-radius: ${(props) => !props.editMode ? '50%' : null};
    outline-offset: 2px;
  }
`;

export const ProfileImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const ProfileName = styled.span`
  ${textBody1}
  color: ${({ theme }) => theme.agendaAccordion.profileNameColor};
  font-weight: 800;
`;

export const ProfileTitle = styled.span`
  ${fixedBody12}
  color: ${({ theme }) => theme.agendaAccordion.profileTitleColor};
`;
