import { canUseDOM } from 'exenv';

export const setSection = (path): string => {
  if (!canUseDOM) {
    return '';
  }

  if (path === '/') {
    return 'home:';
  }

  return path.substring(1).replace(/\//g, ':').toLowerCase();
};

