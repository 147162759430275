import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { FormContext } from '_containers/FormSectionContext';

import { EditableSectionActionsProps } from './definitions';

import {
  CancelButton,
  SaveButton,
  SectionActions,
} from './StyledEditableSection';

const EditableSectionActions: React.FC<EditableSectionActionsProps> = ({
  cancelLabel,
  children,
  explicitSectionKey,
  saveLabel,
  ...props
}) => {
  // TODO: explicitSectionKey is a hack until partial saves is possible, and this component moves within each section, within a sectionKeyContext
  const formContext = useContext(FormContext);
  const sectionContext = formContext?.[explicitSectionKey];

  const formikContext = useFormikContext();
  const { isValid, touched, submitCount } = formikContext;

  const handleOnClickSave = (e: Event) => {
    sectionContext?.saveAction?.(e);
    formikContext?.handleSubmit?.();

    if (!formikContext?.isValid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      {sectionContext?.isEditing ? (
        <SectionActions {...props}>
          <CancelButton
            aria-label={cancelLabel}
            onClick={sectionContext?.cancelAction}
            disabled={formikContext?.isSubmitting}
          >
            {cancelLabel}
          </CancelButton>

          <SaveButton
            aria-label={saveLabel}
            type="submit"
            onClick={handleOnClickSave}
            disabled={(!isValid && touched && submitCount > 0) || formikContext?.isSubmitting}
          >
            {saveLabel}
          </SaveButton>
        </SectionActions>
      ) : (
        <>
          {children}
        </>
      )}
    </>
  );
};

export default EditableSectionActions;
