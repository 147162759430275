import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    background: colors.background.tint2,
    borderColor: colors.ui.divider
  },
  campaignName: {
    background: colors.background.darkBlue,
    color: colors.all.darkText
  },
  linkItem: {
    color: colors.text.default,
    hover: {
      background: colors.background.tint3,
      color: colors.text.default
    },
    focus: {
      outline: colors.interactiveCell.focus
    },
    active: {
      background: colors.interactiveCell.pressed
    },
    activeAfter: {
      background: colors.all.brandPrimary1
    },
  },
  gradient: {
    background: colors.background.tint2,
    end: {
      background: colors.background.tint2
    }
  },
  navigation: {
    borderColor: colors.ui.divider
  },
  linkList: {
    scrollbar: {
      background: colors.background.tint4
    }
  },
  linkButton: {
    visited: {
      color: colors.all.neutralWhite
    }
  }
};