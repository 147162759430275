import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';

import { Grid, Link } from '_utils/components';
import {
  BookHingeParent,
  BookHingeSVGContainer,
} from '_utils/components/BookHinge/StyledBookHinge';
import { spacing } from '_utils/props';
import {
  avoidPageBreakOnPrint,
  button,
  fixedTitle12,
  headingH1,
  mq,
  subheading2,
  textBody1,
} from '_utils/styles';

const BANNER_MIN_HEIGHT_MD = 480;
const BANNER_MIN_HEIGHT_LG = 590;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.homepageBanner.container.background};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq('md')} {
    align-items: center;
    display: flex;
    min-height:  ${props => props.adjustDepth === 1 ? '350px' : `${BANNER_MIN_HEIGHT_MD}px`}; 
  }

  ${mq('lg')} {
    min-height: ${props => props.adjustDepth === 1 ? '380px' : `${BANNER_MIN_HEIGHT_LG}px` };
  }

  @media print {
    background-color: ${({ theme }) => theme.homepageBanner.container.mediaPrint.background};
    color: ${({ theme }) => theme.homepageBanner.container.mediaPrint.color};
  }
`;

export const BannerBlurbContainer = styled.div`
  ${({ $mobile }) =>
    $mobile
      ? css`
          ${({ theme }) => spacing({ theme, pt: 3, pb: 5 })};
          display: block;
        `
      : css`
          display: none;
        `}

  ${mq('md')} {
    ${({ $mobile }) =>
    $mobile
      ? css`
            display: none;
          `
      : css`
            display: block;
            ${({ theme }) => spacing({ theme, mt: 3 })}
          `}
  }
`;

export const BannerGridContainer = styled(Grid.Container)`
  margin: 0;

  ${mq('md')} {
    max-width: 50%;
    width: 50%;
  }

  ${({ theme }) => spacing({
  theme,
  px: {
    xs: `${theme.grid.gutter.size.xs}px`,
    md: 3,
    lg: 4,
    xl: 6,
  },
  pr: {
    xl: 0,
  },
  py: {
    md: 7,
    lg: 8,
  }
})};

padding-top:  ${props => props.adjustDepth === 1 && '42px'} !important; 
padding-bottom:  ${props => props.adjustDepth === 1 && '42px'} !important;

`;


export const BannerHeader = styled.div`
  ${({ theme }) =>
    spacing({ theme, pt: { xs: 5, md: 0 }, pb: { xs: 3, md: 0 } })};
`;

export const Header = styled.h1`
  ${headingH1}
  color: ${({ theme }) => theme.homepageBanner.headerColor};
  margin-bottom: 0;

  /* mq specificity required to override default heading margin-bottom */
  ${mq('md')} {
    margin-bottom: 0;
  }
`;

export const Label = styled.p`
  ${fixedTitle12};
  color: ${({ theme }) => theme.homepageBanner.labelColor};
  letter-spacing: 0.0025em;
  line-height: 1;
  text-transform: uppercase;

  ${({ theme }) => spacing({ theme, mt: 0, mb: 2 })}
`;

export const Blurb = styled.p`
  ${subheading2}
  color: ${({ theme }) => theme.homepageBanner.color};
  ${({ theme }) => spacing({ theme, mt: { md: 2 } })}
`;

export const TagListContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const BannerImage = styled(BookHingeParent)`
  background-color: ${({ theme }) => theme.homepageBanner.background};
  display: block;
  height: 212px;
  position: relative;
  width: 100%;

  ${mq('md')} {
    height: 100%;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }

  @media print {
    width: 100%;
  }

  ${BookHingeSVGContainer} {
    path {
      fill: ${({ theme }) => theme.homepageBanner.container.background};
    }
  }
`;

export const Figure = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const BannerContent = styled(Grid.Item)`
  ${mq('xl')} {
    max-width: 624px;
    width: 624px;
  }

  @media (min-width: 1920px) {
    max-width: 75%;
    width: 75%;
  }

  @media print {
    ${({ theme }) => spacing({ theme, py: 3, px: 0 })};
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const LinkButton = styled(Link)`
  ${({ theme }) => spacing({ theme, pt: { xs: 3, lg: 5 } })};

  a {
    ${button('default', 'secondaryDark')};
    ${textBody1};
    margin-bottom: 0;

    ${mq.lessThan('md')} {
      width: 100%;
    }

    ${mq('md')} {
      min-width: 140px;
    }

    path {
      fill: currentColor;
    }
  }

  @media print {
    display: none !important;
  }
`;
