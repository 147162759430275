import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';
import { isValidUrl } from '_utils/validationChecks';

export const campaignNavigationFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'data.campaignName',
    errorMessage: 'Campaign Name is required',
    required: true
  },
  {
    field: 'data.links',
    errorMessage: 'At least one navigation link must be provided',
    minLength: 1
  }
];
