import colors from '_containers/Theme/intheblack/colors';

export default {
  action: colors.all.brandPrimary1,
  attributeIcon: colors.all.neutral700,
  background: `transparent`,
  cardBackground: colors.all.neutralWhite,
  border: `1px solid ${colors.all.neutral200}`,
  borderDashed: `0px dashed ${colors.all.neutral200}`,
  borderDashedHover: `0px dashed ${colors.all.neutral800}`,
  borderFocus: `2px solid ${colors.all.interactive500}`,
  borderHover: `none`,
  borderRadius: 0,
  borderSelected: `none`,
  boxShadow: null,
  boxShadowHover: null,
};

export const darkMode = {
  background: colors.all.brandPrimary3,
  color: colors.all.neutralWhite,
  ctaColor: 'tertiaryDark',
};