import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';

import { RadioListProps } from './definitions';

import {
  RadioLabel,
  InvalidMessage,
  InvalidMessageContainer
} from './StyledRadioList'

import {
  FormElementWrapper
} from '../StyledFormElement'

const Radio = ({ children, ...props }) => {
  const [field, meta] = useField(props?.name);
  const errorOccur = meta?.error && (meta?.touched || meta?.initialTouched) ? "error-border" : "";

  return (
    <RadioLabel>
      <Field
        className={`formik-field-radio ${errorOccur}`}
        type="radio"
        {...field}
        {...props}
      />
      {children}
    </RadioLabel>
  )
}

const RadioList: React.FC<RadioListProps> = (props) => {
  const items = props?.items;
  const name = props?.name;

  return (
    <>
      <FormElementWrapper className="checkbox-radio">
        {items?.map((item, index) => (
          <Radio
            key={index}
            name={name}
            // may need to set different value than displayed to user
            value={item?.value ?? item.label}
            {...props}
          >
            {item.label}
          </Radio>
        ))}
        <InvalidMessageContainer name={name}>
          <InvalidMessage><ErrorMessage name={name} /></InvalidMessage>
        </InvalidMessageContainer>
      </FormElementWrapper>
    </>
  );
};

export default RadioList;