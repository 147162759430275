import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';

import { MOTION_SPEED_FAST } from '_containers/Theme/global';
import { withMenuContext } from '_containers/MenuContext';

import { enableBodyScroll, disableBodyScroll } from '_utils/helpers';
import { usePrevious, useTimer } from '_utils/hooks';

import { MenuProps } from './definitions';
import {
  InnerNavWrapper,
  Nav,
} from './StyledMainMenu';
import PrimaryNavigation from './PrimaryNavigation';
import SecondaryNavigation from './SecondaryNavigation';


// https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html

const MainMenu: React.FC<MenuProps> = ({
  activeMenu,
  fields,
  isSticky,
  menuActive,
  setActiveMenu,
  setMenuActive,
}) => {
  const datasource = fields?.data?.datasource;
  // Get navigation data
  const getNavigation = datasource?.navigation;
  if (!getNavigation) {
    console.error("Main navigation not configured");
    return null;
  };
  const getNavigationItem = (navigationName: string) => {
    return getNavigation?.filter((item) => item?.name === navigationName);
  };

  const containerRef = useRef<HTMLInputElement>();
  const prevMenuActive = usePrevious(menuActive);
  // focused element index - the first value is parent index, second is child index
  const { setTimer, cancelTimer } = useTimer();
  // TODO determine if/where this needs to be added
  const [transitioning, setTransitioning] = useState(false);

  // focus listener effect:
  // 1. auto focus first menu item when menu opened
  // 2. close menu if focused element is outside of menu
  useEffect(
    () => {
      const container = containerRef.current;
      const overflowElements = [containerRef.current, ...container.getElementsByTagName('ul')];

      const focusListener = (e) => {
        let target: HTMLElement = e.target;

        if (target && target.id === 'menu-button' || target && target.id === 'dashboard-menu-button') {
          return;
        }

        // iterate through parents until we find menu container or no more
        while (target) {
          if (target === container) {
            // found menu container, bail
            return;
          }
          // move up to next parent
          target = target.parentNode as HTMLElement;
        }
        // focus outside, close menu
        setMenuActive(false);
      };

      // add/remove listener when menu opened/closed
      if (menuActive && !prevMenuActive) {
        setTransitioning(true);
        setTimer(() => {
          document.body.addEventListener('focus', focusListener, true);
          setTransitioning(false);
        }, MOTION_SPEED_FAST + 10);

        disableBodyScroll(overflowElements, {
          reserveScrollBarGap: true
        });
        // without setImmediate effect clean up runs immediately
      } else if (!menuActive && prevMenuActive) {
        document.body.removeEventListener('focus', focusListener, true);
        enableBodyScroll(overflowElements);
        setTransitioning(true);

        setTimer(() => {
          setTransitioning(false);
        }, MOTION_SPEED_FAST + 10);
      }

      // clean up
      return () => {
        cancelTimer();
        enableBodyScroll(overflowElements);
        document.body.removeEventListener('focus', focusListener, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuActive]
  );

  const resetMenu = (e) => {
    e.preventDefault();
    setMenuActive(false);
  };

  return (
    <Nav
      id="main-menu"
      className={`main-menu ${canUseDOM && menuActive && 'menu-active'}`}
      isSticky={isSticky}
      ref={containerRef}
    >
      <InnerNavWrapper>
        <SecondaryNavigation
          activeMenu={activeMenu}
          fields={fields}
          isSticky={isSticky}
          navigationItems={getNavigationItem("secondaryNavigation")}
          resetMenu={resetMenu}
        />
        {/* <div style={{backgroundColor: 'white', color: 'white'}}>hello worl</div> */}
        <PrimaryNavigation
          activeMenu={activeMenu}
          fields={fields}
          isSticky={isSticky}
          navigationItems={getNavigationItem("primaryNavigation")}
          resetMenu={resetMenu}
          setActiveMenu={setActiveMenu}
        />
      </InnerNavWrapper>
    </Nav>
  );
};

export default compose(withSitecoreContext(), withMenuContext)(MainMenu);
