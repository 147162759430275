import React from "react";

export const YOUTUBEICON = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="20"
    viewBox="0 0 90 20"
    fill="none"
  >
    <g id="layer1">
      <g id="g2366">
        <path
          id="path1412"
          d="M14.0702 19.4276C14.0702 19.4276 22.8949 19.4276 25.0838 18.8448C26.3163 18.5145 27.2432 17.5625 27.5685 16.3872C28.17 14.2307 28.17 9.69437 28.17 9.69437C28.17 9.69437 28.17 5.18717 27.5685 3.05013C27.2432 1.84562 26.3163 0.913094 25.0838 0.59254C22.8949 -2.3187e-06 14.0702 0 14.0702 0C14.0702 0 5.26524 -2.3187e-06 3.08618 0.59254C1.8734 0.913094 0.92684 1.84562 0.58174 3.05013C1.65621e-07 5.18717 0 9.69437 0 9.69437C0 9.69437 1.65621e-07 14.2307 0.58174 16.3872C0.92684 17.5625 1.8734 18.5145 3.08618 18.8448C5.26524 19.4276 14.0702 19.4276 14.0702 19.4276Z"
          fill="white"
        />
        <path
          id="path1414"
          d="M18.4562 9.7138L11.1709 5.58543V13.8422L18.4562 9.7138Z"
          fill="#090D46"
        />
      </g>
      <g id="g2362">
        <path
          id="path1418"
          d="M36.0755 18.3588V13.055L39.4462 2.03963H36.9303L35.6481 7.04224C35.3567 8.18847 35.0944 9.38327 34.9001 10.4906H34.7447C34.6378 9.51926 34.327 8.24675 34.0162 7.02282L32.7728 2.03963H30.2569L33.579 13.055V18.3588H36.0755Z"
          fill="white"
        />
        <path
          id="path1420"
          d="M42.8381 6.11942C39.8949 6.11942 38.8846 7.81934 38.8846 11.4814V13.2202C38.8846 16.5034 39.516 18.5628 42.7799 18.5628C45.9951 18.5628 46.6848 16.6006 46.6848 13.2202V11.4814C46.6848 8.20789 46.0243 6.11942 42.8381 6.11942ZM44.0912 14.2984C44.0912 15.8915 43.8095 16.892 42.7896 16.892C41.7891 16.892 41.5074 15.8818 41.5074 14.2984V10.374C41.5074 9.01414 41.7016 7.79991 42.7896 7.79991C43.9358 7.79991 44.0912 9.08213 44.0912 10.374V14.2984Z"
          fill="white"
        />
        <path
          id="path1422"
          d="M50.7753 18.5434C52.1935 18.5434 53.0774 17.9508 53.806 16.8823H53.9128L54.0197 18.3588H55.9526V6.35258H53.3883V15.9983C53.1162 16.4743 52.4848 16.824 51.8923 16.824C51.1443 16.824 50.9113 16.2315 50.9113 15.2407V6.35258H48.3564V15.3572C48.3564 17.3097 48.9199 18.5434 50.7753 18.5434Z"
          fill="white"
        />
        <path
          id="path1424"
          d="M61.028 18.3588V4.03096H63.981V2.03963H55.5397V4.03096H58.4927V18.3588H61.028Z"
          fill="white"
        />
        <path
          id="path1426"
          d="M65.9303 18.5434C67.3485 18.5434 68.2324 17.9508 68.961 16.8823H69.0678L69.1747 18.3588H71.1077V6.35258H68.5433V15.9983C68.2713 16.4743 67.6399 16.824 67.0473 16.824C66.2994 16.824 66.0663 16.2315 66.0663 15.2407V6.35258H63.5115V15.3572C63.5115 17.3097 64.0749 18.5434 65.9303 18.5434Z"
          fill="white"
        />
        <path
          id="path1428"
          d="M78.3655 6.09029C77.1221 6.09029 76.2285 6.63426 75.6359 7.51822H75.5096C75.5873 6.35256 75.6456 5.36176 75.6456 4.57494V1.39853H73.1686L73.1589 11.8311L73.1686 18.3588H75.3251L75.5096 17.1931H75.5776C76.1507 17.9799 77.0347 18.4753 78.2101 18.4753C80.1625 18.4753 80.9979 16.7949 80.9979 13.2202V11.3648C80.9979 8.02334 80.6191 6.09029 78.3655 6.09029ZM78.4529 13.2202C78.4529 15.4544 78.1227 16.7851 77.0833 16.7851C76.5976 16.7851 75.9273 16.552 75.6262 16.1149V8.97529C75.8885 8.29532 76.4713 7.79992 77.1221 7.79992C78.1712 7.79992 78.4529 9.07243 78.4529 11.394V13.2202Z"
          fill="white"
        />
        <path
          id="path1430"
          d="M90 11.1706C90 8.2759 89.7086 6.12915 86.3767 6.12915C83.2392 6.12915 82.5398 8.21761 82.5398 11.2871V13.395C82.5398 16.3869 83.1809 18.5628 86.299 18.5628C88.7663 18.5628 90.0388 17.3291 89.8931 14.9395L87.7075 14.823C87.6784 16.2995 87.3384 16.9017 86.3573 16.9017C85.1237 16.9017 85.0654 15.7263 85.0654 13.9779V13.1619H90V11.1706ZM86.3185 7.74164C87.5035 7.74164 87.591 8.85872 87.591 10.7529V11.734H85.0654V10.7529C85.0654 8.87815 85.1431 7.74164 86.3185 7.74164Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
