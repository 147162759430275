import React, { FC } from 'react';
import {
    StyledCard,
    StyledTags,
    StyledTitle,
    StyledHtmlContent,
} from './StyledItem';

const Item: FC = ():JSX.Element => {

    return (
        <StyledCard className='anchor' href='#'>
            <StyledTags className="tags"></StyledTags>
            <StyledTitle className="heading"></StyledTitle>
            <StyledHtmlContent className="html-content"></StyledHtmlContent>
      </StyledCard>
    );
};

export default Item;