import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import {
  aspect,
  avoidPageBreakOnPrint,
  color,
  headingH1,
  headingH4,
  mq,
  subheading2,
} from '_utils/styles';

export const Container = styled.div`
  position: relative;
  width: 100%;

  ${mq('md')} {
    display: flex;
    flex-direction: row-reverse;
  }

  @media print {
    color: ${({ theme }) => theme.profilePageBanner.container.color};
  }
`;

export const TextBannerContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 7 })}
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq('lg')} {
    align-items: center;
    display: flex;
  }

  @media print {
    background-color: ${({ theme }) => theme.profilePageBanner.textBannerContainer.background};
    color: ${({ theme }) => theme.profilePageBanner.textBannerContainer.color};
  }
`;

export const Title = styled.h1`
  ${headingH1}
`;

export const SubTitle = styled.h2`
  ${headingH4}
  color: ${({ theme }) => theme.profilePageBanner.subtitle.color};
`;

export const Blurb = styled.p`
  ${subheading2}
  ${({ theme }) => spacing({ theme, mt: 4 })}
`;

export const BannerImage = styled.div`
  align-self: flex-start;
  display: flex;

  ${mq.lessThan('md')} {
    justify-content: center;
    ${({ theme }) => spacing({ theme, mb: 5 })}
  }

  ${({ theme }) => spacing({ theme, ml: { xs: 0, md: 4 } })}

  ${mq('md')} {
    flex-shrink: 0;
    height: 224px;
    justify-content: flex-end;
    width: 224px;

  }

  ${mq('lg')} {
    height: 304px;
    width: 304px;
  }

  @media print {
    width: 100%;
  }
`;

export const Figure = styled.div`
  display: flex;
  flex-grow: 0;
  height: 224px;
  position: relative;
  width: 224px;

  // allows image to be slightly larger inbetween mobile - tablet
  ${mq.between('sm', 'md')} {
    height: auto;
    width: 45%;
    ${aspect(1, 1)}
  }

  ${mq('md')} {
    height: 100%;
    width: 100%;
  }

  img {
    border-radius: ${(props) => !props.editMode ? '50%' : null};
  }
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const BannerContent = styled.div`
  align-self: flex-start;
  flex-grow: 1;
`;

export const TextBannerContent = styled(Grid.Item)`

  @media print {
    ${({ theme }) => spacing({ theme, py: 4, px: 1 })};
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
