import colors from '_containers/Theme/corporate/colors';

export default {
  background: 'transparent',
  backgroundOverride: {
    Grey: colors.all.neutral50
  },
  divider: {
    color: colors.ui.divider
  }
};

export const darkMode = {
  background: colors.all.brandPrimary3
}
