
import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

import { InternalNavigationProps } from './definitions';

import Icon from '_components/Icon';
import {
  ContextWrapper,
  Description,
  Heading,
  IconDirectionWrapper,
  IconWrapper,
  ListItem,
  NavigationLink,
} from './StyledInternalNavigation';


export const InternalNavigation: React.FC<InternalNavigationProps> = ({
  description,
  editMode,
  title,
  url
}) => {

  return (
    <ListItem editMode={editMode} as='li'>
      <NavigationLink href={url}>
        <ContextWrapper>
          <Heading>
            <Text
              field={title?.jss}
              editable={false}
            />
          </Heading>
          <Description>
            <Text
              field={description?.jss}
              editable={false}
            />
          </Description>
          <IconDirectionWrapper>
            <IconWrapper>
              <Icon name="arrow_forward" ariaHidden />
            </IconWrapper>
          </IconDirectionWrapper>
        </ContextWrapper>
      </NavigationLink>
    </ListItem>
  );
};
