import React from 'react';
import { RecommendedInsightsProps } from './definitions';
import { 
  RecommendedInsightsContainer, 
  ListItem,
  MainTitle 
} from './StyledRecommendedInsights';
import { Carousel } from "_utils/components";
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Item from "./item";
import { useIsMobile } from '_components/StudyPlanner/hooks';
import { BREAKPOINTS } from '_utils/styles/mq';

const renderItem = (items, responsiveColumnConfig, editMode) => {
  return items.map((item, index) => (
    <ListItem
      key={index}
      as={''}
      carouselEnabled={true}
      config={responsiveColumnConfig}
      editMode={editMode}
    >
      <Item {...item} editMode={editMode} />
    </ListItem>
  ))
}


const RecommendedInsights: React.FC<RecommendedInsightsProps> = ({ rendering, editMode }) => {

  const items = rendering?.fields?.data?.datasource?.field?.targetItems || [];
  const responsiveColumnConfig = {
    col: {
      xs: 12,
      md: 12,
      lg: 12,
      xl: 12,
    }
  };

  const isMobileView = useIsMobile(BREAKPOINTS.lg);
  const recommendedForYouLabel = getDictionaryItem('inpractice-recommended-for-you', 'Recommended for you')

  return (
    <RecommendedInsightsContainer>
      <MainTitle as={'h2'}>{recommendedForYouLabel}</MainTitle>
      {isMobileView ? <Carousel key={'MobielCarouselWithAutoPlay'} flickityOptions={{ groupCells: true, cellAlign: 'left', autoPlay: 3000}} hidePrevNextButtonsOnMobile={true}>
        {renderItem(items, responsiveColumnConfig, editMode)}
      </Carousel> : <Carousel key={'DesktopCarousel'} flickityOptions={{ groupCells: true, cellAlign: 'left'}}>
        {renderItem(items, responsiveColumnConfig, editMode)}
      </Carousel>}
    </RecommendedInsightsContainer>
  );
}

export default RecommendedInsights;
