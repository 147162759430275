export const responsiveColumnConfig = (itemLength: number) => {
    const multiColumnSettings = {
        md: {
            1: 12,
            2: 6,
            multi: 6
        },
        lg: {
            1: 12,
            2: 6,
            // if all card types are simple, render as 4 column, else render as 3 column
            multi: itemLength > 3 ? 3 : 4
        },
        xl: {
            1: 12,
            2: 6,
            // if all card types are simple, render as 4 column, else render as 3 column
            multi: itemLength > 3 ? 3 : 4
        },
    };

    return {
        col: {
          xs: 12,
          md: multiColumnSettings.md[itemLength < 3 ? itemLength : 'multi'],
          lg: multiColumnSettings.lg[itemLength < 3 ? itemLength : 'multi'],
          xl: multiColumnSettings.lg[itemLength < 3 ? itemLength : 'multi'],
        }
    };
};