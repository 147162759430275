import { RichText } from '@sitecore-jss/sitecore-jss-react';

import styled, { css } from 'styled-components';


import {
  avoidPageBreakOnPrint,
  caption1,
  caption2,
  fixedBody16,
  fixedBody12,
  fixedBody14,
  fixedBody18,
  fixedTitle12,
  fixedTitle14,
  fixedTitle16,
  fixedTitle18,
  headingH1,
  headingH2,
  headingH3,
  headingH4,
  headingH5,
  headingH6,
  link,
  linkButton,
  linkFeatured,
  mq,
  pageTitle,
  subheading1,
  subheading2,
  textBody1,
  textBody2,
  unorderedList,
} from '_utils/styles/';
import { spacing } from '_utils/props';

export const baseStyles = ({ theme }) => css`
  word-break: break-word;

  /*  When RTE placed without being child of another component
      make all elements render only 10 of 12 columns (91.67%) */
  ${mq('lg')} {
    &:not([class*='fullWidthOnDesktop'])> * {
      margin-right: auto;
      max-width: ${theme.contentRichText.base.maxWidth}%;
    }
  }
`;

export const HeadingContainer = styled.div`
  ${baseStyles}
`;

export const blockStyle = ({ theme }) => css`
  p,
  ul,
  ol,
  dl,
  blockquote,
  table {
    ${spacing({ theme, my: 2 })}

    &:first-child {
      ${spacing({ theme, mt: 0 })}
    }

    &:last-child {
      ${spacing({ theme, mb: 0 })}
    }
  }

  p {
    margin-top: 0;

    ${avoidPageBreakOnPrint}
  }
`;

export const headingMargin = ({ theme }) => css`
  ${spacing({ theme, mt: 5 })};

  &:first-child {
    ${spacing({ theme, mt: 0 })};
  }
`;

export const headingStyle = ({ theme }) => css`
  .pageTitle {
    ${pageTitle};
  }

  .headingH1,
  h1 {
    ${headingH1};
    ${headingMargin}
  }

  .headingH2,
  h2 {
    ${headingH2};
    ${headingMargin}
  }

  .headingH3,
  h3 {
    ${headingH3};
    ${headingMargin}
  }

  .headingH4,
  h4 {
    ${headingH4};
    ${headingMargin}
  }

  .headingH5,
  h5 {
    ${headingH5};
    ${headingMargin}
  }

  /*  only for backwards compatibility, H6 not able to be added
      via RTE anymore */
  .headingH6,
  h6 {
    ${headingH6};
    ${headingMargin}
  }

  .subheading1 {
    ${subheading1};
  }

  .subheading2 {
    ${subheading2};
  }

  .fixed-title12 {
    ${fixedTitle12}
  }
  .fixed-title14 {
    ${fixedTitle14}
  }
  .fixed-title16 {
    ${fixedTitle16}
  }
  .fixed-title18 {
    ${fixedTitle18}
  }
  .caption1 {
    ${caption1};
  }
  .caption2 {
    ${caption2};
  }
`;

export const textStyle = ({ theme }) => css`
  p {
    ${theme.contentRichText.paragraph}
  }

  .text-body1 {
    ${textBody1}
  }

  .fixed-body12 {
    ${fixedBody12}
  }
  .fixed-body14 {
    ${fixedBody14}
  }
  .fixed-body16 {
    ${fixedBody16}
  }
  .fixed-body18 {
    ${fixedBody18}
  }
`;

export const linkStyle = ({ theme }) => css`
  .link,
  a {
    ${link}

    &:visited,
    &:visited:hover {
      background-color: inherit;
    }
  }

  a.button-primary {
    ${linkButton}
  }

  // featured links
  a.link-featured {
    ${linkFeatured}

    &[target=_blank] {
      &:hover {
        text-decoration: underline;
      }

      :after {
        background-size: 18px 18px;
      }
    }
  }

  // external links
  a[target=_blank] {
    display: inline;
    line-height: 20px;
    margin-right: 6px;
    padding-right: 16px;
    position: relative;

      &:not(.link-featured) {
      &:after {
        background-size: 16px 16px;
      }

      &:hover, &:focus {
        &:after{
          background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='16px' fill='%23${({ theme }) => theme.contentRichText.externalLink.icon.hoverColor.replace('#', '')}' %3e%3cpath d='M0 0h24v24H0z' fill='none' /%3e%3cpath d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/%3e%3c/svg%3e");
        }
      }
    }

    // Since in design, no this icon
    ${({hideExternalLinkIcon}) => hideExternalLinkIcon && `
      margin-right: 0px;
      padding-right: 0px;
    `}
   
    &:after {
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='16px' fill='%23${({ theme }) => theme.contentRichText.externalLink.icon.afterColor.replace('#', '')}' %3e%3cpath d='M0 0h24v24H0z' fill='none' /%3e%3cpath d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/%3e%3c/svg%3e");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      content: '';
      height: 100%;
      position: absolute;
      top: -2px;
      transition: background-image 0.1s linear, color 0.1s linear, background-color 0.1s linear;
      width: 20px;

      // Since in design, no this icon
      ${({hideExternalLinkIcon}) => hideExternalLinkIcon && `
        display: none;
      `}
    }
  }
`;

export const blockquoteStyle = ({ theme }) => css`
    blockquote {
      ${theme.contentRichText.blockquote.heading};
      margin-left: 6px;
      margin-right: 0;
      ${spacing({
  theme,
  pt: 2,
  pr: 2,
  pl: {
    xs: `${theme.content.indent.xs}px`,
    md: `${theme.content.indent.md}px`,
  }
})}
      display: block;
      margin-left: 6px;
      position: relative;

      ${mq('md')} {
        margin-left: 12px;
      }

      p {
        ${theme.contentRichText.blockquote.heading};
      }

      > * {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:before {
        ${spacing({ theme, mt: 2 })}
        background-color: ${theme.contentRichText.blockquote.background};
        content: '';
        display: block;
        height: calc(100% - 10px);
        left: 6px;
        position: absolute;
        top: 0;
        width: 4px;

        ${mq('md')} {
          left: 12px;
        }
      }

      + p {
        margin-top: 0;
      }
    }

  figure {
    ${spacing({ theme, ml: 0 })}

    padding-left: 0;

    @media print {
      blockquote {
         border: none;
      }
    }

    ${mq.lessThan('lg')} {
      margin-right: 0;
    }

    figcaption {
      ${subheading2};
      ${spacing({
  theme,
  ml: {
    xs: `${theme.content.indent.xs + 6}px`,
    md: `${theme.content.indent.md + 12}px`
  }
})}
    }
  }
`;

// customise tooltip styling TBC
export const tooltipStyle = ({ theme }) => css`
  span[title],
  a[title] {
    border-bottom: 2px dotted ${theme.colors.element.primary};
  }
`;

export const buttonContainerStyle = ({ theme }) => css`
  .button-container {
    display: flex;
    flex-direction: column;

    > * {
      ${spacing({ theme, mt: { xs: 2, sm: 0 }, ml: { xs: 0, sm: 2 } })}

      &:first-child {
        ${spacing({ theme, mt: { xs: 0 }, ml: { sm: 0 } })}
      }
    }

    ${mq('sm')} {
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const listStyle = ({ theme }) => css`
  li {
    margin-bottom: 12px;
    line-height: 20px;

    &:last-child {
      ${spacing({ theme, mb: 0 })}
    }

    ${mq('md')} {
      line-height: 24px;
    }
  }

  /*
   * unordered list
   */

  ul {
    ${theme.contentRichText.list.textbody}
    ${unorderedList({ theme })}
  }

  /*
   * nesting
   */
  ol,
  ul {
    ol,
    ul {
      left: -6px;
      position: relative;

      &:first-child {
        ${spacing({ theme, mt: 1 })}
      }
      &:last-child {
        ${spacing({ theme, mb: 1 })}
      }
    }
  }

  /* nested ul */
  ol,
  ul {
    ul li::before {
      content: '-';
      font-size: 1.5rem;
      font-family: 'sans-serif';
    }

    p {
      ${spacing({ theme, my: 1 })}
    }
  }

  /*
   * ordered list
   */

  ol {
    
    li {
      ${theme.contentRichText.list.textbody}
      margin-bottom: 12px;
    }

    ${spacing({ theme, ml: 0, my: 2, p: theme.contentRichText.orderedList.padding})}

    > li {
      padding: 0 0 0 16px;

      ${mq('md')} {
        padding-left: 25px;
      }

      ol, ul {
        margin-left: 0;
      }
    }

    li::marker {
      color: ${theme.contentRichText.list.marker.color};
      font-weight: 700;
    }

    &.featured {
      list-style: none;
      ${spacing({ theme, p: '0 0 0 3px'})}
      counter-reset: section;

      ol.featured {
        list-style: none;
        padding-left: 38px;
        counter-reset: section;
      }

      > li {
        margin-bottom: 16px;
        padding: 0 0 0 38px;
        text-indent: 0;
        position: relative;

        ${mq('md')} {
          padding-left: 48px;
        }

        ol.featured li {
          padding: 0;
        }
      }

      > li::before {
        background-color: ${theme.contentRichText.list.background};
        border-radius: 999px;
        border: ${theme.contentRichText.list.border};
        border-color: ${theme.contentRichText.list.borderColor};
        color: ${theme.contentRichText.list.color};
        content: counters(section, '.');
        counter-increment: section;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        height: 28px;
        left: 0;
        line-height:  ${theme.contentRichText.orderedList.lineHeight}px;
        min-width: 28px;
        position: absolute;
        text-align: center;
        text-indent: 0;
        top:  ${theme.contentRichText.orderedList.bullet.top};

        ${mq('md')}{
          font-size: 16px;
          height: 32px;
          left: 0;
          line-height: ${theme.contentRichText.orderedList.md.lineHeight}px;
          top:  ${theme.contentRichText.orderedList.bullet.top};
          width: 32px;
        }
      }

      > li ol.featured li::before {
        font-size: 12px;
        left: -40px;
        letter-spacing: -0.5px;
      }
    }
  }
  /* temp fix for numbered lists  */
  ol.featured[start='1'] {
    counter-reset: section 0;
  }
  ol.featured[start='2'] {
    counter-reset: section 1;
  }
  ol.featured[start='3'] {
    counter-reset: section 2;
  }
  ol.featured[start='4'] {
    counter-reset: section 3;
  }
  ol.featured[start='5'] {
    counter-reset: section 4;
  }
  ol.featured[start='6'] {
    counter-reset: section 5;
  }
  ol.featured[start='7'] {
    counter-reset: section 6;
  }
  ol.featured[start='8'] {
    counter-reset: section 7;
  }
  ol.featured[start='9'] {
    counter-reset: section 8;
  }
  ol.featured[start='10'] {
    counter-reset: section 9;
  }
  ol.featured[start='11'] {
    counter-reset: section 10;
  }
  ol.featured[start='12'] {
    counter-reset: section 11;
  }
  ol.featured[start='13'] {
    counter-reset: section 12;
  }
  ol.featured[start='14'] {
    counter-reset: section 13;
  }
  ol.featured[start='15'] {
    counter-reset: section 14;
  }
  ol.featured[start='16'] {
    counter-reset: section 15;
  }
  ol.featured[start='17'] {
    counter-reset: section 16;
  }
  ol.featured[start='18'] {
    counter-reset: section 17;
  }
  ol.featured[start='19'] {
    counter-reset: section 18;
  }
  ol.featured[start='20'] {
    counter-reset: section 19;
  }

  ol.nested-counter {
    list-style: none;
    padding: 0;
    counter-reset: section;

    li {
      position: relative;
      padding: 4px 0 4px 40px;
    }

    li::before {
      display: inline-block;
      position: absolute;
      content: counters(section, '.') '. ';
      counter-increment: section;
      left: 0;
    }

    ol.nested-counter {
      li::before {
        text-align: right;
        content: counters(section, '.') ' ';
        left: 0;
        transform: translateX(-100%);
      }
    }
  }

  /* definition list */
  dl {
    ${theme.contentRichText.list.textbody}
    margin-bottom: 0;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0;

    ${spacing({
  theme,
  p: 0,
  pl: {
    xs: `40px`,
    md: `48px`
  }
})}

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const tableStyle = ({ theme }) => css`
  .table-scroll-container {
    width: 100%;
    overflow-x: auto;

    table {
      min-width: 540px;
    }
  }

  table {
    border-collapse: collapse;
  }

  thead, tbody tr {
    width: 100%;
  }

  thead th {
    ${headingH6};
    background-color: ${theme.colors.table.head.background};
    color: ${theme.colors.table.head.color};

  }

  tbody {
    tr {
      background-color: ${theme.colors.table.row.background};

      &:nth-child(2n) {
        background-color: ${theme.colors.table.row.backgroundAlt};
      }
    }
  }

  td,
  th {
    border: 1px solid ${theme.colors.table.cell.border};
    padding: 16px;
    text-align: left;
    vertical-align: top;
  }

  th {
    vertical-align: middle;
  }

  td {
    ${textBody2};
  }
`;

export const responsiveEmbedStyle = () => css`
  .responsive-embed {
    position: relative;
    height: 0;
    margin-bottom: 1rem;
    padding-bottom: 75%;
    overflow: hidden;
  }
  .responsive-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .responsive-embed.widescreen {
    padding-bottom: 56.25%;
  }
`;

export const RichTextStyle = css`
  ${baseStyles}
  ${blockStyle}
  ${headingStyle}
  ${textStyle}
  ${linkStyle}
  ${tooltipStyle}
  ${buttonContainerStyle}
  ${listStyle}
  ${tableStyle}
  ${blockquoteStyle}
  ${responsiveEmbedStyle}
`;

export default styled(RichText)`
  ${RichTextStyle}
`;
