import colors from '_containers/Theme/corporate/colors';

export default {
  circle: {
    borderColor: colors.all.brandPrimary1,
    color: colors.all.brandPrimary1,
    completed:{
      background: colors.all.neutralWhite,
      bppAssessment: {
        background: colors.text.dark.default,
        borderColor: colors.all.neutralWhite,
        color: colors.all.brandPrimary3
      }
    },
    active: {
      background: colors.all.brandPrimary1,
      color: colors.all.neutralWhite,
      disabled: {
        background: colors.all.neutralWhite,
        borderColor: colors.all.neutral300,
        color: colors.all.neutral400,
        bppAssessment: {
          background: colors.background.tint3,
          borderColor: colors.background.tint5,
          color: colors.text.nonessential
        }
      }
    }
  },
  leftConnector: {
    background: colors.all.brandPrimary1,
    active: {
      background: colors.all.brandPrimary1
    },
    completed: {
      background: colors.all.brandPrimary1
    },
    disabled: {
      background: colors.all.neutral300
    },
    bppAssessment: {
      active: {
        background: colors.text.dark.secondary
      },
      completed: {
        background: colors.text.dark.secondary
      }
    }
  },
  circleWrapper: {
    background: colors.all.neutralWhite,
    greyBackground: colors.all.neutral50
  },
  labelWrapper: {
    active: {
      color: colors.all.brandPrimary3,
      background: colors.all.neutralWhite,
      greyBackground: colors.all.neutral50
    },
    disabled: {
      color: colors.all.neutral500
    },
    completed: {
      color: colors.all.brandPrimary3
    },
    bppAssessment: {
      color: colors.text.subtle
    }
  },
  iconWrapper: {
    color: colors.all.brandPrimary3,
    bppAssessment: {
      color: colors.messaging.colorError
    }
  }
};
