import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { textBody1, headingH2, headingH3, headingH4, headingH5, headingH6, mq } from '_utils/styles/';

export const Container = styled.div``;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Heading = styled(Text)`
  flex-basis: 100%;
  flex-grow: 1;

  ${mq('md')} {
    flex-basis: initial;
  }

  h2& {
    ${headingH2}
  }

  h3& {
    ${headingH3}
  }

  h4& {
    ${headingH4}
  }

  h5& {
    ${headingH5}
  }

  h6& {
    ${headingH6}
  }
`;

export const Description = styled.p`
  ${({ theme }) => theme.headingWithDescription.descriptionSize}


  // this for some situation that we need to control the maxmium lines
  ${({ reduceBottomPadding }) => {
    if (reduceBottomPadding) {
      return css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3; 
        -webkit-box-orient: vertical;
      `
    }
    return css``;
  }
  }

  
  ${({ theme, reduceBottomPadding }) =>
    spacing({
      theme,
      my: 0,
      mt: reduceBottomPadding ? '4px' : 3,
      mb: reduceBottomPadding ? '8px' : 0,
      pb: reduceBottomPadding ? '0x' : 3
    })}

  > :last-child {
    margin-bottom: 0;
  }
`;
