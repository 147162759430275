// The styles are currently coming from StyledMembershipDetailsForm
// The common syles need to be abstracted to somewhere (possibly in _utils/Forms/... somewhere)
// All that should be in here is contact details form specific styling that isn't shared
import styled from 'styled-components';

import {
  Grid,
  Link,
} from '_utils/components';
import { Field } from '_utils/components/Form/StyledFormSection';
import { spacing } from '_utils/props';
import {
  button,
  color,
  mq,
} from '_utils/styles';

export const EditLink = styled(Link)`
  display: inline-block;
  margin-left: 16px;

  a {
    color: ${({ theme }) => theme.contactDetailsForm.color};
    text-decoration: none;
  }

  svg {
    margin-left: 10px;
  }
`;

export const AddressDropdownContainer = styled(Field)`
  margin-top: -10px;

  ${mq('sm')} {
    margin-top: 0;
  }
`;

export const BackToRenewalFormContent = styled(Grid.Item)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const CTAButtons = styled.div`
  display: flex;

  ${({ theme }) => spacing({
    theme,
    px: {
      xs: `${theme.grid.gutter.size.xs}px`,
      md: 0,
    },
  })}
`;

export const BackToRenewalFormButton = styled.button`
  ${button('slim', 'primary')};

  align-items: center;
  display: inline-flex;
  justify-content: center;

  ${mq('md')} {
    ${button('default', 'primary')};

    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  ${mq.lessThan('md')} {
    flex: 1;
  }
`;

export const BackToRenewalFormButtonLabel = styled.span`
  display: flex;

  svg {
    margin-right: 6px;
  }
`;