import styled from 'styled-components';
import { button, showOnPrint, mq } from '_utils/styles';
import { Grid } from '_utils/components';

export const RecommendedInsightsContainer = styled.div`
  position: relative;
  width: 100%;
  .flickity-viewport {
    height: 344px !important;
  }

  ${mq.lessThan('lg')} {
    .flickity-viewport {
      height: 500px !important;
    }
  }

`;

export const RecommendedInsightsButton = styled.button`
  ${button('default', 'primary')}
  align-items: center;
  display: inline-flex;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);

  svg {
    margin-left: 10px;
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
  }
`;

export const MainTitle = styled.div`
  h2& {
    margin-top: 0;
    ${mq('md')} {
      font-size: 32px;
      line-height: 38px;
    }

    ${mq.lessThan('md')} {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.is-hidden {
    display: none;
    ${showOnPrint}
  }

  @media print {
    display: inline-block !important;
    margin:0;
    width: 33%;
  }
`;

