import { canUseDOM } from 'exenv';
import qs from 'query-string';
import { keys, omit } from 'ramda';

import { ANALYTICS_QS_KEY } from '_containers/DigitalDataContext/definitions';

// setQueryStringWhithoutPageReload: replaces the current query string value
// with the new provided value without triggering a page refresh.
export const setQueryStringWithoutPageReload = (qsValue) => {
  if (!canUseDOM) {
    return;
  }

  const newurl =
    `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

// setQueryStringValue: updates the query string parameters only for the
// provided key, keeping the remaining parameters intact.
export const setQueryStringValue = (key, value, queryString = undefined, options) => {
  if (!canUseDOM) {
    return;
  }

  if (queryString === undefined) {
    queryString = window.location.search;
  }

  if (value === '') value = undefined;
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value }, options);
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

// getQueryStringValue: returns the query string value for the provided key.
export const getQueryStringValue = (key, queryString = undefined) => {
  if (!canUseDOM) {
    return;
  }

  if (queryString === undefined) {
    queryString = window.location.search;
  }

  const values = qs.parse(queryString);
  return values[key];
};

// deleteKeyFromQueryString: removes the key from query string
export const deleteKeyFromQueryString = (key, queryString = undefined) => {
  if (!canUseDOM || !key) {
    return;
  }

  if (queryString === undefined) {
    queryString = window.location.search;
  }

  const qsObj = qs.parse(queryString);

  if (keys(qsObj)?.length && keys(qsObj).indexOf(key) >= 0) {
    const newqsObj = omit(['ref'], qsObj);
    const newQsValue = qs.stringify(newqsObj);
    setQueryStringWithoutPageReload(`?${newQsValue}`);
  }
};

export const convertStringToKebabCase = (str: string) => str.toLowerCase().replace(/ /g, '-');

export const appendAnalyticsTrackingQueryString = (link, trackingName) => {

  const { href, url, querystring } = link;
  const urlPath = href || url;

  if (!urlPath) {
    return;
  }

  const mergedQueryStringWithTracking = qs.stringify({
    [ANALYTICS_QS_KEY]: convertStringToKebabCase(trackingName),
    ...qs.parse(querystring)
  });

  const trackedURL =
    mergedQueryStringWithTracking ?
      urlPath.concat('?', mergedQueryStringWithTracking)
      : urlPath;

  return trackedURL;
};

export const convertHashToObject = (hash: string) =>
  hash.split("&")
    .map(v => v.split("="))
    .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});


