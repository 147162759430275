import * as yup from 'yup';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  renderInvalidMinLengthError,
  renderRequiredError
} from '_utils/helpers/form';
import moment from 'moment-mini';

const isDateGreaterThan1800 = (value) => {

  if(!value) {
    return false;
  }

  const [day, month, year] = value.split('/');
  const adjustedValue = `${month}/${day}/${year}`;
  const minDate = new Date('1800-01-01'); // Based on CE, there is a min date 1753, we use 1800
  const inputDate = new Date(adjustedValue);

  return inputDate > minDate;
};


export const validationSchema = (initEducationName, educationRef) => yup.object().shape({
  cpaAustraliaId: yup.number(),
  employment: yup.object({
    jobTitle: yup.string()
      .min(3, renderInvalidMinLengthError(getDictionaryItem('form-career-position', 'Position'), 3)),
    jobFunction: yup.string().when('isEmployed', {
      is: 'true',
      then: yup.string().required(renderRequiredError(getDictionaryItem('form-career-job-function', 'Primary job function')))
    }),
    industry: yup.string(),
    currentEmploymentStartDate: yup.string().nullable().when('isEmployed', {
      is: 'true',
      then: yup.string().required('Start date in this role is required')
        .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Date is required and its format must be DD/MM/YYYY')
        .test('is-date-valide', 'Date is not valid',  value => moment(value, 'DD/MM/YYYY', true).isValid())
        .test('is-greater-than-1800', 'Date is required and must be greater than 01/01/1800', isDateGreaterThan1800)
        
    }),
    isEmployed: yup.string()
      .oneOf(['true', 'false'], renderRequiredError(getDictionaryItem('form-career-currently-employed-entry', 'Are you currently employed?')))
      .nullable(),
    isNewCompany: yup.boolean(),
    careerStage: yup.number().nullable().transform((value, originalValue) =>
      originalValue === null ? undefined : value
    ).required('Career Stage is a required field'),
    currentCareerSector: yup.string().nullable().transform((value, originalValue) =>
      originalValue === null ? undefined : value
    ).required('Current Career sector is a required field'),
    currentCareerIndustry: yup.string().nullable().transform((value, originalValue) =>
      originalValue === null ? undefined : value
    ).required('Current Career industry is a required field'),
    company: yup.object().nullable().when('isNewCompany', (isNewCompany, schema) => {
      if (isNewCompany) {
        return schema.shape({
          name: yup.string().required(renderRequiredError(getDictionaryItem('form-career-company-name', 'Company name'))),
          websiteUrl: yup.string(),
          country: yup.object({
            isoCode2Letter: yup.string()
              .required(renderRequiredError(getDictionaryItem('form-career-company-region', 'Company region')))
          }),
          // industry: yup.object({
          //   name: yup.string()
          //     .required(renderRequiredError(getDictionaryItem('form-career-industry', 'Industry'))),
          //   code: yup.string()
          //     .required(renderRequiredError(getDictionaryItem('form-career-industry', 'Industry')))
          // }),
          // sector: yup.object({
          //   name: yup.string()
          //     .required('Employer sector is required'),
          //   code: yup.string()
          //     .required('Employer sector is required')
          // }),
        });
      }
      return schema.shape({
        id: yup.number().nullable().required(renderRequiredError(getDictionaryItem('form-career-company-name', 'Company name'))),
      });
    })
  }),
  education: yup.object({
    highestEducationName: yup.string().test('is-higherEducation', getDictionaryItem('form-career-error-highest-education-existed', 'Our records indicate that you have a higher level of education than the one you have chosen. If you believe your record is incorrect, please contact us for assistance.'), (value) => {

      // When the value is '', all good
      if (!initEducationName) { return true }

      // if have value recorded, then comparing
      const initItemRanking = educationRef?.find(item => item.name === initEducationName)?.ranking
      const currentRanking = educationRef?.find(item => item.name === value)?.ranking
      if (initItemRanking > currentRanking) {
        return false
      }
      return true

    })
  }),
  licences: yup.object({
    items: yup.array().of(yup.object({
      licenceName: yup.string().test('licenceName should not be empty', renderRequiredError(getDictionaryItem('form-career-license-type', 'Licence Type')), (value) => {
        if (!value) {
          return false
        }
        return true;
      }),
      registrationNumber: yup.string().nullable()
    })).test('no-duplicates', getDictionaryItem('form-career-license-error-duplicate', 'Duplicated Licence Type found'), (values) => {
      const nameSet = new Set();
      for (let i = 0; i < values.length; i++) {
        if (nameSet.has(values[i].licenceName)) {
          return false;
        }
        nameSet.add(values[i].licenceName);
      }
      return true;
    })
  }),
  professionalInterests: yup.object({
    items: yup.array()
      .min(1, renderRequiredError(getDictionaryItem('form-career-section-professional-interests', 'Professional interests')))
  }),
  specialityAreas: yup.object({
    items: yup.array()
  })
});