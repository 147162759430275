import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, { useRef } from 'react';

import { withEditMode, withThemeOption } from '_containers/BaseComponent';
import { excludeThemeColors } from '_containers/Theme/global';
import { Section } from '_utils/components';
import Grid from '_utils/components/Grid';
import { usePlaceholderHasChildren } from '_utils/hooks';
import { getThemeClasses } from '_utils/props/theme';
import { PlaceholderSingleColumnProps } from './definitions';

import { Wrapper } from './StyledPlaceholderSingleColumn';

const PlaceholderSingleColumn: React.FC<PlaceholderSingleColumnProps> = ({ rendering, editMode }) => {
  const isEmpty = (rendering?.placeholders?.['jss-components-single-column'] ?? []).length === 0;
  const isEmptyClassName = !editMode && isEmpty ? 'is-empty' : null;

  const sectionTheme =
    (rendering?.params?.placeholderTheme &&
      excludeThemeColors.indexOf(rendering?.params?.placeholderTheme.toLowerCase()) === -1)
      ? rendering?.params?.placeholderTheme.toLowerCase()
      : '';

  const backgroundColor = rendering?.params?.backgroundColor;

  const defaultClassName = 'default';

  const staticClassArray = [];

  if (backgroundColor) {
    staticClassArray.push(backgroundColor.toLowerCase());
  }

  if (isEmptyClassName) {
    staticClassArray.push(isEmptyClassName);
  }

  if (sectionTheme) {
    staticClassArray.push(sectionTheme);
  }

  if (staticClassArray.length === 0) {
    staticClassArray.push(defaultClassName);
  }

  const staticClasses = staticClassArray.join(' ');

  const rowRef = useRef<HTMLElement>(null);

  usePlaceholderHasChildren(rowRef)

  return (
    <Section
      backgroundColor={backgroundColor}
      className={`ph ph-sc ${getThemeClasses(rendering?.params)} ${staticClasses}`}
    >
      <Grid.Container>
        <Grid.Row justifyContent="center" config={{ gutters: ['left', 'right'] }}>
          <Wrapper config={{ gutters: ['left', 'right'] }} className='row' ref={rowRef}>
            <Placeholder name="jss-components-single-column" rendering={rendering} />
          </Wrapper>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export default compose(withThemeOption, withEditMode)(PlaceholderSingleColumn);
