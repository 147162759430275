import colors from '_containers/Theme/corporate/colors';

export default {
  backgroundEven: colors.all.neutralWhite,
  backgroundHover: colors.all.neutral100,
  backgroundOdd: colors.all.neutral50,
  colorHeading: colors.all.brandPrimary3,
  colorLink: colors.all.interactive500,
  paddingUnit: 0
};

export const darkMode = {
  paddingUnit: 2
}

export const columnType = {
  type1: 'date',
  type2: 'description',
  type3: 'link',
};
