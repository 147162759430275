import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, { forwardRef } from 'react';

import NavItem from '_components/NavItem';
import Icon from '_components/Icon';
import { withMenuContext } from '_containers/MenuContext';
import { warnInDev } from '_utils/helpers/dev';
import { isValidNavLink } from '_utils/validationChecks';

import {
  MenuItemHandlerProps,
  MenuItemProps
} from './definitions';

import {
  Item,
  Label,
  LabelWrapper,
  Link,
  LinkEndIcon,
} from './StyledMainMenu';

const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
  ({
    item,
    menuActive,
    onBlur,
    onFocus,
    onItemClick,
    tabIndex,
  }, ref) => {
    if (!item) {
      return null;
    }

    let label = item.title?.value || item?.link?.text || '';

    // nullify links without urls
    const link = isValidNavLink(item) ? item.link : null;

    // only create handlers if we have a link
    const handlers: MenuItemHandlerProps = link
      ? {
        'aria-hidden': !menuActive,
        'aria-expanded': menuActive,
        onBlur,
        onClick: onItemClick,
        onFocus,
        onKeyDown: () => { },
        tabIndex: tabIndex,
        target: item?.link?.target || ''
      }
      : null;

    return (
      <NavItem {...handlers} ref={ref} link={link} StyledLink={Link}>
        <LabelWrapper>
          {label && (
            <Label>{label}</Label>
          )}
        </LabelWrapper>
        <LinkEndIcon showOnHover={true}>
          <Icon name="arrow_forward" ariaHidden={true} />
        </LinkEndIcon>
      </NavItem>
    );
  }
);

const MenuItemWrapper = forwardRef<HTMLElement, any>(
  ({
    enabledItemRefs,
    enabledItemsIndex,
    item,
    keyPrefix,
    menuActive,
    onBlur,
    onItemClick,
    onItemFocus,
  }, ref) => {
    if (!item) {
      return null;
    }
    const currentRef = enabledItemRefs?.current[enabledItemsIndex];

    if (!currentRef) {
      warnInDev(`Link does not have valid url: ${item?.title?.value}`);
      return null;
    }

    const classList = [];

    if (item?.className) {
      classList.push(item.className);
    }

    return (
      <React.Fragment key={`fragment-${keyPrefix}`}>
        <Item
          className={classList.join(' ')}
          key={`menu-${keyPrefix}`}
        >
          <MenuItem
            item={item}
            menuActive={menuActive}
            onBlur={onBlur}
            onFocus={(e) => {
              onItemFocus(e, enabledItemsIndex, -1)
            }}
            onItemClick={onItemClick}
            ref={ref}
            tabIndex={menuActive ? 0 : -1}
          />
        </Item>
      </React.Fragment>
    );
  },
);

const MenuItemList = ({
  enabledItemRefs,
  getEnabledItemsIndex,
  keyPrefix,
  menuActive,
  menuItems,
  onItemBlur,
  onItemClick,
  onItemFocus,
}) => menuItems.map((item, index) => {

  const enabledItemsIndex = getEnabledItemsIndex(item);

  const enabledItemProps =
    enabledItemsIndex > -1
      ? {
        ref: enabledItemRefs?.current[enabledItemsIndex]?.uid,
        onItemFocus: onItemFocus,
        onBlur: onItemBlur
      }
      : {};

  return (
    <MenuItemWrapper
      enabledItemRefs={enabledItemRefs}
      enabledItemsIndex={enabledItemsIndex}
      item={item}
      key={`${keyPrefix}-${index}`}
      keyPrefix={`${keyPrefix}-${index}`}
      menuActive={menuActive}
      onItemClick={onItemClick}
      {...enabledItemProps}
    />
  );
});

export default compose(withSitecoreContext(), withMenuContext)(MenuItemList);

