import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';

export const announcementsValidators: SitecoreFieldValidator[] = [
  {
    field: 'fields.data.datasource.children',
    errorMessage: 'A title is required for all announcements.',
    allowRenderOnError: true,
    validators: [
      (field) => field?.title?.jss?.value,
    ],
  },
];
