import colors from '_containers/Theme/corporate/colors';

export default {
  link: {
    minWidth: {
      md: '280px',
    }
  },
  title: {
    color: colors.text.interactive1,
    colorHover: colors.text.interactive1Hover,
    colorVisited: colors.text.visited,
    fontWeight: 'normal',
  },
  icon: {
    color: colors.text.interactive1,
    colorHover: colors.text.interactive1Hover,
  },
  subtitle: {
    color: colors.text.subtle
  }
};
