import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '_components/Icon';
import { BaseComponentButtonProps } from './definitions';
import { BaseComponentContainer, BaseComponentButton } from './StyledBaseComponent';

const BaseComponent: React.FC<BaseComponentButtonProps> = ({rendering}) => {
  const [t] = useTranslation();
  const name = rendering.fields?.data?.button?.name;
  return (
    <BaseComponentContainer>
      <BaseComponentButton aria-label={t('page-button-label')}>
        { name }
        <Icon ariaHidden={true} name="arrow_right" />
      </BaseComponentButton>
    </BaseComponentContainer>
  );
}

export default BaseComponent;
