import { useEffect, useState } from 'react';
import { canUseDOM } from 'exenv';


const getIsMobile = (size: number) => {
  if(!canUseDOM) {
    return;
  }
  return window.innerWidth < size;
}

export function useIsMobile(size: number) {
  const [isMobile, setIsMobile] = useState(getIsMobile(size));
  useEffect(() => {
    if (!canUseDOM) {
      return;
    }
    const handleResize = () => setIsMobile(getIsMobile(size));
    window.addEventListener('resize', handleResize, { passive: false });
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}