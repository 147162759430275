import { setAnchorElemScrollMarginTop } from "../setAnchorElemScrollMarginTop";

export const scrollToElement = (targetEl?: HTMLElement) => {
  if (!document.location.hash) {
    return
  }

  const cleanLocationId = document.location.hash.replace('#', '');
  const elemToScroll = targetEl || document.getElementById(cleanLocationId);

  if (elemToScroll) {
    // Apply scroll-margin-top on the elemToScroll item that is being covered by the fixed header and breadcrumb
    setAnchorElemScrollMarginTop(elemToScroll);
    setTimeout(() => {
      elemToScroll.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300)
  }
}
