import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '_components/Icon';

import {
  LiveChatOfflineProps,
  LiveChatOfflineMessageProps,
  LIVE_CHAT_OFFLINE_BUTTON_ID,
  LIVE_CHAT_OFFLINE_MESSAGE_ID,
} from './definitions';

import {
  LiveChatOfflineButton,
  LiveChatOfflineContainer,
  LiveChatOfflineIcon,
  LiveChatOfflineText,
  MessageBody,
  MessageCloseButton,
  MessageContainer,
  MessageHeader,
  MessageIcon,
  MessageTitle,
  MessageTitleContainer,
} from '_components/LiveChat/StyledLiveChat';

const LiveChatOfflineMessage: React.FC<LiveChatOfflineMessageProps> = ({
  message,
  toggleIsOpen,
  closeLabel,
}) => {
  return (
    <MessageContainer id={LIVE_CHAT_OFFLINE_MESSAGE_ID}>
      <MessageHeader>
        <MessageTitleContainer>
          <MessageIcon>
            <Icon name="question_answer" />
          </MessageIcon>
          <MessageTitle>
            Live Chat
          </MessageTitle>
        </MessageTitleContainer>
        <MessageCloseButton
          aria-label={closeLabel}
          onClick={toggleIsOpen}
        >
          <Icon name="close" />
        </MessageCloseButton>
      </MessageHeader>
      <MessageBody dangerouslySetInnerHTML={{ __html: message }} />
    </MessageContainer>
  );
};

const LiveChatOffline: React.FC<LiveChatOfflineProps> = ({
  datasource,
  shouldHideLiveChat,
}) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const openLabel = t("live-chat-offline-open-accessible-label");
  const closeLabel = t("live-chat-offline-close-accessible-label");

  return (
    <LiveChatOfflineContainer
      id={LIVE_CHAT_OFFLINE_BUTTON_ID}
      className={shouldHideLiveChat ? 'hidden' : ''}
    >
      {
        isOpen && (
          <LiveChatOfflineMessage
            closeLabel={closeLabel}
            message={datasource?.offlineMessage}
            toggleIsOpen={toggleIsOpen}
          />
        )
      }
      <LiveChatOfflineButton
        $isOpen={isOpen}
        aria-label={isOpen ? closeLabel : openLabel}
        aria-haspopup="true"
        aria-expanded={`${!!isOpen}`}
        aria-controls={LIVE_CHAT_OFFLINE_MESSAGE_ID}
        onClick={toggleIsOpen}
      >
        <LiveChatOfflineIcon>
          {
            isOpen
              ? <Icon name="expand_more" />
              : <Icon name="question_answer" />
          }
        </LiveChatOfflineIcon>
        {
          !isOpen && (
            <LiveChatOfflineText>
              Live Chat
            </LiveChatOfflineText>
          )
        }
      </LiveChatOfflineButton>
    </LiveChatOfflineContainer>
  );
};

export default LiveChatOffline;
