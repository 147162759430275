import styled from 'styled-components';
import ReactARIAToolTip from "react-aria-tooltip";

import {
  buttonReset,
  depth,
  fixedBody12,
} from '_utils/styles';

export const Button = styled.button`
  ${buttonReset}
  color: ${({ theme }) => theme.tooltip.color};
  outline: 0;
  position:relative;
  top:-2px;

  body:not(.safe-focus-removal) &:focus {
    /* psuedo focus outline */
    &:before {
      border-radius: 3px;
      border: 2px solid ${({ theme }) => theme.tooltip.borderColorFocus};
      content: '';
      height: calc(100% + 1px);
      left: 4px;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  svg {
    width: 16px;
    color: ${({ theme }) => theme.tooltip.svgColor};
  }
 `;

export const StyledReactARIAToolTip = styled(ReactARIAToolTip)`

  // Tooltips overrides for react-aria-tooltip
  .ra-tooltip-wrapper {
    display: inline-flex !important;
    height: 14px;
  }

  .ra-tooltip {
    z-index: ${depth.TOOL_TIP};
    background: transparent !important;
    max-width: 200px;
    width: max-content;

    &.top {
      transform: translate(-50%,-104%) !important;
    }

    .ra-tooltip-message {
      ${fixedBody12}
      background: ${({theme }) => theme.tooltip.background};
      border-radius: 4px;
      box-shadow: ${({ theme}) => theme.shadows.elevation16};
      color: ${({ theme }) => theme.tooltip.color};
      padding: 8px 16px;

      p {
        margin-bottom: 0;
        text-align: center;
        white-space: initial;
      }

      // notch on bottom
      &:after {
        border-top-color: ${({ theme }) => theme.tooltip.background} !important;
      }
    }
  }
`
