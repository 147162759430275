import React from 'react';
import { CoveoNoIndexWrapper } from '_containers/BaseComponent';
import { Spinner } from '_utils/components';

import {
  Container,
} from './StyledSpaLoader';

const SpaLoader: React.FC = () => (
  <CoveoNoIndexWrapper>
    <Container id="spa-loader">
      <Spinner showLoadingLabel={true} />
    </Container>
  </CoveoNoIndexWrapper>
);

export default SpaLoader;
