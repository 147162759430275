import colors from '_containers/Theme/corporate/colors';

export default {
  backgroundColor: colors.all.brandPrimary3,
  color: colors.all.neutralWhite,
  iconColor: colors.all.brandPrimary2,
  normal: {
    borderRadius: 0,
  },
  nextBestAction: {
    borderRadius: 8,
  },
  linkWrapper: {
    color: colors.all.neutralWhite
  },
  linkButton: {
    pathFillColor: colors.all.neutralWhite
  },
  outline: {
    focusColor: colors.all.neutralWhite
  }
};