import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Grid } from '_utils/components';
import { ImageHeight16by9, ImageSize } from '_utils/types/enums';
import { createSrcSet } from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';

import { BannerProps } from './definitions';
import ReadingTime from './ShowcaseHeroReadingTime';

import {
  BannerArticle,
  BannerArticleHeader,
  BannerArticleImage,
  BannerArticleRow,
  BannerContainer,
  BannerFeaturedArticle,
  BannerFeaturedArticleImage,
  BannerFeaturedArticleImageIECutout,
  BannerFeaturedArticleImageWrapper,
  BannerFeaturedArticleText,
  CategoryText,
  HeroHeadline,
  Image,
  SubArticleSummary,
} from './StyledShowcaseHero';

const Banner: FC<BannerProps> = ({
  editMode,
  handleAnalytics,
  items,
  uid,
}): JSX.Element => {
  const [featuredArticleItem, ...otherArticleItems] = items;

  return (
    <BannerContainer
      id={uid}
      className="showcase-hero"
      data-component
    >
      <Grid.Container>
        <Link to={featuredArticleItem?.url}>
          <BannerFeaturedArticle
            alignItems="center"
            config={{ gutters: [] }}
            onClick={() => handleAnalytics('itb-homepage-showcase-hero-1', featuredArticleItem?.url)}
          >

            <BannerFeaturedArticleImageWrapper
              config={{
                col: { md: 8, xs: 12 },
                gutters: [],
              }}
              alignSelf="flex-start"
            >
              <BannerFeaturedArticleImage>
                <Image
                  className="lazyload"
                  draggable={false}
                  field={
                    editMode
                      ? featuredArticleItem?.image
                      : {
                        value: {
                          src: imagePlaceholderSrc,
                          alt: featuredArticleItem?.image?.value?.alt ?? '',
                          'data-srcset': createSrcSet(featuredArticleItem?.image?.value?.src,
                            [
                              ImageSize.MW_384,
                              ImageSize.MW_512,
                              ImageSize.MW_768
                            ])
                        }
                      }
                  }
                  height={ImageHeight16by9.MW_384}
                  width={ImageSize.MW_384}
                />
                <BannerFeaturedArticleImageIECutout />
              </BannerFeaturedArticleImage>
            </BannerFeaturedArticleImageWrapper>

            <BannerFeaturedArticleText
              config={{
                col: { md: 4, xs: 12 },
                gutters: [],
              }}
              alignSelf="flex-start"
            >
              <HeroHeadline className="headline">
                <Text field={featuredArticleItem?.headline} />
              </HeroHeadline>

              <ReadingTime {...featuredArticleItem?.readingTime} />

              <CategoryText>
                {featuredArticleItem?.categoryInformation?.categoryLabelText}
              </CategoryText>
            </BannerFeaturedArticleText>

          </BannerFeaturedArticle>
        </Link>
        <BannerArticleRow
          justifyContent="flex-end"
          config={{ gutters: [] }}
        >
          {
            otherArticleItems.slice(0, 3).map((item, i) => {

              return (
                <BannerArticle
                  key={i}
                  config={{
                    col: { md: 4, xs: 12, lg: 3 },
                    gutters: [],
                  }}
                  alignSelf="flex-start"
                  // '+2' for analytics because featured (above) = 1
                  // `i` is zero-based - we actually want 2,3,4
                  onClick={() => handleAnalytics(`itb-homepage-showcase-hero-${i + 2}`, item?.url)}
                >
                  <Link to={item?.url}>
                    <BannerArticleImage>
                      <Image
                        className="lazyload"
                        draggable={false}
                        field={
                          editMode
                            ? item?.image
                            : {
                              value: {
                                src: imagePlaceholderSrc,
                                alt: item?.image?.value?.alt ?? '',
                                'data-srcset': createSrcSet(item?.image?.value?.src, [ImageSize.MW_384, ImageSize.MW_512])
                              }
                            }
                        }
                      />
                    </BannerArticleImage>

                    <SubArticleSummary>
                      <BannerArticleHeader className="headline">
                        <Text field={item?.headline} />
                      </BannerArticleHeader>
                      <ReadingTime {...item.readingTime} />
                      <CategoryText>
                        {item?.categoryInformation?.categoryLabelText}
                      </CategoryText>
                    </SubArticleSummary>
                  </Link>
                </BannerArticle>
              )
            })
          }
        </BannerArticleRow>
      </Grid.Container>
    </BannerContainer>
  );
};

export default Banner;
