import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  withCoveoNoIndexTags,
  withEditMode,
  withSitecoreContext,
} from '_containers/BaseComponent';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  useAdSlot,
  useBreakpoint,
  useMutationObserver,
} from '_utils/hooks';

import { adConfig } from "./config";
import { AdProps } from "./definitions";

import {
  AdContainer,
  AdLabel,
  Container,
  PlaceholderContainer,
} from './StyledAd';

const Ad: React.FC<AdProps> = (
  {
    adSlotID,
    editMode,
    keywords,
    position,
    sitecoreContext,
    uid,
  }) => {
  const advertisementLabel = getDictionaryItem('advertisement-label', 'Advertisement');
  if (!(adConfig?.[position] && adSlotID)) {
    return null;
  }

  const [showAdLabel, setShowAdLabel] = useState(false);
  const adRef = useRef<HTMLElement>(null);
  const bp = useBreakpoint();

  const ad = adConfig[position];

  // bind hook only on initial render
  useEffect(() => {
    useAdSlot({
      mapping: ad.mapping,
      sizes: ad.sizes,
      adSlotID: adSlotID,
      position: position,
      template: sitecoreContext?.route?.templateName ?? '',
      keywords: keywords,
      uid: uid,
    });
  }, []);

  // refresh ad slots when breakpoint changes to ensure correct dimension ads
  useEffect(() => {
    if (showAdLabel && canUseDOM && typeof window !== 'undefined') {
      const googletag = window.googletag || (window.googletag || { cmd: [] });
      googletag.cmd.push(function () {
        const slot = googletag.pubads().getSlots().filter(slot => slot.getSlotElementId() === uid);
        if (slot) {
          googletag.pubads().refresh(slot);
        }
      });
    }

  }, [bp]);

  const handleMutations = useCallback((mutations) => {
    mutations.forEach(
      ({
        type,
        target
      }: {
        type: MutationRecordType;
        target: Element | null;
      }) => {
        if (type === 'childList') {
          setShowAdLabel(!!target?.getElementsByTagName('iframe')?.length);
        }
      }
    );
  }, []);

  useMutationObserver({
    target: adRef,
    options: { childList: true, subtree: true },
    callback: handleMutations
  });

  return (
    <Container
      className="advertisement"
      data-component
    >
      {
        editMode ?
          <PlaceholderContainer>Advertisment <span>{position}</span> goes here </PlaceholderContainer>
          :
          <>
            <AdContainer id={uid} ref={adRef} />
            {showAdLabel && <AdLabel>{advertisementLabel}</AdLabel>}
          </>
      }
    </Container>
  );
}

export default compose(
  withCoveoNoIndexTags,
  withEditMode,
  withSitecoreContext()
)(Ad);

