export function stripHTML(string) {
  if (!string) {
    return '';
  }
  if (typeof document !== 'undefined') {
    try {
      const div = document.createElement('div');
      div.innerHTML = string;
      return div.textContent || div.innerText || '';
    } catch (err) {
      return '';
    }
  }
}

// Wraps bold tags around matching substring fragments
// TODO add unit tests
export const highlightSubstring = (searchTerm: string, searchPhrase: string) => {
  if (!searchTerm && !searchPhrase) {
    return;
  }
  if (!searchTerm) {
    return searchPhrase;
  }
  return searchPhrase.replace(new RegExp(searchTerm, "gi"), (match) => `<b>${match}</b>`)
}
