import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';

import { Grid } from '_utils/components';

import { spacing } from '_utils/props';
import {
  aspect,
  avoidPageBreakOnPrint,
  fixedTitle14,
  fixedTitle16,
  headingH2,
  mq,
} from '_utils/styles';

export const BannerContainer = styled.div`
  overflow: hidden;
  ${({ theme }) => spacing({ theme, pb: 6 })}

  & a {
    text-decoration: none;

    &:hover .headline {
      text-decoration: underline;
    }
  }

  @media print {
    background-color: ${({ theme }) => theme.colors.background.tint1};
    color: ${({ theme }) => theme.colors.element.neutralBlack};
  }
`;

export const BannerFeaturedArticle = styled(Grid.Row)`
  text-decoration: none;

  ${({ theme }) => spacing({ theme, mb: { md: 3, xs: 5 } })}
`;

export const BannerFeaturedArticleImageWrapper = styled(Grid.Item)`
  ${({ theme }) => spacing({ theme, mb: { md: 0, xs: 3 } })}

  @media print {
    max-width: 50% !important;
  }
`;

export const BannerFeaturedArticleImage = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  ${mq('lg')} {
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 95px),
      37.5% calc(100% - 95px),
      37.5% 100%,
      0 100%
    );
  }
`;

// IE Only - Display a black background for desktop as clip-path is not supported
export const BannerFeaturedArticleImageIECutout = styled.div`
  @media all and (min-width: 1024px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    bottom: 0;
    height: 90px;
    position: absolute;
    right: 0;
    width: 62%;
  }
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const HeroHeadline = styled.h2`
  ${headingH2}
  color: ${({ theme }) => theme.showcaseHero.headline.colour};
  margin-bottom: 0;

  /* mq specificity required to override default heading margin-bottom */
  ${mq('md')} {
    margin-bottom: 0;
  }
`;

export const CategoryText = styled.p`
  color: ${({ theme }) => theme.showcaseHero.category.colour};
  font-size: 12px;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 6px;
  text-transform: uppercase;
`;

export const BannerFeaturedArticleText = styled(Grid.Item)`
  ${({ theme }) => spacing({ theme, pl: { md: 3, xs: 0 } })}
`;

export const BannerArticle = styled(Grid.Item)`
  & a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
  }

  ${({ theme }) => spacing({ theme, mb: { md: 0, xs: 3 }, pl: { lg: 3, xs: 0 } })}

  ${mq('md')} {
    & a {
      flex-direction: column;
    }
  }

  ${mq.lessThan('md')} {
    border-top: ${({ theme }) => `1px solid ${theme.showcaseHero.bannerArticle.borderTopColour}`};
    padding-top: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${avoidPageBreakOnPrint}
`;

export const BannerArticleRow = styled(Grid.Row)`
  ${mq('md')} {
    ${BannerArticle} + ${BannerArticle} {
      ${({ theme }) => spacing({ theme, pl: { md: 3, xs: 0 } })}
    }
  }

  @media all and (min-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${BannerArticle} + ${BannerArticle} {
      padding-left: 24px;
    }
  }

  ${mq('lg')} {
    margin-top: -95px;
  }

`;

export const BannerArticleHeader = styled.div`
  ${fixedTitle14}
  color: ${({ theme }) => theme.showcaseHero.headline.colour};
  flex: 1;

  ${mq('md')} {
    ${fixedTitle16}
  }
`;

export const BannerArticleImage = styled.div`
  overflow: hidden;

  ${({ theme }) => spacing({ theme, mb: { md: 2, xs: 0 } })}

  ${mq('md')} {
    align-self: auto;
    height: 100%;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }

  ${mq.lessThan('md')} {
    ${aspect(84, 47)}
    align-self: flex-start;
    flex-shrink: 0;
    height: 100%;
    width: 33%;
  }
`;

export const SubArticleSummary = styled.div`
  ${({ theme }) => spacing({ theme, ml: { md: 0, xs: 3 } })}
`;