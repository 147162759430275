import colors from '_containers/Theme/intheblack/colors';

export default {
  dialog: {
    background: colors.modal.background,
  },
  crossWrapper: {
    pathFillColor: colors.modal.close.fill,
    closeButtonHover: {
      pathFillColor: colors.modal.close.fillHover,
    }
  }
};