import colors from '_containers/Theme/intheblack/colors';

export default {
  figcaption: {
    color: colors.text.subtle
  },
  iconContainer: {
    color: colors.text.subtle
  },
 textWrapper: colors.text.default,
  credit: {
    color: colors.text.subtle
  },
};
