import { makeStyles } from "@material-ui/core/styles";
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import Icon from '_components/Icon';
import ErrorMessage from '_utils/components/Form/ErrorMessage';
import { errorInDev } from "_utils/helpers/dev";
import { highlightSubstring } from '_utils/helpers/html';

import { DropdownProps } from './definitions';
import {
  CheckboxOption,
  overrides,
  StyledAutocomplete,
  StyledSpinner,
  StyledTextField,
  SelectedValuesContainer,
} from './StyledDropdown';

const useStyles = makeStyles(overrides);

const popupIcons =
{
  expand: <Icon ariaHidden={true} name="expand_more" />,
  search: <Icon ariaHidden={true} name="search" />
};

const SelectedValues = ({ multiple, meta, getOptionLabel }) => {
  if (multiple && Array.isArray(meta?.value)) {
    return (
      <SelectedValuesContainer>
        {meta?.value?.map(value => <li><span>{getOptionLabel(value)}</span></li>)}
      </SelectedValuesContainer>
    )
  }

  return null;
};

const getValueToClear = (value: any) => {
  if (Array.isArray(value)) {
    return [];
  }

  if (value === null) {
    return '';
  }

  // In the likely case that we are dealing with object type options
  errorInDev('Ensure that valueToClear is defined when a Dropdown deals with object options, as these values may need to be emptied in a specific way.');
};

const getErrorMessage = (errorObj) => {
  if (typeof errorObj === 'string') {
    return errorObj || '';
  }

  return errorObj?.[Object.keys(errorObj)?.[0]] || '' // pick out first key in error obj if the field name describes a field that stores an object with many keys, and possibly has multiple validation errors
};

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    className,
    getOptionLabel = (option) => option?.label ?? option?.name ?? option,
    handleChange,
    id,
    loading,
    multiple = true,
    name,
    onChange,
    onClose = () => { },
    onOpen = () => { },
    options,
    popupIcon = 'expand',
    placeholder,
    shouldShowCheckboxes,
    shouldShowSelectedValuesBelow = false,
    valueOnClear,
    isInRenewPage = false,
    ...restProps
  } = props;

  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const classes = useStyles(); // MaterialUI Style Overrides

  const styledAutocompleteClasses = classNames('dropdown', className);

  const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext();
  const meta = name ? getFieldMeta(name) : null;
  const hasError = !!((meta?.touched || meta?.initialTouched) && meta?.error);
  const errorMessage = getErrorMessage(meta?.error);

  return (
    <>
      <StyledAutocomplete
        classes={classes}
        className={styledAutocompleteClasses}
        $shouldShowSelectedValuesBelow={shouldShowSelectedValuesBelow}
        disableCloseOnSelect={shouldShowCheckboxes}
        disablePortal={true}
        getOptionLabel={getOptionLabel}
        loading={loading}
        multiple={multiple}
        onOpen={(event) => {
          onOpen(event);
          setOpen(true);
        }}
        onClose={(event, reason) => {
          onClose(event, reason);
          setOpen(false);
          setSearchString('');
        }}
        onChange={(e, value, reason) => {
          switch (reason) {
            case 'clear':
              if (props?.name) {
                setFieldValue(
                  props.name,
                  valueOnClear ?? getValueToClear(value),
                );
              }
              // removes the highlighted text in results
              setSearchString('');
              break;
            case 'select-option':
            case 'remove-option':
              if (props?.name) {
                const valueToSet = value !== null
                  ? (value?.value ?? value) // if value is object with key 'value', else just the value
                  : props?.defaultValue;

                if(valueToSet?.identifier) {
                  setFieldValue(
                    props.name,
                    valueToSet?.identifier,
                  );
                } else {
                  setFieldValue(
                    props.name,
                    valueToSet,
                  );
                }
              }
              setSearchString('');
              break;
            default:
              break;
          }

          if (handleChange) {
            handleChange(e, value, reason);
          }
        }}
        open={open}
        options={options}
        $popupIconType={popupIcon}
        popupIcon={popupIcons[popupIcon]}
        renderInput={(params) => (
          <StyledTextField
            error={hasError}
            id={id}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <StyledSpinner />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={placeholder}
            variant="outlined"
            {...params}
            {...getFieldProps(name)}
            // onChange needed here after getFieldProps(name) so highlighting doesn't break
            onChange={
              (event) => setSearchString(event?.currentTarget?.value)
            }
          />
        )}
        renderOption={(option, { selected }) => {
          // reqd to re-use styles from Checkbox
          const checkboxOptionClasses = classNames('formik-field-checkbox', 'dropdown', {
            'checked': !!selected,
          });

          const label = getOptionLabel(option);

          // highlight search string
          const highlightedOption = searchString ? highlightSubstring(searchString, label) : label;

          return (
            <>
              {shouldShowCheckboxes ?
                <CheckboxOption className={checkboxOptionClasses}>
                  <span dangerouslySetInnerHTML={{ __html: highlightedOption }}></span>
                </CheckboxOption>
                :
                <span dangerouslySetInnerHTML={{ __html: highlightedOption }}></span>
              }</>
          )
        }}
        {...restProps}
      />

      {shouldShowSelectedValuesBelow && <SelectedValues multiple={multiple} meta={meta} getOptionLabel={getOptionLabel} />}

      {hasError && <ErrorMessage isInRenewPage={isInRenewPage}>{errorMessage}</ErrorMessage>}
    </>
  );
}

export default Dropdown;
