import styled, { css } from 'styled-components'; 
export const MOTION_SPEED_BASE = 400;
export const MOTION_SPEED_FAST = 400 / 2;
export const MOTION_SPEED_SLOW = 400 * 2;


export const hiddenContainer = css`
    display: none
`

export const TEXT_LINE_HEIGHT = 1.6;
export const FONT_FAMILY_TEXT = `'Avenir', Helvetica, Arial, sans-serif`;

export const placeholderBackgroundColors = {
  'Grey': 'neutral50'
}


// TODO remove once BEDs run Powershell script to remove from Placeholders
export const excludeThemeColors = ['white'];
