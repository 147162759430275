import { spacing } from '../../props';
import { mq } from '_utils/styles/';

export function listReset() {
  return `
    list-style: none;
    padding: 0;
    margin: 0;
  `;
}

export function unorderedList({ theme }) {
  return `

    ${listReset()}

    margin: 16px 0;

    > li {
      padding: 0 0 0 38px;
      text-indent: 0;
      position: relative;

      ${mq('md')} {
        padding-left: 48px;
      }

      ul {
        margin-left: 0;
      }

      p {
        ${spacing({ theme, my: 1 })}
      }
    }

    > li::before {
      position: absolute;
      display: inline-block;
      text-align: center;
      content: '•';
      font-family: 'sans-serif';
      font-size: ${theme.contentRichText.orderedList.bullet.fontSize};
      color: ${theme.colors.element.primary};
      text-indent: 0;
      left: 10px;
    }

    li ul li {
      padding-left: 30px;

      &::before {
        content: '${theme.contentRichText.list.marker.content}';
        font-size: ${theme.contentRichText.list.marker.fontSize};
        font-family: 'sans-serif';
        left: 5px;
      }
    }
  `;
}
