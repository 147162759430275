import { canUseDOM } from 'exenv';
import { useDebouncedCallback } from 'use-debounce';
import { useState, useEffect, RefObject } from 'react';
import { useLocation } from 'react-router';

export default function useScrollDepthAnalyticsTracking(trackEvent: Function, scrollPercentage: number, elementOffset?: RefObject<HTMLElement>) {
  const [triggered, setTriggered] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    // reset triggred when location change 
    setTriggered(false)
  }, [pathname]);

  const getDocHeight = () => {
    const body = document.body;
    const docEl = document.documentElement;
    let heightOffset = 0;
    if (elementOffset?.current) {
      heightOffset = elementOffset.current.getBoundingClientRect().height;
    };

    return Math.max(
      body.scrollHeight, docEl.scrollHeight,
      body.offsetHeight, docEl.offsetHeight,
      body.clientHeight, docEl.clientHeight
    ) - heightOffset
  };

  const amountscrolled = () => {
    const winheight = window.innerHeight || (document.documentElement || document.body).clientHeight;
    const docheight = getDocHeight();
    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode as HTMLElement || document.body).scrollTop;
    const trackLength = docheight - winheight;
    const pecentageScrolled = Math.floor(scrollTop / trackLength * 100); // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)

    if (pecentageScrolled >= scrollPercentage) {
      setTriggered(true);
    }
  };

  useEffect(() => {
    if (triggered) {
      trackEvent();
    }
  }, [triggered]);

  const debouncedAmountsScrolled = useDebouncedCallback(
    () => {
      amountscrolled();
    }, 500
  );

  if (canUseDOM) {
    window.addEventListener("scroll", debouncedAmountsScrolled.callback, false);
  };
};
