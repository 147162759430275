import colors from '_containers/Theme/corporate/colors';

export default {
  action: colors.all.brandPrimary1,
  cardTextColor: colors.text.subtle,
  ctaColor: 'tertiary',
  featured: {
    cardTextColor: colors.all.neutralWhite,
    iconWrapper: {
      pathFillColor: colors.all.neutralWhite
    }
  }
};
