import { compose } from 'ramda';
import React, { useState, useEffect, forwardRef, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Flickity, { FlickityOptions } from 'react-flickity-component';

import { withDigitalDataContext } from '_containers/DigitalDataContext';
import { AnalyticsDataProps } from '_containers/DigitalDataContext/definitions';

import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import { warnInDev } from '_utils/helpers/dev';
import { usePrevious } from '_utils/hooks';
import { SimpleArrow } from '_utils/icons';

import {
  Container,
  FlickityWrapper,
  PrevNextPosition,
  PrevNextButton,
  ArrowWrapper
} from './StyledCarousel';
import ScreenReader from '../ScreenReader';

export type CarouselOptions = {
  className?: string;
  disableImagesLoaded?: boolean;
};

const defaultOptions = {
  className: '',
  disableImagesLoaded: true
};

const defaultFlickityOptions = {
  contain: true,
  draggable: true,
  freeScroll: true,
  pageDots: true,
  prevNextButtons: false,
  setGallerySize: true,
  static: true,
  accessibility: true,
  lazyLoad: true
};

type CarouselProps = AnalyticsDataProps & {
  options?: CarouselOptions;
  flickityOptions?: FlickityOptions;
  children: ReactNode;
  // This is for 1. AutoPlay feature 2. Hide the buttons
  hidePrevNextButtonsOnMobile?: boolean;
};

const isInteractive = (element) => {
  if (!element || !element.tagName) {
    return false;
  }

  return element.tagName === 'A' || element.tagName === 'BUTTON';
};

// not very robust: just gets first anchor or button
const getInteractiveChild = (element) => {
  if (!element) {
    return null;
  }
  return element.querySelector('a, button');
};

const getInteractiveElement = (element) => isInteractive(element) ? element : getInteractiveChild(element);

// return true if element (or DOM ancestor) has flickity is-selected
const elementCellIsActive = (element) => {
  if (!element) {
    return null;
  }

  while (element) {
    if (element.classList.contains('is-selected')) {
      return true;
    }

    if (element.classList.contains('flickity-slider')) {
      return false;
    }

    element = element.parentNode;
  }

  return null;
};

// get the top level cell element
const getCellElement = (element) => {
  if (!element) {
    return null;
  }

  while (element) {
    const cellIndex = element.getAttribute('data-cellindex');

    if (cellIndex !== '' && cellIndex !== null) {
      return element;
    }

    if (element.classList.contains('flickity-slider')) {
      return null;
    }

    element = element.parentNode;
  }

  return null;
};

// get the slide index for this element (@see flickity api documentation on slides)
const getElementSlideIndex = (slides, element) => {
  element = getCellElement(element);

  if (!slides || !element) {
    return null;
  }

  for (let i = 0; i < slides.length; i++) {
    const cells = slides[i].cells;
    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];
      if (cell.element === element) {
        return i;
      }
    }
  }

  return null;
};

const Carousel = forwardRef<HTMLElement, CarouselProps>(
  ({ children, options, flickityOptions, digitalData, setDigitalData, trackingName, hidePrevNextButtonsOnMobile }, ref) => {
    options = {
      ...defaultOptions,
      ...options
    };

    flickityOptions = {
      ...defaultFlickityOptions,
      ...flickityOptions
    };

    const [t] = useTranslation();
    const [flickity, setFlickity] = useState(null);
    const prevFlickity = usePrevious(flickity);
    const [prevEnabled, setPrevEnabled] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);
    const numItems = React.Children.count(children);
    const wrapperRef = useRef();

    if (numItems === 0) {
      return null;
    }

    // Cell.prototype.setAriaHidden = function( val ) {
    //   this.element.setAttribute( 'aria-hidden', val );
    // };

    // listen to cell focus and update current slide index if the
    // focused cell is outside the viewport
    useEffect(() => {
      if (flickity) {
        const resize = flickity.resize;
        flickity.resize = () => {
          flickity.element.classList.remove(`flickity-resize`);
          resize.call(flickity);
          flickity.element.classList.add(`flickity-resize`);
        };

        setTimeout(() => flickity.resize(), 100);
      }

      const cellFocusListener = (e) => {
        if (e && e.target) {
          const focusIndex = e.target.getAttribute('data-focusindex');
          if (focusIndex === '' || focusIndex === null) {
            return;
          }

          if (elementCellIsActive(e.target) === false) {
            const slideIndex = getElementSlideIndex(flickity.slides, e.target);

            if (slideIndex !== null) {
              flickity.select(slideIndex);
            }

            // correct browser automagic scroll behaviour on the parent with overflow
            if (wrapperRef.current) {
              (wrapperRef.current as HTMLElement).scrollLeft = 0;
            }
          }
        }
      };

      // update prev next buttons based on scroll
      const scrollListener = (n) => {
        const overflows = flickity?.slides?.length > 1 || false;
        setPrevEnabled(overflows && n > 0.001);
        setNextEnabled(overflows && n < 0.999);
      };

      const changeListener = () => {

        // emit tracking event if name provided
        if (trackingName && typeof setDigitalData === 'function') {
          setDigitalData(
            setObjectData(
              ['carousel'],
              { carouselName: trackingName },
              digitalData
            )
          );
          emitTrackEvent('carouselSwipe');
        }

        flickity.getCellElements().forEach((element, index) => {
          // flickity messes this up - just remove
          element?.removeAttribute('aria-hidden');
        });
      };

      if (!prevFlickity && flickity) {
        // flickity initialization
        flickity.on('scroll', scrollListener);
        flickity.on('change', changeListener);

        flickity.getCellElements().forEach((element, index) => {
          element.setAttribute('data-cellindex', index);
          const el = getInteractiveElement(element);

          if (el) {
            el.setAttribute('data-focusindex', index);
            el.addEventListener('focus', cellFocusListener);
          }
        });

        return () => {
          // flickity clean up
          flickity.getCellElements().forEach((element) => {
            const el = getInteractiveElement(element);
            if (el) {
              el.removeEventListener('focus', cellFocusListener);
            }
          });
          flickity.off('scroll', scrollListener);
          flickity.off('change', changeListener);
        };
      }
      // ignore prevFlickity dep
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flickity]);

    return (
      <Container className={options.className} ref={ref}>
        <PrevNextPosition className="prev" hidePrevNextButtonsOnMobile={hidePrevNextButtonsOnMobile}>
          <PrevNextButton
            onClick={() => {
              flickity.previous();
              try {
                flickity.once('settle', (i) => {
                  flickity.getCellElements()[i].focus();
                });
              } catch (err) {
                warnInDev(err);
              }
            }}
            disabled={!prevEnabled}
          >
            <ScreenReader>{t('accessibility-previous-slide')}</ScreenReader>
            <ArrowWrapper>
              <SimpleArrow ariaHidden={true} />
            </ArrowWrapper>
          </PrevNextButton>
        </PrevNextPosition>
        <FlickityWrapper ref={wrapperRef}>
          <Flickity
            className="carousel"
            disableImagesLoaded={options.disableImagesLoaded}
            options={flickityOptions}
            flickityRef={(flickity) => {
              setFlickity(flickity);
            }}
          >
            {children}
          </Flickity>
        </FlickityWrapper>
        <PrevNextPosition className="next"  hidePrevNextButtonsOnMobile={hidePrevNextButtonsOnMobile}>
          <PrevNextButton
            onClick={() => {
              flickity.next();
              try {
                flickity.once('settle', (i) => {
                  flickity.getCellElements()[i].focus();
                });
              } catch (err) {
                warnInDev(err);
              }
            }}
            disabled={!nextEnabled}
          >
            <ScreenReader>{t('accessibility-next-slide')}</ScreenReader>
            <ArrowWrapper>
              <SimpleArrow ariaHidden={true} />
            </ArrowWrapper>
          </PrevNextButton>
        </PrevNextPosition>
      </Container>
    );
  }
);

export default compose(withDigitalDataContext)(Carousel);
