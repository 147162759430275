import styled from 'styled-components';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';

import { spacing } from '_utils/props';
import {
  avoidPageBreakOnPrint,
  headingH3,
  lineclamp,
} from '_utils/styles';

export const SpotlightCardImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  transform: scale(1);
  transition: transform 300ms ease-in-out, opacity ${({ theme }) => theme.motion.timing.fast} linear !important;
  user-select: none;
  -webkit-user-drag: none;

  ${avoidPageBreakOnPrint}

  @media print {
    position: static;
  }
`;

export const SpotlightCardContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  ${({ theme }) => spacing({ theme, p: 3 })};

  min-height: 407px;
`;

export const SpotlightCardImageWrapper = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
`;


export const SpotlightCardHeading = styled.div`
  z-index: 1;
`;

export const SpotlightCardHeadingText = styled.h4`
  ${headingH3}
  ${lineclamp(2, '56px')}
  color: white;
`;

export const SpotlightCardText = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SpotlightCardGradient = styled.div`
  /* https://larsenwork.com/easing-gradients */
  background: linear-gradient(
    to bottom,
    black,
    cubic-bezier(0.48, 0.3, 0.64, 1),
    transparent
  );
  background: linear-gradient(
    to top,
    hsl(0, 0%, 0%),
    hsla(0, 0%, 0%, 0.94505) 7.9%,
    hsla(0, 0%, 0%, 0.88294) 15.3%,
    hsla(0, 0%, 0%, 0.81522) 22.2%,
    hsla(0, 0%, 0%, 0.7426) 28.7%,
    hsla(0, 0%, 0%, 0.66692) 34.8%,
    hsla(0, 0%, 0%, 0.58891) 40.6%,
    hsla(0, 0%, 0%, 0.50925) 46.2%,
    hsla(0, 0%, 0%, 0.42866) 51.7%,
    hsla(0, 0%, 0%, 0.34817) 57.2%,
    hsla(0, 0%, 0%, 0.2693) 62.8%,
    hsla(0, 0%, 0%, 0.19309) 68.7%,
    hsla(0, 0%, 0%, 0.12126) 75.2%,
    hsla(0, 0%, 0%, 0.05882) 82.6%,
    hsla(0, 0%, 0%, 0.01457) 91.2%,
    hsla(0, 0%, 0%, 0)
  );
  bottom: 0;
  height: 55%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
`;
