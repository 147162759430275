import { canUseDOM } from 'exenv';
import React, { useContext } from 'react';
import { Prompt } from 'react-router-dom';

import { FormContext } from '_containers/FormSectionContext';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { errorInDev } from '_utils/helpers/dev';
import { useUnload } from '_utils/hooks';

import { DiscardChangesAlertProps } from './definitions';

const DiscardChangesAlert: React.FC<DiscardChangesAlertProps> = ({
  navigationBlocked,
  alertBodyDictionaryKey,
}) => {
  const formContext = useContext(FormContext);

  if (formContext === null) {
    errorInDev('DiscardChangesAlert is being rendered outside a FormContextProvider!');
    return null;
  }

  const alertBody = getDictionaryItem(
    alertBodyDictionaryKey ?? 'form-unsaved-changes-body',
    'Are you sure you want to leave? You currently have some unsaved changes.',
  );

  // TODO: Temporary. This will need to be updated to read if form is editing AND dirty.
  const isNavigationBlocked = formContext?.isAnySectionEditing && (navigationBlocked ?? true);

  useUnload(
    (e) => {
      if (e) {
        // This ensures a prompt is shown on beforeunload event
        // See: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
        e.preventDefault();
        //@ts-ignore
        e.returnValue = '';
      }
    },
    !isNavigationBlocked,
  );

  if (!canUseDOM) {
    return null;
  }

  return (
    <Prompt
      when={isNavigationBlocked}
      message={alertBody}
    />
  );
};

export { DiscardChangesAlert };
