import { useState, useEffect } from 'react';
import { canUseDOM } from 'exenv';
import { useDebouncedCallback } from 'use-debounce';
import mq, { getBreakpointKeys } from '_utils/styles/mq';

const breakpoints = getBreakpointKeys();

const getBreakpointIndex = (string) => breakpoints.indexOf(string);

// Returns single string value of exact breakpoint (xs, sm, md, lg, xl)
export const useBreakpoint = (): string | undefined => {
  const getBreakpoint = () => {
    if (canUseDOM && window.matchMedia) {
      return breakpoints.find((point, index) => {
        const pointFrom = point;
        const pointTo = breakpoints.length - 1 !== index ? breakpoints[index + 1] : false;
        
        return pointTo ? 
          window.matchMedia(mq.between(pointFrom, pointTo, false)).matches
          : window.matchMedia(mq(pointFrom, false)).matches;
      });
    }
  }

  const [breakpoint, setBreakpoint] = useState(getBreakpoint);

  const debouncedHandleResize = useDebouncedCallback(
    () => {
      const currentBreakpoint = getBreakpoint();
      if (currentBreakpoint !== breakpoint) {
        setBreakpoint(currentBreakpoint);
      }
    }, 500
  );

  useEffect(() => {
    if (canUseDOM) {
      window.addEventListener('resize', debouncedHandleResize.callback);
      return () => window.removeEventListener('resize', debouncedHandleResize.callback);
    }
  });

  return breakpoint;
};

export const minBreakpoint = (breakpoint) => {
  return getBreakpointIndex(useBreakpoint()) >= getBreakpointIndex(breakpoint);
}

export const maxBreakpoint = (breakpoint) => {
  return getBreakpointIndex(useBreakpoint()) < getBreakpointIndex(breakpoint);
}