import React, { FC } from 'react';

import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { ShowcaseHeroReadingTimeProps } from './definitions';
import TimeIcon from './TimeIcon';

import {
  ReadingTimeContainer,
  ReadingTimeText,
} from './StyledShowcaseHeroReadingTime';

const ReadingTime: FC<ShowcaseHeroReadingTimeProps> = (readingTime): JSX.Element => {
  const dictionaryItem = getDictionaryItem('global-read-time', '{minutes} min read time');
  const formattedTime = dictionaryItem.replace(/\{minutes\}/ig, readingTime?.value ?? '');

  return (
    <ReadingTimeContainer>
      <TimeIcon />
      <ReadingTimeText>{formattedTime}</ReadingTimeText>
    </ReadingTimeContainer>
  )
};

export default ReadingTime;