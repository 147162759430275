import styled from 'styled-components';

import {
  Grid,
} from '_utils/components';
import {
  Container as CarouselContainer,
  PrevNextPosition,
} from '_utils/components/Carousel/StyledCarousel';
import {
  headingH3,
  hideOnPrint,
  mq,
} from '_utils/styles';
import { spacing } from '_utils/props';

export const Heading = styled.h3`
 ${headingH3}
 ${({ theme }) => spacing({
  theme,
  mb: {
    xs: 1,
    md: 3,
  }
})}
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: visible;

  // shadow only on image, not on entire card
  a img {
    box-shadow: ${({ theme }) => theme.shadows.elevation8};
  }

  a:hover img {
    box-shadow: ${({ theme }) => theme.shadows.elevation16};
  }

  a:active img {
    box-shadow: ${({ theme }) => theme.shadows.elevation4};
  }
`;

export const Container = styled.div`
  overflow: visible;

  ${hideOnPrint}

  .flickity-page-dots {
    bottom: -16px;

    ${mq('md')} {
      bottom: -24px;
    }
  }

  // if carousel, ensure shadows aren't cut off and apply negative margin to extend full width in < lg breakpoints
  ${CarouselContainer} {
    width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.xs * 2.5}px)`};

    ${mq('md')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.md}px)`};
    }

    ${mq('lg')} {
      width: calc(100% + 24px);
    }

    // fixes for display box shadow LHS
    .flickity-viewport {
       transform: translateX(-16px);

       ${mq.between('md', 'lg')} {
        width: calc(100% + 16px);
       }
    }

    .flickity-slider {

      ${mq('lg')} {
        width: 100%;
      }

      ${ListItem} {
        ${({ theme }) => spacing({
          theme,
          pl: '16px',
          pr: {
            md: `8px`,
          },
          py: {
            xs: 1,
            md: 1,
            lg: 1,
          },
        })}
      }
    }

    ${PrevNextPosition} {
      top: calc(50% - 32px);

      &.prev {
        left: 0;

        ${mq('lg')} {
          left: 44px;
        }
      }

      &.next {
        right: 36px;

        ${mq('md')} {
          right: 24px;
        }

        ${mq('lg')} {
          right: 70px;
        }
      }
    }
  }
`;
