import colors from '_containers/Theme/intheblack/colors';

export default {
  background: colors.all.neutralWhite,
  borderColor: colors.all.neutral200,
  headerColor: colors.all.brandPrimary1,
  height: {
    fixed: 73,
    md: 93,
    xs: 65
  },
  logo: {
    svg: {
      md: {
       height: 52,
       width: 120,
        },
      lg: {
       height: 40,
       width: 165,
      },
      sticky: {
        md: {
          height: 48,
          width: 112,
        },
        lg: {
          height: 32,
          width: 132,
        }
      }
    }
  },
  menu: {
    button: {
      paddingLeft: '40px'
    },
    fontWeight: 900,
    dashboardMenuButton: {
      marginRight: '0',
      marginLeft: '8px'
    },
    md: {
      marginLeft: 3
    }
  },
  overlayColor: colors.all.neutralBlack,
  position: {
    stickyOnMobile: false
  },
  searchContainer: {
    width: '424px',
    md: {
      paddingLeft: '37px',
      width: '100%'
    }
  },
  skipContainer: {
    background: colors.background.tint2
  },
  skipLink: {
    color: colors.all.neutralBlack,
    focus: {
      outlineColor: colors.element.focused
    }
  }
};
