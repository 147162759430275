import { compose } from 'ramda';
import React, { FC } from 'react';
import {
  withDataSourceValidation,
  withEditMode,
} from '_containers/BaseComponent';
import { withDigitalDataContext } from '_containers/DigitalDataContext';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import { normalizeJssFields } from '_utils/helpers/jss';

import { ShowcaseHeroFieldValidators } from './validation';

import {
  BannerProps,
  NormalisedShowcaseItem,
  ShowcaseHeroProps,
} from './definitions';

import Banner from './Banner';

const ShowcaseHero: FC<ShowcaseHeroProps> = ({
  digitalData,
  editMode,
  rendering,
  setDigitalData,
}): JSX.Element | null => {
  const targetItems = rendering?.fields?.data?.datasource?.items?.targetItems ?? [];
  const normalizedItems: Array<NormalisedShowcaseItem> = targetItems?.map((item: any) => (normalizeJssFields(item)));

  const handleAnalytics = (linkName: string, linkDestination: string) => {
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['link'],
          {
            linkName: linkName,
            linkDestination: linkDestination,
          },
          digitalData
        )
      );

      emitTrackEvent('linkClick');
    }
  };

  const bannerProps: BannerProps = {
    editMode,
    handleAnalytics,
    items: normalizedItems,
    uid: rendering?.uid,
  }
  const shouldShowBanner = editMode || (normalizedItems?.length > 0);
  return shouldShowBanner ? <Banner {...bannerProps} /> : null;
};

export default compose(
  withDigitalDataContext,
  withEditMode,
  withDataSourceValidation(ShowcaseHeroFieldValidators, false),
)(ShowcaseHero);