import styled from 'styled-components';

import { spacing } from '_utils/props';
import {
  button,
  focusOutline,
  hideOnPrint,
  iconColorOnPrint,
  inputReset,
  mq,
  svg,
  textBody1,
} from '_utils/styles';

export const Form = styled.form`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  ${hideOnPrint}
`;

export const InputWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.mainSearch.background};
  border: ${({ theme }) => theme.mainSearch.border};
  border-radius: ${({ theme }) => theme.mainSearch.inputBorderRadius};
  border-right-style: none;
  display: flex;
  height: 100%;
  justify-content: center;
  transition:
    width ${({ theme }) => theme.motion.timing.fast} ${({ theme }) => theme.motion.ease},
    border-color ${({ theme }) => theme.motion.timing.fast} ${({ theme }) => theme.motion.ease},
    background-color ${({ theme }) => theme.motion.timing.fast} ${({ theme }) => theme.motion.ease};
  width: 100%;

  .search--focused & {
    background-color: ${({ theme }) => theme.mainSearch.backgroundActive};
    border: ${({ theme }) => theme.mainSearch.borderActive};
    border-right: none;
  }

  ${mq.lessThan('md')} {
    background-color: ${({ theme }) => theme.mainSearch.background};
    border: ${({ theme }) => theme.mainSearch.border};
    border-radius: ${({ theme }) => theme.mainSearch.inputBorderRadiusLessThanMd};

    .search--focused & {
      background-color: ${({ theme }) => theme.mainSearch.backgroundActive};
      border: ${({ theme }) => theme.mainSearch.borderActive};
    }
  }
`;


export const InputIcon = styled.span`
  ${svg(17, 17)}
  display: none;
  width: 22px;

  ${({ theme }) => spacing({ theme, ml: { xs: '18px', md: '18px' }, mr: { xs: '12px' } })}

  path {
    fill: ${({ theme }) => theme.mainSearch.iconFill};
  }

  .search--focused & {
    margin-left: 17px; // Prevent icon from shifting when border applied to InputWrapper
  }

  ${mq.lessThan('md')} {
    display: inline-block;
    min-width: 20px; // Prevent icon from shifting when border applied to InputWrapper
  }
`;

export const Input = styled.input`
  ${inputReset}
  ${textBody1}

  border: 1px solid transparent; // Prevent shifting of input when border outline is applied to InputWrapper
  height: 100%;
  margin: 0;
  outline: 0;
  text-overflow: ellipsis;
  width: 100%;

  .search--focused & {
    ${mq('md')} {
      border: none; // Prevent shifting of input when border outline is applied to InputWrapper
    }
  }

  ${({ theme }) => spacing({ theme, pl: { xs: 0, md: '22px' }, pr: { xs: '18px', md: 0 } })}

  /*
    Hiding browser supplied "X" button for type="search" inputs.
    We roll our own cross-browser clear input button.
  */
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  &::-ms-clear {
    display: none;
  }

  /* As the input is not full width, tap highlight color is misaligned and jarring. This removes it in favor of the focus outline. */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
`;

export const ClearButton = styled.button`
  ${button('default', 'text')}

  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0px 24px 0px 12px;
  width: 24px;

  body:not(.safe-focus-removal) &:focus {
    &:before {
      ${focusOutline}
      border-radius: ${({ theme }) => theme.mainSearch.clear.borderRadius} !important;
      content: '';
      height: calc(100% + ${({ theme }) => theme.mainSearch.clear.height}) !important;
      left: ${({ theme }) => theme.mainSearch.clear.left} !important;
      margin-top: -1px;
      outline-color: ${({ theme }) => theme.mainSearch.outlineColour} !important;
      top: ${({ theme }) => theme.mainSearch.clear.top} !important;
      width: calc(100% + ${({ theme }) => theme.mainSearch.clear.width}) !important;
      z-index: 1;
    }
  }
`;

export const ClearIcon = styled.span`
  ${svg(14, 14)}
  flex: none;
  width: 24px;
  path {
    fill: ${({ theme }) => theme.mainSearch.clear.iconFill};
  }

  ${iconColorOnPrint}
`;

export const Submit = styled.button`
  ${button('default', 'primary')}

  // fix false positive from Pally eval as #FFF
  span {
    color: initial;
  }

  align-items: center;
  background-color: ${({ theme }) => theme.mainSearch.submit.background};
  border: 2px solid ${({ theme }) => theme.mainSearch.submit.background};
  border-radius: ${({ theme }) => theme.mainSearch.submit.borderRadius};
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: ${({ theme }) => theme.mainSearch.submit.minWidth};
  padding: ${({ theme }) => theme.mainSearch.submit.padding};
  outline: 0;
  transition:
    border-color ${({ theme }) => theme.motion.timing.fast} ${({ theme }) => theme.motion.ease},
    background-color ${({ theme }) => theme.motion.timing.fast} ${({ theme }) => theme.motion.ease};

  /* Submit focus outline style */
  body:not(.safe-focus-removal) &:focus {
    
    &:before {
      ${focusOutline}
      border-radius: ${({ theme }) => theme.mainSearch.submit.borderRadius} !important;
      content: '';
      height: calc(100% + ${({ theme }) => theme.mainSearch.submit.height}) !important;
      left: ${({ theme }) => theme.mainSearch.submit.left} !important;
      outline-color: ${({ theme }) => theme.mainSearch.outlineColour} !important;
      position: absolute;
      top: ${({ theme }) => theme.mainSearch.submit.top} !important;
      width: calc(100% + ${({ theme }) => theme.mainSearch.submit.width}) !important;
    }
  }

  ${mq.lessThan('md')} {
    display: none;
  }

  &:hover &:focus {
    background-color: ${({ theme }) => theme.mainSearch.submit.backgroundActive} !important;
    border: 2px solid ${({ theme }) => theme.mainSearch.submit.borderHover};
    border-left: none;
  }


  .search--focused & {
    background-color: ${({ theme }) => theme.mainSearch.submit.backgroundActive} !important;
    border: 2px solid ${({ theme }) => theme.mainSearch.submit.borderHover} !important;
    border-left: none !important;
  }
`;

export const IconWrapper = styled.span`
  ${svg(24, 24)}
  pointer-events: none; // prevent search icon from preventing click on parent button
  width: 24px;

  path {
    fill: ${({ theme }) => theme.mainSearch.submit.iconFill};
  }

  ${Submit}:hover &,
  ${Submit}:active &,
  .search--focused & {
    path {
      fill: ${({ theme }) => theme.mainSearch.submit.iconFillActive} !important;

      ${iconColorOnPrint}
    }
  }
`;
