import styled, { css } from 'styled-components';
import { Grid } from "_utils/components";
import { avoidPageBreakOnPrint, mq } from "_utils/styles";
import {Container as CarouselContainer} from "_utils/components/Carousel/StyledCarousel";
import {spacing} from "_utils/props";

export const DynamicTextAndImageContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Container = styled(Grid.Container)`
  // remove padding if child of Single Column placeholder (ie. within Dashboard)
  .ph-sc & {
    padding: 0;
  }

  background: ${({ theme }) => theme.cardList.background};
  
`;

export const List = styled(Grid.Row)`
  list-style: none;
  padding: 0;

  /* beat specificity of grid breakpoints */
  && {
    margin-top: 0;
  }

  ${avoidPageBreakOnPrint}

  @media print {
    display: block;
    width: 100%;
  }
`;

