import React, { useEffect, useState } from 'react';
import NavItem from '_components/NavItem';
import { convertSrcJss } from '_utils/media';
import { ImageSize } from '_utils/types/enums';
import { imagePlaceholderSrc } from '_utils/styles';
import { SponsorLogoProps } from './definitions';
import {
  Container,
  LogoLink,
  LogoWrapper,
  SponsorLabel,
  SponsorLogoImg
} from './StyledSponsorLogo'

const SponsorLogo: React.FC<SponsorLogoProps> = ({ rendering }) => {
  const [imageClassName, setImageClassName] = useState('landscape-style');

  const renderingPageFields = rendering?.fields?.data?.pageFields || {};
  const targetItem = renderingPageFields?.sponsor?.targetItem || {};
  const hasImage = (targetItem?.logo?.jss?.value?.src ?? false);
  const uid = rendering?.uid;
  const label = renderingPageFields?.label?.value;
  const sponsorName = targetItem?.sponsorName?.value;
  const logo = targetItem?.logo;
  const link = targetItem?.link;

  useEffect(() => {
    const img = new Image();
    img.src = logo?.jss?.value?.src;
    img.onload = () => {
      const imageClassName = (img.width > img.height) ? 'landscape-style' : 'portrait-style';
      setImageClassName(imageClassName);
    }
  }, []);

  return !hasImage ? null : (
    <Container
      className="sponsor-logo"
      data-component
      id={uid}
    >
      <NavItem link={link?.jss?.value} StyledLink={LogoLink} target="_blank">
        <LogoWrapper
          className={imageClassName}
        >
          <SponsorLogoImg
            className="lazyload"
            draggable={false}
            field={
              {
                value: {
                  src: imagePlaceholderSrc,
                  alt: sponsorName,
                  ...{ 'data-src': `${convertSrcJss(logo?.jss?.value?.src, ImageSize.MW_248, ImageSize.MH_112)}` },
                }
              }
            }
            height={ImageSize.MH_112}
            width={ImageSize.MW_248}
          />
        </LogoWrapper>
        <SponsorLabel>{label}</SponsorLabel>
      </NavItem>
    </Container>
  );
};

export default SponsorLogo;
