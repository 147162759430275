import colors from '_containers/Theme/corporate/colors';

export default {
  action: colors.all.neutral300,
  actionHover: colors.all.brandPrimary3,
  background: colors.all.neutralWhite,
  backgroundActive: colors.all.neutral200,
  backgroundHover: colors.all.neutral50,
  border: `1px solid ${colors.all.neutral300}`,
  borderFocusColor: colors.all.interactive500,
  borderHover: `1px solid inherit`,
  borderRadius: 0,
  headingColor: colors.all.brandPrimary3,
  icon: colors.all.brandPrimary1,
  primary: colors.all.neutral600,
  profileNameColor: colors.all.neutral700,
  profileTitleColor: colors.all.neutral500,
};

export const darkMode = {};
