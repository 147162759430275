import { canUseDOM } from 'exenv';
import { useFormikContext } from 'formik';
import { intersection, keys } from 'ramda';
import React, { useContext, useEffect } from 'react';

import InPageAnnouncement from '_components/Announcements/InPageAnnouncement';
import {
  AddressesForm,
  Alert,
  ContactDetails,
  PersonalDetailsForm,
} from '_components/MMFContactDetailsForm/Sections';
import Icon from '_components/Icon';
import ApiErrorContent from '_components/MMFContactDetailsForm/Sections/ApiErrorContent';
import { ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';
import { useLocationAPIContext } from '_containers/LocationAPIContext';
import { ContactDetailsFormContext } from '_containers/MMFContactDetailsFormContext/definitions';
import { FormContext } from '_containers/FormSectionContext';
import { EditableSectionActions } from '_utils/components/Form/EditableSection';
import {
  IntroContainer,
  NarrowContainer,
  PrivacyPolicy,
} from '_utils/components/Form/StyledContainerForm';
import { CTAButton } from '_utils/components/Form/StyledFormSection';
import { buttonAction } from '_utils/helpers/form/index';
import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { ContactDetailsFormProps } from './definitions';

// helper function to flag if user has error with phone or returned mail
export const hasCommunicationErrors = (errors): boolean => {
  if (errors?.contact?.comms) {
    const phoneErrorKeys = [
      'workPhone',
      'homePhone',
      'mobilePhone',
      'mobilePhoneCountryCode',
      'preferredPhoneNumber',
    ];

    const keyIntersection = intersection(
      phoneErrorKeys,
      keys(errors.contact.comms)
    );

    return !!keyIntersection?.length;
  }

  return false;
};

const ValidationAlerts = () => {

  const formikContext = useFormikContext<ContactDetailsFormValues>();
  const { errors, isValid, submitCount } = formikContext;

  if (isValid || submitCount > 0) {
    return null;
  }

  const hasMailError = !!errors?.contact?.comms?.isMailReturned;

  return (
    <>
      {errors && hasCommunicationErrors(errors) &&
        <Alert type="phone" />
      }
      {hasMailError &&
        <Alert type="mail" />
      }
    </>
  );
};

const FormContent: React.FC<ContactDetailsFormProps> = (props) => {
  const {
    contactProfileApi,
    contactProfileSubmissionApi,
    rendering,
  } = props;

  const { countriesApi } = useLocationAPIContext();

  const {
    isAnySectionEditing,
    sectionEditing,
    personalDetails: { updateAction },
    isReferredFromRenewal,
    setIsEditing,
  } = useContext<ContactDetailsFormContext>(FormContext);

  const isEditing = isAnySectionEditing;

  const datasource = rendering?.fields?.data?.datasource;
  const myAccountLink = datasource?.myAccountLink?.jss?.value;
  const privacyNotice = datasource?.privacyNotice?.jss;

  const viewMyAccountLabel = getDictionaryItem('form-my-account-link-label', 'View My Account');
  const cancelLabel = getDictionaryItem('form-contact-button-cancel', 'Cancel');
  const saveLabel = getDictionaryItem('form-contact-button-save-updates', 'Save changes');
  const errorHeading = getDictionaryItem('form-errors-heading', 'Something went wrong');

  // Get Api data state for (ContactProfile, Countries, ContactProfileReferenceData)
  const contactProfileApiError = contactProfileApi?.result?.error;
  const contactProfileSubmissionApiLoading = contactProfileSubmissionApi?.result?.isLoading;
  const contactProfileApiLoading = contactProfileApi?.result?.isLoading;
  const countriesApiLoading = countriesApi?.result?.isLoading;
  const countriesApiError = typeof countriesApi?.result?.error?.message === 'string';

  // TODO: these need to be made clearer
  const isLoading = contactProfileApiLoading || contactProfileSubmissionApiLoading;
  const shouldShowApiError = !isLoading && (!countriesApiLoading && countriesApiError) || (!contactProfileApiLoading && contactProfileApiError);
  const shouldShowForm = !isLoading && !contactProfileApiError && contactProfileApi?.result?.data && !shouldShowApiError;

  const getAPIErrorMessage = () => {
    if (countriesApiError) {
      return countriesApi?.result?.error?.message;
    }

    if (typeof contactProfileApi?.result?.error?.message === 'string') {
      return contactProfileApi?.result?.error?.message;
    }
  };

  // scroll user to section being edited
  // TODO allow for header offset
  useEffect(() => {
    if (!canUseDOM || !sectionEditing) {
      return;
    }

    const targetSection = document.getElementById(sectionEditing);

    if (targetSection) {
      window.scrollTo({ top: targetSection?.offsetTop, behavior: 'smooth' });
    }
  }, [sectionEditing]);

  useEffect(() => {
    // Trigger Edit mode on form if referred from Membership Renewal form
    // TODO: change this to open the specific section that was clicked on the renewal form, when partial saving is available
    if (setIsEditing) {
      setIsEditing(isReferredFromRenewal);
    }
  }, [isReferredFromRenewal]);

  return (
    <>
      {shouldShowForm &&
        <PrivacyPolicy field={privacyNotice} className="fullWidthOnDesktop" />
      }

      <NarrowContainer>
        {isEditing && shouldShowApiError &&
          <>
            <IntroContainer>
              <InPageAnnouncement
                description={{ value: getAPIErrorMessage() }}
                title={{ value: errorHeading }}
                type={{ value: 'Red' }}
              />
            </IntroContainer>
            {contactProfileApi?.result?.error?.message &&
              <NarrowContainer>
                <ApiErrorContent {...props} />
              </NarrowContainer>
            }
          </>
        }

        {shouldShowForm && (
          <>
            <ValidationAlerts />
            <PersonalDetailsForm {...props} />
            <ContactDetails {...props} />
            <AddressesForm {...props} />
            <EditableSectionActions
              cancelLabel={cancelLabel}
              explicitSectionKey={'personalDetails'} // arbitrary choice because forms using this prop have the same sectionContexts per section (no partial saving available)
              saveLabel={saveLabel}
            >
              <CTAButton
                aria-label={viewMyAccountLabel}
                onClick={(e) => buttonAction(e, myAccountLink)}
              >
                <Icon
                  ariaHidden={true}
                  name="arrow_back"
                />
                {viewMyAccountLabel}
              </CTAButton>
            </EditableSectionActions>
          </>
        )}
      </NarrowContainer >
    </>
  )
};


export default FormContent;