/**
 * Initialises a FormContext + Formik Context, with values for Contact Details Form.
 */
import { clone, compose, equals, omit } from 'ramda';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { hasCommunicationErrors } from '_components/MMFContactDetailsForm/FormContent';
import {
  getValidationSchema,
  contactDetailsFormValidationDictionaryItems,
} from '_components/MMFContactDetailsForm/formValidation';
import { withEditMode } from '_containers/BaseComponent';
import { DigitalDataContext } from '_containers/DigitalDataContext';
import { FormContext } from '_containers/FormSectionContext';
import { useLocationAPIContext } from '_containers/LocationAPIContext';
import { MemberContext } from '_containers/MemberContext';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { getQueryStringValue } from '_utils/data/queryString';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import { errorInDev } from '_utils/helpers/dev';
import {
  formatDate,
  REF_PARAM,
  RENEWAL_REF_PARAM_VALUE,
} from '_utils/helpers/form';
import { useUnload } from '_utils/hooks';

import {
  ContactDetailsFormContext,
  ContactDetailsFormContextProviderProps,
  ContactDetailsFormValues,
} from './definitions';

const ContactDetailsFormContextProvider: React.FC<ContactDetailsFormContextProviderProps> = ({
  children,
  editMode,
}) => {
  const memberContext = useContext(MemberContext);
  const { countriesMap, countriesApi, hasCountryGotStates } = useLocationAPIContext();
  const formRef = useRef(null);

  const digitalDataContext = useContext(DigitalDataContext);
  const { digitalData, setDigitalData } = digitalDataContext;

  useEffect(() => {
    //NOSONAR
    countriesApi?.request?.();
  }, []);

  if (Object.keys(memberContext ?? {})?.length < 1) {
    errorInDev('ContactDetailsFormContextProvider is being rendered outside MemberContext!');
  }

  // TODO: When partial saving is possible, there should be individual isEditing state and actions for each editable section.
  const [isEditing, setIsEditing] = useState(false);
  const [sectionEditing, setSectionEditing] = useState(null);
  const [isReferredFromRenewal, setIsReferredFromRenewal] = useState(getQueryStringValue(REF_PARAM) === RENEWAL_REF_PARAM_VALUE);

  // pre-validation causes user to straight to edit mode, so need to capture user interaction
  // else user get stuck in loop
  const [hasUserBeenShownPrompt, setHasUserBeenShownPrompt] = useState(false);
  
    // Initial fetch
  useEffect(() => {
    //NOSONAR
    memberContext?.contactProfileApi?.request?.({
      setLoadingStatus: true,
      useCachedResponse: false,
    });
  }, []);

  // Refresh form data everytime the user exits form edit mode
  useEffect(() => {
    if (!editMode && !isEditing) {
      //NOSONAR
      memberContext?.contactProfileApi?.request?.({
        setLoadingStatus: true,
        useCachedResponse: false,
      });

      // clear out any cached submit errors returning to readonly mode
      if (memberContext?.contactProfileSubmissionApi?.result?.error) {
        memberContext?.contactProfileSubmissionApi?.clear?.();
      }
    }

    if (!editMode && isEditing) {
      // clear out any cached success, if there was one left over from a previous edit
      // ("Updates saved" to hide when going back into editing mode)
      if (memberContext?.contactProfileSubmissionApi?.result?.data) {
        memberContext?.contactProfileSubmissionApi?.clear?.();
      }
    }
  }, [editMode, isEditing]);

  // Ensure removal of updates saved announcement on route change
  useUnload(() => memberContext?.contactProfileSubmissionApi?.clear());

  const updateAction = (event?: Event, sectionIdentifier?: string) => {
    if (event) {
      event?.preventDefault();
    }
    setIsEditing(true);

    if (sectionIdentifier) {
      setSectionEditing(sectionIdentifier);
    }

    // dispatch tracking event when start section being updated
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['form'],
          {
            formName: 'contact details form',
            update: sectionIdentifier ?? ''
          },
          digitalData)
      );
      emitTrackEvent('infoUpdate');
    }
  };

  const cancelAction = (event: Event) => {
    event.preventDefault();

    // dispatch tracking event on cancelAction
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['page'],
          {
            pageName: 'contact form unsaved changes overlay'
          },
          digitalData)
      );
      emitTrackEvent('overlay');
    }

    // TODO: detect if there are changes to discard and show a dialog to confirm user cancellation
    const alertBody = getDictionaryItem('form-unsaved-changes-body', 'Are you sure you want to leave? You currently have some unsaved changes.');

    // user confirm cancel,so don't re-validate on mount
    if (window.confirm(alertBody)) {
      setIsEditing(false);

      // remove referrer from query string if come from Renewal Form
      setIsReferredFromRenewal(false);

      setHasUserBeenShownPrompt(true);

      //TODO: Update analytics linkName and linkDestination when overlay button text updated
      // dispatch tracking event when user confirm to cancel
      if (typeof setDigitalData === 'function') {
        setDigitalData(
          setObjectData(
            ['link'],
            {
              linkName: 'discard changes',
              linkDestination: 'discard changes'
            },
            digitalData)
        );
        emitTrackEvent('linkClick');
      }
    } else {

      // dispatch tracking event when user confirm to continue editing
      if (typeof setDigitalData === 'function') {
        setDigitalData(
          setObjectData(
            ['link'],
            {
              linkName: 'continue editing',
              linkDestination: 'continue editing'
            },
            digitalData)
        );
        emitTrackEvent('linkClick');
      }
    }

    setSectionEditing(null); // so we don't conflict with scroll next line

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const saveAction = (event: Event) => {
    // event.preventDefault();
    // TODO: when partial save is ready to implement, handleFormSubmit should be moved into here
  };

  //NOSONAR
  const value: ContactDetailsFormContext = {
    hasUserBeenShownPrompt,
    isAnySectionEditing: isEditing,
    sectionEditing: sectionEditing,
    isReferredFromRenewal: isReferredFromRenewal,
    setIsEditing: setIsEditing,
    personalDetails: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    },
    contactDetails: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    },
    addresses: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    }
  }; 


  const getInitialFormValues = (): ContactDetailsFormValues => {
    const apiResponse = memberContext?.contactProfileApi?.result?.data;
    const dateOfBirth = formatDate(apiResponse?.contact?.personal?.dateOfBirth || '', 'DAY_FIRST')
    // not sure if need this so leaving it here just in case
    // cpaAustraliaIdTkn: apiResponse?.cpaAustraliaIdTkn,

    // there is a new field 'addressType' in MMF added, use the omit function provided by Ramda to omit it. 
    const omitAddressType = omit(['addressType']);

    return {
      cpaAustraliaId: apiResponse?.cpaAustraliaId,
      contact: {
        personal: {
          salutation: apiResponse?.contact?.personal?.salutation || '',
          gender: apiResponse?.contact?.personal?.gender || '',
          firstName: apiResponse?.contact?.personal?.firstName || '',
          preferredNameOrder: apiResponse?.contact?.personal?.preferredNameOrder || 'None',
          middleName: apiResponse?.contact?.personal?.middleName || '',
          surname: apiResponse?.contact?.personal?.surname || '',
          preferredName: apiResponse?.contact?.personal?.preferredName || '',
          dateOfBirth: dateOfBirth,
          indigenousIdentity: apiResponse?.contact?.personal?.indigenousIdentity || '',
          isMember: apiResponse?.contact?.membership?.isMember // Used by DOB validation so needs to be sibling for yup.when()
        },
        comms: {
          mobilePhoneCountryCode: apiResponse?.contact?.comms?.mobilePhoneCountryCode || '',
          mobilePhone: apiResponse?.contact?.comms?.mobilePhone || '',
          workPhone: apiResponse?.contact?.comms?.workPhone || '',
          homePhone: apiResponse?.contact?.comms?.homePhone || '',
          preferredPhoneNumber: apiResponse?.contact?.comms?.preferredPhoneNumber || '',
          isMailReturned: apiResponse?.contact?.comms?.isMailReturned || false, // handle null from API else breaks validation
        },
      },
      billingAddress: {
        primaryContactName: apiResponse?.billingAddress?.primaryContactName || '',
        addressLine1: apiResponse?.billingAddress?.addressLine1 || '',
        addressLine2: apiResponse?.billingAddress?.addressLine2 || '',
        addressLine3: apiResponse?.billingAddress?.addressLine3 || '',
        postCode: apiResponse?.billingAddress?.postCode || '',
        city: apiResponse?.billingAddress?.city || '',
        state: {
          isoCode: apiResponse?.billingAddress?.state?.isoCode || '',
          name: apiResponse?.billingAddress?.state?.name || '',
        },
        country: {
          isoCode2Letter: apiResponse?.billingAddress?.country?.isoCode2Letter || '',
          name: apiResponse?.billingAddress?.country?.name || ''
        },
        isPrivate: apiResponse?.billingAddress?.isPrivate || false,
        deliveryIdentifier: apiResponse?.billingAddress?.deliveryIdentifier || '',
        deliveryIdentifierProvider: apiResponse?.billingAddress?.deliveryIdentifierProvider || '',
      },
      isSameAddress: equals(omitAddressType(apiResponse?.billingAddress), omitAddressType(apiResponse?.shippingAddress)),
      shippingAddress: {
        primaryContactName: apiResponse?.shippingAddress?.primaryContactName || '',
        addressLine1: apiResponse?.shippingAddress?.addressLine1 || '',
        addressLine2: apiResponse?.shippingAddress?.addressLine2 || '',
        addressLine3: apiResponse?.shippingAddress?.addressLine3 || '',
        postCode: apiResponse?.shippingAddress?.postCode || '',
        city: apiResponse?.shippingAddress?.city || '',
        state: {
          isoCode: apiResponse?.shippingAddress?.state?.isoCode || '',
          name: apiResponse?.shippingAddress?.state?.name || '',
        },
        country: {
          isoCode2Letter: apiResponse?.shippingAddress?.country?.isoCode2Letter || '',
          name: apiResponse?.shippingAddress?.country?.name || '',
        },
        isPrivate: apiResponse?.shippingAddress?.isPrivate,
        deliveryIdentifier: apiResponse?.shippingAddress?.deliveryIdentifier || '',
        deliveryIdentifierProvider: apiResponse?.shippingAddress?.deliveryIdentifierProvider || '',
      }
    };
  };

  const isContactDetailsDataReady =
    !memberContext?.contactProfileApi?.result?.isLoading
    && memberContext?.contactProfileApi?.result?.data;

  const isMailReturnedApiResponse = isContactDetailsDataReady ?
    !!memberContext?.contactProfileApi?.result?.data?.contact?.comms?.isMailReturned : false;

  // pre-validate form on page load to check phone and mail to show warnings
  useEffect(() => {
    if (isContactDetailsDataReady && !hasUserBeenShownPrompt && formRef?.current) {
      formRef.current.validateForm()
        .then(
          errors => {
            if (hasCommunicationErrors(errors)) {
              setIsEditing(true);
            }
          }
        );
    }
  }, [formRef, hasUserBeenShownPrompt, isContactDetailsDataReady]);

  const handleFormSubmit = (
    values: ContactDetailsFormValues,
    { setSubmitting }: FormikHelpers<ContactDetailsFormValues>
  ) => {
    // Transforms to prepare form values to send
    /** Note below:
     *  @clone function is for deep clone, which is Async and will take long time, 
     *  it causes a behaviour that its below following code will run before it eg. delete valuesToSubmitToAPI..
     *  some unexpected observation will see if using console.log
     * */
    const valuesToSubmitToAPI = clone(values);

    // If DateOfBirth is already available in the CRM, do not set it again..\up.ps
    // sending empty "" will not trigger a reset
    // API DOB is soure of truth here
    const apiResponse = memberContext?.contactProfileApi?.result?.data;
    const dateOfBirthFromAPI = formatDate(apiResponse?.contact?.personal?.dateOfBirth || '', 'DAY_FIRST');
    const isDateOfBirthAvailable = dateOfBirthFromAPI.length > 0;
    if (isDateOfBirthAvailable) {
      valuesToSubmitToAPI.contact.personal.dateOfBirth = '';
    } else {
      valuesToSubmitToAPI.contact.personal.dateOfBirth = formatDate(valuesToSubmitToAPI.contact.personal.dateOfBirth, 'YEAR_FIRST');
    }

    // Overwrite shippingAddress if the checkbox is tick
    if (valuesToSubmitToAPI.isSameAddress) {
      valuesToSubmitToAPI.shippingAddress = valuesToSubmitToAPI.billingAddress;
    }

    // Remove isSameAddress not require to save to api
    delete valuesToSubmitToAPI.isSameAddress;

    // Remove isMember, only used temporarily for DOB validation
    delete valuesToSubmitToAPI.contact.personal.isMember;

    // Remove firstName and surName as they cannot be changed on the form
    delete valuesToSubmitToAPI.contact.personal.firstName;    
    delete valuesToSubmitToAPI.contact.personal.surname;

    //NOSONAR
    memberContext?.contactProfileSubmissionApi?.request?.({
      data: valuesToSubmitToAPI,
      onComplete: () => {
        setSubmitting(false);
        setSectionEditing(null); // clear the current edit section so doesn't conflict with scroll top next line
        // remove referrer from query string if come from Renewal Form
        setIsReferredFromRenewal(false);

        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      onSuccess: () => {
        setIsEditing(false);

        // dispatch tracking event when "Updates saved"
        if (typeof setDigitalData === 'function') {
          setDigitalData(
            setObjectData(
              ['form'],
              {
                formName: 'contact details form',
                update: 'contact details form saved'
              },
              digitalData)
          );
          emitTrackEvent('infoUpdate');
        }

      },
      setLoadingStatus: true,
      useCachedResponse: false,
    });
  };

  return (
    <FormContext.Provider value={value}>
      {isContactDetailsDataReady
        ? (
          <Formik
            initialValues={getInitialFormValues()}
            innerRef={formRef}
            onSubmit={handleFormSubmit}
            validationSchema={getValidationSchema(countriesMap, hasCountryGotStates, contactDetailsFormValidationDictionaryItems)}
            validateOnMount
            // Set initialTouched to ensure we show form errors we need to display, on load
            initialTouched={{
              contact: {
                comms: {
                  workPhone: true,
                  homePhone: true,
                  mobilePhone: true,
                  mobilePhoneCountryCode: true,
                  preferredPhoneNumber: true,
                  isMailReturned: isMailReturnedApiResponse
                }
              },
              billingAddress: {
                addressLine1: isMailReturnedApiResponse,
                postCode: isMailReturnedApiResponse,
                state: isMailReturnedApiResponse,
              },
              shippingAddress: {
                addressLine1: isMailReturnedApiResponse,
                postCode: isMailReturnedApiResponse,
                state: isMailReturnedApiResponse,
              },
              isSameAddress: false,
            }}
          >
            <>
              {children}
            </>
          </Formik>
        ) : children
      }
    </FormContext.Provider>
  )
};

export default compose(
  withEditMode,
)(ContactDetailsFormContextProvider);
