import React from 'react';
import Icon from '_components/Icon';

import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { ReadingTimeProps } from '../definitions';
import {
  ReadingTimeContainer,
  ReadingTimeIconWrapper,
  ReadingTimeText,
} from './StyledReadingTime';

const ReadingTime: React.FC<ReadingTimeProps> = (readingTime) => {
  const dictionaryItem = getDictionaryItem('global-read-time', '{minutes} min read time');
  const formattedTime = dictionaryItem.replace(/\{minutes\}/ig, readingTime?.value ?? '');

  return (
    <ReadingTimeContainer>
      <ReadingTimeIconWrapper>
        <Icon name="schedule" ariaHidden />
      </ReadingTimeIconWrapper>
      <ReadingTimeText>
        {formattedTime}
      </ReadingTimeText>
    </ReadingTimeContainer>
  )
};

export default ReadingTime;