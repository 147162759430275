import React from 'react';
import { compose } from 'ramda';
import { RecommendedInsightsItem } from './definitions';
import { normalizeJssFields } from '_utils/helpers/jss';
import { createSrcSet } from '_utils/media';
import { getImageAttrsByRatio } from '_utils/helpers/image';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { ImageSize, ImageRatio } from '_utils/types/enums';
import { withAuthenticatedCheck } from "_containers/BaseComponent";
import { 
  ItemStyle, 
  ItemFooterLink,
  ItemContent,
  ImageWrapper,
  Image as StyledImage,
  ItemTitle,
  ItemSubTitle,
  ItemDescription
} from "./StyledItem";
import Icon from "_components/Icon";
import { ArrowWrapper } from "_components/CardList/StyledItem";

const Item: React.FC<RecommendedInsightsItem> = ({
  Heading,
  Description,
  OtherPageDescription,
  url,
  Image,
}) => {
  const heading = normalizeJssFields(Heading);
  const image = normalizeJssFields(Image);
  const desc = normalizeJssFields(Description);
  const otherDesc = normalizeJssFields(OtherPageDescription);
  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_16By9);
  const readArticleLabel = getDictionaryItem('inpractice-read-article', 'Read article')

  return (
    <ItemStyle>
      <ImageWrapper>
        <StyledImage
          className="lazyload"
          draggable={false}
          field={
            {
              value: {
                src: image?.jss?.value?.src,
                alt: 'image', // alt text is empty string, as this is a decorative image
                'data-srcset': createSrcSet(image?.jss?.value?.src, [ImageSize.MW_384, ImageSize.MW_384])
              }
            }
            }
            {...defaultImageSize}
        />
          
      </ImageWrapper>
      <ItemContent>
        <ItemTitle as={`h3`}>{heading?.jss?.value}</ItemTitle>
        <ItemSubTitle>{desc?.jss?.value}</ItemSubTitle>
        <ItemDescription>{otherDesc?.jss?.value}</ItemDescription>
        <ItemFooterLink>
          <a href={url}>{readArticleLabel}</a>
          <ArrowWrapper>
            <Icon name="arrow_forward" ariaHidden />
          </ArrowWrapper>
        </ItemFooterLink>
      </ItemContent>
    </ItemStyle>
    );
}

export default compose(withAuthenticatedCheck)(Item);
