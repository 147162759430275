import React from 'react';
import { InpracticeToplicsListProps } from './definitions';
import { ListWrapper, List, Container, MainTitle  } from './StyledInpracticeTopicsList';
import Item from "./Item";

const InpracticeTopicsList: React.FC<InpracticeToplicsListProps> = ({rendering}) => {

  const uid = rendering?.uid;
  const componentTitle = rendering.fields?.data?.datasource?.title?.jss?.value;
  const getItems = rendering.fields?.data?.datasource?.inpracticeTopics || [];
  const headingLevel = rendering?.params?.headingLevel || 'h2';
  const headingType = typeof headingLevel === 'number' ? `h${headingLevel}` : headingLevel

  return (
    <Container
      className={"inpractice-toplic-list"}
      data-component
      uid={uid}
    >
      <MainTitle as={headingType}>{componentTitle}</MainTitle>
      <ListWrapper>
        <List as="ul">
            {getItems?.map((item) => <Item {...item}></Item>)}
        </List>
      </ListWrapper>
    </Container>
  );
}

export default InpracticeTopicsList;
