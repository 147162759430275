import React from 'react';

import Icon from '_components/Icon';

import { Button, StyledReactARIAToolTip } from './StyledTooltip';

type TooltipProps = {
  content: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

const Tooltip: React.FC<TooltipProps> = ({ content }) =>
  // margin-left thowing out position of tooltip
  <>&nbsp;
    <StyledReactARIAToolTip
      bgcolor="transparent"
      eventType="hover"
      message={content}
    ><Button><Icon ariaHidden={false} name="info" /></Button>
    </StyledReactARIAToolTip>
  </>;

export default Tooltip;
