import colors from '_containers/Theme/corporate/colors';
import { shadows } from '_containers/Theme/shared';

export default {
  action: colors.all.brandPrimary1,
  attributeIcon: colors.all.neutral700,
  background: `transparent`,
  cardBackground: colors.all.neutralWhite,
  border: `1px solid ${colors.all.neutral200}`,
  borderDashed: `0px dashed ${colors.all.neutral200}`,
  borderDashedHover: `0px dashed ${colors.all.neutral800}`,
  borderFocus: `2px solid ${colors.all.interactive200}`,
  borderHover: `none`,
  borderRadius: 4,
  borderSelected: `none`,
  boxShadow: shadows.elevation4,
  boxShadowHover: shadows.elevation16,
};

export const darkMode = {
  background: colors.all.brandPrimary3,
  color: colors.all.neutralWhite,
  ctaColor: 'tertiaryDark',
};