import colors from '_containers/Theme/corporate/colors';

const defaultStyle = {
  borderRadius: '3px',
  padding: '1px 4px !important',
}
export default {
  accordion: {
    ...defaultStyle,
    background: colors.all.neutralWhite,
    border: colors.all.neutral700,
    color: colors.all.neutral700,
  },
  accordionDark: {
    ...defaultStyle,
    background: colors.all.darkBackgroundTint1,
    color: colors.all.darkText,
  }
}

export const darkMode = {
  ...defaultStyle,
  background: colors.all.darkBackgroundTint1,
  color: colors.all.darkText,
}
