import { textBody2 } from '_utils/styles';
import colors from './colorOptions';

export default {
  primary: {
    background: colors.transparent,
    backgroundHover: colors.interactive700,
    border: colors.interactive600,
    borderFocus: '3px solid',
    borderFocusColor: colors.interactive600,
    borderFocusWithinColor: colors.interactive600,
    borderHover: colors.transparent,
    borderVisited: colors.visited500,
    color: colors.interactive600,
    colorFocus: colors.neutralWhite,
    colorHover: colors.neutralWhite,
    colorVisited: colors.visited500,
    textDecoration: 'none',
    textDecorationFocus: 'none',
  },
  primaryWithoutVisited: {
    background: colors.transparent,
    backgroundHover: 'inherit',
    border: colors.interactive600,
    borderHover: colors.interactive700,
    color: 'inherit',
    colorHover: 'inherit',
    colorVisited: 'inherit',
    borderVisited: 'inherit',
    textDecoration: 'none',
    textDecorationFocus: 'none',
  },
  secondary: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderFocus: 'none',
    borderFocusColor: colors.interactive600,
    borderFocusWithinColor: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    color: colors.interactive600,
    colorFocus: colors.interactive600,
    colorHover: colors.interactive700,
    colorVisited: colors.visited500,
    textDecoration: 'underline',
    textDecorationFocus: 'underline',
  },
  featured: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderFocusColor: colors.interactive600,
    borderFocusWithinColor: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    boxShadowColor: colors.transparent,
    color: colors.interactive600,
    colorFocus: colors.interactive600,
    colorHover: colors.interactive700,
    colorVisited: colors.visited500,
    fontWeight: 'bold',
    textBody: textBody2,
    textDecoration: 'none',
    textDecorationFocus: 'none',
    textTransform: 'uppercase',
  },
  white: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    color: 'inherit',
    colorHover: colors.neutralWhite,
    colorVisited: colors.neutralWhite,
    textDecoration: 'underline',
    textDecorationFocus: 'inherit',
  },
  breadcrumb: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    color: colors.interactive500,
    colorHover: colors.brandPrimary1,
    colorVisited: colors.brandPrimary1,
    textDecoration: 'none',
    textDecorationFocus: 'none',
  }
}