import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { canUseDOM } from 'exenv';

export const TRANSITION_DELAY = 250;
let timer;

export function scrollTop(delay = TRANSITION_DELAY, behavior: ScrollBehavior = 'auto') {
  try {
    if (canUseDOM) {
      clearTimeout(timer);

      // Let the LoadingScreen fade in before scrolling
      timer = window.setTimeout(
        () =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior
          }),
        delay
      );
    }
  } catch {}
}

function scrollToElementById(id, scrollOptions = { behavior: 'smooth' }, attempts = 0) {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth'
    });
  } else {
    requestAnimationFrame(() => {
      // give up after ~15 sec
      if (attempts < 900) {
        scrollToElementById(id, scrollOptions, attempts + 1);
      }
    });
  }
}

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    // no hash: Scroll to top whenever path changes
    if (!hash) {
      scrollTop();
    } else {
      // hash: try to scroll to element with that id
      const id = hash.replace('#', '');
      scrollToElementById(id);
    }
  }, [pathname, hash]);

  return null;
}
