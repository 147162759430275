import { ImageSize, ImageRatio } from '_utils/types/enums';
import { warnInDev } from '../dev';

export const getImageSrcSizesByColWidth = (colWidth: number | Record<string, number>): number[] => {
  const smallestColumns = typeof colWidth === 'number'
    ? colWidth
    : Object.keys(colWidth).reduce((num, key) => (num < colWidth[key] ? num : colWidth[key]), 12);

  if (smallestColumns === 3) {
    return [ImageSize.MW_384, ImageSize.MW_640, ImageSize.MW_768];
  } else if (smallestColumns === 4) {
    return [ImageSize.MW_384, ImageSize.MW_640];
  }

  return [ImageSize.MW_384, ImageSize.MW_640, ImageSize.MW_896];
}

export const getImageAttrsByRatio = (width: ImageSize = ImageSize.MW_384, ratio: number = ImageRatio.Ratio_16By9) => {
  const fallBackWidth = ImageSize.MW_384;
  const fallBackRatio = ImageRatio.Ratio_16By9;

  if (!Object.values(ImageSize).includes(width) ||
    !Object.values(ImageRatio).includes(ratio)) {
    warnInDev(`Image size or ratio not valid ${JSON.stringify({ width, ratio })}`);
  }

  const imageWidth = Object.values(ImageSize).includes(width) ? width : fallBackWidth;
  const imageRatioDivisor = Object.values(ImageRatio).includes(ratio) ? ratio : fallBackRatio;
  return {
    width: imageWidth,
    height: Math.floor(imageWidth / imageRatioDivisor),
  }
}
