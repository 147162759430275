import styled from 'styled-components';
import { fixedBody12 } from '_utils/styles';

export const ReadingTimeContainer = styled.div`
  align-items: center;
  column-gap: 9px;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  
  & > svg {
    fill: ${({ theme }) => theme.showcaseHero.readingTime.colour};
    height: 13.33px;
    width: 13.33px;
  }
`;

export const ReadingTimeText = styled.span`
  ${fixedBody12}
  font-size: 12px;
  color: ${({ theme }) => theme.showcaseHero.readingTime.colour};
`;
