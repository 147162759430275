import colors from '_containers/Theme/corporate/colors';

export default {
  background: colors.all.neutralWhite,
  borderColor: colors.all.neutral200,
  headerColor: colors.all.brandPrimary3,
  height: {
    fixed: 73,
    md: 93,
    xs: 65
  },
  logo: {
    svg: {
      md: {
       height: 52,
       width: 120,
        },
      sticky: {
        md: {
          height: 48,
          width: 110,
        }
      }
    }
  },
  menu: {
    button: { 
      paddingLeft: '40px'
    },
    fontWeight: 'bold',
    dashboardMenuButton: {
      marginLeft: null
    },
    md: {
      paddingLeft: '0',
      marginLeft: '40px'
    }
  },
  overlayColor: colors.all.neutralBlack,
  position: {
    stickyOnMobile: true
  },
  searchContainer: {
    width: '520px',
    md: {
      paddingLeft: '37px',
      width: '410px'
    }
  },
  skipContainer: {
    background: colors.background.tint2
  },
  skipLink: {
    color: colors.all.neutralBlack,
    focus: {
      outlineColor: colors.element.focused
    }
  }
};
