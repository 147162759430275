import colors from '_containers/Theme/intheblack/colors';

export default {
  borderColor: colors.ui.divider,
  color: colors.text.default,
  linkWrapper: {
    color: colors.all.neutralWhite
  },
  linkButton: {
    pathFillColor: colors.all.neutralWhite
  },
  outline: {
    focusColor: colors.interactiveCard.focus
  }
};