import styled from 'styled-components';

import { Section as UtilSection, PlaceholderWrapper } from '_utils/components';
import Grid from '_utils/components/Grid';
import { spacing } from '_utils/props';

export const Wrapper = styled(PlaceholderWrapper)`
  ${({ theme }) => spacing({ theme, p: 3, mb: 3 })}
  background-color: ${({ theme }) => theme.placeholderDashboardSingleColumn.background};
  border: 1px solid ${({ theme }) => theme.placeholderDashboardSingleColumn.borderColor};
  border-radius: 4px;

  .content-rich-text * {
    max-width: 100%;
  }
`;

export const Container = styled(Grid.Container)``;

export const Section = styled(UtilSection)``;
