import { canUseDOM } from 'exenv';
import React from 'react';
import i18n from 'i18next';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  handleBodyChanges,
  TRANSITIONING_CLASS,
} from '_utils/helpers/render';
import config from './temp/config';
import LayoutFactory from './LayoutFactory';
import { layoutServiceFactory } from './lib/layout-service-factory';


/* eslint-disable no-console */

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

let observer;
class RouteHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      routeData: null,
      isRouteChange: false,
      defaultLanguage: config.defaultLanguage,
    };
    

    const routeData = this.extractRouteData();
    

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (
      routeData?.sitecore?.context?.language
    ) {
      this.state.defaultLanguage = routeData.sitecore.context.language;
    }

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    
    const routeData = this.extractRouteData();


    if (this.props.ssrRenderComplete) {
      this.addMutationObserver();
    }

    // if no existing routeData is present (from SSR), get Layout Service fetching the route data or ssr render complete
    if (!routeData) {
      this.updateRouteData();
    } else {
      this.storeAppVersion(routeData?.sitecore?.context);
    }
  }

  storeAppVersion = (context) => {
    if (
      canUseDOM &&
      context?.appVersion &&
      !sessionStorage.getItem('appVersion')
    ) {
      sessionStorage.setItem('appVersion', context.appVersion);
    }
  }

  extractRouteData = () => {
    
    if (!this.props.sitecoreContext.route) return null;

    const { route, ...context } = this.props.sitecoreContext;

    return {
      sitecore: {
        route,
        context
      }
    }
  }

  /**
    *  watch for changes to app root so personalisation team have hook to know when stable
    */
  addMutationObserver = () => {

    if (canUseDOM && window?.MutationObserver) {
      // Previously, there is no `if` to check if observer is already there
      // Add this since no need to create the observer again
      if(!observer) {
        observer = new MutationObserver(handleBodyChanges);
      }

      observer.observe(document.getElementById('root'), {
        childList: true,
        subtree: false,
        attributes: false,
        characterData: false,
      });
    }
  }

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    // cancel listening for change until layout service resolves
    if (canUseDOM && typeof observer === 'object') {
      observer.disconnect();
    }
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const language = this.props.route.match.params.lang || this.state.defaultLanguage;
    const site = this.props.sitecoreContext.route ? this.props.sitecoreContext.site.name : null;

    if (this.props.ssrRenderComplete && canUseDOM) {
      this.setState({ isRouteChange: true });
      document.body.classList.add(TRANSITIONING_CLASS);
    }

    // get the route data for the new route
    getRouteData(sitecoreRoutePath, language, site).then((routeData) => {

      const sitecore = routeData?.sitecore;
      const context = routeData?.sitecore?.context;

      if (sitecore?.route) {
        /* check if page has redirect defined. We don't care what it is from FE perspective because we cannot return appropriate HTTP status code, so reload page and Sitecore can handle the redirect */
        if (canUseDOM && context?.redirect?.hasRedirect) {
          window.location.reload();
          return;
        }

        // check if the app version has changed and if so, update session var and force reload
        if (
          canUseDOM &&
          sessionStorage.getItem('appVersion') &&
          context?.appVersion != sessionStorage.getItem('appVersion')
        ) {
          sessionStorage.setItem('appVersion', context.appVersion);
          window.location.reload();
          return;
        }

        // store app version if we don't have anything already
        this.storeAppVersion(context);

        this.addMutationObserver();

        if (this.props.ssrRenderComplete) {
          this.setState({ isRouteChange: false });
        }

        // set the sitecore context data and push the new route
        this.props.updateSitecoreContext({
          route: sitecore?.route,
          itemId: sitecore?.route?.itemId,
          ...sitecore?.context,
        });

        if (!this.props.sitecoreContext) {
          return null;
        }
      } else {
        /* no route?
        404 or perhaps page requiring user to log in (ie. authenticated content), hard reload page so server can return correct status */
        if (canUseDOM &&
          // don't reload page if using HMR via jss-start.ps1 (ie. localhost:3000)
          window?.location?.hostname !== 'localhost') {
          window.location.reload();
        }
      }
    });
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.props.route.match.params.lang || this.state.defaultLanguage;

    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
  }

  render() {
    const routeData = this.extractRouteData();

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData) {
      return null;
    }

    // store `ssrRenderComplete` for use by analytics to only dispatch `pageView` once per page
    if (canUseDOM) {
      window.ssrRenderComplete = this.props.ssrRenderComplete;
      window.pageIsStable = false;
    }

    // Get layout to render for route
    const Layout = LayoutFactory.resolveLayout(routeData.sitecore.route);

    if (!Layout) {
      return (
        <p>
          Could not find layout, routeData.sitecore.route:
          <br />
          <pre>{JSON.stringify(routeData.sitecore.route, null, 2)}</pre>
        </p>
      );
    }

    // Render the app's root structural layout
    return (
      <Layout
        context={routeData.sitecore.context}
        loading={this.state.isRouteChange}
        route={routeData.sitecore.route}
        loading={!this.props.ssrRenderComplete}
      />
    );
  }
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language, siteName) {
  const layoutServiceInstance = layoutServiceFactory.create();
  return layoutServiceInstance.fetchLayoutData(route, language).catch((error) => {
    if (error.response && error.response.status === 404 && error.response.data) {
      return error.response.data;
    }

    console.error('Route data fetch error', error, error.response);

    return null;
  });
}

export default withSitecoreContext({ updatable: true })(RouteHandler)
