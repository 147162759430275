import styled from 'styled-components';
import { spacing } from '_utils/props';
import {
  mq,
  svg,
} from '_utils/styles';


export const IconWrapper = styled.div`
  top: ${props => `${props.config.xs.top}px`};
  width: ${props => `${props.config.xs.iconSize}px`};
  ${({ theme, config }) => spacing({ theme, mr: { xs: `${config.xs.mr}px`, md: `${config.md.mr}px` } })};
  ${props => svg(props.config.xs.iconSize, props.config.xs.iconSize)};

  ${mq('md')} {
    top: ${props => `${props.config.md.top}px`};
    width: ${props => `${props.config.md.iconSize}px`};
    ${props => svg(props.config.md.iconSize, props.config.md.iconSize)};
  }
`;