import colors from '_containers/Theme/corporate/colors';

export default {
  border: colors.all.neutral200,
  iconColor: colors.all.neutralBlack,
  downloadAttributes: {
    color: colors.text.subtle,
  },
  downloadDescription: {
    color: colors.text.subtle
  },
  downloadLink: {
    color: colors.element.primary
  }

};
