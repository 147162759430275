import React, { useEffect } from 'react';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import InPageAnnouncement from '_components/Announcements/InPageAnnouncement';

import { AlertProps } from '../definitions';

const Alert: React.FC<AlertProps> = ({
    type
}) => {
    const mailAlertHeading = getDictionaryItem("form-contact-notification-mail-heading", "Please confirm your address");
    const mailAlertDescription = getDictionaryItem("form-contact-notification-mail-description", "We have received returned mail. Please review your addresses.");
    const phoneAlertHeading = getDictionaryItem("form-contact-notification-phone-heading", "Please update the phone number formatting");
    const phoneAlertDescription = getDictionaryItem("form-contact-notification-phone-description", "We're in the process of database maintenance");

    const fieldMapping = {
        mail: {
            heading: mailAlertHeading,
            description: mailAlertDescription
        },
        phone: {
            heading: phoneAlertHeading,
            description: phoneAlertDescription
        }
    }

    return (
        <InPageAnnouncement
            customIcon={{ targetItem: { name: 'error_outline' } }}
            description={{ value: fieldMapping[type]?.description }}
            title={{ value: fieldMapping[type]?.heading }}
            type={{ value: 'Red' }}
        />
    )
};

export default Alert;