import styled from 'styled-components';
import buttonSettings from '_containers/Theme/corporate/button'
import { button } from '_utils/styles';

export const BackToTopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const BackToTopButton = styled.button`
  ${button('slim', 'backToTop')}

  align-items: center;
  border-radius: ${Object.values(buttonSettings?.default?.borderRadius).map(corner => `${corner}px `)};
  display: inline-flex;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);

  svg {
    margin-left: 10px;
    height: ${({ theme }) => theme.button.slim.icon.sm.h}px;
    width: ${({ theme }) => theme.button.slim.icon.sm.w}px;
  }
`;
