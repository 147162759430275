import { compose } from 'ramda';
import React from 'react';
import { withEditMode } from '_containers/BaseComponent';
import Ad from './Ad';
import { AdvertisementProps } from './definitions';

import { PlaceholderContainer } from './StyledAd';

const Advertisement: React.FC<AdvertisementProps> = ({
  editMode,
  rendering
}) => {
  const datasource = rendering?.fields?.data?.datasource;
  const pageFields = rendering?.fields?.data?.pageFields;
  const adSlotID = datasource?.adverts?.adSlotID;
  const position = datasource?.adverts?.position;
  const uid = rendering?.uid;
  const adUid = uid.concat('-ads');

  const topicTagArray = pageFields?.topictags?.topics || [];
  const category = pageFields?.categoryInformation?.categoryLabelText;
  const topics = topicTagArray.map(item => item.topic);

  const keywords = [...topics, category].join();

  return (
    editMode ?
      <PlaceholderContainer>Advertisment <span>{position}</span> goes here </PlaceholderContainer>
      :
      <Ad
        adSlotID={adSlotID}
        position={position}
        keywords={keywords}
        uid={adUid}
      />
  );
}

export default compose(withEditMode)(Advertisement);
