import FocusTrap from 'focus-trap-react';
import React, { useEffect, useRef } from 'react';

import {
  withModalContext,
} from '_containers/ModalContext';

import ScreenReader from '_utils/components/ScreenReader';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  disableBodyScroll,
  enableBodyScroll,
} from '_utils/helpers';
import {
  usePrevious,
  useWindowSize,
} from '_utils/hooks';
import LargeCross from '_utils/icons/LargeCross';
import { BREAKPOINTS } from '_utils/styles/mq';

import { ModalProps } from './definitions';

import {
  CloseButton,
  CloseContainer,
  ContentContainer,
  CrossWrapper,
  Dialog,
  Heading,
  Layout
} from './StyledModal';

const Modal: React.FC<ModalProps> = ({ modal }) => {

  const {
    id,
    content,
    onClose,
    dismissable,
    shouldHideCloseButton,
    closeOnBreakpoint,
    onActivate,
    heading
  } = modal || {};

  const dialogRef = useRef();

  const contentContainerRef = useRef();

  const prevModalContent = usePrevious(Boolean(content));

  const windowSize = useWindowSize({ enabled: !!closeOnBreakpoint });

  useEffect(() => {
    if (windowSize?.width >= BREAKPOINTS[closeOnBreakpoint]) {
      onClose();
    }
  }, [windowSize?.width])

  useEffect(
    () => {
      const dialog = dialogRef.current;

      const contentContainer = contentContainerRef.current;

      const clickOutsideListener = (e) => {
        let target: HTMLElement = e.target;

        // iterate through parents until we find menu dialog or no more
        while (target) {
          if (target === contentContainer) {
            // target is in contentContainer, bail
            return;
          }
          // move up to next parent
          target = target.parentNode as HTMLElement;
        }
        // focus outside, close menu
        onClose();
      };

      // add/remove listener when menu opened/closed
      if (content && !prevModalContent) {
        disableBodyScroll(dialog);
        if (dismissable) {
          document.body.addEventListener('mousedown', clickOutsideListener, true);
        }
        // without setImmediate effect clean up runs immediately
      } else if (!content && prevModalContent) {
        if (dismissable) {
          document.body.removeEventListener('mousedown', clickOutsideListener, true);
        }
        enableBodyScroll(dialog);
      }

      // clean up
      return () => {
        if (dismissable) {
          document.body.removeEventListener('mousedown', clickOutsideListener, true);
        }
        enableBodyScroll(dialog);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content]
  );

  if (!content) {
    return null;
  }

  return (
    <Dialog
      aria-modal={true}
      ref={dialogRef}
      closeOnBreakpoint={closeOnBreakpoint}
    >
      <FocusTrap
        focusTrapOptions={{
          onActivate: () => {
            if (onActivate) {
              onActivate();
            }
          }
        }
        }>
        <Layout>
          {!shouldHideCloseButton && (
            <CloseContainer>
              <Heading>{heading}</Heading>
              <CloseButton onClick={() => onClose()}>
                <ScreenReader>{getDictionaryItem('accessibility-close-modal', 'close')}</ScreenReader>
                <CrossWrapper>
                  <LargeCross titleId={`modal-close-${id}`} ariaHidden={true} />
                </CrossWrapper>
              </CloseButton>
            </CloseContainer>
          )}
          <ContentContainer ref={contentContainerRef}>{content}</ContentContainer>
        </Layout>
      </FocusTrap>
    </Dialog>
  );
};

export default withModalContext(Modal);
