import styled from 'styled-components';

import ContentRichText from '_components/ContentRichText';
import { Grid, Spinner } from '_utils/components';
import { spacing } from '_utils/props';
import { color, headingH3, section } from '_utils/styles';

export const Container = styled.div`
  ${({ theme }) => section({ theme })};
  background: ${color('background.tint2')};
`;

export const PageContainer = styled(Grid.Container)``;

export const FormContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.complexFormElement.formContainer.borderColor};
  display: flex;
`;

export const SpinnerWrapper = styled.div`
  ${({ theme }) => spacing({ theme, my: 5 })};
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
`;

export const StyledSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

export const StepTrackerContainer = styled(Grid.Container)`
  ${({ theme }) => spacing({ theme, mt: 0, mb: 4, p: 0 })};
  max-width: 960px;
`;

export const IntroContainer = styled(Grid.Container)`
  ${({ theme }) => spacing({ theme, my: 5, mx: 0, p: 0 })};
  max-width: 960px;
`;

export const NarrowContainer = styled(Grid.Container)`
  ${({ theme }) => spacing({ theme, mt: 5, mb: 4, p: 0 })};
  max-width: 796px;
`;

export const Heading = styled.h2`
  ${headingH3}
`;

export const PrivacyPolicy = styled(ContentRichText)`
${({ theme }) => spacing({
  theme,
  pt: 4,
})}
`;
