import React from 'react';

import { BookHingeSVG } from './book-hinge';

import { BookHingeProps } from './definitions';

import {
  BookHingeContainer,
  BookHingeSVGContainer,
} from './StyledBookHinge';

export const BookHinge: React.FC<BookHingeProps> = ({
  desktopPlacement,
  mobilePlacement,
}) => {
  return (
    <BookHingeContainer
      desktopPlacement={desktopPlacement}
      mobilePlacement={mobilePlacement}
    >
      <BookHingeSVGContainer>
        <BookHingeSVG aria-hidden={true} />
      </BookHingeSVGContainer>
    </BookHingeContainer>
  );
};