import styled, {css} from 'styled-components';
import { fixedBody12 } from '_utils/styles/';

export const RadioLabel = styled.label`
  align-items: flex-start;
  display: inline-flex;
  margin-bottom: 6px;
  margin-right: 32px;
  max-width: 148px;
  position: relative;
  user-select: none;
  vertical-align: top;

  &:last-child {
    margin-right: 0;
  }

  input {
    &.formik-field-radio {
      flex-shrink: 0;
      height: 18px;
      margin-bottom: 18px;
      margin: 2px 13px 2px 1px;
      overflow: visible;
      position: relative;
      width: 18px;

      &:focus {
        outline-color: transparent;
      }

      ::before {
        background-color: ${({ theme }) => theme.form.check.background};
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.form.check.border};
        content: '';
        height: 20px;
        left: -1px;
        margin-right: 12px;
        position: absolute;
        top: -1px;
        width: 20px;
      }

      &:checked::after {
        border-width: 6px !important;
        border: 5px solid ${({ theme }) => theme.form.check.selectedBackground};
        border-radius: 50%;
        content: '';
        height: 11px;
        left: 3px;
        position: absolute;
        top: 3px;
        width: 5px;
      }

      &:hover::before {
        border-color: ${({ theme }) => theme.form.check.borderHover};
        border-width: 2px;
      }

      &:focus::before {
        border-color: ${({ theme }) => theme.form.check.borderFocus};
        border-width: 2px;
      }

      &:checked::before {
        background-color: ${({ theme }) => theme.form.check.background};
        border-color: ${({ theme }) => theme.form.check.selectedBorder};
        border-radius: 50%;
      }

      &:hover:checked::before {
        background-color: ${({ theme }) => theme.form.check.background};
        border-color: ${({ theme }) => theme.form.check.selectedBorderHover};
      }

      &:hover:checked::after {
        border-color: ${({ theme }) => theme.form.check.selectedBorderHover};
      }

      &:checked:focus::before {
        background-color: ${({ theme }) => theme.form.check.background};
        border-color: ${({ theme }) => theme.form.check.selectedBorder};
      }

      &:disabled::before {
        background-color: ${({ theme }) =>
          theme.form.check.backgroundDisabled} !important;
        border-color: ${({ theme }) =>
          theme.form.check.borderDisabled} !important;
        border-width: 1px !important;
      }

      &:disabled:checked::after {
        border-color: ${({ theme }) => theme.form.check.colorDisabled};
      }

      &.error-border::before {
        background-color: ${({ theme }) => theme.form.check.background};
        border-color: ${({ theme }) => theme.form.check.borderError};
      }
    }
  }
`;

export const RadioWithTick = styled(RadioLabel)`
  input {
    &.formik-field-radio {
      &:checked::after {
        border-width: 0 2px 2px 0 !important;
        border: solid ${({ theme }) => theme.form.check.background};
        border-radius: unset;
        content: '';
        height: 11px;
        left: 6px;
        position: absolute;
        top: 2px;
        transform: rotate(45deg);
        width: 5px;
      }

      &:checked::before {
        background-color: ${({ theme }) => theme.form.check.selectedBackground};
      }

      &:hover:checked::before {
        background-color: ${({ theme }) =>
          theme.form.check.selectedBackgroundHover};
      }

      &:checked:focus::before {
        background-color: ${({ theme }) =>
          theme.form.check.selectedBackgroundFocus};
      }
    }
  }
`;

export const InvalidMessageContainer = styled.div`
  ${fixedBody12};
  color: ${({ theme }) => theme.form.error.color};
`;

export const InvalidMessage = styled.p`
  margin: 0;
`;
