import {
  CountriesMap,
  HasCountryGotStates,
} from '_containers/LocationAPIContext/definitions';
import {
  fallbackMobilePhoneErrorMessage,
  fallbackPhoneErrorMessage,
  mobilePhoneRegexValidationPattern,
  phoneRegexValidationPattern,
} from '_utils/components/Form/definitions';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  DAY_FIRST_FORMAT,
  renderInvalidError,
  renderRequiredError
} from '_utils/helpers/form';
import { yup } from '_utils/types';

type ValidationDictionaryItems = any;

export const contactDetailsFormValidationDictionaryItems = {
  dateOfBirth: {
    invalid: renderInvalidError(getDictionaryItem('form-contact-dob', 'Date of birth'), '{field}', getDictionaryItem('invalid-date-error-message', "{field} must be in the format 'DD/MM/YYYY'")),
  },
  addressLine1: {
    required: renderRequiredError(getDictionaryItem('form-contact-address-line1', 'Address line 1')),
  },
  country: {
    isoCode2Letter: {
      required: renderRequiredError(getDictionaryItem('form-contact-country', 'Country')),
    },
  },
  workPhone: {
    invalid: getDictionaryItem('invalid-phone-error-message', fallbackPhoneErrorMessage),
    required: renderRequiredError(getDictionaryItem('form-contact-work-number', 'Business phone number')),
  },
  homePhone: {
    invalid: getDictionaryItem('invalid-phone-error-message', fallbackPhoneErrorMessage),
    required: renderRequiredError(getDictionaryItem('form-contact-home-number', 'Home phone number')),
  },
  mobilePhone: {
    invalid: getDictionaryItem('invalid-mobile-error-message', fallbackMobilePhoneErrorMessage),
    required: renderRequiredError(getDictionaryItem('form-contact-mobile-number', 'Mobile phone')),
  },
  mobilePhoneCountryCode: {
    required: renderRequiredError(getDictionaryItem('form-contact-mobile-country', 'Mobile country code')),
  },
  preferredPhoneNumber: {
    required: renderRequiredError(getDictionaryItem('form-contact-preferred-phone', 'Preferred phone number')),
  },
  isMailReturned: {
    returnedMail: getDictionaryItem('form-contact-notification-mail-description', 'We have received returned mail. Please review your addresses.'),
  },
};

export const commsValidationSchema = (validationDictionaryItems: ValidationDictionaryItems) => yup.object({
  workPhone: yup.string().ensure()
    .matches(phoneRegexValidationPattern, {
      message: validationDictionaryItems.workPhone.invalid,
      excludeEmptyString: true,
    })
    .when('preferredPhoneNumber', {
      is: 'Business Phone',
      then: yup.string().ensure().required(validationDictionaryItems.workPhone.required)
    }),

  homePhone: yup.string().ensure()
    .matches(phoneRegexValidationPattern, {
      message: validationDictionaryItems.homePhone.invalid,
      excludeEmptyString: true
    })
    .when('preferredPhoneNumber', {
      is: 'Home Phone',
      then: yup.string().ensure().required(validationDictionaryItems.homePhone.required),
    }),

  mobilePhone: yup.string().ensure()
    .matches(mobilePhoneRegexValidationPattern, {
      message: validationDictionaryItems.mobilePhone.invalid,
      excludeEmptyString: true
    })
    .when('preferredPhoneNumber', {
      is: 'Mobile Phone',
      then: yup.string().ensure().required(validationDictionaryItems.mobilePhone.required),
    }),

  mobilePhoneCountryCode: yup.string().ensure()
    .when('preferredPhoneNumber', {
      is: 'Mobile Phone',
      then: yup.string().ensure().required(validationDictionaryItems.mobilePhoneCountryCode.required),
    }),

  preferredPhoneNumber: yup.string().ensure().required(validationDictionaryItems.preferredPhoneNumber.required),

  // Email address handled by separate system so no need to validate here
  emailAddress: yup.string().ensure(),

  // validate in reverse - ie. if field is true, we fail test
  isMailReturned: yup.boolean().test('returned mail', validationDictionaryItems.isMailReturned.returnedMail, (val) => !val),
});

export const getValidationSchema = (
  countriesMap: CountriesMap,
  hasCountryGotStates: HasCountryGotStates,
  validationDictionaryItems: ValidationDictionaryItems,
) => {
  const personalValidationSchema = yup.object({
    salutation: yup.string().ensure(),
    firstName: yup.string().ensure(),
    surname: yup.string().ensure(),
    preferredName: yup.string().ensure(),
    dateOfBirth: yup.date().nullable().format(DAY_FIRST_FORMAT, true)
      .when('isMember', {
        is: false,
        then: yup.date()
          .format(DAY_FIRST_FORMAT, true)
          .typeError(validationDictionaryItems.dateOfBirth.invalid)
      })
  });

  const addressValidationSchema = yup.object({
    primaryContactName: yup.string().ensure(),
    addressLine1: yup.string().ensure().required(validationDictionaryItems.addressLine1.required),
    addressLine2: yup.string().ensure(),
    addressLine3: yup.string().ensure(),
    postCode: yup.string().when('country.isoCode2Letter', (isoCode, schema) => {
      const country = countriesMap && countriesMap[isoCode];

      if (country?.hasPostcode) {
        schema = schema.required(renderRequiredError(country.postcodeLabel));
      }

      if (country?.postcodeRegex) {
        schema = schema.matches(country.postcodeRegex, renderInvalidError(country.postcodeLabel));
      }

      return schema;
    }),
    city: yup.string().when('country.isoCode2Letter', (isoCode, schema) => {
      return countriesMap && countriesMap[isoCode]?.hasSuburb ? schema.required(renderRequiredError(countriesMap[isoCode]?.suburbLabel)) : schema;
    }),
    state: yup.object().when('country.isoCode2Letter', (isoCode, schema) => {
      if (!countriesMap || !countriesMap[isoCode]?.hasState) {
        return schema;
      }

      // State with dropdown:
      if (hasCountryGotStates(countriesMap?.[isoCode])) {
        return yup.object({
          isoCode: yup.string().required(renderRequiredError(countriesMap[isoCode]?.stateLabel)),
          name: yup.string()
        });
      }

      // State with text input:
      return yup.object({
        isoCode: yup.string(),
        name: yup.string().required(renderRequiredError(countriesMap[isoCode]?.stateLabel))
      });
    }),
    country: yup.object({
      isoCode2Letter: yup.string().required(validationDictionaryItems.country.isoCode2Letter.required)
    }),
    isPrivate: yup.boolean(),
    deliveryIdentifier: yup.string().ensure(),
    deliveryIdentifierProvider: yup.string().ensure()
  });

  return yup.object().shape({
    contact: yup.object({
      personal: personalValidationSchema,
      comms: commsValidationSchema(validationDictionaryItems),
    }),
    billingAddress: addressValidationSchema,
    isSameAddress: yup.boolean(),
    shippingAddress: yup.object().when('isSameAddress', {
      is: false,
      then: addressValidationSchema
    })
  });
}