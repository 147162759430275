import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagNavLink = styled(NavLink)`
  text-decoration: none;

  &:focus {
    outline-offset: -3px;
  }

  &:active {
    span {
      background-color:  ${({ theme }) => theme?.tag?.backgroundPressed};
      border:  ${({ theme }) => theme?.tag?.borderPressed};
    }
  }
`;
