import { useFormikContext } from 'formik';
import { compose } from 'ramda';
import React, { useContext } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { withEditMode } from '_containers/BaseComponent';
import { ContactDetailsFormContext, ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';
import {
  FormContext,
  withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import FieldSummary from '_utils/components/Form/FieldSummary';
import {
  Field,
  FormSummary,
} from '_utils/components/Form/StyledFormSection';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import {
  formatDate,
  renderSelectPlaceholderForField,
} from '_utils/helpers/form';
import FormLabel from '_utils/components/Form/Label';
import Dropdown from '_utils/components/Form/Dropdown';
import TextInput from '_utils/components/Form/TextInput';
import DateInput from '_utils/components/Form/DateInput';

import { ContactDetailsFormProps } from '../definitions';
import mockData from '../mock.json';

const PersonalDetailsForm: React.FC<ContactDetailsFormProps> = ({
  editMode,
  mockContactProfile,
  rendering,
  contactProfileApi
}) => {
  const formContext = useContext<ContactDetailsFormContext>(FormContext);
  const sectionContext = formContext?.personalDetails;

  const { values } = useFormikContext<ContactDetailsFormValues>();
  const referenceData = contactProfileApi?.result;
  const salutationsRefData = referenceData?.data?.referenceData?.salutations?.items;
  const genderRefData = referenceData?.data?.referenceData?.genderCodes?.items;
  const atsiOriginRefData = referenceData?.data?.referenceData?.indigenousChoices?.items;

  const uid = `personal-details`;

  // render component with mock data when in Experience Editor
  const contactProfile =
    !editMode
      ? contactProfileApi?.result?.data
      : mockContactProfile ?? mockData?.mockContactProfile;

  const heading = getDictionaryItem('form-contact-section-personal-details', 'Personal details');
  const updateLabel = getDictionaryItem('form-renewal-button-update', 'Update');
  const salutationLabel = getDictionaryItem("form-contact-salutation", "Salutation");
  const preferredNameLabel = getDictionaryItem("form-contact-preferred-name", "Preferred name");
  const dateOfBirthLabel = getDictionaryItem("form-contact-dob", "Date of birth");
  const genderLabel = getDictionaryItem("form-contact-gender", "Gender");
  const atsiOriginLabel = getDictionaryItem("form-contact-atsi-origin-entry", "Are you of Aboriginal or Torres Strait Islander origin?");
  const pleaseSelectPlaceholder = getDictionaryItem("form-placeholder-please-select", "Please select");
  const genderPlaceholder = renderSelectPlaceholderForField(genderLabel);

  const preferredNameOrderOptions =  
  [{code: 1, name: "None"}, {code: 2, name: "First Name Last Name"}, {code: 3, name: "Last Name First Name"}]

  return (
    <EditableSectionContainer
      className="personal-details-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      <EditableSectionHeader
        icon="perm_identity"
        sectionIdentifier={uid}
        updateLabel={updateLabel}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <>
            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor="contact.personal.salutation">{salutationLabel}</FormLabel>
                <Dropdown
                  defaultValue={values?.contact?.personal?.salutation}
                  id="contact.personal.salutation"
                  multiple={false}
                  name="contact.personal.salutation"
                  options={salutationsRefData?.map(item => item?.name) ?? []}
                  placeholder={pleaseSelectPlaceholder}
                />
              </Field>
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FieldSummary
                  isEditing
                  labelDictionaryKey="form-contact-given-name"
                  labelFallback="Given name"
                  value={contactProfile?.contact?.personal?.firstName}
                />
              </Field>
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FieldSummary
                  isEditing
                  labelDictionaryKey="form-contact-family-name"
                  labelFallback="Family name"
                  value={contactProfile?.contact?.personal?.surname}
                />
              </Field>
            </FormSummary>
            <FormSummary>
              {contactProfile?.contact?.personal?.middleName !== null && contactProfile?.contact?.personal?.middleName !== '' &&
                <Field config={{ col: { xs: 12, sm: 4 } }}>
                  <FieldSummary
                    isEditing
                    labelDictionaryKey="form-contact-middle-name"
                    labelFallback="Middle name"
                    value={contactProfile?.contact?.personal?.middleName}
                  />
                </Field>
              }
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor="preferredName">{preferredNameLabel}</FormLabel>
                <TextInput
                  id="preferredName"
                  name="contact.personal.preferredName"
                  maxLength={50}
                />
              </Field>
            </FormSummary>
            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor="contact.personal.preferredNameOrder">
                Order of given and family name for printed correspondence
                  </FormLabel>
                  <Dropdown
                    defaultValue={values?.contact?.personal?.preferredNameOrder}
                    id="contact.personal.preferredNameOrder"
                    multiple={false}
                    name="contact.personal.preferredNameOrder"
                    options={preferredNameOrderOptions?.map(item => item?.name) ?? []}
                    placeholder={pleaseSelectPlaceholder}
                  />
                </Field>
              </FormSummary>
              <FormSummary>
                <Field config={{ col: { xs: 12, sm: 4 } }}>
                { // Only allow editing if DOB not set and is not a member
                  contactProfile?.contact?.personal?.dateOfBirth ||
                    contactProfile?.contact?.membership?.isMember
                    ? <FieldSummary
                      isEditing
                      labelDictionaryKey="form-contact-dob"
                      labelFallback="Date of birth"
                      value={formatDate(contactProfile?.contact?.personal?.dateOfBirth || '', 'DAY_FIRST')}
                    />
                    : <>
                      <FormLabel htmlFor="dateOfBirth">{dateOfBirthLabel}</FormLabel>
                      <DateInput
                        name="contact.personal.dateOfBirth"
                        placeholder="DD/MM/YYYY"
                        disabled={false}
                        type="text"
                        maxLength={10}
                        inputMode="numeric"
                      />
                    </>
                }
              </Field>
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                {contactProfile?.contact?.personal?.gender
                  ? <FieldSummary
                    isEditing
                    labelDictionaryKey="form-contact-gender"
                    labelFallback="Gender"
                    value={contactProfile?.contact?.personal?.gender}
                  />
                  : <>
                    <FormLabel htmlFor="contact.personal.gender">{genderLabel}</FormLabel>
                    <Dropdown
                      defaultValue={values?.contact?.personal?.gender}
                      id="contact.personal.gender"
                      multiple={false}
                      name="contact.personal.gender"
                      options={genderRefData?.map(item => item?.name) ?? []}
                      placeholder={genderPlaceholder}
                    />
                  </>
                }
              </Field>
            </FormSummary>
            <FormSummary>
              <Field config={{ col: { xs: 12, sm: 4 } }}>
                <FormLabel htmlFor="contact.personal.indigenousIdentity">{atsiOriginLabel}</FormLabel>
                <Dropdown
                  defaultValue={values?.contact?.personal?.indigenousIdentity}
                  id="contact.personal.indigenousIdentity"
                  multiple={false}
                  name="contact.personal.indigenousIdentity"
                  options={atsiOriginRefData?.map(item => item?.name) ?? []}
                  placeholder={pleaseSelectPlaceholder}
                />
              </Field>
            </FormSummary>
            {/* @ts-ignore: issue with sitecore supplied type for ComponentRendering */}
            <Placeholder name="jss-form-section-help" rendering={rendering} />
          </>
        ) : (
          <FormSummary>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-full-name"
                labelFallback="Full name"
                value={contactProfile?.contact?.personal?.fullName}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-preferred-name"
                labelFallback="Preferred name"
                value={contactProfile?.contact?.personal?.preferredName}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 12 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-name-order"
                labelFallback="Order of given and family name for printed correspondence"
                value={contactProfile?.contact?.personal?.preferredNameOrder}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-dob"
                labelFallback="Date of birth"
                value={formatDate(contactProfile?.contact?.personal?.dateOfBirth || '', 'DAY_FIRST')}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-gender"
                labelFallback="Gender"
                value={contactProfile?.contact?.personal?.gender}
              />
            </Field>
            <Field config={{ col: { xs: 12, sm: 6 } }}>
              <FieldSummary
                labelDictionaryKey="form-contact-atsi-origin"
                labelFallback="Aboriginal or Torres Strait Islander origin"
                value={contactProfile?.contact?.personal?.indigenousIdentity}
              />
            </Field>
          </FormSummary>
        )
      }
    </EditableSectionContainer>
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('personalDetails'),
)(PersonalDetailsForm);
