import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { ImageSize } from '_utils/types/enums';
import {
  createSrcSet,
} from '_utils/media';

import {
  ComponentLabel,
  Container,
  ContentWrapper,
  CoverImage,
  CoverImageWrapper,
  Divider,
  LinkButton,
  PageContainer,
  Title,
  ButtonWrapper,
  LinkButtonSub
} from "./StyledMagazine";

import { MagazineProps } from "./definitions";

const Magazine: React.FC<MagazineProps> = ({
  componentLabel,
  coverImage,
  ctaLabel,
  editMode,
  link,
  title,
  subscribeToNewsletterLink,
  resetMenu
}) => {
  const menuSubscribeLabel = getDictionaryItem('menu-button-subscribe-to-newsletter', 'Subscribe to newsletter');

  return (
    <>
      <Container>
        <PageContainer>
          <ButtonWrapper onClick={resetMenu}><LinkButtonSub
            to={subscribeToNewsletterLink || '/'}
          >{menuSubscribeLabel}</LinkButtonSub>
          </ButtonWrapper>
          <Title>
            <Text
              field={{ value: title }}
              editable={false}
            />
          </Title>
          <ContentWrapper>
            <CoverImageWrapper>
              <CoverImage
                className="lazyload"
                field={
                  editMode
                    ? coverImage
                    : {
                      value: {
                        src: coverImage?.value?.src,
                        alt: coverImage?.value?.alt,
                        'data-srcset': createSrcSet(coverImage?.value?.src, [
                          ImageSize.MW_128,
                          ImageSize.MW_256
                        ])
                      }
                    }
                }
                width={ImageSize.MW_128}
                height={ImageSize.MW_256}
              />
            </CoverImageWrapper>
            <ComponentLabel>{componentLabel}</ComponentLabel>
          </ContentWrapper>
          <LinkButton
            className="magazineLink"
            field={{ value: link }}
            fallbackTitle={ctaLabel}
            hideExternalIcon="true"
          />
        </PageContainer>
      </Container>
      <Divider />
    </>
  )
}

export default Magazine;