import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, { FC } from 'react';

import {
  withEditMode
} from '_containers/BaseComponent';
import { withDigitalDataContext } from '_containers/DigitalDataContext';

import { imagePlaceholderSrc } from '_utils/styles';
import { ImageRatio, ImageSize } from '_utils/types/enums';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import { PodcastProps } from './definitions';

import {
  Figure,
  Image,
  PlatformLink,
} from './StyledPlatform';
import { convertSrcJss } from '_utils/media';
import { getImageAttrsByRatio } from '_utils/helpers/image';

const Platform: FC<PodcastProps> = ({
  digitalData,
  icon,
  link,
  setDigitalData,
  title,
}) => {

  const handleClick = (title: string) => {
    if (!canUseDOM) {
      return;
    }

    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['podcast'],
          {
            podcastPlatform: title
          },
          digitalData
        )
      );

      emitTrackEvent('podcastSubscribe');
    }
  }

  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_24, ImageRatio.Ratio_1By1);
  const linkProps = { ...link?.jss?.value, target: '_blank' }

  return (
    <PlatformLink
      childrenOnly
      fallbackTitle={'follow podcast'}
      field={{ value: linkProps }}
      hideExternalIcon="true"
      linkTheme='secondary'
      onClick={() => handleClick(title?.jss?.value)}
    >
      <Figure>
        <Image
          className="lazyload"
          draggable={false}
          field={
            {
              value: {
                src: imagePlaceholderSrc,
                alt: '',
                ...{ 'data-src': `${convertSrcJss(icon?.jss?.value?.src, ImageSize.MW_24, ImageSize.MW_24)}` },
              }
            }
          }
          {...defaultImageSize}
        />
      </Figure>
      <div>{title?.jss?.value}</div>
    </PlatformLink>
  )
};

export default compose(
  withDigitalDataContext,
  withEditMode,
)(Platform);