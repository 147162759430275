import { motion } from '_containers/Theme/shared';

export default (
  properties: string | string[] = 'all',
  speed = 'default',
  delay = 'none',
  ease = motion.ease
) => {
  let propertiesToMap = [];

  if (typeof properties === 'string') {
    propertiesToMap.push(properties);
  } else {
    propertiesToMap = properties;
  }

  return propertiesToMap
    .map((prop) => `${prop} ${ease} ${motion.timing[speed]} ${motion.timing[delay]}`)
    .join(', ');
};
