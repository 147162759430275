import { useRef } from 'react';

export interface IValues {
  setTimer: (fn: any, delay: number) => void;
  cancelTimer: () => void;
}

export default function useTimer(): IValues {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const cancelTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const setTimer = (fn: any, delay = 1000) => {
    cancelTimer();

    if (typeof fn === 'function') {
      timeoutRef.current = setTimeout(() => {
        fn();
        cancelTimer();
      }, delay);
    }
  };

  return { setTimer, cancelTimer };
}
