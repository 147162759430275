import styled from 'styled-components';

import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import {
  color,
  ie,
  mq,
} from '_utils/styles';

export const StickyFooterContainer = styled.div`
  background-color: ${({ theme }) => theme.complexFormElement.stickyFooter.background};
  border-top: 1px solid ${({ theme }) => theme.complexFormElement.stickyFooter.borderColor};
  bottom: ${({ editMode }) => editMode ? '0' : '-1px'};
  position: ${({ editMode }) => editMode ? 'relative' : 'sticky'};
  z-index: 1; // to lift above Renewal form content

  ${ie} {
    bottom: ${({ editMode, isSticky }) => !editMode && isSticky ? '0' : '-1px'};
    position: ${({ editMode, isSticky }) => !editMode && isSticky ? 'fixed' : 'relative'};
    width: 100%;
  }

  ${({ theme }) => spacing({
  theme,
  pt: {
    xs: '12px',
    md: '16px',
  },
  pb: {
    xs: '12px',
  }
})}

  ${mq('md')} {
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }

  @media all and (min-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-bottom: 16px;
  }

  ${Grid.Container} {
    ${mq.lessThan('md')} {
      padding: 0;
    }
  }
`;
