import React from 'react';
import { NavLink, Link as ReactLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MOTION_SPEED_FAST } from '_containers/Theme/global';

import {
  button,
  buttonReset,
  depth,
  fixedTitle14,
  fixedTitle16,
  fixedTitle18,
  ie,
  listReset,
  mq,
  safeFocusRemoval,
  svg,
  textBody1,
  textBody2,
} from '_utils/styles';
import { spacing } from '_utils/props';


export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
  display: inline-grid;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: transform ${() => `${MOTION_SPEED_FAST}ms`} cubic-bezier(0.42, 0, 0.59, 1);
  /* delay to allow menu transition bw mobile/table opposite sides of screen */
  transition-delay: opacity .1s;
  width: 100%;
  z-index: ${depth.MENU};

  &.menu-active {
    opacity: 1;
    transform: translateX(0);
  }

  margin-top: 0px;

  ${mq('md')} {
    height: 100%;
    min-height: auto;
    overflow: visible;
    position: absolute;
    transform: translateX(-100%);
    width: 100%;
    margin-top: 60px;
  }

  ${mq('lg')} {
    width: 1024px;
    margin-top: 0;
  }

  // hide scrollbars if possible
  * {
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(225, 225, 225, 0.1);
      border-radius: 20px;
    }
    scrollbar-color: rgba(225, 225, 225, 0.1) ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    scrollbar-width: thin;
  }
`;

export const NavToolbar = styled.div`
  ${({ theme }) => spacing({ theme, pl: '20px' })};
  border-bottom: 1px solid ${({ theme }) => theme.mainMenu.toolbar.divider};
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.pageHeader.height.xs}px;
  position: absolute;
  top: 0px;
  width: 100%;

  ${ie} {
    display: none !important;
  }

  ${mq('md')} {
    border: 0;
  }

  ${mq.lessThan('md')} {
    background-color: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    position: fixed;
    z-index: 999;
    top: 96px;
  }
`;

export const NavToolbarControl = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  ${mq('md')} {
    display: none;
  }
`;

export const NavToolbarTabs = styled.div`
  display: flex;
`;

export const NavToolbarTabItem = styled.button`
  ${buttonReset};
  ${fixedTitle16};
  align-items: center;
  box-shadow: inset 0px -4px 0px ${({ theme }) => theme.mainMenu.toolbar.activeHighlight};
  color: ${({ theme }) => theme.mainMenu.toolbar.color};
  color: ${({ theme }) => theme.mainMenu.toolbar.colorActive};
  display: flex;
  height: 100%;
`;


export const NavToolbarClose = styled.div`
  display: flex;
  margin-left: auto;

  ${({ submenuActive }) => {
    if (submenuActive) {
      return css`
        ${mq('md')} {
          display: none;
        }
      `;
    }
  }}
`;

export const MenuBody = styled.div`
  flex: 1;
`;

export const MenuBodyNav = styled.div`
  background: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
  min-height: 100%;
`;


export const List = styled.ul`
  ${listReset};
  ${({ theme }) => spacing({ theme, py: { xs: 4, md: 0 } })};
  display: inline-block;
  opacity: 1;
  overflow-y: auto;
  transition: opacity ${() => `${MOTION_SPEED_FAST + 10}ms`};
  width: 100%;

  & & {
    background: ${({ theme }) =>
    theme.mainMenu.container.backgroundSecondary};
    opacity: 1;
  }

  &.primaryNavigation {
    background: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    height: calc(100% - ${({ theme }) => theme.pageHeader.height.xs + 96}px);
    margin-top: ${({ theme }) => theme.pageHeader.height.xs + 96}px;

    ${mq('md')} {
      column-count: 2;
      height: calc(100% - ${({ theme }) => theme.pageHeader.height.xs}px);
      margin-top: ${({ theme }) => theme.pageHeader.height.xs}px;
    }
  }
`;

export const ListWrapper = styled.div`
  display: flex;
`;

export const SecondaryContainerWrapper = styled.div`
  background: ${({ theme }) => theme.mainMenu.container.backgroundSecondary};
  display: flex;
  flex-direction: column;
  min-width: 100%;

  ${mq.lessThan('md')} {
    flex-direction: column;
    order: 1;
  }

  ${mq('md')} {
    min-width: 310px;
  }

  ${mq('lg')} {
    min-width: 342px;
  }
`

export const PrimaryContainerWrapper = styled.div`
  display: flex;
  position: relative;

  ${mq.lessThan('md')} {
    flex-direction: column
  }
`;

export const InnerNavWrapper = styled.div`
  display: flex;

  ${mq.lessThan('md')} {
    flex-direction: column;
    overflow-y: auto;
    order: 2;
  }
`


export const Item = styled.li`
  color: ${({ theme }) => theme.mainMenu.item.color};
  display: inline-block;
  flex: none;
  width: 100%;


  &.loginButton {
    ${mq('md')} {
      display: none;
    }
  }
`;

export const Link = styled(NavLink)`
  ${({ theme }) => spacing({ theme, py: '16px', pl: { xs: '20px', md: 5 }, pr: { xs: '20px', md: 3 } })};
  align-items: center;
  color: ${({ theme }) => theme.mainMenu.item.color};
  display: flex;
  flex-wrap: nowrap;
  outline: 0;
  text-decoration: none;

  body:not(.safe-focus-removal) &:focus {
    box-shadow: inset 0 3px 0 0 ${({ theme }) => theme.colors.element.focused}, inset 0 -3px 0 0 ${({ theme }) => theme.colors.element.focused};
    outline: 0;
  }

  &:focus {
    ${safeFocusRemoval};

    body:not(.safe-focus-removal) & {
      background-color: ${({ theme }) => theme.mainMenu.item.backgroundActive};
    }

    .primaryNavigation & {
      body:not(.safe-focus-removal) & {
      background-color: ${({ theme }) => theme.mainMenu.item.primaryNavigation.backgroundActive};
      }
    }
  }

  &[href] {
    position: relative;
    transition: background-color 0.1s linear;

    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.mainMenu.item.backgroundActive};

      .primaryNavigation & {
        background-color: ${({ theme }) => theme.mainMenu.item.primaryNavigation.backgroundActive};
      }
    }
  }
`;


export const LabelWrapper = styled.div`
  flex: 1;
`;

export const Label = styled.span`
  ${fixedTitle16};
  color: ${({ theme }) => theme.mainMenu.item.color};
  display: inline-flex;
  font-weight: bold;
  line-height: 1.2 !important;

  ${Link}[href]:active &,
  ${Link}[href]:hover & {
    color: ${({ theme }) => theme.mainMenu.item.colorHighlight};
  }

  ${mq.lessThan('md')} {
    ${fixedTitle14}
  }

  .primaryNavigation & {
    ${mq('md')} {
      ${fixedTitle18};
    }

    ${mq.lessThan('md')} {
    ${fixedTitle16}
  }
  }
`;


export const LinkEndIcon = styled.div`
  ${({ theme }) => spacing({ theme, ml: '16px' })};
  ${svg(24, 24)};
  flex-shrink: 0;
  height: 24px;
  width: 24px;

  path {
    fill: ${({ theme }) => theme.mainMenu.item.color};

    ${Link}[href]:active &,
    ${Link}[href]:hover &,
    .submenu-active > a & {
      fill: ${({ theme }) => theme.mainMenu.item.colorHighlight};
    }
  }

  ${({ showOnHover }) => {
    if (showOnHover) {
      return css`
        opacity: 0;

        [href]:hover &,
        [href]:focus & {
          opacity: 1;
        }
      `;
    }
  }}
`;

export const MenuCloseButton = styled.button`
  ${buttonReset};
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 64px;

  &:before,
  &:after {
    background-color: ${({ theme }) => theme.mainMenu.toolbar.buttonColor};
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 20px;
  }

  &:before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }

  &:after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme }) =>
    theme.mainMenu.toolbar.activeHighlight};
    }
  }

  &:focus {
    ${safeFocusRemoval}
    outline-color: ${({ theme }) => theme.colors.element.focused};
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 2px;
    position: relative;
  }
`;


export const ButtonWrapper = styled.div`
  ${({ theme }) => spacing({ theme, px: '20px' })};
  border-bottom: 1px solid ${({ theme }) => theme.mainMenu.toolbar.divider};
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.pageHeader.height.xs}px;
  position: absolute;
  top: 0px;
  width: 100%;

  ${ie} {
    display: none !important;
  }

  ${mq('md')} {
    border: 0;
  }

  ${mq.lessThan('md')} {
    background-color: ${({ theme }) => theme.mainMenu.container.backgroundPrimary};
    position: fixed;
    z-index: 999;
  }
`;

export const LinkButtonSub = styled(ReactLink)`  
  ${button('default', 'primary')};
  ${textBody1};
  width: 100%;     
  margin: 24px 0;
  z-index: 999;

  ${mq('md')} {
    display: none;
  }


  path {
    fill: ${({ theme }) => theme.internalPageBanner.linkButton.pathFillColor};
  }
  

  a:focus:before {
     outline: 2px solid ${({ theme }) => theme?.internalPageBanner?.outline?.focusColor} !important;
  }

`;