import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';
import { isValidImage } from '_utils/validationChecks';

export const ProfilePageBannerFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.title',
    errorMessage: 'A page title must be set or this component will not render',
    required: true
  },
  {
    field: 'rendering.fields.data.datasource.subTitle',
    errorMessage: 'A subtitle must be set or this component will not render',
    required: true
  },
  {
    field: 'rendering.fields.data.datasource.image',
    errorMessage: 'An image must be set or this component will not render',
    validators: [isValidImage]
  }
];
