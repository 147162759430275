export enum ImageSize {
  'MW_24' = 24,
  'MH_112' = 112,
  'MW_128' = 128,
  'MW_248' = 248,
  'MW_256' = 256,
  'MW_304' = 304,
  'MW_384' = 384,
  'MW_512' = 512,
  'MW_640' = 640,
  'MW_768' = 768,
  'MW_896' = 896,
  'MW_1024' = 1024,
  'MW_1280' = 1280,
  'MW_1664' = 1664,
}


export enum ImageHeight16by9 {
  'MW_128' = 72,
  'MW_256' = 144,
  'MW_384' = 216,
  'MW_512' = 288,
  'MW_640' = 360,
  'MW_768' = 432,
  'MW_896' = 504,
  'MW_1024' = 576,
  'MW_1280' = 720,
  'MW_1664' = 936,
}

export enum ImageRatio {
  'Ratio_1By1' = 1,
  'Ratio_2By1' = 2,
  'Ratio_2By3' = 2 / 3,
  'Ratio_3By1' = 3,
  'Ratio_3By2' = 3 / 2,
  'Ratio_3By4' = 3 / 4,
  'Ratio_4By3' = 4 / 3,
  'Ratio_5By2' = 5 / 2,
  'Ratio_16By9' = 16 / 9,
  'Ratio_golden' = 1 / 1.61,
}
