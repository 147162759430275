import React from 'react';
import { withSvg } from '../Svg';

const SvgPlanned = () => (
  <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2H15V1C15 0.734863 14.8945 0.480469 14.707 0.292969C14.5195 0.105469 14.2656 0 14 0C13.8516 0 13.707 0.0322266 13.5781 0.0932617C13.4727 0.14209 13.375 0.209473 13.293 0.292969C13.1055 0.480469 13 0.734863 13 1V2H7V1C7 0.891113 6.98047 0.78418 6.94531 0.682617C6.89844 0.537109 6.81641 0.40332 6.70703 0.292969C6.51953 0.105469 6.26562 0 6 0C5.73438 0 5.48047 0.105469 5.29297 0.292969C5.10547 0.480469 5 0.734863 5 1V2H3C2.20312 2 1.44141 2.31592 0.878906 2.87891C0.316406 3.44141 0 4.20459 0 5V17C0 17.7954 0.316406 18.5586 0.878906 19.1211C1.07812 19.3198 1.30078 19.4878 1.54297 19.6221C1.98438 19.8677 2.48438 20 3 20H9C9.55229 20 10 19.5523 10 19C10 18.4477 9.55228 18 9 18H3C2.73438 18 2.48047 17.8945 2.29297 17.707C2.10547 17.5195 2 17.2651 2 17V10H19C19.5523 10 20 9.55229 20 9V5C20 4.20459 19.6836 3.44141 19.1211 2.87891C18.5586 2.31592 17.7969 2 17 2ZM18 8H2V5C2 4.73486 2.10547 4.48047 2.29297 4.29297C2.48047 4.10547 2.73438 4 3 4H5V5C5 5.26514 5.10547 5.51953 5.29297 5.70703C5.48047 5.89453 5.73438 6 6 6C6.26562 6 6.51953 5.89453 6.70703 5.70703C6.79297 5.61914 6.86328 5.5166 6.91406 5.40576C6.97266 5.2793 7 5.14111 7 5V4H13V5C13 5.09863 13.0156 5.19531 13.043 5.28809C13.0898 5.44482 13.1758 5.58936 13.293 5.70703C13.4805 5.89453 13.7344 6 14 6C14.2656 6 14.5195 5.89453 14.707 5.70703C14.8945 5.51953 15 5.26514 15 5V4H17C17.2656 4 17.5195 4.10547 17.707 4.29297C17.8945 4.48047 18 4.73486 18 5V8Z"
        fill="black"
      />
      <path
        d="M20.3 20.7529C20.4176 20.6739 20.5171 20.5709 20.5919 20.4506C20.6666 20.3303 20.715 20.1954 20.7337 20.055C20.7525 19.9146 20.7412 19.7718 20.7006 19.6361C20.66 19.5004 20.591 19.3748 20.4982 19.2678C20.4054 19.1607 20.291 19.0746 20.1624 19.0151C20.0338 18.9556 19.8941 18.9242 19.7524 18.9228C19.6108 18.9213 19.4705 18.9501 19.3407 19.007C19.211 19.0639 19.0949 19.1477 19 19.2529C18.6364 19.5735 18.2006 19.8013 17.7298 19.9167C17.259 20.032 16.7672 20.0316 16.2966 19.9154C15.826 19.7992 15.3906 19.5707 15.0276 19.2495C14.6646 18.9283 14.3848 18.5238 14.2122 18.0709C14.0397 17.6179 13.9795 17.1298 14.0367 16.6485C14.094 16.1672 14.267 15.7069 14.541 15.307C14.8151 14.9071 15.1819 14.5796 15.6101 14.3525C16.0384 14.1254 16.5152 14.0055 17 14.0029C17.421 14.0038 17.8372 14.0933 18.2214 14.2655C18.6056 14.4378 18.9493 14.6891 19.23 15.0029H18C17.7348 15.0029 17.4804 15.1083 17.2929 15.2958C17.1053 15.4833 17 15.7377 17 16.0029C17 16.2681 17.1053 16.5225 17.2929 16.71C17.4804 16.8976 17.7348 17.0029 18 17.0029H21C21.2652 17.0029 21.5195 16.8976 21.7071 16.71C21.8946 16.5225 22 16.2681 22 16.0029V13.0029C22 12.7377 21.8946 12.4833 21.7071 12.2958C21.5195 12.1083 21.2652 12.0029 21 12.0029C20.7348 12.0029 20.4804 12.1083 20.2929 12.2958C20.1053 12.4833 20 12.7377 20 13.0029C19.2442 12.4349 18.3429 12.0929 17.4006 12.0164C16.4583 11.9399 15.5136 12.1322 14.6761 12.5708C13.8386 13.0095 13.1427 13.6766 12.669 14.4948C12.1953 15.313 11.9633 16.2487 11.9998 17.1934C12.0364 18.1381 12.3401 19.0531 12.8756 19.8322C13.4111 20.6113 14.1565 21.2226 15.0254 21.5953C15.8943 21.9679 16.851 22.0865 17.7846 21.9374C18.7182 21.7883 19.5904 21.3776 20.3 20.7529Z"
        fill="black"
      />
  </>
);

export default withSvg(24, 24)(SvgPlanned);
