import { canUseDOM } from 'exenv';
import { lensPath, set } from 'ramda'
const packageConfig = require('../../../../package.json');
import { logInDev } from '_utils/helpers/dev';

import { SearchDataType } from '_containers/DigitalDataContext/definitions';

const STAFF_USER_ROLE = 'extranet\\staff';

// 1 min timeout (going by 60 FPS) - to make sure OneTrust has mounted Adobe Launch scripts.
const numAttemptsCheckLoaded = 3600;

// polling fn to check Adobe _satellite available
export const checkSatelliteWithCallback = (callback, attempts = 0) => {
  if (!canUseDOM) {
    callback(null);
    return;
  }

  if (window?._satellite?.track) {
    callback(callback);
  } else {
    requestAnimationFrame(() => {
      if (attempts < numAttemptsCheckLoaded) {
        // keep trying
        checkSatelliteWithCallback(callback, attempts + 1);
      } else {
        // give up
        return;
      }
    });
  }
};

// delay fire event to allow datalayer update prior
const emit = (name) => {
  setTimeout(() => {
    // logging in DEV to aid tracing events
    if (packageConfig?.config?.debugAnalytics && window?.cpa?.digitalData) {
      logInDev(
        JSON.stringify(
          {
            trackEventName: name,
            digitalData: window.cpa.digitalData
          }));

    }
    window._satellite.track(name);
  }, 50);
};

export const emitTrackEvent = (name: string) => {

  if (!canUseDOM) {
    console.warn(`emitTrackEvent: cannot use DOM - event: ${name}`);
    return;
  }

  if (window?._satellite?.track) {
    emit(name);
  } else {
    console.warn(`emitTrackEvent: _satellite not available - could not emit: ${name}`);
    checkSatelliteWithCallback(() => emit(name));
  }
};

/**
 * @param path: array specifying the node in object you wish to update
 * @param data: the data you wish to set
 * @param digitalData: the object that you are mutating
 */
export const setObjectData = (path: Array<string>, data: any, digitalData: any) => {
  if (!digitalData || !path || !data) {
    return;
  }
  const newObj = set(
    lensPath(path),
    data,
    digitalData
  )

  return newObj;
};

export const defaultSearchData = {
  search: <SearchDataType>{
    filterName: '',
    filterValue: '',
    searchTerm: '',
    numberOfResults: '',
    searchPage: '',
    searchResult: '',
  }
};

/**
 * @param userRole: the role from Sitecore Context
 * @return string value mapped to `staff` or empty
 */
export const setAnalyticsStaffUserRole = (userRole: string): string => userRole?.toLowerCase() === STAFF_USER_ROLE?.toLowerCase() ? 'staff' : '';

