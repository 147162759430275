import colors from '_containers/Theme/corporate/colors';


export default {
  container: {
    border: `1px solid`,
    borderColor: colors.all.neutral200,
    textColor: "#090D46"
  },
  infoSessionName: {
    background: "#090D46",
    desktopFontSize:"18px",
    TabletFontSize:"16px",
    mobileFontSize:"16px"
  },
  date: {
    background: "#090D46",
    desktopNumberFontSize:"36px",
    desktopTextFontSize:"22px",
    tabletNumberFontSize:"36px",
    tabletTextFontSize:"22px",
    mobileNumberFontSize:"24px",
    mobileTextFontSize:"24px"
    
  },
  schedule: {
    background: "#090D46",
    desktopFontSize:"12px",
    tabletFontSize:"12px",
    mobileFontSize:"12px"
  },
  divider: {
    background: "#0A5CC7"
  },
  address: {
    background: "#090D46",
    desktopFontSize:"12px",
    tabletFontSize:"12px",
    mobileFontSize:"12px"
  },
  leftbar:{
    background: colors.background.brandPrimary1,
    desktopSize:"6px",
    tabletSize:"6px"
  }
};



