import React, { createContext, useEffect, useState } from 'react';

import { usePrevious } from '_utils/hooks';
import { BREAKPOINTS } from '_utils/styles/mq';
import { Optionalize } from '_utils/types';

import { ChildrenPropTypes } from '../../types';

export type ModalProps = {
  id: string;
  content: JSX.Element;
  dismissable?: boolean;
  heading?: string;
  onClose: () => void;
  shouldHideCloseButton?: boolean;
  closeOnBreakpoint?: keyof typeof BREAKPOINTS;
  onActivate?: () => void;
};
export type ModalContextProps = {
  modal: ModalProps;
  prevModalContent: null;
  setModal: (modal: ModalProps) => void;
};

const ModalContext = createContext({
  modal: null,
  setModal: null
});

function ModalProvider({ children }) {
  // state
  const [modal, setModal] = useState<ModalProps>(null);
  const modalContent = modal?.content;
  const prevModalContent = usePrevious(Boolean(modalContent));

  useEffect(() => {
    if (modalContent && !prevModalContent) {
      document.body.classList.add('menu-active');
    } else if (!modalContent && prevModalContent) {
      document.body.classList.remove('menu-active');
    }

    return () => {
      document.body.classList.remove('menu-active');
    };
  }, [modalContent, prevModalContent]);

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: ChildrenPropTypes
};

export default ModalProvider;

export const ModalConsumer = ModalContext.Consumer;

export function withModalContext<T extends ModalContextProps = ModalContextProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withModalContext: React.FC<Optionalize<T, ModalContextProps>> = (props) => (
    <ModalContext.Consumer>
      {(value) => <WrappedComponent {...value} {...(props as T)} />}
    </ModalContext.Consumer>
  );

  withModalContext.displayName = `withModalContext(${displayName})`;

  return withModalContext;
}
