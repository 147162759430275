import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  color: ${({ theme }) => theme.colors.background.tint5};
  display: block;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8px;
  width: 48px;

  svg {
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    animation: rotate 1.5s linear infinite;
    height: 100%;
    width: 100%;

    circle {
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -20;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124;
        }
      }

      animation: dash 1.5s linear infinite 0s;
      fill: none;
      stroke: currentcolor;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 0.3em;
    }
  }
`;

export const MainContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.subtle};
  text-align: center;
`;
