import { FormikConfig } from 'formik';

export interface MemberFormProps extends FormikConfig<any> {
}

export const phoneRegexValidationPattern = /^\+?\d{1,3}-\(?\d{0,3}\)?-\d{1,11}$/;

export const mobilePhoneRegexValidationPattern = /^\d{0,14}$/;

export const fallbackMobilePhoneErrorMessage = 'Please enter Mobile phone in a number format and must not exceed 14 digits';

export const fallbackPhoneErrorMessage = "Please enter as 'CountryCode- AreaCode-PhoneNumber' (Eg: 061-03-XXXXXXXX)";

export type DateFormat = 'YEAR_FIRST' | 'DAY_FIRST';
