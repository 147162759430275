/**
 * Initialises a FormContext + Formik Context, with values for Career Profile Form.
 */

import { clone, compose } from 'ramda';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { validationSchema } from '_components/MMFCareerProfileForm/formValidation';
import { withEditMode } from '_containers/BaseComponent';
import { DigitalDataContext } from '_containers/DigitalDataContext';
import { FormContext } from '_containers/FormSectionContext';
import { MemberContext } from '_containers/MemberContext';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import { getQueryStringValue } from '_utils/data/queryString';
import { errorInDev } from '_utils/helpers/dev';
import { formatDate, castStringAsBoolean, REF_PARAM, RENEWAL_REF_PARAM_VALUE } from '_utils/helpers/form';
import { useUnload } from '_utils/hooks';

import {
  CareerProfileFormContext,
  CareerProfileFormContextProviderProps,
  CareerProfileFormValues,
} from './definitions';

const CareerProfileFormContextProvider: React.FC<CareerProfileFormContextProviderProps> = ({
  children,
  editMode,
}) => {
  const memberContext = useContext(MemberContext);
  const defaultLicenseValue = useRef<CareerProfileFormValues['licences']['items']>([]);
  const digitalDataContext = useContext(DigitalDataContext);
  const { digitalData, setDigitalData } = digitalDataContext;

  if (Object.keys(memberContext).length === 0) {
    errorInDev('CareerProfileFormContextProvider is being rendered outside MemberContext!');
  }

  // TODO: When partial saving is possible, there should be individual isEditing state and actions for each editable section.
  const [isEditing, setIsEditing] = useState(false);
  const [sectionEditing, setSectionEditing] = useState(null);
  const [isReferredFromRenewal, setIsReferredFromRenewal] = useState(getQueryStringValue(REF_PARAM) === RENEWAL_REF_PARAM_VALUE);

  // pre-validation causes user to straight to edit mode, so need to capture user interaction
  // else user get stuck in loop
  const [hasUserBeenShownPrompt, setHasUserBeenShownPrompt] = useState(false);

  // Initial fetch
  useEffect(() => {
    memberContext?.careerProfileApi?.request?.({
      setLoadingStatus: true,
      useCachedResponse: false,
    });
  }, []);

  // Refresh form data everytime the user exits form edit mode
  useEffect(() => {
    if (!editMode && !isEditing) {
      memberContext?.careerProfileApi?.request?.({
        setLoadingStatus: true,
        useCachedResponse: false,
      });

      // clear out any cached submit errors returning to readonly mode
      if (memberContext?.careerProfileSubmissionApi?.result?.error) {
        memberContext?.careerProfileSubmissionApi?.clear?.();
      }
    }

    if (!editMode && isEditing) {
      // clear out any cached success, if there was one left over from a previous edit
      // ("Updates saved" to hide when going back into editing mode)
      if (memberContext?.careerProfileSubmissionApi?.result?.data) {
        memberContext?.careerProfileSubmissionApi?.clear?.();
      }
    }
  }, [editMode, isEditing]);

  // Ensure removal of updates saved announcement on route change
  useUnload(() => memberContext?.careerProfileSubmissionApi?.clear());

  const updateAction = (event?: Event, sectionIdentifier?: string) => {
    if (event) {
      event?.preventDefault();
    }
    setIsEditing(true);

    if (sectionIdentifier) {
      setSectionEditing(sectionIdentifier);
    }

    // dispatch tracking event when start section being updated
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['form'],
          {
            formName: 'career form',
            update: sectionIdentifier ?? ''
          },
          digitalData)
      );
      emitTrackEvent('infoUpdate');
    }
  };

  const cancelAction = (event: Event) => {
    event.preventDefault();

    // dispatch tracking event when users saw unsaved changes overlay
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        setObjectData(
          ['page'],
          {
            pageName: 'career form unsaved changes overlay'
          },
          digitalData)
      );
      emitTrackEvent('overlay');
    }

    // TODO: detect if there are changes to discard and show a dialog to confirm user cancellation
    const alertBody = getDictionaryItem('form-unsaved-changes-body', 'Are you sure you want to leave? You currently have some unsaved changes.');

    // user confirm cancel,so don't re-validate on mount
    if (window.confirm(alertBody)) {
      setIsEditing(false);
      setHasUserBeenShownPrompt(true);
      // remove referrer from query string if come from Renewal Form
      setIsReferredFromRenewal(false);

      //TODO: Update analytics linkName and linkDestination when overlay button text updated
      // dispatch tracking event when user confirm to cancel
      if (typeof setDigitalData === 'function') {
        setDigitalData(
          setObjectData(
            ['link'],
            {
              linkName: 'discard changes',
              linkDestination: 'discard changes'
            },
            digitalData)
        );
        emitTrackEvent('linkClick');
      }
    } else {
      // dispatch tracking event when user confirm to continue editing
      if (typeof setDigitalData === 'function') {
        setDigitalData(
          setObjectData(
            ['link'],
            {
              linkName: 'continue editing',
              linkDestination: 'continue editing'
            },
            digitalData)
        );
        emitTrackEvent('linkClick');
      }

      setSectionEditing(null); // so we don't conflict with scroll next line

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    };
  };

  const saveAction = (event: Event) => {
    // event.preventDefault();
    // TODO: when partial save is ready to implement, handleFormSubmit should be moved into here
  };

  const value: CareerProfileFormContext = {
    hasUserBeenShownPrompt: hasUserBeenShownPrompt,
    isAnySectionEditing: isEditing,
    isReferredFromRenewal: isReferredFromRenewal,
    sectionEditing: sectionEditing,
    setIsEditing: setIsEditing,
    employmentDetails: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    },
    educationProfessionalBodies: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    },
    licences: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    },
    professionalInterests: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    },
    specialityAreas: {
      isEditing,
      cancelAction,
      saveAction,
      updateAction,
    }
  };

  const getInitialFormValues = (): CareerProfileFormValues => {
    const apiResponse = memberContext?.careerProfileApi?.result?.data;

    // Now, careerProfile api is containing reference data
    const careerStageItems = apiResponse?.referenceData?.careerStages?.items
    const careerStageVal = apiResponse?.employment?.careerStage || null
    const careerStageDefaultItem = careerStageItems.find(item => item.name === careerStageVal)

    // Now, careerProfile api is containing reference data
     const sectorsItems = apiResponse?.referenceData?.sectors?.items
     const sectorVal = apiResponse?.employment?.currentCareerSector || null
     const sectorDefaultItem = sectorsItems.find(item => item.name === sectorVal)

    // Now, careerProfile api is containing reference data
    const employmentIndustryItems = apiResponse?.referenceData?.employmentIndustries?.items
    const employmentIndustryVal = apiResponse?.employment?.currentCareerIndustry || null
    const employmentIndustryDefaultItem = employmentIndustryItems.find(item => item.name === employmentIndustryVal)
     
    const startDate = apiResponse?.employment?.currentEmploymentStartDate || ''

    defaultLicenseValue.current = apiResponse?.licences?.items || [];

    return {
      cpaAustraliaId: apiResponse?.cpaAustraliaId,
      employment: {
        jobTitle: apiResponse?.employment?.jobTitle || '',
        jobFunction: apiResponse?.employment?.jobFunction || '',
        jobLevel: apiResponse?.employment?.jobLevel || '',
        currentEmploymentStartDate:  formatDate(startDate.split('T')[0] || '', 'DAY_FIRST') || '',
        isEmployed: 
          apiResponse?.employment?.isEmployed === null 
            ?
            'false'
            :
            (!!apiResponse?.employment?.isEmployed).toString(),
        careerStage: careerStageDefaultItem?.code || null,
        currentCareerSector: sectorDefaultItem?.code || null,
        currentCareerIndustry: employmentIndustryDefaultItem?.code || null,
        employmentType: apiResponse?.employment?.employmentType || '',
        company: {
          id: apiResponse?.employment?.company?.id,
          // industry: employmentIndustryDefaultItem || {name: '', code: '', state: 0},
          websiteurl: apiResponse?.employment?.company?.websiteurl || '',
          region: apiResponse?.employment?.company?.region || '',
          // sector: sectorDefaultItem || {name: '', code: ''},
          name: apiResponse?.employment?.company?.name || ''
        }
      },
      education: {
        highestEducationName: apiResponse?.education?.highestEducationName || ''
      },
      professionalMemberships: {
        items: apiResponse?.professionalMemberships?.items || [],
      },
      licences: {
        items: apiResponse?.licences?.items || []
      },
      professionalInterests: {
        // Convert ProfeesionalInterests into an array of item
        items: apiResponse?.professionalInterests?.items.map((items) => items.professionalInterestName) || [],
      },
      specialityAreas: {
        // Convert ProfeesionalInterests into an array of item
        items: apiResponse?.specialityAreas?.items.map((items) => items.specialityAreaName) || [''],
      }
    };
  };

  const isCareerProfileDataReady =
    !memberContext?.careerProfileApi?.result?.isLoading
    && memberContext?.careerProfileApi?.result?.data;

  // This is for education part validation
  const initEducationName = memberContext?.careerProfileApi?.result?.data?.education?.highestEducationName || '';
  const educationRef = memberContext?.careerProfileApi?.result?.data?.referenceData?.highestEducations?.items;
  const validations = validationSchema(initEducationName, educationRef)

  const handleFormSubmit = async (
    values: CareerProfileFormValues,
    { setSubmitting }: FormikHelpers<CareerProfileFormValues>
  ) => {
    // Transforms to prepare form values to send
    const valuesToSubmitToAPI = clone(values);

    let hasCompanyError = false;

    valuesToSubmitToAPI.licences.items = valuesToSubmitToAPI.licences.items.map(item => {
      // @ts-ignore: Convert item key to licenceKey
      return ({
        // licenceKey: licenceKeyValue,
        // @ts-ignore: Convert item name to licenceName
        // if have value, licenceName is a must have value
        licenceName: item?.licenceName ?? item?.name,
        registrationNumber: item?.registrationNumber ?? ''
      })
    });

    // Convert professionalInterest back to it's original data format on form submit
    valuesToSubmitToAPI.professionalInterests.items = valuesToSubmitToAPI.professionalInterests.items.map(name => {
      return typeof name === "string" ? { professionalInterestName: name } : name;
    })

    valuesToSubmitToAPI.specialityAreas.items = valuesToSubmitToAPI.specialityAreas.items.map(name => {
      return typeof name === "string" ? { specialityAreaName: name } : name;
    })

    valuesToSubmitToAPI.employment.careerStage = values?.employment?.careerStage;
    valuesToSubmitToAPI.employment.employmentType = values?.employment?.employmentType;
    valuesToSubmitToAPI.employment.currentEmploymentStartDate = formatDate(values.employment.currentEmploymentStartDate, 'YEAR_FIRST')

    valuesToSubmitToAPI.employment.isEmployed = castStringAsBoolean(values?.employment?.isEmployed)

    // ensure `isNewCompany` is false if not employed
    if (!castStringAsBoolean(values?.employment?.isEmployed)) {
      valuesToSubmitToAPI.employment.isNewCompany = false;
    }

    // Remove isEmployed, not needed for update
    // delete valuesToSubmitToAPI.employment.isEmployed;

    // If isNewCompany, save to Companies API and set company ID in career profile
    if (valuesToSubmitToAPI.employment.isNewCompany) {
      await memberContext?.companySubmissionApi?.request({
        axiosRequestConfig: {
          params: {
            companyName: valuesToSubmitToAPI.employment.company.name,
            countryCode: valuesToSubmitToAPI.employment.company.country.isoCode2Letter,
            // industryId: valuesToSubmitToAPI.employment.company.industry.code,
            websiteUrl: valuesToSubmitToAPI.employment.company.websiteUrl,
            // Bug fix for passing sector as string name, and industry as string name
            // sector: valuesToSubmitToAPI.employment.company.sector.name,
            // industry: valuesToSubmitToAPI.employment.company.industry.name,
          },
        },
        useCachedResponse: false,
        onError: (err) => {
          console.warn(err);
          hasCompanyError = true;
          setIsEditing(true);
          setSectionEditing(null); // clear the current edit section so doesn't conflict with scroll top next line

          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        },
        onSuccess: (response) => {
          valuesToSubmitToAPI.employment.company.id = response.data.identifier;
        },
      });
    }

    // do not attempt submit to career api if company api has error
    if (hasCompanyError) {
      return;
    }

    // Based on mmf response
    if(!castStringAsBoolean(values?.employment?.isEmployed)) {
      valuesToSubmitToAPI.employment.company = null
    }

    // Only company ID needed for update
    delete valuesToSubmitToAPI.employment.isNewCompany;
    if (valuesToSubmitToAPI.employment.company !== null) {
      delete valuesToSubmitToAPI.employment.company.name;
      delete valuesToSubmitToAPI.employment.company.country;
      delete valuesToSubmitToAPI.employment.company.industryId;
      delete valuesToSubmitToAPI.employment.company.websiteUrl;
    }

    // Transform professional memberships for API
    valuesToSubmitToAPI.professionalMemberships.items = valuesToSubmitToAPI.professionalMemberships.items.map(item => (
      {
        professionalBodyName: item.professionalBodyName,
      }
    ));

    memberContext?.careerProfileSubmissionApi?.request?.({
      data: valuesToSubmitToAPI,
      onComplete: () => {
        // Note, this is a edge case fix! if Api fails, make the company name as ''
        if(values?.employment?.company?.name) {
          values.employment.company.name = '';
        }

        setSubmitting(false);
        setSectionEditing(null); // clear the current edit section so doesn't conflict with scroll top next line
        // remove referrer from query string if come from Renewal Form
        setIsReferredFromRenewal(false);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      onSuccess: () => {
        setIsEditing(false);

        // dispatch tracking event when "Updates saved"
        if (typeof setDigitalData === 'function') {
          setDigitalData(
            setObjectData(
              ['form'],
              {
                formName: 'career form',
                update: 'career form saved'
              },
              digitalData)
          );
          emitTrackEvent('infoUpdate');
        }

      },
      setLoadingStatus: true,
      useCachedResponse: false,
    });
  };

  return (
    <FormContext.Provider value={value}>
      {isCareerProfileDataReady
        ? (
          <Formik
            initialValues={getInitialFormValues()}
            onSubmit={handleFormSubmit}
            validationSchema={validations}
          >
            <>
              {children}
            </>
          </Formik>
        )
        : children
      }
    </FormContext.Provider>
  )
};

export default compose(
  withEditMode,
)(CareerProfileFormContextProvider);
