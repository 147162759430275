export function loadMktoForm(formId, munchkinId, rootUrl) {
    if (munchkinId) {
        (function () {
            function insMktoForm() {
                   MktoForms2.loadForm(`//${rootUrl}`, munchkinId, formId, function (form) {
                    form.onSuccess(function (values, followUpUrl) {
                        _satellite.track('formComplete'); // trigger form complete analytics event                        
                        return true;
                    });
                    const formEl = form.getFormElem()[0];
                    formEl.addEventListener("focus", logFirstFocus, true); // form start listener                    
                    function logFirstFocus(e) {
                        formEl.removeEventListener("focus", logFirstFocus, true);
                        _satellite.track('formStart'); // trigger form start analytics event                    
                    };
                    form.onValidate(function(){ // validate phone number format (4-10 numbers) and throw error if fail                        
                        const validPhone = /^[0-9]{4,12}$/;
                        let vals = form.vals();
                        const phoneValidation = (field) => {
                            const phoneInput = document.getElementById(field).value;
                            let cleanInput = phoneInput.indexOf('_') < 0 ? phoneInput : phoneInput.slice(0, phoneInput.indexOf('_'))
                            form.setValues({[field]: cleanInput});
                            vals = form.vals();
                            if(!validPhone.test(vals[field])){
                                form.submittable(false);
                                let formElem = form.getFormElem().find(`#${field}`);
                                let phoneNumberInput = document.getElementById(field);
                                form.showErrorMessage('Must be only numbers, between 4-12 digits', formElem);
                                phoneNumberInput.classList.remove('mktoValid');
                                phoneNumberInput.classList.add('mktoInvalid');
                                phoneNumberInput.scrollIntoView({block: "center"});
                            } else {
                                form.submittable(true);
                            }
                        }
                        if (!!vals.phoneNumberMKTO) {
                            phoneValidation('phoneNumberMKTO');
                        } 
                        if (!!vals.mobileNumberMKTO) {
                            phoneValidation('mobileNumberMKTO');
                        }
                    });
                });
                MktoForms2.whenReady(function (form) {
                        let vals = form.vals();
                        digitalData.form.formName = vals.cpa_leadtype; // record form name from marketo hidden field to datalayer                        
                        const maskCharExtensions = { // enable 'b' as optional number input mask, used in phone number fields                            
                            b: "[0-9]?"                         
                        }
                         function checkMaskDefinitions(timeout) { // promise function to wait/check for MktoForms2.$.mask.definitions                            
                            var start = Date.now();
                            return new Promise(waitForMaskDefinitions);
                            function waitForMaskDefinitions(resolve, reject) {
                                if (typeof MktoForms2.$.mask == 'object')
                                    resolve(MktoForms2.$.mask.definitions);
                                else if (timeout && (Date.now() - start) >= timeout)
                                    reject(new Error("Marketo Forms Mask Definitions Timeout"));
                                else                                    
                                    setTimeout(waitForMaskDefinitions.bind(this, resolve, reject), 30);
                            }
                        }
                        checkMaskDefinitions(1000).then(function(){ // run promise with 1 sec timeout                            
                            Object.keys(maskCharExtensions)
                                .forEach(function(char) {
                                    MktoForms2.$.mask.definitions[char] = this[char];
                                }, maskCharExtensions);
                        });
                        // function to cascade application of field rules                        
                        function manageCascadingRequired(mktoForm, sourceFieldName, rules) {
                            const formEl = mktoForm.getFormElem()[0],
                                selectEl = formEl.querySelector("[name='" + sourceFieldName + "']");
                            function cascade() {
                                const currentSourceValue = mktoForm.getValues()[sourceFieldName],      
                                    currentCascadeables = rules[currentSourceValue];
                                if(!currentCascadeables) return;
                                Object.keys(currentCascadeables).forEach(function (targetFieldName) {          
                                    const fieldEl = formEl.querySelector("[name='" + targetFieldName + "']");
                                    if(!fieldEl) return;                 
                                    const wrapperEl = fieldEl.parentElement,
                                        descriptorEl = wrapperEl.parentElement,
                                        descriptorJq = MktoForms2.$(descriptorEl),
                                        isRequired = currentCascadeables[targetFieldName];
                                    wrapperEl.classList[isRequired ? "add" : "remove"]("mktoRequiredField");
                                    descriptorJq.data("mktoFieldDescriptor").required = isRequired;
                                });
                            }
                            cascade(); // initial                            
                            selectEl.addEventListener("change", cascade); // ongoing                        
                        }
                        // add required attribute to number fields per value of mobile selection field                        
                        if (!!vals.mobileorPhoneNumberMKTO) {
                        let requiredBySelection = {
                            "" : {
                              mobileNumberCountryCodeMKTO : false,
                              mobileNumberMKTO: false,
                              phoneNumberCountryCodeMKTO: false,
                              phoneNumberMKTO: false                            
                            },
                            "Phone" : {
                              mobileNumberCountryCodeMKTO : false,
                              mobileNumberMKTO: false,
                              phoneNumberCountryCodeMKTO: true,
                              phoneNumberMKTO: true                            
                            },
                            "Mobile" : {
                              mobileNumberCountryCodeMKTO : true,
                              mobileNumberMKTO: true,
                              phoneNumberCountryCodeMKTO: false,
                              phoneNumberMKTO: false                            
                            }
                         }
                         manageCascadingRequired(form, "mobileorPhoneNumberMKTO", requiredBySelection); 
                        }
                    })
            }
            // remove if insMktoForm function called from index.tsx            
            if (typeof MktoForms2 == 'object') { 
                insMktoForm();
            } else {
                console.log('MktoForms2 object does not exist');
            }
        })();
    } 
}