import React from 'react';
import asyncComponent from '_containers/AsyncComponent';
import { SvgProps } from '_utils/icons/Svg';
import { materialIcons, customIcons } from './definitions';

// This is title case but with no spaces
const iconCase = (str) => {
  return str.toLowerCase().replace(/(?:^|[^a-zA-Z0-9]+)(.)/g, (m, chr) => chr.toUpperCase());
}

export type CustomIcons = typeof customIcons[number];
export type MaterialIcons = typeof materialIcons[number];

export interface IconProps extends SvgProps {
  name: CustomIcons | MaterialIcons,
};

const Icon: React.FC<IconProps> = React.memo(({
  name,
  ...props
}) => {
  if (!name) {
    return null;
  }
  const iconFieldName = name;
  const iconComponentName = iconCase(iconFieldName);
  const isCustomIcon = customIcons.includes(iconFieldName as CustomIcons);
  const isMaterialIcon = materialIcons.includes(iconFieldName as MaterialIcons);

  if (!isMaterialIcon && !isCustomIcon) {
    console.warn(`"${iconFieldName}" is not a valid icon name.`);
    return null;
  }

  const folderName = isCustomIcon ? 'custom' : 'material';

  const Icon = asyncComponent(() =>
      import(
        /* webpackChunkName: "[request]" */
        `../../utils/icons/${folderName}/${iconComponentName}`)
    )

  return Icon && <Icon {...props} />;
})

export default Icon;
