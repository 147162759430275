import { compose } from 'ramda';
import React from 'react';

import Icon from '_components/Icon';
import { withAuthenticatedCheck } from '_containers/BaseComponent';
import { createSrcSet } from '_utils/media';
import { getImageAttrsByRatio } from '_utils/helpers/image';
import { getLink } from '_utils/helpers/link';
import { AuthenticatedContentIcon } from '_utils/components/AuthenticatedContentIcon';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize, ImageRatio } from '_utils/types/enums';

import {
  ListItemProps,
  stylesThatRenderDescription,
  stylesThatRenderImage,
  stylesThatRenderTags,
} from './definitions';

import {
  ArrowWrapper,
  Card,
  Description,
  DetailsColumn,
  FooterWrapper,
  AttributeFooterWrapper,
  ItemList,
  Image as StyledImage,
  ImageColumn,
  ImageContainer,
  PlaceholderCard,
  Tag,
  TagContainer,
  Title,
} from './StyledItem';

const Item: React.FC<ListItemProps> = ({
  description,
  editMode,
  headingLevel,
  isAuthenticatedContent,
  link,
  listingImage,
  tags,
  title,
  type
}) => {
  const renderImage = stylesThatRenderImage[type] || false;
  const renderDescription = stylesThatRenderDescription[type] || false;

  // TODO enable when needed
  const renderTags = stylesThatRenderTags[type] || false;
  const cardProps = getLink(link);
  const headingType = `h${headingLevel + 1}`;
  const Component = editMode ? PlaceholderCard : Card;
  const isExternalLink = cardProps?.target === '_blank';
  const hasImageSrc = !!listingImage?.value?.src;
  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_16By9);

  if (renderImage && hasImageSrc) {
    let classes = 'hasImage';
  }

  return (
    (editMode || cardProps) && (
        <ItemList>
          <Component {...cardProps}
                     className= "dynamicTextandmage"
                     as={isExternalLink ? 'a' : null}>
            {
                renderImage &&
                hasImageSrc &&
                <ImageColumn>
                  <ImageContainer>
                    <StyledImage
                        className="lazyload"
                        draggable={false}
                        field={
                          editMode
                              ? listingImage?.value
                              : {
                                value: {
                                  src: imagePlaceholderSrc,
                                  alt: '', // alt text is empty string, as this is a decorative image
                                  'data-srcset': createSrcSet(listingImage?.value?.src, [ImageSize.MW_384, ImageSize.MW_512])
                                }
                              }
                        }
                        {...defaultImageSize}
                    />
                  </ImageContainer>
                </ImageColumn>
            }
            <DetailsColumn>
              <div>
                <Title as={headingType}>
                  <AuthenticatedContentIcon
                      iconStyleConfig={{
                        xs: {
                          iconSize: 20,
                          mr: 8,
                          top: 4
                        }
                      }}
                      isAuthenticated={isAuthenticatedContent}
                  />
                  <h3>{title}</h3>
                </Title>
                {
                  renderTags &&
                  tags?.length > 0 &&
                  <TagContainer>
                    {tags.map(tag =>
                        <Tag key={tag.id}>{tag.displayName}</Tag>
                    )}
                  </TagContainer>
              }
                {(editMode || renderDescription) && <Description>{description}</Description>}
              </div>

            </DetailsColumn >
            <AttributeFooterWrapper>
              {(
                  <FooterWrapper>
                    Read Article
                    <ArrowWrapper>
                      <Icon name="arrow_forward" ariaHidden />
                    </ArrowWrapper>
                  </FooterWrapper>
              )}
            </AttributeFooterWrapper>
          </Component >
        </ItemList>
    )
  );
};

export default compose(withAuthenticatedCheck)(Item);
