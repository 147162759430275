import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { linkStyle } from '_utils/styles/'; // these import fine
import svg from '_utils/styles/svg'; // but svg isn't ...
import { textBody1, textBody2 } from '_utils/styles/text'; // but textBody2 isn't ...
import color from '_utils/styles/color'; // but color isn't ...

import { LinkTheme, LinkSize } from './definitions';

const handleLinkTheme = ({ linkTheme, theme }) => {
  if (linkTheme === LinkTheme.None) {
    return null;
  }

  return css`
    ${linkStyle(theme.colors.link[linkTheme])}
  `;
};

export const LinkChildrenWrapper = styled.span``;

export const LinkTextWrapper = styled.span``;

export const LinkIconWrapper = styled.span`
  line-height: 0;
  position: relative;
  white-space: nowrap;

  &.featuredIcon {
    padding-right: 28px;
  }
`;

export const SvgWrapper = styled.span`
  ${svg(16, 16)}
  width: 16px;
  margin-left: 8px;
  margin-right: 2px;
  top: 2px;
  position: relative;

  .featuredIcon & {
    ${svg(24, 24)}
    position: absolute;
    top: -3px;
    width: 24px;
  }

  path {
    fill: currentColor;
  }
`;

// Need to remove extra props getting sent to the dom
// https://github.com/styled-components/styled-components/issues/135
export const StyledLink = styled(({ linkTheme, linkUrl, linkSize, linkAriaCurrent, ...rest }) =>
    <NavLink to={linkUrl} aria-current={linkAriaCurrent} {...rest} />)`
  ${handleLinkTheme}
  ${({ theme, linkSize }) => (linkSize === LinkSize.Small ? textBody2({ theme }) : textBody1({ theme }))}
  display: inline;
  margin: 0;
`;

export const EditorDiv = styled.div``;
