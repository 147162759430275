import colors from '_containers/Theme/corporate/colors';


export default {
  body: {
    focus: {
      borderColor: colors.element.focused
    },
    checkboxFocus: colors.element.focused
  }
}
