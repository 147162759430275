import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { FC } from 'react';

import { Grid } from '_utils/components';
import { stripHTML } from '_utils/helpers/html';
import { TextBannerProps } from './definitions';

import {
  Blurb,
  CategoryLabelLink,
  Header,
  TextBannerContainer,
  TextBannerContent,
} from './StyledPageBanner';

export const TextBanner: FC<TextBannerProps> = ({
  editMode,
  pageDescription,
  pageTitle,
  uid,
  linkField,
  trackingName,
  datasourceName,
  isCorporateSite = true
}) => {
  return (
    <TextBannerContainer id={uid} data-component className="page-banner">
      <Grid.Container>
        <Grid.Row alignItems="stretch" config={{ gutters: ['left', 'right'] }}>
          <TextBannerContent config={{ col: 12, gutters: ['left', 'right'] }} alignSelf="center">
            {!isCorporateSite &&
              <CategoryLabelLink
                field={linkField}
                fallbackTitle={linkField?.fallbackTitle}
                linkSize="s"
                linkTheme="none"
                analyticsOptions={{
                  analyticsTrackingName: trackingName?.value,
                  datasourceName: datasourceName
                }}
              />
            }
            <Header>
              <Text field={pageTitle} />
            </Header>
            {(editMode || pageDescription?.value) && (
              <Blurb>
                <Text field={editMode ? pageDescription : { value: stripHTML(pageDescription?.value) }} />
              </Blurb>
            )}
          </TextBannerContent>
        </Grid.Row>
      </Grid.Container>
    </TextBannerContainer>
  );
};
