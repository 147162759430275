import React from 'react';
import { DynamicTextAndImageProps } from './definitions';
import { DynamicTextAndImageContainer,ListItem, List, Container } from './StyledDynamicTextAndImage';
import Item from "./Item";


const DynamicTextAndImage: React.FC<DynamicTextAndImageProps> = ({rendering}) => {

  const getItems = rendering?.fields?.data?.cards?.dynamicItems;

  return (
    <DynamicTextAndImageContainer>
      <Container
          className="card-list"
          data-component
          id={rendering.uid}
      >
      <h2>This month INPRACTICE</h2>
        <ListItem>
          <List as="ul" >
              {getItems?.map((item) =>  <Item {...item}> </Item>)}
          </List>
        </ListItem>
      </Container>
    </DynamicTextAndImageContainer>
  );
}

export default DynamicTextAndImage;
