import styled from 'styled-components';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import {
  headingH2,
  headingH3, 
  headingH4, 
  headingH5, 
  headingH6,
} from '_utils/styles/';

export const Podcast =styled.div`
  h3 {
    padding-left: 20px;
  }
`;

export const NoPodcast = styled.div`
  padding-left: 20px;
`;

export const Heading = styled(Text)`
  h2& {
    ${headingH2}
  }

  h3& {
    ${headingH3}
  }

  h4& {
    ${headingH4}
  }

  h5& {
    ${headingH5}
  }

  h6& {
    ${headingH6}
  }
`;