import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Grid from '_utils/components/Grid';
import { spacing } from '_utils/props';

import { ThemeOptions } from './definitions';

import {
  fixedTitle12,
  fixedTitle18,
  linkCard,
  mq,
  restrictCardTitle,
  svg,
  textBody2
} from '_utils/styles';

import {
  avoidPageBreakOnPrint,
} from '_utils/styles/print';

const container = css`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq('lg')} {
    height: 100%;
  }
`;

export const PlaceholderCard = styled(Grid.Item)`
  background-color: ${({ theme }) => theme.cardListWithInternalNavigation.cardBackground};
`;

export const getThemeType = (
  theme?: ThemeOptions,
): string =>
  ['blue', 'purple'].includes(theme?.toLowerCase())
    ? 'dark'
    : 'light';

export const Card = styled(Link)`
  ${({ theme }) => linkCard({ theme })}
  background-color: ${({ theme, $themeOption }) => theme?.cardListWithInternalNavigation?.theme?.background[$themeOption]};
  color: ${({ theme, $themeOption }) =>
    $themeOption &&
    theme?.cardListWithInternalNavigation?.theme?.
    [getThemeType($themeOption)]
      ?.color
  } !important;
  ${({ theme }) => spacing({ theme, pr: 3, pl: 3, pb: 3 })}
  ${container}
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  padding-top: 24px;
  position: relative;

  &:hover {
    h4 {
      color: ${({ theme, $themeOption }) => $themeOption ? 'inherit' : theme.colors.text.interactive1};
    }
  }

  ${avoidPageBreakOnPrint}
`;

export const BackToParentLabel = styled.span`
  ${fixedTitle12}
  color: ${({ theme, $themeOption }) =>
    theme?.cardListWithInternalNavigation?.theme?.
    [getThemeType($themeOption)]?.backToParentColor};
`;

export const DetailsColumn = styled.div`
  ${textBody2}
  display: block;
  flex: 0 1 auto;
`;

const IconContainerStyles = css`
  color: inherit;
  display: block;
  text-decoration: none;
`;

export const IconContainer = styled.span`
  ${IconContainerStyles}
`;

export const LinkIconContainer = styled(Link)`
  ${IconContainerStyles}
`;

export const ArrowWrapper = styled.span`
  ${svg(24, 24)}
  display: flex;
  width: 24px;

  path {
    fill: ${({ theme, $themeOption }) =>
    $themeOption
      ?
      theme?.cardListWithInternalNavigation?.theme?.
      [getThemeType($themeOption)]?.color
      :
      theme.cardListWithInternalNavigation.action};
  }
`;

export const Title = styled.div`
  h3,
  h4,
  h5,
  h6,
  h3&,
  h4&,
  h5&,
  h6& {
    ${fixedTitle18}
    color: inherit;
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: auto;
  width: 100%;

  .backToParent & {
    justify-content: flex-start;
  }
`;

export const Description = styled.p`
  ${textBody2}
  ${restrictCardTitle}
  flex-grow: auto;
  margin-bottom: 0 !important;
  margin-top: 8px;
`;
