import { canUseDOM } from 'exenv';
import { assocPath, clone, compose, dissocPath } from 'ramda';
import React, { useState, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  StyledComponentWrapper,
  StyledNotice,
  StyledComponentHighlight
} from './StyledBaseComponent';

import { placeholderBackgroundColors } from '_containers/Theme/global';

import { warnInDev } from '_utils/helpers/dev';
import { getDisplayName } from '_utils/helpers';
import { SitecoreContextReactContext, withSitecoreContext as withSitecoreContextJss } from '@sitecore-jss/sitecore-jss-react';
import { WithSitecoreContextOptions } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext';
import CorporateTheme, { DarkTheme as CorporateDarkTheme } from '_containers/Theme/corporate/theme';
import InTheBlackTheme, { DarkTheme as InTheBlackDarkTheme } from '_containers/Theme/intheblack/theme';
import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';
import { getFieldValidationResults, hasDataSource } from '_utils/validationChecks';

const isEditMode = (props) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const pageEditing = sitecoreContextFactory?.context?.pageEditing ?? false;

  return props?.editMode || pageEditing;
};

// HOC to wrap around the JSS withSitecoreContext HOC to only get context if not already in props (allows storybook to work without Sitecore)
export const withSitecoreContext = (options?: WithSitecoreContextOptions) => (Component) => (props) => {
  const hasSitecoreContextProp = Object.prototype.hasOwnProperty.call(props, 'sitecoreContext');

  if (hasSitecoreContextProp) {
    return <Component {...props} />
  }

  const ComponentWithSitecoreContext = compose(withSitecoreContextJss(options))(Component);
  return <ComponentWithSitecoreContext {...props} />
};

// HOC to wrap component with editMode provider
// (ex BaseComponent instance method 'isEditMode')
export const withEditMode = (Component) => (props) => {
  return <Component {...props} editMode={isEditMode(props)} />;
};

// HOC to wrap component with isAuthenticatedContent boolean to show `lock` icon if needed
export const withAuthenticatedCheck = (Component) => (props) => {
  const isAuthenticatedContent = !!props?.accessRestrictedTo?.length || false;
  return <Component {...props} isAuthenticatedContent={isAuthenticatedContent} />;
};

// Coveo HTML mark up to wrap around components
const CoveoNoIndexBeginTag = () => <noindex className="coveo-no-index-begin" dangerouslySetInnerHTML={{ __html: '<!-- BEGIN NOINDEX -->' }}></noindex>;

const CoveoNoIndexEndTag = () => <noindex className="coveo-no-index-end" dangerouslySetInnerHTML={{ __html: '<!-- END NOINDEX -->' }}></noindex>;

/*  normally we would use the `withCoveoNoIndexTags` HOC, but some components (Card List) use logic oustide the validation HOC so we need to check if there are any children from the render fn to determin if tags need to be output */
export const CoveoNoIndexWrapper = (props) => {

  return props?.children && (
    <>
      <CoveoNoIndexBeginTag />
      {props?.children}
      <CoveoNoIndexEndTag />
    </>
  );

};

export const withCoveoNoIndexTags = (Component) => (props) => {

  return (
    <>
      <CoveoNoIndexBeginTag />
      <Component {...props} />
      <CoveoNoIndexEndTag />
    </>
  );
};

// HOC to wrap component with theme provider
export const withThemeOption = (Component) => (props) => {

  let clonedProps = clone(props);

  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const siteName = sitecoreContextFactory?.context?.site?.name?.toLowerCase();
  let theme = siteName === 'corporate' ? CorporateTheme : InTheBlackTheme;

  const placeholderTheme = props?.rendering?.params?.placeholderTheme ?? '';

  const darkThemeConfig = {
    corporate: CorporateDarkTheme,
    intheblack: InTheBlackDarkTheme
  }
  // Dark theme switches theme provider for all nested components
  if (placeholderTheme === 'Dark') {
    theme = darkThemeConfig[siteName] || CorporateDarkTheme;
  }
  // we don't need passed through to component so map to new `backgroudColor` prop and remove the theme prop
  else if (Object.keys(placeholderBackgroundColors).indexOf(placeholderTheme) >= 0) {
    const removeRenderingTheme = dissocPath(['rendering', 'params', 'placeholderTheme'], clonedProps);
    const removeParamTheme = dissocPath(['params', 'placeholderTheme'], removeRenderingTheme);
    clonedProps = assocPath(['rendering', 'params', 'backgroundColor'], placeholderTheme, removeParamTheme);
  }

  return (
    <ThemeProvider theme={theme} >
      <Component {...clonedProps} />
    </ThemeProvider>
  );
};

// HOC to wrap component with disableable render
// (ex BaseComponent instance method componentRender)
//
// this is an inversion of the orig BaseComponent func 'componentRender'
// approach, instead component may *opt-in* to disable render by calling
// props.setRenderDisabled() with bool indicating whether to proceed with
// render or just return null
//
// Note: to get meaningfull debug messages in dev about when render was disabled,
// make withDisableRender right-most function in composition, e.g:
// export default compose( withSitecoreContext(), withFieldValidation, withDisableRender )(ArticleHeaderImage);
//
export const withDisableRender = (Component) => (props) => {
  const editMode = isEditMode(props);

  // wrapped components can set this with a truthy value (debug msg) to prevent render
  const [renderDisabled, setRenderDisabled] = useState();

  // TODO this may get a bit noisy - give options to turn down/off warnings
  if (renderDisabled && !editMode) {
    warnInDev(`${getDisplayName(Component)} was not rendered (${renderDisabled})`);
    return null;
  }

  return (
    <StyledComponentWrapper>
      <Component {...props} editMode={editMode} setRenderDisabled={setRenderDisabled} />
    </StyledComponentWrapper>
  );
};

// HOC to wrap component with editor Notice (ex BaseComponent instance methods)
export const withEditModeNotice = (Component) => (props) => {
  const editMode = isEditMode(props);

  const { noticeEnabled, noticeMessage = '', noticeLevel = 'warning' } = props;

  if (!editMode || !noticeEnabled) {
    return <Component {...props} />;
  }

  return (
    <div>
      <StyledComponentHighlight noticeLevel={noticeLevel} >
        <Component {...props} />
      </StyledComponentHighlight>
      <StyledNotice noticeLevel={noticeLevel}> {noticeMessage} </StyledNotice>
    </div>
  );
};

// HOC to wrap component with data source and field validation
// (Shows editor warnings based on field validation config and blocks rendering if there are validation errors)
const EditorNotice = compose(withEditModeNotice)(styled.div``);
export const withDataSourceValidation = (fieldValidators: SitecoreFieldValidator[], expectDataSource: boolean = true) => (Component) => (props) => {
  const editMode = isEditMode(props);

  const MissingDataSourceWarning = (props: JSX.ElementChildrenAttribute & {
    componentName: string
  }) => {
    const message = `${props?.componentName || "UNKNOWN COMPONENT"} has no data source and will not render`;

    // Output the error to the console log in dev environments
    warnInDev(message);

    // Return the experience editor warning
    return (
      <EditorNotice noticeEnabled={true} noticeLevel='error' noticeMessage={message} editMode={editMode}>
        {props.children}
      </EditorNotice>
    )
  }

  const InvalidFieldsWarning = (props: JSX.ElementChildrenAttribute & {
    componentName: string,
    validationErrors: string[]
  }) => {
    const message = `${props?.componentName || "UNKNOWN COMPONENT"} has invalid or missing fields and may not render`;
    const invalidFieldsList = (
      <>
        {props?.validationErrors && props.validationErrors.length > 0 &&
          <ul>
            {props.validationErrors.map((message) =>
              <li>{message}</li>
            )}
          </ul>
        }
      </>
    );

    // Output the errors to the console log in dev environments
    warnInDev(message);
    props.validationErrors.forEach((message) => warnInDev(message));

    // Return the experience editor warning
    return (
      <EditorNotice noticeEnabled={true} noticeLevel='warning' noticeMessage={[message, invalidFieldsList]} editMode={editMode}>
        {props.children}
      </EditorNotice>
    )
  }

  // Validate the data source and fields
  const componentName = props?.rendering?.componentName;
  const missingDataSource = expectDataSource && !hasDataSource({ componentProps: props });
  const validationResults = getFieldValidationResults({ componentProps: props, validators: fieldValidators });
  const validationErrors = validationResults?.filter((r) => !r.isValid).map((r) => r.error) || [];
  const hasErrors = validationErrors.length > 0;
  // canUseDOM check to prevent issue with SSR when inserting new component
  // into dynamic placeholder (ie. tabs)
  let allowRender = !canUseDOM || !hasErrors || validationResults.every((r) => r.isValid || r.validator?.allowRenderOnError);
  if (editMode && hasErrors) {
    allowRender = validationResults.every((r) => r.isValid || r.validator?.allowEditModeRenderOnError == null || r.validator?.allowEditModeRenderOnError === true);
  }

  // Inject the validation results as additional props
  let componentWithValidationResults = <></>;
  if (allowRender && !missingDataSource) {
    componentWithValidationResults =
      <Component
        validationErrors={hasErrors ? validationErrors : null}
        validationResults={validationResults}
        {...props}
      />;
  }

  // Conditionally output the component with Editor warnings as needed
  if (editMode) {
    if (missingDataSource) {
      return (
        <MissingDataSourceWarning componentName={componentName}>
          {componentWithValidationResults}
        </MissingDataSourceWarning>
      )
    } else if (hasErrors) {
      return (
        <InvalidFieldsWarning componentName={componentName} validationErrors={validationErrors}>
          {componentWithValidationResults}
        </InvalidFieldsWarning>
      )
    }
  }
  return componentWithValidationResults;
}
