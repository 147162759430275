import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import {
  mq,
  textBody2
} from '_utils/styles/';


export const Caption = styled.figcaption`
  color: ${({ theme }) => theme.contentRichText.richTextWithImage.captionColor};
  margin-top: 16px;
  text-align: left;
`

export const Container = styled.div`

  :after {
    clear: both;
    content: '';
    display: table;
  }
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
  `;

export const ImageFigure = styled.figure`
  ${textBody2}
  margin: 20px 0px 24px 0px;

  ${mq('md')} {
    width: 50%;

    &.left {
      float: left;
      margin: 0px 24px 24px 0px;
    }

    &.right {
      float: right;
      margin: 0px 0px 24px 24px;
    }

    // limit size of image in Experience Editor
    &.editMode {
      max-width: 350px;
    }
  }
`;

export const IconWrapper = styled.span`
  svg {
    height: 15px;
    margin-right: 5px;
    max-width: unset;
    width: 16px;
  }
`
