import colors from '_containers/Theme/corporate/colors';

export default {
  background: {
    dark: colors.all.brandPrimary3,
    default: colors.all.neutralWhite,
    subtle: colors.all.neutral50,
    subtle2: colors.all.neutral100,
    subtle3: colors.all.neutral200,
  },
  border: {
    dark: colors.all.neutral300,
    default: colors.all.neutral200,
    outline: colors.all.interactive200,
  },
  button: {
    background: colors.all.brandPrimary1,
    backgroundHover: colors.all.brandPrimary1Dark,
  },
  cards: {
    content: colors.all.brandPrimary1,
    course: colors.all.brandSecondary3,
    event: colors.all.brandPrimary1,
    inTheBlack: colors.all.brandSecondary3,
    inPractice: colors.all.brandSecondary2,
    podcastSeries: colors.all.brandPrimary2,
    podcast: colors.all.brandSecondary3Dark,
    podcastTopic: colors.all.brandSecondary3Dark,
  },
  text: {
    dark: colors.all.neutral600,
    dark2: colors.all.neutral900,
    default: colors.all.neutral700,
    error: colors.all.error500,
    primary: colors.all.brandPrimary1,
    primaryDark: colors.all.brandPrimary3,
    subtle: colors.all.neutral500,
    subtle2: colors.all.neutral400,
    subtle3: colors.all.neutral300,
    light: colors.all.neutralWhite,
  },
};
