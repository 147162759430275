import { Link as ReactRouterLink} from 'react-router-dom';
import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import {
  button,
  headingH5,
  linkWithoutVisited,
  mq,
  textBody1,
  textBody2,
} from '_utils/styles';
import {
  Grid,
  Link,
} from '_utils/components';
import { spacing } from '_utils/props';

export const MagazineContainer = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.mainMenu.container.backgroundSecondary};
`

export const Container = styled.div`
  margin-top: 32px;  

  ${mq('lg')} {
    margin-top: 48px;
  }

  ${mq.lessThan('md')} {
    margin-top: 0;
  }
  
  &.hide-on-mobile {
    ${mq.lessThan('md')} {
      display: none;
    }
  }
  `;

export const PageContainer = styled(Grid.Container)`
  ${({ theme }) => spacing({ theme, pr: '20px', py: 1 })};
  display: inline-block;

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, pl: '40px', py: 2, })};
  }
`;

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  ${({ theme }) => spacing({ theme, mt: 3 })};
`

export const CoverImageWrapper = styled.figure`
  float: left;
  margin: 0;

  ${({ theme }) => spacing({ theme, mr: 3 })};
`;

export const CoverImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: auto;
  object-fit: cover;
  width: auto;
  max-width: 92px;

  /* applies to magazine cover image to avoids blurry edges */
  image-rendering: -webkit-optimize-contrast;

  ${mq.lessThan('md')} {
    margin-top: unset;
  }
`

export const Divider = styled.div`
  ${({ theme }) => spacing({ theme, my: '40px', })};
  border-bottom: 1px solid ${({ theme }) => theme.mainMenu.secondaryNavigation.divider};

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, my: '20px', })};
  }
`
export const Title = styled.h5`
  ${headingH5}
  color: ${({ theme }) => theme.magazineBanner.title.color};
  font-weight: 900;
  text-align: left;
`;

export const ComponentLabel = styled.span`
  ${textBody2}
  color: ${({ theme }) => theme.mainMenu.magazine.color};
`;


export const LinkWrapper = styled.a``

export const LinkButton = styled(Link)`
  margin-left: auto;
  ${({ theme }) => spacing({ theme, mt: 3 })}

  a {
    ${linkWithoutVisited}
    ${button('slim', 'tertiaryDark')};
    border-radius: 2px;
    box-shadow: 0 0 0 2px;
    display: inline-block;
    font-weight: 800;
    line-height: 20px;
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.mainMenu.magazine.color};
    }

    &:focus {
      border: none;
      box-shadow: 0 0 0 3px;
      outline: none;
      text-decoration: none;
    }

    &:focus::before {
      border: none !important;
    }

    &:focus-within {
      text-decoration: none;
      outline: none;
    }

    ${mq.lessThan('md')} {
      width: 100%;
    }
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mt: 4 })};
    float: unset;
    margin-left: unset;
  }
`;

export const VisitCorporateSiteCTA = styled(LinkButton)`
  ${({ theme }) => spacing({ theme, mx: '20px', mb: 5, mt: 0 })};

  ${mq('md')} {
    display: none;
  }
`

export const IconWrapper = styled.span`
  margin-right: 9px;

  svg {
    width: 17px;
  }
`;


export const ButtonWrapper = styled.div``;

export const LinkButtonSub = styled(ReactRouterLink)`  
  ${button('default', 'primary')};
  ${textBody1};
  width: 100%;     
  margin-bottom: 12px;

  ${mq('lg')} {
    margin-bottom: 32px;
  }

  ${mq.lessThan('md')} {
    display: none;
  }

  path {
    fill: ${({ theme }) => theme.internalPageBanner.linkButton.pathFillColor};
  }
  
  a:focus:before {
     outline: 2px solid ${({ theme }) => theme?.internalPageBanner?.outline?.focusColor} !important;
  }

`;