export const normalizeJssFields = (data: object, filters = []): any => {
  if (!data) {
    return data;
  }

  const normalized = {};

  const keys = Object.keys(data).filter((key) => !filters.length || filters.indexOf(key) > -1);

  keys.forEach((key) => {
    normalized[key] = normalizeJssField(data[key]);
  });

  return normalized;
};

export const normalizeJssField = (field: any): any => {
  if (field && typeof field === 'object' && field.hasOwnProperty('jss')) {
    const {jss, ...rest} = field;

    // Properties with names that match inside and outside `jss` for a given field will be overwritten.
    return {
      ...rest,
      ...jss,
    };
  }

  return field;
};
