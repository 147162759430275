import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';

import { withEditMode, withThemeOption } from '_containers/BaseComponent';
import { Section } from '_utils/components';
import Grid from '_utils/components/Grid';

import { PlaceholderDoubleColumnProps } from './definitions';

import { Wrapper } from './StyledPlaceholderDoubleColumn';

const PlaceholderDoubleColumn: React.FC<PlaceholderDoubleColumnProps> = ({ rendering, editMode }) => {
  const isEmpty = (rendering?.placeholders?.['jss-components-single-column'] ?? []).length === 0;
  const sectionClassName = !editMode && isEmpty ? 'is-empty' : '';
  const sectionTheme = rendering?.params?.placeholderTheme;

  return (
    <Section className={`row ${sectionClassName}`} sectionTheme={sectionTheme}>
      <Grid.Container>
        <Grid.Row justifyContent="center" config={{ gutters: ['left', 'right'] }}>
          <Wrapper
            config={{
              order: { md: 1 },
              col: { xs: 12, md: 6, lg: 6 },
              gutters: ['left', 'right']
            }
            }
          >
            <Placeholder name="jss-components-single-column" rendering={rendering} />
          </Wrapper>
          <Wrapper
            spacingUnit={3}
            config={{
              order: { md: 2 },
              col: { xs: 12, md: 6, lg: 6 },
              gutters: ['left', 'right']
            }
            }
            mb={{ xs: 7, md: 0 }}
          >
            <Placeholder name="jss-components-single-column" rendering={rendering} />
          </Wrapper>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export default compose(withThemeOption, withEditMode)(PlaceholderDoubleColumn);
