import { compose } from 'ramda';
import React from 'react';

import Icon from '_components/Icon';
import { withAuthenticatedCheck } from '_containers/BaseComponent';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { dateConverter } from '_utils/helpers/';

import { createSrcSet } from '_utils/media';
import { getImageAttrsByRatio } from '_utils/helpers/image';
import { getLink } from '_utils/helpers/link';
import { AuthenticatedContentIcon } from '_utils/components/AuthenticatedContentIcon';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize, ImageRatio } from '_utils/types/enums';

import {
  cardTypeLabels,
  ListItemProps,
  stylesThatRenderAttributes,
  stylesThatRenderAuthor,
  stylesThatRenderColourStrip,
  stylesThatRenderDate,
  stylesThatRenderPodcastDate,
  stylesThatRenderPodcastSeriesDate,
  stylesThatRenderDescription, 
  stylesThatRenderEpisode,
  stylesThatRenderIcon,
  stylesThatRenderImage,
  stylesThatRenderListeningTime,
  stylesThatRenderReadingTime,
  stylesThatRenderTags,
  stylesThatRenderPodcastSeriesCardDate
} from './definitions';

import {
  ArrowWrapper,
  AttributesItem,
  AttributesWrapper,
  Card,
  CardTypeLabel,
  Description,
  DetailsColumn,
  FooterWrapper,
  AttributeFooterWrapper,
  IconWrapper,
  Image as StyledImage,
  ImageColumn,
  ImageContainer,
  ITBLabel,
  LogoWrapper,
  PlaceholderCard,
  StyledSeparator,
  Tag,
  TagContainer,
  Title,
  SubTitle,
  BrandContainer,
  BrandImage
} from './StyledItem';

import inTheBlackLogo from '../../assets/logo_intheblack_default.svg';
import inPracticeLogo from '../../assets/logo_inpractice_default.svg';

const getPublishedDate = (published) => {
  if (published) {
    return dateConverter(published, { isUTC: true, outputFormat: 'DD MMM YYYY' });
  }
  return;
}

const Separator = () => <StyledSeparator>&#183;</StyledSeparator>;

const Item: React.FC<ListItemProps> = ({
  author,
  description,
  editMode,
  headingLevel,
  icon,
  isAuthenticatedContent,
  link,
  listingImage,
  published,
  readingTime,
  listeningTime,
  noOfEpisodes,
  episodeBrand,
  tags,
  title,
  ctaButtonText,
  type,
}) => {
  const itemStyle = type;

  const renderAttributes = stylesThatRenderAttributes[itemStyle] || false;
  const renderAuthor = stylesThatRenderAuthor[itemStyle] || false;
  const renderColourStrip = stylesThatRenderColourStrip[itemStyle] || false;
  const renderIcon = stylesThatRenderIcon[itemStyle] || false;
  const renderImage = stylesThatRenderImage[itemStyle] || false;
  const renderDate = stylesThatRenderDate[itemStyle] || false;
  const renderPodcastDate = stylesThatRenderPodcastDate[itemStyle] || false;
  const renderPodcastSeriesDate = stylesThatRenderPodcastSeriesDate[itemStyle] || false;
  const renderPodcastSeriesCardDate = stylesThatRenderPodcastSeriesCardDate[itemStyle] || false;
  const renderDescription = stylesThatRenderDescription[itemStyle] || false;

  const readingTimeLabel = getDictionaryItem('global-read-time', '{minutes} min read time');
  const listeningTimeLabel = getDictionaryItem('global-listening-time', '{minutes} min listening time');
  const episodeLabel = getDictionaryItem('global-episode', '{episode} Episode');
  const seriesLabel = getDictionaryItem('card-list-podcast-series-card-series-label', 'SERIES');
  const formattedReadingTime = readingTimeLabel.replace(/\{minutes\}/ig, readingTime ?? '');
  const formattedListeningTime = listeningTimeLabel.replace(/\{minutes\}/ig, listeningTime ?? '');
  // For cardlist, we only have reading time field for indicating how much time
  const formattedListeningTimeForPodcastEpisodeCard = listeningTimeLabel.replace(/\{minutes\}/ig, readingTime ?? '');
  const formattedEpisode = episodeLabel.replace(/\{episode\}/ig, noOfEpisodes ?? '') + `${parseInt(noOfEpisodes) > 1 ?'s' : ''}`;

  // TODO enable when needed
  // const renderListeningTime = stylesThatRenderListeningTime[itemStyle] || false;
  const renderPodcastSeriesAvailable = (itemStyle === 'podcastSeries' || itemStyle === 'podcastSeriesCard');
  const renderReadingTime = stylesThatRenderReadingTime[itemStyle] || false;
  const renderListeningTime= stylesThatRenderListeningTime[itemStyle] || false;
  const renderEpisode= stylesThatRenderEpisode[itemStyle] || false;
  const renderTags = stylesThatRenderTags[itemStyle] || false;

  const cardProps = getLink(link);
  const publishedDate = getPublishedDate(published);
  const headingType = `h${headingLevel + 1}`;
  const Component = editMode ? PlaceholderCard : Card;
  const CTAButtonText = (ctaButtonText) ? ctaButtonText : '' ;

  const isExternalLink = cardProps?.target === '_blank';
  const hasImageSrc = !!listingImage?.value?.src;

  // For brand image
  const brandImgUrl = episodeBrand?.image || '';
  const brandName = episodeBrand?.name || ''


  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_16By9);

  const classes = [];
  classes.push(type.toLowerCase());

  if (renderImage && hasImageSrc) {
    classes.push('hasImage');
  }

  return (
    (editMode || cardProps) && (
      <Component {...cardProps}
        $showHighlightStrip={renderColourStrip}
        $type={itemStyle}
        className={classes.join(' ')}
        as={isExternalLink ? 'a' : null}>
        {
          renderImage &&
          hasImageSrc &&
          <ImageColumn>
            <ImageContainer>
              <StyledImage
                className="lazyload"
                draggable={false}
                field={
                  editMode
                    ? listingImage?.value
                    : {
                      value: {
                        src: imagePlaceholderSrc,
                        alt: '', // alt text is empty string, as this is a decorative image
                        'data-srcset': createSrcSet(listingImage?.value?.src, [ImageSize.MW_384, ImageSize.MW_512])
                      }
                    }
                }
                {...defaultImageSize}
              />
            </ImageContainer>
          </ImageColumn>
        }
        {
          renderIcon &&
          (editMode || icon) &&
          <IconWrapper>
            <Icon name={icon} ariaHidden />
          </IconWrapper>
        }
        {
          isExternalLink && itemStyle === 'inTheBlack' &&
          <LogoWrapper>
            <img
              alt="INTHEBLACK logo"
              height="20"
              src={inTheBlackLogo}
              width="73"
            />
          </LogoWrapper>
        }
        {
          itemStyle === 'inPractice' && (
            <LogoWrapper>
              <img
                alt="INPRACTICE logo"
                height="20"
                src={inPracticeLogo}
                width="73"
              />
            </LogoWrapper>
          )
        }

         {/*A brand image using absolute position  */}
        {brandImgUrl && <BrandContainer>
          <BrandImage 
            className='lazyload'
            aria-hidden
            draggable={false}
            field={
              editMode
                ? brandImgUrl
                : {
                  value: {
                    src: brandImgUrl,
                    alt: brandName, // alt text is empty string, as this is a decorative image
                    'data-srcset': createSrcSet(brandImgUrl, [ImageSize.MW_128])
                  }
                }
            }
            height="34px"
            width="34px"
          />
        </BrandContainer>}
        <DetailsColumn hasBrandImg={!!brandImgUrl}>
          <div>
            <Title as={headingType}>
              <AuthenticatedContentIcon
                  iconStyleConfig={{
                    xs: {
                      iconSize: 20,
                      mr: 8,
                      top: 4
                    }
                  }}
                  isAuthenticated={isAuthenticatedContent}
              />
              {title}
            </Title>
             {(editMode || renderDescription) && <Description>{description}</Description>}
          </div>
          {
                renderTags &&
                tags?.length > 0 &&
                <TagContainer hasBrandImg={!!brandImgUrl}>
                  {tags.map(tag =>
                      <Tag key={tag.id}>{tag.displayName}</Tag>
                  )}
                </TagContainer>
            }
         </DetailsColumn >
        <AttributeFooterWrapper>
          {
            renderAttributes && <AttributesWrapper>
              {
                cardTypeLabels[type] &&
                <AttributesItem className="cardType">
                  <CardTypeLabel>{cardTypeLabels[type]}</CardTypeLabel>
                  {
                    (
                      (renderDate && publishedDate) ||
                      (renderAuthor && author) ||
                      (renderReadingTime && readingTime) ||
                      renderPodcastSeriesAvailable) &&
                    <Separator />
                  }
                </AttributesItem>
              }
              {
                  renderListeningTime && (editMode || listeningTime) &&
                  <AttributesItem>
                    <Icon name="schedule" ariaHidden />
                    <span>{formattedListeningTime}</span>
                  </AttributesItem>
              }
              {
                // This is only for the podcastEpisodeCard. Card list only have reading time currently, to align with the design "listen time"
                (itemStyle === 'podcastEpisodeCard') &&  renderReadingTime && (editMode || readingTime) &&
                <AttributesItem>
                  <Icon name="schedule" ariaHidden />
                  <span>{formattedListeningTimeForPodcastEpisodeCard}</span>
                </AttributesItem>
              }
              {
                (itemStyle === 'podcastSeriesCard') && 
                  <SubTitle as={`h4`}> {seriesLabel} </SubTitle>
              }
              {
                (renderPodcastSeriesAvailable && renderEpisode) &&
                  <AttributesItem>
                    <Icon name="list" ariaHidden />
                    <span>{formattedEpisode}</span>
                  </AttributesItem>
              }
              {(renderDate && (editMode || typeof publishedDate!== 'undefined')) && (
                <AttributesItem>
                  <Icon name="calendar_today" ariaHidden />
                  {(editMode || publishedDate) && (
                    <span>{getDictionaryItem('card-list-published-label', 'Published')}{' '}<time dateTime={publishedDate?.attrDate}>{publishedDate?.visualDate}</time></span>
                  )}
                </AttributesItem>
              )}
              {(renderPodcastDate && (editMode || typeof publishedDate!== 'undefined')) && (
                  <AttributesItem>
                    <Icon name="calendar_today" ariaHidden />
                    {(editMode || publishedDate) && (
                        <span>{getDictionaryItem('card-list-published-podcast-label', 'Published on')}{' '}<time dateTime={publishedDate?.attrDate}>{publishedDate?.visualDate}</time></span>
                    )}
                  </AttributesItem>
              )}

              {(renderPodcastSeriesDate && (editMode || typeof publishedDate!== 'undefined')) && (
                  <AttributesItem>
                    <Icon name="calendar_today" ariaHidden />
                    {(editMode || publishedDate) && (
                        <span>{getDictionaryItem('card-list-published-podcast-series-label', 'Last Published on')}{' '}<time dateTime={publishedDate?.attrDate}>{publishedDate?.visualDate}</time></span>
                    )}
                  </AttributesItem>
              )}
              {/* This is for card level */}
              {(renderPodcastSeriesCardDate && (editMode || typeof publishedDate!== 'undefined')) && (
                  <AttributesItem>
                    <Icon name="calendar_today" ariaHidden />
                    {(editMode || publishedDate) && (
                        <span>{getDictionaryItem('card-list-published-podcast-series-label', 'Last Published on')}{' '}<time dateTime={publishedDate?.attrDate}>{publishedDate?.visualDate}</time></span>
                    )}
                  </AttributesItem>
              )} 
              {
                (renderAuthor &&
                  (editMode || author)) && (
                  <AttributesItem>
                    <Icon name="person_outline" ariaHidden />
                    <span aria-label="author">{author}</span>
                  </AttributesItem>
                )
              }
              {
                // Card list only have reading time currently, to align with the design "listen time"
                (itemStyle !== 'podcastEpisodeCard') && renderReadingTime && (editMode || readingTime) &&
                <AttributesItem>
                  <Icon name="schedule" ariaHidden />
                  <span>{formattedReadingTime}</span>
                </AttributesItem>
              }
            </AttributesWrapper >
          }

          {(
            <FooterWrapper>
              {!isExternalLink &&
                <>
                  {isAuthenticatedContent &&
                    <ITBLabel>{getDictionaryItem('member-access-only', 'Member access only')}</ITBLabel>
                  }
                  {CTAButtonText}
                  <ArrowWrapper>
                    <Icon name="arrow_forward" ariaHidden />
                  </ArrowWrapper>
                </>
              }
              {isExternalLink &&
                <>
                  {itemStyle === 'inTheBlack' &&
                    <ITBLabel>{getDictionaryItem('card-list-in-the-black-label', 'Visit INTHEBLACK')}</ITBLabel>
                  }
                  <ArrowWrapper><Icon name="open_in_new" ariaHidden /></ArrowWrapper>
                </>}
            </FooterWrapper>
          )}
        </AttributeFooterWrapper>
      </Component >
    )
  );
};

export default compose(withAuthenticatedCheck)(Item);
