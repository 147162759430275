import isEmpty from 'lodash/isEmpty';

/**
 * @description Designed to retrieve the property value by key name. Supports recursive implementation for use cases when the object is multi layered.
 * @param {string} key 
 * @param {object} obj - anonymous object  
 * @returns T
 */
export const getPropertyByKey = <T>(key: string, obj: any): undefined | T => {
    if(isEmpty(obj)){
        return undefined;
    }

    const keyValue = Object.entries(obj)
        .find((p: Array<any>) => ( 
            p.length > 0 
            && p[0] === key
            && p[1] !== undefined
        ));

    return keyValue !== undefined && keyValue.length > 0
        ? keyValue[1] as T
        : undefined;
};