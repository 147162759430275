import { compose } from 'ramda';
import { canUseDOM } from 'exenv';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { withEditMode } from '_containers/BaseComponent';
import { EmbededMarketoFormProps } from './definitions';
import {loadMktoForm} from './loadMktoForm';

const EmbededMarketoForm:React.FC<EmbededMarketoFormProps> = ({
    rendering
}) => {

    const dataSource = rendering?.fields?.data?.datasource;
    const rootUrl = dataSource?.embedAdobeMarketoSettings?.rootURL;
    const munchkinId = dataSource?.embedAdobeMarketoSettings?.munchkinID;
    const formId = dataSource?.formID?.jss?.value;
  //TODO update component to use the SitecoreDatasourceValidator


  useEffect(() => {
    const script = document.createElement("script")
    if(canUseDOM && typeof window !== 'undefined') {
      // if exists in storage don't add else do
      if (!window.localStorage.getItem('market-embed-script')) {
        window.localStorage.setItem('market-embed-script', 'true');
        script.src =
          `//${rootUrl}/js/forms2/js/forms2.min.js`
        document.body.appendChild(script)
        script.async = true;
      }
  
      return () => {
        // clean up the script when the component in unmounted
        document.body.removeChild(script)
        window.localStorage.removeItem('market-embed-script');
      }

    }
 
  }, [])

  useEffect(() => {
    const script = document.createElement("script")
    if(canUseDOM && typeof window !== 'undefined') {
      setTimeout(() => {
        if(!window.localStorage.getItem('market-embed-script-load-form')) {
          document.body.appendChild(script)
          window.localStorage.setItem('market-embed-script-load-form', 'true');
          script.innerHTML =
          `${loadMktoForm(formId, munchkinId, rootUrl)}`
          script.async = true;
        }
    }, 3000)

      return () => {
        // clean up the script when the component in unmounted
        // This is more safe method to remove
        if (script && script.parentNode) {
          script.parentNode.removeChild(script);
        }
        window.localStorage.removeItem('market-embed-script-load-form');
      }
    }
 
  }, [])

  // unmount and remove items
  useEffect(() => {
    window.localStorage.removeItem('market-embed-script-load-form');
    window.localStorage.removeItem('market-embed-script');
  })

  return (
    <div>
      <form id={`mktoForm_${formId}`}></form>
    </div>
  );
};

export default compose(withRouter, withEditMode)(EmbededMarketoForm);
 