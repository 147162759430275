import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import { compose } from 'ramda';
import { withMemberContext } from '_containers/MemberContext';
import Dropdown from '_utils/components/Form/Dropdown';
import FormLabel from '_utils/components/Form/Label';
import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { CompaniesDropdownProps } from './definitions';
import { ManualModeOption, ManualModeToggle } from './StyledDropdown';

const RESULTS_LIMIT = 100;

const CompaniesDropdown: React.FC<CompaniesDropdownProps> = ({
  onEnterManually,
  handleChange,
  companiesApi,
  defaultValue,
  enterManuallyCheck,
  isV2 = false,
  isInRenewal = false,
}) => {
  const [apiOptions, setApiOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const companiesDropdownLabel = getDictionaryItem(
    'form-career-company-name',
    'Company name'
  );
  const companiesDropdownPlaceholder = getDictionaryItem(
    'form-placeholder-company-name',
    'Search company'
  );
  const noOptionsText = getDictionaryItem(
    'form-career-no-results-found',
    'No results found'
  );
  const enterManuallyText = getDictionaryItem(
    'form-career-enter-manually',
    'Enter manually'
  );
  const cantFindCompanyText = getDictionaryItem(
    'form-career-cant-find-company',
    "Can't find your company?"
  );

  const fetchCompanies = (query?: string) => {
    if (!query) {
      setApiOptions([]);
      return;
    }

    setLoading(true);

    companiesApi.request?.({
      axiosRequestConfig: {
        params: {
          searchText: query,
        },
      },
      onComplete: () => {
        setLoading(false);
      },
      onError: (err) => {
        console.warn(err);
      },
      onSuccess: (response) => {
        if ((response?.data?.items?.length ?? 0) > 0) {
          setApiOptions(response?.data?.items);
        }
      },
      useCachedResponse: false,
    });
  };

  return (
    <>
      {isInRenewal === true ? (
        <FormLabel htmlFor="employment.company.id">
          {companiesDropdownLabel}
        </FormLabel>
      ) : (
        <FormLabel htmlFor="employment.company.id" required>
          {companiesDropdownLabel}
        </FormLabel>
      )}

      <Dropdown
        className="api-lookup"
        filterOptions={(x) => x.slice(0, RESULTS_LIMIT)}
        getOptionLabel={(option) => option?.name}
        getOptionSelected={(option, value) =>
          option.identifier === value.identifier
        }
        id="employment.company.id"
        name="employment.company.id"
        loading={loading}
        multiple={false}
        noOptionsText={noOptionsText}
        onInputChange={(_, value) => fetchCompanies(value)}
        options={apiOptions}
        placeholder={companiesDropdownPlaceholder}
        popupIcon="search"
        handleChange={handleChange}
        PaperComponent={({ children, ...rest }) => {
          const footerRef = React.useRef();
          const paperRef = React.useRef();

          return (
            <Paper elevation={6} ref={paperRef} {...rest}>
              {children}
              {!isV2 && (
                <ManualModeOption ref={footerRef}>
                  {cantFindCompanyText}{' '}
                  <ManualModeToggle
                    onClick={onEnterManually}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                  >
                    {enterManuallyCheck && enterManuallyText}
                  </ManualModeToggle>
                </ManualModeOption>
              )}
            </Paper>
          );
        }}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default compose(withMemberContext)(CompaniesDropdown);
