import colors from '_containers/Theme/intheblack/colors';

const defaultValue = {
  headline: {
    colour: colors.element.neutralWhite,
  },
  readingTime: {
    colour: colors.text.darkTextSecondary,
  },
  category: {
    colour: colors.text.darkSubtle,
  },
  bannerArticle: {
    borderTopColour: colors.all.darkBackgroundTint5,
  },
};

export const darkMode = { ...defaultValue };

export default defaultValue;