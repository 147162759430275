import colors from '_containers/Theme/intheblack/colors';


export default {
  background: 'transparent',
  backgroundOverride: {
    Grey: colors.background.tint4,
    Dark: colors.all.darkBackgroundTint1
  },
  divider: {
    color: colors.ui.divider
  }
};

export const darkMode = {
  background: colors.all.darkBackgroundTint1
}
