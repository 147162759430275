import React from 'react';
import { Carousel } from '_utils/components';

const ItemWrapper = ({ carouselEnabled, children, trackingName }) =>
  <Carousel
    flickityOptions={{
      cellAlign: 'left',
      draggable: carouselEnabled,
      groupCells: true,
      pageDots: carouselEnabled,
    }}
    trackingName={trackingName}
  >
    {children}
  </Carousel>;

export default ItemWrapper;
