import styled, { css } from 'styled-components';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { fixedTitle14 } from '_utils/styles/';

const requiredTag = getDictionaryItem("form-required-tag", "required");

export const FormElementLabel = styled.label`
  ${fixedTitle14};
  color: ${({ theme }) => theme.form.label.color};
  display: inline-block;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  &:disabled {
    color: ${({ theme }) => theme.form.input.colorDisabled};
  }
`;

export const FormElementTitle = styled.span`
  ${({ required }) => required && css`
    &:after {
      content: '\\00a0(${requiredTag})';
      display: inline-block;
      font-weight: normal;
    }
  `};
`;

export const FormElementOptionalTitle = styled.span`
  font-weight: normal;
`;