import { compose } from 'ramda';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import CareerProfileFormContextProvider from '_containers/MMFCareerProfileFormContext';
import { PageContainer } from '_components/MMFBPPAssessment/StyledBPPAssessment';
import {
  BackToRenewalFormButton,
  BackToRenewalFormButtonLabel,
  BackToRenewalFormContent,
  CTAButtons,
} from '_components/MMFContactDetailsForm/StyledContactDetailsForm';
import FormContent from '_components/MMFCareerProfileForm/FormContent';
import ApiErrorContent from '_components/MMFCareerProfileForm/Sections/ApiErrorContent';
import Modal from '_components/MMFMembershipRenewalForm/Sections/Declaration/Modal';
import {
  withDataSourceValidation,
  withEditMode,
} from '_containers/BaseComponent';
import { withDigitalDataContext } from '_containers/DigitalDataContext';
import { withMemberContext } from '_containers/MemberContext';
import ModalProvider from '_containers/ModalContext';
import { DiscardChangesAlert } from '_utils/components/Form/DiscardChangesAlert';
import StickyFooter from '_utils/components/Form/StickyFooter';
import {
  Container,
  FormContainer,
  IntroContainer,
  NarrowContainer,
  SpinnerWrapper,
  StyledSpinner
} from '_utils/components/Form/StyledContainerForm';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import {
  checkUserRerferredFromRenewal
} from '_utils/helpers/form';
import { CareerProfileFormProps } from './definitions';
import {
  EditMode,
} from '_components/MMFCareerProfileForm/Sections';
import { careerProfileFormValidators } from './validation';
import InPageAnnouncement from '_components/Announcements/InPageAnnouncement';
import Icon from '_components/Icon';
import {redirectUrls} from '_utils/helpers/urlFuncs' 

const MMFCareerProfileForm: React.FC<CareerProfileFormProps> = (props) => {
  const {
    careerProfileApi,
    careerProfileSubmissionApi,
    companySubmissionApi,
    digitalData,
    editMode,
    rendering,
    setDigitalData
  } = props;

  const successHeading = getDictionaryItem('form-updates-saved', 'Updates saved');
  const errorHeading = getDictionaryItem('form-errors-heading', 'Something went wrong');
  const backToRenewalFormLabelText = getDictionaryItem('form-back-to-renewal-button-label', 'Back to membership renewal');

  const redirectValues = rendering?.fields?.data?.datasource?.submissionRedirectLinks?.values;
  const redirectValuesName = rendering?.fields?.data?.datasource?.submissionRedirectLinks?.values.map((a) => a.name.toLowerCase());

  // TODO: back to renewal form footer can be extracted as a common forms component
  const history = useHistory();

  const shouldShowStickyFooter = useRef<boolean>(false);

  // only check referrer to should show footer on initial mount, not qs change
  useEffect(() => {
    shouldShowStickyFooter.current = checkUserRerferredFromRenewal();
  }, []);

  const handleReturnButtonClick = () => {
    // TODO: might need the URL for Membership Renewal form from BE defined, so that this won't fail?
    history.goBack();
  };

  const uid = rendering?.uid || null;

  const hasAnyAPIError =
    careerProfileApi?.result?.error ||
    careerProfileSubmissionApi?.result?.error ||
    companySubmissionApi?.result?.error;

  const getAPIErrorMessage = () => {
    // company submission api error
    if (typeof companySubmissionApi?.result?.error?.message === 'string') {
      return companySubmissionApi?.result?.error?.message;
    }

    if (typeof careerProfileApi?.result?.error?.message === 'string') {
      return careerProfileApi?.result?.error?.message;
    }

    if (typeof careerProfileSubmissionApi?.result?.error?.message !== 'string') {
      return (
        careerProfileSubmissionApi?.result?.error?.message?.response?.data?.error
        || careerProfileSubmissionApi?.result?.error?.message?.response?.data?.errors?.[0]?.message
      );
    }
  };

  const shouldShowAPIError = hasAnyAPIError;
  const shouldShowAPISuccess = !hasAnyAPIError && careerProfileSubmissionApi?.result?.data;
  const shouldShowSpinner = !hasAnyAPIError && (
    careerProfileApi?.result?.isLoading
    || careerProfileSubmissionApi?.result?.isLoading || companySubmissionApi?.result?.isLoading
  );

  if (shouldShowAPISuccess && successHeading.length > 1) {
    redirectUrls('?referral=', redirectValuesName, redirectValues)
  }

  // render component with mock data when in Experience Editor
  if (editMode) {
    return (
      <EditMode {...props} />
    )
  }

  // dispatch tracking event when API error occur and form loaded
  useEffect(() => {
    if (
      !editMode &&
      !careerProfileApi?.result?.isLoading &&
      hasAnyAPIError &&
      typeof setDigitalData === 'function'
    ) {
      setDigitalData(
        setObjectData(
          ['form'],
          {
            formName: 'career form',
            errorName: getAPIErrorMessage()
          },
          digitalData)
      );
      emitTrackEvent('formErrors');
    }
  }, [hasAnyAPIError, careerProfileApi?.result?.isLoading]);

  return (
    <CareerProfileFormContextProvider>
      <DiscardChangesAlert />

      <Container id={uid} className="career-profile-form">
        <ModalProvider>
          <FormContainer>
            <PageContainer>
              {shouldShowAPIError &&
                <>
                  <IntroContainer>
                    <InPageAnnouncement
                      description={{ value: getAPIErrorMessage() }}
                      title={{ value: errorHeading }}
                      type={{ value: 'Red' }}
                    />
                  </IntroContainer>

                  {careerProfileApi?.result?.error?.message &&
                    <NarrowContainer>
                      <ApiErrorContent {...props} />
                    </NarrowContainer>
                  }
                </>
              }

              {shouldShowAPISuccess &&
                <IntroContainer>
                  <InPageAnnouncement
                    customIcon={{ targetItem: { name: 'check_circle' } }}
                    title={{ value: successHeading }}
                    type={{ value: 'Green' }}
                  />
                </IntroContainer>
              }

              {shouldShowSpinner &&
                <SpinnerWrapper>
                  <StyledSpinner showLoadingLabel={true} />
                </SpinnerWrapper>
              }

              {!shouldShowSpinner && careerProfileApi?.result?.isLoading &&
                <SpinnerWrapper>
                  <StyledSpinner showLoadingLabel={true} />
                </SpinnerWrapper>
              }

              <FormContent
                {...props}
              />
            </PageContainer>
          </FormContainer>

          <Modal />

          {shouldShowStickyFooter?.current && (
            <StickyFooter>
              <BackToRenewalFormContent config={{ col: { xs: 12 }, gutters: [] }}>
                <CTAButtons>
                  <BackToRenewalFormButton onClick={handleReturnButtonClick}>
                    <BackToRenewalFormButtonLabel>
                      <Icon name="arrow_back" />
                      {backToRenewalFormLabelText}
                    </BackToRenewalFormButtonLabel>
                  </BackToRenewalFormButton>
                </CTAButtons>
              </BackToRenewalFormContent>
            </StickyFooter>
          )}
        </ModalProvider>
      </Container>
    </CareerProfileFormContextProvider>
  );
};

export default compose(
  withDataSourceValidation(careerProfileFormValidators),
  withDigitalDataContext,
  withEditMode,
  withMemberContext,
)(MMFCareerProfileForm);