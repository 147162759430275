import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Grid,
} from '_utils/components';

import { spacing } from '_utils/props';

import {
  button,
  hideOnPrint,
  mq,
  subheading2,
} from '_utils/styles';

export const Container = styled.div`
  background-color: ${({ theme }) => theme?.magazinePageBanner?.background};

  ${hideOnPrint}
`;

export const PageContainer = styled(Grid.Container)`
  display: flex;
  ${({ theme }) =>
    spacing({
      theme,
      pt: { xs: 7 },
      pb: { xs: 5, md: 0 }
    })};

  ${mq.lessThan('md')} {
    flex-direction: column;
  }

  ${mq('md')} {
    min-height: 412px;
  }

  ${mq('lg')} {
    min-height: 484px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ${mq('md')} {
    ${({ theme }) => spacing({ theme, py: { md: 5, lg: 8 } })};
  }
`

export const CoverImageWrapper = styled.figure`
  margin: 0 auto;
  width: 208px;

  ${mq('md')} {
    clip-path: inset(0px 0px 24px 0px);
    flex-shrink: 0;
    ${({ theme }) =>
    spacing({
      theme,
      mb: -3,
      ml: 0,
      mr: { md: 5, lg: 7 },
      mt: 0,
    })};
    width: 304px;
  }

  ${mq('lg')} {
    width: 386px;
  }

`;

export const CoverImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  object-fit: cover;
`
export const Title = styled.h1`
  color: ${({ theme }) => theme?.magazinePageBanner?.color};
`;

export const Description = styled.p`
  color: ${({ theme }) => theme?.magazinePageBanner?.color};
  ${subheading2}
`;

export const LinkButton = styled.a`
  ${button('default', 'primary')};
  ${({ theme }) => spacing({ theme, mt: 3 })};
  width: auto;

  ${mq.lessThan('md')} {
    margin-top: 12px;
  }

  ${mq('md')} {
    margin-right: auto;
  }
`;
