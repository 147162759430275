export function toLocaleStringSupported() {
  return !!(typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function');
}

export default function formatNumber(x): string {
  x = Number(x);
  if (Number.isNaN(x) || x === undefined || x === null) return '';
  return x.toLocaleString('en-AU');
}

export const formatNumberTwoDecimals = (num) => parseFloat(num).toFixed(2);
