import colors from '_containers/Theme/corporate/colors';
import { headingH5 } from '_utils/styles';

export default {
  background: colors.all.brandPrimary3,
  color: colors.all.neutralWhite,
  colorHover: colors.all.neutralWhite,
  labelColor: colors.all.darkTextSubtle,
  primaryLink: {
    fontWeight: 'bold',
    level: headingH5,
  }
};
