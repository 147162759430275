import colors from '_containers/Theme/corporate/colors';
import { subheading2, textBody2 } from '_utils/styles';

export default {
  announcementCta: {
    text: textBody2
  },
  announcementThemes: {
    blue: {
      background: colors.all.information100,
      borderColor: colors.all.brandPrimary1,
    },
    yellow: {
      background: colors.all.warning100,
      borderColor: colors.all.warning500,
    },
    red: {
      background: colors.all.error100,
      borderColor: colors.all.error500,
    },
    green: {
      background: colors.all.success100,
      borderColor: colors.all.success500,
    },
    pathFillColor: colors.all.neutral900,
  },
  announcementWrapper: {
    color: colors.text.default,
    pathFillColor: colors.element.neutralBlack
  },
  content: {
    richText: textBody2
  },
  titleWrapper: {
    heading2: subheading2
  }
};
