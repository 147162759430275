import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardNavigation from '_components/DashboardNavigation';
import { MOTION_SPEED_FAST } from '_containers/Theme/global';
import { withMenuContext } from '_containers/MenuContext';

import { enableBodyScroll, disableBodyScroll } from '_utils/helpers';
import { usePrevious, useTimer } from '_utils/hooks';

import { MenuProps } from './definitions';
import Menu from './Menu';
import {
  Nav,
  NavToolbar,
  MenuCloseButton,
  NavToolbarClose,
  NavToolbarControl,
  NavToolbarTabItem,
  NavToolbarTabs,
} from './StyledMainMenu';

// https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html

const MainMenu: React.FC<MenuProps> = ({
  activeMenu,
  fields,
  isLoggedIn,
  isSticky,
  loginUrl,
  menuActive,
  placeholders,
  setActiveMenu,
  setMenuActive,
}) => {
  const [t] = useTranslation();
  const containerRef = useRef<HTMLInputElement>();
  const prevMenuActive = usePrevious(menuActive);
  // focused element index - the first value is parent index, second is child index
  const { setTimer, cancelTimer } = useTimer();
  const [activeSubNav, setActiveSubNav] = useState(-1);
  // TODO determine if/where this needs to be added
  const [transitioning, setTransitioning] = useState(false);
  const dashboardNav = placeholders['jss-header-navigation'] || [];

  // focus listener effect:
  // 1. auto focus first menu item when menu opened
  // 2. close menu if focused element is outside of menu
  useEffect(
    () => {
      const container = containerRef.current;
      const overflowElements = [containerRef.current, ...container.getElementsByTagName('ul')];

      const focusListener = (e) => {
        let target: HTMLElement = e.target;

        if (target && target.id === 'menu-button' || target && target.id === 'dashboard-menu-button') {
          return;
        }

        // iterate through parents until we find menu container or no more
        while (target) {
          if (target === container) {
            // found menu container, bail
            return;
          }
          // move up to next parent
          target = target.parentNode as HTMLElement;
        }
        // focus outside, close menu
        setMenuActive(false);
      };

      // add/remove listener when menu opened/closed
      if (menuActive && !prevMenuActive) {
        setTransitioning(true);
        setTimer(() => {
          document.body.addEventListener('focus', focusListener, true);
          setTransitioning(false);
        }, MOTION_SPEED_FAST + 10);

        disableBodyScroll(overflowElements, {
          reserveScrollBarGap: true
        });
        // without setImmediate effect clean up runs immediately
      } else if (!menuActive && prevMenuActive) {
        document.body.removeEventListener('focus', focusListener, true);
        enableBodyScroll(overflowElements);
        setTransitioning(true);
        // close any open subnavs
        setActiveSubNav(-1);

        setTimer(() => {
          setTransitioning(false);
        }, MOTION_SPEED_FAST + 10);
      }

      // clean up
      return () => {
        cancelTimer();
        enableBodyScroll(overflowElements);
        document.body.removeEventListener('focus', focusListener, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuActive]
  );

  const resetMenu = (e) => {
    e.preventDefault();
    setActiveSubNav(-1);
    setMenuActive(false);
  };

  return (
    <Nav
      id="main-menu"
      className={`main-menu ${canUseDOM && menuActive && 'menu-active'}`}
      isSticky={isSticky}
      ref={containerRef}
    >
      <NavToolbar level={'primary'}>
        <NavToolbarControl submenuActive={activeSubNav >= 0}>
          <NavToolbarTabs>
            <NavToolbarTabItem active={activeMenu === 0} onClick={() => { setActiveMenu(0) }}>{t('menu-label')}</NavToolbarTabItem>
            {!!(dashboardNav.length) && (
              <NavToolbarTabItem active={activeMenu === 1} onClick={() => { setActiveMenu(1) }}>{t('menu-my-cpa-label')}</NavToolbarTabItem>
            )}
          </NavToolbarTabs>
        </NavToolbarControl>
        <NavToolbarClose submenuActive={activeSubNav >= 0}>
          <MenuCloseButton onClick={resetMenu} tabIndex="0" aria-label="close menu" />
        </NavToolbarClose>
      </NavToolbar>
      <Menu
        activeSubNav={activeSubNav}
        fields={fields}
        isLoggedIn={isLoggedIn}
        isSticky={isSticky}
        loginUrl={loginUrl}
        menuID={0}
        setActiveSubNav={setActiveSubNav}
      />
      {!!(dashboardNav.length) && (
        <DashboardNavigation
          activeSubNav={activeSubNav}
          isSticky={isSticky}
          rendering={dashboardNav[0]}
          setActiveSubNav={setActiveSubNav}
        />
      )}
    </Nav>
  );
};

export default compose(withSitecoreContext(), withMenuContext)(MainMenu);
