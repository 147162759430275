import { canUseDOM } from 'exenv';
import React, { createContext, useEffect, useState } from 'react';

import { Optionalize } from '_utils/types';

import { logInDev } from '_utils/helpers/dev';

import { ChildrenPropTypes } from '../../types';

const packageConfig = require('../../../package.json');

import { DigitalDataProps, DigitalDataContextProps } from './definitions';

const defaultDigitalData: DigitalDataProps = {
  form: {
    errorName: '',
    formName: '',
    update: '',
  },
  link: {
    linkName: '',
    linkDestination: '',
    topic: '',
  },
  member: {
    countryRegion: '',
    countryArea: '',
    stateProvince: '',
    cpaID: '',
    cpdhoursPendingByTriennium: '',
    cpdRequired: '',
    cpdtrienniumEndDate: '',
    designation: '',
    division: '',
    loginStatus: '',
    profileType: '',
    userType: '',
  },
  page: {
    contentType: '',
    contributor: '',
    language: '',
    multimediaType: '',
    pageDescription: '',
    pageListingDescription: '',
    pageName: '',
    pageType: '',
    pageurl: '',
    primaryTopic: '',
    siteSection: '',
    sponsor: '',
    topicTags: '',
    publishedDate: '',
    readTime: null,
  },
  section: {
    title: '',
    option: '',
  },
  social: {
    sharedPlatform: '',
  },
};

export const DigitalDataContext = createContext({
  digitalData: null,
  setDigitalData: null
});

function DigitalDataProvider({ children }) {
  const [digitalData, setDigitalData] = useState<DigitalDataProps>(defaultDigitalData);

  // [DEV ONLY] tracing data
  useEffect(() => {

    if (packageConfig?.config?.debugAnalytics) {
      logInDev(
        JSON.stringify(
          digitalData
        ));
    }

    if (canUseDOM) {
      window.digitalData = digitalData;
    }

  }, [digitalData]);

  return (
    <DigitalDataContext.Provider
      value={{
        digitalData,
        setDigitalData
      }}
    >
      {children}
    </DigitalDataContext.Provider>
  );
}

DigitalDataProvider.propTypes = {
  children: ChildrenPropTypes
};

export default DigitalDataProvider;

export const DigitalDataConsumer = DigitalDataContext.Consumer;

export function withDigitalDataContext<T extends DigitalDataContextProps = DigitalDataContextProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withDigitalDataContext: React.FC<Optionalize<T, DigitalDataContextProps>> = (props) => (
    <DigitalDataContext.Consumer>
      {(value) => <WrappedComponent {...value} {...(props as T)} />}
    </DigitalDataContext.Consumer>
  );

  withDigitalDataContext.displayName = `withDigitalDataContext(${displayName})`;

  return withDigitalDataContext;
}
