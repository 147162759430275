import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import {
  button,
  hideOnPrint,
  linkWithoutVisited,
  mq,
  textBody1,
} from '_utils/styles';
import {
  Grid,
  Link,
} from '_utils/components';
import { spacing } from '_utils/props';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.magazineBanner.container.column.background};
  border-bottom: 4px solid ${({ theme }) => theme.magazineBanner.container.column.border};

  &.hide-on-mobile {
    ${mq.lessThan('md')} {
      display: none;
    }
  }

  ${hideOnPrint}
`;


export const ClickableWrapper = styled.div`
  text-decoration: none;

  &:focus {
      outline-color: ${({ theme }) => theme.magazineBanner.container.column.border} !important;
      outline-offset: 0px !important;
      outline-width: 2px !important;
      outline-style: solid !important;
  }
`;

export const PageContainer = styled(Grid.Container)`
  display: flex;

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, py: 5 })};
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  ${mq.lessThan('md')} {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 20px;
  }
`

export const CoverImageWrapper = styled.figure`
  margin: 0;
  float: left;

  ${mq('md')} {
    clip-path: inset(0px 0px 18px 0px);
    ${({ theme }) => spacing({ theme, mr: 4, mt: 2, mb: '-18px' })};
  }
`;

export const CoverImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  object-fit: cover;

  ${mq.lessThan('md')} {
      margin-top: unset;
  }
`

export const Title = styled.span`
  color: ${({ theme }) => theme.magazineBanner.title.color};
  font-weight: 800;
  ${textBody1}
`;

export const ComponentLabel = styled.span`
  ${textBody1}
  color: ${({ theme }) => theme.magazineBanner.title.color};

  :before {
    content: '\\00a0•\\00a0';

    ${mq.lessThan('md')} {
      content: '';
      padding-bottom: 12px;
    }
  }
`;

export const LinkButton = styled(Link)`
  float: right;
  margin-left: auto;
  color: ${({ theme }) => theme.magazineBanner.link.color};

  a {
    ${linkWithoutVisited};
    ${button('slim', 'tertiaryDark')};
    border-bottom: none;
    border-radius: 2px;
    display: inline-block;
    padding-right: 38px;
    position: relative;

    &:focus {
      box-shadow: 0 0 0 3px;
      text-decoration: none;
      outline: none;
      border: none;
    }

    &:focus::before {
      border: none !important;
    }

    &:focus-within {
      text-decoration: none;
      outline: none;
    }

    :after {
      background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="%23${({ theme }) => theme.magazineBanner.button.iconColor.replace('#', '')}"/%3E%3C/svg%3E');
      background-size: 14px 14px;
      background-position: center center;
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      position: absolute;
      right: 10px;
      top: 0;
      transition: background-image 0.1s linear, color 0.1s linear, background-color 0.1s linear;
      width: 20px;
    }
    ${mq('md')} {
      width: auto;
    }

  }

  ${mq.lessThan('md')} {
    margin-top: 12px;
    margin-left: unset;
    float: unset;
  }
`;