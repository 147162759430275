import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'ramda';
import { withEditMode } from '_containers/BaseComponent';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize } from '_utils/types/enums';
import Icon from '_components/Icon';
import { makeStyles } from '@material-ui/core/styles';
import {
  SpotlightCardContainer,
  SpotlightCardImageWrapper,
  SpotlightCardImage,
  SpotlightCardHeading,
  SpotlightCardHeadingText,
  SpotlightCardText,
  SpotlightCardGradient,
} from './StyledSpotlightItem';
import { ListItemProps } from './definitions';


const useStyles = makeStyles({
  hoverLink: {
    textDecoration: 'none',
    '&:hover': {
      boxShadow:`0px 1px 4px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.12)`
    },
  },
});

const SpotlightCard: React.FC<ListItemProps> = ({
  description,
  editMode,
  listingImage,
  title,
  link,
}) => {
  const classes = useStyles();

  return (
    <Link to={link} className={classes.hoverLink}>
    <SpotlightCardContainer
      as={'div'}
    >
      <SpotlightCardImageWrapper>
        <SpotlightCardImage
          className='lazyload'
          draggable={false}
          aria-hidden
          field={
            editMode
              ? imagePlaceholderSrc
              : {
                value: {
                  src: imagePlaceholderSrc,
                  alt: '', // alt text is empty string, as this is a decorative image
                  'data-srcset': createSrcSet(listingImage?.value?.src,
                    [
                      ImageSize.MW_384,
                      ImageSize.MW_512,
                      ImageSize.MW_768,
                    ])
                }
              }
          }
          height={ImageSize.MW_384}
          width={ImageSize.MW_384}
        />
      </SpotlightCardImageWrapper>
      <SpotlightCardHeading>
        <SpotlightCardHeadingText>
          {title}
        </SpotlightCardHeadingText>
        <SpotlightCardText>
          <span>{description}</span>
          <span><Icon name="arrow_forward" ariaHidden /></span>
        </SpotlightCardText>
      </SpotlightCardHeading>
      <SpotlightCardGradient />
    </SpotlightCardContainer>
    </Link>
  );
};

export default compose(
  withEditMode,
)(SpotlightCard);
