import colors from '_containers/Theme/intheblack/colors';


export default {
  searchMenuHeader: {
    borderColor: colors.all.darkBackgroundTint5,
  },
  closeIcon: {
    pathFillColor: colors.element.neutralWhite,
  },
  searchMenuButton: {
    pathFillColor: colors.all.brandPrimary1,
    focus: colors.element.focused,
    label: {
      color: colors.all.brandPrimary3
    }
  }
}