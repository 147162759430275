import styled from 'styled-components';
import { Grid } from "_utils/components";
import { avoidPageBreakOnPrint, mq } from "_utils/styles";


export const ListWrapper = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  a {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Container = styled(Grid.Container)`
  // remove padding if child of Single Column placeholder (ie. within Dashboard)
  .ph-sc & {
    padding: 0;
  }

  background: ${({ theme }) => theme.cardList.background};
  
`;

export const List = styled(Grid.Row)`
  list-style: none;
  padding: 0;

  /* beat specificity of grid breakpoints */
  && {
    margin-top: 0;
  }

  ${avoidPageBreakOnPrint}

  @media print {
    display: block;
    width: 100%;
  }
`;

export const MainTitle = styled.div`
  h2& {
    margin-top: 0;
    ${mq('md')} {
      font-size: 32px;
      line-height: 38px;
    }

    ${mq.lessThan('md')} {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;
