import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  Grid,
} from '_utils/components';
import {
  Container as CarouselContainer,
  PrevNextPosition,
} from '_utils/components/Carousel/StyledCarousel';
import {
  avoidPageBreakOnPrint,
  link,
  mq,
  showOnPrint,
  svg,
  textBody2
} from '_utils/styles';
import { spacing } from '_utils/props';

export const Header = styled.div`
  color: ${({ theme }) => theme.cardListWithInternalNavigation.color};

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: inherit;
  }
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.is-hidden {
    display: none;
    ${showOnPrint}
  }

  @media print {
    display: inline-block !important;
    margin:0;
    width: 33%;
  }
`;

export const Container = styled(Grid.Container)`
  background: ${({ theme }) => theme.cardListWithInternalNavigation.background};

  // if carousel, ensure shadows aren't cut off and apply negative margin to extend full width in < lg breakpoints
  ${CarouselContainer} {
    ${({ theme }) => spacing({
  theme,
  ml: {
    xs: `-${theme.grid.gutter.size.xs}px`,
    md: `-${theme.grid.gutter.size.md}px`,
    lg: `-${theme.grid.gutter.size.lg}px`,
  },
  mr: {
    xs: `${theme.grid.gutter.size.xs * 2}px`,
    md: `${theme.grid.gutter.size.md * 2}px`,
    lg: `${theme.grid.gutter.size.lg * 2}px`,
  }
})}

    width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.xs * 2}px)`};

    ${mq('md')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.md * 2}px)`};
    }

    ${mq('lg')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.lg}px)`};
    }

    .flickity-slider {

      ${avoidPageBreakOnPrint}

      @media print {
        overflow: hidden;
        white-space: pre-wrap;
      }

      ${ListItem} {
        ${({ theme }) => spacing({
  theme,
  pl: {
    xs: `${theme.grid.gutter.size.xs}px`,
    md: 3,
    lg: 0,
  },
  pr: {
    xs: 0,
    md: 0,
    lg: `${theme.grid.gutter.size.lg}px`,
  },
  py: {
    xs: 1,
    md: 1,
    lg: 1,
  },
})}

        ${mq('lg')} {
          transform: ${({ theme }) => `translateX(${theme.grid.gutter.size.lg}px)`};
        }
      }

      /* Corrections to the last card, to allow it to sit on the end edge with shadow overflowing right */

    }

    ${PrevNextPosition} {
      &.prev {
        ${({ theme }) => css`
          left: ${theme.grid.gutter.size.xs}px;
          top: 45%;

          ${mq('md')} {
            left: ${theme.grid.gutter.size.md}px;
          }

          ${mq('lg')} {
            left: 64px;
          }
        `}
      }

      &.next {
        ${({ theme }) => css`
          right: ${theme.grid.gutter.size.xs}px;
          top: 45%;

          ${mq('md')} {
            right: ${theme.grid.gutter.size.md}px;
          }

          ${mq('lg')} {
            right: 52px;
          }
        `}
      }
    }
  }
`;

export const List = styled(Grid.Row)`
  list-style: none;
  padding: 0;

  /* beat specificity of grid breakpoints */
  && {
    margin-top: 0;
  }

  ${avoidPageBreakOnPrint}

  @media print {
    display: block;
    width: 100%;
  }
`;

export const FooterLinkContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const FooterLink = styled(NavLink)`
  ${link};
`;

export const FooterLinkLabel = styled(Text)`
  ${textBody2}
`;

export const ArrowWrapper = styled.span`
  ${svg(14, 14)}
  margin-left: 6px;
  margin-right: 2px;
  width: 14px;

  path {
    fill: ${({ theme }) => theme.cardListWithInternalNavigation.action};
  }
`;

