import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import styled, { css } from 'styled-components';

// because ${({theme})} not exposed for overrides for material UI JS obj
import colors from '_containers/Theme/colors';
import { FONT_FAMILY_TEXT } from '_containers/Theme/global';

import { Spinner } from '_utils/components';
import { CheckboxLabel } from '_utils/components/Form/CheckboxList/StyledCheckboxList';
import { listReset } from '_utils/styles/list'
import { StyledAutocompleteProps } from './definitions';

import {
  buttonReset,
  fixedBody14,
  fixedTitle14
} from '_utils/styles';

export const StyledSpinner = styled(Spinner)`
  height: 24px;
  width: 24px;
`;

export const StyledTextField = styled(TextField)`
  ${fixedBody14}
`;

const chipStyles = css`
  ${fixedTitle14}
  background-color: ${({ theme }) => theme.complexFormElement.dropdown.chips.background};
  border-radius: 8px;
  color: ${({ theme }) => theme.complexFormElement.dropdown.chips.color};
  font-weight: bold;
`;

export const StyledAutocomplete: React.FC<StyledAutocompleteProps> = styled(Autocomplete)`
  ${fixedBody14}

  &.MuiAutocomplete-root {
    font-family: ${FONT_FAMILY_TEXT};
  }

  // chip = tag (for multiple options)
  .MuiChip-root {
    ${chipStyles}

    // When chip tag should display underneath
    ${({ $shouldShowSelectedValuesBelow }) =>
    $shouldShowSelectedValuesBelow && css`
        display: none;
      `
  }
  }

  // open/close indicator
  .MuiAutocomplete-popupIndicator {
    color: ${({ theme }) => theme.complexFormElement.dropdown.indicator.color};
  }

  // open/close indicator, open state
  .MuiAutocomplete-popupIndicatorOpen {
    ${({ $popupIconType }) => $popupIconType === 'search' && css`
      transform: rotate(0deg);
    `};
  }

  &.api-lookup[aria-expanded="true"] {
    .MuiAutocomplete-popupIndicator {
      visibility: hidden;
      width: 0;
    }

    .MuiAutocomplete-clearIndicator {
      visibility: visible;
    }
  }
`;

export const SelectedValuesContainer = styled.ul`
  ${listReset}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    margin: 10px 6px 0px 0px;
  }

  span {
    ${chipStyles}
    color: ${({ theme }) => theme.form.label.color};
    display: inline-block;
    padding: 4px 16px;
  }
`;

export const overrides = {
  inputRoot: {
    fontFamily: FONT_FAMILY_TEXT,
    paddingBottom: '6px !important',
    paddingTop: '6px !important',
    // focus state
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${({ theme }) => theme.complexFormElement.dropdown.overrides.borderColor}`
    }
  },
  // dropdownlist option
  option: {
    'min-height': 'auto',
    // hover state
    '&[data-focus="true"]': {
      backgroundColor: `${({ theme }) => theme.complexFormElement.dropdown.overrides.backgroundHover}`
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
  },
  popupIndicator: {},
  tag: {
    fontFamily: FONT_FAMILY_TEXT
  }
}

export const CheckboxOption = styled(CheckboxLabel)`
  ${fixedBody14}
  height: auto !important;
  padding-left: 30px;
  width: 100% !important;

  &.selected {
    background-color: none;
  }
`;

export const ManualModeOption = styled.div`
  border-top: 1px solid ${({ theme }) => theme.complexFormElement.dropdown.manualOption.borderColor};
  padding: 12px;
`;

export const ManualModeToggle = styled.button`
  ${buttonReset};
  color: ${({ theme }) => theme.complexFormElement.dropdown.manualToggle.color};
  text-decoration: underline;
`;
