import colors from '_containers/Theme/intheblack/colors';

export default {
  link: {
    minWidth: {
      md: '130px',
    }
  },
  title: {
    color: colors.text.interactive1,
    colorHover: colors.text.interactive1Hover,
    colorVisited: colors.text.visited,
  },
  icon: {
    color: colors.text.interactive1,
    colorHover: colors.text.interactive1Hover,
  },
  subtitle: {
    color: colors.text.subtle
  }
};
