import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import {
  fixedBody14,
  fixedTitle12,
  headingH5,
  hideOnPrint,
  linkFeatured,
  mq
} from '_utils/styles';
import { Link } from '_utils/components';
import { spacing } from '_utils/props';

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.magazineBanner.container.sidebar.background};
  display: flex;
  padding: 16px;

  ${mq('md')} {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  ${({ theme }) => spacing({ theme, p: { md: '24px', lg: 4 } })};

  ${hideOnPrint}
`;

export const ClickableWrapper = styled.div`
  display: inline-block;
  text-decoration: none;
`;

export const CoverImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  object-fit: cover;

  ${mq('md')} {
    width: 100%;
  }
`;

export const CoverImageWrapper = styled.figure`
  margin: 0;
`;

export const ContentWrapper = styled.div`
  padding-top: 16px;

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, ml: '16px', my: 1 })};
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: unset;
  }
`;

export const Title = styled.span`
  ${fixedTitle12}
  color: ${({ theme }) => theme.magazineBanner.title.color};
  font-weight: 800;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const Label = styled.h5`
  ${headingH5}
  color: ${({ theme }) => theme.magazineBanner.title.color};
`;

export const LinkItem = styled(Link)`
  padding-top: 16px;
  a {
    ${linkFeatured}
    ${fixedBody14}
    color: ${({ theme }) => theme.magazineBanner.link.color};
    display: inline-block;
    font-weight: 800;
    padding-right: 30px;
    position: relative;
    text-transform: uppercase;

    &:hover {
      color: ${({ theme }) => theme.magazineBanner.link.hoverColor};
      text-decoration: underline;
    }

    :after {
      background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="%23${({ theme }) => theme.magazineBanner.link.iconColor.replace('#', '')}"/%3E%3C/svg%3E');
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-image 0.1s linear, color 0.1s linear, background-color 0.1s linear;
      width: 20px;
    }
  }

`;
