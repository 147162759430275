import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

import { getDictionaryItem } from '_utils/data/dictionaryItem';

import { CareerProfileFormProps } from '../definitions';

import { Container, FormContainer, NarrowContainer } from '_utils/components/Form/StyledContainerForm';
import { EditModeHeading, EditModeInstructions, FormSection, LargeHeading, RichTextContainer } from '_utils/components/Form/StyledFormSection';

const EditMode: React.FC<CareerProfileFormProps> = (props) => {
  const rendering = props?.rendering;
  const apiErrorheading = getDictionaryItem('form-api-error-heading', 'We’re experiencing some technical difficulties');

  return (
    <Container>
      <FormContainer>
        <NarrowContainer>
          <EditModeHeading>Career Profile Form</EditModeHeading>
          <EditModeInstructions>
            Given this component relies on member data and has multiple states, a custom editing experience has been setup to allow you to add content to the placeholders
          </EditModeInstructions>
          <EditModeInstructions>
            <strong>API Error Content: </strong> shown below the error message when the APIs returns an error getting data from the CareerProfile API
            (the heading is a dictionary item at <code>/sitecore/content/CPA/Corporate/Dictionary/Forms/API Error Heading</code>)
          </EditModeInstructions>
          <FormSection>
            <LargeHeading>{apiErrorheading}</LargeHeading>
            <RichTextContainer>
              <Placeholder name="jss-form-api-error" rendering={rendering} />
            </RichTextContainer>
          </FormSection>
        </NarrowContainer>
      </FormContainer>
    </Container>
  );
};

export default EditMode;
