import { compose } from 'ramda';
import React, { useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import ErrorMessage from '_utils/components/Form/ErrorMessage';
import { CareerProfileFormContext, CareerProfileFormValues } from '_containers/CareerProfileFormContext/definitions';
import { withEditMode } from '_containers/BaseComponent';
import {
    FormContext,
    withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import {
    EditableSectionContainer,
    EditableSectionHeader,
} from '_utils/components/Form/EditableSection';

import {
    Field,
    FormSummary,
} from '_utils/components/Form/StyledFormSection';
import { Tooltip } from '_utils/components';
import LicencesTableRow from './LicencesComponents/LicencesTableRow';
import LicencesListRow from './LicencesComponents/LicencesListRow'
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import Grid from '@material-ui/core/Grid';
import { FieldSummaryNoValue } from '_utils/components/Form/FieldSummary/StyledFieldSummary';
import { LicencesTableWrapper, LicencesAddButton, LicencesListContentWrapper, LicencesListRowWrapper } from './LicencesComponents/StyledLicencesListForm';
import { CareerProfileFormProps } from '../definitions';
import mockData from '../mock.json';

// Todo: change it to supporting field level and array level
const getErrorMessage = (errorObj, msg) => {

    if (typeof errorObj === 'string') {
        return errorObj || '';
    }

    // Since there is Array here with edit and read view, this error is for the read view.
    if (Array.isArray(errorObj)) {
        let err = msg // In Licence field, only licence Type is a must required value
        return err
    }

    return errorObj?.[Object.keys(errorObj)?.[0]] || '' // pick out first key in error obj if the field name describes a field that stores an object with many keys, and possibly has multiple validation errors
};

const LicencesListForm: React.FC<CareerProfileFormProps> = ({
    editMode,
    mockCareerProfile,
    rendering,
    careerProfileApi
}) => {
    // get the field and label values
    const heading = getDictionaryItem('form-career-section-licenses', 'Licences and certificates');
    const updateLabel = getDictionaryItem('form-career-button-update', 'Update');
    const notCapturedLabel = getDictionaryItem('form-not-captured-label', 'Not captured');
    const tooltipText = getDictionaryItem('form-career-license-tooltip-text');
    const licenceTypeLabel = getDictionaryItem('form-career-license-type', 'Licence Type');
    const registrationNumberLabel = getDictionaryItem('form-career-registration-number', 'Registration Number');
    const addItemLabel = getDictionaryItem('form-career-license-add-item', 'Add Item');
    const notProvidedLabel = getDictionaryItem('form-career-registration-number-not-provided', 'Not Provided');
    const noItemAddedLabel = getDictionaryItem('form-career-license-no-item-added', 'No items added');
    const licenceTypeNoEmpty = getDictionaryItem('form-career-license-error-not-empty', 'Licences Type should not be empty');


    const formContext = useContext<CareerProfileFormContext>(FormContext);
    const [isMobile, setIsMobile] = useState(false)
    const sectionContext = formContext?.licences;
    const referenceData = careerProfileApi?.result;

    const getLicensesOptions = referenceData?.data?.referenceData?.licences?.items;

    const uid = `licenses-certificates-mmf`;

    // render component with mock data when in Experience Editor
    const careerProfile =
        !editMode
            ? careerProfileApi?.result?.data
            : mockCareerProfile ?? mockData?.mockCareerProfile;

    const { values, setFieldValue, getFieldMeta } = useFormikContext<CareerProfileFormValues>();

    // // For checking the duplicate
    const meta = getFieldMeta(`licences.items`) ?? null;
    let hasError = meta?.error;
    const errorMessage = getErrorMessage(meta?.error, licenceTypeNoEmpty);

    const getLicences = values?.licences?.items;

    const DesktopView = (isEditing) => {
        if (isEditing) {
            return (
                <FormSummary>
                    <Field config={{ col: { xs: 12 } }}>
                        <LicencesTableWrapper>
                            <thead>
                                <tr>
                                    <th>{licenceTypeLabel}</th>
                                    <th>{registrationNumberLabel}</th>
                                </tr>
                            </thead>

                            {/* feel like this part should be as a component with proprs */}
                            <tbody>
                                {getLicences?.length === 0 ? <FieldSummaryNoValue>{noItemAddedLabel}</FieldSummaryNoValue> :
                                    getLicences?.map((item, index) => {
                                        return <LicencesTableRow
                                            item={item}
                                            index={index}
                                            getLicensesOptions={getLicensesOptions}
                                            addNew={item?.addNew}
                                        />
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <LicencesAddButton onClick={() => {
                                            // add a new row with "singItemEdit true"
                                            const updatedLicences = [...getLicences, {
                                                "licenceName": "",
                                                "registrationNumber": "",
                                                "licenceStatus": null,
                                                "addNew": true
                                            }]
                                            setFieldValue('licences.items', updatedLicences)
                                        }}>
                                            {addItemLabel}
                                        </LicencesAddButton>
                                    </td>
                                </tr>
                            </tfoot>
                        </LicencesTableWrapper>
                    </Field>
                </FormSummary>
            )
        }

        return (
            <FormSummary>
                <Field config={{ col: { xs: 12 } }}>
                    <LicencesTableWrapper>
                        <thead>
                            <tr>
                                <th>{licenceTypeLabel}</th>
                                <th>{registrationNumberLabel}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {/* feel like this part should be as a component with proprs */}
                            {getLicences?.length === 0 ? <FieldSummaryNoValue>{notCapturedLabel}</FieldSummaryNoValue> :
                                getLicences.map((item) => {
                                    return (
                                        <tr>
                                            <td>
                                                {item?.licenceName}
                                            </td>
                                            <td>
                                                {item?.registrationNumber || notProvidedLabel}
                                            </td>

                                        </tr>
                                    )
                                })

                            }
                        </tbody>
                    </LicencesTableWrapper>

                </Field>
            </FormSummary>

        )
    }


    const MobileView = (isEditing) => {
        if (isEditing) {
            return (
                <FormSummary>
                    <Field config={{ col: { xs: 12 } }}>

                        {getLicences?.length === 0 ? <FieldSummaryNoValue>{noItemAddedLabel}</FieldSummaryNoValue> :
                            getLicences.map((item, index) => {
                                return (
                                    <LicencesListRow item={item} index={index} getLicensesOptions={getLicensesOptions} addNew={item?.addNew} />
                                )
                            })
                        }
                        <Grid container justifyContent="flex-end">
                            <Grid item> <LicencesAddButton onClick={() => {
                                // add a new row with "singItemEdit true"
                                const updatedLicences = [...getLicences, {
                                    "licenceName": "",
                                    "registrationNumber": "",
                                    "licenceStatus": null,
                                    "addNew": true
                                }]
                                setFieldValue('licences.items', updatedLicences)
                            }}>
                                {addItemLabel}
                            </LicencesAddButton></Grid>
                        </Grid>
                    </Field>
                </FormSummary>
            )
        }

        return (
            <FormSummary>
                <Field config={{ col: { xs: 12 } }}>
                    {getLicences?.length === 0 ? <FieldSummaryNoValue>{notCapturedLabel}</FieldSummaryNoValue> :
                        getLicences.map((item) => {
                            return (
                                <Field config={{ col: { xs: 12 } }}>
                                    <LicencesListRowWrapper>
                                        <LicencesListContentWrapper><span>{licenceTypeLabel}</span><span>{item?.licenceName}</span></LicencesListContentWrapper>
                                        <LicencesListContentWrapper isRegistrationNumber={true}> <span>{registrationNumberLabel}</span><span>{item?.registrationNumber || notProvidedLabel}</span></LicencesListContentWrapper>
                                    </LicencesListRowWrapper>
                                </Field>
                            )
                        })
                    }
                </Field>
            </FormSummary>

        )
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // check if window width is less than 768px
        }

        setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

   

    return (
        <EditableSectionContainer
            className="licenses-certificates-form"
            isEditing={sectionContext?.isEditing}
            uid={uid}
        >
            <EditableSectionHeader
                icon="verified_user"
                updateLabel={updateLabel}
                sectionIdentifier={uid}
            >
                {heading} <Tooltip content={tooltipText} />
            </EditableSectionHeader>

            {isMobile ? MobileView(sectionContext?.isEditing) : DesktopView(sectionContext?.isEditing)}

            {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </EditableSectionContainer>
    );
};

export default compose(
    withEditMode,
    withMemberContext,
    withFormSectionKeyContext('licences'),
)(LicencesListForm);