import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { spacing } from '_utils/props';
import {
  avoidPageBreakOnPrint,
  headingH1,
  mq,
  subheading2,
} from '_utils/styles';

import ContentRichText from "../ContentRichText";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .advertisement + & {
    ${({ theme }) => spacing({ theme, pt: 4 })}
}

  ${mq('sm')} {
    flex-direction: row;
  }
`;

export const ProfileFigureWrap = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => spacing({ theme, mb: { xs: 5, md: 0 } })}
`

export const ProfileFigure = styled.div`
  ${({ theme }) => spacing({ theme, mr: 4 })}
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 168px;
  position: relative;
  width: 168px;

  img,
  svg {
    border-radius: ${(props) => !props.editMode ? '50%' : null};
    outline-offset: 2px;
  }

 ${mq('md')} {
    height: 224px;
    width: 224px
  }

 ${mq('lg')} {
    height: 304px;
    width: 304px
  }
`;

export const ProfileImage = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const ProfileDetails = styled.div`
`

export const Heading = styled.h1`
  ${headingH1};
  ${({ theme }) => spacing({ theme, mb: 4 })}
`

export const ProfileBio = styled(ContentRichText)`
  ${subheading2}
  ${({ theme }) => spacing({ theme, mb: 4 })}
`;

export const SocialListItem = styled.div`
  display: inline-block;
  width: 24px;

  & + & {
    margin-left: 24px;
  }

  .linked_in {
    svg path {
      fill: invert(100%);
    }
  }
`;

const linkStyle = css`
  color: inherit;
  display: inline-block;
  line-height: 1;
`;

export const SocialLink = styled(Link)`
  ${linkStyle};
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  svg path {
    fill: ${({ theme }) => theme.colors.button.secondary.background};
  }
`;
