import styled from 'styled-components';

import { safeFocusRemoval, focusOutline } from '../../styles/focus';
import { caption1 } from '../../styles/text'; // can't import color here
import color from '../../styles/color';

export const Trigger = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;

  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }
`;

export const TriggerText = styled.span`
  ${caption1}
  color: ${color('element.primary2')};
  transition: opacity 400ms;

  opacity: ${({ hide }) => (hide ? 0 : 1)};

`;

export const Status = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms, transform 400ms;
  ${caption1}
  color: ${({ type }) => (type === 'success' ? '#2DB84B' : '#B00020')};
  margin: 0;


  &[aria-hidden='true'] {
    opacity: 0;
    transform: translateY(5px);
    visibility: hidden;
  }
`;
