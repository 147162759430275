import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react';

export type SearchDataType = {
  filterName?: string;
  filterValue?: string;
  searchTerm?: string;
  numberOfResults?: string;
  searchPage?: string;
  searchResult?: string;
}

export type UserRestrictedAccess = {
  displayName?: string;
  fields?: any;
  id: string;
  name: string;
  url?: string;
}

export type DigitalDataProps = {
  accordion?: {
    name?: string;
    text?: string;
  };
  authenticatedContent?: boolean;
  authenticatedContentPermissions?: string;
  carousel?: {
    carouselName?: string;
  };
  download?: {
    fileName?: string;
    fileTitle?: string;
  };
  form?: {
    errorName?: string;
    formName?: string;
    update?: string;
  };
  link?: {
    linkName?: string;
    linkDestination?: string;
    topic?: string;
  };
  page: {
    contentType?: string;
    contributor?: string;
    language?: string;
    multimediaType?: string;
    pageDescription?: string;
    pageListingDescription?: string;
    pageName?: string;
    pageType?: string;
    pageurl?: string;
    primaryTopic?: string;
    publishedDate?: string;
    readTime?: string;
    siteSection?: string;
    sponsor?: string;
    topicTags?: string;
  };
  member?: {
    countryRegion?: string;
    countryArea?: string;
    stateProvince: string;
    cpaID?: string;
    cpdhoursPendingByTriennium?: string;
    cpdRequired?: string;
    cpdtrienniumEndDate?: string;
    designation?: string;
    division?: string;
    loginStatus?: string;
    profileType?: string;
    userType?: string;
  };
  podcast?: {
    podcastPlatform?: string;
  };
  search?: SearchDataType;
  section?: {
    title?: string;
    option?: string;
  };
  social?: {
    sharedPlatform?: string;
  };
};

export type DigitalDataContextProps = {
  digitalData: DigitalDataProps;
  rendering: ComponentRendering & {
    fields: {
      data: {
        datasource: any;
      }
    };
  };
  setDigitalData: (digitalData: DigitalDataProps) => void;
  sitecoreContext: any;
};
export interface AnalyticsDataProps {
  digitalData?: DigitalDataProps;
  setDigitalData?: (digitalData: DigitalDataProps) => void;
  trackingName?: { value: string };
};

export const ANALYTICS_QS_KEY = 'icid';

export type LinkTrackingEvent = (name: string, destination: string) => void;
