import { dataFetcher } from '_utils/dataFetcher';

import {
  ApiDefinition,
  ApiCallback,
  ApiProxyProps,
  ApiResult,
  RequestArgs,
} from './definitions';

export const ApiProxy = <Response extends any>({
  apiUri,
  isLoggedIn,
  result,
  setResult,
  axiosRequestConfig: proxyLevelAxiosConfig,
}: ApiProxyProps<Response>): ApiDefinition<Response> => {
  let apiCallRunning = false;

  const reportSuccess = (
    apiResponse: ApiResult<Response>,
    onComplete?: ApiCallback<Response>,
    onSuccess?: ApiCallback<Response>,
  ): ApiResult<Response> => {
    if (onSuccess) {
      onSuccess(apiResponse);
    }

    if (onComplete) {
      onComplete(apiResponse);
    }
    return apiResponse;
  };

  const reportError = (
    errorMessage: string,
    onComplete?: ApiCallback<Response>,
    onError?: ApiCallback<Response>,
  ): ApiResult<Response> => {
    const apiResponse = {
      data: null,
      error: {
        code: null,
        message: errorMessage ?? 'Unknown API error',
      },
      isLoading: false,
    };

    if (onError) {
      onError(apiResponse);
    }

    if (onComplete) {
      onComplete(apiResponse);
    }

    return apiResponse;
  };

  const request = ({
    axiosRequestConfig = {},
    data = {},
    onComplete = null,
    onError = null,
    onSuccess = null,
    setLoadingStatus = true,
    useCachedResponse = true,
    allowParallelFetching = false,
  }: RequestArgs<Response> = {}) => {
    const mergedAxiosConfig = {
      ...proxyLevelAxiosConfig,
      ...axiosRequestConfig,
    };

    const shouldMakeAPIRequest =
      (mergedAxiosConfig?.method?.toLowerCase() ?? 'get') !== 'get' // always request if POST, PATCH, etc...
      || (result.data == null || !useCachedResponse) && (!apiCallRunning || allowParallelFetching) // if GET

    if (!apiUri) {
      // Set the api error status
      setResult(reportError('API URL not defined', onComplete, onError));
    } else {
      if (shouldMakeAPIRequest) {
        apiCallRunning = true;

        if (!isLoggedIn) {
          // Set the not logged in error status
          setResult(reportError('User must be logged in to access APIs', onComplete, onError));
        } else {
          // Set the loading status
          setResult(reportSuccess(
            {
              data: result.data,
              error: null,
              isLoading: setLoadingStatus,
            }
          ));

          return dataFetcher(apiUri, { data, ...mergedAxiosConfig })
            .then((response) => {
              if (response?.data) {
                apiCallRunning = false;

                setResult(reportSuccess(
                  {
                    data: response.data,
                    error: null,
                    isLoading: false,
                  },
                  onComplete,
                  onSuccess,
                ));
              } else {
                apiCallRunning = false;
                // Set the api error status
                setResult(reportError('API did not return data', onComplete, onError));
              }
            })
            .catch((err) => {
              apiCallRunning = false;
              // Set the api error status
              setResult(reportError(err.toJSON()?.message, onComplete, onError));
            });
        }
      }
    }
  };

  const clear = () => {
    setResult({
      data: null,
      error: null,
      isLoading: false,
    });
  };

  return {
    apiUri,
    request,
    result,
    clear,
  }
};
