import colors from '_containers/Theme/intheblack/colors';

export default {
  action: colors.all.brandPrimary1,
  attributeIcon: colors.all.neutral700,
  background: colors.all.neutralWhite,
  text: {
    category: colors.text.interactive1,
    categoryHover: colors.text.interactive1Hover,
    heading: colors.text.contrast,
    attributes: colors.text.secondary,
  },
  pagination: {
    backgroundActive: colors.background.tint4,
    color: colors.text.default,
    colorHover: colors.text.interactive1Hover,
  },
  cardBackground: colors.all.neutralWhite,
};
