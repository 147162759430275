//TODO - need to remove - but too many components referencing directly
// need to change ref to use the `_utils/styles/color` helper
export default {
  // primary
  brandPrimary1: '#0A5CC7',
  brandPrimary1Dark: '#084697',
  brandPrimary1Darker: '#043472',
  brandPrimary2: '#FFDD00',
  brandPrimary2Dark: '#E6C700',
  brandPrimary2Darker: '#E5B300',
  brandPrimary3: '#090D46',
  brandPrimary3Dark: '#050724',
  // secondary
  brandSecondary1: '#712B90',
  brandSecondary1Dark: '#57216E',
  brandSecondary2: '#F59419',
  brandSecondary2Dark: '#B26200',
  brandSecondary3: '#00BAB5',
  brandSecondary3Dark: '#00827E',

  // TODO move this when dark theme is ready
  darkBackground: '#073A7D',
  darkBackgroundTint1: '#080B3B',
  darkBackgroundTint3: '#0C125E',
  darkText: '#E6F1FF',
  darkTextSecondary: '#BBCBE5',
  darkTextSubtle: '#829BBA',

  error100: '#FFEDED',
  error500: '#E10004',
  error700: '#AD0003',
  focus: 'rgba(117,172,255,0.5)',
  information100: '#F0F5FE',
  information500: '#3C78F6',
  interactive200: '#75ACFF',
  interactive300: 'rgba(7, 100, 221, 0.6)',
  interactive500: '#0A5CC7',
  interactive600: '#084697',
  neutral50: '#F7F7F7',
  neutral75: '#F4F4F4',
  neutral100: '#EEEEEE',
  neutral200: '#DDDDDD',
  neutral300: '#C7C7C7',
  neutral400: '#8C8C8C',
  neutral500: '#767676',
  neutral600: '#444444',
  neutral700: '#303030',
  neutral800: '#222222',
  neutral900: '#111111',
  neutralBlack: '#000',
  neutralBlackTransparent10: 'rgba(0,0,0,0.1)',
  neutralBlackTransparent20: 'rgba(0,0,0,0.2)',
  neutralWhite: '#FFF',
  neutralWhiteTransparent: '#ffffff33',
  success100: '#EAFAEF',
  success500: '#24A148',
  success700: '#018940',
  transparent: '#00000000',
  visited: '#6009A0',
  warning100: '#FFF5DF',
  warning500: '#FDA108',
};
