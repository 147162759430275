import { useTranslation } from 'react-i18next';

export const getDictionaryItem = (key: string, fallbackText = '') => {
  let text = '';

  try {
    const [t] = useTranslation();

    text = t(key);
  }
  catch (e) {
    text = fallbackText;
  }
  finally {
    // if empty in Sitecore, returns the key, so check
    // that values are not the same
    return (text !== key) ? text : fallbackText;
  }
}
