import colors from '_containers/Theme/corporate/colors';
import { headingH2 } from '_utils/styles';

export default {
    background: colors.all.neutral50,
    heading: headingH2,
    headingColor: colors.all.brandPrimary3,
    my: 3,
    p: 3,
    textBody: colors.all.textBody
}