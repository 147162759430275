import { canUseDOM } from 'exenv';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '_components/Icon';
import { BackToTopButton, BackToTopContainer } from './StyledBackToTop';

const BackToTop: React.FC<any> = () => {
  const [t] = useTranslation();

  const scrollToTop = () => {
    if (!canUseDOM) {
      return false;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <BackToTopContainer>
      <BackToTopButton aria-label={t('page-footer-back-to-top-label')} onClick={scrollToTop}>
        {t('page-footer-back-to-top-label')}
        <Icon ariaHidden={true} name="arrow_upward" />
      </BackToTopButton>
    </BackToTopContainer>
  );
}

export default BackToTop;
