import React from 'react';
import { ErrorMessage, useField } from 'formik';

import { CheckboxProps, CheckboxListProps } from './definitions';
import {
  CheckboxLabel,
  InvalidMessage,
  InvalidMessageContainer,
} from './StyledCheckboxList'

import {
  FormElementWrapper
} from '../StyledFormElement'

const getChecked = (
  value: string,
  fieldValue: boolean | string[],
) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue?.indexOf(value) > -1;
  } else {
    return fieldValue;
  }
};

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  onChange,
  state,
  ...props
}) => {
  const [{ onChange: onChangeFormik, ...field }, meta] = useField(props?.name);
  const hasError = meta?.error && (meta?.touched || meta?.initialTouched) ? "error-border" : "";

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange?.(e);
    onChangeFormik?.(e);
  }

  return (
    <CheckboxLabel className={state}>
      <input
        checked={getChecked(props?.value?.toString(), field?.value)}
        className={`formik-field-checkbox ${hasError}`}
        onChange={handleOnChange}
        type="checkbox"
        {...field}
        {...props}
      />
      {children}
    </CheckboxLabel>
  )
};

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  //@ts-ignore: name not being recognised in CheckboxListProps, which contains a union type - which may have confused TS.
  name: globalName,
  otherReferenceLabel,
}) => {
  return (
    <FormElementWrapper className="checkbox-radio">
      {items?.map((item, index) => {
        const isMultiple = (items?.length ?? 0) > 1 ? 'is-multiple' : '';
        const label = otherReferenceLabel ? item?.[otherReferenceLabel] : item?.label;

        return (
          <Checkbox
            key={index}
            name={globalName ?? item?.name}
            state={isMultiple}
            value={label}
            onChange={item?.onChange}
          >
            {label}
          </Checkbox>
        )
      })}

      <InvalidMessageContainer>
        {
          globalName
            ? (
              <InvalidMessage>
                <ErrorMessage name={globalName} />
              </InvalidMessage>
            ) : (
              items?.map((item, index) => (
                <InvalidMessage key={index}>
                  <ErrorMessage name={item?.name} />
                </InvalidMessage>
              ))
            )
        }
      </InvalidMessageContainer>
    </FormElementWrapper>
  );
};

export default CheckboxList;