import React from 'react';
import { Helmet } from 'react-helmet';
import {
  WithContext,
} from 'schema-dts';

import { ITBArticlePagesDataProps } from '../definitions';

const ITBArticlePagesData: React.FC<ITBArticlePagesDataProps> = ({ context, route }) => {

  const whiteListTemplateId = [
    '5600C0A4-E02A-4C07-82EF-876F0866A1FC',
    '93AF8150-E259-4DE2-A5F7-D7860583E79F', 
    '3F066E49-76AC-4AA8-A729-64EDB071FDA7',
    '2E8D5F2A-BE65-4634-B391-5C64CF80910A',
    '4A0A5086-5EA0-4E1C-A02B-1F5684A4445D'
  ];

  const currentTemplateId = route?.templateId?.toUpperCase() || ''

  if (!context?.site?.name) {
    return null;
  }
  
  // check if the current TemplateId is in the array or not
  if (!whiteListTemplateId.includes(currentTemplateId)) {
    return null
  }

  const contributors = route?.fields?.contributors || [];
  const author = contributors[0]?.fields?.fullName?.value ?? '';
  const datePublished = route?.fields?.published?.value ?? '';
  const headline = route?.fields?.pageTitle?.value ?? '';
  const image = route?.fields?.featuredImage?.value?.src ?? '';
  const url = context?.canonicalUrl ?? '';
  const logoUrl = 'https://www.cpaaustralia.com.au/-/jssmedia/Project/CPA/InTheBlack/images/Other/INTHEBLACK.jpg';

  const websiteData: WithContext<any> = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": url
    },
    "headline": headline,
    "image": [
      image,
    ],
    "datePublished": datePublished,
    "author": {
      "@type": "Person",
      "name": author
    },
    "publisher": {
      "@type": "Organization",
      "name": "CPA Australia",
      "logo": {
        "@type": "ImageObject",
        "url": logoUrl
      }
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(websiteData)}
      </script>
    </Helmet>
  );
};

export default ITBArticlePagesData;
