import { textBody1 } from '_utils/styles';
import colors from '../colors';

export default {
  primary: {
    background: colors.transparent,
    backgroundHover: colors.brandPrimary1,
    border: colors.brandPrimary1,
    borderFocus: '3px solid',
    borderFocusColor: colors.brandPrimary1,
    borderFocusWithinColor: colors.brandPrimary1,
    borderHover: colors.brandPrimary1Dark,
    borderVisited: colors.visited,
    color: colors.neutral700,
    colorFocus: colors.neutralWhite,
    colorHover: colors.neutralWhite,
    colorVisited: colors.visited,
    textDecoration: 'none',
    textDecorationFocus: 'none',
  },
  primaryWithoutVisited: {
    background: colors.transparent,
    backgroundHover: 'inherit',
    border: colors.brandPrimary1,
    borderHover: colors.brandPrimary1Dark,
    color: 'inherit',
    colorHover: 'inherit',
    colorVisited: 'inherit',
    borderVisited: 'inherit',
    textDecoration: 'none',
    textDecorationFocus: 'none',
  },
  secondary: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderFocus: 'none',
    borderFocusColor: colors.brandPrimary1,
    borderFocusWithinColor: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    color: colors.brandPrimary1,
    colorFocus: colors.brandPrimary1,
    colorHover: colors.brandPrimary1Dark,
    colorVisited: colors.visited,
    textDecoration: 'underline',
    textDecorationFocus: 'underline',
  },
  featured: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderFocusColor: colors.transparent,
    borderFocusWithinColor: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    boxShadowColor: colors.brandPrimary1Dark,
    color: colors.brandPrimary1,
    colorFocus: colors.brandPrimary1,
    colorHover: colors.brandPrimary1Dark,
    colorVisited: colors.visited,
    fontWeight: 'normal',
    textBody: textBody1,
    textDecoration: 'none',
    textDecorationFocus: 'inherit',
    textTransform: 'normal',
  },
  white: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    color: 'inherit',
    colorHover: colors.neutralWhite,
    colorVisited: colors.neutralWhite,
    textDecoration: 'underline',
    textDecorationFocus: 'inherit',
  },
  breadcrumb: {
    background: colors.transparent,
    backgroundHover: colors.transparent,
    border: colors.transparent,
    borderHover: colors.transparent,
    borderVisited: colors.transparent,
    color: colors.interactive500,
    colorHover: colors.brandPrimary1Dark,
    colorVisited: colors.brandPrimary1,
    textDecoration: 'none',
    textDecorationFocus: 'inherit',
  }
}
