import colors from '_containers/Theme/corporate/colors';


import { ImageWithTitleAndTextValidVariants } from './definitions';

export const validVariants: ImageWithTitleAndTextValidVariants = {
  '1Column': {
    columns: 12,
    headingLevel: 'h4',
    alignment: 'flex-start',
  },
  '2Column': {
    columns: { xs: 12, sm: 6, lg: 6 },
    headingLevel: 'h5',
    alignment: 'flex-start',
  },
  '3Column': {
    columns: { xs: 12, sm: 4, lg: 4 },
    headingLevel: 'h5',
    alignment: 'flex-start',
  },
  '4Column': {
    columns: { xs: 12, sm: 6, lg: 3 },
    headingLevel: 'h5',
    alignment: 'flex-start',
  },
};

export default {
  headingColor: colors.all.brandPrimary3,
  item: {
    color: colors.text.default
  }
};
