import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import CampaignNavigation from '_components/CampaignNavigation';
import MainMenu from '_components/MainMenu';
import MainMenuInTheBlack from '_components/MainMenuInTheBlack';
import MainSearch from '_components/MainSearch';
import TabletMainSearch from './TabletMainSearch';
import HeaderButton from './HeaderButton';
import SearchMenuButton from '_components/SearchMenuButton';
import { withCoveoNoIndexTags, withSitecoreContext } from '_containers/BaseComponent';
import { withMenuContext } from '_containers/MenuContext';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { Portals, getPortal } from '_utils/helpers';
import { warnInDev } from '_utils/helpers/dev';
import { isCorporateSite } from '_utils/helpers/render';
import { stickyfill } from '_utils/styles';

import { HeaderProps, SkipLinks } from './definitions';

import {
  DimContent,
  LogoLink,
  LogoWrapper,
  MenuActiveBlockInteraction,
  MenuContainer,
  PageHeaderContent,
  PageHeaderWrapper,
  PageHeaderWrapperSec,
  SearchContainer,
  ITBSearchContainer,
  SkipContainer,
  SkipLink,
  StyledPageHeader,
  LinkButton,
  ITBSearchWrapper
} from './StyledPageHeader';
import AsyncLogo from '_utils/icons/Logo';
import AsyncLogoInTheBlack from '_utils/icons/LogoITB';

const SEARCH_RESULTS_TEMPLATE_NAME = 'Search results page';
const SEARCH_RESULTS_INTHEBLACK_TEMPLATE_NAME = 'ITB Search Page';

const links: SkipLinks = [
  {
    id: `001-main-nav`,
    label: 'main navigation',
    dictionaryKey: 'page-header-skip-to-main-navigation-label',
    target: 'menu-button'
  },
  {
    id: `002-secondary-nav`,
    label: 'secondary navigation',
    dictionaryKey: 'page-header-skip-to-secondary-navigation-label',
    target: 'secondary-navigation'
  },
  {
    id: `003-main-content`,
    label: 'main content',
    dictionaryKey: 'page-header-skip-to-content-label',
    target: 'main-content'
  },
  {
    id: `004-footer`,
    label: 'footer',
    dictionaryKey: 'page-header-skip-to-footer-label',
    target: 'page-footer'
  }
];

const PageHeader: FC<HeaderProps> = ({
  fields,
  menuActive,
  rendering,
  setMenuActive,
  sitecoreContext,
}) => {
  // data to pass to CampaignNavigation component
  const currentPageId = sitecoreContext?.route?.itemId;
  const campaignNavigationData = { ...fields?.data?.campaignNavigation, currentPageId };
  const shouldDisplayCampaignNavigation = campaignNavigationData?.displayCampaignNavigation ?? false;

  // dictionary items
  const inTheBlackCpaLinkTitleLabel = getDictionaryItem('page-header-cpa-australia-link-title', 'CPA Australia');
  const inTheBlackHomeLinkTitle = getDictionaryItem('page-header-home-link-title', 'Intheblack Homepage');
  const corporateHomeLinkTitle = getDictionaryItem('page-header-home-link-title', 'CPA Australia Homepage');
  const menuSubscribeLabel = getDictionaryItem('menu-button-subscribe-to-newsletter', 'Subscribe to newsletter');

  // toggle visiblity of breadcrumb if campaign navigation rendered
  if (canUseDOM) {
    document.body.classList
    [
      shouldDisplayCampaignNavigation
        ? 'add'
        : 'remove'
    ]
      ('hasCampaignNavigation');
  }

  const [t] = useTranslation();
  const portalRef = useRef<HTMLElement>(null);

  const [skipActive, setSkipActive] = useState(false);
  const [isPageFooterInDom, setIsPageFooterInDom] = useState(false);
  const [beforeRootSpace, setBeforeRootSpace] = useState(0);

  const [isSticky, setSticky] = useState(false);
  const stickyRef = useRef<HTMLElement>(null);

  const subscribeToNewsletterLink = fields?.data?.datasource?.subscribeToNewsletterLink?.jss?.value?.href || '/';

  useEffect(() => {
    if (portalRef.current === null) {
      portalRef.current = getPortal(Portals.before);
    }

    const portalRefBottomPos = portalRef.current.getBoundingClientRect().bottom;

    setBeforeRootSpace(
      (portalRefBottomPos && portalRefBottomPos < 0)
        ? 0
        : portalRefBottomPos
    );
  }, [menuActive]);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    let timeout;
    let initialPos = 0;

    const handleScroll = () => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      timeout = window.requestAnimationFrame(() => {
        const pageHeaderContent = stickyRef?.current?.getBoundingClientRect();
        const isScrollDirectionUp = canUseDOM && document.body.getBoundingClientRect().top > initialPos;
        initialPos = canUseDOM && document.body.getBoundingClientRect().top;

        if (pageHeaderContent && !shouldDisplayCampaignNavigation) {
          setSticky(window.scrollY > 0 && pageHeaderContent.top <= 0);
        }

        if (pageHeaderContent && shouldDisplayCampaignNavigation) {
          setSticky(window.scrollY > 0 && isScrollDirectionUp);
        }
      });
    }

    window.addEventListener('scroll', handleScroll, false);
    handleScroll();

    if (!shouldDisplayCampaignNavigation) {
      stickyfill.add(stickyRef?.current ?? []);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
      stickyfill.remove(stickyRef?.current ?? []);
    };
  }, []);

  const isSearchRoute: boolean = useMemo(
    () => sitecoreContext?.route?.templateName === SEARCH_RESULTS_TEMPLATE_NAME,
    [sitecoreContext?.route?.templateName],
  );

  const isInTheBlackSearchRoute: boolean = useMemo(
    () => sitecoreContext?.route?.templateName === SEARCH_RESULTS_INTHEBLACK_TEMPLATE_NAME,
    [sitecoreContext?.route?.templateName],
  );


  const isCorporate = isCorporateSite(sitecoreContext);
  const isInTheBlack = !isCorporateSite(sitecoreContext);
  const siteSearchSettings = sitecoreContext?.siteSearchSettings;
  const searchBackdoorKey = siteSearchSettings?.searchBackdoorKey ?? '';
  const searchDisabled = (siteSearchSettings?.searchDisabled ?? false)
    && (!canUseDOM || !searchBackdoorKey || window.location.search.indexOf(searchBackdoorKey) === -1);

  const isLoggedIn: boolean = useMemo(
    () => !sitecoreContext?.securityInfo?.isAnonymous,
    [sitecoreContext?.securityInfo?.isAnonymous],
  );

  // A loginUrl must exist for the login menu button to appear
  const loginUrl = sitecoreContext?.securityInfo?.loginUrl || '';

  useEffect(() => {
    // PageHeader doesn't know when PageFooter is mounted, so poll to check so not missed in skip link
    if (window?.MutationObserver) {
      let observer = new MutationObserver(() => {
        if (document.getElementById('page-footer')) {
          observer.disconnect();
          setIsPageFooterInDom(true);
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false,
      });

      return () => {
        observer.disconnect();
      }
    }
  }, []);

  const renderskipLinks = useMemo(
    () =>
      links.map(link => {
        // target element may not always exists, so suppress render
        try {
          const el = document.getElementById(link.target);
          // fallback if dictionary item is empty
          const label = t(link.dictionaryKey) === link.dictionaryKey ? `Skip to ${link.label}` : t(link.dictionaryKey);

          if (el) {
            return <SkipLink
              key={link.id}
              href={`#${link.target}`}
              onClick={() => {
                el.focus();
              }}
              onFocus={() => {
                if (window) {
                  window.scrollTo(0, 0);
                }
                setSkipActive(true);
              }}
              onBlur={() => setSkipActive(null)}
            >
              {label}
            </SkipLink>
          } else {
            warnInDev(`SkipTo: ${link.label} suppressed - could not find id ${link.target}`);
          }
        }
        catch (err) {
          warnInDev(err);
        }
      }),
    [isPageFooterInDom]
  );

  return (
    <>
      <SkipContainer className={skipActive ? 'skipActive' : ''}>
        {renderskipLinks}
      </SkipContainer>
      <PageHeaderWrapper
        editMode={sitecoreContext && sitecoreContext.pageEditing}
        ref={stickyRef}
        sticky={isSticky}
      >
        <StyledPageHeader
          className="page-header"
          editMode={sitecoreContext && sitecoreContext.pageEditing}
          id="page-header"
          onKeyDown={(e) => {
            // ESCAPE
            if (e.keyCode === 27 && menuActive) {
              setMenuActive(false);
            }
          }}
          sticky={!shouldDisplayCampaignNavigation && isSticky && sitecoreContext && !sitecoreContext.pageEditing}
        >
          <PageHeaderContent pageDisplayCampaignNavigation={shouldDisplayCampaignNavigation} sticky={isSticky && sitecoreContext && !sitecoreContext.pageEditing}>
            <LogoLink to="/" aria-label={isCorporate ? corporateHomeLinkTitle : inTheBlackHomeLinkTitle}>
              <LogoWrapper pageDisplayCampaignNavigation={shouldDisplayCampaignNavigation} sticky={isSticky}>
                {isCorporate ?
                  <AsyncLogo />
                  :
                  <AsyncLogoInTheBlack />
                }
              </LogoWrapper>
            </LogoLink>

            {!searchDisabled && !isSearchRoute && !isInTheBlackSearchRoute && (
              <SearchMenuButton />
            )}

            <HeaderButton
              id="menu-button"
              targetMenu={0}
              iconName="menu"
              title={t('menu-label')}
            />

            {isCorporate && !!(loginUrl) && (
              <HeaderButton
                id="dashboard-menu-button"
                targetMenu={1}
                link={!isLoggedIn && loginUrl}
                iconName="perm_identity"
                title={!isLoggedIn ? t('login-label') : t('menu-my-cpa-label')}
              />
            )}

            {isInTheBlack && (
              <HeaderButton
                id="dashboard-menu-button"
                targetMenu={1}
                link="https://www.cpaaustralia.com.au"
                iconName="login"
                title={inTheBlackCpaLinkTitleLabel}
                target="_blank"
              />
            )}

            {menuActive && (
              <MenuActiveBlockInteraction
                className={menuActive ? 'menuActive' : ''}
                onClick={() => setMenuActive(false)}
              />
            )}


            {isCorporate && !searchDisabled && !isSearchRoute && !isInTheBlackSearchRoute && (
              <SearchContainer pageDisplayCampaignNavigation={shouldDisplayCampaignNavigation} sticky={isSticky}>
                <MainSearch />
              </SearchContainer>
            )}

            {isInTheBlack && !searchDisabled && !isSearchRoute && !isInTheBlackSearchRoute && (
              <ITBSearchContainer pageDisplayCampaignNavigation={shouldDisplayCampaignNavigation} sticky={isSticky}>
                <ITBSearchWrapper>
                  <MainSearch />
                </ITBSearchWrapper>

                <LinkButton to={subscribeToNewsletterLink}>{menuSubscribeLabel}</LinkButton>
              </ITBSearchContainer>
            )}

            <MenuContainer
              beforeRootSpace={beforeRootSpace}
              className={skipActive ? 'skipActive' : ''}
              pageDisplayCampaignNavigation={shouldDisplayCampaignNavigation}
              isSticky={isSticky}
            >
              {
                menuActive && (
                  <DimContent
                    className={menuActive ? 'menuActive' : ''}
                    onClick={() => setMenuActive(false)}
                  />
                )
              }

              {
                canUseDOM && (
                  isCorporate ?
                    (
                      <MainMenu
                        fields={fields}
                        placeholders={rendering.placeholders}
                        pageDisplayCampaignNavigation={shouldDisplayCampaignNavigation}
                        isSticky={isSticky}
                        isLoggedIn={isLoggedIn}
                        loginUrl={loginUrl}
                      />
                    ) :
                    (
                      <MainMenuInTheBlack
                        fields={fields}
                        isSticky={isSticky}
                      />
                    )
                )
              }
            </MenuContainer>
          </PageHeaderContent>
        </StyledPageHeader>
      </PageHeaderWrapper>
      {isInTheBlack && !searchDisabled && !isSearchRoute && !isInTheBlackSearchRoute && (
        <PageHeaderWrapperSec sticky={isSticky}>
          <TabletMainSearch />
        </PageHeaderWrapperSec>
      )}

      {shouldDisplayCampaignNavigation && <CampaignNavigation data={campaignNavigationData} />}
    </>
  );
};

export default compose(withCoveoNoIndexTags, withSitecoreContext(), withMenuContext)(PageHeader);
