import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ImageSize } from '_utils/types/enums';
import {
  createSrcSet,
} from '_utils/media';

import {
  ClickableWrapper,
  ComponentLabel,
  Container,
  ContentWrapper,
  CoverImage,
  CoverImageWrapper,
  LinkButton,
  PageContainer,
  Title,
} from "./StyledColumnMagazineBanner";

import { MagazineBannerProps } from "./definitions";

const ColumnMagazineBanner: React.FC<MagazineBannerProps> = ({
  componentLabel,
  coverImage,
  ctaLabel,
  editMode,
  hideOnMobile,
  link,
  title,
  uid
}) => {
  const linkItem = { ...link?.jss?.value, target: '_blank' }

  return (
    <ClickableWrapper
      className="column-magazine-banner"
      data-component
      id={uid}
      as={editMode ? 'div' : 'a'}
      href={link?.jss?.value?.href}
      target={link?.jss?.value?.target ?? '_blank'}
      title={link?.jss?.value?.title}
    >
      <Container className={hideOnMobile ? "hide-on-mobile" : ""} >
        <PageContainer>
          <CoverImageWrapper>
            <CoverImage
              className="lazyload"
              field={
                editMode
                  ? coverImage
                  : {
                    value: {
                      src: coverImage?.value?.src,
                      alt: coverImage?.value?.alt ?? '',
                      'data-srcset': createSrcSet(coverImage?.value?.src, [
                        ImageSize.MW_128,
                        ImageSize.MW_256,
                      ])
                    }
                  }
              }
              width={ImageSize.MW_128}
              height={ImageSize.MW_128}
            />
          </CoverImageWrapper>
          <ContentWrapper>
            <Title>
              <Text
                field={{ value: title }}
                editable={false}
              />
            </Title>
            <ComponentLabel>{componentLabel}</ComponentLabel>
            <LinkButton
              fallbackTitle={ctaLabel}
              field={{ value: linkItem }}
              hideExternalIcon="true"
            />
          </ContentWrapper>
        </PageContainer>
      </Container >
    </ClickableWrapper>
  )
}

export default ColumnMagazineBanner;