import React from 'react';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { FieldSummaryProps } from './definitions';
import {
  FieldSummaryFootnote,
  FieldSummaryLabel,
  FieldSummaryNoValue,
  FieldSummaryValue
} from './StyledFieldSummary';

const FieldSummary: React.FC<FieldSummaryProps> = ({
  footnote,
  isEditing = false,
  labelDictionaryKey,
  labelFallback,
  value,
}) => {
  const hasValue = !!value;
  const hasFootnote = !!footnote;
  const label = labelDictionaryKey ? getDictionaryItem(labelDictionaryKey, labelFallback) : labelFallback;
  const notCapturedLabel = getDictionaryItem('form-not-captured-label', 'Not captured');

  return (
    <>
      <FieldSummaryLabel isEditing={isEditing}>{label}</FieldSummaryLabel>
      {hasValue &&
        <FieldSummaryValue>{value}</FieldSummaryValue>
      }
      {!hasValue &&
        <FieldSummaryNoValue>{notCapturedLabel}</FieldSummaryNoValue>
      }
      {hasFootnote &&
        <FieldSummaryFootnote>{footnote}</FieldSummaryFootnote>
      }
    </>
  )
}

export default FieldSummary;
