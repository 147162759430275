import styled, {
  css,
  createGlobalStyle,
} from 'styled-components';

import {
  button,
  depth,
  hideOnPrint,
  mq,
  textBody1,
} from '_utils/styles';

// Overrides for thrid party lib component
export const GlobalLiveChatStyleOverrides = createGlobalStyle`
  .ie-div-position-customer-chat,
  #LiveChatOfflineButton {
    opacity: 1;
    transition: all 0.2s ease-in-out 0s;
    visibility: visible;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  /* Fix for Safari 15 to pad LiveChat up above bottom nav bar */
  #chat-div-wrap,
  #icChatButton {
    padding-bottom: env(safe-area-inset-bottom);
  }

  ${mq.lessThan('md')} {
    #chat-div-wrap,
    .button-iframe-wrap {
      body.menu-active & {
        z-index: ${depth.LIVE_CHAT_MOBILE_MENU_ACTIVE} !important;
      }
    }
  }
`;

// Offline Live Chat Bubble
export const LiveChatOfflineContainer = styled.div`
  bottom: 24px;
  opacity: 1;
  position: fixed;
  right: 24px;
  transition: all 0.2s ease-in-out 0s;
  visibility: visible;
  z-index: ${depth.LIVE_CHAT};

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  ${hideOnPrint}
`;

export const LiveChatOfflineButton = styled.button`
  ${button('default', 'secondaryDark')}

  align-items: center;
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
  display: flex;
  transition: padding .3s;

  ${({ $isOpen }) => $isOpen
    ? css`
        height: 52px;
        min-width: 52px;
        padding: 12px 14px;
      `
    : css`
      height: 52px;
    `
  }

  &:hover {
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
  }
`;

export const LiveChatOfflineIcon = styled.div`
  path {
    fill: ${({ theme }) => theme.liveChat.iconFill};
  }
`;

export const LiveChatOfflineText = styled.div`
  color: ${({ theme }) => theme.liveChat.buttonTextColor};
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
  white-space: nowrap;
`;

export const MessageBody = styled.div`
  ${textBody1}
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 20px;
`;

export const MessageCloseButton = styled.button`
  ${button('iconOnly', 'text')}
  color: ${({ theme }) => theme.liveChat.iconFill};
  padding: 7px 10px;
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const MessageContainer = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 80px;
  box-shadow: 0 -5px 50px rgb(0 0 0 / 20%);
  position: absolute;
  right: 0px;
  width: 340px;
`;

export const MessageHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.liveChat.background};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
`;

export const MessageIcon = styled.div`
  path {
    fill: ${({ theme }) => theme.liveChat.iconFill};
  }
`;

export const MessageTitle = styled.div`
  color: ${({ theme }) => theme.liveChat.buttonTextColor};
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
`;

export const MessageTitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

