import { withSitecoreContext, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from 'exenv';
import { compose, omit } from 'ramda';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import { withDigitalDataContext } from '_containers/DigitalDataContext';

import { dateConverter } from '_utils/helpers';
import {
  emitTrackEvent,
  setObjectData,
} from '_utils/helpers/analytics';
import { DateData } from '_utils/helpers/dateConverter'
import { warnInDev } from '_utils/helpers/dev';
import {
  MUTATING_CLASS,
  STABLE_CLASS,
} from '_utils/helpers/render';
import { 
  useScrollDepthAnalyticsTracking,
 } from '_utils/hooks';

import { MetadataProps } from './definitions';
import { DISMISSED_ANNOUNCEMENTS_COOKIE_NAME } from '_components/Announcements/definitions';
import {
  getContributors,
  getEnterpriseTopic,
  getMultiMediaTypes,
  getPrimaryTopic,
  getPublishedDate,
  getTopicTags,
  setSection
} from './helpers';
import cpaLogo from '../../assets/cpa.png';

const getCustomMetaTags = (customMetaTags: string): JSX.Element[] => {
  let metaTags: JSX.Element[] = [];

  if (customMetaTags) {
    let tags = customMetaTags.split('&');
    tags.forEach((tag) => {
      if (tag.indexOf('=') !== -1) {
        const tagParts = tag.split('=');
        const extraTag = <meta name={tagParts[0]} content={tagParts[1] || ''} />;
        metaTags.push(extraTag);
      }
    });
  }

  return metaTags;
}

const Head: React.FC<MetadataProps> = (props) => {
  
  /**
   * On Page Load, focus H1. Does not subscribe to a trigger.
   */
   useEffect(() => {
    const firstHeadingOne: null | HTMLHeadElement = document.querySelector("h1");

    if(firstHeadingOne !== null){
      firstHeadingOne.tabIndex = -1;
      firstHeadingOne.focus();
    }
  }, []);

  const {
    digitalData,
    rendering,
    setDigitalData,
    sitecoreContext,
  } = props;

  const route = sitecoreContext?.route || null;
  const fields = (route && route.fields) || {};
  const templateName = route?.templateName || '';
  const seoTitle: string = getFieldValue(fields, 'seoTitle') || '';
  const seoDescription: string = getFieldValue(fields, 'seoDescription') || '';
  const pageTitle: string = getFieldValue(fields, 'pageTitle') || '';
  const pageDescription: string = getFieldValue(fields, 'pageDescription') || '';
  const pageListingDescription: string = getFieldValue(fields, 'pageListingDescription') || '';
  const language: string = getFieldValue(fields, 'languageType') || 'en';
  const featuredImage: any = getFieldValue(fields, 'featuredImage') || null;
  const socialSharingImage: any = getFieldValue(fields, 'socialSharingImage') || null;
  const noFollow: boolean = getFieldValue(fields, 'noFollow') || false;
  const seoCanonicalUrl: boolean = getFieldValue(fields, 'seoCanonicalUrl') || null;
  const customMetaTags: string = getFieldValue(fields, 'customTags') || null;
  const readTime: string = getFieldValue(fields, 'readingTime') || null;
  const publishedDate: string = getFieldValue(fields, 'published') || '';
  const multimediaType = getMultiMediaTypes(fields?.multimediaType);

  /**
   * Article types:
   * Standard: derive comma delimited string of contributors.
   * Advertorial or Sponsored: derive sponsor's name
   */
  const contributor = getContributors(fields, templateName) ?? '';
  const sponsor = fields?.sponsor?.fields?.name?.value ?? '';
  const contentType = fields?.pageType?.displayName ?? '';
  const topicTags = getTopicTags(route);
  const primaryTopic = getPrimaryTopic(route, rendering);
  const enterpriseTopic = getEnterpriseTopic(route, rendering);
  
  const formattedPublishedDate: DateData = dateConverter(publishedDate, { isUTC: true, medium: true });

  const extraMetaTags = getCustomMetaTags(customMetaTags);
  const canonicalUrl: string = seoCanonicalUrl || sitecoreContext?.canonicalUrl || null;
  const dataSource = rendering?.fields?.data?.datasource || null;
  const adobeLaunchScriptURL: string = dataSource?.adobeLaunchConfigurationItem?.jss?.fields?.URL?.value ?? '';
  const googleAdSrcScriptURL: string = dataSource?.googleAdSrcConfigurationItem?.jss?.fields?.URL?.value ?? '';
  const titleSiteName: string = dataSource?.titleSiteName?.value || null;
  const ogSiteName: string = dataSource?.ogSiteName?.value || null;
  const twitterCreator: string = dataSource?.twitterCreator?.value || null;
  const twitterSite: string = dataSource?.twitterSite?.value || null;

  // Set the title to the seoTitle, falling back to the pageTitle if not set or if set to the site title
  let title = seoTitle;
  if (!title || title === titleSiteName) title = pageTitle;

  // Append the site title
  if (title && title !== titleSiteName) {
    title = title + " | " + titleSiteName;
  } else {
    title = titleSiteName;
  }

  const description = seoDescription || pageDescription;

  let image = socialSharingImage?.src || featuredImage?.src || cpaLogo;

  const { pathname } = useLocation();

  // reset fields that shouldn't persist through SPA
  useEffect(() => {
    if (typeof setDigitalData === 'function') {
      setDigitalData(
        omit([
          'accordion',
          'carousel',
          'download',
          'form',
          'search',
          'section',
        ], digitalData));
    }

    // reset mutation observer classes
    if (canUseDOM) {
      document.body.classList.remove(MUTATING_CLASS);
      document.body.classList.remove(STABLE_CLASS);
    }
  }, [pathname]);

  // update Adobe Analytics `digitalData` obj
  useEffect(() => {    
    if (typeof setDigitalData === 'function') {

      const updatedPageData = setObjectData(
        ['page'],
        {
          pageDescription: description,
          pageListingDescription: pageListingDescription,
          pageName: title,
          pageurl: pathname,
          siteSection: canUseDOM && pathname && `itb:${setSection(pathname)}`,
          language: language,
          pageType: route?.fields?.analyticsPageType?.value || templateName,
          publishedDate: getPublishedDate(templateName, formattedPublishedDate),
          primaryTopic,
          enterpriseTopic,
          readTime,
          topicTags,
          multimediaType,
          contentType,
          contributor,
          sponsor,
        },
        digitalData
      );

      const updatedDigitalData = {
        ...digitalData,
        ...updatedPageData,
      };

      setDigitalData(
        updatedDigitalData
      );

      // track page views (for SPA)
      if (window?.ssrRenderComplete) {
        emitTrackEvent('pageView');
      }
    }

    // Ignore changes to pathname it happens before title
    // has changed, instead wait for title to change before
    // tracking page view. Assumes no two page titles are the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  // expose `digitalData` in namespaced window obj
  // for ease of debugging by analytics team
  useEffect(() => {
    if (canUseDOM) {
      window.cpa = window.cpa || {};
      window.cpa.digitalData = digitalData;
    }
  }, [digitalData]);

  const renderDigitalData = (digitalData) => {
    try {
      if (typeof digitalData !== 'undefined') {
        return JSON.stringify(digitalData, null, 2);
      }
      return {};
    }
    catch (err) {
      warnInDev(err);
    }
  };

  // Dispatch 50% scroll depth analytics 
  const dispatchHalfPageScrollEmitTrack = () => emitTrackEvent('scroll50');
  useScrollDepthAnalyticsTracking(dispatchHalfPageScrollEmitTrack, 50);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {noFollow && <meta name="robots" content="noindex, follow" />}
        <meta property="og:title" content={title} />
        {description && <meta property="og:description" content={description} />}
        {image && <meta property="og:image" content={image} />}
        {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
        {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
        <meta property="og:type" content="website" />
        {image && <meta name="twitter:card" content="summary_large_image" />}
        {!image && <meta name="twitter:card" content="summary" />}
        <meta name="twitter:title" content={title} />
        {description && <meta name="twitter:description" content={description} />}
        {image && <meta name="twitter:image" content={image} />}
        {twitterSite && <meta name="twitter:site" content={twitterSite} />}
        {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {extraMetaTags}

        {
          adobeLaunchScriptURL && (
            <script src={adobeLaunchScriptURL} async></script>
          )
        }

        {
          googleAdSrcScriptURL && (
            <script src={googleAdSrcScriptURL} async></script>
          )
        }

        {/* Site-wide Announcements Dismissal Handler - split out as it relies on a cookie in the 'C0003 / Functional' category. */}
        <script type="text/plain" className="optanon-category-C0003">
          {
            `
              function setAnnouncementDismissed (id) {
                if (typeof document !== 'undefined') {
                  var DISMISSED_ANNOUNCEMENTS_COOKIE_NAME = '${DISMISSED_ANNOUNCEMENTS_COOKIE_NAME}';
                  var DISMISSED_ANNOUNCEMENTS_EXPIRY_IN_DAYS = 30;

                  function _getDismissedAnnouncementsCookieValue() {
                    var dismissedAnnouncementsCookie = document.cookie
                      .split('; ')
                      .find(row => row.startsWith(DISMISSED_ANNOUNCEMENTS_COOKIE_NAME + '='));

                    return dismissedAnnouncementsCookie
                      ? dismissedAnnouncementsCookie.split('=')[1]
                      : false;
                  };

                  var dismissedAnnouncementsCookieValue = _getDismissedAnnouncementsCookieValue();

                  var dismissedAnnouncementIds = dismissedAnnouncementsCookieValue
                    ? decodeURIComponent(dismissedAnnouncementsCookieValue).split(',')
                    : [];

                  var isIDAlreadyDismissed = dismissedAnnouncementIds && (dismissedAnnouncementIds.indexOf(id) > -1);

                  if (id && !isIDAlreadyDismissed) {
                    var expiry = new Date(Date.now() + DISMISSED_ANNOUNCEMENTS_EXPIRY_IN_DAYS * 864e5).toUTCString();

                    document.cookie =
                      DISMISSED_ANNOUNCEMENTS_COOKIE_NAME
                      + '='
                      + encodeURIComponent(dismissedAnnouncementIds.concat(id).toString())
                      + '; expires='
                      + expiry;
                  };
                }
              };

              window.setAnnouncementDismissed = setAnnouncementDismissed;
            `
          }
        </script>

        {adobeLaunchScriptURL && (
          <script>
            {`var digitalData = ${renderDigitalData(digitalData)};`}
          </script>
        )}
      </Helmet>
    </>
  );
};

export default compose(
  withDigitalDataContext,
  withSitecoreContext(),
)(Head);
