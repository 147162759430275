// see https://tobbelindstrom.com/blog/useMutationObserver/
import { RefObject, useEffect, useMemo } from 'react';
import { canUseDOM } from 'exenv';
import { getRefElement } from '_utils/helpers';

interface Props {
  target?: RefObject<Element> | Element | Node | null;
  options?: MutationObserverInit;
  callback?: MutationCallback;
}

const useMutationObserver = ({
  target,
  options = {},
  callback
}: Props): void => {
  const observer = useMemo(
    () =>
      canUseDOM
        ? new MutationObserver((mutationRecord, mutationObserver) => {
          callback?.(mutationRecord, mutationObserver);
        })
        : null,
    [callback]
  );

  useEffect(() => {
    const element = getRefElement(target);

    if (observer && element) {
      observer.observe(element, options);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};

export default useMutationObserver;