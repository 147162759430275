import styled from 'styled-components';

import { spacing } from '_utils/props';
import {
  hideOnPrint,
  tag10,
  tag12,
  tag14,
} from '_utils/styles';

const sizeMap = {
  'large': tag14,
  'small': tag12,
  'extra-small': tag10,
};

export const TagContainer = styled.span`
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  font-weight: bold;
  ${({ theme }) => spacing({ theme, my: '4px', mr: '8px' })}
  text-transform: uppercase;

  ${({ theme, size }) => {
    if (size === 'large') {
      return spacing({ theme, px: '8px', py: '4px' });
    }
    if (['extra-small', 'small'].includes(size)) {
      return spacing({ theme, px: '6px', py: '3px' });
    }
  }}

  ${({ size }) => sizeMap?.[size]};

  a > & {
    ${({ theme }) => spacing({ theme, m: '4px' })}
  }

  background-color: ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.background ?? null};
  border-color: ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.border ?? 'transparent'};
  border-radius:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.borderRadius ?? null};
  color: ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.color ?? 'inherit'};
  // padding override required by Corporate AgendaAccordion
  padding:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.padding ?? null};
  text-decoration:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.textDecoration ?? null};

  &:hover {
    background-color:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.backgroundHover ?? null};
    color:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.colorHover ?? null};
  }

  &:visited {
    background-color:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.backgroundVisited ?? null};
    color:  ${({ theme, tagVariant }) => theme?.tag[tagVariant]?.colorVisited ?? null};
  }

  ${hideOnPrint}
`;
