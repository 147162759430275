import colors from '_containers/Theme/intheblack/colors';
import { headingH3 } from '_utils/styles/';

export default {
    background: colors.all.interactive100,
    border: `4px solid ${colors.all.brandPrimary2}`,
    gridContainer: {
      padding: 0
    },
    heading: headingH3,
    headingColor: colors.all.brandPrimary2Dark,
    my: null,
    p: null,
    textBody: colors.all.textBody
}