import { compose } from 'ramda';
import React from 'react';

import Icon from '_components/Icon';
import { withAuthenticatedCheck } from '_containers/BaseComponent';
import { createSrcSet } from '_utils/media';
import { getImageAttrsByRatio } from '_utils/helpers/image';
import { getLink } from '_utils/helpers/link';
import { AuthenticatedContentIcon } from '_utils/components/AuthenticatedContentIcon';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize, ImageRatio } from '_utils/types/enums';

import {
    ListItemProps,
    stylesThatRenderDescription,
    stylesThatRenderImage,
    stylesThatRenderTags,
    stylesThatRenderAttributes,
    stylesThatRenderAuthor,
    stylesThatRenderDate,
    stylesThatRenderReadingTime
} from './definitions';

import {
    ArrowWrapper,
    Card,
    Description,
    DetailsColumn,
    FooterWrapper,
    AttributeFooterWrapper,
    ItemList,
    Image as StyledImage,
    ImageColumn,
    ImageContainer,
    PlaceholderCard,
    Tag,
    TagContainer,
    Title,
} from './StyledItem';
import {AttributesItem, AttributesWrapper} from "_components/CardList/StyledItem";

import {getDictionaryItem} from "_utils/data/dictionaryItem";
import {dateConverter} from "_utils/helpers";

const getPublishedDate = (published) => {
    if (published) {
        return dateConverter(published, { isUTC: true, outputFormat: 'DD MMM YYYY' });
    }
    return;
}

const Item: React.FC<ListItemProps> = ({
                                           description,
                                           editMode,
                                           headingLevel,
                                           isAuthenticatedContent,
                                           link,
                                           published,
                                           author,
                                           readingTime,
                                           listingImage,
                                           tags,
                                           title,
                                           type
                                       }) => {
    const renderAttributes = stylesThatRenderAttributes[type] || false;
    const renderImage = stylesThatRenderImage[type] || false;
    const renderAuthor = stylesThatRenderAuthor[type] || false;
    const renderDate = stylesThatRenderDate[type] || false;
    const renderDescription = stylesThatRenderDescription[type] || false;
    const renderReadingTime = stylesThatRenderReadingTime[type] || false;

    const renderTags = stylesThatRenderTags[type] || false;
    const cardProps = getLink(link);
    const headingType = `h${headingLevel + 1}`;
    const Component = editMode ? PlaceholderCard : Card;
    const isExternalLink = cardProps?.target === '_blank';
    const hasImageSrc = !!listingImage?.value?.src;
    const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_16By9);
    const readingTimeLabel = getDictionaryItem('global-read-time', '{minutes} min read time');

    const formattedReadingTime = readingTimeLabel.replace(/\{minutes}/ig, readingTime ?? '');

    const publishedDate = getPublishedDate(published);

    let classes = "inpractice"

    if (renderImage && hasImageSrc) {
        classes = 'hasImage';
    }

    return (
        (editMode || cardProps) && (
            <ItemList>
                <Component {...cardProps}
                           className= {classes}
                           as={isExternalLink ? 'a' : null}>
                    {
                        renderImage &&
                        hasImageSrc &&
                        <ImageColumn>
                            <ImageContainer>
                                <StyledImage
                                    className="lazyload"
                                    draggable={false}
                                    field={
                                        editMode
                                            ? listingImage?.value
                                            : {
                                                value: {
                                                    src: imagePlaceholderSrc,
                                                    alt: '', // alt text is empty string, as this is a decorative image
                                                    'data-srcset': createSrcSet(listingImage?.value?.src, [ImageSize.MW_384, ImageSize.MW_512])
                                                }
                                            }
                                    }
                                    {...defaultImageSize}
                                />
                            </ImageContainer>
                        </ImageColumn>
                    }
                    <DetailsColumn>
                        <div>
                            <Title as={headingType}>
                                <AuthenticatedContentIcon
                                    iconStyleConfig={{
                                        xs: {
                                            iconSize: 20,
                                            mr: 8,
                                            top: 4
                                        }
                                    }}
                                    isAuthenticated={isAuthenticatedContent}
                                />
                                <h3>{title}</h3>
                            </Title>
                        </div>
                        {
                            renderTags &&
                            tags?.length > 0 &&
                            <TagContainer>
                                {tags.map(tag =>
                                    <Tag key={tag.id}>{tag.displayName}</Tag>
                                )}
                            </TagContainer>
                        }
                        {(editMode || renderDescription) && <Description>{description}</Description>}
                    </DetailsColumn >
                    <AttributeFooterWrapper>
                        {
                            renderAttributes &&
                            <AttributesWrapper>
                                {(renderDate && (editMode || typeof publishedDate!== 'undefined')) && (
                                    <AttributesItem>
                                        <Icon name="calendar_today" ariaHidden />
                                        {(editMode || publishedDate) && (
                                            <span>{getDictionaryItem('card-list-published-label', 'Published')}{' '}<time dateTime={publishedDate?.attrDate}>{publishedDate?.visualDate}</time></span>
                                        )}
                                    </AttributesItem>
                                )}
                                {
                                    (renderAuthor &&
                                        (editMode || author)) && (
                                        <AttributesItem>
                                            <Icon name="person_outline" ariaHidden />
                                            <span aria-label="author">{author}</span>
                                        </AttributesItem>
                                    )
                                }
                                {
                                    renderReadingTime && (editMode || readingTime) &&
                                    <AttributesItem>
                                        <Icon name="schedule" ariaHidden />
                                        <span>{formattedReadingTime}</span>
                                    </AttributesItem>
                                }
                            </AttributesWrapper >
                        }

                        {(
                            <FooterWrapper>
                                Read Article
                                <ArrowWrapper>
                                    <Icon name="arrow_forward" ariaHidden />
                                </ArrowWrapper>
                            </FooterWrapper>
                        )}
                    </AttributeFooterWrapper>
                </Component >
            </ItemList>
        )
    );
};

export default compose(withAuthenticatedCheck)(Item);
