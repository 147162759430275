import colors from '_containers/Theme/corporate/colors';
import {
  headingH3,
  headingH4,
  subheading2,
  textBody1
} from '_utils/styles';

export default {
  heading: headingH3,
  introduction: {
    subheading: subheading2,
  },
  completedMessage: {
    heading: {
      headingH4: headingH4,
      color: colors.all.brandSecondary3Dark,
    },
    subheading: {
      text: textBody1,
      fontWeight: 'bold',
    },
    text: textBody1,
    iconWrapper: {
      color: colors.all.brandSecondary3,
    },
  },
};
