import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';
import { isValidImage } from '_utils/validationChecks'

export const HomepageBannerFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.contextItem.Heading',
    errorMessage: 'A page title must be set or this component will not render',
    required: true
  },
  {
    field: 'rendering.fields.data.contextItem.Image',
    errorMessage: 'An image must be set or this component will not render',
    validators: [isValidImage]
  }
];
