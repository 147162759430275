import { compose } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MainSearch from '_components/MainSearch';
import { withModalContext } from '_containers/ModalContext';
import { Cross } from '_utils/icons';

import { SearchMenuProps } from './definitions';

import {
  CloseButton,
  CloseIcon,
  MainSearchWrapper,
  SearchMenuContainer,
  SearchMenuHeader,
} from './StyledSearchMenu';

// This is the mobile-only search menu for the page header
const SearchMenu: React.FC<SearchMenuProps> = ({
  modal,
  inputRef,
}) => {
  const [t] = useTranslation();
  const mobileSearchPlaceholder = t('search-placeholder-text-mobile');

  const handleCloseSearchMenu = () => {
    modal.onClose();
  };

  const handleOnSubmit = () => {
    handleCloseSearchMenu();
  };

  return (
    <SearchMenuContainer id="search-menu" className="search-menu">
      <SearchMenuHeader>
        <MainSearchWrapper>
          <MainSearch
            inputRef={inputRef}
            onSubmit={handleOnSubmit}
            placeholder={mobileSearchPlaceholder}
          />
          <CloseButton
            type="button"
            onClick={handleCloseSearchMenu}
            aria-label={t('search-menu-close-button-label')}
          >
            <CloseIcon>
              <Cross />
            </CloseIcon>
          </CloseButton>
        </MainSearchWrapper>
      </SearchMenuHeader>
    </SearchMenuContainer>
  );
};
export default compose(withModalContext)(SearchMenu);
