import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';

export const isValidheadingCTAConfiguration = (
  datasource,
) => {
  if (!datasource) {
    return false;
  }

  const { heading, link } = datasource;

  if (!heading?.jss?.value && link?.jss?.value?.href) {
    return false;
  }

  return true;
};

export const featureSectionFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.items',
    errorMessage: 'At least one article must be provided',
    minLength: 1
  },
  {
    field: 'rendering.fields.data.datasource.items',
    errorMessage: 'Some articles do not have valid links',
    allowRenderOnError: true,
    validators: [
      (field) => field?.url
    ]
  },
  {
    field: 'rendering.fields.data.datasource.items',
    errorMessage: 'Some articles do not have valid titles',
    allowRenderOnError: true,
    validators: [
      (field) => field?.title
    ]
  },
  {
    field: 'rendering.fields.data.datasource',
    errorMessage: 'Please add a heading when using a CTA',
    validators: [
      (field) => isValidheadingCTAConfiguration(field),
    ],
  }
];
