import styled from 'styled-components';
import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import { mq } from '_utils/styles';

export const Wrapper = styled(Grid.Container)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${({ theme }) => spacing({ theme, mt: '24px' })}

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const ContributorContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const DateReadingTimeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  ${mq('md')} {
    flex-direction: row;
  }
`;

export const StyledSeparator = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  flex-grow: 0;
  font-size: 24px;
  justify-content: center;
  ${({ theme }) => spacing({ theme, mx: '12px' })}

  &.hideOnMobile {
    ${mq.lessThan('md')} {
      display: none;
    }
  }
`;

