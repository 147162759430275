import React, { FC } from 'react';
import { Carousel } from '_utils/components';
import { PresonalisationCarouselProps, PresonalisationItem } from './definitions';
import Item from './Item';
import { responsiveColumnConfig } from './helpers';
import {
    StyledContainer,
    StyledListItem,
} from './StyledPresonalisationCarousel'

const PresonalisationCarousel: FC<PresonalisationCarouselProps> = ({
    editMode,
    rendering,
}):JSX.Element => {    
    const {
        uid,
    } = rendering;
    const items: Array<PresonalisationItem> = rendering?.fields?.data?.datasource?.items;

    return (
        <StyledContainer
            numItems={items?.length ?? 0}
            data-component
            id={uid}
        >
            <div className="hideContainer">
                <Carousel
                    flickityOptions={{ groupCells: true, cellAlign: 'left' }}
                    trackingName='personalisation'
                >
                    {items.map((p:PresonalisationItem) => (
                        <StyledListItem
                            key={p.id}
                            as={''}
                            carouselEnabled={true}
                            config={responsiveColumnConfig(items.length)}
                            editMode={editMode}
                            data-uid={p.id}
                        >
                            <Item />
                        </StyledListItem>
                    ))}
                </Carousel>
            </div>
        </StyledContainer>
    );
};

export default PresonalisationCarousel;