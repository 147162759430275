import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    color: colors.all.darkText,
    background: colors.all.neutralWhite,
    mediaPrint: {
      background: colors.background.tint1,
      color: colors.element.neutralBlack,
    }
  },
  bannerHeading: {
    color: colors.all.neutralWhite,
  }
};
