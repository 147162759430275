import React from 'react';


import Icon from '_components/Icon';
import { dateConverter } from '_utils/helpers';
import { warnInDev } from '_utils/helpers/dev';
import { useTranslation } from 'react-i18next';
import { AttributeRow, AttributeIcon, AttributeValue } from './StyledLastUpdated';

function LastUpdated({ systemDateUpdated }) {
  const [t] = useTranslation();
  const { value } = systemDateUpdated;
  const output = dateConverter(value, { isUTC: true, outputFormat: 'DD MMM YYYY' });

  if (!output) {
    warnInDev(`LastUpdated : dateConverter failed to convert ${value}`);
    return null;
  }

  const { attrDate, visualDate } = output;

  return (
    <AttributeRow>
      <AttributeIcon role="presentation">
        <Icon name={"calendar_today"} ariaHidden={true} />
      </AttributeIcon>
      <AttributeValue>
        {t('publication-page-banner-last-updated-prefix')}{' '}
        <time dateTime={attrDate}>{visualDate}</time>{' '}
      </AttributeValue>
    </AttributeRow>
  );
}

export default LastUpdated;
