import { canUseDOM } from 'exenv';

function useAdSlot({ mapping, sizes, adSlotID, position, template, keywords, uid }) {

  if (canUseDOM && typeof window !== 'undefined') {

    const googletag = window.googletag || (window.googletag || { cmd: [] });

    googletag.cmd.push(function () {

      // if slot already exists, delete it and re-create
      const existingSlot = googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === uid);

      if (existingSlot) {
        googletag.destroySlots([existingSlot]);
      }

      const adMapping = googletag.sizeMapping();

      mapping.forEach(item => {
        if (item?.viewport && item?.sizes) {
          adMapping?.addSize(item.viewport, item.sizes);
        }
      });
      const builtMapping = adMapping.build();

      const slot = googletag
        .defineSlot(adSlotID, sizes, uid)
        .defineSizeMapping(builtMapping)
        .addService(googletag.pubads());

      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs(true);

      slot.setTargeting('ITB-Template', [template]);
      slot.setTargeting('ITB-Position', [position]);
      if (keywords) {
        slot.setTargeting('keywords', keywords);
      }
      slot.setTargeting('url', document.location.pathname);
      googletag.enableServices();
    });

    googletag.cmd.push(function () {
      googletag.display(uid);
    });

  };
}

export default useAdSlot;
