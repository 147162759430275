
import styled,{ css } from 'styled-components';
import {button, mq} from '_utils/styles';
import { Spinner} from "_utils/components";


 export const LogOutButton = styled.button`
  ${button('default', 'primary')};
  align-items: center;
  display: flex;
  justify-content: center;
  width: calc(100% - 3rem);
  margin: 1rem auto;
  padding: 12px 24px;

  ${mq('sm')} {
    width: auto;
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledSpinnerLogout = styled(Spinner)`
  height: 24px;
  width: 24px;
`;