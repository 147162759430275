import { compose } from 'ramda';
import React, { useContext } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { withEditMode } from '_containers/BaseComponent';
import { CareerProfileFormContext } from '_containers/MMFCareerProfileFormContext/definitions';
import {
  FormContext,
  withFormSectionKeyContext,
} from '_containers/FormSectionContext';
import { withMemberContext } from '_containers/MemberContext';
import {
  EditableSectionContainer,
  EditableSectionHeader,
} from '_utils/components/Form/EditableSection';
import FieldSummary from '_utils/components/Form/FieldSummary';
import {
  Field,
  FormSummary,
  HeadingContainer,
} from '_utils/components/Form/StyledFormSection';
import { Tooltip } from '_utils/components';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { getCommaSeparatedList } from '_utils/helpers/form/index';

import { CareerProfileFormProps } from '../definitions';
import mockData from '../mock.json';
import CheckboxList from '_utils/components/Form/CheckboxList';

const SpecialInterestsForm: React.FC<CareerProfileFormProps> = ({
  editMode,
  mockCareerProfile,
  rendering,
  careerProfileApi,
}) => {
  const formContext = useContext<CareerProfileFormContext>(FormContext);
  const sectionContext = formContext?.specialityAreas;
  const referenceData = careerProfileApi?.result;

  const getInterestsOptions = referenceData?.data?.referenceData?.specialityAreas?.items;
  const tooltipMessage = 'Please select all areas in which you are considered a specialist or have significant expertise. CPA Australia may use this information to identify you as a subject matter expert.'

  const uid = `special-interests`;

  // render component with mock data when in Experience Editor
  const careerProfile =
    !editMode
      ? careerProfileApi?.result?.data
      : mockCareerProfile ?? mockData?.mockCareerProfile;

  // get the field and label values
  const heading = getDictionaryItem('form-career-section-special-interests', 'Speciality areas');
  const updateLabel = getDictionaryItem('form-career-button-update', 'Update');
  const interestsLabel = getDictionaryItem('form-career-select-interests', 'Select at least one of the following topics you are interested in');

  const specialInterestData = {
    name: "specialityAreas.items",
    items: getInterestsOptions
  };

  return (
    <EditableSectionContainer
      className="licenses-form"
      isEditing={sectionContext?.isEditing}
      uid={uid}
    >
      <EditableSectionHeader
        icon="lightbulb"
        updateLabel={updateLabel}
        sectionIdentifier={uid}
      >
        {heading}
      </EditableSectionHeader>

      {sectionContext?.isEditing
        ? (
          <>
            {/* TODO: Add checkbox functionality as part of WP-2338 */}
            <FormSummary>
              <Field>
                <HeadingContainer>
                  <RichText field={{ value: interestsLabel }} />
                  <Tooltip content={tooltipMessage} />
                </HeadingContainer>
                <CheckboxList
                  otherReferenceLabel="name"
                  id="specialityAreas.items"
                  {...specialInterestData}
                />
              </Field>
            </FormSummary>
          </>
        ) : (
          <FormSummary>
            <Field>
              <FieldSummary
                labelDictionaryKey="form-career-my-interests"
                labelFallback="My speciality areas"
                value={getCommaSeparatedList(careerProfile?.specialityAreas?.items, 'specialityAreaName')}
              />
            </Field>
          </FormSummary>
        )
      }
    </EditableSectionContainer>
  );
};

export default compose(
  withEditMode,
  withMemberContext,
  withFormSectionKeyContext('specialityAreas'),
)(SpecialInterestsForm);
