import colors from '../../containers/Theme/intheblack/colors';

export default {
  carousel: {
    background: colors.background.tint1,
  },
  item: {
    card: {
      borderRadius: 4,
      background: colors.background.tint1,
      boxShadow: '0px 1px 1px rgba(0,0,0,0.08),0px 2px 4px rgba(0,0,0,0.12)',
      hoverBoxShadow: '0px 1px 4px rgb(0 0 0 / 8%), 0px 6px 16px rgb(0 0 0 / 12%)',
    },
    tags: {
      color: colors.text.subtle,
      background: colors.background.tint3,
    },
    title: {
  
    },
    htmlContent: {
      color: colors.text.subtle,
    }
  }
};

export const darkMode = {};