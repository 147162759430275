import colors from '_containers/Theme/corporate/colors';

export default {
  container: {
    background: colors.background.tint2
  },
  pageContainer: {
    color: colors.text.secondary
  },
  separator: {
    color: colors.text.nonessential
  }

};

